<template>
  <div class="page-mb" style="position: relative;height:auto">
    <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
      <div v-if="modalShowIndex === 0"
        class="mask-nft gotnft d-flex flex-column justify-content-center align-items-center"
        :class="boxObtainList.length === 1 ? 'one' : 'more'">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center"
          :class="isMobile && boxObtainList.length === 1 ? 'justify-content-center' : ''">
          <div :class="isMobile && boxObtainList.length > 1 ? 'multiitems' : ''" class="box-infotip">

            {{ $t('MysteryBox.congratulations-you-have-got-cards', {
              'cardcount': (boxObtainList.length === 1 ? 'card' :
                boxObtainList.length + ' cards')
            })
            }}</div>
          <div v-if="boxObtainList.length === 1" class="nft-item d-flex align-items-center justify-content-center">
            <img :src="boxObtainList[0].type === 'nft' ? boxObtainList[0].nft.preview : boxObtainList[0].token.image"
              class="nft-item-image" />
            <div class="nft-item-intro d-flex flex-column align-items-start">
              <div class="nft-item-intro-title">
                {{
                  boxObtainList[0].type === 'nft' ? $t(`CardList.${boxObtainList[0].nft.typeid}.name`) :
                  boxObtainList[0].token.symbol
                }}
              </div>
              <div class="nft-item-intro-title" v-if="boxObtainList[0].type === 'nft'">
                #{{
                  boxObtainList[0].nft.tokenid
                }}
              </div>
            </div>
            <div v-if="boxObtainList[0].type === 'nft'"
              class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
              <!-- <img src="/static/images/element_lighting.png" /> -->
              <span class="text" :class="boxObtainList[0].nft.level > 0 ? 'nozero' : ''">LV. {{
                boxObtainList[0].nft.level
              }}/5</span>
            </div>
            <div v-else class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
              <span>{{ boxObtainList[0].amount }}</span>
            </div>

            <div v-if="boxObtainList[0].type === 'nft'" class="nft-item-attrs">
              <div v-if="boxObtainList[0].nft['earth'] > 0"
                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                <img class="nft-item-attrs-image" src="/static/images/element_attr_yellow_earth.png" />
                <span class="nft-item-attrs-text earth" :class="boxObtainList[0].nft['earth'] >= 10 ? 'digit2' : ''">
                  {{
                    boxObtainList[0].nft['earth']
                  }}
                </span>
              </div>
              <div v-if="boxObtainList[0].nft['water'] > 0"
                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                <img class="nft-item-attrs-image" src="/static/images/element_attr_blue_water.png" />
                <span class="nft-item-attrs-text water" :class="boxObtainList[0].nft['water'] >= 10 ? 'digit2' : ''">
                  {{
                    boxObtainList[0].nft['water']
                  }}
                </span>
              </div>
              <div v-if="boxObtainList[0].nft['fire'] > 0"
                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                <span class="nft-item-attrs-text fire" :class="boxObtainList[0].nft['fire'] >= 10 ? 'digit2' : ''">{{
                  boxObtainList[0].nft['fire']
                }}</span>
              </div>
              <div v-if="boxObtainList[0].nft['wind'] > 0"
                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                <img class="nft-item-attrs-image" src="/static/images/element_attr_green_wind.png" />
                <span class="nft-item-attrs-text wind" :class="boxObtainList[0].nft['wind'] >= 10 ? 'digit2' : ''">{{
                  boxObtainList[0].nft['wind']
                }}</span>
              </div>
              <div class="nft-item-attr d-flex align-items-center"></div>
            </div>
            <img v-if="boxObtainList[0].type === 'nft'" class="nft-item-attrs-image-level"
              :src="'/static/images/element_level_' + getLowerCase(boxObtainList[0].nft['rarity']) + '.png'" />
          </div>
          <div v-else class="d-flex flex-column" style="width: 100%">
            <div class="nft-item-list">
              <div v-for="(boxItem, boxItemIndex) of boxObtainList" :key="'index_' + boxItemIndex"
                class="d-flex flex-column justify-content-center align-items-center">
                <!-- <div > -->
                <div class="nft-item d-flex align-items-center justify-content-center">
                  <img :src="boxItem.type === 'nft' ? boxItem.nft.preview : boxItem.token.image" class="nft-item-image" />
                  <div class="nft-item-intro d-flex flex-column align-items-start">
                    <div class="nft-item-intro-title">
                      {{
                        boxItem.type === 'nft' ? $t(`CardList.${boxItem.nft.typeid}.name`) : boxItem.token.symbol
                      }}
                    </div>
                    <div class="nft-item-intro-title" v-if="boxItem.type === 'nft'">#{{ boxItem.nft.tokenid }}</div>
                  </div>
                  <div v-if="boxItem.type === 'nft'"
                    class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                    <!-- <img src="/static/images/element_lighting.png" /> -->
                    <span class="text" :class="boxItem.nft.level > 0 ? 'nozero' : ''">{{ boxItem.nft.level }}/5</span>
                  </div>
                  <div v-else class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                    <span>{{ boxItem.amount }}</span>
                  </div>
                  <div v-if="boxItem.type === 'nft'" class="nft-item-attrs">
                    <div v-if="boxItem.nft['earth'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_yellow_earth.png" />
                      <span class="nft-item-attrs-text earth" :class="boxItem.nft['earth'] >= 10 ? 'digit2' : ''">{{
                        boxItem.nft['earth']
                      }}</span>
                    </div>
                    <div v-if="boxItem.nft['water'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_blue_water.png" />
                      <span class="nft-item-attrs-text water" :class="boxItem.nft['water'] >= 10 ? 'digit2' : ''">{{
                        boxItem.nft['water']
                      }}</span>
                    </div>
                    <div v-if="boxItem.nft['fire'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                      <span class="nft-item-attrs-text fire" :class="boxItem.nft['fire'] >= 10 ? 'digit2' : ''">{{
                        boxItem.nft['fire']
                      }}</span>
                    </div>
                    <div v-if="boxItem.nft['wind'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_green_wind.png" />
                      <span class="nft-item-attrs-text wind" :class="boxItem.nft['wind'] >= 10 ? 'digit2' : ''">{{
                        boxItem.nft['wind']
                      }}</span>
                    </div>
                    <div class="nft-item-attr d-flex align-items-center"></div>
                  </div>
                  <img v-if="boxItem.type === 'nft'" class="nft-item-attrs-image-level"
                    :src="'/static/images/element_level_' + getLowerCase(boxItem.nft['rarity']) + '.png'" />
                </div>
                <!-- </div> -->
              </div>
            </div>
            <div class="gap"></div>
          </div>

        </div>
      </div>
      <div v-if="modalShowIndex === 1"
        class="mask-nft mysteryboxcontent d-flex flex-column justify-content-center align-items-center">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <div class="box-infotip">{{ boxContent.name }} Content</div>
          <div class="droprate-block">
            <div class="title">Probability</div>
            <div class="list">
              <div class="item d-flex align-items-center justify-content-between"
                v-for="(rate, rateIndex) of boxContent.rate" :key="'rate-' + rateIndex">
                <template v-if="rate['name'] === 'MC'">
                  <span class="percent">MC</span>
                </template>
                <img v-else :src="'/static/images/element_level_' + getLowerCase(rate['name']) + '.png'" />
                <span class="percent">{{ (parseFloat(rate['rate'] / (10 ** 5)) * 100).toFixed(2) }}%</span>
              </div>
            </div>
          </div>
          <div class="nft-list-title">Options ({{ boxContent.list.length }})</div>
          <div class="nft-item-list">
            <div v-for="(boxItem, boxItemIndex) of boxContent.list" :key="'index-boxcontent-' + boxItemIndex">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="nft-item d-flex align-items-center justify-content-center">
                  <img :src="boxItem['type'] === 'nft' ? boxItem['nft']['preview'] : boxItem['token']['image']"
                    class="nft-item-image" />
                  <div class="nft-item-intro d-flex flex-column align-items-start">
                    <div class="nft-item-intro-title">
                      {{
                        boxItem['type'] === 'nft' ? $t(`CardList.${boxItem['nft']['typeid']}.name`) :
                        boxItem['token']['symbol']
                      }}
                    </div>
                    <!-- <div v-else class="nft-item-intro-upgrade">AMOUNT: {{ nft['amount'] }}</div> -->
                  </div>
                  <div v-if="boxItem['type'] === 'nft'"
                    class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                    <!-- <img src="/static/images/element_lighting.png" /> -->
                    <span class="text" :class="boxItem['nft']['level'] > 0 ? 'nozero' : ''">LV. {{
                      boxItem['nft']['level']
                    }}/5</span>
                  </div>
                  <div v-else class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                    <span>{{ boxItem['amount'] }}</span>
                  </div>

                  <div v-if="boxItem['type'] === 'nft'" class="nft-item-attrs">
                    <div v-if="boxItem['nft']['earth'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_yellow_earth.png" />
                      <span class="nft-item-attrs-text earth" :class="boxItem['nft']['earth'] >= 10 ? 'digit2' : ''">{{
                        boxItem['nft']['earth']
                      }}</span>
                    </div>
                    <div v-if="boxItem['nft']['water'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_blue_water.png" />
                      <span class="nft-item-attrs-text water" :class="boxItem['nft']['water'] >= 10 ? 'digit2' : ''">{{
                        boxItem['nft']['water']
                      }}</span>
                    </div>
                    <div v-if="boxItem['nft']['fire'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                      <span class="nft-item-attrs-text fire" :class="boxItem['nft']['fire'] >= 10 ? 'digit2' : ''">{{
                        boxItem['nft']['fire']
                      }}</span>
                    </div>
                    <div v-if="boxItem['nft']['wind'] > 0"
                      class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                      <img class="nft-item-attrs-image" src="/static/images/element_attr_green_wind.png" />
                      <span class="nft-item-attrs-text wind" :class="boxItem['nft']['wind'] >= 10 ? 'digit2' : ''">{{
                        boxItem['nft']['wind']
                      }}</span>
                    </div>
                    <div class="nft-item-attr d-flex align-items-center"></div>
                  </div>
                  <img v-if="boxItem['type'] === 'nft'" class="nft-item-attrs-image-level"
                    :src="'/static/images/element_level_' + getLowerCase(boxItem['nft']['rarity']) + '.png'" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="modalShowIndex === 2" class="mask-nft d-flex flex-column justify-content-center align-items-center"
        style="height: auto;padding: 32px 0">
        <svg class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          @click="closeModal()" style="cursor: pointer;">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="d-flex flex-column align-items-center" style="width: 90%;">
          <div class="box-infotip">Invitation</div>
          <div :class="isMobile ? 'flex-column' : ''" class="d-flex align-items-center"
            style="color: white;font-size: 14px;cursor: pointer;">
            <span class="blindbox-model-text">{{ serverHost }}#/mysterybox?invitecode={{ inviteCode }}</span>
            <div class="d-flex align-items-center">
              <el-button style="margin-top: 0;margin-left: 10px;height: auto;"
                @click="copyTextInvitation(serverHost + '#/mysterybox?invitecode=' + inviteCode)" round
                class="nft-item-button blind-box-button">Copy</el-button>
            </div>
          </div>
          <div style="color: white;margin-top: 20px;font-size: 14px;">
            Your all invited user have bought {{
              invitedBuyBoxCount
            }} mysteryboxs
          </div>
        </div>
      </div>
      <modal-approve v-if="modalShowIndex === 3" :tokeninfo="getApproveTokenAndNeedAmount(selecedBoxIndex)"
        @confirm="approve(selecedBoxIndex)" @cancel="closeModal()" />
      <div v-else-if="modalShowIndex === 4"
        class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <div class="title">{{ $t('Dialog.transter') }}</div>
          <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
            class="content d-flex">
            <div class="right flex flex-column">
              <div class="infotip">
                {{ $t('MysteryBox.your-mystery-box-will-be-transterd-to-another-wallet-address') }}
              </div>
              <div class="item-title">{{ $t('Dialog.recievewalletaddress') }}</div>
              <div class="input-block d-flex align-items-center">
                <input v-model="inputWalletAddressTransfer" type="text" placeholder="" style="text-align: left"
                  maxlength="42" />
              </div>
              <div class="button-block-mysterbox">
                <button class="button-cancel" @click="closeModal()">
                  {{ $t('Dialog.cancel') }}
                </button>
                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="transferBox()">
                  {{ $t('Dialog.transter') }}
                </button>
              </div>

            </div>
          </div>


          <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
        </div>
      </div>
    </el-dialog>

    <div v-if="!isLoading" class="mysterybox d-flex justify-content-center"
      :class="isMobile ? '' : 'height-header-padding'">
      <div v-if="!isMobile" class="content">
        <div class="top d-flex align-items-center justify-content-between">
          <div class="left d-flex align-items-center">
            <div class="desc">{{ $t('MysteryBox.account-balance') }}</div>
            <div class="balance">{{ tokenObject.TAP.balance === 0 ? 0 : tokenObject.TAP.balance.toFixed(2) }} TAP {{
              $t('Common.and')
            }} {{
  tokenObject.MC.balance === 0 ? 0 : tokenObject.MC.balance.toFixed(2)
}} MC</div>
          </div>
          <!-- <div class="right d-flex flex-column align-items-center">
              <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + tokenObject.TAP.address"
                class="buy-link" target="_blank">{{ tokenObject.TAP.symbol }} / USDT Exchange ></a>
              <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + tokenObject.MC.address"
                class="buy-link" target="_blank">{{ tokenObject.MC.symbol }} / USDT Exchange ></a>
            </div> -->
        </div>
        <div class="box-type-list">
          <div class="title">{{ $t('MysteryBox.limited-mystery-boxes') }}</div>
          <div class="box-list">
            <template v-for="(box, boxIndex) of boxList" :key="'limit-box-'+boxIndex">
              <div v-if="box['stock'] > -1"
                class="item limited d-flex flex-column justify-content-start align-items-center">
                <span :class="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3 ? 'finished' : ''"
                  class="status">
                  <template v-if="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3">{{ $t('MysteryBox.ended')
                  }}</template>
                  <template v-else-if="getBoxState(boxIndex) === 0">{{ $t('MysteryBox.incomming') }}</template>
                  <template v-else>{{ $t('MysteryBox.on-sale') }}</template>
                </span>
                <img :src="box['boximage']" @click="showTopBoxContent(boxIndex)" />
                <span class="box-title">{{ box['boxname'] }}</span>

                <div class="data-block d-flex flex-column">
                  <div class="text-block d-flex align-items-center justify-content-end">
                    <span class="text">
                      <template v-if="getBoxState(boxIndex) > 0">{{
                        (box['sold'] / (box['sold'] + box['stock']) *
                          100).toFixed(2)
                      }}</template>
                      <template v-else>0</template>%
                    </span>
                  </div>
                  <div class="progress">
                    <div class="inner"
                      :style="{ 'width': currentTime < box['starttime'] ? 0 : (box['sold'] / (box['sold'] + box['stock']) * 100).toFixed(2) + '%' }">
                    </div>
                  </div>
                  <div class="text-block d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <template v-if="box['starttime'] > 0 && box['endtime'] > 0 && currentTime <= box['endtime']">
                        <span class="text">
                          <template v-if="currentTime >= box['starttime']">{{ $t('MysteryBox.time-remaining')
                          }}</template>
                          <template v-else>{{ $t('MysteryBox.time-starting') }}</template>
                        </span>
                        <span class="text yellow">
                          <template v-if="currentTime >= box['starttime']">{{
                            countDownTime(box['endtime'] - currentTime)
                          }}</template>
                          <template v-else>{{ formatTime(box['starttime']) }}</template>
                        </span>
                      </template>
                    </div>
                    <span class="text">{{ box['sold'] }}/{{ box['sold'] + box['stock'] }}</span>
                  </div>
                </div>
                <div class="max-block d-flex align-items-center justify-content-center">
                  <div class="max"></div>
                  <el-input-number :disabled="getBoxState(boxIndex) !== 1" v-model="box['buyAmount']" :min="1"
                    @change="watchInput(boxIndex)" />
                  <button class="max maxbutton" :disabled="getBoxState(boxIndex) !== 1" @click="maxInput(boxIndex)">{{
                    $t('Common.max')
                  }}</button>
                </div>
                <template v-if="walletwhitelist.includes(box['white'])">
                  <button class="button-buy mbbuy" v-if="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3"
                    disabled>
                    <template v-if="getBoxState(boxIndex) === 3">{{ $t('MysteryBox.sold-out') }}</template>
                    <template v-else>{{ $t('MysteryBox.button_ended') }}</template>
                  </button>
                  <template v-else>
                    <button class="button-buy mbbuy" @click="openTopbox(boxIndex, parseInt(box['buyAmount'], 10))"
                      :disabled="getBoxState(boxIndex) === 0">
                      {{ box['price'] * parseInt(box['buyAmount'], 10) }} {{
                        box['paysymbol']
                      }}
                    </button>
                  </template>
                </template>
                <template v-else>
                  <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                    <button class="button-buy mbbuy">{{ $t('MysteryBox.how-to-buy') }}</button>
                  </a>
                </template>
                <a v-if="box['paysymbol'].indexOf('USDT') < 0"
                  :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + box['pay']"
                  class="tip buy-link" target="_blank">{{ box['paysymbol'] }} / USDT {{ $t('MysteryBox.exchange') }}
                  ></a>
              </div>
            </template>
          </div>
          <div class="title">{{ $t('MysteryBox.unlimited-mystery-boxes') }}</div>
          <div class="box-list">
            <template v-for="(box, boxIndex) of boxList" :key="'unlimit-box-'+boxIndex">
              <div v-if="box['stock'] === -1" class="item d-flex flex-column justify-content-start align-items-center">
                <span :class="getBoxState(boxIndex) !== 1 ? 'finished' : ''" class="status">
                  <template v-if="getBoxState(boxIndex) === 0">{{ $t('MysteryBox.incomming') }}</template>
                  <template v-else-if="getBoxState(boxIndex) === 1">{{ $t('MysteryBox.on-sale') }}</template>
                </span>
                <img :src="box['boximage']" @click="showTopBoxContent(boxIndex)" />
                <span class="box-title">{{ box['boxname'] }}</span>
                <span class="soldnum">{{ $t('MysteryBox.total-sold') }} {{ box['sold'] }}</span>
                <div class="max-block d-flex align-items-center justify-content-center">
                  <div class="max"></div>
                  <el-input-number v-model="box['buyAmount']" :min="1" @change="watchInput(boxIndex)"
                    :disabled="getBoxState(boxIndex) !== 1" />
                  <div class="max" @click="maxInput(boxIndex)" :disabled="getBoxState(boxIndex) !== 1">MAX</div>
                </div>
                <template v-if="walletwhitelist.includes(box['white'])">
                  <button :disabled="getBoxState(boxIndex) === 0" class="button-buy mbbuy"
                    @click="openTopbox(boxIndex, parseInt(box['buyAmount'], 10))">
                    {{ box['price'] * parseInt(box['buyAmount'], 10) }} {{
                      box['paysymbol']
                    }}
                  </button>
                </template>
                <template v-else>
                  <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                    <button class="button-buy mbbuy">{{ $t('MysteryBox.how-to-buy') }}</button>
                  </a>
                </template>
                <a v-if="box['paysymbol'].indexOf('USDT') < 0"
                  :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + box['pay']"
                  class="tip buy-link" target="_blank">{{ box['paysymbol'] }} / USDT {{ $t('MysteryBox.exchange') }}
                  ></a>
              </div>
            </template>
          </div>
        </div>
        <div class="box-tip-contract d-flex align-items-center">
          <span>{{ $t('MysteryBox.mystery-box-contract-address') }}</span>
          <span class="contract">{{ contractBoxAssetsAddress }}</span>
        </div>
        <div class="line-split"></div>
        <el-radio-group v-model="radioIndex" size="large">
          <el-radio-button label="0">{{ $t('MysteryBox.my-mystery-boxes') }}</el-radio-button>
          <el-radio-button label="1">{{ $t('MysteryBox.purchase-history') }}</el-radio-button>
        </el-radio-group>
        <div class="d-flex align-items-start" :style="{ height: getBottomHeight() }">
          <template v-if="parseInt(radioIndex) === 0">
            <div ref="divMyBoxListPC" class="box-owned">
              <template v-for="(box, boxIndex) of boxTypeList">
                <template v-if="box.boxIdList.length > 0">
                  <div class="title-block d-flex align-items-center">
                    <span class="title">
                      {{ box.name }} ({{
                        box.boxIdList.length > 1 ?
                        box.boxIdList.length + ' boxes' : '1 box'
                      }})
                    </span>
                    <button class="button-buy mbopenall" v-if="box.boxIdList.length > 1" :disabled="buttonDisabled"
                      @click="openbox(boxIndex, true)">{{ $t('MysteryBox.open-all-box') }}</button>
                  </div>
                  <div class="box-list">
                    <div v-for="(boxId, boxIdIndex) of box.boxIdList"
                      class="item d-flex flex-column justify-content-center align-items-center">
                      <div class="img-block d-flex align-items-center justify-content-center">
                        <img v-if="boxOpenId === boxId || boxOpenCatIndex === boxIndex"
                          :src="`/static/images/${getBoxPreviewName(box['image'])}-open.gif`" class="open" />
                        <img v-else @click="showboxContent(boxIndex)" :src="box['image']" />
                      </div>

                      <button v-if="boxOpenId === -1 && boxOpenCatIndex === -1" class="button-buy mbopen"
                        @click="openbox(boxIndex, false, boxId)" :disabled="buttonDisabled">{{ $t('MysteryBox.open-box')
                        }}</button>
                      <span @click="showModal(4, { boxId: boxId })" class="tip buy-link" target="_blank">{{
                        $t('Infotip.action-transfer')
                      }}
                        &gt;</span>
                    </div>
                  </div>
                </template>
              </template>
              <el-empty image="/static/images/icon_empty_mysterybox.png"
                v-if="isEmptyMysteryBoxList() || walletAddress.length === 0"
                :description="$t('MysteryBox.no-mystery-boxes')">
              </el-empty>
            </div>
          </template>
          <template v-else-if="parseInt(radioIndex) === 1">
            <div ref="divBuyHistoryPC" class="buy-history">
              <div class="title-block d-flex align-items-center justify-content-between">
                <span class="col-box">{{ $t('MysteryBox.mystery-box') }}</span>
                <span class="col-price">{{ $t('MysteryBox.price') }}</span>
                <span class="col-time">{{ $t('MysteryBox.purchased-at') }}</span>
                <!-- <span class="col-amount">Amount</span> -->
                <span class="col-time">{{ $t('MysteryBox.open-at') }}</span>
                <span class="col-trade-link">{{ $t('MysteryBox.trade-hash') }}</span>
              </div>
              <template v-if="boxOpenHistoryData.length > 0">
                <div class="data-item d-flex align-items-center justify-content-between"
                  v-for="(ele, eleIndex) of boxOpenHistoryData" :key="'history-' + eleIndex">
                  <div class="d-flex col-box align-items-center justify-content-start">
                    <img :src="ele['boxinfo']['boximage']" :title="ele['boxinfo']['boxname']" />
                    <span class="name">{{ ele['boxinfo']['boxname'] }}</span>
                  </div>
                  <span class="col-price">{{ ele['boxinfo']['price'] }} {{ ele['boxinfo']['paysymbol'] }}</span>
                  <span class="col-time">{{ ele['buyTS'] === 0 ? '---' : formatTime(ele['buyTS']) }}</span>
                  <!-- <span class="col-amount">1</span> -->

                  <span class="col-time">{{
                    ele['obtainTS'] > 0 ? formatTime(ele['obtainTS']) : $t('MysteryBox.not-opened')
                  }}</span>
                  <div class="col-trade-link">
                    <a target="_blank" :href="getTXLink(ele['obtaintx'])"
                      v-if="ele['obtaintx'] && ele['obtaintx'].length > 0">{{ $t('MysteryBox.view') }}</a>
                    <span v-else>---</span>
                  </div>
                </div>
                <div v-if="recordCount / pagePerCount > 1"
                  class="pageinfo mysteryboxpage d-flex align-items-center justify-content-center">
                  <pagination @change-page="getPageDataBuyHistory" :pagesize="pagePerCount" :total="recordCount"
                    :page="pageNow" />
                </div>
              </template>
              <el-empty v-else :desccription="$t('MysteryBox.no-open-history')" />
            </div>
          </template>
        </div>

      </div>
      <div v-else class="mobile-content height-header-padding">
        <div class="mobile-content-inner">
          <div v-if="!isEmptyMysteryBoxList() && walletAddress.length > 0" class="go2myboxlist"
            @click="go2MyBoxListMobile">
            {{ $t('MysteryBox.my-mystery-box') }}
          </div>
          <div class="top d-flex  flex-column align-items-center justify-content-between">
            <div class="left d-flex align-items-center justify-content-between">
              <div class="desc">{{ $t('MysteryBox.balance') }}</div>
              <div class="balance">{{ tokenObject.TAP.balance === 0 ? 0 : tokenObject.TAP.balance.toFixed(2) }} TAP {{
                $t('Common.and')
              }}
                {{
                  tokenObject.MC.balance === 0 ? 0 : tokenObject.MC.balance.toFixed(2)
                }} MC</div>
            </div>
            <!-- <div class="right d-flex align-items-center justify-content-between">
                <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + tokenObject.TAP.address"
                  class="buy-link" target="_blank">{{ tokenObject.TAP.symbol }} / USDT Exchange ></a>
                <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + tokenObject.MC.address"
                  class="buy-link" target="_blank">{{ tokenObject.MC.symbol }} / USDT Exchange ></a>
              </div> -->
          </div>
          <div class="box-type-list">
            <div class="title">{{ $t('MysteryBox.limited-mystery-boxes') }}</div>
            <div class="box-list">
              <template v-for="(box, boxIndex) of boxList" :key="'limit-box-'+boxIndex">
                <div v-if="box['stock'] > -1"
                  class="item limited d-flex flex-column justify-content-start align-items-center">
                  <span :class="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3 ? 'finished' : ''"
                    class="status font-10px">
                    <template v-if="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3">{{
                      $t('MysteryBox.ended')
                    }}</template>
                    <template v-else-if="getBoxState(boxIndex) === 0">{{ $t('MysteryBox.incomming') }}</template>
                    <template v-else>{{ $t('MysteryBox.on-sale') }}</template>
                  </span>
                  <img :src="box['boximage']" @click="showTopBoxContent(boxIndex)" />
                  <span class="box-title">{{ box['boxname'] }}</span>

                  <div class="data-block d-flex flex-column">
                    <div class="text-block d-flex align-items-center justify-content-end">
                      <span class="text">
                        <template v-if="getBoxState(boxIndex) > 0">{{
                          (box['sold'] / (box['sold'] + box['stock']) *
                            100).toFixed(2)
                        }}</template>
                        <template v-else>0</template>%
                      </span>
                    </div>
                    <div class="progress">
                      <div class="inner"
                        :style="{ 'width': currentTime < box['starttime'] ? 0 : (box['sold'] / (box['sold'] + box['stock']) * 100).toFixed(2) + '%' }">
                      </div>
                    </div>
                    <div class="text-block d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <template v-if="box['starttime'] > 0 && box['endtime'] > 0 && currentTime < box['endtime']">
                          <span class="text">
                            <template v-if="currentTime >= box['starttime']">{{ $t('MysteryBox.time-remaining')
                            }}</template>
                            <template v-else>{{ $t('MysteryBox.time-starting') }}</template>
                          </span>
                          <span class="text yellow">
                            <template v-if="currentTime >= box['starttime']">{{
                              countDownTime(box['endtime'] - currentTime)
                            }}</template>
                            <template v-else>{{ formatTime(box['starttime']) }}</template>
                          </span>
                        </template>
                      </div>
                      <span class="text">{{ box['sold'] }}/{{ box['sold'] + box['stock'] }}</span>
                    </div>
                  </div>
                  <div class="max-block d-flex align-items-center justify-content-center">
                    <div class="max"></div>
                    <el-input-number :disabled="getBoxState(boxIndex) !== 1" v-model="box['buyAmount']" :min="1"
                      @change="watchInput(boxIndex)" />
                    <button class="max maxbutton" :disabled="getBoxState(boxIndex) !== 1" @click="maxInput(boxIndex)">{{
                      $t('Common.max')
                    }}</button>
                  </div>
                  <template v-if="walletwhitelist.includes(box['white'])">

                    <button class="button-buy mbbuy" v-if="getBoxState(boxIndex) === 2 || getBoxState(boxIndex) === 3"
                      disabled>
                      <template v-if="getBoxState(boxIndex) === 3">{{ $t('MysteryBox.sold-out') }}</template>
                      <template v-else>{{ $t('MysteryBox.button_ended') }}</template>
                    </button>
                    <template v-else>
                      <button class="button-buy mbbuy" @click="openTopbox(boxIndex, parseInt(box['buyAmount'], 10))"
                        :disabled="getBoxState(boxIndex) === 0">
                        {{ box['price'] * parseInt(box['buyAmount'], 10) }} {{
                          box['paysymbol']
                        }}
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                      <button class="button-buy mbbuy">{{ $t('MysteryBox.how-to-buy') }}</button>
                    </a>
                  </template>
                  <a v-if="box['paysymbol'].indexOf('USDT') < 0"
                    :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + box['pay']"
                    class="tip buy-link" target="_blank">{{ box['paysymbol'] }} / USDT {{ $t('MysteryBox.exchange') }}
                    ></a>
                </div>
              </template>
            </div>
            <div class="title">{{ $t('MysteryBox.unlimited-mystery-boxes') }}</div>
            <div class="box-list">
              <template v-for="(box, boxIndex) of boxList" :key="'unlimit-box-'+boxIndex">
                <div v-if="box['stock'] === -1" class="item d-flex flex-column justify-content-start align-items-center">
                  <span :class="getBoxState(boxIndex) !== 1 ? 'finished' : ''" class="status font-10px">
                    <template v-if="getBoxState(boxIndex) === 0">{{ $t('MysteryBox.incomming') }}</template>
                    <template v-else-if="getBoxState(boxIndex) === 1">{{ $t('MysteryBox.on-sale') }}</template>
                  </span>
                  <img :src="box['boximage']" @click="showTopBoxContent(boxIndex)" />
                  <span class="box-title">{{ box['boxname'] }}</span>
                  <span class="soldnum">{{ $t('MysteryBox.total-sold') }} {{ box['sold'] }}</span>
                  <div class="max-block d-flex align-items-center justify-content-center">
                    <div class="max"></div>
                    <el-input-number v-model="box['buyAmount']" :min="1" @change="watchInput(boxIndex)"
                      :disabled="getBoxState(boxIndex) !== 1" />
                    <div class="max" @click="maxInput(boxIndex)" :disabled="getBoxState(boxIndex) !== 1">{{
                      $t('Common.max')
                    }}</div>
                  </div>
                  <template v-if="walletwhitelist.includes(box['white'])">
                    <button :disabled="getBoxState(boxIndex) === 0" class="button-buy mbbuy"
                      @click="openTopbox(boxIndex, parseInt(box['buyAmount'], 10))">
                      {{ box['price'] * parseInt(box['buyAmount'], 10) }} {{
                        box['paysymbol']
                      }}
                    </button>
                  </template>
                  <template v-else>
                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                      <button class="button-buy mbbuy">{{ $t('MysteryBox.how-to-buy') }}</button>
                    </a>
                  </template>
                  <a v-if="box['paysymbol'].indexOf('USDT') < 0"
                    :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + box['pay']"
                    class="tip buy-link" target="_blank">{{ box['paysymbol'] }} / USDT {{ $t('MysteryBox.exchange') }}
                    ></a>
                </div>
              </template>
            </div>
          </div>
          <div class="box-tip-contract d-flex flex-column align-items-center">
            <span>{{ $t('MysteryBox.mystery-box-contract-address') }}</span>
            <span class="contract">{{ contractBoxAssetsAddress }}</span>
          </div>
          <div class="line-split"></div>
          <div class="d-flex align-items-center justify-content-center">
            <el-radio-group v-model="radioIndex" size="large" style="width: 80%;">
              <el-radio-button style="width: 50%" label="0">{{ $t('MysteryBox.my-mystery-boxes') }}</el-radio-button>
              <el-radio-button style="width: 50%" label="1">{{ $t('MysteryBox.purchase-history') }}</el-radio-button>
            </el-radio-group>
          </div>
          <template v-if="parseInt(radioIndex) === 0">
            <div ref="refMyBoxListMobile" class="box-owned">
              <template v-for="(box, boxIndex) of boxTypeList">
                <template v-if="box.boxIdList.length > 0">
                  <div class="title-block d-flex align-items-center justify-content-between">
                    <span class="title">
                      {{ box.name }} ({{
                        box.boxIdList.length > 1 ?
                        box.boxIdList.length + ' boxes' : '1 box'
                      }})
                    </span>
                    <button class="button-buy mbopenall" v-if="box.boxIdList.length > 1" :disabled="buttonDisabled"
                      @click="openbox(boxIndex, true)">{{ $t('MysteryBox.open-all') }}</button>
                  </div>
                  <div class="box-list">
                    <div v-for="(boxId, boxIdIndex) of box.boxIdList"
                      class="item d-flex flex-column justify-content-center align-items-center">
                      <div class="img-block d-flex align-items-center justify-content-center">
                        <img v-if="boxOpenId === boxId || boxOpenCatIndex === boxIndex"
                          :src="`/static/images/${getBoxPreviewName(box['image'])}-open.gif`" class="open" />
                        <img v-else @click="showboxContent(boxIndex)" :src="box['image']" />
                      </div>

                      <button v-if="boxOpenId === -1 && boxOpenCatIndex === -1" class="button-buy mbopen"
                        @click="openbox(boxIndex, false, boxId)" :disabled="buttonDisabled">{{ $t('MysteryBox.open-box')
                        }}</button>
                      <span @click="showModal(4, { boxId: boxId })" class="tip buy-link" target="_blank">{{
                        $t('Infotip.action-transfer')
                      }}
                        &gt;</span>
                    </div>
                  </div>
                </template>
              </template>
              <el-empty v-if="isEmptyMysteryBoxList() || walletAddress.length === 0"
                :description="$t('MysteryBox.no-mystery-boxes')">
              </el-empty>
            </div>
          </template>
          <template v-else-if="parseInt(radioIndex) === 1">
            <div class="trade-record d-flex flex-column" style="background: none;">
              <template v-if="boxOpenHistoryData.length > 0">
                <div class="data-item-mobile" v-for="(ele, eleIndex) of boxOpenHistoryData" :key="'history-' + eleIndex">
                  <div class="row-data">
                    <div class="text">{{ $t('MysteryBox.box-name') }}</div>
                    <div class="d-flex align-items-center justify-content-end">
                      <!-- <img :src="ele['boxinfo']['boximage']" /> -->
                      <span class="text">{{ ele['boxinfo']['boxname'] }}</span>
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">{{ $t('MysteryBox.box-price') }}</div>
                    <div class="text">{{ ele['buyTS'] === 0 ? '---' : formatTime(ele['buyTS']) }}</div>
                  </div>
                  <div class="row-data">
                    <div class="text">{{ $t('MysteryBox.buy-time') }}</div>
                    <div class="text">{{ ele['boxinfo']['price'] }} {{ ele['boxinfo']['paysymbol'] }}</div>
                  </div>
                  <div class="row-data">
                    <div class="text">{{ $t('MysteryBox.open-time') }}</div>
                    <div class="text">{{
                      ele['obtainid'] > 0 ? formatTime(ele['obtainTS']) : 'not opened'
                    }}</div>
                  </div>
                  <div class="row-data">
                    <div class="text">{{ $t('MysteryBox.trade-hash') }}</div>
                    <div class="text">
                      <a target="_blank" :href="getTXLink(ele['obtaintx'])"
                        v-if="ele['obtaintx'] && ele['obtaintx'].length > 0">{{ $t('MysteryBox.view') }}</a>
                      <span v-else>---</span>
                    </div>
                  </div>
                </div>
                <div v-if="boxOpenHistoryData.length < recordCount && !isLoading"
                  class="d-flex justify-content-center loadmore">
                  <div v-if="!isLoadingMore" @click="getPageDataBuyHistory(pageNow + 1)">
                    {{ $t('Common.load-more-data') }}
                  </div>
                  <div v-else class="d-flex justify-content-center align-items-center">
                    <span>{{ $t('Common.loading-more') }}</span>
                    <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff" style="width: 16px; height: 16px;margin-left: 6PX;">
                      <path
                        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </template>

            </div>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateEvents } from '@makerdao/multicall'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  getCurrentInstance,
  watch,
  toRefs,
  inject,
  nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElMessage } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
// import TapDesktop from '../abis/TapDesktop.json'
import ERC20 from '../abis/ERC20.json'
import NFTMysteryKey from '../abis/NFTMysteryKey.json'
import NFTMysteryKeyBuyer from '../abis/NFTMysteryKeyBuyer.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import ERC721 from '../abis/ERC721.json'
// import i18n from '@/i18n'

export default defineComponent({
  name: 'MysteryBox',
  components: { ElButton, ElLoading },
  props: {
    walletAddress: {
      type: String,
      default: ''
    },
    isReady: {
      type: Boolean,
      default: false
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    watch(
      () => props.walletAddress,
      (newWalletAddr, oldWalletAddr) => {
        // LogUtils.debug(newWalletAddr)
        // LogUtils.debug(oldWalletAddr)
        if (!isLoadingInit.value) {
          if (newWalletAddr !== oldWalletAddr) {
            LogUtils.debug('load from change wallet...')
            loadData()
          }
        }
      },
    )

    watch(
      () => props.isRefresh,
      (newReady, oldReady) => {
        LogUtils.debug(newReady)
        LogUtils.debug(oldReady)
        if (newReady && newReady !== oldReady) {
          if (props.walletAddress.length === 0) {
            buttonDisabled.value = true
          } else {
            buttonDisabled.value = false
          }
          LogUtils.debug('load from ready...')
          loadData()
        }
      }
    )

    const { t } = useI18n()
    const createNotification = <CreateNotification>inject('create-notification')
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const router = useRouter()
    const BigNumber =
      getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

    const serverHost = ref(BLOCKCHAIN_CONFIG.serverHost)
    const pageChainConfig: any = {}
    const isLoading = ref(true)
    const buttonDisabled = ref(false)
    const isLoadingInit = ref(true)
    const isShowModal = ref(false)
    const modalShowIndex = ref(-1)
    const boxOpenId = ref(-1)
    const boxOpenCatIndex = ref(-1)
    const boxTopOpenIndex = ref(-1)
    const boxList: Ref<any[]> = ref([])
    const inviteCode = ref('')
    const inviteBuyCode = ref('')
    const invitedBuyBoxCount = ref(0)
    const inviterBoardList = ref([])
    const contractBoxAssetsAddress = ref('')
    const radioIndex = ref('0')
    const pagePerCount = ref(20)
    const pageNow = ref(1)
    const recordCount = ref(0)
    const isLoadingMore = ref(false)
    const refMyBoxListMobile: Ref<any> = ref(null)
    const selecedBoxIndex = ref(-1)
    const inputWalletAddressTransfer = ref('')
    let transferBoxId = -1

    // LogUtils.debug(`invitebuycode: ${inviteBuyCode.value}`)

    const boxObtainList: Ref<{
      type: string
      token: string
      amount: number
      symbol: string
      image: string
      nft: {
        tokenid: number
        character: string,
        description: string,
        image: string,
        name: string,
        preview: string,
        rarity: string,
        level: number,
        earth: number,
        wind: number,
        water: number,
        fire: number,
        typeid: number
      }
    }[]> = ref([])
    const isMobile = ref(false)
    const boxTypeInfoList: Ref<{
      name: string
      boxid: number
      list: []
    }[]> = ref([])
    const boxContent = ref({
      name: '',
      list: []
    })
    const boxTypeList: Ref<{
      name: string
      boxOneId: number,
      boxIdList: number[],
      image: ''
    }[]> = ref([])
    // let chainId = -1
    const chainSymbol = ''
    let assetsAPIBoxkeyListOwned: {
      list: [],
      wallet: '',
      chainid: 56,
      contract: ''
    } | null = null
    let assetsAPIBoxkeyInfo = {
      chainid: 56,
      contract: '',
      list: []
    }

    let boxbuyerBoxListAPIInfo = {
      chainid: 56,
      contract: '',
      assets: '',
      list: [],
      wallet: '',
      walletwhite: []
    }

    let boxOpenHistoryAPIInfo = {
      chainid: 56,
      wallet: '',
      list: []
    }

    const boxOpenHistoryData: Ref<any[]> = ref([])

    const tokenObject = ref({
      MC: {
        balance: 0,
        symbol: 'MC',
        address: ''
      },
      TAP: {
        balance: 0,
        symbol: 'TAP',
        address: ''
      }
    })

    const walletwhitelist = ref([])

    const currentTime = ref(0)
    let currentBlockNo = 0

    const divMyBoxListPC: Ref<any> = ref(null)
    const divBuyHistoryPC: Ref<any> = ref(null)
    const divMyBoxListPCHeight = ref(0)
    const divBuyHistoryPCHeight = ref(0)

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })
      if (typeof router.currentRoute.value.query.invitecode !== 'undefined') {
        inviteBuyCode.value = router.currentRoute.value.query.invitecode?.toString() || ''
      }
      if (props.isRefresh && isLoadingInit) {
        LogUtils.debug('load from onMounted...')
        loadData()
      }
    })

    const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
      TOOL.gtmTrack(event, category, action, label, value)
    }

    const go2MyBoxListMobile = () => {
      if (refMyBoxListMobile.value) {
        refMyBoxListMobile.value.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center'
        })
      }
    }

    const getTXLink = (hash: string) => {
      return pageChainConfig.chainBlockchainBrowserURLConfig + hash
    }

    //0-incomming 1-onsale 2-ended 3-soldout
    const getBoxState = (index: number) => {
      const box = boxList.value[index]

      if (box['stock'] !== -1) {
        if (box['stock'] === 0) {
          return 3
        } else {
          if (box['endtime'] > 0 && currentTime >= box['endtime']) {
            return 2
          } else if (box['starttime'] > 0 && currentTime < box['starttime']) {
            return 0
          }
        }
        return 1
      } else {
        if (box['starttime'] > 0 && currentTime < box['starttime']) {
          return 0
        }
        return 1
      }


      //  
    }

    const getBottomHeight = () => {
      if (divMyBoxListPC.value || divBuyHistoryPC.value) {
        if (divMyBoxListPC.value) {
          divMyBoxListPCHeight.value = divMyBoxListPC.value.offsetHeight
        }
        if (divBuyHistoryPC.value) {
          divBuyHistoryPCHeight.value = divBuyHistoryPC.value.offsetHeight
        }
        if (divMyBoxListPCHeight.value === 0 || divBuyHistoryPCHeight.value === 0) {
          return 'auto'
        }
        const height = divMyBoxListPCHeight.value > divBuyHistoryPCHeight.value ? divMyBoxListPCHeight.value : divBuyHistoryPCHeight.value
        return height + 'px'
      } else {
        return 'auto'
      }

    }

    const formatTime = (secs: number) => {
      return moment(secs * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
    }

    const countDownTime = (secs: number) => {
      return TOOL.dateTransform1(secs)
    }

    const clearNoNum = (numStr: string) => {
      numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
      numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
      // numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      // numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      // numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
      if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        numStr = parseInt(numStr, 10).toString()
      }
      return numStr
    }

    const watchInput = (index: number) => {
      const box = boxList.value[index]
      if (box.buyAmount.length > 0) {
        let inputNum = parseInt(box.buyAmount, 10)
        // const available = box.stock - box.sold
        let maxBuy = box.stock === -1 ? 99 : (box.stock > 99 ? 99 : box.stock)
        if (inputNum > maxBuy) {
          inputNum = maxBuy
        }
        box.buyAmount = inputNum.toString()
      }
    }

    const maxInput = (index: number) => {
      const box = boxList.value[index]
      // const available = box.stock - box.sold
      let maxBuy = box.stock === -1 ? 99 : (box.stock > 99 ? 99 : box.stock)
      box.buyAmount = maxBuy
    }

    const copyTextInvitation = (content: string) => {
      toClipboard(content)
      ElMessage({ message: 'Invitation link copied!', type: 'success' })
    }

    const isEmptyMysteryBoxList = () => {
      for (const info of boxTypeList.value) {
        if (info.boxIdList.length > 0) {
          return false
        }
      }
      return true
    }

    const getBoxPreviewName = (url: string) => {
      const res = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
      // LogUtils.debug(res)
      return res
    }

    const showModal = (index: number, data: any) => {
      isShowModal.value = true
      modalShowIndex.value = index
      if (index === 0) {
        boxObtainList.value = data
      } else if (index === 3) {
        selecedBoxIndex.value = data
      } else if (index === 4) {
        transferBoxId = data.boxId
      } else {
        boxContent.value = data
      }

    }

    const closeModal = () => {
      isShowModal.value = false
      boxObtainList.value = []
      boxContent.value = {
        name: '',
        list: []
      }
      transferBoxId = -1
      inputWalletAddressTransfer.value = ''
      modalShowIndex.value = -1
      boxTopOpenIndex.value = -1
      boxOpenId.value = -1
      selecedBoxIndex.value = -1
    }

    const getLowerCase = (str: any) => {
      return str.toString().toLowerCase()
    }

    const getApproveTokenAndNeedAmount = (index: number = -1, type = 0) => {
      let tokenInfo = {
        address: '',
        symbol: '',
        decimals: 0
      }
      let buyAmount = 0
      let tokenAmount = 0
      if (type === 0) {
        const ele = (index === -1000 ? null : boxList.value[index])
        tokenInfo = {
          address: ele.pay,
          symbol: ele.paysymbol,
          decimals: ele.paydecimals
        }
        buyAmount = ele['buyAmount']
        tokenAmount = new BigNumber(buyAmount).multipliedBy(ele['price']).toNumber()
      } else {
        tokenInfo = {
          address: tokenObject.value.MC.address,
          symbol: tokenObject.value.MC.symbol,
          decimals: 18
        }
      }

      return {
        symbol: tokenInfo.symbol,
        amount: tokenAmount
      }
    }

    const checkApprove = async (index: number = -1, type = 0) => {
      let tokenInfo = {
        address: '',
        symbol: '',
        decimals: 0
      }
      let buyAmount = 0
      let tokenAmount = 0
      const contractAddr = type === 0 ? boxbuyerBoxListAPIInfo.contract : null
      if (type === 0) {
        const ele = (index === -1000 ? null : boxList.value[index])
        tokenInfo = {
          address: ele.pay,
          symbol: ele.paysymbol,
          decimals: ele.paydecimals
        }
        buyAmount = ele['buyAmount']
        tokenAmount = new BigNumber(buyAmount).multipliedBy(ele['price']).toNumber()
      } else {
        tokenInfo = {
          address: tokenObject.value.MC.address,
          symbol: tokenObject.value.MC.symbol,
          decimals: 18
        }
      }

      const { walletAddress } = toRefs(props)
      if (walletAddress.value.length > 0) {
        const provider = await getWalletProvider()
        if (provider) {
          const signer = provider.getSigner()
          const contractToken = new ethers.Contract(
            tokenInfo.address,
            ERC20,
            signer
          )
          const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
          const allowanceNumber = Number(ethers.utils.formatEther(allowance))
          const needAmount = getApproveTokenAndNeedAmount(index).amount
          if (Number(allowanceNumber) < needAmount) {
            return false
          } else {
            return true
          }
        }
      }
      return false
    }

    const approve = async (index: number) => {
      TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request')
      const { walletAddress } = toRefs(props)
      if (walletAddress.value.length > 0) {
        const provider = await getWalletProvider()
        if (provider) {
          const ele = boxList.value[index]
          const tokenInfo = {
            address: ele.pay,
            symbol: ele.paysymbol,
            decimals: ele.paydecimals
          }

          const signer = provider.getSigner()
          const contractToken = new ethers.Contract(
            tokenInfo.address,
            ERC20,
            signer
          )
          const uuid = createNotification({
            message: t('Infotip.approving-token', { 'symbol': tokenInfo.symbol }),
            autoClose: false,
            canClose: false
          })
          const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
          // const amount = 0
          contractToken
            .approve(boxbuyerBoxListAPIInfo.contract, amount)
            .then(async function (transaction: any) {
              transaction
                .wait()
                .then(async function (transaction: any) {
                  const approveBN = await contractToken.allowance(
                    walletAddress.value,
                    boxbuyerBoxListAPIInfo.contract
                  )

                  for (let box of boxList.value) {
                    if (box.pay.toLowerCase() === tokenInfo.address.toLowerCase()) {
                      box.payapprove = approveBN / (10 ** tokenInfo.decimals)
                    }
                  }

                  BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                  createNotification({
                    type: 'success',
                    message: t('Infotip.approve-token-successful', { 'symbol': tokenInfo.symbol })
                  })
                  TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success')
                  closeModal()
                  openTopbox(index, ele['buyAmount'])
                })
                .catch((error: any) => {
                  TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade')
                  console.error(error)
                  BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                  createNotification({
                    type: 'error',
                    message: t('Infotip.approve-token-error', { 'symbol': tokenInfo.symbol })
                  })
                })
            })
            .catch((error: any) => {
              TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request')
              console.error(error)
              BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
              createNotification({
                type: 'error',
                message: t('Infotip.approve-token-error', { 'symbol': tokenInfo.symbol })
              })
            })
        }
      }
    }

    const showbox = async (hash: string) => {
      // hash = '0x78b3054b5086cc0421eb5d6ffb067df2ce474c731cc87b3392d3b5cdc5d69bbc'
      const url = `${API.boxkeyTxInfo}${pageChainConfig.chainId}/${hash}/`
      const data = (await axios.get(url)).data.data
      showModal(0, data.items)
      boxOpenId.value = -1
      boxOpenCatIndex.value = -1
      loadData()
    }

    const showTopbox = async (hash: string) => {
      // hash = '0x78b3054b5086cc0421eb5d6ffb067df2ce474c731cc87b3392d3b5cdc5d69bbc'
      const url = `${API.buyerboxTxInfo}${pageChainConfig.chainId}/${hash}/`
      const data = (await axios.get(url)).data.data
      const list = []
      for (const ele of data.items) {
        list.push(ele['obtainitem'])
      }
      showModal(0, list)
      loadData()
    }

    const showboxContent = (index: number) => {
      showModal(1, boxTypeInfoList.value[index])
    }

    const showTopBoxContent = (index: number) => {
      let boxResult
      const boxTop = boxList.value[index]
      for (const box of boxTypeInfoList.value) {
        if (boxTop['boxid'] === box.boxid) {
          boxResult = box
        }

      }
      showModal(1, boxResult)
    }

    const openTopbox = async (index: number, count: number = 1, openBox: boolean = false) => {
      TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-request')
      const { walletAddress } = toRefs(props)
      if (walletAddress.value.length > 0) {
        const box = boxList.value[index]
        // LogUtils.debug(box)
        const boxCount = Number(count).toString()
        const boxPrice = Number(box['price'])

        const ele = boxList.value[index]
        const flagApprove = await checkApprove(index)
        if (!flagApprove) {
          // createNotification({
          //     type: 'error',
          //     message: `Your token approve count is not enough`,
          // })
          showModal(3, index)
          return
        }
        const tokenInfo = {
          address: ele.pay,
          symbol: ele.paysymbol,
          decimals: ele.paydecimals,
          balance: ele.paybalance
        }

        if (openBox) {
          // count = 3
          boxTopOpenIndex.value = index
        } else {
          boxTopOpenIndex.value = -1
        }

        const action = openBox ? t('Infotip.action-open') : t('Infotip.action-buy')
        const actioning = openBox ? t('Infotip.action-opening') : t('Infotip.action-buying')

        if (box.buyAmount === 0) {
          createNotification({
            type: 'error',
            message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Infotip.error') })}${t('Common.colon')} ${t('Infotip.buy-amount-can-not-be-zero')}${t('Common.exclamation mark')}`
          })
          boxTopOpenIndex.value = -1
          return
        }

        if (tokenInfo.balance < boxPrice) {
          createNotification({
            type: 'error',
            message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Infotip.error') })}${t('Common.colon')} ${t('Infotip.your-token-balance-not-enough', { 'symbol': tokenInfo.symbol })}${t('Common.exclamation mark')}`
          })
          boxTopOpenIndex.value = -1
          return
        }

        const provider = await getWalletProvider()
        if (provider) {
          try {
            const signer = provider.getSigner()
            const contractBoxBuyer = new ethers.Contract(
              boxbuyerBoxListAPIInfo!.contract,
              NFTMysteryKeyBuyer,
              signer
            )
            // LogUtils.debug(`id: ${box['id']}`)
            const sendPromise = contractBoxBuyer.onBuyBatch(box['id'], count, openBox)


            const uuid = createNotification({
              message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': '' })}...`,
              autoClose: false,
              canClose: false
            })
            sendPromise
              .then(function (transaction: any) {
                //上报
                if (inviteBuyCode.value.length > 0) {
                  const hash = transaction.hash
                  const url = `${API.userBlindboxInviteCodeUploadByHash}${pageChainConfig.chainId}/${hash}/${inviteBuyCode.value}/`
                  axios.get(url)
                }

                transaction.wait().then(function (transactionResult: any) {
                  const blockRate = TOOL.getDynamicObject(pageChainConfig.chainSymbol, BLOCKCHAIN_CONFIG.BLOCK_RATE)
                  setTimeout(() => {
                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                    TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-success')
                    if (openBox) {
                      showTopbox(transactionResult.transactionHash)
                    } else {
                      createNotification({
                        type: 'success',
                        message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.successful') })}`
                      })
                      loadData()
                    }
                  }, 2 * 1000 * blockRate)
                })
              })
              .catch((error: any) => {
                TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-error-trade')
                console.error(error)
                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                createNotification({
                  type: 'error',
                  message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.error') })}`,
                  duration: 5
                })
                boxTopOpenIndex.value = -1
              })
          } catch (e) {
            TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-error-request')
            console.error(e)
            createNotification({
              type: 'error',
              message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.error') })}`,
            })
            boxTopOpenIndex.value = -1
          }
        }
      }
    }

    const openbox = async (index: number, isOpenAll: boolean = false, boxId: number = -1) => {
      TOOL.gtmTrack('openbox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'openbox-request')
      const { walletAddress } = toRefs(props)
      if (walletAddress.value.length > 0) {
        const box = boxTypeList.value[index]
        // LogUtils.debug(box)
        const boxCount = isOpenAll ? 'all' : '1'
        if (!isOpenAll) {
          boxOpenId.value = boxId
          boxOpenCatIndex.value = -1
        } else {
          boxOpenId.value = -1
          boxOpenCatIndex.value = index
        }
        const provider = await getWalletProvider()
        if (provider) {
          try {
            if (assetsAPIBoxkeyInfo.contract === '') {
              createNotification({
                type: 'info',
                message: `${t('Infotip.wait-try-again')}`,
              })
              return;
            }
            const signer = provider.getSigner()
            const contractBoxkey = new ethers.Contract(
              assetsAPIBoxkeyInfo!.contract,
              NFTMysteryKey,
              signer
            )
            const sendPromise = isOpenAll ? contractBoxkey.onBuyBatch(box.boxIdList) : contractBoxkey.onBuy(boxId)
            const uuid = createNotification({
              message: `${t('Infotip.action-open-buy-mysterybox', { 'action': t('Infotip.action-opening'), 'boxCount': boxCount, 'boxname': box.name, 'state': '' })}...`,
              autoClose: false,
              canClose: false
            })
            sendPromise
              .then(function (transaction: any) {
                transaction.wait().then(function (transactionResult: any) {
                  const blockRate = TOOL.getDynamicObject(pageChainConfig.chainSymbol, BLOCKCHAIN_CONFIG.BLOCK_RATE)
                  setTimeout(() => {
                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                    TOOL.gtmTrack('openbox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'openbox-success')
                    // createNotification({
                    //   type: 'success',
                    //   message: `Open mystery box successful`
                    // })
                    showbox(transactionResult.transactionHash)
                  }, 2 * 1000 * blockRate)
                })
              })
              .catch((error: any) => {
                TOOL.gtmTrack('openbox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'openbox-error-trade')
                console.error(error)
                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                createNotification({
                  type: 'error',
                  message: `${t('Infotip.action-open-buy-mysterybox', { 'action': t('Infotip.action-open'), 'boxCount': boxCount, 'boxname': box.name, 'state': t('Common.error') })}`,
                  duration: 5
                })

                boxOpenId.value = -1
                boxOpenCatIndex.value = -1

              })
          } catch (e) {
            TOOL.gtmTrack('openbox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'openbox-error-request')
            console.error(e)
            createNotification({
              type: 'error',
              message: `${t('Infotip.action-open-buy-mysterybox', { 'action': t('Infotip.action-open'), 'boxCount': boxCount, 'boxname': box.name, 'state': t('Common.error') })}`,
            })

            boxOpenId.value = -1
            boxOpenCatIndex.value = -1

          }
        }
      }
    }

    const transferBox = async () => {
      const boxId = transferBoxId

      const address = inputWalletAddressTransfer.value.trim()
      const flag = ethers.utils.isAddress(address)
      const boxAddress = contractBoxAssetsAddress.value

      if (!flag) {
        createNotification({
          type: 'error',
          message: "Your reciece wallet address is invaild",
          duration: 6
        })
        return
      } else {
        TOOL.gtmTrack('transfer-button-box', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-request-box`)
        const { walletAddress } = toRefs(props)
        if (walletAddress.value.length > 0) {
          const provider = await getWalletProvider()
          if (provider) {
            const signer = provider.getSigner()
            const contractToken = new ethers.Contract(
              boxAddress,
              ERC721,
              signer
            )
            const sendPromise = contractToken["safeTransferFrom(address,address,uint256)"](walletAddress.value, address, boxId)
            const uuid = createNotification({
              message: t('Infotip.action-transfer-token-mysterybox', [t('Infotip.action-transfering'), boxId, address, '']) + '...',
              autoClose: false,
              canClose: false
            })
            buttonDisabled.value = true
            sendPromise
              .then(async function (transaction: any) {
                transaction
                  .wait()
                  .then(async function (transaction: any) {
                    TOOL.gtmTrack('transfer-button-box', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-success-box`)
                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                    createNotification({
                      type: 'success',
                      message: t('Infotip.action-transfer-token-mysterybox', [t('Infotip.action-transfer'), boxId, address, t('Common.successful')]),
                    })
                    setTimeout(() => {
                      buttonDisabled.value = false
                      closeModal()
                      loadData()
                    }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)
                  })
                  .catch((error: any) => {
                    TOOL.gtmTrack('transfer-button-box', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-trade-box`)
                    console.error(error)
                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                    createNotification({
                      type: 'error',
                      message: t('Infotip.action-transfer-token-mysterybox', [t('Infotip.action-transfer'), boxId, address, t('Common.error')]),
                    })
                    buttonDisabled.value = false
                    closeModal()
                  })
              })
              .catch((error: any) => {
                TOOL.gtmTrack('transfer-button-box', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-request-box`)
                console.error(error)
                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                createNotification({
                  type: 'error',
                  message: t('Infotip.action-transfer-token-mysterybox', [t('Infotip.action-transfer'), boxId, address, t('Common.error')]),
                })
                buttonDisabled.value = false
                closeModal()
              })
          }
        }
      }
    }

    const random = (min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getPageDataBuyHistory = async (page: number) => {
      if (isMobile.value) {
        isLoadingMore.value = true
      }
      const { walletAddress } = toRefs(props)
      const urlboxkeyAPIHistoryList = `${API.buyerboxHistory}${pageChainConfig.chainId}/${walletAddress.value}/${page - 1}/${pagePerCount.value}`
      const boxOHDData = (await axios.get(urlboxkeyAPIHistoryList)).data.data
      const boxOHD = boxOHDData.list
      const time = currentTime.value
      pageNow.value = page
      recordCount.value = boxOHDData.count

      for (let trade of boxOHD) {
        let delta, deltaTime
        let buyblocknum = trade.buytxid
        if (buyblocknum === 0) {
          trade['buyTS'] = 0
        } else {
          delta = currentBlockNo - buyblocknum
          deltaTime = BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * delta
          trade['buyTS'] = Math.floor(time - deltaTime)
        }


        let obtainblocknum = trade.obtaintxid
        if (obtainblocknum > 0) {
          delta = currentBlockNo - obtainblocknum
          deltaTime = BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * delta
          trade['obtainTS'] = Math.floor(time - deltaTime)
        } else {
          trade['obtainTS'] = 0
        }

      }

      if (isMobile.value) {
        if (page === 1) {
          boxOpenHistoryData.value = boxOHD
        } else {
          boxOpenHistoryData.value = boxOpenHistoryData.value.concat(boxOHD)
        }
      } else {
        boxOpenHistoryData.value = boxOHD
      }



      if (isMobile.value) {
        isLoadingMore.value = false
      }
    }

    const loadData = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
      const projectConfig = TOOL.getConfigProject()
      const chainSymbol = await TOOL.getChainSymbol()
      const chainId = await TOOL.getChainId()
      const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
      const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
      const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
      const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

      pageChainConfig.projectConfig = projectConfig
      pageChainConfig.chainId = chainId
      pageChainConfig.chainSymbol = chainSymbol
      pageChainConfig.chainRPCConfig = chainRPCConfig
      pageChainConfig.chainABIConfig = chainABIConfig
      pageChainConfig.chainTokensConfig = chainTokensConfig
      pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
      pageChainConfig.multicallConfig = {
        rpcUrl: chainRPCConfig,
        multicallAddress: chainABIConfig.MULTICALL
      }

      // LogUtils.debug(pageChainConfig)

      const { walletAddress } = toRefs(props)

      //invitecode
      // const urlInviterBoard = `${API.inviteBlindboxBoard}${chainId}/`
      // inviterBoardList.value = (await axios.get(urlInviterBoard)).data.data.list

      // if (walletAddress.value.length > 0) {
      //   const urlInviteCode = `${API.userInviteCode}${chainId}/${walletAddress.value}/`
      //   const inviteCodeData = (await axios.get(urlInviteCode)).data.data
      //   inviteCode.value = inviteCodeData.code
      //   invitedBuyBoxCount.value = inviteCodeData.count
      // }

      const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)
      const currentBlock = (await jsonRPCProvider.getBlock('latest'))

      currentTime.value = currentBlock.timestamp

      const urlBuyerBoxList = `${API.buyerboxInfo}${chainId}/${walletAddress.value.length === 0 ? BLOCKCHAIN_CONFIG.ZERO_ADDRESS : walletAddress.value}`
      boxbuyerBoxListAPIInfo = (await axios.get(urlBuyerBoxList)).data.data


      const boxListData: any[] = boxbuyerBoxListAPIInfo.list
      contractBoxAssetsAddress.value = boxbuyerBoxListAPIInfo.assets
      walletwhitelist.value = boxbuyerBoxListAPIInfo.walletwhite

      if (boxListData.length > 0) {
        for (let ele of boxListData) {
          ele.buyAmount = 1
          ele.payapprove = 0
          ele.paybalance = 0
        }
        let mcFlag, tapFlag = false
        const tokenO = {
          MC: {
            balance: 0,
            symbol: 'MC',
            address: ''
          },
          TAP: {
            balance: 0,
            symbol: 'TAP',
            address: ''
          }
        }

        for (let i = 0; i < boxListData.length; i++) {
          const ele = boxListData[i]
          if (ele.paysymbol.indexOf('MC') >= 0 && !mcFlag) {
            tokenO.MC.address = ele.pay
            mcFlag = true
          } else if (ele.paysymbol.indexOf('TAP') >= 0 && !tapFlag) {
            tokenO.TAP.address = ele.pay
            tapFlag = true
          }
        }
        if (walletAddress.value.length > 0) {
          let calls: any[] = []
          let returnValue = null

          for (let i = 0; i < boxListData.length; i++) {
            const ele = boxListData[i]
            calls = calls.concat([
              {
                target: ele.pay,
                call: ['balanceOf(address)(uint256)', walletAddress.value],
                returns: [['PAYTOKENBOX_BALANCE_' + i, (val: any) => val / (10 ** ele.paydecimals)]]
              },
              {
                target: ele.pay,
                call: [
                  'allowance(address,address)(uint256)',
                  walletAddress.value,
                  boxbuyerBoxListAPIInfo!.contract
                ],
                returns: [['PAYTOKENBOX_APPROVE_' + i, (val: any) => val / (10 ** ele.paydecimals)]]
              }])
          }

          returnValue = await aggregateEvents(calls, pageChainConfig.multicallConfig)
          const returnResult = returnValue.results.transformed

          mcFlag = false
          tapFlag = false

          for (let i = 0; i < boxListData.length; i++) {
            const ele = boxListData[i]
            ele.payapprove = returnResult['PAYTOKENBOX_APPROVE_' + i]
            ele.paybalance = returnResult['PAYTOKENBOX_BALANCE_' + i]

            if (ele.paysymbol.indexOf('MC') >= 0 && !mcFlag) {
              tokenO.MC.balance = ele.paybalance
              mcFlag = true
            } else if (ele.paysymbol.indexOf('TAP') >= 0 && !tapFlag) {
              tokenO.TAP.balance = ele.paybalance
              tapFlag = true
            }
          }

          tokenObject.value = tokenO
        }
      }

      boxList.value = boxListData
      // const provider = await getWalletProvider()
      // if (provider) {
      isLoading.value = false
      if (isLoadingInit.value) {
        isLoadingInit.value = false
        ctx.emit('update', false)
      }
      nextTick(() => {
        // Loading should be closed asynchronously
        loadingInstance.close()
      })
      if (walletAddress.value.length > 0) {

        const urlboxkeyAPIOwnedList = `${API.boxkeyListowned}${chainId}/${walletAddress.value}`
        assetsAPIBoxkeyListOwned = (await axios.get(urlboxkeyAPIOwnedList)).data.data
        if (assetsAPIBoxkeyListOwned) {
          const ownedList = []
          for (const ele of assetsAPIBoxkeyListOwned.list.reverse()) {
            const boxItemList = ele['list']
            const count = ele['count']
            const name = ele['name']
            const idx = count === 0 ? -1 : random(0, count - 1)
            ownedList.push({
              name: name,
              boxOneId: count === 0 ? idx : boxItemList[idx],
              boxIdList: boxItemList,
              image: ele['image']
            })
          }
          boxTypeList.value = ownedList
        }


        const urlAPIBoxkeyInfo = `${API.boxkeyInfo}${chainId}/`
        assetsAPIBoxkeyInfo = (await axios.get(urlAPIBoxkeyInfo)).data.data
        boxTypeInfoList.value = assetsAPIBoxkeyInfo.list.reverse()

        // showbox('')

      }
      // }
      currentBlockNo = currentBlock.number

      if (walletAddress.value.length > 0) {
        getPageDataBuyHistory(1)
      }
    }

    return {
      boxTypeInfoList,
      getLowerCase,
      openbox,
      isLoading,
      isMobile,
      isShowModal,
      modalShowIndex,
      boxObtainList,
      showModal,
      closeModal,
      buttonDisabled,
      boxTypeList,
      boxContent,
      showboxContent,
      boxOpenId,
      boxList,
      openTopbox,
      showTopBoxContent,
      walletwhitelist,
      isEmptyMysteryBoxList,
      boxTopOpenIndex,
      approve,
      boxOpenCatIndex,
      getBoxPreviewName,
      serverHost,
      inviteCode,
      invitedBuyBoxCount,
      inviterBoardList,
      copyTextInvitation,
      watchInput,
      maxInput,
      tokenObject,
      contractBoxAssetsAddress,
      radioIndex,
      boxOpenHistoryData,
      formatTime,
      currentTime,
      countDownTime,
      getBoxState,
      getTXLink,
      divMyBoxListPC,
      divBuyHistoryPC,
      getBottomHeight,
      divMyBoxListPCHeight,
      divBuyHistoryPCHeight,
      getPageDataBuyHistory,
      recordCount,
      pageNow,
      pagePerCount,
      isLoadingMore,
      go2MyBoxListMobile,
      refMyBoxListMobile,
      gtmTrack,
      selecedBoxIndex,
      transferBox,
      inputWalletAddressTransfer,
      getApproveTokenAndNeedAmount
    }
  }

})
</script>
<style lang="stylus">
    #root,#app{
      height: auto
      // background-color: #1d1d26;
    }
  
  </style>