<template>
    <div class="page-nftheadinfo height-header-padding" style="position: relative;height: auto;">
        <el-dialog v-model="isShowModal" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div v-if="modalShowIndex === 8"
                class="mask-nft modalwindow d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.notice') }}</div>
                    <div style="word-break: break-word" class="infotip infotip-attention d-flex justify-content-center">
                        {{ modalInfotip }}
                    </div>
                    <div v-if="isShowLoginLink" style="margin-top: 40PX;" class="d-flex justify-content-center">
                        <a class="go2login" href="/#/login">{{ $t('Dialog.go-to-your-game-account-to-bind-your-wallet')
                        }}</a>
                    </div>
                    <button class="button-buy nftinfo" style="margin-top: 40PX;width:130PX" @click="closeModal()">{{
                            $t('Dialog.ok')
                    }}</button>

                </div>
            </div>
            <modal-approve v-else-if="modalShowIndex === 9" @confirm="approveNFT(indexApproveNFT)"
                @cancel="closeModal" />
            <div v-else-if="modalShowIndex === 11"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.transter') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="nft-image-block head">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('NFTInfo.your-nft-will-be-transterd-to-another-wallet-address') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.recievewalletaddress') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputWalletAddressTransfer" type="text" placeholder=""
                                    style="text-align: left" maxlength="42" />
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="transfer()">
                                    {{ $t('Dialog.transter') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
        </el-dialog>
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>

                        <div class="content-up">
                            <div class="left d-flex flex-column">
                                <div class="nft-state" v-if="belongto === 'forsell' || isOwner">
                                    <div class="anim"></div>
                                    <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                                </div>
                                <div class="nft-image-block head">
                                    <img :src="nftInfo['medium']" class="nft-image" />
                                </div>
                            </div>
                            <div class="right">
                                <template v-if="belongto !== 'wallet'">
                                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                                        <template v-if="belongto === 'ingame'">
                                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                                        </template>
                                        <template v-else>
                                            {{
                                                    $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time')
                                            }}
                                        </template>
                                    </div>
                                </template>
                                <template v-if="belongto === 'wallet' && isOwner">
                                    <!-- <div class="title-cat">Action</div> -->
                                    <div class="action-block">
                                        <button class="button-buy" :disabled="true">{{ $t('NFTInfo.sell') }}</button>
                                        <button v-if="!nftApprove.ingame" @click="showModal(9, 2)" class="button-buy"
                                            :disabled="buttonDisabled">{{ $t('NFTInfo.charge-game') }}</button>
                                        <button v-else @click="charge()" class="button-buy"
                                            :disabled="buttonDisabled">{{
                                                    $t('NFTInfo.charge-game')
                                            }}</button>
                                        <button class="button-buy" :disabled="buttonDisabled" @click="showModal(11)">
                                            {{ $t('Dialog.transter') }}
                                        </button>

                                    </div>
                                </template>
                                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                                    <span class="text">#{{ tokenId }}</span>
                                </div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                                    <span class="text">{{ nftInfo.name }}</span>
                                </div>
                                <!-- <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div> -->
                                <!-- 
                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                                <div class="d-flex align-items-center justify-content-end">
                                    <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                            nftInfo['level']
                                    }}/5</span>
                                </div>
                            </div> -->
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                                    <span class="text">{{ owner }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="content-down">
                            <div class="title">{{ $t('NFTInfo.properties') }}</div>
                            <div class="attr-list d-flex">

                                <div v-for="(attr, attrIndex) of nftInfo.attrs" class="item">
                                    <img :src="attr.url" />
                                    <span class="attr-type">
                                        {{ attr.type }}
                                    </span>
                                    <!-- <span class="attr-value">
                                        {{ attr.value }}
                                    </span> -->

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{{ $t('Dialog.back') }}</span>
                </div>
                <div class="top-block">
                    <div class="nft-state"
                        v-if="(belongto === 'forsell' || belongto === 'forrent' || belongto === 'rentin' || belongto === 'rentout') || isOwner">
                        <div class="anim"></div>
                        <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                    </div>
                </div>
                <div class="nft-image-block head">
                    <img :src="nftInfo['medium']" class="nft-image" />
                </div>
                <div class="properties">{{ $t('NFTInfo.properties') }}</div>
                <div class="attr-list d-flex">
                    <div v-for="(attr, attrIndex) of nftInfo.attrs" class="item">
                        <img :src="attr.url" />
                        <span class="attr-type">
                            {{ attr.type }}
                        </span>
                        <!-- <span class="attr-value">
                            {{ attr.value }}
                        </span> -->

                    </div>
                </div>
                <template v-if="belongto !== 'wallet'">
                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                        <template v-if="belongto === 'ingame'">
                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                        </template>
                        <template v-else>
                            {{
                                    $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time')
                            }}
                        </template>
                    </div>
                </template>
                <template v-if="belongto !== 'wallet'">
                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                        <template v-if="belongto === 'ingame'">
                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                        </template>
                        <template v-else>
                            {{ $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time') }}
                        </template>

                    </div>
                </template>
                <template v-if="belongto === 'wallet' && isOwner">
                    <!-- <div class="title-cat">Action</div> -->
                    <div class="action-block">
                        <button class="button-buy" :disabled="true">{{ $t('NFTInfo.sell') }}</button>
                        <button v-if="!nftApprove.ingame" @click="showModal(9, 2)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('Infotip.action-charge') }}</button>
                        <button v-else @click="charge()" class="button-buy" :disabled="buttonDisabled">{{
                                $t('Infotip.action-charge')
                        }}</button>
                        <button class="button-buy" :disabled="buttonDisabled" @click="showModal(11)">
                            {{ $t('Dialog.transter') }}
                        </button>

                    </div>
                </template>
                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                    <span class="text">#{{ tokenId }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                    <span class="text">{{ nftInfo.name }}</span>
                </div>
                <!-- <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.character') }}</span>
                    <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                </div> -->

                <!-- <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                    <div class="d-flex align-items-center justify-content-end">
                        <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                nftInfo['level']
                        }}/5</span>
                    </div>
                </div> -->
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                    <div class="address">
                        <div class="inner">
                            {{ owner }}
                        </div>
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateNFTInfo } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElTooltip } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
import ERC20 from '../abis/ERC20.json'
import ERC721 from '../abis/ERC721.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import NFTInGameAcc from '../abis/NFTInGameAcc.json'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'

export default defineComponent({
    name: 'NFTHeadInfo',
    components: { ElButton, ElLoading },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        if (isShowModal.value) {
                            closeModal()
                        }
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        const serverHost = ref(BLOCKCHAIN_CONFIG.serverHost)
        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isMobile = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const inputAmountBuy = ref(1)
        const inputWalletAddressTransfer = ref('')
        const belongto = ref('')
        const owner = ref('')
        const isOwner = ref(false)
        const historyIndex = ref('0')
        const historyIndexNumber = ref(0)
        const indexApproveNFT = ref(-1)
        const tokenId = ref(0)
        let nftHeadInfoAPIData: {
            nftrechargecontract: string
            nftassets: string
            rechargefee: number
            tokenid: number
            chainid: number
            belongto: string
            tag: string
            owner: string
            info: {
                image: string,
                preview: string,
                attrs: any[],
                medium: string,
                name: string
            }
            saletoken: string
            saletokensymbol: string
            saledecimals: number
            salefeeRate: number
            salegoodsid: number

        } | null = null
        const gameAccount = ref('')
        const nftApprove = ref({
            market: false,
            ingame: false,
            lending: false
        })
        let assetsAPIInfo = {
            goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
            mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            wallet: { accountid: 0, address: '', nickname: '' },
        }
        const nftInfo = ref({
            image: '',
            preview: '',
            attrs: [] as any,
            medium: '',
            name: ''
        })

        interface goodsPayToken {
            approve: number,
            balance: string
            decimals: number,
            address: string,
            symbol: string,
            feerate: number
            price: number
        }
        const goodsPaySale: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })

        const rechargeInfo: Ref<any> = ref({
            fee: 0
        })

        const nftStateMap = {
            forsell: 'For Sale',
            rentout: 'Rent Out',
            rentin: 'Rent In',
            wallet: 'Collection',
            ingame: 'In Game',
            transaction: 'Transacting',
            forrent: 'For Rent',
        }

        const saleHistoryList = ref([])
        const inputAmount = ref('1')

        const modalInfotip = ref('')
        const isShowLoginLink = ref(false)
        let loadingInstance: any = null
        const pageSrcPara = ref({
            src: '',
            type: -1
        })

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })

            const tokenid = router.currentRoute.value.params.id
            tokenId.value = Number(tokenid)

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
        })

        const showModal = (modalIdx: number, indexANFT: number = -1) => {
            inputAmount.value = '1'
            isShowModal.value = true
            modalShowIndex.value = modalIdx
            indexApproveNFT.value = indexANFT
        }

        const closeModal = () => {
            isShowModal.value = false
            modalShowIndex.value = -1
            modalInfotip.value = ''
            isShowLoginLink.value = false
            inputAmount.value = '1'
        }

        const changeRadioGroup = () => {
            historyIndexNumber.value = parseInt(historyIndex.value, 10)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const approveNFT = async (optType: number) => {
            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-request-nft`)
            const { walletAddress } = toRefs(props)
            let approveAddr = ''
            switch (optType) {
                // case 0:
                //     approveAddr = nftHeadInfoAPIData!.marketcontract
                //     break;
                // case 1:
                //     approveAddr = nftHeadInfoAPIData!.lendingcontract
                //     break;
                case 2:
                    approveAddr = nftHeadInfoAPIData!.nftrechargecontract
                    break;
                default:
                    break;
            }
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        nftHeadInfoAPIData!.nftassets,
                        ERC721,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-nft'),
                        autoClose: false,
                        canClose: false
                    })
                    contractToken
                        .setApprovalForAll(approveAddr, true)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.isApprovedForAll(
                                        walletAddress.value,
                                        approveAddr
                                    )
                                    closeModal()
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-nft-successful')
                                    })
                                    switch (optType) {
                                        case 0:
                                            nftApprove.value.market = true
                                            showModal(5)
                                            break;
                                        case 1:
                                            nftApprove.value.lending = true
                                            if (belongto.value === 'wallet') {
                                                showModal(6)
                                            } else if (belongto.value === 'forrent') {
                                                showModal(7)
                                            }

                                            break;
                                        case 2:
                                            nftApprove.value.ingame = true
                                            charge()
                                            break;
                                        default:
                                            break;
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-trade`)
                                    console.error(error)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-nft-error')
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-request`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-nft-error')
                            })
                        })
                }
            }
        }

        const clearNoNum = (numStr: string) => {
            numStr = numStr.replace(/[^\d\.]/g, "");  //清除“数字”和“.”以外的字符
            numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const clearNoNumInteger = (numStr: string) => {
            numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
            numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const watchInput = () => {
            const price = inputAmount.value
            let input_num_str = price.toString()
            input_num_str = clearNoNum(input_num_str)
            inputAmount.value = input_num_str
        }

        const charge = async () => {
            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-request-nft`)
            const tokenid = nftHeadInfoAPIData!.tokenid
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    if (typeof gameAccount.value === 'undefined' || gameAccount.value.length === 0) {
                        modalInfotip.value = `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.before-charging-nft-please-bind-your-wallet-address-to-your-game-account')}`
                        isShowLoginLink.value = true
                        showModal(8)
                        return
                    }

                    const feeNumber = Number(nftHeadInfoAPIData?.rechargefee)
                    // const options = { value: ethers.utils.parseEther(feeNumber.toString()) }
                    const gas = await provider.getBalance(walletAddress.value)
                    const gasNumber = new BigNumber(gas.toString()).div(10 ** 18).toNumber()

                    // LogUtils.debug(gasNumber)
                    // LogUtils.debug(feeNumber)

                    if (gasNumber < feeNumber) {
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.not-enough-bnb-available-as-gas-to-transfer')}`
                        })
                        return
                    }

                    const signer = provider.getSigner()
                    const contractIngame = new ethers.Contract(
                        nftHeadInfoAPIData!.nftrechargecontract,
                        NFTInGameAcc,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charging'), tokenid, '']) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    buttonDisabled.value = true

                    contractIngame
                        .lockInGame(0, tokenid)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.successful')])
                                    })
                                    buttonDisabled.value = false
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                                    })
                                    buttonDisabled.value = false
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                            })
                            buttonDisabled.value = false
                        })
                }
            }
        }

        const transfer = async () => {
            const address = inputWalletAddressTransfer.value.trim()
            const flag = ethers.utils.isAddress(address)

            if (!flag) {
                createNotification({
                    type: 'error',
                    message: "Your reciece wallet address is invaild",
                    duration: 6
                })
                return
            } else {
                TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-request-nfthead`)
                const { walletAddress } = toRefs(props)
                if (walletAddress.value.length > 0) {
                    const provider = await getWalletProvider()
                    if (provider) {
                        const signer = provider.getSigner()
                        const contractToken = new ethers.Contract(
                            nftHeadInfoAPIData!.nftassets,
                            ERC721,
                            signer
                        )
                        const sendPromise = contractToken["safeTransferFrom(address,address,uint256)"](walletAddress.value, address, nftHeadInfoAPIData!.tokenid)
                        const uuid = createNotification({
                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfering'), nftHeadInfoAPIData!.tokenid, address, '']) + '...',
                            autoClose: false,
                            canClose: false
                        })
                        buttonDisabled.value = true
                        sendPromise
                            .then(async function (transaction: any) {
                                transaction
                                    .wait()
                                    .then(async function (transaction: any) {
                                        TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-success-nfthead`)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'success',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftHeadInfoAPIData!.tokenid, address, t('Common.successful')]),
                                        })
                                        setTimeout(() => {
                                            buttonDisabled.value = false
                                            closeModal()
                                            loadData()
                                        }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)
                                    })
                                    .catch((error: any) => {
                                        TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-trade-nfthead`)
                                        console.error(error)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'error',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftHeadInfoAPIData!.tokenid, address, t('Common.error')]),
                                        })
                                        buttonDisabled.value = false
                                        closeModal()
                                    })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-request-nfthead`)
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftHeadInfoAPIData!.tokenid, address, t('Common.error')]),
                                })
                                buttonDisabled.value = false
                                closeModal()
                            })


                    }

                }


            }



        }

        const goBack = () => {
            router.back()
        }

        const loadData = async () => {
            buttonDisabled.value = true
            // if (loadingInstance === null) {
            loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            // }

            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)

            const { walletAddress } = toRefs(props)
            LogUtils.debug(walletAddress.value)

            const wa = walletAddress.value.length > 0 ? walletAddress.value : BLOCKCHAIN_CONFIG.ZERO_ADDRESS

            const urlNFTInfo = `${API.nftHeadInfo}${chainId}/${tokenId.value}/${wa}/`
            nftHeadInfoAPIData = (await axios.get(urlNFTInfo)).data.data
            if (nftHeadInfoAPIData) {
                nftInfo.value = nftHeadInfoAPIData.info
                belongto.value = nftHeadInfoAPIData.belongto
                isOwner.value = walletAddress.value.toLowerCase() === nftHeadInfoAPIData.owner.toLowerCase()
                owner.value = nftHeadInfoAPIData.owner
                // tokenId.value = nftHeadInfoAPIData.tokenid

                const paySale: goodsPayToken = {
                    approve: 0,
                    balance: '',
                    decimals: nftHeadInfoAPIData.saledecimals,
                    address: nftHeadInfoAPIData.saletoken,
                    symbol: nftHeadInfoAPIData.saletokensymbol,
                    feerate: nftHeadInfoAPIData.salefeeRate,
                    price: 0
                }

                if (walletAddress.value.length > 0) {
                    const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`

                    axios.get(urlAssetsAPIInfo)
                        .then(async (response) => {
                            assetsAPIInfo = response.data.data
                            gameAccount.value = assetsAPIInfo!.wallet.nickname

                            buttonDisabled.value = false
                        })
                        .catch((e) => {

                        })

                    // assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
                } else {
                    goodsPaySale.value = paySale
                    // goodsPayLend.value = payLending
                }

                if (isOwner.value) {
                    let calls: any[] = []
                    calls = calls.concat([
                        {
                            target: nftHeadInfoAPIData.nftassets,
                            call: [
                                'isApprovedForAll(address,address)(bool)',
                                walletAddress.value,
                                nftHeadInfoAPIData.nftrechargecontract
                            ],
                            returns: [['NFT_APPROVE_INGAME', (val: any) => val]]
                        }
                    ])

                    const returnValue = await aggregateNFTInfo(
                        calls,
                        pageChainConfig.multicallConfig
                    )

                    nftApprove.value.ingame = returnValue.results.transformed.NFT_APPROVE_INGAME
                }

                LogUtils.debug('Loading end')

                isLoading.value = false

                if (isLoadingInit.value) {
                    isLoadingInit.value = false
                    ctx.emit('update', false)
                }
                nextTick(() => {
                    // Loading should be closed asynchronously
                    loadingInstance.close()
                })
            } else {
                nextTick(() => {
                    // Loading should be closed asynchronously
                    loadingInstance.close()
                })
            }

        }

        return {
            buttonDisabled,
            isLoading,
            isShowModal,
            modalShowIndex,
            isMobile,
            nftInfo,
            getTime,
            inputAmountBuy,
            goodsPaySale,
            belongto,
            nftStateMap,
            isOwner,
            owner,
            historyIndex,
            historyIndexNumber,
            changeRadioGroup,
            saleHistoryList,
            closeModal,
            showModal,
            gameAccount,
            approveNFT,
            nftApprove,
            inputAmount,
            modalInfotip,
            charge,
            watchInput,
            indexApproveNFT,
            router,
            isShowLoginLink,
            goBack,
            pageSrcPara,
            inputWalletAddressTransfer,
            transfer,
            tokenId
        }
    }
})
</script>
<style lang="stylus">
.box-item {
//   width: 110PX;
//   margin-top: 10PX;
    margin-left: 4PX;
    width: 16PX;
        height: 16PX;
}

.el-icon.box-item{
    cursor pointer
    svg{
        width: 16PX;
        height: 16PX;
    }
}
</style>