<template>
    <a target="_blank"
        :href="icon === 'bsc' ? 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x59C49B54D4425C7fF1E49f40958a14d48cC87c26' : 'https://app.ston.fi/swap?ft=Magic+%E2%80%A6&tt=TON&fa=1'">
        <div class="bottom-wrap">
            <div class="bottom"></div>
            <div class="bottom-text">
                <span>Buy $MC</span>
                <template v-if="icon.length > 0">
                    <img v-if="icon === 'bsc'" src="/static/images/icon-bnbchain-buymc-top.png" />
                    <img v-else-if="icon === 'ton'" src="/static/images/icon-tonchain-buymc-top.png" />
                </template>
            </div>
        </div>
    </a>
</template>
<script lang="ts" setup>
import { defineProps, ref, onMounted, toRefs } from 'vue'

interface Props {
    icon?: string
}

const props = withDefaults(defineProps<Props>(), {
    icon: ''
})

const { icon } = toRefs(props);
</script>

<style scoped lang="stylus">

@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}
.bottom-wrap {
    cursor: pointer;
    margin: 0 auto;
    // background: #212121;
    width: 200px;
    height: 50px;
    line-height: 50px;
    /* Sketch doesnt export gradient borders at this point */
    border-radius: 6px;
    font-family: Avenir-Black;
    font-size: 24px;
    color: #765C24;
    letter-spacing: 0;
    text-align: center;
    background: linear-gradient(-90deg, #ED6464, #F18628, #E6CF14, #4BC10F, #40CCCE, #877EFF,#9215C2);
    background-size: 400% 400%;
    animation: Gradient 7s ease infinite;
    position: relative;
    .bottom{
        width: 196px;
        height: 46px;
        background: #212121;
        // -webkit-background-clip: text;
        position: absolute;
        z-index: 0;
        left : 2px;
        top: 1.5px;
        border-radius: 4px;
    }
    .bottom-text{
        position: absolute;
        z-index: 1;
        width: 200px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: transparent;
        background: linear-gradient(-90deg, #AF2B1C, #FF8015, #63D67F, #72A7FE,#FF4EE9);
        -webkit-background-clip: text; /*截取背景区域为文字*/
        color: transparent;
        background-size: 300% 100%; /*扩大背景区域*/	
        animation: Gradient 7s ease infinite;

        img{
            width: 24px;
            margin-left: 16px;
        }
    }
}
</style>

<style scoped lang="stylus">

@media screen and (max-width: 1140px) {
    
    .bottom-wrap {
        width: 160rem;
        height: 40rem;
        line-height: 40rem;
        border-radius: 6rem;
        font-family: Avenir-Black;
        font-size: 24px;
        color: #765C24;
        letter-spacing: 0;
        text-align: center;
        
        .bottom{
            
            width: 156rem;
            height: 36rem;
            left : 2rem;
            top: 2rem;
            border-radius: 4rem;
        }
        .bottom-text{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Avenir-Black;
            font-size: 20rem;
            line-height: 40rem;
            width: 160rem;
            height: 40rem;
            img{
                width: 16rem;
                margin-left: 10rem;
            }
        }
    }
    
}

</style>