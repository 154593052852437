<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content d-flex flex-column align-items-center">
      <span class="title">{{ $t('Login.reset-your-password') }}</span>
      <template v-if="!isResetPass">
        <div class="input-item first d-flex flex-column">
          <span class="input-title">{{ $t('Login.verify-code') }}</span>
          <div class="input-block d-flex align-items-center">
            <input :placeholder="$t('Login.please-input-pass-verify-code')" ref="regcode"
              v-model.trim="registerForm.seccode" style="flex: 1;" />
          </div>
        </div>
        <div class="input-item d-flex flex-column">
          <span class="input-title">{{ $t('Login.password') }}</span>
          <div class="input-block d-flex align-items-center">
            <input :placeholder="$t('Login.please-enter-password')" ref="password" autocomplete="new-password"
              :type="passwordType" v-model="registerForm.password" style="flex: 1;" />
          </div>
        </div>
        <div class="input-item d-flex flex-column">
          <span class="input-title">{{ $t('Login.confirm-password') }}</span>
          <div class="input-block d-flex align-items-center">
            <input :placeholder="$t('Login.please-confirm-password')" ref="password2" autocomplete="new-password"
              :type="passwordType" v-model="password2" style="flex: 1;" />
          </div>
        </div>
        <div class="link-block d-flex align-items-center justify-content-end">
          <a href="/#/login">{{ $t('Login.sign-in-to-game-account') }}</a>
        </div>
        <span class="error-block login">
          {{ errorMsg }}
        </span>
        <button class="button signin" @click="handleSetNewpassword">{{ $t('Login.set-new-password') }}</button>
      </template>
      <template v-else>
        <div class="item d-flex align-items-center">
          <span class="text">{{ $t('Login.your-game-account') }}{{ $t('Common.colon') }}</span>
          <span class="text yellow">{{ userName }}</span>
        </div>
        <div class="link-block d-flex align-items-center justify-content-end">
          <a href="/#/login">{{ $t('Login.sign-in-to-tap-fantasy') }}</a>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import { useRouter } from 'vue-router'
import md5 from 'js-md5'
import { setNewPassByEmail } from '@/api/account'
import { tokenKey } from '@/settings'
import { _setCookie } from '@/utils/storage'
import { ElMessage } from 'element-plus'

export default {
  name: 'Resetpass',
  data() {
    return {
      styleObj: {
        width: '100%',
        color: 'white',
        'border-radius': '0px',
        'font-size': '2vh',
        'min-height': '5vh',
        border: 'none',
        'border-bottom': '1px solid white'
      },
      passwordType: 'password',
      loading: false,
      password2: '',
      registerForm: {
        password: '',
        seccode: ''
      },
      isResetPass: false,
      errorMsg: '',
      userName: '',
      router: useRouter(),
    }
  },
  props: {
    walletAddress: ''
  },
  created() {
    // const inviter = this.$route.query && this.$route.query.inviter
    // this.registerForm.inviter = inviter
  },
  methods: {
    login() {
      this.router.push({ name: 'Login' })
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleSetNewpassword() {
      const that = this
      this.loading = true
      this.errorMsg = ''
      if (this.validate()) {
        this.registerForm.password = this.getmd5(this.registerForm.password)
        this.password2 = ''
        setNewPassByEmail(this.registerForm)
          .then((response) => {
            that.isResetPass = true
            const { userName } = response.data
            that.userName = userName
            ElMessage({ message: this.$t('Login.reset-pass-successfull'), type: 'success' })
          })
          .catch((error) => {
            that.errorMsg = error
            // that.registerForm.password = ''
            // that.password2 = ''
            return false
          })
      }
    },
    validateSecCode(value) {
      if (!value) {
        return this.$t('Login.reset-pass-seccode-is-empty-try-again')
      }
      return ''
    },
    validatePassword() {
      if (!this.registerForm.password) {
        return this.$t('Login.password-is-empty-try-again')
      }
      if (this.registerForm.password.length < 8) {
        return this.$t('Login.password-should-use-8-or-more-characters-with-a-mix-of-letters-numbers-and-symbols')
      }
      if (this.registerForm.password !== this.password2) {
        return this.$t('Login.those-passwords-didnt-match-try-again')
      }
      return ''
    },
    validate() {
      if (this.validateSecCode(this.registerForm.seccode)) {
        this.errorMsg = this.validateSecCode(this.registerForm.seccode)
        return false
      }
      if (this.validatePassword()) {
        this.errorMsg = this.validatePassword()
        return false
      }
      return true
    },
    getmd5(str) {
      return md5(str)
    }
  }
}
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
