<template>
    <div class="mc-real-wrap mobile-card">
        <div class="mc-real" ref="swiperCharts">
            <div class="title font-LondonTwo">$MC {{ $t('HomeNew.real-time') }}</div>
            <div class="info-wrap">
                <div class="supply-info" :class="activeIndex == 0 ? 'active' : ''" @click="setSwiperIndex(0)">
                    <div class="value">{{ props.data.todaysupply }}</div>
                    <div class=text>
                        {{ $t('HomeNew.daily-supply') }}
                        <el-tooltip placement="top">
                            <template #content> {{ $t('HomeNew.daily-supply-toast-line1') }}<br />
                                {{ $t('HomeNew.daily-supply-toast-line2') }} </template>
                            <span class="tooltip-icon">
                                <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
                                        <g transform="translate(-604.000000, -2396.000000)">
                                            <g transform="translate(604.000000, 2396.000000)">
                                                <circle fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
                                                <path
                                                    d="M8.04659498,9.16666667 L8.33333333,3.33333333 L6.66666667,3.33333333 L6.93548387,9.16666667 L8.04659498,9.16666667 Z M7.49311295,11.6666667 C7.72727273,11.6666667 7.9338843,11.5826331 8.09917355,11.4285714 C8.25068871,11.2745098 8.33333333,11.0784314 8.33333333,10.8263305 C8.33333333,10.5882353 8.25068871,10.3921569 8.09917355,10.2380952 C7.9338843,10.070028 7.72727273,10 7.49311295,10 C7.25895317,10 7.0661157,10.070028 6.90082645,10.2380952 C6.73553719,10.3921569 6.66666667,10.5882353 6.66666667,10.8263305 C6.66666667,11.0644258 6.73553719,11.2605042 6.90082645,11.4285714 C7.0661157,11.5826331 7.25895317,11.6666667 7.49311295,11.6666667 Z"
                                                    fill="#000000" fill-rule="nonzero"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </el-tooltip>
                    </div>
                </div>
                <div class="supply-info" :class="activeIndex == 1 ? 'active' : ''" @click="setSwiperIndex(1)">
                    <div class="value">{{ new BigNumber(props.data.mcsupplypool).minus(props.data.mcyieldtotalamount).toFixed(2) }}</div>
                    <div class=text>
                        Mining Pool
                        <Tooltip :message="['Mining Pool Remaining=MC Supply Pool-MC Yield Output','When there is not enough MC in mining pool, the daily supply becomes 500', '*MC supply data starts on May 11th']" />
                    </div>
                </div>
                <div class="info" v-if="isShow">
                    <div class="active-wrap">
                        <div class="active-item" :style="{ left: left + '%' }"></div>
                    </div>
                    <div class="item" @click="setSwiperIndex(2)">
                        <div class=text>
                            <count-to :endVal='parseFloat(props.data.totaloutflow)'
                                :decimals="getDecimal(props.data.totaloutflow)"
                                :suffix="getSuffix(props.data.totaloutflow)" />
                        </div>
                        <div class="desc">
                            {{ $t('HomeNew.total-outflow') }}
                            <el-tooltip placement="top">
                                <template #content> {{ $t('HomeNew.total-outflow-toast') }} </template>
                                <span class="tooltip-icon">
                                    <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                            opacity="0.5">
                                            <g id="画板备份" transform="translate(-809.000000, -2396.000000)">
                                                <g id="编组-15" transform="translate(809.000000, 2396.000000)">
                                                    <circle id="椭圆形" fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
                                                    <path
                                                        d="M8.04659498,9.16666667 L8.33333333,3.33333333 L6.66666667,3.33333333 L6.93548387,9.16666667 L8.04659498,9.16666667 Z M7.49311295,11.6666667 C7.72727273,11.6666667 7.9338843,11.5826331 8.09917355,11.4285714 C8.25068871,11.2745098 8.33333333,11.0784314 8.33333333,10.8263305 C8.33333333,10.5882353 8.25068871,10.3921569 8.09917355,10.2380952 C7.9338843,10.070028 7.72727273,10 7.49311295,10 C7.25895317,10 7.0661157,10.070028 6.90082645,10.2380952 C6.73553719,10.3921569 6.66666667,10.5882353 6.66666667,10.8263305 C6.66666667,11.0644258 6.73553719,11.2605042 6.90082645,11.4285714 C7.0661157,11.5826331 7.25895317,11.6666667 7.49311295,11.6666667 Z"
                                                        id="！" fill="#000000" fill-rule="nonzero"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </el-tooltip>

                        </div>
                    </div>
                    
                    <div class="item not-first" @click="setSwiperIndex(3)">
                        <div class=text>
                            <count-to :endVal='parseFloat(props.data.vesting)'
                                :decimals="getDecimal(props.data.vesting)" :suffix="getSuffix(props.data.vesting)" />
                        </div>
                        <div class="desc">{{ $t('HomeNew.vesting-time') }}</div>
                    </div>
                    <div class="item not-first" @click="setSwiperIndex(4)">
                        <div class=text>
                            <count-to :endVal='parseFloat(props.data.yieldrate)'
                                :decimals="getDecimal(props.data.yieldrate)"
                                :suffix="getSuffix(props.data.yieldrate)" />
                        </div>
                        <div class="desc">{{ $t('HomeNew.yield-rate') }}</div>
                    </div>
                    <div class="item not-first" @click="setSwiperIndex(5)">
                        <div class=text>$
                            <count-to :endVal='parseFloat(props.data.baseprice)'
                                :decimals="getDecimal(props.data.baseprice)"
                                :suffix="getSuffix(props.data.baseprice)" />
                        </div>
                        <div class="desc">MC {{ $t('HomeNew.basic-price') }}</div>
                    </div>
                </div>

                <div class="supply-info-mobile">
                    MC {{ $t('HomeNew.daily-supply') }} {{ props.data.todaysupply }}
                    <el-tooltip placement="top">
                        <template #content> {{ $t('HomeNew.daily-supply-toast-line1') }}<br />
                            {{ $t('HomeNew.daily-supply-toast-line2') }} </template>
                        <span class="tooltip-icon">
                            <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                                    opacity="0.5">
                                    <g id="base" transform="translate(-604.000000, -2396.000000)">
                                        <g id="rr-15" transform="translate(604.000000, 2396.000000)">
                                            <circle id="shape" fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
                                            <path
                                                d="M8.04659498,9.16666667 L8.33333333,3.33333333 L6.66666667,3.33333333 L6.93548387,9.16666667 L8.04659498,9.16666667 Z M7.49311295,11.6666667 C7.72727273,11.6666667 7.9338843,11.5826331 8.09917355,11.4285714 C8.25068871,11.2745098 8.33333333,11.0784314 8.33333333,10.8263305 C8.33333333,10.5882353 8.25068871,10.3921569 8.09917355,10.2380952 C7.9338843,10.070028 7.72727273,10 7.49311295,10 C7.25895317,10 7.0661157,10.070028 6.90082645,10.2380952 C6.73553719,10.3921569 6.66666667,10.5882353 6.66666667,10.8263305 C6.66666667,11.0644258 6.73553719,11.2605042 6.90082645,11.4285714 C7.0661157,11.5826331 7.25895317,11.6666667 7.49311295,11.6666667 Z"
                                                id="！" fill="#000000" fill-rule="nonzero"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </el-tooltip>
                </div>

            </div>

            <div class="swiper-wrap">
                <swiper :pagination="isMobile" :navigation="!isMobile" :modules="modules" class="mySwiper"
                    @slideChange="onSlideChange" @swiper="setControlledSwiper">
                    <swiper-slide>
                        <div class="item">
                            <v-chart :option="getDailyOption()" autoresize></v-chart>
                        </div>
                        <div class="swiper-title">{{ $t('HomeNew.daily-supply') }}</div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <div style="height: 1PX;"></div>
                            <div class="mining-pool">
                                <v-chart :option="getMiningPoolOption()" autoresize></v-chart>
                                <div class="mining-pool-desc-wrap">
                                    <div class="mining-pool-value">{{ new BigNumber(props.data.mcyieldtotalamount.toFixed(2)).toFormat() }}/{{ new BigNumber(props.data.mcsupplypool.toFixed(2)).toFormat() }}</div>
                                    <div class="mining-pool-desc">Yield Output/Supply Pool</div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-title">MC Supply Pool and Remaining Real-time Monitoring</div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <v-chart :option="getTotalOutOption()" autoresize></v-chart>
                        </div>
                        <div class="swiper-title">
                            <div>
                                {{ $t('HomeNew.mc-total-outflow-monthly') }}
                            </div>
                            <div class="toggle" >
                                <div @click="()=>totalOutflowChartType = 'monthlylist' " class="toggle-detail" :class="totalOutflowChartType == 'monthlylist' ? 'active' : ''">
                                    <div class="round">•</div>
                                    <div >Monthly</div>
                                </div>

                                <div class="toggle-detail"  @click="()=>totalOutflowChartType = 'dailylist' " :class="totalOutflowChartType == 'dailylist' ? 'active' : '' ">
                                    <div class="round">•</div>
                                    <div >Daily</div>
                                </div>
                            </div>
                            
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="item">
                            <v-chart :option="getVestingOptino()" autoresize></v-chart>
                        </div>
                        <div class="swiper-title">{{ $t('HomeNew.vesting-time-related-to-mc-total-outflow') }}</div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="item">
                            <v-chart :option="getYieldRateOptino()" autoresize></v-chart>
                        </div>
                        <div class="swiper-title">{{ $t('HomeNew.yield-rate-desc') }}</div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="item">
                            <v-chart :option="getMcBasicOptino()" autoresize></v-chart>
                        </div>
                        <div class="swiper-title">MC {{ $t('HomeNew.basic-price-desc') }}</div>
                    </swiper-slide>

                </swiper>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import * as echarts from 'echarts';
import { ref, watch, defineProps, onMounted } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper'
import { MC_REAL_LINE_BASE_OPTION, MC_REAL_BAR_BASE_OPTION, MC_REAL_LINE_BASE_OPTION_MOBILE, MC_REAL_BAR_BASE_OPTION_MOBILE, MC_MINING_POOL_OPTION } from '../config/chartOption'
import { useElementVisibility } from '@vueuse/core'
import 'swiper/css';
import 'swiper/css/navigation';
import { cloneDeep } from 'lodash'
import mcInfo, { ChartData, getTotalOutflowMonthAndDaily } from '@/api/mcInfo'
import { getDecimal, getSuffix } from '../utils/index'
import tool from '@/utils/tool'
import Tooltip from '@/views/components/Tooltip.vue';
import BigNumber from 'bignumber.js';

const isMobile = !tool.getDeviceScreenIsPC()

let modules = [Navigation, Pagination]
const props = defineProps<{
    data: {
        totaloutflow: string,
        vesting: string,
        yieldrate: string,
        baseprice: string,
        todaysupply: string,
        mcyieldtotalamount: number,
        mcsupplypool: number
    }
}>()

const controlledSwiper = ref<any>(null);
const setControlledSwiper = (swiper: any) => {
    controlledSwiper.value = swiper;
};


const setSwiperIndex = (index: Number) => {
    if (!controlledSwiper.value || controlledSwiper.value.activeIndex == index) return;
    controlledSwiper.value.slideTo(index, 1000, false)
}

const totalOutflowChart = ref<{ dailylist: ChartData[], monthlylist: ChartData[]  }>({ dailylist: [], monthlylist: [] })
const vestingTimeRelatedChart = ref<ChartData[]>([])
const yieldRateChart = ref<ChartData[]>([])
const mcBasicChart = ref<ChartData[]>([])
const dailySupplyChart = ref<ChartData[]>([])
const swiperCharts = ref(null)
const isShow = useElementVisibility(swiperCharts)
const activeIndex = ref<number>(0)
const miningPolldData = ref<{ supply: number, remining: number }>({ supply: 0, remining: 0 })
const totalOutflowChartType = ref<'monthlylist'|'dailylist'>('monthlylist')
let catchData: ChartData[] = []
let dailyData: ChartData[] = []

const reset = () => {
    // totalOutflowChart.value = totalOutflowChart.value.map(item => ({ ...item, value: 0 }))
    dailySupplyChart.value = dailySupplyChart.value.map(item => ({ ...item, value: 0 }))
    vestingTimeRelatedChart.value = vestingTimeRelatedChart.value.map(item => ({ ...item, value: 0 }))
    yieldRateChart.value = yieldRateChart.value.map(item => ({ ...item, value: 0 }))
    mcBasicChart.value = mcBasicChart.value.map(item => ({ ...item, value: 0 }))
    miningPolldData.value = { supply: 0, remining: 0 }
}



const getTotalOutflowCharData = async () => {
    if (totalOutflowChart.value['dailylist'].length === 0) {
        totalOutflowChart.value = await getTotalOutflowMonthAndDaily()
    }
}


const getDailySypplyChartData = async () => {
    if (dailyData.length === 0) {
        dailyData = await mcInfo.getDailySupply()
    }
    dailySupplyChart.value = dailyData
}

const getMiningPoolData = () => {
    miningPolldData.value = {
        supply: props.data.mcsupplypool,
        remining: props.data.mcyieldtotalamount
    }
}

const getVestingTimeRelatedChartData = async () => {
    // const data = [{ text: "200K", "value": 130 }, { text: "600K", "value": 180 }, { text: "1M", "value": 210 }, { text: "1.5M", "value": 240 }, { text: "3.0M", "value": 300 }, { text: "5.0M", "value": 360 }]
    const data = [{ text: "200K", "value": 130 }, { text: "600K", "value": 170 }, { text: "1M", "value": 210 }, { text: "1.5M", "value": 240 }, { text: "3.0M", "value": 300 }, { text: "5.0M", "value": 360 }]
    if (vestingTimeRelatedChart.value[0]) {
        vestingTimeRelatedChart.value = data
    } else {
        vestingTimeRelatedChart.value = data.map(item => ({ ...item, value: 0 }))
        // get value reset
        setTimeout(() => {
            vestingTimeRelatedChart.value = data
        }, 0);
    }
}

const getYieldRateChartData = async () => {
    const data = [{ text: "$0.2", "value": 0.2 }, { text: "$0.4", "value": 0.3 }, { text: "$0.6", "value": 0.5 }, { text: "$0.8", "value": 0.8 }, { text: "$1.0", "value": 1.0 }, { text: "$2.0", "value": 1.0 }]
    if (yieldRateChart.value[0]) {
        yieldRateChart.value = data
    } else {
        yieldRateChart.value = data.map(item => ({ ...item, value: 0 }))
        // get value reset
        setTimeout(() => {
            yieldRateChart.value = data
        }, 0);
    }
}

const getMCBasicChartData = async () => {
    // const data = [{text:"s4","value":1},{text:"s5","value":1.1},{text:"s6","value":1.21},{text:"s7","value":1.33},{text:"s8","value":1.46},{text:"s9","value":1.61},{text:"s10","value":1.77},{text:"s11","value":1.95},{text:"s12","value":2.14},{text:"s13","value":2.36},{text:"s14","value":2.59},{text:"s15","value":2.85},{text:"s16","value":3.14},{text:"s17","value":3.45},{text:"s18","value":3.8},{text:"s19","value":4.18}]
    const pcData = [
        {
            text: "S5",
            "value": 1
        },
        {
            text: "S6",
            "value": 1.1
        },
        {
            text: "S7",
            "value": 1.21
        },
        // {
        //     text: "S8",
        //     "value": 1.33
        // },
        {
            text: "S9",
            "value": 1.46
        },
        // {
        //     text: "S10",
        //     "value": 1.61
        // },
        {
            text: "S11",
            "value": 1.77
        },
        // {
        //     text: "S12",
        //     "value": 1.95
        // },
        {
            text: "S13",
            "value": 2.14
        },
        // {
        //     text: "S14",
        //     "value": 2.36
        // },
        {
            text: "S15",
            "value": 2.59
        },
        // {
        //     text: "S16",
        //     "value": 2.85
        // },
        {
            text: "S17",
            "value": 3.14
        },
        // {
        //     text: "S18",
        //     "value": 3.45
        // },
        // {
        //     text: "S19",
        //     "value": 3.8
        // },
        {
            text: "S20",
            "value": 4.18
        }]
    const mobileData = [
        {
            text: "S5",
            "value": 1
        },
        // {
        //     text: "S6",
        //     "value": 1.1
        // },
        {
            text: "S7",
            "value": 1.21
        },
        // {
        //     text: "S8",
        //     "value": 1.33
        // },
        {
            text: "S9",
            "value": 1.46
        },
        // {
        //     text: "S10",
        //     "value": 1.61
        // },
        {
            text: "S11",
            "value": 1.77
        },
        // {
        //     text: "S12",
        //     "value": 1.95
        // },
        {
            text: "S13",
            "value": 2.14
        },
        // {
        //     text: "S14",
        //     "value": 2.36
        // },
        {
            text: "S15",
            "value": 2.59
        },
        // {
        //     text: "S16",
        //     "value": 2.85
        // },
        {
            text: "S17",
            "value": 3.14
        },
        // {
        //     text: "S18",
        //     "value": 3.45
        // },
        // {
        //     text: "S19",
        //     "value": 3.8
        // },
        // {
        //     text: "S20",
        //     "value": 4.18
        // }
    ]
    const data = isMobile ? mobileData : pcData
    if (mcBasicChart.value[0]) {
        mcBasicChart.value = data
    } else {
        mcBasicChart.value = data.map(item => ({ ...item, value: 0 }))
        // get value reset
        setTimeout(() => {
            mcBasicChart.value = data
        }, 0);
    }
}
const leftList = [-30, -30, -1, 24.5, 50, 75.5]
const left = ref<number>(leftList[0])
const funcList = [getDailySypplyChartData, getMiningPoolData, getTotalOutflowCharData, getVestingTimeRelatedChartData, getYieldRateChartData, getMCBasicChartData,]

const onSlideChange = async (swiper: any) => {
    reset()
    activeIndex.value = swiper.activeIndex
    if (funcList[swiper.activeIndex]) {
        left.value = leftList[swiper.activeIndex]
        await funcList[swiper.activeIndex]()
    }
};

watch(isShow, async (value: boolean) => {
    if (value) {
        if (funcList[activeIndex.value]) {
            await funcList[activeIndex.value]()
        }
    } else {
        reset()
    }

})


const getOption = (data: ChartData[], type = 'line') => {
    let option: any
    if (type == 'line') {
        if (!isMobile) {
            option = cloneDeep(MC_REAL_LINE_BASE_OPTION)
        } else {
            option = cloneDeep(MC_REAL_LINE_BASE_OPTION_MOBILE)
        }
        // option = cloneDeep(MC_REAL_LINE_BASE_OPTION)
    } else {
        if (!isMobile) {
            option = cloneDeep(MC_REAL_BAR_BASE_OPTION)
        } else {
            option = cloneDeep(MC_REAL_BAR_BASE_OPTION_MOBILE)
        }

    }
    option.xAxis[0].data = data.map(item => item.text) as any
    option.series[0].data = data.map(item => item.value) as any
    return option;

}

const getMiningPoolOption = () => {
    // options.series[0].data = [miningPolldData.value.remining, miningPolldData.value.remining]
    return {
        left: 0,
        top: 400,
        right: 0,
        bottom: 0,
        series: [{
            type: 'liquidFill',
            data: [ miningPolldData.value.remining ==0 ? 0 : (miningPolldData.value.remining/miningPolldData.value.supply)>0.98 ? 0.98 : (miningPolldData.value.remining/miningPolldData.value.supply)],
            color: [new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                        offset: 0,
                        color: '#FFA287' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: '#FFC060' // 100% 处的颜色
                    }], false)],
            amplitude: 5, // 振幅
            shape: 'container', // 类型为容器
            outline: {
                show: false
            },
            backgroundStyle: {
                color: 'transparent'
            },
            emphasis: {
                itemStyle: {
                    opacity: 1
                }
            },
            animationDuration: 1500,
            animationDurationUpdate: 1500,
            label: {
                formatter: () => ''
            }
        }]
    }
}

const getVestingOptino = () => {
    // let option = cloneDeep(MC_REAL_LINE_BASE_OPTION)
    // option.xAxis[0].data = vestingTimeRelatedChart.value.map(item => item.text) as any
    // option.series[0].data = vestingTimeRelatedChart.value.map(item => item.value) as any
    // return option;

    return getOption(vestingTimeRelatedChart.value, 'line')
}
const getDailyOption = () => {
    return getOption(dailySupplyChart.value, 'bar')
}

const getYieldRateOptino = () => {
    // let option = cloneDeep(MC_REAL_LINE_BASE_OPTION)
    // option.series[0].label.normal.width = 60
    // option.xAxis[0].data = yieldRateChart.value.map(item => item.text) as any
    // option.series[0].data = yieldRateChart.value.map(item => item.value) as any
    // return option;

    return getOption(yieldRateChart.value, 'line')
}

const getMcBasicOptino = () => {
    // let option = cloneDeep(MC_REAL_LINE_BASE_OPTION)
    // option.xAxis[0].data = mcBasicChart.value.map(item => item.text) as any
    // option.series[0].data = mcBasicChart.value.map(item => item.value) as any
    // return option;
    return getOption(mcBasicChart.value, 'line')
}

const getTotalOutOption = () => {
    // let option = cloneDeep(MC_REAL_BAR_BASE_OPTION)
    // option.xAxis[0].data = totalOutflowChart.value.map(item => item.text) as any
    // option.series[0].data = totalOutflowChart.value.map(item => item.value) as any
    // return option
    return getOption(totalOutflowChart.value[totalOutflowChartType.value], 'bar')
}







</script>

<style lang="stylus">
    .swiper-wrap{
        .swiper-button-next:after, .swiper-button-prev:after{
            color: #FDCF63;
            font-size: 30px;
        }
    }
    .el-popper.is-dark {
        padding: 15px;    
        line-height: 20px;
    }
</style>

<style scoped lang="stylus">
block_bg_color=#101010
.mc-real-wrap{
    // width: 1780px;
    height: 680px;
    // border-radius: 44px;
    // background-color: #2C2C2C;
    background-color: block_bg_color;
    margin-top: 60px;
    box-sizing: border-box;
    // overflow: hidden;
    .mc-real{
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 40px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            margin-top: 60px;
        }
        .tooltip-icon{
            opacity: 0.6;    
        }
        .info-wrap {
            display: flex;
            margin-top: 54px;
            .info {
                
                width: 784px;
                height: 80px;
                position: relative;
                background: url(/static/images/page1_border_mc_real.svg) no-repeat;
                background-size: 784px 80px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: relative;
                .active-wrap {
                    position: absolute;
                    transform: skewX(-15deg);
                    border-radius: 10px;
                    width: 755px;
                    height: 100%;
                    overflow: hidden;
                    .active-item {
                        width: 25.5%;
                        height: 100%;
                        background-color: #FDCF63;
                        opacity: 0.2;
                        transform: skewX(15deg);
                        position: absolute;
                        // left: -7px;
                        // left: 24.5%;
                        transition: left .5s;
                    }
                    
                    
                }
                .item {
                    position: relative;
                    user-select: none;
                    cursor: pointer;
                }
            }
            .supply-info-mobile {
                display: none;
            }
            .supply-info {
                
                width: 208px;
                height: 80px;
                background: url(/static/images/page1_supply_bg.svg) no-repeat;
                transition: all 2s;
                background-size: 208px 80px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor pointer
                .value {
                    font-family: Avenir-Black;
                    font-size: 26px;
                    color: #101010;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 25px;
                }
                .text{
                    font-family: Avenir-Light;
                    font-size: 14px;
                    color: #101010;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 25px;    
                } 
                &.active {
                    background-image: url(/static/images/page1_supply_bg_active.svg);    
                }
            }
        }
        
        .swiper-wrap {
            width: 930px;
            height: 400px;
            margin-top: 20px;
            position: relative;
            .mySwiper{
                width: 100%;
                height: 100%;
                
                .item {
                    // background: red;
                    width: 800px;
                    height: 380px;
                    margin: 0 auto;

                    .mining-pool{
                        height: 250px;
                        width: 700px;
                        margin: 0 auto;
                        margin-top: 80px;
                        border: 2PX solid #7D5719;
                        position: relative;
                        border-radius: 15px;
                        overflow: hidden;
                        .mining-pool-desc-wrap {
                            position: absolute;
                            top: 60%;
                            // transform: translateY(-50%);
                            // color: white;
                            color: #101010;

                            text-align: center;
                            width: 100%;
                            font-size: 20px;
                            font-family: Avenir-Black;
                            .mining-pool-desc {
                                font-size: 12px;
                                margin-top: 10px;
                            }

                        }
                    }
                }
                .swiper-title {
                    // margin-top: -5px;
                    position: relative;
                    top: -25px;
                    font-family: Avenir-Medium;
                    font-size: 16px;
                    color: #FFC800;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 15px;
                    
                    // font-family: Avenir-Medium;
                    // font-size: 16px;
                    // color: #FFC800;
                    // letter-spacing: 0;
                    // text-align: center;
                    // line-height: 15px;
                }
                .toggle {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    display: flex;
                    .toggle-detail {
                        display: flex;
                        margin: 0 5px;
                        padding: 5px;
                        cursor: pointer;
                        transition: opacity .3s;
                        opacity: 0.5;
                        &.active, &:hover {
                            opacity: 1;
                        }
                        .round {
                            font-size: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
    
</style>

<style scoped lang="stylus">

@media screen and (max-width: 1140px) {
    :deep(.el-popper.is-dark) {
        line-height: 1.7 !important;
        padding: 10rem !important;
    }

    :deep(.swiper-horizontal>.swiper-pagination-bullets), .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 28rem !important;
    }
    :deep(.swiper-pagination-bullet) {
        background-color: #fff !important;
    }
    :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active){
        background-color: #FDCF63 !important;
    }
}
</style>

<style scoped lang="stylus"> 

@media screen and (max-width: 1140px) {

   
    .mc-real-wrap{
        height: 642rem;
        width: 354rem;
        overflow: hidden;

        .mc-real {

            .title {
                font-size: 24rem;
                letter-spacing: 0;
                line-height: 24rem;
                padding-top: 30rem;
                text-align: center;
            }

            .info-wrap {
                margin-top: 30rem;
                display: block;
                .supply-info {
                    display: none;
                }
                .supply-info-mobile {
                    display: block;
                    font-family: Avenir-Black;
                    font-size: 14rem;
                    color: #FFC800;
                    text-align: center;
                    line-height: 16rem;
                    margin-top: 24rem;
                }
                .info {
                    width: 355rem;
                    height: 56rem;
                    background-image: url(/static/images/page1_border_mc_real_mobile.svg);
                    background-size: 355rem 56rem;
                    justify-content: center;
                    column-gap: 6rem;
                    padding-right: 10rem;
                    padding-left: 12rem;
                    .tooltip-icon {
                        display: none;
                    }
                    .active-wrap {
                        display: none;
                    }
                    .item {
                        .desc {
                            transform: scale(0.85);
                        }
                        &.not-first{
                            margin-left: -0rem;
                        }
                    }
                }

            }

            
            .swiper-wrap {
                width: 385rem;
                height: 460rem;
                margin-top: 0rem;
                
                .mySwiper{
                    width: 90%;
                    height: 100%;
                    
                    .item {
                        // background: red;
                        width: 100%;
                        height: 100%;
                        padding-bottom: 50rem;
                        margin: 0 auto;
                        .mining-pool {
                            width: 100%;
                            height: 80%;
                            margin-top: 20rem;
                        }
                    }
                    .swiper-title {
                        // margin-top: -5px;
                        position: relative;
                        top: -90rem;
                        // font-family: Avenir-Medium;
                        // font-size: 16px;
                        // color: #FFC800;
                        // letter-spacing: 0;
                        // text-align: center;
                        // line-height: 15rem;
                        
                        // font-family: Avenir-Medium;
                        font-size: 12rem;
                  
                        line-height: 1;
                    }
                }
            }

            
        }

    }

}

</style>