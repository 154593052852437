<template>
    <div class="page-nftinfo height-header-padding" style="position: relative;">
        <el-dialog v-model="isShowModal" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false">
            <!-- <div v-if="modalShowIndex === 0"
                class="mask-nft gotnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center"
                    :class="isMobile ? 'justify-content-center' : ''" style="overflow: hidden;">
                    <div class="box-infotip">Congratulations, you have got NFT card!</div>
                    <div class="nft-item d-flex align-items-center justify-content-center">
                        <img :src="buyItem['preview']" class="nft-item-image" />
                        <div class="nft-item-intro d-flex flex-column align-items-start">
                            <div class="nft-item-intro-title">{{ buyItem['name'] }}</div>
                            <div class="nft-item-intro-title" v-if="buyItem['typenft']">#{{ buyItem['tokenid'] }}</div>
                        </div>
                        <div v-if="buyItem['typenft']"
                            class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                            <img src="/static/images/element_lighting.png" />
                            <span class="text">{{ buyItem['level'] }}/5</span>
                        </div>
                        <div v-else class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                            <span>{{ buyItem['amount'] }}</span>
                        </div>

                        <div v-if="buyItem['typenft']" class="nft-item-attrs">
                            <div class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                <img class="nft-item-attrs-image" src="/static/images/element_attr_yellow_earth.png" />
                                <span class="nft-item-attrs-text earth"
                                    :class="buyItem['earth'] >= 10 ? 'digit2' : ''">{{ buyItem['earth'] }}</span>
                            </div>
                            <div class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                <img class="nft-item-attrs-image" src="/static/images/element_attr_blue_water.png" />
                                <span class="nft-item-attrs-text water"
                                    :class="buyItem['water'] >= 10 ? 'digit2' : ''">{{ buyItem['water'] }}</span>
                            </div>
                            <div class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                                <span class="nft-item-attrs-text fire" :class="buyItem['fire'] >= 10 ? 'digit2' : ''">{{
                                        buyItem['fire']
                                }}</span>
                            </div>
                            <div class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                <img class="nft-item-attrs-image" src="/static/images/element_attr_green_wind.png" />
                                <span class="nft-item-attrs-text wind" :class="buyItem['wind'] >= 10 ? 'digit2' : ''">{{
                                        buyItem['wind']
                                }}</span>
                            </div>
                            <div class="nft-item-attr d-flex align-items-center"></div>
                        </div>
                        <img v-if="buyItem['typenft']" class="nft-item-attrs-image-level"
                            :src="'/static/images/element_level_' + buyItem['rarity'].toLowerCase() + '.png'" />
                    </div>
                </div>
            </div> -->
            <modal-approve v-if="modalShowIndex === 1" :tokeninfo="getApproveTokenAndNeedAmount(0)"
                @confirm="approve(0)" @cancel="closeModal()" />
            <modal-approve v-else-if="modalShowIndex === 2" :tokeninfo="getApproveTokenAndNeedAmount(1)"
                @confirm="approve(1)" @cancel="closeModal()" />
            <modal-payment v-else-if="modalShowIndex === 3" action="buy" :symbol="goodsPaySale.symbol"
                :amount="goodsPaySale.price" :detail="$t(`CardList.${nftInfo.typeid}.name`) + ' #' + nftInfo.tokenid"
                @confirm="buy()" @cancel="closeModal" />
            <modal-payment v-else-if="modalShowIndex === 4" action="rent" :symbol="goodsPayLend.symbol"
                :amount="goodsPayLend.price" :detail="$t(`CardList.${nftInfo.typeid}.name`) + ' #' + nftInfo.tokenid"
                @confirm="rent()" @cancel="closeModal" />
            <div v-else-if="modalShowIndex === 5"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.sell') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('Dialog.your-nft-will-be-listed-on-the-marketplace-for-other-to-buy') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.payment-type') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input :value="goodsPaySale.symbol" readonly type="text" />
                                <span class="symbol">{{ $t('Dialog.token') }}</span>
                            </div>
                            <div class="item-title">{{ $t('Dialog.sale-price') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputAmount" type="text" placeholder="" @input="watchInput()" />
                                <span class="symbol">{{
                                        goodsPaySale.symbol
                                }}</span>
                            </div>
                            <div class="fee-block d-flex justify-content-end">
                                <span class="text">{{ $t('Dialog.fee') }}{{ $t('Common.colon') }}</span>
                                <span class="fee">{{
                                        goodsPaySale.feerate * 100
                                }}%</span>
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="sell()">
                                    {{ $t('Dialog.sell') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 6"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.rent-out') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('Dialog.your-nft-will-be-listed-on-the-marketplace-for-other-to-rent') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.durations') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputDays" type="text" placeholder="" @input="watchInputInteger()" />
                                <span class="symbol">{{ $t('Dialog.days') }}</span>
                            </div>
                            <div class="infotip" style="margin-top: 10PX">
                                {{ $t('Dialog.the-rental-duration-time-should-be-1-to-30-days') }}
                            </div>
                            <div class="rentout-relet-setting-block">
                                <div class="relet-title">
                                    {{ $t('Dialog.rental-renewal') }}
                                </div>
                                <el-radio-group v-model="isRenewPrice">
                                    <el-radio :label="true">{{ $t('Dialog.allowed') }}</el-radio>
                                    <el-radio :label="false">{{ $t('Dialog.not-allowed') }}</el-radio>
                                </el-radio-group>
                            </div>

                            <!-- <el-checkbox v-model="isRenewPrice" size="small">Allow renew the lease before the end of
                                return time</el-checkbox> -->
                            <div class="item-title">{{ $t('Dialog.payment-type') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input readonly :value="goodsPayLend.symbol" type="text" />
                                <span class="symbol">{{ $t('Dialog.token') }}</span>
                            </div>
                            <div class="item-title">{{ $t('Dialog.daily-price') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputAmount" type="text" placeholder="" @input="watchInput()" />
                                <span class="symbol">{{
                                        goodsPayLend.symbol
                                }}</span>
                            </div>
                            <div class="fee-block d-flex justify-content-end">
                                <span class="text">{{ $t('Dialog.fee') }}{{ $t('Common.colon') }}</span>
                                <span class="fee">{{
                                        goodsPayLend.feerate * 100
                                }}%</span>
                            </div>
                            <div class="fee-block notfirst d-flex justify-content-end">
                                <span class="text">Total Price:</span>
                                <span class="fee">{{ inputAmount.trim().length > 0 && inputDays.trim().length > 0 ?
                                        parseFloat(inputDays) *
                                        parseFloat(inputAmount) : '0'
                                }} {{
        goodsPayLend.symbol
}}</span>
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="rentout()">
                                    {{ $t('Dialog.rent-out') }}
                                </button>
                            </div>
                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 7"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.change-price') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('Dialog.your-nft-will-be-listed-on-the-marketplace-for-other-to-rent') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.durations') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input :value="lendingInfo.period / 3600 / 24" readonly type="text" placeholder="" />
                                <span class="symbol">{{ $t('Dialog.days') }}</span>
                            </div>
                            <!-- <el-checkbox v-model="isRenewPrice" size="small">Allow renew the lease before the end of
                                return time</el-checkbox> -->
                            <div class="item-title">{{ $t('Dialog.payment-type') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input readonly :value="goodsPayLend.symbol" type="text" />
                                <span class="symbol">{{ $t('Dialog.token') }}</span>
                            </div>
                            <div class="item-title">{{ $t('Dialog.daily-price') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputAmount" type="text" placeholder="" @input="watchInput()" />
                                <span class="symbol">{{
                                        goodsPayLend.symbol
                                }}</span>
                            </div>
                            <div class="fee-block d-flex justify-content-end">
                                <span class="text">{{ $t('Dialog.fee') }}{{ $t('Common.colon') }}</span>
                                <span class="fee">{{
                                        goodsPayLend.feerate * 100
                                }}%</span>
                            </div>
                            <div class="fee-block d-flex justify-content-end">
                                <span class="text">Total Price:</span>
                                <span class="fee">{{ inputAmount.trim().length > 0 ?
                                        Number(lendingInfo.period / 3600 / 24) *
                                        parseFloat(inputAmount) : '0'
                                }} {{
        goodsPayLend.symbol
}}</span>
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="changeprice()">
                                    {{ $t('Dialog.change-price') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 8"
                class="mask-nft modalwindow d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.notice') }}</div>
                    <div style="word-break: break-word" class="infotip infotip-attention d-flex justify-content-center">
                        {{ modalInfotip }}
                    </div>
                    <div v-if="isShowLoginLink" style="margin-top: 40PX;" class="d-flex justify-content-center">
                        <a class="go2login" href="/#/login">{{ $t('Dialog.go-to-your-game-account-to-bind-your-wallet')
                        }}</a>
                    </div>
                    <button class="button-buy nftinfo" style="margin-top: 40PX;width:130PX" @click="closeModal()">{{
                            $t('Dialog.ok')
                    }}</button>

                </div>
            </div>
            <modal-approve v-else-if="modalShowIndex === 9" @confirm="approveNFT(indexApproveNFT)"
                @cancel="closeModal" />
            <div v-else-if="modalShowIndex === 10"
                class="mask-nft reletsetting d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="isSetRelet = !isSetRelet; closeModal()"
                    style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.relet-info') }}</div>
                    <div class="info-block">
                        <div class="title">
                            {{ $t('Dialog.current-expiration-time') }}
                        </div>
                        <div class="text yellow">
                            {{ getTime(lendingInfo.expirytime) }}
                        </div>
                    </div>
                    <div class="info-block mt">
                        <div class="title">
                            {{ $t('Dialog.relet-expiration-time') }}
                        </div>
                        <div class="text yellow">
                            {{ getTime(lendingInfo.expirytime + lendingInfo.period) }}
                        </div>
                    </div>
                    <div class="title-level2">
                        {{ $t('Dialog.relet-terms') }}
                    </div>
                    <div class="info-block mt">
                        <div class="title">
                            {{ $t('Dialog.duration') }}
                        </div>
                        <div class="text yellow">
                            {{ lendingInfo.period / 3600 / 24 }} {{ $t('Dialog.days') }}
                        </div>
                    </div>
                    <div class="info-block mt">
                        <div class="title">
                            {{ $t('Dialog.daily-price') }}
                        </div>
                        <div class="text yellow">
                            {{ goodsPayLend.price / (lendingInfo.period / 3600 / 24) }} {{ goodsPayLend.symbol }}
                        </div>
                    </div>
                    <div class="info-block mt">
                        <div class="title">
                            {{ $t('Dialog.total-price') }}
                        </div>
                        <div class="text yellow">
                            {{ goodsPayLend.price }} {{ goodsPayLend.symbol }}
                        </div>
                    </div>
                    <div class="button-block">
                        <button class="button-cancel" @click="isSetRelet = !isSetRelet; closeModal()">
                            {{ $t('Dialog.cancel') }}
                        </button>
                        <button :disabled="buttonDisabled" class="button-buy" @click="reletset()">
                            {{ $t('Dialog.relet') }}
                        </button>
                    </div>



                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 11"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.transter') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('NFTInfo.your-nft-will-be-transterd-to-another-wallet-address') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.recievewalletaddress') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputWalletAddressTransfer" type="text" placeholder=""
                                    style="text-align: left" maxlength="42" />
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="transfer()">
                                    {{ $t('Dialog.transter') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <modal-approve v-if="modalShowIndex === 12" @confirm="approveNFT(3)" @cancel="closeModal()" />
            <modal-stake-u-r v-if="modalShowIndex === 13" :ismobile="isMobile" :action="0" :tokenid="nftInfo['tokenid']"
                :wa="walletAddress" @loan="dealLoan" @cancel="closeModal()" />
            <modal-stake-u-r v-if="modalShowIndex === 14" :ismobile="isMobile" :action="1" :tokenid="nftInfo['tokenid']"
                :wa="walletAddress" @redeem="dealRedeem" @cancel="closeModal()" />
            <modal-approve v-if="modalShowIndex === 15" :tokeninfo="getApproveTokenAndNeedAmount(2)"
                @confirm="approve(2)" @cancel="closeModal()" />
            <modal-approve v-if="modalShowIndex === 16" @confirm="approveNFT(4)" @cancel="closeModal()" />
            <ModalActionResult v-else-if="modalShowIndex === 17" :action="actionResultInfo.action"
                :cardinfo="actionResultInfo.cardinfo" @confirm="closeModal()" :wa="walletAddress" />
        </el-dialog>
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>

                        <div class="left d-flex flex-column">
                            <div class="nft-state"
                                v-if="(belongto === 'forsell' || belongto === 'forrent' || belongto === 'rentin' || belongto === 'rentout' || belongto === 'collateral') || isOwner">
                                <div class="anim"></div>
                                <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                            </div>
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <template v-if="belongto !== 'wallet'">
                                <div class="price-block"
                                    v-if="(belongto === 'forsell' || belongto === 'forrent' || (belongto === 'rentout' && isOwner))"
                                    :class="(belongto === 'forsell' || belongto === 'forrent' || (belongto === 'rentout' && isOwner)) ? 'buyandrent' : ''">
                                    <div class="price-left">
                                        <div class="price-title">
                                            <template v-if="belongto === 'forsell'">{{ $t('NFTInfo.sale-price')
                                            }}</template>
                                            <template v-else>Total Price</template>
                                        </div>
                                        <div class="price-content">
                                            <img v-if="goodsPaySale.symbol.length > 0"
                                                :src="belongto === 'forsell' ? ('/static/images/uc_token_' + goodsPaySale.symbol.toLowerCase() + '.png') : ('/static/images/uc_token_' + goodsPayLend.symbol.toLowerCase() + '.png')">
                                            <div class="price">
                                                <template v-if="belongto === 'forsell'">
                                                    {{ goodsPaySale.price.toFixed(2) }}
                                                </template>
                                                <template v-else>
                                                    {{ (goodsPayLend.price).toFixed(2)
                                                    }}
                                                </template>
                                            </div>
                                            <div class="price yellow">
                                                <template v-if="belongto === 'forsell'">
                                                    {{ goodsPaySale.symbol }}
                                                </template>
                                                <template v-else>
                                                    {{ goodsPayLend.symbol }}
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="!isOwner">
                                        <template v-if="belongto === 'forsell'">
                                            <button :disabled="buttonDisabled" @click="buy()"
                                                class="button-buy nftinfo">{{
                                                        $t('NFTInfo.buy')
                                                }}</button>
                                        </template>
                                        <template v-else-if="belongto === 'forrent'">
                                            <button :disabled="buttonDisabled" @click="rent()"
                                                class="button-buy nftinfo">{{
                                                        $t('NFTInfo.rent')
                                                }}</button>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="belongto === 'forsell'">
                                            <button :disabled="buttonDisabled" @click="cancelOrderSell()"
                                                class="button-buy nftinfo">{{ $t('NFTInfo.cancel-sale') }}</button>
                                        </template>
                                        <template v-if="belongto === 'forrent' || belongto === 'rentout'">
                                            <button v-if="!isBorrowable"
                                                :disabled="buttonDisabled || belongto === 'rentout'"
                                                @click="claimRentNFT()" class="button-buy nftinfo">{{
                                                        $t('NFTInfo.claim-nft')
                                                }}</button>
                                            <button v-if="isBorrowable" :disabled="buttonDisabled"
                                                @click="cancelOrderRent()" class="button-buy nftinfo">{{
                                                        $t('NFTInfo.cancel-rent')
                                                }}</button>
                                            <button :disabled="buttonDisabled" v-if="!nftApprove.lending"
                                                @click="showModal(9, 1)" class="button-buy nftinfo">{{
                                                        $t('NFTInfo.change-price')
                                                }}</button>
                                            <button :disabled="buttonDisabled" v-if="isBorrowable" @click="showModal(7)"
                                                class="button-buy nftinfo">{{ $t('NFTInfo.change-price') }}</button>
                                        </template>
                                    </template>
                                </div>
                                <div v-if="belongto === 'rentin' && currentTime > 0 && returnTimeArr.length > 0"
                                    class="rentin-block">
                                    <div class="left-block">
                                        <div class="left-title">{{ $t('NFTInfo.rent-countdown') }}</div>
                                        <div class="return-time">
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[0].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[0].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.days') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[1].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[1].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[2].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[2].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[3].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[3].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <button :disabled="buttonDisabled" v-if="goodsPayLend.approve === 0"
                                        @click="showModal(2)" class="button-buy nftinfo">{{ $t('NFTInfo.rental-renewal')
                                        }}</button>
                                    <button :disabled="buttonDisabled" v-else @click="rent(true)"
                                        class="button-buy nftinfo">{{ $t('NFTInfo.rental-renewal') }}</button>
                                </div>
                                <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                                    <template v-if="belongto === 'ingame'">
                                        {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                                    </template>
                                    <template v-else>
                                        {{
                                                $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time')
                                        }}
                                    </template>
                                </div>
                                <template v-if="belongto.indexOf('rent') >= 0">
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.duration') }}</span>
                                        <span class="text forsale">
                                            {{ lendingInfo.period / 3600 / 24 }} {{ $t('NFTInfo.days') }}
                                        </span>
                                    </div>
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.daily-price') }}</span>
                                        <span class="text forsale">
                                            {{ goodsPayLend.price / (lendingInfo.period / 3600 / 24) }} {{
                                                    goodsPayLend.symbol
                                            }}
                                        </span>
                                    </div>
                                </template>
                                <template v-if="belongto === 'forrent' || belongto === 'forsell'">
                                    <!-- <div class="info-block d-flex justify-content-between">
                                        <span class="title">Fee Rate</span>
                                        <span class="text forsale">
                                            <template v-if="belongto === 'forsell'">
                                                {{ goodsPaySale.feerate * 100 }}
                                            </template>
                                            <template v-if="belongto === 'forrent'">
                                                {{ goodsPayLend.feerate * 100 }}
                                            </template>
                                            %</span>
                                    </div> -->
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                                        <span class="text">
                                            <template v-if="belongto === 'forsell'">{{
                                                    getTime(Number(nftInfo['timestamp']))
                                            }}</template>
                                            <template v-if="belongto === 'forrent'">{{ getTime(lendingInfo.listtime)
                                            }}</template>
                                        </span>
                                    </div>
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.available-remaining-time') }}</span>
                                        <div v-if="currentTimeRemainTime > 0 && remainTimeArr.length > 0"
                                            class="d-flex align-items-center text">
                                            <span class="">
                                                {{ remainTimeArr[0].toString().charAt(0) }}{{
                                                        remainTimeArr[0].toString().charAt(1)
                                                }} {{ $t('Common.days') }}
                                                {{ remainTimeArr[1].toString().charAt(0) }}{{
                                                        remainTimeArr[1].toString().charAt(1)
                                                }} {{ $t('Common.hrs') }}
                                                {{ remainTimeArr[2].toString().charAt(0) }}{{
                                                        remainTimeArr[2].toString().charAt(1)
                                                }} {{ $t('Common.mins') }}
                                                {{ remainTimeArr[3].toString().charAt(0) }}{{
                                                        remainTimeArr[3].toString().charAt(1)
                                                }} {{ $t('Common.secs') }}
                                            </span>
                                            <el-tooltip class="box-item"
                                                :content="$t('NFTInfo.the-available-remaining-time-for-the-lease-period-is-effected-and-calculated-on-utc-0-00')"
                                                placement="left">
                                                <el-icon>
                                                    <InfoFilled />
                                                </el-icon>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-else-if="belongto === 'wallet' && isOwner">
                                <div class="action-block justify-content-end">
                                    <button v-if="!nftApprove.market" @click="showModal(9, 0)" class="button-buy"
                                        :disabled="buttonDisabled">{{ $t('NFTInfo.sell') }}</button>
                                    <button v-else @click="showModal(5)" class="button-buy"
                                        :disabled="buttonDisabled">{{
                                                $t('NFTInfo.sell')
                                        }}</button>
                                    <button v-if="!nftApprove.lending" @click="showModal(9, 1)" class="button-buy"
                                        :disabled="buttonDisabled">{{ $t('NFTInfo.rent-out') }}</button>
                                    <button v-else @click="showModal(6)" class="button-buy"
                                        :disabled="buttonDisabled">{{
                                                $t('NFTInfo.rent-out')
                                        }}</button>
                                    <button v-if="!nftApprove.market" @click="showModal(9, 2)" class="button-buy"
                                        :disabled="buttonDisabled">{{ $t('NFTInfo.charge-game') }}</button>
                                    <button v-else @click="charge()" class="button-buy" :disabled="buttonDisabled">{{
                                            $t('NFTInfo.charge-game')
                                    }}</button>
                                    <button class="button-buy" @click="showModal(11)" :disabled="buttonDisabled">
                                        {{ $t('Dialog.transter') }}
                                    </button>

                                </div>
                            </template>
                            <template
                                v-if="(belongto === 'rentout' && isOwner) && currentTime > 0 && returnTimeArr.length > 0">
                                <div class="rentout-relet">
                                    <div class="rentout-relet-left" v-if="currentTime > 0">
                                        <div class="left-title">{{ $t('NFTInfo.rent-countdown') }}</div>
                                        <div class="return-time">
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[0].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[0].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.days') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[1].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[1].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[2].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[2].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                            </div>
                                            <div class="return-time-item">
                                                <div class="number-block">
                                                    <div class="number">
                                                        {{ returnTimeArr[3].toString().charAt(0) }}
                                                    </div>
                                                    <div class="number">
                                                        {{ returnTimeArr[3].toString().charAt(1) }}
                                                    </div>
                                                </div>
                                                <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="relet-setting">
                                        <div class="title">{{ $t('NFTInfo.rental-renewal') }}</div>
                                        <el-switch :width="36" @change="reletsetchange()"
                                            :disabled="switchReletDisabled" v-model="isSetRelet" />
                                        <div class="switch-tip">{{ isSetRelet ? $t('NFTInfo.allowed') :
                                                $t('NFTInfo.not-allowed')
                                        }}</div>
                                    </div>
                                </div>
                            </template>
                            <div v-if="canrevert || canloan || canredeem"
                                class="action-block justify-content-end align-items-start">
                                <template v-if="canloan">
                                    <button :disabled="buttonDisabled" v-if="!nftApprove.loan" @click="showModal(16)"
                                        class="button-buy nftinfo">Borrow {{ loanamount }}
                                        {{ loansymbol }}</button>
                                    <button :disabled="buttonDisabled" v-else @click="showModal(13)"
                                        class="button-buy nftinfo">Borrow {{ loanamount }}
                                        {{ loansymbol }}</button>
                                </template>
                                <button v-if="canredeem" :disabled="buttonDisabled" @click="showModal(14)"
                                    class="button-buy nftinfo">Redeem</button>
                                <div class="d-flex flex-column align-items-end" v-if="canrevert">
                                    <button :disabled="buttonDisabled" v-if="!nftApprove.revert" @click="showModal(12)"
                                        class="button-buy nftinfo">Return {{ revertrateamount }}
                                        {{ revertratesymbol }}</button>
                                    <button :disabled="buttonDisabled" v-else @click="revert()"
                                        class="button-buy nftinfo">Return {{ revertrateamount }}
                                        {{ revertratesymbol }}</button>
                                    <div class="countdown-revert-block">
                                        <template v-if="currentTime > 0 && revertTimeArr.length > 0">
                                            <div class="tag">Remaining time:</div>
                                            <div class="timetext">
                                                {{
                                                        revertTimeArr[1].toString() + ":" + revertTimeArr[2].toString() + ":" +
                                                        revertTimeArr[3].toString()
                                                }}
                                            </div>
                                        </template>

                                    </div>
                                </div>
                            </div>
                            <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                                <span class="text">#{{ nftInfo['tokenid'] }}</span>
                            </div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.name') }}</span>
                                <span class="text">{{ $t(`CardList.${nftInfo['typeid']}.name`) }}</span>
                            </div>
                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                                <div class="d-flex align-items-center justify-content-end">
                                    <!-- <img src="/static/images/element_lighting.png" /> -->
                                    <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                            nftInfo['level']
                                    }}/5</span>
                                </div>
                            </div>
                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.owner') }}</span>
                                <span class="text">{{ owner }}</span>
                            </div>
                            <div class="purchase-rent-title-block">
                                <div :class="historyIndexNumber === 0 ? 'active' : ''" class="title-item"
                                    style="cursor: pointer" @click="historyIndexNumber = 0">
                                    <div>Trends Chart</div>
                                    <div class="line" style="height: 1PX"></div>
                                </div>
                                <div class="title-item">|</div>
                                <div :class="historyIndexNumber === 1 ? 'active' : ''" class="title-item"
                                    style="cursor: pointer" @click="historyIndexNumber = 1">
                                    <div>{{ $t('NFTInfo.purchase-history') }}</div>
                                    <div class="line" style="height: 1PX"></div>
                                </div>
                                <div class="title-item">|</div>
                                <div :class="historyIndexNumber === 2 ? 'active' : ''" class="title-item"
                                    style="cursor: pointer" @click="historyIndexNumber = 2">
                                    <div>{{ $t('NFTInfo.rent-history') }}</div>
                                    <div class="line" style="height: 1PX"></div>
                                </div>
                            </div>
                            <div ref="nftChart" v-if="historyIndexNumber === 0" class="trade-record">
                                <v-chart :option="getNFTDataOption()" autoresize></v-chart>
                                <div class="rg-block-chart-period">
                                    <el-radio-group v-model="chartPeriodIndex" @change="changeNFTChartPeriod">
                                        <el-radio-button :label="0">7D</el-radio-button>
                                        <el-radio-button :label="1">1M</el-radio-button>
                                        <el-radio-button :label="2">1Y</el-radio-button>
                                        <el-radio-button :label="3">ALL</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </div>
                            <div ref="divSaleHistory" v-if="historyIndexNumber === 1" class="trade-record">
                                <div class="title-block d-flex justify-content-between align-items-center">
                                    <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.buyer') }}</div>
                                    <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.seller') }}</div>
                                    <div class="col-amount" style="flex: 1;">{{ $t('NFTInfo.price') }}</div>
                                    <div class="col-time" style="flex: 1;">{{ $t('NFTInfo.time') }}</div>
                                </div>
                                <template v-if="saleHistoryList.length > 0">
                                    <div class="data-item d-flex align-items-center justify-content-between"
                                        v-for="(sale, saleIndex) of saleHistoryList" :key="'sale-' + saleIndex">
                                        <div class="col-addr" style="flex: 1;width:auto">{{ sale['buyer'] }}</div>
                                        <div class="col-addr" style="flex: 1;width:auto">{{ sale['seller'] }}</div>
                                        <div class="col-amount" style="flex: 1;width:auto">{{ sale['price'] }}
                                            {{ sale['paysymbol'] }}</div>
                                        <div class="col-time" style="flex: 1;width:auto">{{ getTime(sale['closetime'])
                                        }}
                                        </div>
                                    </div>
                                </template>
                                <div v-else class="data-item d-flex align-items-center justify-content-center">
                                    {{ $t('NFTInfo.no-data') }}
                                </div>
                            </div>
                            <div ref="divRentHistory" v-else-if="historyIndexNumber === 2" class="trade-record">
                                <div class="title-block d-flex justify-content-between align-items-center">
                                    <div class="col-addr" style="flex: 1;width:auto">{{ $t('NFTInfo.renter') }}</div>
                                    <div class="col-addr" style="flex: 1;width:auto">{{ $t('Common.days') }}</div>
                                    <div class="col-amount" style="flex: 1;width:auto">{{ $t('NFTInfo.price') }}</div>
                                    <div class="col-time" style="flex: 1;width:auto">{{ $t('NFTInfo.time') }}</div>
                                </div>
                                <template v-if="rentHistoryList.length > 0">
                                    <div class="data-item d-flex align-items-center justify-content-between"
                                        v-for="(rent, rentIndex) of rentHistoryList" :key="'rent-' + rentIndex">
                                        <div class="col-addr" style="flex: 1;width:auto">{{ rent['lentout'] }}</div>
                                        <div class="col-addr" style="flex: 1;width:auto">{{ rent['period'] / 3600 / 24
                                        }}</div>
                                        <div class="col-amount" style="flex: 1;width:auto">{{ rent['price'] }}
                                            {{ rent['paysymbol'] }}</div>
                                        <div class="col-time" style="flex: 1;width:auto">{{ getTime(rent['closetime'])
                                        }}</div>
                                    </div>
                                </template>
                                <div v-else class="data-item d-flex align-items-center justify-content-center">
                                    {{ $t('NFTInfo.no-data') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{{ $t('Dialog.back') }}</span>
                </div>
                <div class="top-block">
                    <div class="level d-flex align-items-center justify-content-start">
                        <img :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                    </div>
                    <div class="nft-state"
                        v-if="(belongto === 'forsell' || belongto === 'forrent' || belongto === 'rentin' || belongto === 'rentout') || isOwner">
                        <div class="anim"></div>
                        <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                    </div>
                </div>
                <div class="nft-image-block">
                    <img :src="nftInfo['medium']" class="nft-image" />
                </div>
                <div class="attr-list d-flex">
                    <div class="item">
                        <img src="/static/images/element_attr_yellow_earth_ball.png" />
                        <span class="text earth">
                            {{ $t('Common.earth') }}
                        </span>
                        <span class="value earth">
                            {{
                                    nftInfo['earth']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_blue_water_ball.png" />
                        <span class="text water">
                            {{ $t('Common.water') }}
                        </span>
                        <span class="value water">
                            {{
                                    nftInfo['water']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_red_fire_ball.png" />
                        <span class="text fire">
                            {{ $t('Common.fire') }}
                        </span>
                        <span class="value fire">
                            {{
                                    nftInfo['fire']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_green_wind_ball.png" />
                        <span class="text wind">
                            {{ $t('Common.wind') }}
                        </span>
                        <span class="value wind">
                            {{
                                    nftInfo['wind']
                            }}
                        </span>
                    </div>
                </div>
                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                    <span class="text">#{{ nftInfo['tokenid'] }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                    <span class="text">{{ $t(`CardList.${nftInfo['typeid']}.name`) }}</span>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.character') }}</span>
                    <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- <img src="/static/images/element_lighting.png" /> -->
                        <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                nftInfo['level']
                        }}/5</span>
                    </div>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                    <span class="text">{{ owner }}</span>
                </div>
                <template v-if="belongto !== 'wallet'">
                    <template v-if="(belongto === 'forrent' || belongto === 'rentout') && isOwner">
                        <div class="rent-owner-action-block">
                            <button v-if="!isBorrowable && belongto === 'forrent'" :disabled="buttonDisabled"
                                @click="claimRentNFT()" class="button-buy nftinfo">{{ $t('NFTInfo.claim-nft')
                                }}</button>
                            <button v-else :disabled="buttonDisabled" @click="cancelOrderRent()"
                                class="button-buy nftinfo">{{ $t('NFTInfo.cancel-rent') }}</button>
                            <button :disabled="buttonDisabled" v-if="!nftApprove.lending" @click="showModal(9, 1)"
                                class="button-buy nftinfo">{{ $t('NFTInfo.change-price') }}</button>
                            <button :disabled="buttonDisabled" v-else @click="showModal(7)"
                                class="button-buy nftinfo">{{
                                        $t('NFTInfo.change-price')
                                }}</button>
                        </div>

                    </template>
                    <div class="price-block"
                        v-if="(belongto === 'forsell' || belongto === 'forrent' || (belongto === 'rentout' && isOwner))"
                        :class="(belongto === 'forsell' || belongto === 'forrent' || (belongto === 'rentout' && isOwner)) ? 'buyandrent' : ''">
                        <div class="price-left">
                            <div class="price-title">
                                <template v-if="belongto === 'forsell'">{{ $t('NFTInfo.sale-price') }}</template>
                                <template v-else>{{ $t('Dialog.total-price') }}</template>

                            </div>
                            <div class="price-content">
                                <img v-if="goodsPaySale.symbol.length > 0"
                                    :src="belongto === 'forsell' ? ('/static/images/uc_token_' + goodsPaySale.symbol.toLowerCase() + '.png') : ('/static/images/uc_token_' + goodsPayLend.symbol.toLowerCase() + '.png')">
                                <div class="price">
                                    <template v-if="belongto === 'forsell'">
                                        {{ goodsPaySale.price.toFixed(2) }}
                                    </template>
                                    <template v-else>
                                        {{ (goodsPayLend.price).toFixed(2) }}
                                    </template>
                                </div>
                                <div class="price yellow">
                                    <template v-if="belongto === 'forsell'">
                                        {{ goodsPaySale.symbol }}
                                    </template>
                                    <template v-else>
                                        {{ goodsPayLend.symbol }}
                                    </template>
                                </div>

                            </div>
                        </div>
                        <template v-if="!isOwner">
                            <template v-if="belongto === 'forsell'">
                                <button :disabled="buttonDisabled" @click="buy()" class="button-buy nftinfo">{{
                                        $t('NFTInfo.buy')
                                }}</button>
                            </template>
                            <template v-else-if="belongto === 'forrent'">
                                <button :disabled="buttonDisabled" @click="rent()" class="button-buy nftinfo">{{
                                        $t('NFTInfo.rent')
                                }}</button>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="belongto === 'forsell'">
                                <button :disabled="buttonDisabled" @click="cancelOrderSell()"
                                    class="button-buy nftinfo">{{
                                            $t('NFTInfo.cancel-sale')
                                    }}</button>
                            </template>
                        </template>
                    </div>
                    <div v-if="belongto === 'rentin' && currentTime > 0 && returnTimeArr.length > 0"
                        class="rentin-block">
                        <div class="left-block">
                            <div class="left-title">{{ $t('NFTInfo.rent-countdown') }}</div>
                            <div class="return-time">
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[0].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[0].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.days') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[1].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[1].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[2].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[2].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[3].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[3].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                </div>
                            </div>
                        </div>
                        <button :disabled="buttonDisabled" @click="rent(true)" class="button-buy nftinfo">{{
                                $t('NFTInfo.rental-renewal')
                        }}</button>
                    </div>
                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                        <template v-if="belongto === 'ingame'">
                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                        </template>
                        <template v-else>
                            {{ $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time') }}
                        </template>

                    </div>
                    <template v-if="belongto.indexOf('rent') >= 0">
                        <div class="info-block d-flex justify-content-between">
                            <span class="title">{{ $t('NFTInfo.duration') }}</span>
                            <span class="text forsale">
                                {{ lendingInfo.period / 3600 / 24 }} {{ $t('NFTInfo.days') }}
                            </span>
                        </div>
                        <div class="info-block d-flex justify-content-between">
                            <span class="title">{{ $t('MarketPlace.daily-price') }}</span>
                            <span class="text forsale">
                                {{ goodsPayLend.price / (lendingInfo.period / 3600 / 24) }} {{
                                        goodsPayLend.symbol
                                }}
                            </span>
                        </div>
                    </template>
                    <template v-if="belongto === 'forrent' || belongto === 'forsell'">
                        <!-- <div class="info-block d-flex justify-content-between">
                                        <span class="title">Fee Rate</span>
                                        <span class="text forsale">
                                            <template v-if="belongto === 'forsell'">
                                                {{ goodsPaySale.feerate * 100 }}
                                            </template>
                                            <template v-if="belongto === 'forrent'">
                                                {{ goodsPayLend.feerate * 100 }}
                                            </template>
                                            %</span>
                                    </div> -->
                        <div class="info-block d-flex justify-content-between">
                            <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                            <span class="text">
                                <template v-if="belongto === 'forsell'">{{
                                        getTime(Number(nftInfo['timestamp']))
                                }}</template>
                                <template v-if="belongto === 'forrent'">{{ getTime(lendingInfo.listtime)
                                }}</template>
                            </span>
                        </div>
                        <div class="info-block d-flex justify-content-between">
                            <span class="title">{{ $t('NFTInfo.available-remaining-time') }}</span>
                            <div v-if="currentTimeRemainTime > 0 && remainTimeArr.length > 0"
                                class="d-flex align-items-center text">
                                <span>
                                    {{ remainTimeArr[0].toString().charAt(0) }}{{
                                            remainTimeArr[0].toString().charAt(1)
                                    }} {{ $t('Common.days') }}
                                    {{ remainTimeArr[1].toString().charAt(0) }}{{
                                            remainTimeArr[1].toString().charAt(1)
                                    }} {{ $t('Common.hrs') }}
                                    {{ remainTimeArr[2].toString().charAt(0) }}{{
                                            remainTimeArr[2].toString().charAt(1)
                                    }} {{ $t('Common.mins') }}
                                    {{ remainTimeArr[3].toString().charAt(0) }}{{
                                            remainTimeArr[3].toString().charAt(1)
                                    }} {{ $t('Common.secs') }}
                                </span>
                                <el-tooltip class="box-item"
                                    :content="$t('NFTInfo.the-available-remaining-time-for-the-lease-period-is-effected-and-calculated-on-utc-0-00')"
                                    placement="left">
                                    <el-icon>
                                        <InfoFilled />
                                    </el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>

                </template>
                <template v-else-if="belongto === 'wallet' && isOwner">
                    <!-- <div class="title-cat">Action</div> -->
                    <div class="action-block">
                        <button v-if="!nftApprove.market" @click="showModal(9, 0)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('NFTInfo.sell') }}</button>
                        <button v-else @click="showModal(5)" class="button-buy" :disabled="buttonDisabled">{{
                                $t('NFTInfo.sell')
                        }}</button>
                        <button v-if="!nftApprove.lending" @click="showModal(9, 1)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('NFTInfo.rent-out') }}</button>
                        <button v-else @click="showModal(6)" class="button-buy" :disabled="buttonDisabled">{{
                                $t('NFTInfo.rent-out')
                        }}</button>
                        <button v-if="!nftApprove.market" @click="showModal(9, 2)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('NFTInfo.charge-game') }}</button>
                        <button v-else @click="charge()" class="button-buy" :disabled="buttonDisabled">{{
                                $t('NFTInfo.charge-game')
                        }}</button>
                        <button class="button-buy" @click="showModal(11)" :disabled="buttonDisabled">
                            {{ $t('Dialog.transter') }}
                        </button>

                    </div>
                </template>
                <template v-if="(belongto === 'rentout' && isOwner) && currentTime > 0 && returnTimeArr.length > 0">
                    <div class="rentout-relet">
                        <div class="rentout-relet-left" v-if="currentTime > 0">
                            <div class="left-title">{{ $t('NFTInfo.rent-countdown') }}</div>
                            <div class="return-time">
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[0].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[0].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.days') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[1].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[1].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[2].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[2].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ returnTimeArr[3].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ returnTimeArr[3].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="relet-setting">
                            <div class="title">{{ $t('NFTInfo.rental-renewal') }}</div>
                            <el-switch :width="36" @change="reletsetchange()" :disabled="switchReletDisabled"
                                v-model="isSetRelet" />
                            <div class="switch-tip">{{ isSetRelet ? $t('NFTInfo.allowed') : $t('NFTInfo.not-allowed') }}
                            </div>
                        </div>
                    </div>

                </template>
                <div v-if="canrevert || canloan || canredeem" class="action-block align-items-start"
                    style="margin-top: 0;">
                    <template v-if="canloan">
                        <button :disabled="buttonDisabled" v-if="!nftApprove.loan" @click="showModal(16)"
                            class="button-buy nftinfo">Borrow {{ loanamount }}
                            {{ loansymbol }}</button>
                        <button :disabled="buttonDisabled" v-else @click="showModal(13)"
                            class="button-buy nftinfo">Borrow
                            {{
                                    loanamount
                            }}
                            {{ loansymbol }}</button>
                    </template>
                    <button v-if="canredeem" :disabled="buttonDisabled" @click="showModal(14)"
                        class="button-buy nftinfo">Redeem</button>
                    <div style="width: 44%" class="d-flex flex-column align-items-center" v-if="canrevert">
                        <button style="width: 100%" :disabled="buttonDisabled" v-if="!nftApprove.revert"
                            @click="showModal(12)" class="button-buy nftinfo">Return {{ revertrateamount }}
                            {{ revertratesymbol }}</button>
                        <button style="width: 100%" :disabled="buttonDisabled" v-else @click="revert()"
                            class="button-buy nftinfo">Return {{
                                    revertrateamount
                            }}
                            {{ revertratesymbol }}</button>
                        <div class="countdown-revert-block">
                            <template v-if="currentTime > 0 && revertTimeArr.length > 0">
                                <div class="tag">Remaining time:</div>
                                <div class="timetext">
                                    {{
                                            revertTimeArr[1].toString() + ":" + revertTimeArr[2].toString() + ":" +
                                            revertTimeArr[3].toString()
                                    }}
                                </div>
                            </template>

                        </div>
                    </div>

                </div>

                <div class="purchase-rent-title-block">
                    <div :class="historyIndexNumber === 0 ? 'active' : ''" class="title-item" style="cursor: pointer"
                        @click="historyIndexNumber = 0">
                        <div>Trends Chart</div>
                        <div class="line" style="height: 1PX"></div>
                    </div>
                    <div :class="historyIndexNumber === 1 ? 'active' : ''" class="title-item" style="cursor: pointer"
                        @click="historyIndexNumber = 1">
                        <div>{{ $t('NFTInfo.purchase-history') }}</div>
                        <div class="line" style="height: 1PX"></div>
                    </div>
                    <div class="title-item">|</div>
                    <div :class="historyIndexNumber === 2 ? 'active' : ''" class="title-item" style="cursor: pointer"
                        @click="historyIndexNumber = 2">
                        <div>{{ $t('NFTInfo.rent-history') }}</div>
                        <div class="line" style="height: 1PX"></div>
                    </div>
                </div>
                <div ref="nftChart" v-if="historyIndexNumber === 0" class="trade-record">
                    <v-chart :option="getNFTDataOption()" autoresize></v-chart>
                    <div class="rg-block-chart-period">
                        <el-radio-group v-model="chartPeriodIndex" @change="changeNFTChartPeriod">
                            <el-radio-button :label="0">7D</el-radio-button>
                            <el-radio-button :label="1">1M</el-radio-button>
                            <el-radio-button :label="2">1Y</el-radio-button>
                            <el-radio-button :label="3">ALL</el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div ref="divSaleHistory" v-if="historyIndexNumber === 1" class="trade-record">
                    <div class="title-block d-flex justify-content-between align-items-center">
                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.buyer') }}</div>
                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.seller') }}</div>
                        <div class="col-amount" style="flex: 1;">{{ $t('NFTInfo.price') }}</div>
                        <div class="col-time" style="flex: 1;">{{ $t('NFTInfo.time') }}</div>
                    </div>
                    <template v-if="saleHistoryList.length > 0">
                        <div class="data-item d-flex align-items-center justify-content-between"
                            v-for="(sale, saleIndex) of saleHistoryList" :key="'sale-' + saleIndex">
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">
                                    {{ sale['buyer'] }}
                                </div>
                            </div>
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">{{ sale['seller'] }}</div>
                            </div>
                            <div class="col-amount" style="flex: 1;">
                                <div class="inner-text">{{ sale['price'] }}
                                    {{ sale['paysymbol'] }}</div>
                            </div>
                            <div class="col-time" style="flex: 1;">
                                <div class="inner-text">
                                    {{ getTime(sale['closetime'])
                                    }}
                                </div>

                            </div>
                        </div>
                    </template>
                    <div v-else class="data-item d-flex align-items-center justify-content-center">
                        {{ $t('NFTInfo.no-data') }}
                    </div>
                </div>
                <div ref="divRentHistory" v-else-if="historyIndexNumber === 2" class="trade-record">
                    <div class="title-block d-flex justify-content-between align-items-center">
                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.renter') }}</div>
                        <div class="col-addr" style="flex: 1;">{{ $t('Common.days') }}</div>
                        <div class="col-amount" style="flex: 1;">{{ $t('NFTInfo.price') }}</div>
                        <div class="col-time" style="flex: 1;">{{ $t('NFTInfo.time') }}</div>
                    </div>
                    <template v-if="rentHistoryList.length > 0">
                        <div class="data-item d-flex align-items-center justify-content-between"
                            v-for="(rent, rentIndex) of rentHistoryList" :key="'rent-' + rentIndex">
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">{{ rent['lentout'] }}</div>
                            </div>
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">{{ rent['period'] / 3600 / 24
                                }}</div>
                            </div>
                            <div class="col-amount" style="flex: 1;">
                                <div class="inner-text">
                                    {{ rent['price'] }}
                                    {{ rent['paysymbol'] }}
                                </div>
                            </div>
                            <div class="col-time" style="flex: 1;">
                                <div class="inner-text">{{ getTime(rent['closetime'])
                                }}</div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="data-item d-flex align-items-center justify-content-center">
                        {{ $t('NFTInfo.no-data') }}
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>
<script lang="ts">
import * as echarts from 'echarts'
import { ethers } from 'ethers'
import { aggregate as aggregateNFTInfo } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElTooltip, ElRadioGroup, ElRadioButton } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
// import TapDesktop from '../abis/TapDesktop.json'
import ERC20 from '../abis/ERC20.json'
import ERC721 from '../abis/ERC721.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import TapDesktop from '../abis/TapDesktop.json'
import NFTLending from '../abis/NFTLending.json'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import NFTMysteryKeyBuyer from '../abis/NFTMysteryKeyBuyerV2.json'
import { isDebug, isUrBoxOpen } from '../constants/Config'
import { cloneDeep } from 'lodash'

interface ChartData {
    text: string,
    value: number
}

const BASE_OPTION = {
    darkMode: true,
    animationDuration: 1000,
    title: {
        subtext: `Trade Price / ${isDebug ? "T-USDT" : "USDT"}`,
        left: 0,
        top: 10,
        subtextStyle: {
            color: "#aaa"
        }
    },
    grid: {
        top: '25%',
        right: '5%',
        left: '10%',
        bottom: '20%',
        // containLabel: true
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLabel: {
            show: true,
            interval: 'auto',
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.5)',
                width: 0.2
            },
        },
        axisTick: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.4)'
            },
        },
        data: [],
    }],
    yAxis: [{
        type: 'value',
        max: function (value: any) {
            return Math.ceil(value.max * 1.5)
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.5)',
                width: 0.2
            },
        },
    }],
    series: [{
        type: 'line',
        name: 'Trade Price',
        data: [],
        areaStyle: {

        },
        showSymbol: false

    }]
}

const BASE_OPTION_MOBILE = {
    darkMode: true,
    animationDuration: 1000,
    title: {
        subtext: `Trade Price / ${isDebug ? "T-USDT" : "USDT"}`,
        left: 0,
        top: 10,
        subtextStyle: {
            color: "#aaa"
        }
    },
    grid: {
        top: '25%',
        right: '5%',
        left: '10%',
        bottom: '20%',
        // containLabel: true
    },
    tooltip: {
        trigger: 'axis',
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLabel: {
            show: true,
            interval: 'auto',
        },
        axisLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.5)',
                width: 0.2
            },
        },
        data: [],
    }],
    yAxis: [{
        type: 'value',
        max: function (value: any) {
            return Math.ceil(value.max * 1.5)
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.5)',
                width: 0.2
            },
        },
    }],
    series: [{
        type: 'line',
        name: 'Trade Price',
        data: [],
        areaStyle: {}

    }]
}

export default defineComponent({
    name: 'NFTInfo',
    components: { ElButton, ElLoading, ElRadioGroup, ElRadioButton },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        if (isShowModal.value) {
                            closeModal()
                        }
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        // const serverHost = ref(BLOCKCHAIN_CONFIG.serverHost)
        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isMobile = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const inputAmountBuy = ref(1)
        const inputWalletAddressTransfer = ref('')
        const belongto = ref('')
        const owner = ref('')
        const renter = ref('')
        const isOwner = ref(false)
        const isRenter = ref(false)
        const historyIndex = ref('0')
        const historyIndexNumber = ref(0)
        const indexApproveNFT = ref(-1)
        const isRenewable = ref(false)
        const isBorrowable = ref(false)
        let tokenId = 0
        let nftInfoAPIData: {
            revertcontract: string
            loancontract: string
            marketcontract: string
            ingamecontract: string
            lendingcontract: string
            nftrechargecontract: string
            nftassets: string
            rechargefee: number
            lendingfee: number
            lendingprice: number
            lendingperiod: number
            lendingperiodmin: number
            lendingperiodmax: number
            lendingguessprice: number
            lendingpaytokenid: number
            lendingtoken: string
            lendingtokensymbol: string
            lendingdecimals: number
            marketguessprice: number
            lendinglisttime: number
            lendingorderid: number
            lendingexpirytime: number
            lendingrenewable: number
            lendingborrowable: number
            tokenid: number
            chainid: number
            belongto: string
            tag: string
            owner: string
            info: {
                goodsid: number,
                typenft: boolean,
                pay: string,
                nft: string,
                orderid: number,
                stock: number,
                amount: number,
                price: number,
                timestamp: number,
                name: string,
                description: string,
                image: string,
                rarity: string,
                tokenid: number,
                level: number,
                earth: number,
                fire: number,
                water: number,
                wind: number,
                character: string,
                owner: string,
                preview: string,
                medium: string,
                feeRate: number
                typeid: number
            }
            saletoken: string
            saletokensymbol: string
            saledecimals: number
            salefeeRate: number
            salegoodsid: number
            canrevert: boolean
            canloan: boolean
            canredeem: boolean
            revertendtime: number
            revertrateamount: number
            revertratesymbol: string
            loanamount: number
            loansymbol: string
            loantokeninfo: {
                contract: string
                decimals: number
                description: string
                id: number
                image: string
                preview: string
                symbol: string
            }
        } | null = null
        const gameAccount = ref('')
        const nftApprove = ref({
            market: false,
            ingame: false,
            lending: false,
            revert: false,
            loan: false
        })
        let assetsAPIInfo = {
            goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
            mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            wallet: { accountid: 0, address: '', nickname: '' },
        }
        const nftInfo = ref({
            goodsid: 0,
            typenft: false,
            pay: '',
            nft: '',
            orderid: 0,
            stock: 0,
            amount: 0,
            price: 0,
            timestamp: 0,
            name: '',
            description: '',
            image: '',
            rarity: '',
            tokenid: 0,
            level: 0,
            earth: 0,
            fire: 0,
            water: 0,
            wind: 0,
            character: '',
            preview: '',
            medium: '',
            feeRate: 0,
            typeid: 0
        })

        interface goodsPayToken {
            approve: number,
            balance: string
            decimals: number,
            address: string,
            symbol: string,
            feerate: number
            price: number
        }
        const goodsPaySale: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })
        const goodsPayLend: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })
        const goodsPayRedeem: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })

        const rechargeInfo: Ref<any> = ref({
            fee: 0
        })

        const nftStateMap = {
            forsell: 'For Sale',
            rentout: 'Rent Out',
            rentin: 'Rent In',
            wallet: 'Collection',
            ingame: 'In Game',
            transaction: 'Transacting',
            forrent: 'For Rent',
            collateral: 'Collateral'
        }

        const saleHistoryList = ref([])
        const rentHistoryList = ref([])
        const inputAmount = ref('1')
        const inputDays = ref('1')
        const isRenewPrice = ref(true)
        const isSetRelet = ref(true)
        const switchReletDisabled = ref(false)
        const lendingInfo = ref({
            period: 0,
            listtime: 0,
            orderid: 0,
            expirytime: 0
        })

        const modalInfotip = ref('')
        const currentTime = ref(0)
        const currentTimeRemainTime = ref(0)
        const returnTimeArr: Ref<(string | number)[]> = ref([])
        const remainTimeArr: Ref<(string | number)[]> = ref([])
        const revertTimeArr: Ref<(string | number)[]> = ref([])
        let updateReturnTimeTask: NodeJS.Timer | null = null
        let updateRemainTimeTask: NodeJS.Timer | null = null
        let updateRevertTimeTask: NodeJS.Timer | null = null
        const isShowLoginLink = ref(false)
        let loadingInstance: any = null
        const pageSrcPara = ref({
            src: '',
            type: -1
        })

        const canrevert = ref(false)
        const canloan = ref(false)
        const canredeem = ref(false)
        const revertrateamount = ref(0)
        const revertratesymbol = ref('')
        const loanamount = ref(0)
        const loansymbol = ref('')
        const actionResultInfo = ref({
            action: -1,
            cardinfo: {
                tokenid: 0,
                typeid: 0,
                name: '',
                rarity: '',
                preview: '',
                price: 0,
                pricesymbol: '',
                amount: 0
            }
        })
        const nftChart = ref(null)
        const nftChartData = ref<ChartData[]>([])
        const chartPeriodIndex = ref(1)

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })

            const tokenid = router.currentRoute.value.params.id
            tokenId = Number(tokenid)

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            if (updateReturnTimeTask !== null) {
                clearInterval(Number(updateReturnTimeTask))
                updateReturnTimeTask = null
            }
            if (updateRemainTimeTask !== null) {
                clearInterval(Number(updateRemainTimeTask))
                updateRemainTimeTask = null
            }
            if (updateRevertTimeTask !== null) {
                clearInterval(Number(updateRevertTimeTask))
                updateRevertTimeTask = null
            }
        })

        const changeNFTChartPeriod = async () => {
            let para = 'week'
            switch (chartPeriodIndex.value) {
                case 0:
                    para = 'week'
                    break
                case 1:
                    para = 'month'
                    break
                case 2:
                    para = 'year'
                    break
                case 3:
                    para = 'all'
                    break
            }

            const urlNFTChartData = `${API.urlNFTChartData}${pageChainConfig.chainId}/${tokenId}/${para}`
            nftChartData.value = (await axios.get(urlNFTChartData)).data.data.list
        }

        const getNFTDataOption = () => {
            return getOption(nftChartData.value)
        }

        const getOption = (data: ChartData[]) => {
            let option: any

            if (!isMobile.value) {
                option = cloneDeep(BASE_OPTION)
            } else {
                option = cloneDeep(BASE_OPTION_MOBILE)
            }

            option.xAxis[0].data = data.map(item => item.text) as any
            option.series[0].data = data.map(item => item.value) as any
            return option;

        }

        const showModal = (modalIdx: number, indexANFT: number = -1) => {
            inputAmount.value = '1'
            if (modalIdx === 7) {
                inputAmount.value = (goodsPayLend.value.price / (lendingInfo.value.period / 3600 / 24)).toString()
            } else if (modalIdx === 6) {
                const card = nftInfo.value
                if (card.level === 0) {
                    modalInfotip.value = t('Infotip.the-nft-level-must-be-greater-than-0-before-it-can-be-rented')
                    isShowLoginLink.value = false
                    showModal(8)
                    return
                }
            } else if (modalIdx === 2) {
                const canRelet = canReletDisabled()
                if (canRelet > -1) {
                    if (canRelet === 0) {
                        modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-the-owner-has-set-the-condition-to-not-allow-renewal-of-the-rental')
                    } else if (canRelet === 1) {
                        modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-you-have-already-renewed-and-the-rental-terms-limit-the-length-of-the-rental-to-a-maximum-of-two-rental-periods')
                    }

                    isShowLoginLink.value = false
                    showModal(8)
                    return
                }
            }

            isShowModal.value = true
            modalShowIndex.value = modalIdx
            indexApproveNFT.value = indexANFT
        }

        const closeModal = () => {
            isShowModal.value = false
            modalShowIndex.value = -1
            modalInfotip.value = ''
            isShowLoginLink.value = false
            inputAmount.value = '1'

            // const rootEle = (root as any).value
            // rootEle.style.overflowY = 'auto'

            // const headerEle = (header as any).value.$refs.headerRef
            // headerEle.style.display = 'block'

        }

        const changeRadioGroup = () => {
            historyIndexNumber.value = parseInt(historyIndex.value, 10)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const reletsetchange = () => {
            if (!isSetRelet.value) {
                reletset()
            } else {
                showModal(10)
            }
        }

        const clearNoNum = (numStr: string) => {
            numStr = numStr.replace(/[^\d\.]/g, "");  //清除“数字”和“.”以外的字符
            numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const clearNoNumInteger = (numStr: string) => {
            numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
            numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const watchInput = () => {
            const price = inputAmount.value
            let input_num_str = price.toString()
            input_num_str = clearNoNum(input_num_str)
            inputAmount.value = input_num_str
        }

        const watchInputInteger = () => {
            const days = inputDays.value
            let input_num_str = days.toString()
            input_num_str = clearNoNumInteger(input_num_str)
            const input_num = parseInt(input_num_str, 10)
            const mindays = nftInfoAPIData!.lendingperiodmin / 3600 / 24
            const maxdays = nftInfoAPIData!.lendingperiodmax / 3600 / 24
            if (input_num < mindays) {
                inputDays.value = mindays.toString()
            } else if (input_num > maxdays) {
                inputDays.value = maxdays.toString()
            } else {
                inputDays.value = input_num_str
            }

        }

        const goBack = () => {
            // if (!isMobile.value) {
            //     window.opener = null;
            //     window.open("about:blank", "_top")!.close()

            // } else {
            // const src = router.currentRoute.value.params.src
            // const type = router.currentRoute.value.params.type
            // const page = router.currentRoute.value.params.page
            // const url = src === 'mp' ? `/marketplace/` : `/mynft/${type}/${page}/`
            // router.push(
            //     url
            // )
            // }
            router.back()
            // const src = router.currentRoute.value.params.src
            // if (src && src.length > 0) {
            //     const type = router.currentRoute.value.params.type
            //     const page = router.currentRoute.value.params.page
            //     const url = src === 'mp' ? `/marketplace/` : `/mynft/${type}/${page}/`
            //     router.push(
            //         url
            //     )
            // } else {
            //     router.back()
            // }


        }

        const dealLoan = (nftPara: any) => {
            closeModal()
            actionResultInfo.value = {
                action: 2,
                cardinfo: nftPara
            }
            showModal(17)
            loadData()
        }

        const dealRedeem = (nftPara: any) => {
            closeModal()
            actionResultInfo.value = {
                action: 3,
                cardinfo: nftPara
            }
            showModal(17)
            loadData()
        }

        const getApproveTokenAndNeedAmount = (optType: number) => {
            const payToken = optType === 0 ? goodsPaySale.value : (optType === 1 ? goodsPayLend.value : goodsPayRedeem.value)
            const amount = optType === 0 ? (1 * nftInfo.value['price']) : (optType === 1 ? goodsPayLend.value.price : 0)

            return {
                symbol: payToken.symbol,
                amount: new BigNumber(amount).toNumber()
            }
        }

        const checkApprove = async (optType: number) => {
            const payToken = optType === 0 ? goodsPaySale.value : (optType === 1 ? goodsPayLend.value : goodsPayRedeem.value)
            const contractAddr = optType === 0 ? nftInfoAPIData?.marketcontract : (optType === 1 ? nftInfoAPIData?.lendingcontract : nftInfoAPIData?.loancontract)

            const { walletAddress } = toRefs(props)

            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount(optType).amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approve = async (optType: number) => {
            let action = ''
            switch (optType) {
                case 0:
                    action = 'sale'
                    break
                case 1:
                    action = 'rent'
                    break
                case 2:
                    action = 'redeem'
                    break
            }
            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request-' + action)
            const cardInfo = nftInfo.value
            const payToken = optType === 0 ? goodsPaySale.value : (optType === 1 ? goodsPayLend.value : goodsPayRedeem.value)
            const contractAddr = optType === 0 ? nftInfoAPIData?.marketcontract : (optType === 1 ? nftInfoAPIData?.lendingcontract : nftInfoAPIData?.loancontract)

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': payToken.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = '0'
                    contractToken
                        .approve(contractAddr, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.allowance(
                                        walletAddress.value,
                                        contractAddr
                                    )
                                    payToken.approve = approveBN / (10 ** payToken.decimals)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': payToken.symbol })
                                    })
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success-' + action)
                                    if (optType === 0) {
                                        if (isOwner.value) {
                                            sell()
                                        } else {
                                            showModal(3)
                                        }

                                    } else if (optType === 1) {
                                        if (isOwner.value) {
                                            rentout()
                                        } else {
                                            showModal(4)
                                        }
                                    } else if (optType === 2) {
                                        showModal(14)
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade-' + action)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request-' + action)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                            })
                        })
                }
            }
        }

        const approveNFT = async (optType: number) => {
            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-request-nft`)
            const { walletAddress } = toRefs(props)
            let approveAddr = ''
            switch (optType) {
                case 0:
                    approveAddr = nftInfoAPIData!.marketcontract
                    break;
                case 1:
                    approveAddr = nftInfoAPIData!.lendingcontract
                    break;
                case 2:
                    approveAddr = nftInfoAPIData!.nftrechargecontract
                    break;
                case 3:
                    approveAddr = nftInfoAPIData!.revertcontract
                    break;
                case 4:
                    approveAddr = nftInfoAPIData!.loancontract
                    break;
                default:
                    break;
            }
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        nftInfoAPIData!.nftassets,
                        ERC721,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-nft'),
                        autoClose: false,
                        canClose: false
                    })
                    contractToken
                        .setApprovalForAll(approveAddr, true)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.isApprovedForAll(
                                        walletAddress.value,
                                        approveAddr
                                    )
                                    closeModal()
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-nft-successful')
                                    })
                                    switch (optType) {
                                        case 0:
                                            nftApprove.value.market = true
                                            showModal(5)
                                            break;
                                        case 1:
                                            nftApprove.value.lending = true
                                            if (belongto.value === 'wallet') {
                                                showModal(6)
                                            } else if (belongto.value === 'forrent') {
                                                showModal(7)
                                            }

                                            break;
                                        case 2:
                                            nftApprove.value.ingame = true
                                            charge()
                                            break;
                                        case 3:
                                            nftApprove.value.revert = true
                                            revert()
                                            break;
                                        case 4:
                                            nftApprove.value.loan = true
                                            showModal(8)
                                            break;
                                        default:
                                            break;
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-trade`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-nft-error')
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-request`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-nft-error')
                            })
                        })
                }
            }
        }

        const buy = async () => {
            const flagApprove = await checkApprove(0)
            if (!flagApprove) {
                // createNotification({
                //     type: 'error',
                //     message: `Your token approve count is not enough`,
                // })
                showModal(1)
                return
            }
            TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-request')
            const cardInfo = nftInfo.value
            const id = cardInfo['tokenid']
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractDesktop = new ethers.Contract(
                            nftInfoAPIData!.marketcontract,
                            TapDesktop,
                            signer
                        )
                        const price = (1 * cardInfo['price'])
                        if ((price) > Number(goodsPaySale.value.balance)) {
                            createNotification({
                                type: 'error',
                                message: `${t('Infotip.action-nft-nftinfo', { 'action': 'Buy', 'id': id, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': goodsPaySale.value.symbol })}`,
                            })
                            return
                        }
                        const amount = 1
                        // const amountBN = ethers.utils.parseUnits(amount.toString(), 18).toString()
                        // LogUtils.debug(`${cardInfo['orderid']}|${amountBN}`)
                        const sendPromise = contractDesktop.marketBuy(cardInfo['orderid'], amount)

                        const uuid = createNotification({
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buying'), 'id': id, 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.successful') })}`
                                    })
                                    TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    closeModal()
                                    loadData()
                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                                    duration: 5
                                })
                            })
                    } catch (e) {
                        console.error(e)
                        TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-request')
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                        })
                    }
                }
            }
        }

        //-1 can 0-renew disable 1-renew count >=2
        const canReletDisabled = () => {
            if (!isRenewable) {
                return 0
            } else {
                const rentCount = ((lendingInfo.value.expirytime - currentTime.value) / (lendingInfo.value.period))
                if (rentCount >= 2) {
                    return 1
                }
            }
            return -1
        }

        const rent = async (isRelet: boolean = false) => {
            const actionlabel = isRelet ? 'relet' : 'rent'
            TOOL.gtmTrack(actionlabel + '-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, actionlabel + '-request')
            const cardInfo = nftInfo.value
            const action = isRelet ? t('Infotip.action-relet') : t('Infotip.action-rent')
            const actioning = isRelet ? t('Infotip.action-releting') : t('Infotip.action-renting')
            const id = cardInfo['tokenid']
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const canRelet = canReletDisabled()
                    if (canRelet > -1) {
                        if (canRelet === 0) {
                            modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-the-owner-has-set-the-condition-to-not-allow-renewal-of-the-rental')
                        } else if (canRelet === 1) {
                            modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-you-have-already-renewed-and-the-rental-terms-limit-the-length-of-the-rental-to-a-maximum-of-two-rental-periods')
                        }

                        isShowLoginLink.value = false
                        showModal(8)
                        return
                    }
                    if (typeof gameAccount.value === 'undefined' || gameAccount.value.length === 0) {
                        modalInfotip.value = `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.before-renting-nft-please-bind-your-wallet-address-to-your-game-account')}`
                        isShowLoginLink.value = true
                        showModal(8)
                        return
                    }
                    const flagApprove = await checkApprove(1)
                    if (!flagApprove) {
                        // createNotification({
                        //     type: 'error',
                        //     message: `Your token approve count is not enough`,
                        // })
                        showModal(2)
                        return
                    }
                    try {
                        const signer = provider.getSigner()
                        const contractLending = new ethers.Contract(
                            nftInfoAPIData!.lendingcontract,
                            NFTLending,
                            signer
                        )
                        const price = goodsPayLend.value.price
                        if ((price) > Number(goodsPayLend.value.balance)) {
                            createNotification({
                                type: 'error',
                                message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': goodsPayLend.value.symbol })}`,
                            })
                            return
                        }
                        // const amountBN = ethers.utils.parseUnits(amount.toString(), 18).toString()
                        // LogUtils.debug(`${cardInfo['orderid']}|${amountBN}`)
                        const sendPromise = contractLending.onBorrow(lendingInfo.value.orderid)

                        const uuid = createNotification({
                            message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': actioning, 'id': cardInfo.tokenid, 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.successful') })}`
                                    })
                                    TOOL.gtmTrack(actionlabel + '-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, actionlabel + '-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    closeModal()
                                    loadData()
                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack(actionlabel + '-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, actionlabel + '-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                let message = null
                                if (error.data && error.data.message) {
                                    message = error.data.message
                                } else if (error.message) {
                                    message = error.message
                                }

                                if (message) {
                                    if (message.indexOf('renewable!') >= 0) {
                                        // createNotification({
                                        //     type: 'error',
                                        //     message: `${action} NFT(#${id}) error: this NFT can not relet!`,
                                        //     duration: 5
                                        // })
                                        modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-the-owner-has-set-the-condition-to-not-allow-renewal-of-the-rental')
                                        isShowLoginLink.value = false
                                        showModal(8)
                                        return
                                    } else if (message.indexOf('periodx2!') >= 0) {
                                        modalInfotip.value = t('Infotip.you-cannot-currently-renew-the-rental-because-you-have-already-renewed-and-the-rental-terms-limit-the-length-of-the-rental-to-a-maximum-of-two-rental-periods')
                                        isShowLoginLink.value = false
                                        showModal(8)
                                        return
                                    } else {
                                        createNotification({
                                            type: 'error',
                                            message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.error') + ': ' + message })}`,
                                            duration: 5
                                        })
                                    }
                                } else {
                                    createNotification({
                                        type: 'error',
                                        message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.error') })}`,
                                        duration: 5
                                    })
                                }

                            })
                    } catch (e: any) {
                        console.error(e)
                        TOOL.gtmTrack(actionlabel + '-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, actionlabel + '-error-request')
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-rent-nft-nftinfo', { 'action': action, 'id': cardInfo.tokenid, 'state': t('Common.error') })}`,
                            duration: 5
                        })
                    }
                }
            }
        }

        const sell = async () => {
            TOOL.gtmTrack('sell-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `sell-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const price = parseFloat(inputAmount.value)
                if (inputAmount.value.trim().length === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-sell-price-can-not-be-empty')
                    })
                    return
                } else if (price === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-sell-price-can-not-be-zero')
                    })
                    return
                }
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractDesktop = new ethers.Contract(
                        nftInfoAPIData!.marketcontract,
                        TapDesktop,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': card['tokenid'], 'state': '' }) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString())
                    const priceBN = ethers.utils.parseUnits(price.toString(), 18).toString()
                    const tokenid = Number(card['tokenid']).toString()
                    const tokenidBN = tokenid
                    const type = 0
                    const sendPromise = contractDesktop.marketSell(type, tokenidBN, priceBN)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-success-nft`)

                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })

                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-list'), 'id': tokenid, 'state': t('Common.successful') })
                                    })
                                    closeModal()
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': tokenid, 'state': t('Common.error') })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': tokenid, 'state': t('Common.error') })
                            })
                        })
                }
            }
        }

        const cancelOrderSell = async () => {
            TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const provider = await getWalletProvider()
                if (provider) {
                    const orderid = card['orderid']
                    const signer = provider.getSigner()
                    const contractDesktop = new ethers.Contract(
                        nftInfoAPIData!.marketcontract,
                        TapDesktop,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), card['tokenid'], orderid], ''),
                        autoClose: false,
                        canClose: false
                    })

                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString()) }

                    const sendPromise = contractDesktop.marketCancel(orderid)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), card['tokenid'], orderid], t('Common.successful'))
                                    })
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), card['tokenid'], orderid], t('Common.error'))
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), card['tokenid'], orderid], t('Common.error'))
                            })
                        })
                }
            }
        }

        const rentout = async () => {
            TOOL.gtmTrack('rentout-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `rentout-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const price = parseFloat(inputAmount.value)
                const days = parseFloat(inputDays.value)
                if (inputAmount.value.trim().length === 0 || inputDays.value.trim().length === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-rentout-price-or-days-can-not-be-empty')
                    })
                    return
                } else if (price === 0 || days === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-rentout-price-or-days-can-not-be-zero')
                    })
                    return
                }

                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractLending = new ethers.Contract(
                        nftInfoAPIData!.lendingcontract,
                        NFTLending,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-rentout-nft-nftinfo', [t('Infotip.action-rentouting'), card['tokenid'], '']) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString())
                    const period = parseInt(Number(days * 3600 * 24).toString(), 10)
                    const priceBN = ethers.utils.parseUnits((price * days).toString(), 18).toString()
                    const tokenid = card['tokenid']
                    const tokenidBN = tokenid.toString()
                    const paytokenid = nftInfoAPIData!.lendingpaytokenid
                    const paytokenidBN = paytokenid.toString()


                    // LogUtils.debug(
                    //     {
                    //         tokenidBN: tokenidBN,
                    //         paytokenidBN: paytokenidBN,
                    //         priceBN: priceBN,
                    //         period: period,
                    //         isRenewPrice: isRenewPrice.value
                    //     }
                    // )

                    const sendPromise = contractLending.onRentOut(tokenidBN, paytokenidBN, priceBN, period, isRenewPrice.value)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('rentout-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `rent-success-nft`)

                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })

                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-rentout-nft-nftinfo', [t('Infotip.action-rentout'), card['tokenid'], t('Common.successful')]),
                                    })
                                    closeModal()
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('rentout-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `rentout-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-rentout-nft-nftinfo', [t('Infotip.action-rentout'), card['tokenid'], t('Common.error')]),
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('rentout-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `rentout-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-rentout-nft-nftinfo', [t('Infotip.action-rentout'), card['tokenid'], t('Common.error')]),
                            })
                        })
                }
            }
        }

        const changeprice = async () => {
            TOOL.gtmTrack('changeprice-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `changeprice-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const price = parseFloat(inputAmount.value)
                if (inputAmount.value.trim().length === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-rentout-price-can-not-be-empty')
                    })
                    return
                } else if (price === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-rentout-price-can-not-be-zero')
                    })
                    return
                }

                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractLending = new ethers.Contract(
                        nftInfoAPIData!.lendingcontract,
                        NFTLending,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-change-nft-price-ntfinfo', [t('Infotip.action-changingprice'), card['tokenid'], '']) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString())
                    const priceBN = ethers.utils.parseUnits((price * (lendingInfo.value.period / 3600 / 24)).toString(), 18).toString()
                    const tokenid = card['tokenid']
                    const tokenidBN = tokenid.toString()
                    const paytokenid = nftInfoAPIData!.lendingpaytokenid
                    const paytokenidBN = paytokenid.toString()
                    const orderid = lendingInfo.value.orderid

                    LogUtils.debug(
                        {
                            tokenidBN: tokenidBN,
                            paytokenidBN: paytokenidBN,
                            priceBN: priceBN,
                            isRenewPrice: isRenewPrice.value
                        }
                    )

                    const sendPromise = contractLending.onEditPrice(orderid, priceBN)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('changeprice-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `changeprice-success-nft`)

                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })

                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-change-nft-price-ntfinfo', [t('Infotip.action-changingprice'), card['tokenid'], t('Common.successful')])
                                    })
                                    closeModal()
                                    loadData()
                                    if (belongto.value === 'rentout') {
                                        modalInfotip.value = t('Infotip.you-have-modified-the-price-the-new-price-will-take-effect-after-the-expiration-of-this-rental-period')
                                        isShowLoginLink.value = false
                                        showModal(8)
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('changeprice-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `changeprice-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-change-nft-price-ntfinfo', [t('Infotip.action-changingprice'), card['tokenid'], t('Common.error')])
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('changeprice-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `changeprice-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-change-nft-price-ntfinfo', [t('Infotip.action-changingprice'), card['tokenid'], t('Common.error')])
                            })
                        })
                }
            }
        }

        const reletset = async () => {
            switchReletDisabled.value = true
            TOOL.gtmTrack('relet-switch-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `switch-relet-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const provider = await getWalletProvider()
                if (provider) {
                    const orderid = lendingInfo.value.orderid
                    const signer = provider.getSigner()
                    const contractLending = new ethers.Contract(
                        nftInfoAPIData!.lendingcontract,
                        NFTLending,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-set-relet-nft-nftinfo', [t('Infotip.action-setreleting'), card['tokenid'], '']) + '...',
                        autoClose: false,
                        canClose: false
                    })

                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString()) }

                    const sendPromise = contractLending.onEditRenewable(orderid, isSetRelet.value)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('switch-relet-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `switch-relet-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-set-relet-nft-nftinfo', [t('Infotip.action-setrelet'), card['tokenid'], t('Common.successful')])
                                    })
                                    switchReletDisabled.value = false
                                    closeModal()
                                })
                                .catch((error: any) => {
                                    switchReletDisabled.value = false
                                    TOOL.gtmTrack('switch-relet-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `switch-relet-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-set-relet-nft-nftinfo', [t('Infotip.action-setrelet'), card['tokenid'], t('Common.error')])
                                    })
                                    isSetRelet.value = !isSetRelet.value
                                })
                        })
                        .catch((error: any) => {
                            switchReletDisabled.value = false
                            TOOL.gtmTrack('switch-relet-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `switch-relet-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-set-relet-nft-nftinfo', [t('Infotip.action-setrelet'), card['tokenid'], t('Common.error')])
                            })
                            isSetRelet.value = !isSetRelet.value
                        })
                }
            }
        }

        const claimRentNFT = async () => {
            TOOL.gtmTrack('claimrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `claimrent-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const provider = await getWalletProvider()
                if (provider) {
                    const orderid = lendingInfo.value.orderid
                    const signer = provider.getSigner()
                    const contractLending = new ethers.Contract(
                        nftInfoAPIData!.lendingcontract,
                        NFTLending,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-claim-rentout-nft-nftinfo', [t('Infotip.action-claiming'), card['tokenid'], orderid, '']) + '...',
                        autoClose: false,
                        canClose: false
                    })

                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString()) }

                    const sendPromise = contractLending.onClaim(orderid)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('claimrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `claimrent-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-claim-rentout-nft-nftinfo', [t('Infotip.action-claim'), card['tokenid'], orderid, t('Common.successful')]),
                                    })
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('claimrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `claimrent-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-claim-rentout-nft-nftinfo', [t('Infotip.action-claim'), card['tokenid'], orderid, t('Common.error')]),
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('claimrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `claimrent-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-claim-rentout-nft-nftinfo', [t('Infotip.action-claim'), card['tokenid'], orderid, t('Common.error')]),
                            })
                        })
                }
            }
        }

        const cancelOrderRent = async () => {
            TOOL.gtmTrack('unrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unrent-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const provider = await getWalletProvider()
                if (provider) {
                    const orderid = lendingInfo.value.orderid
                    const signer = provider.getSigner()
                    const contractLending = new ethers.Contract(
                        nftInfoAPIData!.lendingcontract,
                        NFTLending,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-cance-rent-nftinfo', [t('Infotip.action-cancel-renting'), card['tokenid'], orderid, '']) + '...',
                        autoClose: false,
                        canClose: false
                    })

                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString()) }

                    const sendPromise = contractLending.onEditBorrowable(orderid, false)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('unrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unrent-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-cance-rent-nftinfo', [t('Infotip.action-cancel-rent'), card['tokenid'], orderid, t('Common.successful')]),
                                    })
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('unrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unrent-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-cance-rent-nftinfo', [t('Infotip.action-cancel-rent'), card['tokenid'], orderid, t('Common.error')]),
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('unrent-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unrent-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-cance-rent-nftinfo', [t('Infotip.action-cancel-rent'), card['tokenid'], orderid, t('Common.error')]),
                            })
                        })
                }
            }
        }

        const charge = async () => {
            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-request-nft`)
            const tokenid = nftInfo.value.tokenid
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    if (typeof gameAccount.value === 'undefined' || gameAccount.value.length === 0) {
                        modalInfotip.value = `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.before-charging-nft-please-bind-your-wallet-address-to-your-game-account')}`
                        isShowLoginLink.value = true
                        showModal(8)
                        return
                    }

                    const nftingameAddr = nftInfoAPIData!.ingamecontract

                    let calls: any[] = []
                    let returnValue = null

                    // LogUtils.debug(nftingameAddr)

                    calls = calls.concat([
                        {
                            target: nftingameAddr,
                            call: [
                                'tokenOutOwner(uint256)(address)',
                                tokenid
                            ],
                            returns: [['NFT_TOKEN_OUT_OWNER', (val: any) => val]]
                        },
                        {
                            target: nftingameAddr,
                            call: [
                                'tokenCoolTime(uint256)(uint256)',
                                tokenid
                            ],
                            returns: [['NFT_TOKEN_COOL_TIME', (val: any) => val.toNumber()]]
                        },
                        {
                            target: pageChainConfig.multicallConfig.multicallAddress,
                            call: [
                                'getCurrentBlockTimestamp()(uint256)',
                            ],
                            returns: [['CURRENT_TIMESTAMP', (val: any) => val.toNumber()]]
                        },
                    ])

                    LogUtils.debug(`${tokenid} - ${nftingameAddr} - ${pageChainConfig.multicallConfig.multicallAddress}`)

                    returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                    const returnResult = returnValue.results.transformed

                    LogUtils.debug(returnResult)

                    const nftTokenOutOwner = returnResult.NFT_TOKEN_OUT_OWNER
                    const currentTime = returnResult.CURRENT_TIMESTAMP
                    const nftTokenCoolTime = returnResult.NFT_TOKEN_COOL_TIME

                    if (nftTokenCoolTime !== 0 && nftTokenOutOwner.toLowerCase() !== BLOCKCHAIN_CONFIG.ZERO_ADDRESS) {
                        if (nftTokenOutOwner.toLowerCase() !== walletAddress.value.toLowerCase()) {
                            if (currentTime <= nftTokenCoolTime) {
                                createNotification({
                                    type: 'error',
                                    message: t('Infotip.the-current-nft-is-purchased-from-a-non-whitelisted-platform-and-it-takes-24-hours-to-transfer-to-the-game'),
                                    duration: 6
                                })
                                return
                            }
                        }
                    }

                    const feeNumber = Number(nftInfoAPIData?.rechargefee)
                    const options = { value: ethers.utils.parseEther(feeNumber.toString()) }
                    const gas = await provider.getBalance(walletAddress.value)
                    const gasNumber = new BigNumber(gas.toString()).div(10 ** 18).toNumber()

                    // LogUtils.debug(gasNumber)
                    // LogUtils.debug(feeNumber)

                    if (gasNumber < feeNumber) {
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.not-enough-bnb-available-as-gas-to-transfer')}`
                        })
                        return
                    }

                    const signer = provider.getSigner()
                    const contractIngame = new ethers.Contract(
                        nftInfoAPIData!.nftrechargecontract,
                        TapDesktop,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charging'), tokenid, '']) + '...',
                        autoClose: false,
                        canClose: false
                    })

                    contractIngame
                        .gameNFTIn(tokenid, options)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.successful')])
                                    })
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                            })
                        })
                }
            }
        }

        const transfer = async () => {
            const address = inputWalletAddressTransfer.value.trim()
            const flag = ethers.utils.isAddress(address)

            if (!flag) {
                createNotification({
                    type: 'error',
                    message: "Your reciece wallet address is invaild",
                    duration: 6
                })
                return
            } else {
                TOOL.gtmTrack('transfer-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-request-nft`)
                const { walletAddress } = toRefs(props)
                if (walletAddress.value.length > 0) {
                    const provider = await getWalletProvider()
                    if (provider) {
                        const signer = provider.getSigner()
                        const contractToken = new ethers.Contract(
                            nftInfoAPIData!.nftassets,
                            ERC721,
                            signer
                        )
                        const sendPromise = contractToken["safeTransferFrom(address,address,uint256)"](walletAddress.value, address, nftInfo.value.tokenid)
                        const uuid = createNotification({
                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfering'), nftInfo.value.tokenid, address, '']) + '...',
                            autoClose: false,
                            canClose: false
                        })
                        buttonDisabled.value = true
                        sendPromise
                            .then(async function (transaction: any) {
                                transaction
                                    .wait()
                                    .then(async function (transaction: any) {
                                        TOOL.gtmTrack('transfer-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-success-nft`)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'success',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftInfo.value.tokenid, address, t('Common.successful')]),
                                        })
                                        setTimeout(() => {
                                            buttonDisabled.value = false
                                            closeModal()
                                            loadData()
                                        }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)
                                    })
                                    .catch((error: any) => {
                                        TOOL.gtmTrack('transfer-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-trade-nft`)
                                        console.error(error)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'error',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftInfo.value.tokenid, address, t('Common.error')]),
                                        })
                                        buttonDisabled.value = false
                                        closeModal()
                                    })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('transfer-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-request-nft`)
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftInfo.value.tokenid, address, t('Common.error')]),
                                })
                                buttonDisabled.value = false
                                closeModal()
                            })
                    }
                }
            }
        }

        const revert = async () => {
            const tokenIdPara = nftInfo.value.tokenid
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractRevertURCard = new ethers.Contract(
                            nftInfoAPIData!.revertcontract,
                            NFTMysteryKeyBuyer,
                            signer
                        )
                        const sendPromise = contractRevertURCard.onRevert(tokenIdPara)
                        const uuid = createNotification({
                            message: `Revert UR Card(#${tokenIdPara})...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'success',
                                    message: `Revert UR Card(#${tokenIdPara}) successful`
                                })
                                actionResultInfo.value = {
                                    action: 1,
                                    cardinfo: {
                                        tokenid: nftInfo.value.tokenid,
                                        typeid: nftInfo.value.typeid,
                                        name: nftInfo.value.name,
                                        preview: nftInfo.value.preview,
                                        rarity: nftInfo.value.rarity,
                                        price: nftInfoAPIData!.revertrateamount,
                                        pricesymbol: nftInfoAPIData!.revertratesymbol,
                                        amount: 1
                                    }
                                }
                                showModal(17)
                                loadData()

                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('reverturcard-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'reverturcard-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `Revert UR Card(#${tokenIdPara}) error`
                                })
                            })
                    } catch (e) {
                        TOOL.gtmTrack('reverturcard-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'reverturcard-error-request')
                        console.error(e)
                        createNotification({
                            type: 'error',
                            message: `Revert UR Card(#${tokenIdPara}) error`
                        })
                    }
                }

            }
        }

        const loadData = async () => {
            buttonDisabled.value = true
            // if (loadingInstance === null) {
            loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            // }

            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)



            const { walletAddress } = toRefs(props)
            LogUtils.debug(walletAddress.value)

            const wa = walletAddress.value.length > 0 ? walletAddress.value : BLOCKCHAIN_CONFIG.ZERO_ADDRESS

            const urlNFTInfo = `${API.nftInfo}${chainId}/${tokenId}/${wa}/`
            nftInfoAPIData = (await axios.get(urlNFTInfo)).data.data
            if (nftInfoAPIData) {
                // nftInfoAPIData.revertendtime = 1680117454
                // nftInfoAPIData.canrevert = true

                nftInfo.value = nftInfoAPIData.info
                belongto.value = nftInfoAPIData.belongto
                isOwner.value = walletAddress.value.toLowerCase() === nftInfoAPIData.owner.toLowerCase()
                owner.value = nftInfoAPIData.owner
                lendingInfo.value.period = nftInfoAPIData.lendingperiod
                lendingInfo.value.listtime = nftInfoAPIData.lendinglisttime
                lendingInfo.value.orderid = nftInfoAPIData.lendingorderid
                lendingInfo.value.expirytime = nftInfoAPIData.lendingexpirytime
                isBorrowable.value = nftInfoAPIData.lendingborrowable > 0 ? true : false
                isRenewable.value = nftInfoAPIData.lendingrenewable > 0 ? true : false
                canrevert.value = nftInfoAPIData.canrevert
                canloan.value = nftInfoAPIData.canloan
                canredeem.value = nftInfoAPIData.canredeem
                loanamount.value = nftInfoAPIData.loanamount
                loansymbol.value = nftInfoAPIData.loansymbol
                revertratesymbol.value = nftInfoAPIData.revertratesymbol
                revertrateamount.value = nftInfoAPIData.revertrateamount

                const paySale: goodsPayToken = {
                    approve: 0,
                    balance: '',
                    decimals: nftInfoAPIData.saledecimals,
                    address: nftInfoAPIData.saletoken,
                    symbol: nftInfoAPIData.saletokensymbol,
                    feerate: nftInfoAPIData.salefeeRate,
                    price: nftInfoAPIData.info.price
                }

                const payLending: goodsPayToken = {
                    approve: 0,
                    balance: '',
                    decimals: nftInfoAPIData.lendingdecimals,
                    address: nftInfoAPIData.lendingtoken,
                    symbol: nftInfoAPIData.lendingtokensymbol,
                    feerate: nftInfoAPIData.lendingfee,
                    price: nftInfoAPIData.lendingprice
                }

                const payRedeem: goodsPayToken = canredeem.value ? {
                    approve: 0,
                    balance: '',
                    decimals: nftInfoAPIData.loantokeninfo.decimals,
                    address: nftInfoAPIData.loantokeninfo.contract,
                    symbol: nftInfoAPIData.loantokeninfo.symbol,
                    feerate: 0,
                    price: 0
                } : {
                    approve: 0,
                    balance: '',
                    decimals: 0,
                    address: '',
                    symbol: '',
                    feerate: 0,
                    price: 0
                }

                const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)
                const currentBlock = (await jsonRPCProvider.getBlock('latest'))

                currentTime.value = currentBlock.timestamp
                currentTimeRemainTime.value = currentBlock.timestamp

                if (canrevert.value) {
                    if (updateRevertTimeTask === null) {
                        updateRevertTimeTask = setInterval(() => {
                            currentTime.value = currentTime.value + 1
                            const delta = nftInfoAPIData!.revertendtime - currentTime.value
                            revertTimeArr.value = TOOL.dateTransformLong(delta)
                            if (delta === 0) {
                                clearInterval(Number(updateRevertTimeTask))
                                updateRevertTimeTask = null
                                canrevert.value = false
                            }
                        }, 1000)
                    }
                }

                if (belongto.value === 'rentin' || belongto.value === 'rentout') {
                    if (updateReturnTimeTask === null) {
                        updateReturnTimeTask = setInterval(() => {
                            currentTime.value = currentTime.value + 1
                            const delta = nftInfoAPIData!.lendingexpirytime - currentTime.value
                            returnTimeArr.value = TOOL.dateTransformLong(delta)
                            if (delta === 0) {
                                clearInterval(Number(updateReturnTimeTask))
                                updateReturnTimeTask = null
                                loadData()
                            }
                        }, 1000)
                    }
                }

                if (belongto.value === 'forrent') {
                    if (updateRemainTimeTask === null) {
                        updateRemainTimeTask = setInterval(() => {
                            const lendingDays = lendingInfo.value.period / 3600 / 24
                            const lendingAvailableDays = lendingDays - 1
                            currentTimeRemainTime.value = currentTimeRemainTime.value + 1
                            const momentToday = moment(currentTimeRemainTime.value * 1000).utc();
                            const momnetTodaySecs = momentToday.seconds() + 60 * momentToday.minutes() + 60 * 60 * momentToday.hours()
                            const deltaDaySecsRemain = 86400 - momnetTodaySecs + 86400 * lendingAvailableDays
                            remainTimeArr.value = TOOL.dateTransformLong(deltaDaySecsRemain)

                        }, 1000)
                    }
                }

                if (walletAddress.value.length > 0) {
                    const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`

                    axios.get(urlAssetsAPIInfo)
                        .then(async (response) => {
                            assetsAPIInfo = response.data.data
                            gameAccount.value = assetsAPIInfo!.wallet.nickname

                            buttonDisabled.value = false
                            let calls: any[] = []
                            let returnValue = null

                            LogUtils.debug(`Get Approve Start(${walletAddress.value})...`)

                            calls = calls.concat([
                                {
                                    target: paySale.address,
                                    call: ['balanceOf(address)(uint256)', walletAddress.value],
                                    returns: [['PAYSALE_BALANCE', (val: any) => val]]
                                },
                                {
                                    target: paySale.address,
                                    call: [
                                        'allowance(address,address)(uint256)',
                                        walletAddress.value,
                                        nftInfoAPIData!.marketcontract
                                    ],
                                    returns: [['PAYSALE_APPROVE', (val: any) => val / 10 ** paySale.decimals]]
                                },
                                {
                                    target: payLending.address,
                                    call: ['balanceOf(address)(uint256)', walletAddress.value],
                                    returns: [['PAYLEND_BALANCE', (val: any) => val]]
                                },
                                {
                                    target: payLending.address,
                                    call: [
                                        'allowance(address,address)(uint256)',
                                        walletAddress.value,
                                        nftInfoAPIData!.lendingcontract
                                    ],
                                    returns: [['PAYLEND_APPROVE', (val: any) => val / 10 ** payLending.decimals]]
                                },
                            ])

                            returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                            const returnResult = returnValue.results.transformed

                            calls = []
                            paySale.balance = TOOL.scientificToNumber(
                                new BigNumber(returnResult['PAYSALE_BALANCE'].toString())
                                    .div(10 ** paySale.decimals)
                                    .toString()
                            )
                            paySale.approve = returnResult['PAYSALE_APPROVE']


                            payLending.balance = TOOL.scientificToNumber(
                                new BigNumber(returnResult['PAYLEND_BALANCE'].toString())
                                    .div(10 ** payLending.decimals)
                                    .toString()
                            )
                            payLending.approve = returnResult['PAYLEND_APPROVE']

                            goodsPaySale.value = paySale
                            goodsPayLend.value = payLending

                            LogUtils.debug(payLending)
                            LogUtils.debug('Get Approve End...')
                        })
                        .catch((e) => {

                        })

                    // assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
                } else {
                    goodsPaySale.value = paySale
                    goodsPayLend.value = payLending
                }

                if (canredeem.value && walletAddress.value.length > 0) {
                    let calls: any[] = []
                    let returnValue = null
                    calls = calls.concat([
                        {
                            target: payRedeem.address,
                            call: ['balanceOf(address)(uint256)', walletAddress.value],
                            returns: [['PAYREDEEM_BALANCE', (val: any) => val]]
                        },
                        {
                            target: payRedeem.address,
                            call: [
                                'allowance(address,address)(uint256)',
                                walletAddress.value,
                                nftInfoAPIData!.loancontract
                            ],
                            returns: [['PAYREDEEM_APPROVE', (val: any) => val / 10 ** payRedeem.decimals]]
                        },
                    ])

                    returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                    const returnResult = returnValue.results.transformed

                    calls = []
                    payRedeem.balance = TOOL.scientificToNumber(
                        new BigNumber(returnResult['PAYREDEEM_BALANCE'].toString())
                            .div(10 ** payRedeem.decimals)
                            .toString()
                    )
                    payRedeem.approve = returnResult['PAYREDEEM_APPROVE']

                    goodsPayRedeem.value = payRedeem
                } else {
                    goodsPayRedeem.value = payRedeem
                }

                if (isOwner.value) {
                    inputDays.value = (nftInfoAPIData!.lendingperiodmin / 3600 / 24).toString()
                    isSetRelet.value = nftInfoAPIData.lendingrenewable === 0 ? false : true

                    let calls: any[] = []
                    calls = calls.concat([
                        {
                            target: nftInfoAPIData.nftassets,
                            call: [
                                'isApprovedForAll(address,address)(bool)',
                                walletAddress.value,
                                nftInfoAPIData.marketcontract
                            ],
                            returns: [['NFT_APPROVE_MARKET', (val: any) => val]]
                        },
                        {
                            target: nftInfoAPIData.nftassets,
                            call: [
                                'isApprovedForAll(address,address)(bool)',
                                walletAddress.value,
                                nftInfoAPIData.lendingcontract
                            ],
                            returns: [['NFT_APPROVE_LENDING', (val: any) => val]]
                        },
                        {
                            target: nftInfoAPIData.nftassets,
                            call: [
                                'isApprovedForAll(address,address)(bool)',
                                walletAddress.value,
                                nftInfoAPIData.ingamecontract
                            ],
                            returns: [['NFT_APPROVE_INGAME', (val: any) => val]]
                        }
                    ])

                    if (isUrBoxOpen) {
                        calls = calls.concat([
                            {
                                target: nftInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftInfoAPIData.revertcontract
                                ],
                                returns: [['NFT_APPROVE_REVERT', (val: any) => val]]
                            },
                            {
                                target: nftInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftInfoAPIData.loancontract
                                ],
                                returns: [['NFT_APPROVE_LOAN', (val: any) => val]]
                            }
                        ])
                    }

                    const returnValue = await aggregateNFTInfo(
                        calls,
                        pageChainConfig.multicallConfig
                    )

                    nftApprove.value.market = returnValue.results.transformed.NFT_APPROVE_MARKET
                    nftApprove.value.lending = returnValue.results.transformed.NFT_APPROVE_LENDING
                    nftApprove.value.ingame = returnValue.results.transformed.NFT_APPROVE_INGAME

                    if (isUrBoxOpen) {
                        nftApprove.value.revert = returnValue.results.transformed.NFT_APPROVE_REVERT
                        nftApprove.value.loan = returnValue.results.transformed.NFT_APPROVE_LOAN
                    }
                }

                const urlNFTChartData = `${API.urlNFTChartData}${pageChainConfig.chainId}/${tokenId}/month`
                nftChartData.value = (await axios.get(urlNFTChartData)).data.data.list

                LogUtils.debug('Loading end')

                isLoading.value = false

                if (isLoadingInit.value) {
                    isLoadingInit.value = false
                    ctx.emit('update', false)
                }
                nextTick(() => {
                    // Loading should be closed asynchronously
                    loadingInstance.close()
                })

                const urlSaleHistroy = `${API.nftHistoryMarket}${chainId}/${tokenId}/0/50`
                axios.get(urlSaleHistroy)
                    .then((response) => {
                        saleHistoryList.value = response.data.data.list
                    })
                    .catch((e) => {

                    })

                const urlRentHistroy = `${API.nftHistoryLend}${chainId}/${tokenId}/0/50`
                axios.get(urlRentHistroy)
                    .then((response) => {
                        rentHistoryList.value = response.data.data.list
                    })
                    .catch((e) => {

                    })
            }

        }

        return {
            buttonDisabled,
            isLoading,
            isShowModal,
            modalShowIndex,
            isMobile,
            nftInfo,
            getTime,
            approve,
            buy,
            inputAmountBuy,
            goodsPaySale,
            goodsPayLend,
            belongto,
            nftStateMap,
            isOwner,
            isRenter,
            owner,
            renter,
            historyIndex,
            historyIndexNumber,
            changeRadioGroup,
            saleHistoryList,
            rentHistoryList,
            closeModal,
            showModal,
            rent,
            gameAccount,
            approveNFT,
            nftApprove,
            inputAmount,
            modalInfotip,
            sell,
            charge,
            cancelOrderSell,
            watchInput,
            inputDays,
            isRenewPrice,
            watchInputInteger,
            rentout,
            lendingInfo,
            cancelOrderRent,
            indexApproveNFT,
            changeprice,
            currentTime,
            returnTimeArr,
            isSetRelet,
            switchReletDisabled,
            reletset,
            router,
            isShowLoginLink,
            claimRentNFT,
            isRenewable,
            isBorrowable,
            reletsetchange,
            canReletDisabled,
            goBack,
            pageSrcPara,
            inputWalletAddressTransfer,
            transfer,
            remainTimeArr,
            currentTimeRemainTime,
            canrevert,
            canloan,
            canredeem,
            revertTimeArr,
            revertrateamount,
            revertratesymbol,
            revert,
            dealLoan,
            dealRedeem,
            loanamount,
            loansymbol,
            goodsPayRedeem,
            getApproveTokenAndNeedAmount,
            actionResultInfo,
            nftChart,
            getNFTDataOption,
            chartPeriodIndex,
            changeNFTChartPeriod
        }
    }
})
</script>
<style lang="stylus">
.box-item {
//   width: 110PX;
//   margin-top: 10PX;
    margin-left: 4PX;
    width: 16PX;
        height: 16PX;
}

.el-icon.box-item{
    cursor pointer
    svg{
        width: 16PX;
        height: 16PX;
    }
}
</style>