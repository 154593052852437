<template>
    <div @click="stopAtuoPlay" class="banner-wrap">
        <swiper class="bannerSwiper" @swiper="setThumbsSwiper" :speed="1200" :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
        
        }" :pagination="{
    clickable: true
}" :clickable="true" :modules="[Autoplay, Pagination]" :loop="true">
            <!-- <swiper-slide><TonBanner/></swiper-slide> -->
            <swiper-slide>
                <BaseBanner :data="props.data" />
            </swiper-slide>
        </swiper>

    </div>
</template>

<script lang="ts" setup>

import { defineProps, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import BaseBanner from './BaseBanner.vue';
import TonBanner from './TonBanner.vue'

let swiperInstance: any = null
const setThumbsSwiper = (swiper: any) => {
    // console.log(swiper.autoplay)
    swiperInstance = swiper
}
const stopAtuoPlay = () => {
    if (!swiperInstance) return
    if (!swiperInstance?.autoplay.running) return
    swiperInstance.autoplay.stop()
    setTimeout(swiperInstance.autoplay.start, 1000 * 10)
}


const props = defineProps({
    data: {
        type: Object,
        totalusers: String,
        dau: String,
        ranking: String,

    }
})

</script>

<style scoped lang="stylus">
    .banner-wrap{
        & >>> .swiper-pagination {
            bottom: 20px  !important;
        }
        & >>> .swiper-pagination-bullet{
            width: 16px !important;
            height: 16px !important;
            background: white !important;
            opacity: 0.5;
            margin: 0 20px !important;
        }
        & >>>  .swiper-pagination-bullet-active {
            background: white !important;
            opacity: 1;
        }
    }

    @media screen and (max-width: 1140px) {
        .banner-wrap{
            & >>> .swiper-pagination {
                bottom: 20rem  !important;
            }
            & >>> .swiper-pagination-bullet{
                width: 12rem !important;
                height: 12rem !important;
                margin: 0 10rem !important;
            }
        }
    }
    
</style>
