<template>
    <button @click="isShowBuyMCList = !isShowBuyMCList" class="change-chain-wrap button-white play-now" id="LondonTwo">
        <div class="d-flex align-items-center">
            <img class="mc-logo" src="/static/images/icon-mc-buy-top.png" alt="" />
            <span class="mc-title">Buy MC</span>
            <svg :class="isShowBuyMCList ? 'rotate' : ''" viewBox="0 0 11 6" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g transform="translate(-1553.000000, -38.000000)" stroke="#915C92" stroke-width="1.5">
                        <path
                            d="M1561.24264,36.2426407 L1561.24264,41.2426407 C1561.24264,41.7949254 1560.79493,42.2426407 1560.24264,42.2426407 L1555.24264,42.2426407 L1555.24264,42.2426407"
                            transform="translate(1558.242641, 39.242641) rotate(45.000000) translate(-1558.242641, -39.242641) ">
                        </path>
                    </g>
                </g>
            </svg>
        </div>
        <el-card v-if="isShowBuyMCList" :shadow="'none'" class="mc-list-card">
            <div class="item">
                <a href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x59C49B54D4425C7fF1E49f40958a14d48cC87c26"
                    target="_blank" class="bsc">
                    <span class="text">$MC On BSC</span>
                    <img class="logo" src="/static/images/icon-bnbchain-buymc-top.png" />
                </a>
            </div>
            <div class="item nofirst">
                <a href="https://app.ston.fi/swap?ft=Magic+%E2%80%A6&tt=TON&fa=1" target="_blank" class="ton">
                    <span class="text">$MC On TON</span>
                    <img class="logo" src="/static/images/icon-tonchain-buymc-top.png" />
                </a>
            </div>
        </el-card>
    </button>
</template>
<script lang="ts" setup>
import { ref, watch, onUnmounted } from 'vue'
import { isDebug } from '@/constants/Config'
import TOOL from '../../utils/tool'
const selectedIndex = ref<number>(-1)
const isShowBuyMCList = ref(false)

onUnmounted(async () => {

    isShowBuyMCList.value = false
})

watch(selectedIndex, (value: number) => {

})
</script>

<style lang="stylus">
.button-white{
    position relative
    .mc-logo {
        width: 32px;
        height: 32px;
        margin-top: -6px;
    }
    .mc-title{
        margin-left: 10px;
    }

    svg{
        width: 11px 
        height: 6px
        margin-left: 10px
        margin-top: -2px;

        &.rotate{
            transform: rotate(180deg)
        }
    }
    .mc-list-card{
        background: rgba(0,0,0,0.8);
        border: 0;
        position absolute;
        top: 105%;
        left: 35px;
        width: 180px;
        min-width: 130PX;
        box-shadow: none;

        .item{

            &:last-child {
                .logo{
                    width: 27px;
                    height: 27px;
                }
            }
            line-height: 1;
            a{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #FFFFFF;

                .text{
                    font-family: LondonTwo;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 2;
                    width: 80%;
                    text-align: left;
                }

                .logo{
                    width: 25px;
                    height: 25px;
                    margin-left: 8px;
                    margin-bottom: 4px;
                }
                
                transition: color .2s;
                &.bsc:hover{
                        color: #E6B20B;
                    }

                    &.ton:hover{
                        color: #60A3FF;
                    }
            }

            &.nofirst{
                margin-top: 12px;
            }
        }
    }
    &#LondonTwo{
        padding-left: 13px;
        padding-right: 13px;
    }
}
</style>
<style scoped lang="stylus">
@media screen and (max-width: 1140px) {
    .button-white{
        position relative
        .mc-logo {
            width: 18PX;
            height: 18PX;
            margin-top: -6PX;
        }
        .mc-title{
            margin-left: 4PX;
            margin-top: -2PX;
        }

        svg{
            width: 11PX 
            height: 6PX
            margin-left: 4PX
            margin-top: -4PX;

            &.rotate{
                transform: rotate(180deg)
            }
        }
        .mc-list-card{
            background: rgba(0,0,0,0.8);
            border: 0;
            position absolute;
            top: 140%;
            left: 15PX;
            width: 120PX;
            box-shadow: none;
            padding 6PX 10PX;

            .item{
                line-height: 1;
                a{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #FFFFFF;

                    .text{
                        font-family: LondonTwo;
                        font-size: 12PX;
                        letter-spacing: 0;
                        line-height: 1;
                    }

                    .logo{
                        width: 12PX;
                        height: 12PX;
                        margin-left: 4PX;
                        margin-bottom: 4PX;
                    }

                    &.bsc:hover{
                        color: #E6B20B;
                    }

                    &.ton:hover{
                        color: #60A3FF;
                    }
                    &:hover{
                        text-decoration: none;
                    }
                }

                &.nofirst{
                    margin-top: 12PX;
                }
            }
        }
        &#LondonTwo{
            padding-left: 8PX;
            padding-right: 8PX;
        }
    }
}
</style>
<style scoped lang="stylus">
.change-chain-wrap{
    display: flex;
    align-items: center;

    :deep(.el-select .el-input__inner) {
        background-color: transparent;
        border: none;
        color: white;
        width: 75px !important;
        min-width: 90px !important;
        font-family: Avenir-Heavy;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        // text-align: center;

    }

    :deep(.el-select){
        margin: 0 !important;
    }
}
</style>
