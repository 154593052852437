<template>
  <div class="menu-block">
    <a :class="$route.name === 'UserCenter' ? 'active' : ''" href="/#/usercenter"
      class="link-item d-flex align-items-center justify-content-center">{{ $t('Header.wallet') }}</a>
    <a :class="$route.name === 'MyNFTHead' ? 'active' : ''" href="/#/mynfthead"
      class="link-item d-flex align-items-center justify-content-center">{{ $t('Header.nft-profile') }}</a>
    <a :class="$route.name === 'MyNFTSkin' ? 'active' : ''" href="/#/mynft"
      class="link-item d-flex align-items-center justify-content-center">{{ $t('Header.nft-skin') }}</a>
    <a :class="$route.name === 'MyNFTRune' ? 'active' : ''" href="/#/mynftrune"
      class="link-item d-flex align-items-center justify-content-center">NFT Rune</a>
    <!-- <a :class="$route.name === 'Release' ? 'active' : ''" href="/#/release"
      class="link-item d-flex align-items-center justify-content-center">Release</a> -->
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  watch,
  getCurrentInstance,
  ref,
  onActivated
} from 'vue'
import { useRouter } from 'vue-router'
// import { connectWeb3Provider } from '../utils/provider'
// import { fetchWalletProvider } from './wallet/walletAction'
import TOOL from '../utils/tool'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'UCLeft',
  props: ['walletAddress'],
  setup(props, context) {
    // const router = useRouter()
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

    const isMobile = ref(false)
    const isShowNav = ref(false)
    const isLoading = ref(true)

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })
      setTimeout(() => {
        isLoading.value = false
      }, 1200)
    })

    return {
      isMobile,
      isShowNav,
      isLoading,
    }
  }
})
</script>

<style scoped lang="stylus">
  .menu-block {
            margin-top: 83PX;
            width: 100%;

            a:hover {
                text-decoration: none;
            }

            .link-item {
                height: 56PX;
                font-size: 18PX;
                font-family: Avenir-Bold;
                font-weight: bold;
                color: #C7CAD5;
                line-height: 22PX;
                background: transparent;
            }

            .link-item:not(:first-child) {
                margin-top: 44PX;
            }

            .link-item:hover, .link-item.active {
                background: #28283E;
                color: #FFCE22;
            }
        }
</style>
