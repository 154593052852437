import request from '@/network/request'

// const GameUrl = 'http://t3web.sanguoh5.com:8084'
// const GameUrl = 'http://tdev.sanguoh5.com:8282'
// const GameUrl = 'http://127.0.0.1:8084'

export function register(data: any) {
  return request({
    url: '/web/account/register',
    method: 'post',
    params: data
  })
}
export function registerbyMail(data: any) {
  return request({
    url: '/web/account/registerbymail',
    method: 'post',
    data
  })
}

export function login(data: any) {
  return request({
    url: '/web/account/login',
    method: 'get',
    params: data
  })
}

export function getEmailSecCode(data: any) {
  return request({
    url: '/web/account/reqmailcode',
    method: 'get',
    params: data
  })
}

export function getRegInvitationCode(data: any) {
  return request({
    url: '/web/account/getregcode',
    method: 'get',
    params: data
  })
}

export function getActCode(data: any) {
  return request({
    url: '/web/activity/getactcode',
    method: 'get',
    params: data
  })
}

export function getActList(data: any) {
  return request({
    url: '/web/activity/getactlist',
    method: 'get',
    params: data
  })
}



export function bindEmail(data: any) {
  return request({
    url: '/web/account/bindmail',
    method: 'get',
    params: data
  })
}


export function resetpwd2(data: any) {
  return request({
    url: '/web/account/resetpwd2',
    method: 'get',
    params: data
  })
}

export function setNewPassByEmail(data: any) {
  return request({
    url: '/web/account/newpwd',
    method: 'get',
    params: data
  })
}

export function resetpassword(data: any) {
  return request({
    url: '/web/account/resetpwd',
    method: 'post',
    params: data
  })
}

export function getInfo(data: any) {
  return request({
    url: '/web/account/getAccountInfo',
    method: 'get',
    params: data
  })
}

export function getSign(data: any) {
  return request({
    url: '/web/account/getSign',
    method: 'get',
    params: data
  })
}

export function getUserInfoByWalletAddress(data: any) {
  return request({
    url: '/web/account/roleinfo',
    method: 'get',
    params: data
  })
}
