<template>
    <template v-if="!isLoading">
        <div style="max-height: max-content;"
            class="mask-nft sellnft action-result d-flex flex-column justify-content-center align-items-center">
            <!-- <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
            <path
                d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                p-id="2960" fill="#ffffff" />
        </svg> -->
            <div class="dialog-content d-flex flex-column align-items-center">
                <div v-if="action === 0" class="congrats-block">
                    <div class="text">Congratulations!</div>
                    <img class="left-back" src="/static/images/back-congrats-left.png" />
                    <img class="right-back" src="/static/images/back-congrats-right.png" />
                </div>
                <svg v-else style="margin-bottom: 10PX;" width="50px" height="50px" viewBox="0 0 50 50" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(-935.000000, -289.000000)" stroke="#FFCE22" stroke-width="2">
                            <g transform="translate(935.000000, 289.000000)">
                                <circle cx="25" cy="25" r="24"></circle>
                                <polyline stroke-linecap="round" stroke-linejoin="round"
                                    points="15.1074966 24.8012723 22.3062243 32 35.3062243 19"></polyline>
                            </g>
                        </g>
                    </g>
                </svg>
                <div class="title2">
                    <template v-if="action === 0">You have purchased Mystery Box successfully</template>
                    <template v-else-if="action === 1">You have returned your NFT successfully</template>
                    <template v-else-if="action === 2">You have staked your NFT successfully</template>
                    <template v-else-if="action === 3">You have redeemed your NFT successfully</template>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="left">
                        <template v-if="action === 0">
                            <div class="nft-image-block">
                                <img :src="cardinfo['preview']" :class="action === 0 ? 'nft-box' : 'nft-image'" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="nft-item d-flex align-items-center justify-content-center">
                                <img :src="cardinfo['preview']" class="nft-item-image" />
                                <div class="nft-item-intro d-flex flex-column align-items-start">
                                    <div class="nft-item-intro-title">{{
                                            $t(`CardList.${cardinfo['typeid']}.name`)
                                    }}</div>
                                    <div class="nft-item-intro-title">#{{ cardinfo['tokenid'] }}</div>
                                    <!-- <div v-else class="nft-item-intro-upgrade">AMOUNT: {{ nft['amount'] }}</div> -->
                                </div>
                                <div class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                                    <!-- <img src="/static/images/element_lighting.png" /> -->
                                    <span class="text">LV. 0/5</span>
                                </div>

                                <!-- <div class="nft-item-attrs">
                                    <div v-if="boxObtainList[0].nft['earth'] > 0"
                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                        <img class="nft-item-attrs-image"
                                            src="/static/images/element_attr_yellow_earth.png" />
                                        <span class="nft-item-attrs-text earth"
                                            :class="boxObtainList[0].nft['earth'] >= 10 ? 'digit2' : ''">{{
                                                boxObtainList[0].nft['earth']
                                            }}</span>
                                    </div>
                                    <div v-if="boxObtainList[0].nft['water'] > 0"
                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                        <img class="nft-item-attrs-image"
                                            src="/static/images/element_attr_blue_water.png" />
                                        <span class="nft-item-attrs-text water"
                                            :class="boxObtainList[0].nft['water'] >= 10 ? 'digit2' : ''">{{
                                                boxObtainList[0].nft['water']
                                            }}</span>
                                    </div>
                                    <div v-if="boxObtainList[0].nft['fire'] > 0"
                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                        <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                                        <span class="nft-item-attrs-text fire"
                                            :class="boxObtainList[0].nft['fire'] >= 10 ? 'digit2' : ''">{{
                                                boxObtainList[0].nft['fire']
                                            }}</span>
                                    </div>
                                    <div v-if="boxObtainList[0].nft['wind'] > 0"
                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                        <img class="nft-item-attrs-image"
                                            src="/static/images/element_attr_green_wind.png" />
                                        <span class="nft-item-attrs-text wind"
                                            :class="boxObtainList[0].nft['wind'] >= 10 ? 'digit2' : ''">{{
                                                boxObtainList[0].nft['wind']
                                            }}</span>
                                    </div>
                                    <div class="nft-item-attr d-flex align-items-center"></div>
                                </div> -->
                                <img class="nft-item-attrs-image-level"
                                    :src="'/static/images/element_level_' + getLowerCase(cardinfo['rarity']) + '.png'" />
                            </div>
                        </template>
                    </div>
                </div>
                <div class="right" :class="action !== 0 ? 'nobox' : ''">
                    <template v-if="action === 0">
                        <div class="info-block-column">
                            <div class="tag">Contrast Address</div>
                            <div class="text-block">
                                <div class="text">{{ cardinfo.address }}</div>
                            </div>
                        </div>

                        <div class="price-box">Price: {{ cardinfo.price }} {{ cardinfo.pricesymbol }}</div>
                    </template>
                    <template v-else>
                        <div class="info-block d-flex justify-content-between" style="width:100%">
                            <span class="tag">Token ID</span>
                            <span class="text">{{ cardinfo.tokenid }}</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">Name</span>
                            <span class="text">
                                <template v-if="action === 0">
                                    {{ cardinfo.name }}
                                </template>
                                <template v-else>
                                    {{
                                            $t(`CardList.${cardinfo.typeid}.name`)
                                    }}
                                </template>
                            </span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">Rarity</span>
                            <span class="text">{{ cardinfo.rarity }}</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">
                                <template v-if="action === 1">Return Price</template>
                                <template v-if="action === 2">Stake Price</template>
                                <template v-if="action === 3">Redeem Price</template>
                            </span>
                            <span class="text forsale">{{ cardinfo.price }} {{ cardinfo.pricesymbol }}</span>
                        </div>
                        <!-- <div class="info-block notfirst d-flex justify-content-between align-items-center"
                            style="width:100%">
                            <span class="tag">Recieve address</span>
                            <span class="text multiline">{{ wa }} (BSC)</span>
                        </div> -->
                    </template>
                    <div class="button-block stakeur justify-content-center align-items-center">
                        <template v-if="action === 0">
                            <template v-if="!isLoading">
                                <button class="button-buy mynftsell" @click="openbox()">Open Now</button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell continuebuy"
                                    @click="confirm()">Continue
                                    to buy</button>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="!isLoading">
                                <button :disabled="buttonDisabled" class="button-buy mynftsell"
                                    @click="confirm()">Confirm</button>
                            </template>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </template>
</template>
<script lang="ts">
import { getWalletProvider } from '@/utils/provider'
import { computed, ref, defineComponent, toRefs, nextTick, inject, getCurrentInstance } from 'vue'
import * as API from '@/constants/api'
import axios from '@/utils/axios'
import TOOL from '@/utils/tool'
import { useI18n } from "vue-i18n"
// import ERC721 from '@/abis/ERC721.json'
import ERC20 from '@/abis/ERC721.json'
import NFTTFSkinCashout from '@/abis/NFTTFSkinCashout.json'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import { ethers } from 'ethers'
import moment from 'moment'
import { aggregate as aggregateStakeUR } from '@makerdao/multicall'
import BigNumber from 'bignumber.js'
import LogUtils from '@/utils/LogUtils'
import { is } from '@babel/types'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'

export default defineComponent({
    name: 'ModalActionResult',
    props: {
        wa: {
            type: String,
            default: ''
        },
        action: {
            type: Number,
            default: 0
        },
        ismobile: {
            type: Boolean,
            default: false
        },
        cardinfo: {
            type: Object,
            default: {
                tokenid: 0,
                typeid: 0,
                name: '',
                rarity: '',
                preview: '',
                price: 0,
                pricesymbol: '',
                amount: 1,
                address: ''
            }
        },
    },
    setup(props, { emit, attrs }: any) {
        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

        const isLoading = ref(true)
        const buttonDisabled = ref(false)

        const loadData = async () => {
            const { action, cardinfo } = toRefs(props)


            isLoading.value = false
        }
        const formatTime = (secs: number) => {
            return moment(secs * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }
        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }
        const formatNumber = (n: number, l: number) => {
            return TOOL.formatdecimalPlaces(new BigNumber(n), l)
        }

        const confirm = () => {
            emit('confirm', props.cardinfo)
        }

        const cancel = () => {

            emit('cancel')
        }

        const openbox = () => {
            emit('openbox')
        }

        loadData()

        return { confirm, cancel, openbox, getLowerCase, formatTime, isLoading, formatNumber, buttonDisabled }
    }
})
</script>
<style lang="stylus">
</style>