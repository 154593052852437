import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config';

const logProductDebugSwitch = false
const LogUtils = {
    debug(d: any) {
        if (BLOCKCHAIN_CONFIG.isDebug || logProductDebugSwitch) {
            console.log('Debug:', d)
            this.printStack()
        }
    },
    info(i: any) {
        console.info(i)
    },
    warn(w: any) {
        console.warn(w)
    },
    error(e: any) {
        console.error('Error:')
        console.error(e)
        console.trace()
        // console.error('Error:', e)
    },
    printStack() {
        const callstack = new Error().stack.split("\n");
        let i = 0
        try {
            callstack.forEach((s) => {
                if (i > 0) {
                    console.log(s)
                }

                i++
                if (i === 4) {
                    throw new Error("EndError")
                }
            })
        } catch (e: any) {
            if (e.message !== "EndError") {
                throw e
            }
        }

    }

}

export default LogUtils