import { ethers } from 'ethers'
import detectEthereumProvider from '@metamask/detect-provider'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { v4 as uuidv4 } from 'uuid'
import { fetchWalletProvider, clearWallet } from '../components/wallet/walletAction'
import _ from 'lodash'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
// import {
//   addLayoutGlobalMessage,
//   GlobalMessage,
//   GLOBAL_MESSAGE_TYPES
// } from '@/components/layout/layoutAction'
import TOOL from './tool'
import store from '../store'
import { configProject } from '../../configProject'
import * as Config from '../constants/Config'

const projectConfig = Config.isDebug ? configProject.test : configProject.main
const chainId = Config.isDebug ? 97 : 56

let web3Provider: ethers.providers.Web3Provider | null = null
let defaultProvider: ethers.providers.BaseProvider | null = null
let isDetectingMetaMaskProvider = false
let isDetectingNetworkSwitch = false

// const ChainNativeCurrency = ({ name = '', symbol = '', decimals = 18 }) => {
//   return { name, symbol, decimals }
// }

const chainMap = new Map()
chainMap.set(chainId, {
  chainId: `0x${Number(chainId).toString(16)}`,
  chainName: Config.isDebug ? 'BSC Test' : 'BSC',
  nativeCurrency: {
    symbol: 'BNB',
    name: 'BNB',
    decimals: 18
  },
  rpcUrls: [projectConfig.rpcurls.BNB],
  blockExplorerUrls: [projectConfig.blockchain_browser_url.BNB.replace('/tx', '')]
})
chainMap.set(86, {
  chainId: `0x${Number(86).toString(16)}`,
  chainName: 'GATECHAIN',
  nativeCurrency: {
    symbol: 'GT',
    name: 'GT',
    decimals: 18
  },
  rpcUrls: [projectConfig.rpcurls.GT],
  blockExplorerUrls: [projectConfig.blockchain_browser_url.GT.replace('/tx', '')]
})
chainMap.set(97, {
  chainId: `0x${Number(97).toString(16)}`,
  chainName: 'BSC Testnet',
  nativeCurrency: {
    symbol: 'BNB',
    name: 'BNB',
    decimals: 18
  },
  rpcUrls: [projectConfig.rpcurls.BNB],
  blockExplorerUrls: [projectConfig.blockchain_browser_url.BNB.replace('/tx', '')]
})
chainMap.set(128, {
  chainId: `0x${Number(128).toString(16)}`,
  chainName: 'HECO',
  nativeCurrency: {
    symbol: 'HT',
    name: 'HT',
    decimals: 18
  },
  rpcUrls: [projectConfig.rpcurls.HT],
  blockExplorerUrls: [projectConfig.blockchain_browser_url.HT.replace('/tx', '')]
})

export const WEB3_PROVIDER_TYPES = {
  META_MASK: 'META_MASK',
  WALLET_CONNECT: 'WALLET_CONNECT',
  BSC: 'BSC',
  BYBIT: 'BYBIT'
}

export const getDefaultProvider = () => {
  try {
    defaultProvider =
      defaultProvider ||
      new ethers.providers.JsonRpcProvider(BLOCKCHAIN_CONFIG.defaultRpcUrl)
    return defaultProvider
  } catch (error) {
    console.error(`getDefaultProvider Error: `, error)
  }
  return null
}

export const addMetaMaskEthereumChain = async (chainId: number, provider: any) => {
  if (provider) {
    isDetectingNetworkSwitch = true
    let res
    try {
      res = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + chainId.toString(16) }],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          res = await provider.request({
            method: 'wallet_addEthereumChain',
            params: [chainMap.get(chainId)]
          });
        } catch (addError) {
          // handle "add" error
          isDetectingNetworkSwitch = false
        }
      } else {
        isDetectingNetworkSwitch = false
      }
      if (res === null) {
        const jsonChainInfo = JSON.stringify({
          chainId,
          chainSymbol: chainMap.get(chainId).nativeCurrency.symbol
        })
        localStorage.setItem('chain_info', jsonChainInfo)
        store.dispatch('set_chaininfo')
      }
    }

  }
}

// const handleAccountsChanged = async (accounts: string[]) => {
//   const walletAddress = store.getters.wallet.address
//   if (accounts.length === 0) {
//     // MetaMask is locked or the user has not connected any accounts
//     clearWallet()
//     store.dispatch('remove_user')
//     // walletAddress.value = ''
//   } else if (accounts[0] !== walletAddress.value) {
//     await fetchWalletProvider()
//   }
// }

export const resetProvider = async (type = WEB3_PROVIDER_TYPES.META_MASK) => {
  isDetectingMetaMaskProvider = false
  return connectWeb3Provider(type)
}

export const connectWeb3Provider = async (type = WEB3_PROVIDER_TYPES.META_MASK) => {
  type providerTypes = keyof typeof WEB3_PROVIDER_TYPES
  if (WEB3_PROVIDER_TYPES[type as providerTypes] !== undefined) {
    const connectType = WEB3_PROVIDER_TYPES[type as providerTypes]
    let provider: any = null
    try {
      if (type === WEB3_PROVIDER_TYPES.META_MASK && !isDetectingMetaMaskProvider) {
        isDetectingMetaMaskProvider = true
        provider = await detectEthereumProvider()
        if (_.isNil(provider)) {
          // addLayoutGlobalMessage(
          //   GlobalMessage({
          //     id: uuidv4(),
          //     type: GLOBAL_MESSAGE_TYPES.ERROR,
          //     text: 'Missing wallet: please install MetaMask browser extension and try again',
          //     hash: ''
          //   })
          // )
        } else {
          try {
            await provider.enable()
            isDetectingMetaMaskProvider = false
          } catch (error) {
            console.error(error)
            // isDetectingMetaMaskProvider = false
          }

        }

      } else if (type === WEB3_PROVIDER_TYPES.WALLET_CONNECT) {
        // provider = new WalletConnectProvider({
        //   rpc: {
        //     [chainId]: projectConfig.rpcurls.BNB,
        //     [97]: 'https://data-seed-prebsc-2-s2.binance.org:8545/'
        //   },
        //   chainId: chainId
        // })
        provider = await EthereumProvider.init({
          projectId: '0edddcd5d067d391767ad1e51186b59d', // required
          chains: [1], // required
          optionalChains: [56, 97],
          showQrModal: true // requires @walletconnect/modal
        })
        await provider.enable()
      } else if (type === WEB3_PROVIDER_TYPES.BSC) {
        if (window.BinanceChain) {
          provider = window.BinanceChain
          await window.BinanceChain.enable()
          window.BinanceChain.autoRefreshOnNetworkChange = false
          // if (!isBinanceChainRegisteredEvent) {
          //   window.BinanceChain.on('accountsChanged', () => {
          //     window.location.reload()
          //   })
          //   window.BinanceChain.on('chainChanged', () => {
          //     window.location.reload()
          //   })
          //   isBinanceChainRegisteredEvent = true
          // }
        }
      }
      // TODO:bybit
      // else if (type === WEB3_PROVIDER_TYPES.BYBIT) {
      //   // @ts-ignore
      //   provider = window.bybitWallet
      // }
    } catch (error) {
      console.error('connectWeb3Provider: ', error)
      web3Provider = null
      return null
    }

    localStorage.setItem('web3ProviderType', type)

    try {
      if (provider) {
        web3Provider = new ethers.providers.Web3Provider(provider, 'any')
        const providerNetwork = await web3Provider.getNetwork()
        if (
          _.has(providerNetwork, 'chainId') &&
          providerNetwork.chainId.toString() !==
          BLOCKCHAIN_CONFIG.defaultChainId.toString()
        ) {
          if ((type !== WEB3_PROVIDER_TYPES.WALLET_CONNECT) && !isDetectingNetworkSwitch) {
            addMetaMaskEthereumChain(chainId, provider)

          }
        } else {
          const jsonChainInfo = JSON.stringify({
            chainId: await TOOL.getChainId(false),
            chainSymbol: await TOOL.getChainSymbol(false)
          })
          localStorage.setItem('chain_info', jsonChainInfo)
          store.dispatch('set_chaininfo')
        }
      }
    } catch (error) {
      console.error('connectWeb3Provider getNetwork Error: ', error)
      // addLayoutGlobalMessage(
      //   GlobalMessage({
      //     id: uuidv4(),
      //     type: GLOBAL_MESSAGE_TYPES.ERROR,
      //     text: `Wrong network: please connect ${connectType || ''} wallet to ${BLOCKCHAIN_CONFIG.defaultChainName
      //       } network and try again`,
      //     hash: ''
      //   })
      // )
      isDetectingMetaMaskProvider = false
      web3Provider = null
    }

    return web3Provider
  }
  return null
}


export const getWeb3Provider = async (connectIfNil = false) => {
  try {
    if (_.isNil(web3Provider) && connectIfNil) {
      web3Provider = await connectWeb3Provider(WEB3_PROVIDER_TYPES.META_MASK) || null
    }
    return web3Provider
  } catch (error) {
    console.error('getWeb3Provider Error', error)
  }
  return null
}

export const getWeb3ProviderWallet = async (connectIfNil = false) => {
  try {
    if (_.isNil(web3Provider) && connectIfNil) {
      const web3ProviderType = localStorage.getItem('web3ProviderType')
      if (web3ProviderType && Object.keys(WEB3_PROVIDER_TYPES).includes(web3ProviderType)) {
        web3Provider = await connectWeb3Provider(web3ProviderType) || null
      } else {
        web3Provider = await connectWeb3Provider(WEB3_PROVIDER_TYPES.META_MASK) || null
      }

    }
    return web3Provider
  } catch (error) {
    console.error('getWeb3ProviderWallet Error', error)
  }
  return null
}

export const getWalletProvider = async () => {
  const web3ProviderType = localStorage.getItem('web3ProviderType')
  if (web3ProviderType && Object.keys(WEB3_PROVIDER_TYPES).includes(web3ProviderType)) {
    return await connectWeb3Provider(web3ProviderType) || null
  } else {
    return await connectWeb3Provider(WEB3_PROVIDER_TYPES.META_MASK) || null
  }
}

getDefaultProvider()
