<template>
    <div class="page-mp" style="position: relative;min-height: 100vh;">
        <div v-if="!isLoading" class="marketplace-index d-flex" :class="isMobile ? '' : 'height-header-padding'">
            <template v-if="!isMobile">
                <div class="right-padding d-flex flex-column">
                    <div class="list-title">My Loans</div>
                    <div class="hot-list-label-block">
                        <div class="item-label" style="width: 160PX;text-align: left;">Collection</div>
                        <div class="item-label" style="width: 36PX">Rarity</div>
                        <div class="item-label" style="width: 68PX">Collateraled Time</div>
                        <div class="item-label" style="width: 46PX">Borrow MC</div>
                        <div class="item-label" style="width: 46PX">Interest Item</div>
                        <div class="item-label" style="width: 46PX">Long Period</div>
                        <div class="item-label" style="width: 68PX">Last Return Time</div>
                        <div class="item-label" style="width: 36PX">State</div>
                        <div class="item-label" style="width: 110PX">Action</div>
                    </div>
                    <div class="hot-list-block">
                        <div v-for="(item, itemIndex) of listHotTopGainer" class="item-data-block">
                            <div class="item-data-collection">
                                <div class="item-data-image-collection-block">
                                    <img :src="item.preview" class="item-data-image-collection" />
                                </div>
                                <span class="item-data-collection-name">{{ item.character }}</span>
                            </div>
                            <div class="item-data" style="width: 36PX"><img class="item-data-image-rarity"
                                    :src="'/static/images/element_level_' + getLowerCase(item['rarity']) + '.png'" />
                            </div>
                            <div class="item-data" style="width: 46PX">{{ item.element }} {{ item.element_value }}
                            </div>

                            <div class="item-data" style="width: 76PX">{{ item.price }}</div>
                            <div class="item-data" style="width: 68PX" :class="item['24hchange'] > 0 ? 'up' : 'down'">{{
                                    item['24hchange'] > 0 ? '+' : '-'
                            }} {{ item['24hchange'] }}%
                            </div>
                            <div class="item-data" style="width: 68PX">{{ item.tradetimes }}</div>
                            <div class="item-data" style="width: 126PX">
                                <sparkline style="width: 126PX" v-bind:data="item['7dchangedata']" :color="
                            item['7dchangedata'][item['7dchangedata'].length - 2] > item['7dchangedata'][0] ?
                                'red' : 'green'">
                                </sparkline>
                            </div>
                            <div class="item-data" style="width: 110PX"><button class="item-button-buy">Return
                                    It</button></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="mobile-content height-header-padding">
                    <div class="mobile-content-inner">
                        <div class="list-title">My Loans</div>

                    </div>
                </div>
            </template>
        </div>


    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick,
    reactive,
    CSSProperties,
} from 'vue'
import { ElDialog, ElMessageBox, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup } from 'element-plus'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import TOOL from '@/utils/tool'
import * as API from '@/constants/api'
import axios from '@/utils/axios'
import { getWalletProvider } from '@/utils/provider'
import TapDesktop from '@/abis/TapDesktop.json'
import ERC20 from '@/abis/ERC20.json'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import { aggregate as aggregateMarketPlace } from '@makerdao/multicall'
import { isDebug } from '@/constants/Config'
import MPHeader from './components/MPHeader.vue'
import SVGArrowLeftRightMPRecommend from '@/components/svgicon/SVGArrowLeftRightMPRecommend.vue'
import SVGChange from '@/components/svgicon/SVGChange.vue'
import SVGHand from '@/components/svgicon/SVGHand.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import Sparkline from "@/components/Sparkline.vue";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

interface ItemHotTopGainer {
    price: number
    image: string
    preview: string
    character: string
    rarity: string
    typeid: number
    tokenid: number
    level: number
    "24hchange": number
    tradetimes: number
    "7dchangedata": number[]
    element: string
    element_value: number
}

export default defineComponent({
    name: 'MarketPlaceBorrowMC',
    components: {
        Pagination, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup, MPHeader, Swiper,
        SwiperSlide, SVGArrowLeftRightMPRecommend, Sparkline, SVGChange, SVGHand
    },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        console.log('onSetup...')

        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const router = useRouter()
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const createNotification = <CreateNotification>inject('create-notification')
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber
        const pageChainConfig: any = {}
        const buttonDisabled = ref(false)
        const isLoading = ref(true)
        const isLoadingInit = ref(true)
        const isExpand = ref(false)
        const isMobile = ref(false)
        const leaderboardIndex = ref(0)

        const mobileScrollTop = ref(0)

        const selectedBuyIndex = ref(-1)
        let chainId = 0

        interface goodsPayToken {
            goodsid: number
            decimals: number,
            address: string,
            symbol: string,
            priceRange: { key: string; value: any; }[],
            balance: string,
            approve: number
        }

        const goodsPayTokenList: Ref<goodsPayToken[]> = ref([])

        const listHotTopGainer = ref<ItemHotTopGainer[]>([])

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            // LogUtils.debug('load from mount...')
            // setTimeout(() => {
            //   loadData()
            // }, 1000 * 1)

            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile

            })

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            // saveMPStorageSetting()
            ctx.emit('update', true)
        })

        const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
            TOOL.gtmTrack(event, category, action, label, value)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const showModal = (modalIdx: number, cardIdx: number = -1, cardSrc: number = -1,) => {
            // isShowModal.value = true
            // modalShowIndex.value = modalIdx

            if (modalIdx === 0) {

            } else if (modalIdx === 2) {

            }
        }

        const closeModal = () => {
            // LogUtils.debug('close...')

            // isShowModal.value = false
            // modalShowIndex.value = -1
            selectedBuyIndex.value = -1

            // const rootEle = (root as any).value
            // rootEle.style.overflowY = 'auto'

            // const headerEle = (header as any).value.$refs.headerRef
            // headerEle.style.display = 'block'

        }

        const mousewheel = (event: Event) => {
            event.preventDefault();
        }

        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }

        const listMyNFT = () => {
            BUS.$emit('SHOW_MASK', { maskIndex: 2, isShowMask: true, nftList: [] })
        }

        const go2Info = (index: number) => {
            const nft = listForNewUser.value[index]

            router.push(`/nft/${nft['tokenid']}`)


        }

        const getApproveTokenAndNeedAmount = (index: number) => {
            const payToken = goodsPayTokenList.value[0]
            const cardInfo = listForNewUser.value[index]

            return {
                symbol: payToken.symbol,
                amount: new BigNumber(cardInfo['price']).toNumber()
            }
        }

        const checkApprove = async (index: number) => {
            const payToken = goodsPayTokenList.value[0]
            const contractAddr = marketItemListData.desktop

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount(index).amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approvePre = (index: number) => {
            selectedBuyIndex.value = index
            showModal(2)
        }

        const loadData = async (index: number = 0) => {
            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            const { walletAddress } = toRefs(props)

            if (walletAddress.value.length === 0) {
                buttonDisabled.value = true
            }

            chainId = await TOOL.getChainId()

            const urlHotTopGainer = `${API.urlMPBuyNFTHotGainer}`
            listHotTopGainer.value = (await axios.get(urlHotTopGainer)).data.data

            nextTick(() => {
                // Loading should be closed asynchronously
                loadingInstance.close()
                isLoading.value = false
            })
        }

        return {
            isLoading,
            isExpand,
            listMyNFT,
            getLowerCase,
            mousewheel,
            showModal,
            closeModal,
            isMobile,
            getTime,
            buttonDisabled,
            gtmTrack,
            go2Info,
            selectedBuyIndex,
            approvePre,
            getApproveTokenAndNeedAmount,
            goodsPayTokenList,
            modules: [Pagination, Navigation, Scrollbar, A11y],
            leaderboardIndex,
            listHotTopGainer,
        }
    }
})
</script>
<style lang="stylus" scoped>
    #root,#app{
        height: auto
        overflow-y: auto
    }
    .right-padding{
        padding 40PX 16.6vw 0;
        flex: 1;
        position: relative;
    }
    .list-title {
        font-family: Avenir-Bold
        font-size: 16PX
        color: #FFFFFF
        margin-bottom: 22PX
    }

    .nft-item-image {
            height: 120PX;
            z-index: 1;
    }

    .nft-item-list{
        position relative
        column-gap: 30PX;
        margin-bottom: 52PX;
        padding 30PX 0;
        border: 2PX solid transparent;
        border-radius: 6PX;
        background-color: rgba(255,255,255,0.1);
        background-clip: padding-box;

        .change-block{
            position absolute
            right: 26PX
            top: 18PX;
            display: flex;
            align-items: center;
            cursor pointer

            .text{
                font-family: Avenir-Bold;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 1;
                margin-left: 8PX
            }
        }

        .buy-all-block{
            position absolute
            right: 26PX
            bottom: 18PX;
            display: flex;
            align-items: center;
            cursor pointer

            .text{
                font-family: Avenir-Bold;
                font-size: 12PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                line-height: 1;
                margin-left: 8PX
            }
        }
    }

    .nft-item-list::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: inherit;
        padding: 2PX;
        background: linear-gradient(to right bottom, rgba(226,186,134,1), rgba(191,142,106,1));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    .item-fornewuser{
        z-index 10
        .nft-item{
            width: 122.5PX;
            height: 140PX;
            color: #fff;
            position: relative;
            z-index: 2;
            background: rgba(22,20,34,0.3);
            border-radius: 5PX;
        }
        .item-fornewuser-right{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 20PX;

            .line1{
                font-family: Avenir;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
            }

            .line2{
                font-family: Avenir;
                font-size: 12PX;
                color: rgba(152,152,152,0.7)
                letter-spacing: 0;
                text-align: center;
                line-height: 12PX;
                margin-top: 6PX;
            }

            .cardprice{
                font-family: Avenir-Bold;
                font-size: 14PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                margin-top: 18PX;
            }

            .button-buy-fornewuser{
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                border: 1PX solid #FFC800;
                border-radius: 6PX;
                padding 4PX 24PX
                outline: none;
                background: none;
                margin-top: 10PX;
                cursor pointer
            }
        }
    }

    .swiper-container{
        display flex;
        flex-direction: column;

        .swiper-button-prev-pc{
            padding 4PX
            display: flex;
            align-items: center;
            justify-content: center;
            cursor pointer
        }

        .swiper-button-next-pc{
            padding 4PX
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;
            cursor pointer
        }

        .swiper-button-disabled{
            opacity 0.5;
            cursor no-drop;
        }
    }

    .swiper {
        width: calc(100vw - 33.2vw)
        cursor pointer

        .swiper-slide {
                text-align: center;
                font-size: 18PX;
                //   background: #fff;

                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                align-items: center;
            }
    }

    

    .popular-collection-list{
        padding: 0
        display: flex;
        align-items: flex-start;
        // column-gap: 96PX;
        // width:600PX;

        .item-popular-collection{
            display flex;
            flex-direction: column;
            .nft-item-pc{
                width: 133PX;
                height: 152PX;
                position: relative;
                border-radius: 6PX;
                border: 1PX solid transparent;
                background-clip: padding-box;

                .nft-item-pc-image {
                    height: 126PX;
                    z-index: 1;
                }

                .nft-item-chracter{
                    position absolute;
                    top: 6PX;
                    left: 7PX;
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                }

                .nft-item-level{
                    position absolute;
                    top: 6PX;
                    right: 7PX;
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                }

                .nft-item-rarity-img{
                    position absolute;
                    bottom: 6PX;
                    right: 7PX;
                    height: 17PX;
                    width: auto;
                }

                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    border-radius: inherit;
                    padding: 1PX;
                    background: linear-gradient(to right bottom, #E2C186, #BF8E6A);
                    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }

            .nft-item-price-block{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 8PX;

                .nft-item-price{
                    font-family: Avenir;
                    font-size: 14PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                }

                .nft-item-price-increase{
                    font-family: Avenir-Bold;
                    font-size: 14PX;
                    color: #26BA0E;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 10PX;
                    &.down{
                        color: red
                    }
                }
            }

        }
    }

    .hot-list-title-block{
        display: flex;
        column-gap: 50PX;
        margin: 56PX 0 10PX;
        width: 100%;
        .item-hot-list-title-inner-block{
            display: flex;
            align-items center;
            justify-content center;
            position relative
            cursor pointer
            .title-text{
                font-family: Avenir-Bold;
                font-size: 16PX;
                color: #989898;
                letter-spacing: 0;
                line-height: 1;
                padding 14PX 0
            }
            .line-select{
                position absolute;
                bottom: 0;
                width: 100%;
                height: 2PX;
                background: #FFDB62;
                border-radius: 2PX;
                display: none;
            }
            &.active,&:hover{
                .title-text{
                    color: #FFFFFF;
                }
                .line-select{
                    display: block;
                }
            }
        }
    }

    .hot-list-label-block{
        display flex;
        align-items: center;
        justify-content: space-between;
        padding 10PX; 0
        border-bottom: 1PX solid #3D3D3D;
        .item-label{
            font-family: Avenir;
            font-size: 12PX;
            color: #989898;
            letter-spacing: 0;
            line-height: 1;
            text-align: center;
        }
    }
    .hot-list-block{
        display flex;
        flex-direction: column;
        padding-top 22PX;
        row-gap: 30PX;
        .item-data-block{
            
            display flex;
            align-items: center;
            justify-content: space-between;
            padding 0 10PX
            .item-data{
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 1;
                text-align: center;
                &.up{
                    font-family: Avenir-Bold;
                    color: #26BA0E;
                }
                &.down{
                    font-family: Avenir-Bold;
                    color: #D50000;
                }
            }

            .item-data-collection{
                width: 160PX;
                display: flex;
                align-items: center;

                .item-data-image-collection-block{
                    height: 50PX;
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1PX solid #3A3F59;
                    border-radius: 8PX;
                    .item-data-image-collection{
                        height: 42PX;
                        
                    }

                }

                

                .item-data-collection-name{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 16PX;
                }
            }
            

            .item-data-image-element{
                height: 20PX;
            }

            .item-data-image-rarity{
                height: 18PX;
            }

            .item-button-buy{
                border: 1PX solid #3D3D3D;
                border-radius: 6PX;
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #989898;
                letter-spacing: 0;
                text-align: center;
                padding 4PX 30PX;
                outline: none;
                background: none;
                transition: color .3s;
                &:hover,&:active{
                    border: 1PX solid #FFC800;
                    color: #FFC800;
                }
            }
        }
    }

 

    @media screen and (max-width: 1140px) {
        .list-popular-collections {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0PX;
        row-gap: 0PX;

        .item-popular-collection {
            padding 16PX
        }
        }
    }
</style>