<template>
    <div class="change-lang-wrap">
        <svg width="15px" height="15px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="画板备份-2" transform="translate(-1425.000000, -31.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g id="语言" transform="translate(1425.000000, 31.000000)">
                        <path
                            d="M10,0 C15.5229167,0 20,4.47708334 20,10 C20,15.5229167 15.5229167,20 10,20 C4.47708334,20 0,15.5229167 0,10 C0,4.47708334 4.47708334,0 10,0 Z M13.3154167,10.8333333 L6.68458334,10.8333333 C6.86875,15.0870833 8.43875,18.3333333 10,18.3333333 C11.5608333,18.3333333 13.13125,15.0870833 13.3154167,10.8333333 Z M5.01625,10.8333333 L1.70791666,10.83375 C2.01375,13.91125 3.99375,16.49625 6.72458334,17.665 C5.7425,15.9904167 5.12166668,13.5566667 5.01625,10.83375 L5.01625,10.8333333 Z M18.2920833,10.83375 L14.98375,10.83375 C14.8783333,13.5570833 14.2575,15.9904167 13.2754167,17.6645833 C16.00625,16.4966667 17.98625,13.91125 18.2920833,10.83375 L18.2920833,10.83375 Z M6.725,2.33541666 L6.6325,2.37541666 C3.94875,3.56208334 2.01,6.12375 1.70833334,9.16666666 L5.01666666,9.16666666 C5.12208332,6.44333332 5.74291666,4.00958332 6.725,2.33541666 Z M10,1.66666666 L9.9375,1.66833334 C8.3975,1.75333334 6.86666666,4.97 6.68458334,9.16666666 L13.3154167,9.16666666 C13.13125,4.91333334 11.56125,1.66666666 10,1.66666666 Z M13.2754167,2.335 L13.3366667,2.44125 C14.2833333,4.11125 14.8804167,6.5 14.9833333,9.16666666 L18.2916667,9.16666666 C17.98625,6.08875 16.0058333,3.50375 13.275,2.335 L13.2754167,2.335 Z"
                            id="形状"></path>
                    </g>
                </g>
            </g>
        </svg>
        <el-select v-model="lang" class="m-2" placeholder="Select" size="large" popper-class="lang-select">
            <el-option v-for="item in langList" :key="item.lang" :label="item.display" :value="item.lang" />
        </el-select>
    </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue'

import { useI18n } from "vue-i18n"

const { locale } = useI18n()
const lang = ref<string>('en')


const setLang = (lang: string) => {
    const getHTMLTag = document.documentElement
    getHTMLTag.setAttribute("lang", lang)
    localStorage.setItem('locale', lang)

    locale.value = lang
}

watch(lang, (value: string) => {
    setLang(value)
})

const initSelectLang = () => {
    lang.value = localStorage.getItem('locale') || 'en'
}

onMounted(() => {
    initSelectLang()
})

const langList = [
    {
        lang: 'en',
        display: 'ENGLISH',
        displaymobile: 'EN'
    },
    {
        lang: 'ja',
        display: '日本語',
        displaymobile: 'JP'
    },
    {
        lang: 'zh-TW',
        display: '繁體中文',
        displaymobile: '繁'
    }
]

</script>

<style lang="stylus">

    .lang-select{
        border: none !important;
        background-color: #000;
        &+.el-popper__arrow{
            display: none ;
        }

        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
            background-color: #44434E !important;
            color: #fff;
        }
        .el-select-dropdown__item {
            background-color: #000;    
        }

        .el-select-dropdown__item.selected {
            color: #fff;    
        }

        .chain-select .el-select-dropdown__item.selected {
            color: #fff;
        }
    }
</style>
<style scoped lang="stylus">
    .change-lang-wrap{
        display: flex;
        align-items: center;
        :deep(.el-select .el-input__inner) {

            background-color: transparent;
            border: none;
            color: white;
            width: 110px !important;
            min-width : 90PX;
            font-family: Avenir-Heavy;
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: 0;
            // text-align: center;
            
        }
      
        :deep(.el-select){

            margin: 0 !important;
        }
    }
</style>
