<template>
    <div class="terms height-header-padding d-flex align-items-start justify-content-center">
        <div class="content d-flex flex-column align-items-center">
            <template v-if="langCur === 'zh-TW'">
                <h2>TAP FANTASY 使用者條約</h2>
                <p>
                    歡迎來到 Grape Tech（“我們”或“我們的”）擁有和運營的 Tap Fantasy。這些使用條款構成您個人或代表實體（“您”或“用戶”）與 Grape Tech（“我們”或“我們的")
                    關於您訪問和使用 Tap Fantasy 網站和 Tap Fantasy
                    應用程序以及任何其他媒體形式、媒體渠道、移動網站或與之相關、鏈接或以其他方式連接的移動應用程序（統稱為“網站”和“應用程序”）。 Tap Fantasy 是一個分佈式應用程序，目前在 BSC 網絡和
                    SOLANA
                    網絡（“區塊鏈”）上運行，使用專門開發的智能合約（每個，“智能合約”），使用戶能夠擁有、轉讓、戰鬥和創造基因獨特的數字生物（統稱為“生物”）。它還使用戶能夠擁有、轉讓和創造數字資產（“資產”），如土地、物品和角色扮演設備。然後可以在用戶可以訪問的網站（“網站”）上可視化這些生物和資產互動。智能合約和網站在這些條款中統稱為“應用程序”。使用該應用程序，用戶可以查看他們的生物和資產，並讓他們的生物與其他應用程序用戶的生物戰鬥。使用智能合約，用戶可以與其他應用程序用戶獲取和轉移他們的生物和資產。本條款中的網站、應用程序和智能合約統稱為“Tap
                    Fantasy”。本術語中的生物和資產統稱為“Tap Fantasy NFT”。
                </p>
                <p>只有在您接受所有這些條款的情況下，我們才願意為您提供應用程序、智能合約和網站。通過使用本應用程序、智能合約、網站或任何其他官方 Tap Fantasy
                    產品，您確認您已閱讀、理解並同意受所有這些使用條款的約束。如果您不同意和/或接受所有這些使用條款，則明確禁止您使用本網站、應用程序和智能合約，並且您必須立即停止使用。
                </p>
                <p>
                    能不時發佈在網站、應用程序和智能合約上的補充條款和條件或文件特此通過引用明確併入本文。我們保留自行決定隨時以任何理由對這些使用條款進行更改或修改的權利。我們將通過更新這些使用條款的“最後更新”日期來提醒您任何更改，並且您放棄接收每個此類更改的具體通知的任何權利。您有責任定期查看這些使用條款以隨時了解情況在修訂日期後繼續使用本網站、應用程序和智能合約，您將受到並被視為已了解並接受任何修訂後的使用條款的更改使用條款已發布。
                </p>
                <p>
                    本網站、應用程序和智能合約上的資訊無意分發給任何司法管轄區或國家/地區的任何個人或實體，如果此類分發或使用將違反法律或法規，或會使我們遵守任何註冊要求在該司法管轄區或國家/地區內。因此，任何人在違反其管轄範圍內的法律的情況下訪問本網站和/或應用程序的人都是主動進行的，並且必須在適用的情況下處理後果。
                </p>
                <p>
                    在某些情況下，我們可能會要求您提供額外的資訊和文件，例如應任何政府機構的要求，按照任何適用的法律或法規的規定，或調查可能違反這些使用條款的行為。在這種情況下，我們可以自行決定禁用或阻止您訪問或使用
                    Tap Fantasy 的能力，直到我們處理此類附加資訊和文件。如果您沒有提供完整和準確的資訊來響應此類請求，我們可能會拒絕恢復您對 Tap Fantasy
                    的訪問或使用。我們保留隨時自行決定暫時或永久修改、暫停或終止服務（或其任何功能或部分）的權利，且不承擔任何責任。
                </p>
                <p>
                    您對 Tap Fantasy 的訪問和使用可能會不時因多種原因而中斷，包括但不限於設備故障、定期更新、維護或維修 Tap Fantasy 服務或我們在我們的自行決定，可選擇採取。
                </p>
                <p>

                    根據您所在國家或司法管轄區的法律，我們要求您是具有完全法律行為能力的多數人。如果您未達到法定成年年齡，您只能通過父母或監護人的帳戶並在他們的批准和監督下使用 Tap
                    Fantasy。該帳戶持有人應對您使用該帳戶的行為負責。如果您未滿 13 歲，則禁止使用 Tap Fantasy。
                </p>
                <p>
                    您了解 Tap Fantasy NFTS 和我們發行的任何代幣（“TAP”、“MC”和“GOLD”，統稱為“Tap Fantasy 代幣”）尚未且不會根據 1933
                    年證券法註冊，如修訂（“證券法”）和任何其他國家的任何證券交易法。此外，Tap Fantasy NFTS 或 Tap Fantasy 代幣的銷售不被視為一種籌資方式，擁有 Tap Fantasy
                    NFTS 或 Tap Fantasy 代幣的人沒有任何對 Grape Tech 的權利，例如，要求股權所有權的權利如果 Tap Fantasy NFTS 和 Tap Fantasy
                    代幣被視為構成證券或證券法適用的交易、合約或計劃或其他交易、合約或計劃不可分割，Tap Fantasy
                    代幣，此類證券的要約和銷售尚未根據任何國家/地區的證券法進行註冊，因此，除非遵守適用國家/地區的法律，否則不得轉售。您了解 Tap Fantasy NFTS 和 Tap Fantasy
                    代幣可能是適用法律下的“受限安全”，並且根據這些法律，您必須無限期地持有這些 Tap Fantasy NFTS 和 Tap Fantasy 代幣。您承認公司沒有義務註冊或有資格轉售這些 Tap
                    Fantasy NFTS 和 Tap Fantasy 代幣。您進一步承認，如果可以豁免註冊或資格，可能會受到各種要求的限制，包括但不限於銷售時間和方式、持有期等。不在您和我們的控制範圍內。
                </p>
                <h3>1. 知識產權</h3>
                <p>在本條款中，當您購買或獲得任何 Tap Fantasy NFT 時，您擁有 Tap Fantasy NFT，這意味著您有權交易、出售或贈送（即轉讓）您的 Tap Fantasy NFT，並且對與 Tap
                    Fantasy NFT 相關的所有活動和互動負全部責任。 Tap Fantasy NFT 的所有權完全由智能合約和相關的區塊鍊網絡管理。</p>
                <p>您承認並同意 Grape Tech（或我們的關聯公司和許可方）擁有所有知識產權（包括但不限於版權、商標權、專利權等）、合法權利、所有權和利益所有 Tap Fantasy
                    NFT，包括但不限於所有藝術、設計、繪圖、系統、方法、資訊、計算機代碼、軟體、服務、網站設計、“外觀”、組織、內容、代碼、數據和Tap Fantasy NFT
                    的數據庫、功能、音頻、視頻、文本、照片、圖形和所有其他元素（統稱為“材料”）。您承認這些材料受版權、商業外觀、專利和商標法、國際公約、其他相關知識產權和所有權以及適用法律的保護。所有材料均為
                    Grape Tech（或我們的關聯公司和許可方，如適用）的版權財產，與 Tap Fantasy 相關或材料中包含的所有商標、服務標記和商品名稱均為 Grape
                    Tech（或我們的關聯公司）專有和許可人（如適用）。除非本文明確規定，您訪問或使用本網站、應用程序或智能合約並不授予您對任何內容、代碼、數據或其他材料的所有權或任何其他權利。對於這些條款中未明確授予您的材料，我們保留所有權利。
                </p>
                <p>為清楚起見，您理解並同意： (a) 您擁有的任何 Tap Fantasy NFT 的任何知識產權（包括但不限於版權、商標權、專利權等）均歸屬於 Grape Tech
                    （或我們的關聯公司和許可方，如適用）始終； (b) 您購買或採購 Tap Fantasy NFT 並未授予您對材料的任何權利或許可（包括但不限於我們對可能相關的任何藝術、設計和繪圖的版權）使用您擁有的
                    Tap Fantasy NFT），但這些條款中明確包含的內容除外； (c)
                    除非本條款另有規定，否則您無權複製、分發、展示、商業使用或以其他方式將材料的任何元素（包括但不限於任何藝術、設計和可能與您擁有的 Tap Fantasy NFT
                    相關的圖紙）在每種情況下未經我們事先書面同意，我們可以全權酌情決定不同意； (d) 未經我們事先書面同意，您不會在世界任何地方申請、註冊或以其他方式使用或試圖使用任何 Tap Fantasy
                    商標或服務標記，或任何容易混淆的類似標記，在每種情況下，我們同意可以由我們全權酌情決定扣留。我們授予您非排他性、全球性、免版稅許可，以將 Tap Fantasy NFT 中的專有權利用於以下目的：
                </p>
                <ul>
                    <li class="dot">在 Tap Fantasy 中用作生物或資產。</li>
                    <li class="dot">供您個人的、非商業的、合理的使用。</li>
                </ul>
                <p>
                    根據本條款第 1 條，Tap Fantasy NFT 的所有知識產權歸我們所有。如果根據您居住或訪問或使用 Tap Fantasy
                    的國家或司法管轄區的任何適用法律授予您任何此類知識產權，您同意執行任何和所有此類行為並執行任何和所有此類文件我們可能會合理地要求，以便向我們授予全球性的、獨家的、不可轉讓的、免版稅的許可，或免費將此類知識產權轉讓給我們。在這種情況下，您聲明並保證您擁有或已經獲得授予或轉讓此處授予或轉讓的權利所需的所有權利、許可、同意、許可、權力和/或授權。您聲明並保證此類
                    Tap Fantasy NFT 不受版權、商標、商業外觀、專利、商業秘密、宣傳權、其他知識產權或任何其他合法權利的約束，除非您獲得必要的許可或合法有權授予我們將上述許可或知識產權轉讓給我們，並且
                    Tap Fantasy NFT 不違反任何適用法律。
                </p>
                <h3>2. 侵犯知識產權</h3>
                <p>Tap Fantasy 將發送刪除通知和/或其他知識產權侵權索賠，並將從 Tap Fantasy 中刪除侵犯或違反他人知識產權或任何其他合法權利的 Tap Fantasy
                    NFT，或終止用戶對本網站的訪問，應用程序和智能合約。</p>
                <p>如果您認為任何 Tap Fantasy NFT 侵犯了您的版權、商標權、商業外觀、專利、商業秘密、宣傳權、任何其他知識產權或任何其他合法權利，請通過我們提供的電子郵件地址通知我們在 Tap
                    Fantasy 網站上。為了讓我們處理您的侵權索賠，您必須是權利人或被授權代表權利人行事的人。</p>
                <p>我們建議您發送給我們的電子郵件中可以包含以下資訊：</p>
                <ul>
                    <li class="dot">識別您聲稱受到侵犯的版權作品、商標、商業外觀、宣傳權、專利、商業秘密、任何其他知識產權或任何其他合法權利；</li>
                    <li class="dot">識別被要求刪除的涉嫌侵權的Tap Fantasy NFT；</li>
                    <li class="dot">您的聯繫資訊——至少是您的法定全名（非化名）和電子郵件地址；</li>
                    <li class="dot">包含以下所有內容的聲明：</li>
                    <li class="dot">聲明您有充分的理由相信以所投訴的方式使用 Tap Fantasy NFT 未經知識產權所有者、其代理人或法律授權；
                    </li>
                    <li class="dot">聲明通知中的資訊準確無誤和聲明您有權代表涉嫌侵權的知識產權的知識產權所有者行事，受偽證罪處罰。
                    </li>
                    <li class="dot">您的物理或電子簽名（您的法定全名）。</li>
                </ul>
                <p>
                    請注意，我們會將您有關知識產權侵權的電子郵件（包括您的聯繫資訊）轉發給將從 Tap Fantasy 中刪除其 Tap Fantasy NFT 的一方，以便他們了解為什麼 Tap Fantasy
                    不再提供該 NFT 並可以聯繫 您可以解決任何爭議。
                </p>
                <h3>3. 用戶陳述</h3>
                <p>通過使用本網站、應用程序和智能合約，您聲明並保證：</p>
                <ul>
                    <li class="dot">您提交的所有註冊資訊都是真實、準確、最新和完整的；</li>
                    <li class="dot">您將保持此類資訊的準確性，並在必要時及時更新此類註冊資訊；</li>
                    <li class="dot">您是具有法律行為能力的多數人，並且您同意遵守本使用條款；</li>
                    <li class="dot">在您居住的司法管轄區，您不是未成年人，除非您另有父母或監護人的批准和監督；</li>
                    <li class="dot">您不會通過自動和非人工方式訪問網站、應用程序和智能合約，無論是通過機器人、腳本還是其他方式。</li>
                    <li class="dot">您不會將本網站、應用程序和智能合約用於任何非法和未經授權的目的；</li>
                    <li class="dot">
                        您對本網站、應用程序和智能合約的使用不會違反任何適用的法律或法規。如果您提供任何不真實、不准確、不是最新或不完整的資訊，我們有權暫停或終止您的帳戶，並拒絕任何和所有當前或未來使用本網站、應用程序和智能合約（或任何部分）。
                    </li>
                    <li class="dot">您（人類）將只使用一個遊戲內帳戶來賺取代幣。</li>
                    <li class="dot">您作為 Tap Fantasy NFT 的所有者，應對任何“使用者”（使用您擁有的任何 Tap Fantasy
                        NFT，例如角色扮演設備的其他用戶）的行為負責，並且他們的行為可能會對任何連接的帳戶產生影響你擁有的。 </li>
                    <li class="dot">
                        您未被列入任何貿易禁運或經濟制裁名單（如聯合國安理會制裁名單）、OFAC（美國財政部外國資產控制辦公室）維護的特別指定國民名單)、美國商務部被拒絕的人員或實體名單、日本財務省的經濟制裁和目標名單，以及日本經濟產業省網站上公佈的人員或實體。
                    </li>
                    <li class="dot">Tap Fantasy、我們或與我們合作的任何第三方提供商可能會儲存您用於訪問網站的 IP 地址。</li>
                </ul>
                <p>Grape Tech 保留選擇在哪些市場和司法管轄區開展業務的權利，並可自行決定限製或拒絕在某些國家或地區提供 Tap Fantasy 服務。</p>
                <h3>4. 用戶註冊</h3>
                <p>
                    您可能需要在網站、應用程序和智能合約上註冊。 您同意對您的密碼保密，並對您的帳戶和密碼的所有使用負責。
                    如果我們自行決定確定此類用戶名不合適、淫穢或以其他方式令人反感，我們保留刪除、收回或更改您選擇的用戶名的權利。
                </p>
                <h3>5. 禁止活動</h3>
                <p>您不得出於我們提供網站、應用程序和智能合約的目的以外的任何目的訪問或使用網站、應用程序和智能合約。 本網站、應用程序和智能合約不得用於任何商業活動，除非在與 Grape Tech
                    具有約束力的法律合約中同意 禁止以下活動，您同意並保證您不會違反其中任何一項：</p>
                <ul>
                    <li class="dot">未經我們事先書面許可，系統地從網站、應用程序和智能合約檢索數據或其他內容，以直接或間接創建或編譯集合、編譯、數據庫或目錄。</li>
                    <li class="dot">
                        對網站、應用程序和智能合約進行任何未經授權的使用，包括通過電子或其他方式收集用戶的用戶名和/或電子郵件地址以發送未經請求的電子郵件，或通過自動方式或虛假創建用戶帳戶偽裝。
                    </li>
                    <li class="dot">使用採購代理或採購代理在網站、應用程序和智能合約上進行採購。</li>
                    <li class="dot">使用網站、應用程序和智能合約來宣傳或提議銷售商品和/或服務。</li>
                    <li class="dot">規避、禁用或以其他方式干擾網站、應用程序和智能合約的安全相關功能，包括阻止或限制使用或複制任何內容或強制限制使用網站、應用程序的功能以及其中包含的智能合約和/或內容。
                    </li>
                    <li class="dot">參與未經授權構建或鏈接到網站、應用程序和智能合約。</li>
                    <li class="dot">欺騙、詐欺或誤導我們和其他用戶，尤其是在任何試圖了解敏感帳戶資訊（如用戶密碼）的情況下。</li>
                    <li class="dot">不當使用我們的支持服務或提交關於濫用或不當行為的虛假報告。</li>
                    <li class="dot">參與系統的任何自動化使用，例如使用腳本發送評論或消息，或使用任何數據挖掘、機器人或類似的數據收集和提取工具，除非在 2.5 中提及）。</li>
                    <li class="dot">干擾、破壞站點、應用程序和智能合約或連接到站點的網絡或服務，或給站點、應用程序和智能合約造成不適當的負擔。</li>
                    <li class="dot">試圖冒充另一個用戶或個人或使用另一個用戶的用戶名。</li>
                    <li class="dot">出售或以其他方式轉讓您的個人資料。</li>
                    <li class="dot">使用從網站、應用程序和智能合約獲得的任何資訊來騷擾、虐待或傷害他人。</li>
                    <li class="dot">使用網站、應用程序和智能合約作為與我們競爭的任何努力的一部分，或以其他方式將網站、應用程序和智能合約和/或內容用於任何創收努力或商業企業。</li>
                    <li class="dot">解密、反編譯、反彙編或逆向工程任何軟體，包括或以任何方式構成網站、應用程序和智能合約的一部分。</li>
                    <li class="dot">試圖繞過網站旨在防止或限制訪問網站或網站的任何部分、應用程序和智能合約的任何措施。</li>
                    <li class="dot">騷擾、恐嚇或威脅我們任何參與向您提供網站、應用程序和智能合約的任何部分的員工或代理。</li>
                    <li class="dot">從任何內容中刪除版權或其他所有權聲明。</li>
                    <li class="dot">複製或改編本網站的軟體，包括但不限於 Flash、PHP、HTML、JavaScript 或其他代碼。</li>
                    <li class="dot">
                        上傳或傳輸（或嘗試上傳或傳輸）病毒、特洛伊木馬或其他材料，包括過度使用大寫字母和垃圾郵件（連續發布重複文本），這些會干擾任何用戶不間斷地使用和享受本網站或修改、損害、破壞、更改或干擾本網站、應用程序和智能合約的使用、特性、功能、操作或維護。
                    </li>
                    <li class="dot">上傳或傳輸（或嘗試上傳或傳輸）作為被動或主動資訊收集或傳輸機制的任何材料，包括但不限於清晰的圖形交換格式（“gifs”）、1×1 像素、網絡錯誤、cookies
                        或其他類似設備（有時稱為“間諜軟體”或“被動收集機制”或“pcms”）。</li>
                    <li class="dot">
                        除標準搜索引擎或互聯網瀏覽器使用的結果外，使用、啟動、開發或分發任何自動化系統，包括但不限於訪問本網站的任何蜘蛛、機器人、作弊工具、刮板或離線閱讀器、應用程序和智能合約，或使用或啟動任何未經授權的腳本或其他軟體。
                    </li>
                    <li class="dot">在我們看來，貶損、玷污或以其他方式損害我們和/或網站、應用程序和智能合約。</li>
                    <li class="dot">以不符合任何適用法律或法規的方式使用網站、應用程序和智能合約。</li>
                    <li class="dot">參與任何旨在操縱任何Tap Fantasy 結果的實踐。所有用戶都應盡其所能，嚴禁用戶之間進行任何形式的作弊、交易贏利或勾結。</li>
                    <li class="dot">嚴禁使用多個賬戶賺取代幣。</li>
                </ul>
                <h3>6. 費用和付款</h3>
                <p>
                    從網站或市場進行的任何購買（例如，購買 Tap Fantasy NFT）都將使用 MetaMask 等錢包通過區塊鏈上的智能合約完成。您參與的任何金融交易將僅通過區塊鏈通過 MetaMask
                    等錢包進行。我們將無法洞察或控制這些付款或交易，也無法撤銷任何交易。考慮到這一點，對於您通過網站和/或應用程序或使用智能合約進行的任何交易而可能產生的任何索賠或損害，我們對您或任何第三方不承擔任何責任，或您通過
                    BSC 或 SOLANA 網絡進行的任何其他交易。
                </p>
                <p>
                    BSC 或 SOLANA 要求為 BSC 或 SOLANA 網絡上發生的每筆交易支付交易費（“Gas 費”）。 Gas Fee 為運行分散式 BSC 或 SOLANA
                    網絡的計算機網絡提供資金。這意味著您需要為通過應用程序發生的每筆交易支付 Gas 費。
                </p>
                <p>
                    除 Gas
                    費外，每次您使用智能合約通過應用程序與其他用戶進行交易時，您授權我們收取總價值的一定百分比作為該交易的佣金（每一項，“佣金”）。具體百分比將視具體情況而定。您承認並同意，作為交易的一部分，佣金將通過
                    BSC 或 SOLANA 網絡直接轉移給我們。
                </p>
                <p>
                    在我們之間，您將全權負責支付現在或以後任何政府機構（統稱， “稅費”）與您使用該應用程序相關（包括但不限於因您擁有、轉讓、購買或交易您的任何 Tap Fantasy NFT
                    而可能需要支付的任何稅費）。除對我們徵收的所得稅外，您：(i)
                    將支付或償還我們所有國家、聯邦、州、地方或任何司法管轄區的其他稅款和評估，包括增值稅和國際稅收協定要求的稅款、海關或其他進出口稅，以及根據本協議項下的收費、提供的服務或支付的款項而徵收的替代稅款，如現在或以後可能在任何國家、州、地方或任何其他稅收管轄區的授權下徵收的;
                    (ii) 無權從根據這些條款向我們支付的款項中扣除任何此類稅款、關稅或評估的金額。
                </p>
                <h3>7. 提交</h3>
                <p>
                    您承認並同意，您向我們提供的有關本網站、應用程序和智能合約的任何問題、意見、建議、想法、回饋或其他資訊（“提交內容”）都是非機密的，應成為我們的專有財產。我們應擁有專有權利，包括所有知識產權，並且應有權出於任何合法目的、商業或其他目的不受限制地使用和傳播這些提交內容，而無需對您作出承認或補償。您在此放棄對任何人的任何人格權此類提交內容，並且您在此保證任何此類提交內容是您的原創，或者您有權提交此類提交內容。您同意，對於您提交內容中任何涉嫌或實際侵犯或盜用任何所有權的行為，您不應向我們追索。
                </p>
                <h3>8. 第三方網站和內容</h3>
                <p>
                    本網站和/或應用程序（或您可能通過本網站和/或應用程序發送）鏈接到其他網站（“第三方網站”）以及文章、照片、文本、圖形、圖片、設計、音樂、聲音、視頻、資訊、應用程序、軟體和其他屬於或源自第三方的內容或項目（“第三方內容”）。我們不會對此類第三方網站和第三方內容進行調查、監控或檢查其準確性、適當性或完整性，並且我們不對通過本網站和/或應用程序訪問的任何第三方網站負責，或任何在網站和/或應用程序上發布、可通過或安裝的第三方內容，包括第三方網站的內容、準確性、冒犯性、意見、可靠性、隱私慣例或其他政策或第三方網站中包含的其他政策，或第三方內容。包含、鏈接或允許使用或安裝任何第三方網站或任何第三方內容並不意味著我們對其的批准或認可。如果您決定離開本網站和/或應用程序並訪問第三方網站或使用或安裝任何第三方內容，您將自行承擔風險，並且您應該知道這些使用條款不再適用.您應查看您從本網站和/或應用程序導航到的任何網站或與您從本網站和/或應用程序使用或安裝的任何應用程序相關的適用條款和政策，包括隱私和數據收集做法。您通過第三方網站進行的任何購買都將通過其他網站和其他公司進行，對於您與適用第三方之間的此類購買，我們不承擔任何責任。您同意並承認我們不認可第三方網站上提供的產品和服務，並且您應該使我們免受因您購買此類產品和服務而造成的任何損害。此外，您應使我們免受您遭受的任何損失或因任何第三方內容或與第三方網站的任何联係而以任何方式對您造成的傷害。
                </p>
                <h3>9. 廣告商</h3>
                <p>
                    我們允許廣告商在網站和應用程序的某些區域展示他們的廣告和其他資訊，例如側邊欄廣告或橫幅廣告。
                    如果您是廣告商，您應對您在網站和/或應用程序上投放的任何廣告，以及在網站和/或應用程序上提供的任何服務，或通過這些廣告銷售的產品承擔全部責任。
                    此外，作為廣告商，您保證並聲明您擁有在網站和/或應用程序上投放廣告的所有權利和授權，包括但不限於知識產權、宣傳權和合約權利。 我們只是提供放置此類廣告的空間，我們與廣告商沒有其他關係。
                </p>
                <h3>10. 終止</h3>
                <p>
                    當您使用本網站、應用程序和智能合約時，這些使用條款仍然完全有效。在不限製本使用條款的任何其他規定的情況下，我們保留在不通知或不承擔任何責任的情況下自行決定拒絕訪問和使用本網站、應用程序和智能合約（包括阻止某些
                    IP
                    地址）的權利任何人出於任何原因或無原因，包括但不限於任何法院或主管當局作出的任何命令或法令，或違反本使用條款或任何適用法律或法規中包含的任何陳述、保證或契約。我們可以自行決定終止您對網站、應用程序和智能合約的使用或參與，或在不發出警告的情況下刪除您的帳戶。
                </p>
                <p>
                    如果我們出於任何原因終止或暫停您的帳戶，則禁止您以您的姓名、假名或借用姓名或任何第三方的名義註冊和創建新帳戶，即使您可能代表第三者。除了終止和暫停您的帳戶外，我們保留採取適當法律行動的權利，包括但不限於尋求民事、刑事和禁令救濟。
                </p>
                <h3>11. 適用法律</h3>
                <p>
                    他的使用條款以及您對本網站、應用程序和智能合約的使用均受適用於在新加坡訂立並完全在新加坡履行的協議的新加坡法律管轄和構建，不考慮其法律衝突原則。
                </p>
                <h3>12. 爭議解決</h3>
                <h4>12.1 非正式談判</h4>
                <p>
                    加快解決和控制由您或我們（單獨稱為“一方”和統稱）提出的與本使用條款相關的任何爭議、爭議或索賠（單稱“爭議”和統稱“爭議”）的成本，“雙方”），雙方同意首先嘗試在啟動仲裁前至少三十 (30)
                    天以非正式方式協商任何爭議（下文明確規定的爭議除外）。此類非正式談判在一方書面通知另一方後開始。
                </p>
                <h4>12.2 有約束力的仲裁</h4>
                <p>
                    如果一方無法通過非正式談判解決爭議，爭議（下文明確排除的爭議除外）將最終通過具有約束力的仲裁解決。因本條款引起或與本條款有關的所有爭議均應根據新加坡國際仲裁中心 (SIAC)
                    的商業仲裁規則通過仲裁最終解決。仲裁地點為新加坡。
                </p>
                <p>
                    如果出於任何原因，爭議在法庭而非仲裁中進行，爭議應在位於新加坡的法院提起或起訴，雙方特此同意並放棄所有關於缺乏屬人管轄權和法院不方便的抗辯尊重此類法院的地點和管轄權。
                </p>
                <p>
                    在任何情況下，任何一方提出的與網站、應用程序和智能合約相關的任何爭議均不得在訴訟原因發生後一 (1)
                    年後開始。如果本條款被認定為非法或不可執行，則任何一方均不會選擇對屬於本條款被認定為非法或不可執行的部分的任何爭議進行仲裁，且此類爭議應由所列法院或具有管轄權的法院裁決。上述管轄權，並且雙方同意服從該法院的屬人管轄權。
                </p>
                <h4>12.3 非正式談判和仲裁的例外情況</h4>
                <p>
                    雙方同意，以下爭議不受上述關於非正式談判和具有約束力的仲裁的規定的約束：(a) 任何尋求執行或保護一方知識產權，或涉及一方知識產權有效性的爭議，(b )
                    任何與盜竊、盜版、侵犯隱私或未經授權使用的指控有關或由其引起的任何爭議； (c)
                    任何禁令救濟索賠。如果本條款被認定為非法且不可執行，則任何一方均不會選擇對屬於本條款中被認定為非法或不可執行部分的任何爭議進行仲裁，且此類爭議應由所列法院內具有管轄權的法院裁決或上述管轄權，並且雙方同意服從該法院的屬人管轄權。
                </p>
                <h3>13. 免責聲明</h3>
                <p>
                    您明確理解並同意您自行承擔訪問和使用本網站、應用程序和智能合約的風險，並且本網站、應用程序和智能合約均按“原樣”和“可用”提供任何形式的保證，無論是明示的還是暗示的。在適用法律允許的最大範圍內，我們、我們的子公司、關聯公司和許可方不作任何明示保證，並在此否認有關網站、應用程序和智能合約及其任何部分的所有默示保證（包括但不限於，本網站、任何智能合約或任何外部網站），包括對適銷性、特定用途適用性、非侵權、正確性、準確性或可靠性的默示保證。在不限制上述一般性的情況下，我們、我們的子公司、關聯公司和許可方不向您聲明或保證：(I)
                    您訪問或使用本網站、應用程序和智能合約將滿足您的要求，( II) 您對網站、應用程序和智能合約的訪問或使用將不會中斷、及時、安全或沒有錯誤，(III)
                    通過網站、應用程序和智能合約提供的使用數據將是準確的，（ III) 網站、應用程序和智能合約或任何內容、服務或在網站上或通過網站提供的任何內容、服務或功能、應用程序和智能合約沒有病毒或其他有害組件，或
                    (IV) 任何數據您在使用本網站時披露，該應用程序和智能合約將是安全的。某些司法管轄區不允許排除與消費者簽訂的合約中的默示保證，因此上述部分或全部排除條款可能不適用於您。
                </p>
                <p>
                    您接受通過 Internet 提供資訊和在線交易的固有安全風險，並同意我們對任何違反安全的行為不承擔任何責任或義務，除非它是由於我們的重大過失造成的。
                </p>
                <p>
                    對於您因使用 BSC 網絡、SOLANA 網絡和/或 METAMASK 電子錢包而導致的任何損失，我們概不負責或承擔任何責任，包括但不限於因以下原因引起的任何損失、損害或索賠： (A)
                    用戶錯誤，例如忘記密碼或錯誤理解的智能合約或其他交易； (B) 服務器故障或數據丟失； (C) 損壞的錢包文件； (D)
                    第三方未經授權的訪問或活動，包括但不限於使用病毒、網絡釣魚、暴力破解或其他攻擊網站、應用程序、智能合約、BSC 網絡、SOLANA 網絡和/或METAMASK 電子錢包。
                </p>
                <p>
                    Tap Fantasy NFTS 是無形的數字資產，僅通過維護在 BSC 或 SOLANA 網絡上的所有權記錄而存在。所有智能合約都在 BSC 或 SOLANA 網絡內的去中心化賬本上進行和發生。
                    Grape Tech 對智能合約沒有控制權，也不做任何保證或承諾。對於因區塊鍊或 BSC 網絡、SOLANA 網絡和/或 METAMASK
                    電子錢包的任何其他功能而導致的損失，我們概不負責。包括但不限於開發人員或代表延遲報告（或根本不報告）支持 BSC 或 SOLANA
                    網絡的區塊鏈的任何問題，包括分叉、技術節點問題或任何其他導致資金損失的問題。
                </p>
                <h3>14. 責任限制</h3>
                <p>
                    您理解並同意，我們、我們的子公司、關聯公司和許可方將不對您或任何第三方承擔任何間接的、偶然的、特殊的、後果性的或示例性的損害，無論您是出於何種原因或根據以下任何理論造成的責任，包括但不限於任何利潤損失（直接或間接產生）、商譽或商業信譽損失、數據丟失、替代商品或服務的採購成本或任何其他無形損失，即使我們已經被告知此類損害的可能性。
                </p>
                <p>
                    您同意並承認，對於因這些條款或您訪問或使用（或您無法訪問或使用）本網站、應用程序和智能合約，無論是合約、侵權、嚴格責任或任何其他法律理論，僅限於 (A) 您在索賠日期之前的十二 (12)
                    個月內根據這些條款實際支付給我們的金額中的較大者升起，或 (B) 一百 (100) 美元。
                </p>
                <p>
                    您同意並承認，我們已根據此處規定的保證免責聲明和責任限制，向您提供了網站、應用程序和智能合約，並簽訂了這些條款，這反映了合理和公平的風險分配各方並構成我們之間討價還價的重要基礎。如果沒有這些限制，我們將無法向您提供網站、應用程序和智能合約。
                </p>
                <p>
                    某些司法管轄區不允許排除或限製附帶或間接損害，某些司法管轄區還限制免責聲明或對消費品造成的人身傷害的責任限制，因此上述限制可能不適用於人身傷害索賠。
                </p>
                <h3>15. 風險承擔</h3>
                <p>您接受並確認以下各項：</p>
                <p>
                    A. 區塊鏈資產價格波動劇烈。其他數字資產價格的波動可能會對您的 Tap Fantasy NFT 的價值產生重大不利影響，這也可能會受到重大價格波動的影響。我們不能保證任何 Tap Fantasy
                    NFT 的購買者不會賠錢。
                </p>
                <p>
                    B. 您全權負責確定適用於您的 Tap Fantasy NFT 相關交易的稅款（如果有）。我們不負責確定適用於您在應用程序、網站或智能合約上的交易的稅費。
                </p>
                <p>
                    C. 應用程序不儲存、發送或接收任何 Tap Fantasy NFT。這是因為 Tap Fantasy NFT 僅憑藉在 BSC 或 SOLANA 網絡上的應用程序支持區塊鏈上維護的所有權記錄而存在。
                    Tap Fantasy NFT 的任何轉移僅發生在 BSC 或 SOLANA 網絡上。
                </p>
                <p>
                    D. 使用基於互聯網的貨幣存在相關風險，包括但不限於硬體、軟體和互聯網連接的風險、惡意軟體引入的風險以及第三方可能獲得未經授權訪問資訊的風險儲存在您的錢包中。您接受並承認，對於您在使用 BSC 或
                    SOLANA 網絡時可能遇到的任何通信故障、中斷、錯誤、失真或延遲，無論是何種原因造成的，我們概不負責。
                </p>
                <p>
                    E. 在分佈式生態系統的創建和開發中缺乏使用或公眾利益可能會對 Tap Fantasy NFT 生態系統的發展產生負面影響，從而影響 Tap Fantasy NFT 的潛在效用或價值。
                </p>
                <p>
                    F. 管理區塊鏈技術、加密貨幣和代幣的監管制度不確定，新的法規或政策可能會對 Tap Fantasy 生態系統的發展產生重大不利影響，從而影響 Tap Fantasy NFT 的潛在效用或價值。
                </p>
                <p>G. 升級到 BSC 或 SOLANA 網絡可能會對所有 Tap Fantasy NFT 產生意想不到的不利影響。</p>
                <h3>16. 賠償</h3>
                <p>
                    您同意就任何損失、損害、責任、索賠或要求（包括合理的律師）為我們（包括我們的子公司、關聯公司以及我們各自的高級職員、代理、合作夥伴和員工）進行辯護、賠償並使我們免受損害'
                    第三方因以下原因產生的費用和開支：(1) 使用本網站、應用程序和/或智能合約 (2) 違反本使用條款，(3) 任何違反您的本使用條款中規定的陳述和保證，(4)
                    您侵犯第三方的權利，包括但不限於知識產權，或 (5)
                    對本網站的任何其他使用的任何公然有害行為，應用程序和您通過網站連接的智能合約、應用程序和智能合約。儘管有上述規定，我們保留由您承擔費用對您需要賠償我們的任何事項進行獨家辯護和控制的權利，並且您同意自費配合我們對此類索賠的辯護。我們將盡合理努力通知您任何此類索賠、訴訟或訴訟，一旦發現該索賠、訴訟或訴訟將受到此賠償的約束。
                </p>
                <h3>17. 用戶數據和隱私政策</h3>
                <p>
                    您承認並同意，我們將保留您傳輸到網站、應用程序和智能合約的某些數據，以管理網站、應用程序和智能合約的性能，以及與您使用網站、應用程序和智能合約。儘管我們定期對數據進行例行備份，但您對您傳輸或發布給您使用網站、應用程序和智能合約進行的任何活動的所有數據負全部責任。您同意我們對任何此類數據的任何丟失或損壞不承擔任何責任，並且您在此放棄因此類數據的任何此類丟失或損壞而對我們提起的任何訴訟權利。
                </p>
                <p>
                    有關我們如何收集、使用和共享您的個人數據的資訊，請參閱我們的隱私政策。通過我們的服務提交個人數據，即表示您同意我們的隱私政策條款，並明確同意根據隱私政策收集、使用和披露您的個人數據。
                </p>
                <h3>18. 雜項</h3>
                <p>
                    這些使用條款以及我們在網站、應用程序和智能合約上或關於網站、應用程序和智能合約發布的任何政策或操作規則構成您與我們之間的完整協議和諒解。我們未能行使或執行本使用條款的任何權利或規定不應視為對此類權利或規定的放棄。這些使用條款在法律允許的最大範圍內運作。我們可以隨時將我們的任何或所有權利和義務轉讓給他人。對於超出我們合理控制範圍的任何原因造成的任何損失、損害、延遲或未能採取行動，我們概不負責。如果本使用條款的任何條款或條款的一部分被確定為非法、無效和不可執行，則該條款或條款的一部分被視為可分割本使用條款，並且不影響任何剩餘條款的有效性和可執行性.由於這些使用條款或使用本網站，您和我們之間不會產生合資、合夥、僱傭或代理關係。您同意這些使用條款不會因起草而被解釋為對我們不利。您特此放棄您可能基於這些使用條款的電子形式以及沒有由本協議各方簽署來執行這些使用條款的任何和所有抗辯。
                </p>
                <p>最后更新： 2022.10.17</p>
            </template>
            <template v-else>
                <h2>TAP FANTASY TERMS OF USE</h2>
                <p>Welcome to Tap Fantasy owned and operated by Grape Tech (“we,” “us”, or “our”). These Terms of Use
                    constitute a legally binding agreement made between you, whether personally or on behalf of an
                    entity
                    (“you", “User”, or “Users") and Grape Tech (“we", “us", or “our") concerning your access to and use
                    of
                    the Tap Fantasy website and the Tap Fantasy app as well as any other media form, media channel,
                    mobile
                    website or mobile application related, linked, or otherwise connected thereto (collectively, the
                    “Site"
                    and the “App"). Tap Fantasy is a distributed application that is currently running on the BSC
                    Network
                    and the SOLANA network (the "Blockchains"), using specially-developed smart contracts (each, a
                    “Smart
                    Contract”) to enable Users to own, transfer, battle and create genetically unique digital creatures
                    (a
                    “Creature” and collectively “Creatures”). It also enables Users to own, transfer and create digital
                    assets (“Assets”) like plots of land, items and cosplay equipment. These Creatures and Assets can
                    then
                    be visualized on a website (the "Site") that the User can interact with. The Smart Contracts and the
                    Site are collectively referred to in these Terms as the “App”. Using the App, Users can view their
                    Creatures and Assets and let their Creatures battle with other App Users’ Creatures. Using the Smart
                    Contracts, Users can acquire and transfer their Creatures and Assets with other App Users. The Site,
                    the
                    App and the Smart Contracts in this Term are collectively referred to “Tap Fantasy”. Creatures and
                    Assets in this Term are collectively referred to “Tap Fantasy NFTs”.
                </p>
                <p>WE ARE ONLY WILLING TO MAKE THE APP, THE SMART CONTRACTS, AND THE SITE AVAILABLE TO YOU IF YOU ACCEPT
                    ALL
                    OF THESE TERMS. BY USING THE APP, THE SMART CONTRACTS, THE SITE, OR ANY OTHER OFFICIAL Tap Fantasy
                    PRODUCT, YOU ARE CONFIRMING THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY ALL OF THESE
                    TERMS
                    OF USE. IF YOU DO NOT AGREE AND/OR ACCEPT ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
                    PROHIBITED
                    FROM USING THE SITE, THE APP AND THE SMART CONTRACTS AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                </p>
                <p>
                    Supplemental terms and conditions or documents that may be posted on the Site, the App, and the
                    Smart
                    Contracts from time to time are hereby expressly incorporated herein by reference. We reserve the
                    right,
                    in our sole discretion, to make changes or modifications to these Terms of Use at any time and for
                    any
                    reason. We will alert you of any changes by updating the “Last Updated" date of these Terms of Use,
                    and
                    you waive any right to receive specific notice of each such change. It is your responsibility to
                    periodically review these Terms of Use to stay informed of updates. You will be subject to and will
                    be
                    deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by
                    your
                    continued use of the Site, the App, and the Smart Contracts after the date such revised Terms of Use
                    are
                    posted.
                </p>
                <p>
                    The information on the Site, the App, and the Smart Contracts are not intended for distribution to
                    any
                    person or entity in any jurisdiction or country where such distribution or use would be contrary to
                    law
                    or regulation, or which would subject us to any registration requirement within such jurisdiction or
                    country. Accordingly, any persons who access the Site and/or the App while breaking their laws in
                    his,
                    her or its jurisdiction are doing it on their own initiative and will have to deal with the
                    consequences
                    when applicable.
                </p>
                <p>
                    We may require you to provide additional information and documents in certain circumstances, such as
                    at
                    the request of any government authority, as any applicable law or regulation dictates, or to
                    investigate
                    a potential violation of these Terms of Use. In such cases, we, in its sole discretion, may disable
                    or
                    block your ability to access or use of Tap Fantasy until such additional information and documents
                    are
                    processed by us. If you do not provide complete and accurate information in response to such a
                    request,
                    we may refuse to restore your access or use of the Tap Fantasy.
                </p>
                <p>
                    Your access and use of Tap Fantasy may be interrupted from time to time for any of several reasons,
                    including, without limitation, the malfunction of equipment, periodic updating, maintenance, or
                    repair
                    of the Tap Fantasy service or other actions that we, in our sole discretion, may elect to take.
                    We reserve the right in our sole discretion to modify, suspend, or discontinue, temporarily or
                    permanently, the Service (or any features or parts thereof) at any time and without liability as a
                    result.
                </p>
                <p>
                    We require you to be majority with fully legal capacity pursuant to the laws of your country or
                    jurisdiction. If you are under the legal age of being a majority, you may only use Tap Fantasy
                    through a
                    parent or guardian’s account and with their approval and oversight. That account holder is
                    responsible
                    for your actions using the Account. It is prohibited to use Tap Fantasy if you are under 13 years
                    old.
                </p>
                <p>
                    YOU UNDERSTAND THAT Tap Fantasy NFTS AND ANY TOKENSISSUED BY US (“MC”, “GOLD” AND “TAP”, AND
                    COLLECTIVELY “Tap Fantasy TOKENS”) HAVE NOT BEEN AND WILL NOT BE REGISTERED UNDER THE SECURITIES ACT
                    OF
                    1933, AS AMENDED (THE “SECURITIES ACT”), AND ANY SECURITIES EXCHANGE ACT IN ANY OTHER COUNTRIES.
                    BESIDES, THE SELLING OF Tap Fantasy NFTS OR Tap Fantasy TOKENS IS NOT CONSIDERED A METHOD FOR FUND
                    RAISING, THOSE WHO OWN Tap Fantasy NFTS OR Tap Fantasy TOKENS DO NOT HAVE ANY RIGHT TOWARD Grape
                    Tech
                    FOR EXAMPLE, THE RIGHT TO CLAIM AN EQUITY OWNERSHIP TO METAP INC, OR THE RIGHT TO CLAIM THE FUTURE
                    CASH-FLOW OF Grape Tech IF Tap Fantasy NFTS AND Tap Fantasy TOKENS ARE DEEMED TO BE, OR BE INTEGRAL
                    TO,
                    A TRANSACTION, CONTRACT OR SCHEME CONSTITUTING A SECURITY, OR THE SECURITIES LAWS OTHERWISE APPLY TO
                    THE
                    Tap Fantasy NFTS AND Tap Fantasy TOKENS, THEN THE OFFERS AND SALES OF SUCH SECURITY HAVE NOT BEEN
                    REGISTERED UNDER ANY COUNTRY'S SECURITIES LAWS AND, THEREFORE, CANNOT BE RESOLD EXCEPT IN COMPLIANCE
                    WITH THE APPLICABLE COUNTRY'S LAWS. YOU UNDERSTANDS THAT Tap Fantasy NFTS AND Tap Fantasy TOKENS MAY
                    BE,
                    A “RESTRICTED SECURITY” UNDER APPLICABLE LAWS AND THAT, PURSUANT TO THESE LAWS, YOU MUST HOLD THESE
                    Tap
                    Fantasy NFTS AND Tap Fantasy TOKENS INDEFINITELY. YOU ACKNOWLEDGES THAT THE COMPANY HAS NO
                    OBLIGATION TO
                    REGISTER OR QUALIFY FOR RESALE THESE Tap Fantasy NFTS AND Tap Fantasy TOKENS. YOU FURTHER
                    ACKNOWLEDGES
                    THAT IF AN EXEMPTION FROM REGISTRATION OR QUALIFICATION IS AVAILABLE, IT MAY BE CONDITIONED ON
                    VARIOUS
                    REQUIREMENTS INCLUDING, BUT NOT LIMITED TO, THE TIME AND MANNER OF SALE, THE HOLDING PERIOD, AND
                    ETC.
                    WHICH ARE OUTSIDE OF THE YOUR AND OUR CONTROL.
                </p>
                <h3>1. INTELLECTUAL PROPERTY RIGHTS</h3>
                <p>In this Term, when you purchase or procure any Tap Fantasy NFTs, you own the Tap Fantasy NFTs, which
                    means
                    that you have the right to trade your Tap Fantasy NFTs, sell them, or give them away (i.e., transfer
                    them),
                    and are fully responsible for all activities and interactions carried out in connection with the Tap
                    Fantasy
                    NFTs. Ownership of the Tap Fantasy NFTs is governed entirely by the Smart Contract and the relevant
                    Blockchain Network.</p>
                <p>You acknowledge and agree that Grape Tech (or our affiliates and licensors, if applicable) owns all
                    intellectual property rights (including but not limited to copyright, trademark right, patent right,
                    and
                    etc.), legal rights, titles and interests in and to all Tap Fantasy NFTs, including but not limited
                    to
                    all
                    arts, designs, drawings, systems, methods, information, computer codes, software, services, website
                    designs,
                    "look and feel", organization, compilation of the contents, codes, data and database, functionality,
                    audio,
                    video, texts, photographs, graphics, and all other elements of the Tap Fantasy NFTs (collectively,
                    the
                    "Materials"). You acknowledge that the Materials are protected by copyright, trade dress, patent,
                    and
                    trademark law, international conventions, other relevant intellectual property and proprietary
                    rights,
                    and
                    applicable laws. All Materials are the copyrighted property of Grape Tech (or our affiliates and
                    licensors,
                    if applicable), and all trademarks, service marks, and trade names associated with the Tap Fantasy
                    or
                    otherwise contained in the Materials are proprietary to Grape Tech (or our affiliates and licensors,
                    if
                    applicable). Except as expressly set forth herein, your access to or use of the Site, the App, or
                    the
                    Smart
                    Contracts does not grant you ownership of or any other rights with respect to any contents, codes,
                    data,
                    or
                    other Materials. We reserve all rights in and to the Materials that are not expressly granted to you
                    in
                    these Terms.</p>
                <p>For the sake of clarity, you understand and agree: (a) that any intellectual property rights
                    (including
                    but
                    not limited to copyright, trademark right, patent right, and etc.) to any Tap Fantasy NFT you own
                    shall
                    be
                    vested in Grape Tech (or our affiliates and licensors, if applicable) at all times; (b) that your
                    purchase
                    or procurement of a Tap Fantasy NFT does not give you any rights or licenses in or to the Materials
                    (including, without limitation, our copyright in and to the associated any art, design, and drawings
                    that
                    may be associated with a Tap Fantasy NFT that you own) other than those expressly contained in these
                    Terms;
                    (c) that you do not have the right, except as otherwise set forth in these Terms, to reproduce,
                    distribute,
                    display, use commercially, or otherwise commercialize any elements of the Materials (including,
                    without
                    limitation, any art, design, and drawings that may be associated with a Tap Fantasy NFT that you
                    own)
                    without our prior written consent in each case, which consent we may withhold in our sole and
                    absolute
                    discretion; and (d) that you will not apply for, register, or otherwise use or attempt to use any
                    Tap
                    Fantasy trademarks or service marks, or any confusingly similar marks, anywhere in the world without
                    our
                    prior written consent in each case, which consent we may withhold at our sole and absolute
                    discretion.
                    We grant to you a non-exclusive, world-wide, royalty-free license to use propriety rights in the Tap
                    Fantasy
                    NFTs for the following purposes:</p>
                <ul>
                    <li class="dot">use in Tap Fantasy as Creatures or Assets;</li>
                    <li class="dot">for your own personal, non-commercial, and reasonable use.</li>
                </ul>
                <p>
                    All intellectual rights of Tap Fantasy NFTs are owned by us pursuant to Article 1 of this Term. To
                    the
                    extent that any such intellectual property rights are vested in you under any applicable laws of the
                    country
                    or jurisdiction where you reside or access to or use of Tap Fantasy, you agree to do any and all
                    such
                    acts
                    and execute any and all such documents as we may reasonably request in order to grant us a
                    worldwide,
                    exclusive, non-transferable, royalty-free license to us or assign such intellectual property rights
                    to
                    us
                    without any charge. In this situation, you represent and warrant that you have, or have obtained,
                    all
                    rights, licenses, consents, permissions, power and/or authority necessary to grant or assign the
                    rights
                    granted or assigned herein. You represent and warrant that such Tap Fantasy NFT is not subject to
                    copyright,
                    trademark, trade dress, patent, trade secret, publicity rights, other intellectual property rights,
                    or
                    any
                    other legal rights unless you have necessary permission or are otherwise legally entitled to grant
                    us
                    the
                    license or assign the intellectual rights to us described above, and that the Tap Fantasy NFT does
                    not
                    violate any applicable laws.
                </p>
                <h3>2. INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS</h3>
                <p>Tap Fantasy will send takedown notices and/or other intellectual property infringement claims and
                    will
                    remove the Tap Fantasy NFT which Infringes or violates the intellectual property rights or any other
                    legal
                    rights of others from the Tap Fantasy, or terminate a user's access to the Site, the App and the
                    Smart
                    Contracts.</p>
                <p>If you believe that your copyright, trademark right, trade dress, patent, trade secret, publicity
                    right,
                    any
                    other intellectual property rights, or any other legal rights have been infringed by any Tap Fantasy
                    NFT,
                    please inform us via the Email address we provide on the Tap Fantasy website. For us to process your
                    infringement claim, you must be the rightsholder or someone authorized to act on behalf of the
                    rightsholder.</p>
                <p>We suggest the following information could be included in the Email that you send to us:</p>
                <ul>
                    <li class="dot">Identification of the copyrighted work(s), trademark, trade dress, publicity rights,
                        patent, trade
                        secret, any other intellectual property rights, or any other legal rights that you claim is
                        being
                        infringed;</li>
                    <li class="dot">Identification of the allegedly infringing Tap Fantasy NFT that is requested to be
                        removed;</li>
                    <li class="dot">Your contact information – at a minimum, your full legal name (not pseudonym) and
                        email
                        address;</li>
                    <li class="dot">A declaration that contains all of the following:</li>
                    <li class="dot">A statement that you have a good faith belief that use of the Tap Fantasy NFT in the
                        manner
                        complained of is not authorized by the intellectual property rights owner, its agent, or the
                        law;
                    </li>
                    <li class="dot">A statement that the information in the notice is accurate; and A statement under
                        penalty of perjury that you are authorized to act on behalf of the intellectual
                        property owner of the intellectual property that is allegedly being infringed.</li>
                    <li class="dot">Your physical or electronic signature (of your full legal name).</li>
                </ul>
                <p>
                    Please note that we will forward your Email regarding intellectual property infringement, including
                    your
                    contact information, to the party who will have their Tap Fantasy NFT removed from Tap Fantasy so
                    that
                    they can understand why it is no longer available on Tap Fantasy and can also contact you to resolve
                    any
                    dispute.e use the following cookies:
                </p>
                <h3>3. USER REPRESENTATIONS</h3>
                <p>By using the Site, the App and the Smart Contracts, you represent and warrant that: </p>
                <ul>
                    <li class="dot">all registration information you submit will be true, accurate, current, and
                        complete;
                    </li>
                    <li class="dot">you will maintain the accuracy of such information and promptly update such
                        registration
                        information as necessary;</li>
                    <li class="dot">you are a majority with the legal capacity and you agree to comply with these Terms
                        of
                        Use;</li>
                    <li class="dot">you are not a minor in the jurisdiction in which you reside unless otherwise you
                        have
                        your
                        parent or guardian’s approval and oversight;</li>
                    <li class="dot">you will not access the Site, the App and the Smart Contracts through automated and
                        non-human
                        means, whether through a bot, script or otherwise.</li>
                    <li class="dot">you will not use the Site, the App, and the Smart Contracts for any illegal and
                        unauthorized
                        purpose;</li>
                    <li class="dot">your use of the Site, the App, and the Smart Contracts will not violate any
                        applicable
                        law or
                        regulation. If you provide any information that is untrue, inaccurate, not current, or
                        incomplete,
                        we have the right to suspend or terminate your account and refuse any and all current or future
                        use
                        of the Site, the App, and the Smart Contracts (or any portion thereof).</li>
                    <li class="dot">you (a human being) will only use one in-game account to earn tokens.</li>
                    <li class="dot">you, as an Tap Fantasy NFTs owner, are responsible for the actions of any "scholars"
                        (other
                        Users using any Tap Fantasy NFTs you own such as a cosplay equipment), and that their actions
                        can
                        have consequences for any connected accounts that you own.</li>
                    <li class="dot">you have not been included in any trade embargoes or economic sanctions list (such
                        as
                        united
                        nations security council sanctions list), the list of specially designated nationals maintained
                        by
                        OFAC (the office of foreign assets control of the U.S. department of the treasury), the denied
                        persons or entity list of the U.S. department of commerce, the economic sanctions and target
                        list of
                        the Japan ministry of finance, and the persons or entity announced on the website of ministry of
                        Japan economy, trade and industry.</li>
                    <li class="dot">Tap Fantasy, we, or any third-party providers we work with, may store the IP address
                        you
                        use to
                        access the site.</li>
                </ul>
                <p>Grape Tech reserves the right to choose which markets and jurisdictions to conduct its business and
                    may
                    restrict or refuse, at its sole discretion, the provision of Tap Fantasy services in certain
                    countries
                    or regions.</p>
                <h3>4. USER REGISTRATION</h3>
                <p>
                    You may be required to register with the Site, the App, and the Smart Contracts. You agree to keep
                    your
                    password confidential and will be responsible for all use of your account and password. We reserve
                    the
                    right
                    to remove, reclaim or change a username you select if we determine, in our sole discretion, that
                    such
                    username is inappropriate, obscene, or otherwise objectionable.
                </p>
                <h3>5. PROHIBITED ACTIVITIES</h3>
                <p>You may not access or use the Site, the App, and the Smart Contracts for any purpose other than that
                    for
                    which we make the Site, the App, and the Smart Contracts available. The Site, the App, and the Smart
                    Contracts may not be used in connection with any commercial endeavors except if agreed to in a
                    binding
                    legal contract with Grape Tech The following activities are prohibited, you agree and warrant that
                    you
                    will not breach any of them:</p>
                <ul>
                    <li class="dot">Systematically retrieve data or other content from the Site, the App, and the Smart
                        Contracts to create or compile, directly or indirectly, a collection, compilation, database, or
                        directory without prior written permission from us.</li>
                    <li class="dot">Make any unauthorized use of the Site, the App and the Smart Contracts, including
                        collecting usernames and/or email addresses of Users by electronic or other means for the
                        purpose of
                        sending unsolicited email, or creating User accounts by automated means or under false
                        pretenses.
                    </li>
                    <li class="dot">Use a buying agent or purchasing agent to make purchases on the Site, the App, and
                        the
                        Smart Contracts.</li>
                    <li class="dot">Use the Site, the App and the Smart Contracts to advertise or offer to sell goods
                        and/or
                        services.</li>
                    <li class="dot">Circumvent, disable, or otherwise interfere with security-related features of the
                        Site,
                        the App and the Smart Contracts, including features that prevent or restrict the use or copying
                        of
                        any Content or enforce limitations on the use of the Site, the App and the Smart Contracts
                        and/or
                        the Content contained therein.</li>
                    <li class="dot">Engage in unauthorized framing of or linking to the Site, the App, and the Smart
                        Contracts.</li>
                    <li class="dot">Trick, defraud, or mislead us and other Users, especially in any attempt to learn
                        sensitive account information such as User passwords.</li>
                    <li class="dot">Make improper use of our support services or submit false reports of abuse or
                        misconduct.</li>
                    <li class="dot">Engage in any automated use of the system, such as using scripts to send comments or
                        messages, or using any data mining, robots, or similar data gathering and extraction tools,
                        except
                        as mentioned in 2.5)</li>
                    <li class="dot">Interfere with, disrupt, or create an undue burden on the Site, the App, and the
                        Smart
                        Contracts or the networks or services connected to the Site.</li>
                    <li class="dot">Attempt to impersonate another User or person or use the username of another User.
                    </li>
                    <li class="dot">Sell or otherwise transfer your profile.</li>
                    <li class="dot">Use any information obtained from the Site, the App, and the Smart Contracts in
                        order to
                        harass, abuse, or harm another person.</li>
                    <li class="dot">Use the Site, the App, and the Smart Contracts as part of any effort to compete with
                        us
                        or otherwise use the Site, the App, and the Smart Contracts and/or the Content for any
                        revenue-generating endeavor or commercial enterprise.</li>
                    <li class="dot">Decipher, decompile, disassemble, or reverse engineer any of the software comprising
                        or
                        in any way making up a part of the Site, the App, and the Smart Contracts.</li>
                    <li class="dot">Attempt to bypass any measures of the Site designed to prevent or restrict access to
                        the
                        Site, or any portion of the Site, the App, and the Smart Contracts.</li>
                    <li class="dot">Harass, intimidate, or threaten any of our employees or agents engaged in providing
                        any
                        portion of the Site, the App, and the Smart Contracts to you.</li>
                    <li class="dot">Delete the copyright or other proprietary rights notice from any Content.</li>
                    <li class="dot">Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML,
                        JavaScript, or other code.</li>
                    <li class="dot">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
                        other material, including excessive use of capital letters and spamming (continuous posting of
                        repetitive text), that interferes with any Users’ uninterrupted use and enjoyment of the Site or
                        modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation,
                        or
                        maintenance of the Site, the App and the Smart Contracts.</li>
                    <li class="dot">Upload or transmit (or attempt to upload or to transmit) any material that acts as a
                        passive or active information collection or transmission mechanism, including without
                        limitation,
                        clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar
                        devices
                        (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                    <li class="dot">Except as may be the result of standard search engines or Internet browser usage,
                        use,
                        launch, develop, or distribute any automated system, including without limitation, any spider,
                        robot, cheat utility, scraper, or offline reader that accesses the Site, the App and the Smart
                        Contracts, or using or launching any unauthorized script or other software.</li>
                    <li class="dot">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site, the App,
                        and
                        the Smart Contracts.</li>
                    <li class="dot">Use the Site, the App, and the Smart Contracts in a manner inconsistent with any
                        applicable laws or regulations.</li>
                    <li class="dot">Engage in any practice that aims to manipulate the outcome of any Tap Fantasy. All
                        Users
                        should play to the best of their abilities and any sort of cheating, win-trading, or colluding
                        between Users is strictly prohibited.</li>
                    <li class="dot">Using multiple accounts to earn tokens is strictly prohibited.</li>
                </ul>
                <h3>6. FEE AND PAYMENT</h3>
                <p>
                    Any purchases (e.g., buying Tap Fantasy NFTs) from the site or marketplace will be done through
                    smart
                    contracts on a blockchain using a wallet like MetaMask. Any financial transactions that you engage
                    in
                    will be conducted solely through the Blockchain via a wallet such as MetaMask. We will have no
                    insight
                    into or control over these payments or transactions, nor do we have the ability to reverse any
                    transactions. With that in mind, we will have no liability to you or to any third party for any
                    claims
                    or damages that may arise as a result of any transactions that you engage in via the Site and/or the
                    App
                    or using the Smart Contracts, or any other transactions that you conduct via the BSC or SOLANA
                    network.
                </p>
                <p>
                    BSC or SOLANA requires the payment of a transaction fee (a “Gas Fee”) for every transaction that
                    occurs
                    on the BSC or SOLANA network. The Gas Fee funds the network of computers that run the decentralized
                    BSC
                    or SOLANA network. This means that you will need to pay a Gas Fee for each transaction that occurs
                    via
                    the App.
                </p>
                <p>
                    In addition to the Gas Fee, each time you utilize a Smart Contract to conduct a transaction with
                    another
                    User via the App, you authorize us to collect a percentage of the total value as commission on that
                    transaction (each, a “Commission”). The exact percentage will be determined on a case-by-case basis.
                    You
                    acknowledge and agree that the Commission will be transferred directly to us through the BSC or
                    SOLANA
                    network as a part of the transaction.
                </p>
                <p>
                    As between us, you will be solely responsible to pay any and all sales, use, value-added and other
                    taxes,
                    duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any
                    governmental authority (collectively, “Taxes”) associated with your use of the App (including,
                    without
                    limitation, any Taxes that may become payable as the result of your ownership, transfer, purchase or
                    trade of any of your Tap Fantasy NFT). Except for income taxes levied on us, you: (i) will pay or
                    reimburse us for all national, federal, state, local, or other taxes and assessments of any
                    jurisdiction, including value-added taxes and taxes as required by international tax treaties,
                    customs
                    or other import or export taxes, and amounts levied in lieu thereof based on charges set, services
                    performed or payments made hereunder, as are now or hereafter may be imposed under the authority of
                    any
                    national, state, local or any other taxing jurisdiction; and (ii) shall not be entitled to deduct
                    the
                    amount of any such taxes, duties or assessments from payments made to us pursuant to these Terms.
                </p>
                <h3>7. SUBMISSION</h3>
                <p>
                    You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other
                    information regarding the Site, the App and the Smart Contracts (“Submissions") provided by you to
                    us
                    are non-confidential and should become our sole property. We should own exclusive rights, including
                    all
                    intellectual property rights, and should be entitled to the unrestricted use and dissemination of
                    these
                    Submissions to any lawful purpose, commercial, or otherwise, without acknowledgment or compensation
                    for
                    you. You hereby waive any moral rights to any such Submissions, and you hereby warrant that any such
                    Submissions are original with you or that you have the right to submit such Submissions. You agree
                    there
                    should be no recourse against us for any alleged or actual infringement or misappropriation of any
                    proprietary right in your Submissions.
                </p>
                <h3>8. THIRD-PARTY WEBSITE AND CONTENT</h3>
                <p>
                    The Site and/or the App (or you may be sent via the Site and/or the App) links to other websites
                    (“Third-Party Websites") as well as articles, photograph, text, graphics, pictures, designs, music,
                    sound, video, information, applications, software, and other content or items belonging to or
                    originating from third parties (“Third-Party Content"). Such Third-Party Websites and Third-Party
                    Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness
                    by
                    us, and we are not responsible for any Third-Party Websites accessed through the Site and/or the
                    App, or
                    any Third-Party Content posted on, available through, or installed from the Site and/or the App,
                    including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other
                    policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of,
                    linking
                    to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content
                    does
                    not imply approval or endorsement thereof by us. If you decide to leave the Site and/or the App and
                    access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own
                    risk
                    and you should be aware of these Terms of Use no longer govern. You should review the applicable
                    terms
                    and policies, including privacy and data gathering practices, of any website to which you navigate
                    from
                    the Site and/or the App or relating to any applications you use or install from the Site and/or the
                    App.
                    Any purchase you make through Third-Party Websites will be through other websites and from other
                    companies, and we take no responsibility whatsoever in relation to such purchases which are
                    exclusively
                    between you and the applicable third party. You agree and acknowledge that we do not endorse the
                    products and services offered on Third-Party Websites and you should hold us harmless from any harm
                    caused by your purchase of such products and services. Additionally, you should hold us harmless
                    from
                    any losses sustained by you or harm caused to you relating to resulting in any way from any
                    Third-Party
                    Content or any contact with Third-Party Websites.
                </p>
                <h3>9. ADVERTISER</h3>
                <p>
                    We allow advertisers to display their advertisements and other information in certain areas of the
                    Site
                    and the App such as sidebar advertisements or banner advertisements. If you are an advertiser, you
                    should take full responsibility for any advertisements you place on the Site and/or the App, and any
                    services provided on the Site and/or the App, or products sold through those advertisements.
                    Further, as
                    an advertiser, you warrant and represent that you possess all rights and authority to place
                    advertisements on the Site and/or the App, including, but not limited to, intellectual property
                    rights,
                    publicity rights, and contractual rights. We simply provide the space to place such advertisements,
                    and
                    we have no other relationship with advertisers.
                </p>
                <h3>10. TERMINATION</h3>
                <p>
                    These Terms of Use remain in full force and effect while you use the Site, the App and the Smart
                    Contracts. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN
                    OUR
                    SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE, THE APP AND THE
                    SMART CONTRACT (INCLUDING BLOCKING CERTAIN IP ADDRESSES) TO ANY PERSON FOR ANY REASON OR FOR NO
                    REASON,
                    INCLUDING WITHOUT LIMITATION FOR ANY ORDER OR DECREE MADE BY ANY COURT OR CONPETENT AUTHORITY, OR
                    BREACH
                    OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE
                    LAW OR
                    REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE, THE APP AND THE SMART CONTRACT
                    OR
                    DELETE YOUR ACCOUNT WITHOUT WARNING, IN OUR SOLE DISCRETION.
                </p>
                <p>
                    If we terminate or suspend your account for any reason, you are prohibited from registering and
                    creating
                    a new account under your name, a fake or a borrowed name, or the name of any third party, even if
                    you
                    may be acting on behalf of the third party. In addition to terminating and suspending your account,
                    we
                    reserve the right to take appropriate legal action, including without limitation pursuing civil,
                    criminal, and injunctive redress.
                </p>
                <h3>11. GOVERNING LAW</h3>
                <p>
                    This Terms of Use and your use of the Site, the App, and the Smart Contracts are governed by and
                    constructed in accordance with the laws of Singapore applicable to agreements made and to be
                    entirely
                    performed in Singapore, without regard to its conflicts of law principles.
                </p>
                <h3>12. DISPUTE RESOLUTION</h3>
                <h4>12.1 Informal negotiations</h4>
                <p>
                    To expedite resolution and control the cost of any dispute, controversy, or claim related to these
                    Terms
                    of Use (each a “Dispute" and collectively, the “Disputes") brought by either you or us
                    (individually, a
                    “Party" and collectively, the “Parties"), the Parties agree to first attempt to negotiate any
                    Dispute
                    (except those Disputes expressly provided below) informally for at least thirty (30) days before
                    initiating the arbitration. Such Informal negotiations commence upon written notice from one Party
                    to
                    the other Party.
                </p>
                <h4>12.2 Binding Arbitration</h4>
                <p>
                    If a Party is unable to resolve a Dispute through informal negotiations, the Disputes (except those
                    Disputes expressly excluded below) will be finally and exclusively resolved by binding arbitration.
                    All
                    Disputes arising out of or in connection with this Term shall be finally settled by arbitration in
                    accordance with the Commercial Arbitration Rules of The Singapore International Arbitration Centre
                    (SIAC). The place of the arbitration shall be Singapore.
                </p>
                <p>
                    If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be
                    commenced
                    or
                    prosecuted in the courts located in Singapore, and the Parties hereby consent to and waive all
                    defenses
                    of lack of personal jurisdiction, and forum non-conveniens with respect to venue and jurisdiction in
                    such courts.
                </p>
                <p>
                    In no event shall any Dispute brought by either Party related in any way to the Site, the App and
                    the
                    Smart Contracts be commenced more than one (1) year after the cause of the action arose. If this
                    provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any
                    Dispute falling within that portion of this provision found to be illegal or unenforceable and such
                    Dispute shall be decided by a court of competent jurisdiction within the courts listed or
                    jurisdiction
                    above, and the Parties agree to submit to the personal jurisdiction of that court.
                </p>
                <h4>12.3 Exceptions to the Informal Negotiations and Arbitration</h4>
                <p>
                    The Parties agree that the following Disputes are not subject to the above provision concerning
                    informal
                    negotiations and binding arbitration: (a) any Dispute seeking to enforce or protect, or concerning
                    the
                    validity of, and of the intellectual property rights of a Party, (b) any Dispute related to, or
                    arising
                    from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for
                    injunctive relief. If this provision is found to be illegal and unenforceable, then neither Party
                    will
                    elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or
                    unenforceable, and such Dispute shall be decided by a court of competent jurisdiction within the
                    courts
                    listed or jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that
                    court.
                </p>
                <h3>13. DISCLAIMERS</h3>
                <p>
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE, THE APP AND THE SMART
                    CONTRACTS ARE AT YOUR SOLE RISK, AND THAT THE SITE, THE APP AND THE SMART CONTRACTS ARE PROVIDED "AS
                    IS"
                    AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                    PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS MAKE NO
                    EXPRESS
                    WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE, THE APP AND THE SMART
                    CONTRACTS AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY
                    EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF
                    THE
                    FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT:
                    (I)
                    YOUR ACCESS TO OR USE OF THE SITE, THE APP AND THE SMART CONTRACTS WILL MEET YOUR REQUIREMENTS, (II)
                    YOUR ACCESS TO OR USE OF THE SITE, THE APP AND THE SMART CONTRACTS WILL BE UNINTERRUPTED, TIMELY,
                    SECURE
                    OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE, THE APP AND THE SMART CONTRACTS WILL
                    BE
                    ACCURATE, (III) THE SITE, THE APP AND THE SMART CONTRACTS OR ANY CONTENT, SERVICES, OR FEATURES MADE
                    AVAILABLE ON OR THROUGH THE SITE, THE APP AND THE SMART CONTRACTS ARE FREE OF VIRUSES OR OTHER
                    HARMFUL
                    COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE, THE APP AND THE SMART
                    CONTRACTS WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN
                    CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                </p>
                <p>
                    YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET
                    AND
                    AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR
                    GROSS NEGLIGENCE.
                </p>
                <p>
                    WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF
                    THE
                    BSC
                    NETWORK, SOLANA NETWORK, AND/OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO ANY
                    LOSSES,
                    DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUED
                    SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES;
                    (D)
                    UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED TO THE USE OF VIRUSES,
                    PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE, THE APP, THE SMART CONTRACT, BSC
                    NETWORK, SOLANA NETWORK, AND/OR THE METAMASK ELECTRONIC WALLET.
                </p>
                <p>
                    Tap Fantasy NFTS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD
                    MAINTAINED ON THE BSC OR SOLANA NETWORK. ALL SMART CONTRACTS ARE CONDUCTED AND OCCUR ON THE
                    DECENTRALIZED LEDGER WITHIN THE BSC OR SOLANA NETWORK. Grape Tech HAS NO CONTROL OVER AND MAKES NO
                    GUARANTEES OR PROMISES WITH RESPECT TO SMART CONTRACTS. WE ARE NOT RESPONSIBLE FOR LOSSES DUE TO
                    BLOCKCHAINS OR ANY OTHER FEATURES OF THE BSC NETWORK, SOLANA NETWORK, AND/OR THE METAMASK ELECTRONIC
                    WALLET. INCLUDING BUT NOT LIMITED TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT
                    ALL)
                    OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE BSC OR SOLANA NETWORK, INCLUDING FORKS, TECHNICAL
                    NODE
                    ISSUES, OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
                </p>
                <h3>14. LIMITATION OF LIABILITY</h3>
                <p>
                    YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO
                    YOU
                    OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
                    WHICH
                    YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION,
                    ANY
                    LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION,
                    LOSS
                    OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF
                    WE
                    HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>
                    YOU AGREE AND ACKNOWLEDGE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING
                    OUT
                    OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY
                    PORTION OF THE SITE, THE APP AND THE SMART CONTRACTS, WHETHER IN CONTRACT, TORT, STRICT LIABILITY,
                    OR
                    ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER
                    THESE
                    TERMS IN THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) ONE HUNDRED (100)
                    US
                    DOLLAR.
                </p>
                <p>
                    YOU AGREE AND ACKNOWLEDGE THAT WE HAVE MADE THE SITE, THE APP AND THE SMART CONTRACTS AVAILABLE TO
                    YOU
                    AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY
                    SET
                    FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES AND FORM AN
                    ESSENTIAL BASIS OF THE BARGAIN BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE SITE, THE APP AND THE
                    SMART CONTRACTS TO YOU WITHOUT THESE LIMITATIONS.
                </p>
                <p>
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                    AND
                    SOME JURISDICTIONS ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR PERSONAL INJURY FROM
                    CONSUMER
                    PRODUCTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
                </p>
                <h3>15. ASSUMPTION OF RISK</h3>
                <p>You accept and acknowledge each of the following:</p>
                <p>
                    A. The prices of blockchain assets are extremely volatile. Fluctuations in the price of other
                    digital
                    assets could materially and adversely affect the value of your Tap Fantasy NFTs which may also be
                    subject to significant price volatility. We cannot guarantee that any purchasers of Tap Fantasy NFTs
                    will not lose money.
                </p>
                <p>
                    B. You are solely responsible for determining what, if any, taxes apply to your Tap Fantasy
                    NFT-related
                    transactions. We are not responsible for determining the taxes that apply to your transactions on
                    the
                    App, the Site, or the Smart Contracts.
                </p>
                <p>
                    C. The App does not store, send, or receive any Tap Fantasy NFTs. This is because Tap Fantasy NFTs
                    exist
                    only by virtue of the ownership record maintained on the App’s supporting blockchain on the BSC or
                    SOLANA network. Any transfer of Tap Fantasy NFTs occurs only on the BSC or SOLANA network.
                </p>
                <p>
                    D. There are risks associated with using an Internet-based currency, including, but not limited to,
                    the
                    risk of hardware, software and Internet connections, the risk of malicious software introduction,
                    and
                    the risk that third parties may obtain unauthorized access to information stored within your wallet.
                    You
                    accept and acknowledge that we will not be responsible for any communication failures, disruptions,
                    errors, distortions or delays you may experience when using the BSC or SOLANA network, however
                    caused.
                </p>
                <p>
                    E. A lack of use or public interest in the creation and development of distributed ecosystems could
                    negatively impact the development of the ecosystem of Tap Fantasy NFTs, and therefore the potential
                    utility or value of Tap Fantasy NFTs.
                </p>
                <p>
                    F. The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is
                    uncertain,
                    and new regulations or policies may materially adversely affect the development of the Tap Fantasy
                    ecosystem, and therefore the potential utility or value of Tap Fantasy NFTs.
                </p>
                <p>G. Upgrades to the BSC or SOLANA network may have unintended, adverse effects on all Tap Fantasy
                    NFTs.
                </p>
                <h3>16. INDEMNIFICATION</h3>
                <p>
                    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and
                    all of
                    our respective officers, agents, partners, and employees, from and against any loss, damage,
                    liability,
                    claim, or demand, including reasonable attorneys' fees and expenses, made by third party due to or
                    arising out of: (1) use of the Site, the App, and/or the Smart Contract (2) breach of these Terms of
                    Use, (3) any breach of your representations and warranties set forth in these Terms of Use, (4) your
                    violation of the rights of a third party, including but not limited to intellectual property rights,
                    or
                    (5) any overt harmful act toward any other use of the Site, the App and the Smart Contracts with
                    whom
                    you connected via the Site, the App and the Smart Contracts. Notwithstanding the foregoing, we
                    reserve
                    the right, at your expense, to assume the exclusive defense and control of any matter for which you
                    are
                    required to indemnify us, and you agree to cooperate, at your expense, with our defense of such
                    claims.
                    We will use reasonable efforts to notify you of any such claim, action or proceeding which is
                    subject to
                    this indemnification upon becoming aware of it.
                </p>
                <h3>17. USER DATA AND PRIVATE POLICY</h3>
                <p>
                    You acknowledge and agree that we will maintain certain data that you transmit to the Site, the App
                    and
                    the Smart Contracts for the purpose of managing the performance of the Site, the App and the Smart
                    Contracts, as well as data relating to your use of the Site, the App and the Smart Contracts.
                    Although
                    we perform regular routine backups of data, you are solely responsible for all data that you
                    transmit or
                    that release to any activity you have undertaken using the Site, the App and the Smart Contracts.
                    You
                    agree that we shall have no liability to you for any loss or corruption of any such data, and you
                    hereby
                    waive any right of action against us arising from any such loss or corruption of such data.
                </p>
                <p>
                    Please refer to our Privacy Policy for information about how we collect, use, and share personal
                    data
                    about you. By submitting personal data through our Service, you agree to the terms of our Privacy
                    Policy
                    and you expressly consent to the collection, use, and disclosure of your personal data in accordance
                    with the Privacy Policy.
                </p>
                <h3>18. MISCELLANEOUS</h3>
                <p>
                    These Terms of Use and any policies or operating rules posted by us on the Site, the App and the
                    Smart
                    Contracts, or in respect to the Site, the App and the Smart Contracts constitute the entire
                    agreement
                    and understanding between you and us. Our failure to exercise or enforce any right or provision of
                    these
                    Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to
                    the
                    fullest extent permissible by law. We may assign any or all of our rights and obligations to others
                    at
                    any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act
                    caused by
                    any cause beyond our reasonable control. If any provision or part of a provision of these Terms of
                    Use
                    is determined to be unlawful, void, and unenforceable, that provision or part of the provision is
                    deemed
                    severable these Terms of Use and does not affect the validity and enforceability of any remaining
                    provisions. There is no joint venture, partnership, employment or agency relationship created
                    between
                    you and us as a result of these Terms of Use or use of the Site. You agree that these Terms of Use
                    will
                    not be construed against us by virtue of having drafted them. You hereby waive any and all defenses
                    you
                    may have based on the electronic form of these Terms of Use and the lack of signing by the parties
                    hereto to execute these Terms of Use.
                </p>
                <p>Last update: 17/10/2022</p>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted, getCurrentInstance } from 'vue'
import { useI18n } from 'vue-i18n'
import LogUtils from '@/utils/LogUtils'
import i18n from "@/i18n"
import TOOL from '../utils/tool'

export default defineComponent({
    name: 'Terms',
    setup() {
        const { t, locale } = useI18n()
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const isMobile = ref(false)
        const langCur = i18n.global.locale.value

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })
        })

        return {
            isMobile,
            langCur
        }
    }
})
</script>