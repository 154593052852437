<template>
    <div class="page-mp" style="position: relative;height: auto;">
        <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
            <modal-approve v-if="modalShowIndex === 0" :tokeninfo="getApproveTokenAndNeedAmount(selectedBuyIndex)"
                @confirm="approve(selectedBuyIndex)" @cancel="closeModal()" />
        </el-dialog>
        <div v-if="!isLoading" class="marketplace-index d-flex" :class="isMobile ? '' : 'height-header-padding'">
            <template v-if="!isMobile">
                <div class="right-padding d-flex flex-column">
                    <div class="list-title">Beginner's Section</div>
                    <div class="content" :class="listForNewUser.length === 0 ? 'empty' : ''">
                        <div v-if="listForNewUser.length > 0" class="nft-item-list">
                            <div v-for="(nft, itemIndex) of listForNewUser" :key="'index_' + itemIndex"
                                class="d-flex item-fornewuser justify-content-center align-items-center">
                                <div :class="isChangeForNewUser ? 'fadein' : 'fadeout'"
                                    class="nft-item  d-flex align-items-center justify-content-center">
                                    <img @click="go2Info(itemIndex)" :src="nft['preview']" class="nft-item-image" />
                                    <div class="nft-item-attrs">
                                        <div v-if="nft['earth'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_yellow_earth.png" />
                                            <span class="nft-item-attrs-text earth"
                                                :class="nft['earth'] >= 10 ? 'digit2' : ''">{{
                                                    nft['earth']
                                                }}</span>
                                        </div>
                                        <div v-if="nft['water'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_blue_water.png" />
                                            <span class="nft-item-attrs-text water"
                                                :class="nft['water'] >= 10 ? 'digit2' : ''">{{
                                                    nft['water']
                                                }}</span>
                                        </div>
                                        <div v-if="nft['fire'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_red_fire.png" />
                                            <span class="nft-item-attrs-text fire"
                                                :class="nft['fire'] >= 10 ? 'digit2' : ''">{{
                                                    nft['fire']
                                                }}</span>
                                        </div>
                                        <div v-if="nft['wind'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_green_wind.png" />
                                            <span class="nft-item-attrs-text wind"
                                                :class="nft['wind'] >= 10 ? 'digit2' : ''">{{
                                                    nft['wind']
                                                }}</span>
                                        </div>
                                        <div class="nft-item-attr d-flex align-items-center"></div>
                                    </div>
                                    <img class="nft-item-attrs-image-level"
                                        :src="'/static/images/element_level_' + getLowerCase(nft['rarity']) + '.png'" />

                                </div>
                                <div class="item-fornewuser-right">
                                    <span class="line1">#{{ nft.tokenid }} | {{ nft.character }}</span>
                                    <span class="line2">{{ nft.selectRate }}% of {{ nft.character }} choose this
                                        nft</span>
                                    <span class="cardprice">{{ nft['price'] % 1 === 0 ? nft['price']
                                        : nft['price'].toFixed(2)
                                    }} {{ goodsPayTokenList[0].symbol }}</span>
                                    <!-- <template v-if="goodsPayTokenList.length > 0"> -->
                                    <button :disabled="buttonDisabled" @click="buy(itemIndex)"
                                        class="button-buy-fornewuser">
                                        Buy It</button>
                                    <!-- </template> -->
                                </div>
                            </div>
                            <div @click="changeCardsForNewUser" class="change-block">
                                <SVGChange></SVGChange>
                                <span class="text">Change</span>
                            </div>
                            <button :disabled="buttonDisabled" @click="buyBatch" class="buy-all-block">
                                <SVGHand></SVGHand>
                                <span class="text">Buy All</span>
                            </button>
                        </div>
                        <el-empty v-else :description="$t('Common.no-items')" />
                    </div>
                    <div ref="swiperPC" class="swiper-container">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="list-title-block">
                                <span class="title-inner-text">Popular Collections</span>
                                <el-tooltip content="The percentage of the character's nft skin deposited in the game"
                                    placement="right">
                                    <el-icon color="#FFFFFF">
                                        <InfoFilled />
                                    </el-icon>
                                </el-tooltip>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="swiper-button-prev-pc">
                                    <SVGArrowLeftRightMPRecommend class="arrowleft"></SVGArrowLeftRightMPRecommend>
                                </div>
                                <div class="swiper-button-next-pc">
                                    <SVGArrowLeftRightMPRecommend></SVGArrowLeftRightMPRecommend>
                                </div>
                            </div>
                        </div>
                        <swiper v-if="swiperPCSlidesPerView > 0" :slidesPerView="swiperPCSlidesPerView" :spaceBetween="96"
                            :pagination="false" :navigation="{
                                nextEl: '.swiper-button-next-pc',
                                prevEl: '.swiper-button-prev-pc',
                            }" :modules="modules" class="popular-collection-list">
                            <swiper-slide v-for="(pc, itemIndex) of listPopularCollections"
                                :key="'popular-collection-' + itemIndex" class="item-popular-collection">
                                <div @click="go2MPPage(3, itemIndex)"
                                    class="nft-item-pc d-flex align-items-center justify-content-center">
                                    <img :src="pc.preview" class="nft-item-pc-image" />
                                    <span class="nft-item-chracter">{{ pc.character }}</span>
                                    <!-- <span class="nft-item-level">LV {{ pc.level }}/5</span> -->
                                    <img class="nft-item-rarity-img"
                                        :src="'/static/images/element_level_' + getLowerCase(pc['rarity']) + '.png'" />
                                    <div class="nft-item-attrs">
                                        <div v-if="pc['earth'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_yellow_earth.png" />
                                            <span class="nft-item-attrs-text earth"
                                                :class="pc['earth'] >= 10 ? 'digit2' : ''">{{
                                                    pc['earth']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['water'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_blue_water.png" />
                                            <span class="nft-item-attrs-text water"
                                                :class="pc['water'] >= 10 ? 'digit2' : ''">{{
                                                    pc['water']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['fire'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_red_fire.png" />
                                            <span class="nft-item-attrs-text fire"
                                                :class="pc['fire'] >= 10 ? 'digit2' : ''">{{
                                                    pc['fire']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['wind'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_green_wind.png" />
                                            <span class="nft-item-attrs-text wind"
                                                :class="pc['wind'] >= 10 ? 'digit2' : ''">{{
                                                    pc['wind']
                                                }}</span>
                                        </div>
                                        <div class="nft-item-attr d-flex align-items-center"></div>
                                    </div>
                                </div>
                                <div class="nft-item-price-block">
                                    <span class="nft-item-price">{{ pc['price'] % 1 === 0 ? pc['price']
                                        : pc['price'].toFixed(2)
                                    }} {{ goodsPayTokenList[0].symbol }}</span>

                                    <span
                                        :class="parseFloat(pc.increase) === 0 ? '' : (parseFloat(pc.increase) > 0 ? 'up' : 'down')"
                                        class="nft-item-price-increase">
                                        {{ pc.increase }}%
                                    </span>

                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="hot-list-title-block">
                        <div @click="leaderboardIndex = 0" :class="leaderboardIndex === 0 ? 'active' : ''"
                            class="item-hot-list-title-inner-block">
                            <span class="title-text">Top</span>
                            <div class="line-select"></div>
                        </div>
                        <div @click="leaderboardIndex = 1" :class="leaderboardIndex === 1 ? 'active' : ''"
                            class="item-hot-list-title-inner-block">
                            <span class="title-text">Rarity</span>
                            <div class="line-select"></div>
                        </div>
                        <div @click="leaderboardIndex = 2" :class="leaderboardIndex === 2 ? 'active' : ''"
                            class="item-hot-list-title-inner-block">
                            <span class="title-text">Character</span>
                            <div class="line-select"></div>
                        </div>
                    </div>
                    <template v-if="leaderboardIndex === 0">
                        <div class="hot-list-label-block">
                            <div class="item-label" style="width:20PX">#</div>
                            <div class="item-label" style="width: 200PX;text-align: left;">Collection</div>
                            <div class="item-label" style="width: 52PX">Character</div>
                            <div class="item-label" style="width: 50PX;text-align: center;">Element</div>
                            <div class="item-label" style="width: 36PX">Rarity</div>
                            <div class="item-label" style="width: 76PX">Floor Price</div>
                            <div class="item-label" style="width: 68PX">30D Change</div>
                            <!-- <div class="item-label" style="width: 68PX">Trade Times</div> -->
                            <div class="item-label" style="width: 126PX">Recently 30D</div>
                            <div class="item-label" style="width: 94PX">Action</div>
                        </div>
                        <div class="hot-list-block">
                            <div v-for="(item, itemIndex) of listHotTopGainer" class="item-data-block">
                                <div class="item-data" style="width:20PX">{{ itemIndex + 1 }}</div>
                                <div class="item-data-collection">
                                    <div class="item-data-image-collection-block">
                                        <img :src="item.preview" class="item-data-image-collection" />
                                    </div>
                                    <span class="item-data-collection-name">{{ item.name }}</span>
                                </div>
                                <div class="item-data" style="width: 52PX">{{ item.character }}</div>
                                <div class="item-data-element-block" style="width: 50PX">
                                    <img class="item-data-image-element" :src="getElementImage(itemIndex)" />
                                    <span class="item-data-text-element"
                                        :class="item.element_value > 0 ? item.element : ''">{{ item.element_value
                                        }}</span>
                                </div>
                                <div class="item-data" style="width: 36PX"><img class="item-data-image-rarity"
                                        :src="'/static/images/element_level_' + getLowerCase(item['rarity']) + '.png'" />
                                </div>
                                <div class="item-data" style="width: 76PX">{{ item.price }} {{
                                    goodsPayTokenList[0].symbol
                                }}</div>
                                <div class="item-data" style="width: 68PX"
                                    :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">{{
                                        item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' : '')
                                    }}{{ item['24hchange'] }}%
                                </div>
                                <!-- <div class="item-data" style="width: 68PX">{{ item.tradetimes }}</div> -->
                                <div class="item-data" style="width: 126PX">
                                    <sparkline style="width: 126PX" v-bind:data="item['7dchangedata']" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                        'green' : 'red')">
                                    </sparkline>
                                </div>
                                <div class="item-data" style="width: 94PX"><button @click="go2MPPage(0, itemIndex)"
                                        class="item-button-buy">Buy</button></div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="leaderboardIndex === 1">
                        <div class="hot-list-label-block">
                            <div class="item-label" style="width:20PX">#</div>
                            <div class="item-label" style="width: 36PX">Rarity</div>
                            <div class="item-label" style="width: 76PX">Floor Price</div>
                            <div class="item-label-block" style="width: 98PX">
                                <span class="item-label">30D Change</span>
                                <el-tooltip
                                    content="The difference between the latest trading price of the NFT which has the same element  and the previous price before it.">
                                    <el-icon style="margin-left:4PX;" color="#FFFFFF">
                                        <InfoFilled style="width:14PX;height: 14PX;" />
                                    </el-icon>
                                </el-tooltip>
                            </div>
                            <div class="item-label" style="width: 68PX">Marketplace Listed</div>
                            <div class="item-label" style="width: 68PX">Volume</div>
                            <div class="item-label" style="width: 68PX">Items</div>
                            <div class="item-label" style="width: 126PX">Recently 30D</div>
                            <div class="item-label" style="width: 94PX">Action</div>
                        </div>
                        <div class="hot-list-block">
                            <div v-for="(item, itemIndex) of listHotRarity" class="item-data-block">
                                <div class="item-data" style="width:20PX">{{ itemIndex + 1 }}</div>
                                <div class="item-data" style="width: 36PX"><img class="item-data-image-rarity"
                                        :src="'/static/images/element_level_' + getLowerCase(item['rarity']) + '.png'" />
                                </div>
                                <div class="item-data" style="width: 76PX">{{ item.floorprice }} {{
                                    goodsPayTokenList[0].symbol
                                }}</div>
                                <div class="item-data" style="width: 98PX"
                                    :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">{{
                                        item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' : '')
                                    }}{{ item['24hchange'] }}%
                                </div>
                                <div class="item-data" style="width: 68PX">{{ item.listed }}</div>
                                <div class="item-data" style="width: 68PX">{{ item.volume }}</div>
                                <div class="item-data" style="width: 68PX">{{ item.items }}</div>
                                <div class="item-data" style="width: 126PX">
                                    <sparkline style="width: 126PX" v-bind:data="item['7dchangedata']" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                        'green' : 'red')">
                                    </sparkline>
                                </div>
                                <div class="item-data" style="width: 94PX"><button @click="go2MPPage(1, itemIndex)"
                                        class="item-button-buy">Buy</button></div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="leaderboardIndex === 2">
                        <div class="hot-list-label-block">
                            <div class="item-label" style="width:20PX">#</div>
                            <div class="item-label" style="width: 150PX">Character</div>
                            <div class="item-label" style="width: 76PX">Floor Price</div>
                            <div class="item-label-block" style="width: 98PX">
                                <span class="item-label">30D Change</span>
                                <el-tooltip
                                    content="The difference between the latest trading price of the NFT which has the same rarity and the previous price before it.">
                                    <el-icon style="margin-left:4PX;" color="#FFFFFF">
                                        <InfoFilled style="width:14PX;height: 14PX;" />
                                    </el-icon>
                                </el-tooltip>
                            </div>
                            <div class="item-label" style="width: 68PX">Marketplace Listed</div>
                            <div class="item-label" style="width: 68PX">Volume</div>
                            <div class="item-label" style="width: 68PX">Items</div>
                            <div class="item-label" style="width: 126PX">Recently 30D</div>
                            <div class="item-label" style="width: 94PX">Action</div>
                        </div>
                        <div class="hot-list-block">
                            <div v-for="(item, itemIndex) of listHotCharacter" class="item-data-block">
                                <div class="item-data" style="width:20PX">{{ itemIndex + 1 }}</div>
                                <div class="item-data-character">
                                    <div class="item-data-image-character-block">
                                        <img class="item-data-image-character"
                                            :src="`/static/images/icon-character-${getLowerCase(item.character)}.png`" />
                                    </div>
                                    <span class="item-data-character-name">{{ item.character }}</span>
                                </div>
                                <div class="item-data" style="width: 76PX">{{ item.price }} {{
                                    goodsPayTokenList[0].symbol
                                }}</div>
                                <div class="item-data" style="width: 98PX"
                                    :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">{{
                                        item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' : '')
                                    }}{{ item['24hchange'] }}%
                                </div>
                                <div class="item-data" style="width: 68PX">{{ item.listed }}</div>
                                <div class="item-data" style="width: 68PX">{{ item.volume }}</div>
                                <div class="item-data" style="width: 68PX">{{ item.items }}</div>
                                <div class="item-data" style="width: 126PX">
                                    <sparkline style="width: 126PX" v-bind:data="item['7dchangedata']" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                        'green' : 'red')">
                                    </sparkline>
                                </div>
                                <div class="item-data" style="width: 94PX"><button @click="go2MPPage(2, itemIndex)"
                                        class="item-button-buy">Buy</button></div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="mobile-content height-header-padding">
                    <div class="mobile-content-inner" style="padding-top:30PX;">
                        <div class="list-title-block">
                            <span>Beginner's Section</span>
                            <div @click="changeCardsForNewUser" class="change-block">
                                <SVGChange></SVGChange>
                                <span class="text">Change</span>
                            </div>
                        </div>
                        <div class="d-flex flex-column fornewuser-block">
                            <div v-if="listForNewUser.length > 0" class="nft-item-list-fornewuser">
                                <div @click="go2Info(itemIndex)" v-for="(nft, itemIndex) of listForNewUser"
                                    :key="'index_' + itemIndex"
                                    class="d-flex flex-column justify-content-center align-items-center item-fornewuser">
                                    <div class="nft-item d-flex align-items-center justify-content-center">
                                        <img :src="nft['preview']" class="nft-item-image" />
                                        <div class="nft-item-attrs">
                                            <div v-if="nft['earth'] > 0"
                                                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                <img class="nft-item-attrs-image"
                                                    src="/static/images/element_attr_yellow_earth.png" />
                                                <span class="nft-item-attrs-text earth"
                                                    :class="nft['earth'] >= 10 ? 'digit2' : ''">{{
                                                        nft['earth']
                                                    }}</span>
                                            </div>
                                            <div v-if="nft['water'] > 0"
                                                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                <img class="nft-item-attrs-image"
                                                    src="/static/images/element_attr_blue_water.png" />
                                                <span class="nft-item-attrs-text water"
                                                    :class="nft['water'] >= 10 ? 'digit2' : ''">{{
                                                        nft['water']
                                                    }}</span>
                                            </div>
                                            <div v-if="nft['fire'] > 0"
                                                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                <img class="nft-item-attrs-image"
                                                    src="/static/images/element_attr_red_fire.png" />
                                                <span class="nft-item-attrs-text fire"
                                                    :class="nft['fire'] >= 10 ? 'digit2' : ''">{{
                                                        nft['fire']
                                                    }}</span>
                                            </div>
                                            <div v-if="nft['wind'] > 0"
                                                class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                <img class="nft-item-attrs-image"
                                                    src="/static/images/element_attr_green_wind.png" />
                                                <span class="nft-item-attrs-text wind"
                                                    :class="nft['wind'] >= 10 ? 'digit2' : ''">{{
                                                        nft['wind']
                                                    }}</span>
                                            </div>
                                            <div class="nft-item-attr d-flex align-items-center"></div>
                                        </div>
                                        <img class="nft-item-attrs-image-level"
                                            :src="'/static/images/element_level_' + getLowerCase(nft['rarity']) + '.png'" />
                                    </div>
                                    <div class="item-fornewuser-bottom">
                                        <span class="line1">#{{ nft.tokenid }}</span>
                                        <span class="line1">{{ nft.character }}</span>
                                        <span class="cardprice">{{ nft['price'] % 1 === 0 ? nft['price']
                                            : nft['price'].toFixed(2)
                                        }} {{ goodsPayTokenList[0].symbol }}</span>
                                        <!-- <template v-if="goodsPayTokenList.length > 0"> -->
                                        <button :disabled="buttonDisabled" @click="buy(itemIndex)"
                                            class="button-buy-fornewuser">
                                            Buy It</button>
                                        <!-- </template> -->
                                    </div>
                                </div>
                            </div>
                            <el-empty v-else :description="$t('Common.no-items')" />
                            <button :disabled="buttonDisabled" @click="buyBatch" class="buy-all-block">
                                <SVGHand></SVGHand>
                                <span class="text">Buy All</span>
                            </button>
                        </div>
                        <div class="list-title-block" style="margin-top: 10PX;justify-content: flex-start;">
                            <span class="title-inner-text">Popular Collections</span>
                            <el-tooltip content="The percentage of  the character's nft skin deposited in the game"
                                color="#FFFFFF" placement="top">
                                <el-icon>
                                    <InfoFilled />
                                </el-icon>
                            </el-tooltip>
                        </div>
                        <swiper :slidesPerView="'auto'" :spaceBetween="20" :pagination="false" :modules="modules"
                            class="popular-collection-list-mobile">
                            <swiper-slide v-for="(pc, itemIndex) of listPopularCollections"
                                :key="'popular-collection-' + itemIndex" class="item-popular-collection">
                                <div @click="go2MPPage(3, itemIndex)"
                                    class="nft-item-pc d-flex align-items-center justify-content-center">
                                    <img :src="pc.preview" class="nft-item-pc-image" />
                                    <span class="nft-item-chracter">{{ pc.character }}</span>
                                    <!-- <div class="nft-item-level-mobile">LV {{ pc.level }}/5</div> -->
                                    <img class="nft-item-rarity-img"
                                        :src="'/static/images/element_level_' + getLowerCase(pc['rarity']) + '.png'" />
                                    <div class="nft-item-attrs">
                                        <div v-if="pc['earth'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_yellow_earth.png" />
                                            <span class="nft-item-attrs-text earth"
                                                :class="pc['earth'] >= 10 ? 'digit2' : ''">{{
                                                    pc['earth']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['water'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_blue_water.png" />
                                            <span class="nft-item-attrs-text water"
                                                :class="pc['water'] >= 10 ? 'digit2' : ''">{{
                                                    pc['water']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['fire'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_red_fire.png" />
                                            <span class="nft-item-attrs-text fire"
                                                :class="pc['fire'] >= 10 ? 'digit2' : ''">{{
                                                    pc['fire']
                                                }}</span>
                                        </div>
                                        <div v-if="pc['wind'] > 0"
                                            class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                            <img class="nft-item-attrs-image"
                                                src="/static/images/element_attr_green_wind.png" />
                                            <span class="nft-item-attrs-text wind"
                                                :class="pc['wind'] >= 10 ? 'digit2' : ''">{{
                                                    pc['wind']
                                                }}</span>
                                        </div>
                                        <div class="nft-item-attr d-flex align-items-center"></div>
                                    </div>
                                </div>
                                <div class="nft-item-price-block">
                                    <div class="nft-item-price-mobile">{{ pc['price'] % 1 === 0 ? pc['price']
                                        : pc['price'].toFixed(2)
                                    }} {{ goodsPayTokenList[0].symbol }}</div>

                                    <div :class="parseFloat(pc.increase) === 0 ? '' : (parseFloat(pc.increase) > 0 ? 'up' : 'down')"
                                        class="nft-item-price-increase">
                                        {{ pc.increase }}%
                                    </div>

                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="hot-list-title-block">
                            <div @click="leaderboardIndex = 0" :class="leaderboardIndex === 0 ? 'active' : ''"
                                class="item-hot-list-title-inner-block">
                                <span class="title-text">Top</span>
                                <div class="line-select"></div>
                            </div>
                            <div @click="leaderboardIndex = 1" :class="leaderboardIndex === 1 ? 'active' : ''"
                                class="item-hot-list-title-inner-block">
                                <span class="title-text">Rarity</span>
                                <div class="line-select"></div>
                            </div>
                            <div @click="leaderboardIndex = 2" :class="leaderboardIndex === 2 ? 'active' : ''"
                                class="item-hot-list-title-inner-block">
                                <span class="title-text">Character</span>
                                <div class="line-select"></div>
                            </div>
                        </div>
                        <template v-if="leaderboardIndex === 0">
                            <div class="hot-list-label-block">
                                <div class="item-label" style="width:36PX">#</div>
                                <div class="item-label" style="flex:1;text-align:left">Collection</div>
                                <div class="item-label" style="flex:1;text-align:left">Floor Price</div>
                            </div>
                            <div class="hot-list-block">
                                <div @click="item.expand = !item.expand" v-for="(item, itemIndex) of listHotTopGainer"
                                    class="item-data-block" :class="item.expand ? 'expand' : 'collapse'">
                                    <div class="item-data-row-line1" style="height: 80px;">
                                        <div class="item-data" style="width: 36PX">{{ itemIndex + 1 }}</div>
                                        <div class="item-data-collection">
                                            <div class="item-data-image-collection-block">
                                                <img :src="item.preview" class="item-data-image-collection" />
                                            </div>
                                            <div class="item-data-image-collection-block-right">
                                                <span class="item-data-collection-character">{{ item.character }}</span>
                                                <span class="item-data-collection-name">{{ item.name }}</span>
                                            </div>
                                        </div>
                                        <div class="item-data-price-and-buy">
                                            <div class="item-data" style="flex: 1;text-align: left;">{{ item.price }} {{
                                                goodsPayTokenList[0].symbol
                                            }}</div>
                                            <button @click.stop="go2MPPage(0, itemIndex)"
                                                class="item-button-buy">Buy</button>
                                        </div>
                                    </div>
                                    <div class="item-data-row-list">
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-element-block">
                                                <span class="item-label">Element</span>
                                                <img class="item-data-image-element" :src="getElementImage(itemIndex)" />
                                                <span class="item-data-text-element"
                                                    :class="item.element_value > 0 ? item.element : ''">{{
                                                        item.element_value
                                                    }}</span>
                                            </div>
                                            <div class="item-data-24h-block">
                                                <span class="item-label">30D Change</span>
                                                <div class="item-data-24h-data-percent-block">
                                                    <div class="item-data"
                                                        :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">
                                                        {{
                                                            item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' :
                                                                '')
                                                        }}{{ item['24hchange'] }}%
                                                    </div>
                                                    <svg :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')"
                                                        viewBox="0 0 9 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g transform="translate(-327.000000, -806.000000)"
                                                                fill="currentColor">
                                                                <g transform="translate(327.000000, 806.000000)">
                                                                    <path
                                                                        d="M5.34117848,1.30849985 L8.00951298,5.45924241 C8.30816515,5.92381245 8.17366214,6.5425263 7.70909209,6.84117848 C7.54779476,6.94486962 7.36008612,7 7.1683345,7 L1.8316655,7 C1.27938075,7 0.831665498,6.55228475 0.831665498,6 C0.831665498,5.80824838 0.886795878,5.62053974 0.990487023,5.45924241 L3.65882152,1.30849985 C3.9574737,0.843929807 4.57618755,0.709426795 5.04075759,1.00807897 C5.16126024,1.08554496 5.26371249,1.1879972 5.34117848,1.30849985 Z">
                                                                    </path>
                                                                    <rect x="4" y="6" width="1" height="5" rx="0.5">
                                                                    </rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-rarity-block">
                                                <span class="item-label">Rarity</span>
                                                <img class="item-data-image-rarity"
                                                    :src="'/static/images/element_level_' + getLowerCase(item['rarity']) + '.png'" />
                                            </div>
                                            <!-- <div class="item-data-stat-block">
                                                <span class="item-label">Trade Times</span>
                                                <span class="item-data">{{ item.tradetimes }}</span>
                                            </div> -->
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-sparkline-block">
                                                <span class="item-label">Recently 30D</span>
                                                <sparkline style="width: 126PX;height: 32PX;"
                                                    v-bind:data="item['7dchangedata']" :ismobile="true" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                                        'green' : 'red')">
                                                </sparkline>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                        <template v-else-if="leaderboardIndex === 1">
                            <div class="hot-list-label-block">
                                <div class="item-label" style="width:36PX">#</div>
                                <div class="item-label" style="flex:1;text-align:left">Rarity</div>
                                <div class="item-label" style="flex:1;text-align:left">Floor Price</div>
                            </div>
                            <div class="hot-list-block">
                                <div @click="item.expand = !item.expand" v-for="(item, itemIndex) of listHotRarity"
                                    class="item-data-block" :class="item.expand ? 'expand' : 'collapse'">
                                    <div class="item-data-row-line1" style="height: 80px;">
                                        <div class="item-data" style="width: 36PX">{{ itemIndex + 1 }}</div>
                                        <div class="item-data-rarity-block">
                                            <!-- <span class="item-label">Rarity</span> -->
                                            <img class="item-data-image-rarity" style="margin-left: 0"
                                                :src="'/static/images/element_level_' + getLowerCase(item['rarity']) + '.png'" />
                                        </div>
                                        <div class="item-data-price-and-buy">
                                            <div class="item-data" style="flex: 1;text-align: left;">{{ item.floorprice
                                            }} {{
    goodsPayTokenList[0].symbol
}}</div>
                                            <button @click.stop="go2MPPage(1, itemIndex)"
                                                class="item-button-buy">Buy</button>
                                        </div>
                                    </div>
                                    <div class="item-data-row-list">
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Marketplace Listed</span>
                                                <span class="item-data">{{ item.listed }}</span>
                                            </div>
                                            <div class="item-data-24h-block">
                                                <div class="item-label-block">
                                                    <span class="item-label">30D Change</span>
                                                    <el-tooltip :visible="item.isshowtooltip"
                                                        content="The difference between the latest trading price of the NFT which has the same element  and the previous price before it.">
                                                        <el-icon @click.stop="item.isshowtooltip = !item.isshowtooltip"
                                                            style="margin-left:4PX;" color="#FFFFFF">
                                                            <InfoFilled style="width:14PX;height: 14PX;" />
                                                        </el-icon>
                                                    </el-tooltip>
                                                </div>
                                                <div class="item-data-24h-data-percent-block">
                                                    <div class="item-data"
                                                        :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">
                                                        {{
                                                            item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' :
                                                                '')
                                                        }}{{ item['24hchange'] }}%
                                                    </div>
                                                    <svg :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')"
                                                        viewBox="0 0 9 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g transform="translate(-327.000000, -806.000000)"
                                                                fill="currentColor">
                                                                <g transform="translate(327.000000, 806.000000)">
                                                                    <path
                                                                        d="M5.34117848,1.30849985 L8.00951298,5.45924241 C8.30816515,5.92381245 8.17366214,6.5425263 7.70909209,6.84117848 C7.54779476,6.94486962 7.36008612,7 7.1683345,7 L1.8316655,7 C1.27938075,7 0.831665498,6.55228475 0.831665498,6 C0.831665498,5.80824838 0.886795878,5.62053974 0.990487023,5.45924241 L3.65882152,1.30849985 C3.9574737,0.843929807 4.57618755,0.709426795 5.04075759,1.00807897 C5.16126024,1.08554496 5.26371249,1.1879972 5.34117848,1.30849985 Z">
                                                                    </path>
                                                                    <rect x="4" y="6" width="1" height="5" rx="0.5">
                                                                    </rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Volume</span>
                                                <span class="item-data">{{ item.volume }}</span>
                                            </div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Items</span>
                                                <span class="item-data">{{ item.items }}</span>
                                            </div>
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-sparkline-block">
                                                <span class="item-label">Recently 30D</span>
                                                <sparkline style="width: 126PX;height: 32PX;"
                                                    v-bind:data="item['7dchangedata']" :ismobile="true" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                                        'green' : 'red')">
                                                </sparkline>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                        <template v-else-if="leaderboardIndex === 2">
                            <div class="hot-list-label-block">
                                <div class="item-label" style="width:36PX">#</div>
                                <div class="item-label" style="flex:1;text-align:left">Character</div>
                                <div class="item-label" style="flex:1;text-align:left">Floor Price</div>
                            </div>
                            <div class="hot-list-block">
                                <div @click="item.expand = !item.expand" v-for="(item, itemIndex) of listHotCharacter"
                                    class="item-data-block" :class="item.expand ? 'expand' : 'collapse'">
                                    <div class="item-data-row-line1" style="height: 80px;">
                                        <div class="item-data" style="width: 36PX">{{ itemIndex + 1 }}</div>
                                        <div class="item-data-character">
                                            <div class="item-data-image-character-block">
                                                <img class="item-data-image-character"
                                                    :src="`/static/images/icon-character-${getLowerCase(item.character)}.png`" />
                                            </div>
                                            <span class="item-data-character-name">{{ item.character }}</span>
                                        </div>
                                        <div class="item-data-price-and-buy">
                                            <div class="item-data" style="flex: 1;text-align: left;">{{ item.price }} {{
                                                goodsPayTokenList[0].symbol
                                            }}</div>
                                            <button @click.stop="go2MPPage(1, itemIndex)"
                                                class="item-button-buy">Buy</button>
                                        </div>
                                    </div>
                                    <div class="item-data-row-list">
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Marketplace Listed</span>
                                                <span class="item-data">{{ item.listed }}</span>
                                            </div>
                                            <div class="item-data-24h-block">
                                                <div class="item-label-block">
                                                    <span class="item-label">30D Change</span>
                                                    <el-tooltip :visible="item.isshowtooltip"
                                                        content="The difference between the latest trading price of the NFT which has the same rarity and the previous price before it.">
                                                        <el-icon @click.stop="item.isshowtooltip = !item.isshowtooltip"
                                                            style="margin-left:4PX;" color="#FFFFFF">
                                                            <InfoFilled style="width:14PX;height: 14PX;" />
                                                        </el-icon>
                                                    </el-tooltip>
                                                </div>
                                                <div class="item-data-24h-data-percent-block">
                                                    <div class="item-data"
                                                        :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')">
                                                        {{
                                                            item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? '+' :
                                                                '')
                                                        }}{{ item['24hchange'] }}%
                                                    </div>
                                                    <svg :class="item['24hchange'] === 0 ? '' : (item['24hchange'] > 0 ? 'up' : 'down')"
                                                        viewBox="0 0 9 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <g transform="translate(-327.000000, -806.000000)"
                                                                fill="currentColor">
                                                                <g transform="translate(327.000000, 806.000000)">
                                                                    <path
                                                                        d="M5.34117848,1.30849985 L8.00951298,5.45924241 C8.30816515,5.92381245 8.17366214,6.5425263 7.70909209,6.84117848 C7.54779476,6.94486962 7.36008612,7 7.1683345,7 L1.8316655,7 C1.27938075,7 0.831665498,6.55228475 0.831665498,6 C0.831665498,5.80824838 0.886795878,5.62053974 0.990487023,5.45924241 L3.65882152,1.30849985 C3.9574737,0.843929807 4.57618755,0.709426795 5.04075759,1.00807897 C5.16126024,1.08554496 5.26371249,1.1879972 5.34117848,1.30849985 Z">
                                                                    </path>
                                                                    <rect x="4" y="6" width="1" height="5" rx="0.5">
                                                                    </rect>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Volume</span>
                                                <span class="item-data">{{ item.volume }}</span>
                                            </div>
                                            <div class="item-data-stat-block">
                                                <span class="item-label">Items</span>
                                                <span class="item-data">{{ item.items }}</span>
                                            </div>
                                        </div>
                                        <div class="item-data-row" style="height: 46px;">
                                            <div style="width: 36PX"></div>
                                            <div class="item-data-sparkline-block">
                                                <span class="item-label">Recently 30D</span>
                                                <sparkline style="width: 126PX;height: 32PX;"
                                                    v-bind:data="item['7dchangedata']" :ismobile="true" :color="item['7dchangedata'][item['7dchangedata'].length - 1] === item['7dchangedata'][0] ? '' : (item['7dchangedata'][item['7dchangedata'].length - 1] > item['7dchangedata'][0] ?
                                                        'green' : 'red')">
                                                </sparkline>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>


    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick,
    reactive,
    CSSProperties,
} from 'vue'
import { ElDialog, ElMessageBox, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup } from 'element-plus'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import TOOL from '@/utils/tool'
import * as API from '@/constants/api'
import axios from '@/utils/axios'
import { getWalletProvider } from '@/utils/provider'
import TapDesktop from '@/abis/TapDesktop.json'
import ERC20 from '@/abis/ERC20.json'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import { aggregate as aggregateMarketPlaceRecommend } from '@makerdao/multicall'
import { isDebug } from '@/constants/Config'
import MPHeader from './components/MPHeader.vue'
import SVGArrowLeftRightMPRecommend from '@/components/svgicon/SVGArrowLeftRightMPRecommend.vue'
import SVGChange from '@/components/svgicon/SVGChange.vue'
import SVGHand from '@/components/svgicon/SVGHand.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import Sparkline from "@/components/Sparkline.vue";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

interface ItemForNewUser {
    price: number
    image: string
    preview: string
    character: string
    rarity: string
    typeid: number,
    tokenid: number
    level: number
    earth: number
    fire: number
    water: number
    wind: number
    selectRate: number
    orderid: number
}

interface ItemHotPopularCollection {
    price: number
    image: string
    preview: string
    character: string
    rarity: string
    typeid: number,
    tokenid: number
    level: number
    earth: number
    fire: number
    water: number
    wind: number
    increase: string
    name: string
}

interface ItemHotTopGainer {
    price: number
    image: string
    preview: string
    character: string
    rarity: string
    typeid: number
    tokenid: number
    name: string
    level: number
    "24hchange": number
    tradetimes: number
    "7dchangedata": number[]
    element: string
    element_value: number
    expand: boolean
}

interface ItemHotRarity {
    rarity: string
    floorprice: number
    "24hchange": number
    listed: number
    volume: number
    items: number
    "7dchangedata": number[]
    expand: boolean
    isshowtooltip: boolean
}

interface ItemHotCharacter {
    character: string
    price: number
    "24hchange": number
    listed: number
    volume: number
    items: number
    "7dchangedata": number[]
    expand: boolean
    isshowtooltip: boolean
}

export default defineComponent({
    name: 'MarketPlaceRecommend',
    components: {
        Pagination, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup, MPHeader, Swiper,
        SwiperSlide, SVGArrowLeftRightMPRecommend, Sparkline, SVGChange, SVGHand
    },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        console.log('onSetup...')

        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const router = useRouter()
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const createNotification = <CreateNotification>inject('create-notification')
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber
        const pageChainConfig: any = {}
        const buttonDisabled = ref(true)
        const isLoading = ref(true)
        const isLoadingInit = ref(true)
        const isExpand = ref(false)
        const isMobile = ref(false)
        const leaderboardIndex = ref(0)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const listForNewUser = ref<ItemForNewUser[]>([])
        const listPopularCollections = ref<ItemHotPopularCollection[]>([])
        const swiperPC: any = ref(null)
        const swiperPCSlidesPerView = ref(0)
        const isChangeForNewUser = ref(true)

        const mobileScrollTop = ref(0)

        const selectedBuyIndex = ref(-1)
        let chainId = 0

        let marketAPIFilter: {
            filterchar: any[]
            filterrarity: any[]
            filterattr: any[]
            filtersearch: any[]
            ordertype: any[]
            paytype: any[]
            pt0price: any[]
            pt1price: any[]
            chainid: 0,
        } | null = null

        interface goodsPayToken {
            goodsid: number
            decimals: number,
            address: string,
            symbol: string,
            priceRange: { key: string; value: any; }[],
            balance: string,
            approve: number
        }

        let forNewUserAPIData: {
            list: ItemForNewUser[]
            desktop: ''
            market: ''
        } | null = null

        const goodsPayTokenList: Ref<goodsPayToken[]> = ref([])

        const listHotTopGainer = ref<ItemHotTopGainer[]>([])
        const listHotRarity = ref<ItemHotRarity[]>([])
        const listHotCharacter = ref<ItemHotCharacter[]>([])

        const localStorageMPConfig = "mp_data_select_setting_buynft"

        // let listAPIHotTopGainer: ItemHotTopGainer[] = []
        // let listAPIHotRarity: ItemHotRarity[] = []
        // let listAPIHotCharacter: ItemHotCharacter[] = []

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            // LogUtils.debug('load from mount...')
            // setTimeout(() => {
            //   loadData()
            // }, 1000 * 1)

            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
                if (!isLoading.value) {
                    setSwipperPerCount()
                }
            })

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            // saveMPStorageSetting()
            ctx.emit('update', true)
        })

        const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
            TOOL.gtmTrack(event, category, action, label, value)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const setSwipperPerCount = () => {
            if (isMobile.value) {
                swiperPCSlidesPerView.value = 2
            } else {
                if (swiperPC.value && swiperPC.value!.offsetWidth) {
                    const width = 133
                    const col_gap = 96
                    const contentWidth = swiperPC.value!.offsetWidth

                    swiperPCSlidesPerView.value = Math.floor((contentWidth) / (width + col_gap))
                }

            }
        }

        const showModal = (modalIdx: number, cardIndex: number,) => {
            isShowModal.value = true
            modalShowIndex.value = modalIdx

            if (modalIdx === 0) {
                selectedBuyIndex.value = cardIndex
            }
        }

        const closeModal = () => {
            // LogUtils.debug('close...')

            isShowModal.value = false
            modalShowIndex.value = -1
            selectedBuyIndex.value = -1
        }

        const mousewheel = (event: Event) => {
            event.preventDefault();
        }

        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }

        const getElementIndex = (element: string) => {
            let index = 0
            const attrListMP = marketAPIFilter!.filterattr
            if (attrListMP.length > 0) {
                const attrObj = attrListMP[0]
                let i = 0
                for (const key in attrObj) {
                    if (key === element) {
                        index = i
                        break
                    }
                    i++
                }

            }
            return index
        }

        const getElementImage = (index: number) => {
            const ele = listHotTopGainer.value[index]
            switch (ele.element.toUpperCase()) {
                case 'EARTH':
                    return '/static/images/element_attr_yellow_earth_ball.png'
                case 'WATER':
                    return '/static/images/element_attr_blue_water_ball.png'
                case 'FIRE':
                    return '/static/images/element_attr_red_fire_ball.png'
                case 'WIND':
                    return '/static/images/element_attr_green_wind_ball.png'
                default:
                    return '/static/images/element_attr_yellow_earth_ball.png'
            }
        }

        const getRarityIndex = (name: string) => {
            let index = -1
            const rarityListMP = marketAPIFilter!.filterrarity
            if (rarityListMP.length > 0) {
                for (let i = 0; i < rarityListMP.length; i++) {
                    for (const key in rarityListMP[i]) {
                        if (key === name) {
                            index = i
                            break
                        }
                    }
                    if (index === i) {
                        break
                    }
                }
            }
            return index
        }

        const getChracterIndex = (name: string) => {
            let index = -1
            const chaListMP = marketAPIFilter!.filterchar
            if (chaListMP.length > 0) {
                for (let i = 0; i < chaListMP.length; i++) {
                    for (const key in chaListMP[i]) {
                        if (key === name) {
                            index = i
                            break
                        }
                    }
                    if (index === i) {
                        break
                    }
                }
            }
            return index
        }

        interface localStorageMPConfig {
            data: localStorageMPConfigObject,
            scrollTop: number
        }

        interface localStorageMPConfigObject {
            searchKeywords: string,
            pageNow: number,
            checkListCha: number[],
            countListCha: number,
            checkListRarity: number[],
            countListRarity: number,
            checkListAttr: number[],
            checkListPrice: number[],
            sortCheckedIndex: number,
            countSortCondition: number,
            // countListPrice: number,
        }

        const go2MPPage = (indexType: number, selectedIndex: number) => {
            const data: localStorageMPConfigObject =
            {
                searchKeywords: '',
                pageNow: 1,
                checkListCha: [],
                countListCha: marketAPIFilter!.filterchar.length,
                checkListRarity: [],
                countListRarity: marketAPIFilter!.filterrarity.length,
                checkListAttr: [-2, -2, -2, -2],
                checkListPrice: [],
                sortCheckedIndex: 0,
                countSortCondition: 3,
            }

            switch (indexType) {
                case 0:
                    const itemHTG = listHotTopGainer.value[selectedIndex]
                    // const chaIndexHTG = getChracterIndex(itemHTG.character)
                    // const rarityIndexHTG = getRarityIndex(itemHTG.rarity)
                    const attrIndexHTG = getElementIndex(itemHTG.element)

                    data.searchKeywords = itemHTG.name
                    // data.checkListCha = [chaIndexHTG]
                    // data.checkListRarity = [rarityIndexHTG]
                    data.checkListAttr[attrIndexHTG] = itemHTG.element_value
                    break
                case 1:
                    const itemHR = listHotRarity.value[selectedIndex]
                    const rarityIndexHR = getRarityIndex(itemHR.rarity)

                    data.checkListRarity = [rarityIndexHR]
                    break
                case 2:
                    const itemHC = listHotCharacter.value[selectedIndex]
                    const chaIndexHC = getChracterIndex(itemHC.character)

                    data.checkListCha = [chaIndexHC]
                    break
                case 3:
                    const itemPC = listPopularCollections.value[selectedIndex]

                    data.searchKeywords = itemPC.name
                    break

            }

            const config = {
                data: data,
                scrollTop: 0
            }

            localStorage.setItem(localStorageMPConfig, JSON.stringify(config))

            router.push('/marketplace/buynft')
        }

        const listMyNFT = () => {
            BUS.$emit('SHOW_MASK', { maskIndex: 2, isShowMask: true, nftList: [] })
        }

        const go2Info = (index: number, src: number = 0) => {
            const nft = src === 0 ? listForNewUser.value[index] : listPopularCollections.value[index]

            router.push(`/nft/${nft['tokenid']}`)


        }

        const getApproveTokenAndNeedAmount = (index: number) => {
            const payToken = goodsPayTokenList.value[0]
            const cardInfo = listForNewUser.value[index]

            if (index >= 0) {
                return {
                    symbol: payToken.symbol,
                    amount: new BigNumber(cardInfo['price']).toNumber()
                }
            } else {
                let tokenCount = new BigNumber(0)
                for (let ele of listForNewUser.value) {
                    tokenCount = tokenCount.plus(new BigNumber(ele['price']))
                }
                return {
                    symbol: payToken.symbol,
                    amount: tokenCount.toNumber()
                }
            }


        }

        const checkApprove = async (index: number) => {
            const payToken = goodsPayTokenList.value[0]
            const contractAddr = index >= 0 ? forNewUserAPIData!.desktop : forNewUserAPIData!.market

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount(index).amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approve = async (index: number) => {
            const action = 'recommend'
            TOOL.gtmTrack('approve-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request-' + action)
            const payToken = goodsPayTokenList.value[0]
            const contractAddr = index >= 0 ? forNewUserAPIData!.desktop : forNewUserAPIData!.market

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': payToken.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = '0'
                    contractToken
                        .approve(contractAddr, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.allowance(
                                        walletAddress.value,
                                        contractAddr
                                    )
                                    payToken.approve = approveBN / (10 ** payToken.decimals)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': payToken.symbol })
                                    })
                                    TOOL.gtmTrack('approve-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success-' + action)
                                    if (index >= 0) {
                                        buy(index)
                                    } else {
                                        buyBatch()
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade-' + action)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request-' + action)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                            })
                        })
                }
            }
        }

        const buy = async (index: number) => {
            TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-request')
            const cardInfo = listForNewUser.value[index]
            const flagApprove = await checkApprove(index)
            if (!flagApprove) {
                // createNotification({
                //   type: 'error',
                //   message: `Your token approve count is not enough`,
                // })
                approvePre(index)
                return
            }
            const id = cardInfo['tokenid']
            const payToken = goodsPayTokenList.value[0]
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractDesktop = new ethers.Contract(
                            forNewUserAPIData!.desktop,
                            TapDesktop,
                            signer
                        )
                        const price = (1 * cardInfo['price'])
                        if ((price) > Number(payToken.balance)) {
                            createNotification({
                                type: 'error',
                                message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': payToken.symbol })}`,
                            })
                            return
                        }
                        const amount = 1
                        // const amountBN = ethers.utils.parseUnits(amount.toString(), 18).toString()
                        // LogUtils.debug(`${cardInfo['orderid']}|${amountBN}`)
                        const sendPromise = contractDesktop.marketBuy(cardInfo['orderid'], amount)

                        buttonDisabled.value = true

                        const uuid = createNotification({
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buying'), 'id': id, 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.successful') })}`
                                    })
                                    TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    buttonDisabled.value = false
                                    setTimeout(() => {
                                        closeModal()
                                        loadData()
                                    }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)

                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                                    duration: 5
                                })
                                buttonDisabled.value = false
                            })
                    } catch (e) {
                        console.error(e)
                        TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-error-request')
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                        })
                        buttonDisabled.value = false
                    }
                }
            }
        }

        const buyBatch = async () => {
            TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-request')
            const flagApprove = await checkApprove(-1)
            if (!flagApprove) {
                // createNotification({
                //   type: 'error',
                //   message: `Your token approve count is not enough`,
                // })
                approvePre(-1)
                return
            }
            const orderids = listForNewUser.value.map((item: any) => item['orderid'])
            const amounts = [1, 1, 1]
            const needAmount = getApproveTokenAndNeedAmount(-1).amount


            const payToken = goodsPayTokenList.value[0]
            const { walletAddress } = toRefs(props)

            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractDesktop = new ethers.Contract(
                            forNewUserAPIData!.market,
                            TapDesktop,
                            signer
                        )
                        if (needAmount > Number(payToken.balance)) {
                            createNotification({
                                type: 'error',
                                message: `Buy new user nfts error${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': payToken.symbol })}`,
                            })
                            return
                        }
                        const sendPromise = contractDesktop.onBuyBatch(orderids, amounts)

                        buttonDisabled.value = true

                        const uuid = createNotification({
                            message: `Buy new user nfts...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `Buy new user nfts ${t('Common.successful')}`
                                    })
                                    TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    buttonDisabled.value = false
                                    setTimeout(() => {
                                        closeModal()
                                        loadData()
                                    }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)

                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `Buy new user nfts ${t('Common.error')}`,
                                    duration: 5
                                })
                                buttonDisabled.value = false
                            })
                    } catch (e) {
                        console.error(e)
                        TOOL.gtmTrack('buy-recommend-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-recommend-error-request')
                        createNotification({
                            type: 'error',
                            message: `Buy new user nfts ${t('Common.error')}`,
                        })
                        buttonDisabled.value = false
                    }
                }
            }

        }

        const approvePre = (index: number) => {
            selectedBuyIndex.value = index
            showModal(0, index)
        }

        const changeCardsForNewUser = async () => {
            const urlRecommendFNU = `${API.urlMPRecommendForNewUser}${chainId}/`
            isChangeForNewUser.value = false
            forNewUserAPIData = (await axios.get(urlRecommendFNU)).data.data
            listForNewUser.value = forNewUserAPIData!.list
            isChangeForNewUser.value = true
        }

        const loadData = async (index: number = 0) => {
            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)

            chainId = await TOOL.getChainId()

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            const { walletAddress } = toRefs(props)

            const urlRecommendFNU = `${API.urlMPRecommendForNewUser}${chainId}/`
            const urlRecommendPC = `${API.urlMPRecommendPopularCollection}${chainId}/`

            forNewUserAPIData = (await axios.get(urlRecommendFNU)).data.data
            listForNewUser.value = forNewUserAPIData!.list

            const pcList = (await axios.get(urlRecommendPC)).data.data
            // let arr: any[] = []
            // arr = arr.concat(pcList)
            // arr = arr.concat(pcList)
            // arr = arr.concat(pcList)
            listPopularCollections.value = pcList

            const urlMarketFilter = `${API.filterMP}${chainId}/`
            marketAPIFilter = (await axios.get(urlMarketFilter)).data.data

            const payTL = []
            const bnbRange = []
            const busdRange = []

            const busdPrice = marketAPIFilter!.pt1price
            for (let key in busdPrice) {
                const valueBusd = (busdPrice as any)[key]
                for (let keyBusd in valueBusd) {
                    busdRange.push({
                        key: keyBusd,
                        value: (valueBusd as any)[keyBusd]
                    })
                }
            }
            let eleUSDT: goodsPayToken = {
                goodsid: 0,
                decimals: 18,
                address: isDebug ? pageChainConfig.chainTokensConfig.TUSDT : pageChainConfig.chainTokensConfig.USDT,
                symbol: isDebug ? "T-USDT" : "USDT",
                priceRange: busdRange,
                balance: '',
                approve: 0
            }

            payTL.push(eleUSDT)

            if (walletAddress.value.length > 0) {
                let calls: any[] = []
                let returnValue = null

                LogUtils.debug(`Get Approve Start(${walletAddress.value})...`)

                for (let i = 0; i < payTL.length; i++) {
                    const ele = payTL[i]
                    calls = calls.concat([
                        {
                            target: ele.address,
                            call: ['balanceOf(address)(uint256)', walletAddress.value],
                            returns: [['PAYSALE_BALANCE_' + i, (val: any) => val]]
                        },
                        {
                            target: ele.address,
                            call: [
                                'allowance(address,address)(uint256)',
                                walletAddress.value,
                                pageChainConfig.chainABIConfig.DESKTOP
                            ],
                            returns: [['PAYSALE_APPROVE_' + i, (val: any) => val / 10 ** ele.decimals]]
                        }
                    ])
                }

                returnValue = await aggregateMarketPlaceRecommend(calls, pageChainConfig.multicallConfig)
                const returnResult = returnValue.results.transformed

                for (let i = 0; i < payTL.length; i++) {
                    const ele = payTL[i]
                    ele.balance = TOOL.scientificToNumber(
                        new BigNumber(returnResult['PAYSALE_BALANCE_' + i].toString())
                            .div(10 ** ele.decimals)
                            .toString()
                    )
                    ele.approve = returnResult['PAYSALE_APPROVE_' + i]
                }
            }

            goodsPayTokenList.value = payTL

            const urlHotTopGainer = `${API.urlMPBuyNFTHotGainer}${chainId}`
            const htgList = (await axios.get(urlHotTopGainer)).data.data
            for (let eleHTG of htgList) {
                eleHTG.expand = false
            }
            listHotTopGainer.value = htgList

            const urlHotRarity = `${API.urlMPBuyNFTHotRarity}${chainId}`
            const htrList = (await axios.get(urlHotRarity)).data.data
            for (let eleHTR of htrList) {
                eleHTR.expand = false
                eleHTR.isshowtooltip = false
            }
            listHotRarity.value = htrList

            const urlHotCharacter = `${API.urlMPBuyNFTHotCharacter}${chainId}`
            const htcList = (await axios.get(urlHotCharacter)).data.data
            for (let eleHTC of htcList) {
                eleHTC.expand = false
                eleHTC.isshowtooltip = false
            }
            listHotCharacter.value = htcList

            nextTick(() => {
                // Loading should be closed asynchronously
                if (walletAddress.value.length === 0) {
                    buttonDisabled.value = true
                } else {
                    buttonDisabled.value = false
                }
                loadingInstance.close()
                isLoading.value = false
                if (isLoadingInit.value) {
                    isLoadingInit.value = false
                    ctx.emit('update', false)
                }
                setTimeout(() => {
                    setSwipperPerCount()
                }, 1)
            })
        }

        return {
            isLoading,
            isExpand,
            listMyNFT,
            getLowerCase,
            mousewheel,
            showModal,
            closeModal,
            isMobile,
            getTime,
            buttonDisabled,
            gtmTrack,
            go2Info,
            buy,
            selectedBuyIndex,
            approvePre,
            getApproveTokenAndNeedAmount,
            listForNewUser,
            listPopularCollections,
            goodsPayTokenList,
            modules: [Pagination, Navigation, Scrollbar, A11y],
            leaderboardIndex,
            listHotTopGainer,
            listHotRarity,
            listHotCharacter,
            go2MPPage,
            changeCardsForNewUser,
            getElementImage,
            isShowModal,
            modalShowIndex,
            approve,
            buyBatch,
            swiperPC,
            swiperPCSlidesPerView,
            isChangeForNewUser,
        }
    }
})
</script>
<style lang="stylus" scoped>
    pc-small = 'screen and (max-width: 1380px)'
    pc-mobile-breakpoint = 'screen and (max-width: 1140px)'

    .el-icon{
            margin-left: 6PX;
            height: 20PX;
            width: 20PX;
            svg{
                height: 18PX;
                width: 18PX;
            }
            aspect-ratio: 1;
            cursor pointer
        }

    #root,#app{
        height: auto
        overflow-y: auto
    }
    .right-padding{
        padding 40PX 16.6vw 0;
        flex: 1;
        position: relative;
    }
    @media pc-small
        .right-padding{
            padding 40PX 40PX 0;
            width: 1180PX;
        }
    .list-title {
        font-family: Avenir-Bold
        font-size: 16PX
        color: #FFFFFF
        margin-bottom: 22PX
    }

    .list-title-block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22PX

        .title-inner-text {
            font-family: Avenir-Bold
            font-size: 16PX
            color: #FFFFFF
        }
    }

    .nft-item-image {
            height: 120PX;
            z-index: 1;
    }

    .nft-item-list{
        position relative
        column-gap: 30PX;
        margin-bottom: 52PX;
        padding 30PX 0 40PX;
        border: 2PX solid transparent;
        border-radius: 6PX;
        background-color: rgba(255,255,255,0.1);
        background-clip: padding-box;

        .change-block{
            position absolute
            right: 26PX
            top: 18PX;
            display: flex;
            align-items: center;
            cursor pointer

            .text{
                font-family: Avenir-Bold;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 1;
                margin-left: 8PX
            }
            &:hover,{
                opacity: 0.8;
            }
            &:disabled,{
                opacity: 0.5;
                cursor: no-drop;
            }
        }

        .buy-all-block{
            position absolute
            right: 26PX
            bottom: 18PX;
            display: flex;
            align-items: center;
            cursor pointer
            outline: none;
            border: none;
            background: none;

            .text{
                font-family: Avenir-Bold;
                font-size: 12PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                line-height: 1;
                margin-left: 8PX
            }

            &:hover,{
                opacity: 0.8;
            }
            &:disabled,{
                opacity: 0.5;
                cursor: no-drop;
            }
        }
    }

    .nft-item-list::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-radius: inherit;
        padding: 2PX;
        background: linear-gradient(to right bottom, rgba(226,186,134,1), rgba(191,142,106,1));
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    .item-fornewuser{
        z-index 10
        .nft-item{
            width: 122.5PX;
            height: 140PX;
            color: #fff;
            position: relative;
            z-index: 2;
            background: rgba(22,20,34,0.3);
            border-radius: 5PX;
            &.fadein{
                animation: fadeIn 1s;
            }
            &.fadeout{
                animation: fadeOut 1s;
            }
        }

            @keyframes fadeIn {
                from {
                    opacity: 0;
                    // transform: translate3d(0, 100%, 0);
                }
                to {
                    opacity: 1;
                    // transform: translate3d(0, 0, 0);
                }
            }

            @keyframes fadeOut {
                from {
                    opacity: 1;
                    // transform: translate3d(0, 0, 0);
                }
                to {
                    opacity: 0;
                    // transform: translate3d(0, 100%, 0);
                }
            }

        .item-fornewuser-right{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 20PX;

            .line1{
                font-family: Avenir;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
            }

            .line2{
                font-family: Avenir;
                font-size: 12PX;
                color: rgba(152,152,152,0.7)
                letter-spacing: 0;
                text-align: center;
                line-height: 12PX;
                margin-top: 6PX;
            }

            .cardprice{
                font-family: Avenir-Bold;
                font-size: 14PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                margin-top: 18PX;
            }

            .button-buy-fornewuser{
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #FFC800;
                letter-spacing: 0;
                text-align: center;
                border: 1PX solid #FFC800;
                border-radius: 6PX;
                padding 4PX 24PX
                outline: none;
                background: none;
                margin-top: 10PX;
                cursor pointer
                &:hover{
                    opacity: 0.8;
                }
                &:disabled{
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }
        }
    }

    .swiper-container{
        display flex;
        flex-direction: column;

        .swiper-button-prev-pc{
            padding 4PX
            display: flex;
            align-items: center;
            justify-content: center;
            cursor pointer
        }

        .swiper-button-next-pc{
            padding 4PX
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;
            cursor pointer
        }

        .swiper-button-disabled{
            opacity 0.5;
            cursor no-drop;
            display none;
        }
    }

    .swiper {
        width: calc(100vw - 33.2vw)
        cursor pointer

        .swiper-slide {
                text-align: center;
                font-size: 18PX;
                //   background: #fff;

                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                align-items: center;
            }
    }

    @media pc-small
        .swiper {
            width: calc(100vw - 32PX)
        }

    .popular-collection-list{
        padding: 0
        display: flex;
        align-items: flex-start;
        // column-gap: 96PX;
        // width:600PX;

        .item-popular-collection{
            display flex;
            flex-direction: column;
            .nft-item-pc{
                width: 133PX;
                height: 152PX;
                position: relative;
                border-radius: 6PX;
                border: 1PX solid transparent;
                background-clip: padding-box;

                .nft-item-pc-image {
                    height: 126PX;
                    z-index: 1;
                }

                .nft-item-chracter{
                    position absolute;
                    top: 6PX;
                    left: 7PX;
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                }

                .nft-item-level{
                    position absolute;
                    top: 6PX;
                    right: 7PX;
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                }

                .nft-item-rarity-img{
                    position absolute;
                    bottom: 6PX;
                    right: 7PX;
                    height: 17PX;
                    width: auto;
                }

                .nft-item-attrs {
                    position: absolute;
                    left: 7PX;
                    bottom: 6PX;
                    z-index: 2;
                }

                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    border-radius: inherit;
                    padding: 1PX;
                    background: linear-gradient(to right bottom, #E2C186, #BF8E6A);
                    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: xor;
                    mask-composite: exclude;
                }
            }

            .nft-item-price-block{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 8PX;

                .nft-item-price{
                    font-family: Avenir;
                    font-size: 14PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                }

                .nft-item-price-increase{
                    font-family: Avenir-Bold;
                    font-size: 14PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 10PX;
                    &.down{
                        color: #ff6d6d
                    }
                    &.up{
                        color: #26BA0E;
                    }
                }
            }

        }
    }

    .hot-list-title-block{
        display: flex;
        column-gap: 50PX;
        margin: 56PX 0 10PX;
        width: 100%;
        .item-hot-list-title-inner-block{
            display: flex;
            align-items center;
            justify-content center;
            position relative
            cursor pointer
            .title-text{
                font-family: Avenir-Bold;
                font-size: 16PX;
                color: #989898;
                letter-spacing: 0;
                line-height: 1;
                padding 14PX 0
            }
            .line-select{
                position absolute;
                bottom: 0;
                width: 100%;
                height: 2PX;
                background: #FFDB62;
                border-radius: 2PX;
                display: none;
            }
            &.active,&:hover{
                .title-text{
                    color: #FFFFFF;
                }
                .line-select{
                    display: block;
                }
            }
        }
    }

    .hot-list-label-block{
        display flex;
        align-items: center;
        justify-content: space-between;
        padding 10PX;
        border-bottom: 1PX solid #3D3D3D;
        .item-label{
            font-family: Avenir;
            font-size: 12PX;
            color: #989898;
            letter-spacing: 0;
            line-height: 1;
            text-align: center;
        }
        .item-label-block{
            display flex;
            align-items: center;
            justify-content: center
        }
    }
    .hot-list-block{
        display flex;
        flex-direction: column;
        padding 22PX 0;
        row-gap: 30PX;
        .item-data-block{
            
            display flex;
            align-items: center;
            justify-content: space-between;
            padding 0 10PX
            .item-data{
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 1;
                text-align: center;
                &.up{
                    font-family: Avenir-Bold;
                    color: #26BA0E;
                }
                &.down{
                    font-family: Avenir-Bold;
                    color: #D50000;
                }
            }

            .item-data-collection{
                width: 200PX;
                display: flex;
                align-items: center;

                .item-data-image-collection-block{
                    height: 50PX;
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1PX solid #3A3F59;
                    border-radius: 8PX;
                    .item-data-image-collection{
                        height: 42PX;
                        
                    }

                }

                

                .item-data-collection-name{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 16PX;
                }
            }

            .item-data-character{
                width: 150PX;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .item-data-image-character-block{
                    height: 50PX;
                    aspect-ratio: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1PX solid #3A3F59;
                    border-radius: 8PX;
                    .item-data-image-character{
                        height: 100%;
                        border-radius: 8PX;
                    }

                }

                .item-data-character-name{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 16PX;
                }
            }
            
            .item-data-element-block{
                display flex;
                align-items: center;
                justify-content: center;

                .item-data-image-element{
                    height: 20PX;
                }

                .item-data-text-element{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    margin-left: 6PX;
                    &.earth{
                        color: #FFDB62;
                    }

                    &.fire{
                        color: #FF6D6D;
                    }

                    &.water{
                        color: #6D9CFF;
                    }

                    &.wind{
                        color: #6DFFD4;
                    }
                }
            }

            

            .item-data-image-rarity{
                height: 18PX;
            }

            .item-button-buy{
                border: 1PX solid #3D3D3D;
                border-radius: 6PX;
                font-family: Avenir-Medium;
                font-size: 12PX;
                color: #989898;
                letter-spacing: 0;
                text-align: center;
                padding 4PX 30PX;
                outline: none;
                background: none;
                transition: color .3s;
                &:hover,&:active{
                    border: 1PX solid #FFC800;
                    color: #FFC800;
                }
            }
        }
    }

 

    @media pc-mobile-breakpoint {
        .list-title-block{
            display flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            span{
                font-family: Avenir-Bold;
                font-size: 16PX;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 1;
            }

            .change-block{
                display: flex;
                align-items: center;
                cursor pointer;
                justify-content: center;

                .text{
                    font-family: Avenir-Bold;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: left;
                    line-height: 1;
                    margin-left: 8PX
                }
            }

        }
        .list-title,.list-title-block {
            margin-bottom: 10PX
        }

        .nft-item-image {
            height: 86PX;
        }

        .fornewuser-block{
            position relative
            display: flex;
            align-items: center;
            justify-content: center;
            padding 16PX;
            margin-bottom: 30PX;
            border: 2PX solid transparent;
            border-radius: 6PX;
            background-color: rgba(255,255,255,0.1);
            background-clip: padding-box;

            .buy-all-block{
                display: flex;
                align-items: center;
                cursor pointer
                outline: none;
                border: none;
                background: none;
                margin-top: 15PX;

                .text{
                    font-family: Avenir-Bold;
                    font-size: 12PX;
                    color: #FFC800;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 1;
                    margin-left: 8PX
                }

                &:hover,{
                    opacity: 0.8;
                }
                &:disabled,{
                    opacity: 0.5;
                    cursor: no-drop;
                }
            }

            .nft-item-list-fornewuser{
                position relative
                // column-gap: 30PX;
                justify-content: space-between
                display flex
                border: none
                width: 100%
            }
        }

        .fornewuser-block::before{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border-radius: inherit;
            padding: 2PX;
            background: linear-gradient(to right bottom, rgba(226,186,134,1), rgba(191,142,106,1));
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
        }

        .item-fornewuser{
            width: 80PX;
            z-index 10
            .nft-item{
                width: 80PX;
                height: 92PX;
                color: #fff;
                position: relative;
                z-index: 2;
                background: rgba(22,20,34,0.3);
                border-radius: 5PX;
            }
            .item-fornewuser-bottom{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 8PX;

                .line1{
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                    width: 100%;
                }

                .cardprice{
                    font-family: Avenir-Bold;
                    font-size: 14PX;
                    color: #FFC800;
                    letter-spacing: 0;
                    text-align: center;
                    margin-top: 8PX;
                }

                .button-buy-fornewuser{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFC800;
                    letter-spacing: 0;
                    text-align: center;
                    border: 1PX solid #FFC800;
                    border-radius: 6PX;
                    padding 4PX 0PX
                    width: 100%;
                    outline: none;
                    background: none;
                    margin-top: 10PX;
                    cursor pointer
                    &:hover{
                        opacity: 0.8;
                    }
                    &:disabled{
                        opacity: 0.5;
                        cursor: no-drop;
                    }
                }
            }
        }

        .swiper-container{
            display flex;
            flex-direction: column;

            .swiper-button-prev-pc{
                padding 4PX
                display: flex;
                align-items: center;
                justify-content: center;
                cursor pointer
            }

            .swiper-button-next-pc{
                padding 4PX
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0;
                cursor pointer
            }

            .swiper-button-disabled{
                opacity 0.5;
                cursor no-drop;
            }
        }

        .swiper {
            width: 100vw;
            margin-left: -16PX;
            cursor pointer
            padding: 0 16PX;

            .swiper-slide {
                text-align: center;
                font-size: 18PX;
                //   background: #fff;

                /* Center slide text vertically */
                 display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
            }
        }

        .popular-collection-list-mobile{
            .item-popular-collection{
                flex-direction: column;
                .nft-item-pc{
                    aspect-ratio: 210/240;
                    width: 40vw; 
                    height: 45.714vw;
                    position: relative;
                    border-radius: 6PX;
                    border: 1PX solid transparent;
                    background-clip: padding-box;

                    .nft-item-pc-image {
                        height: 100%;
                        z-index: 1;
                    }

                    .nft-item-chracter{
                        position absolute;
                        top: 6PX;
                        left: 6PX;
                        font-family: Avenir;
                        font-size: 12PX;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        line-height: 1;
                    }

                    .nft-item-level-mobile{
                        position absolute;
                        bottom: 6PX;
                        left: 6PX;
                        font-family: Avenir;
                        font-size: 12PX;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        line-height: 1;
                        height:14PX;
                        width: auto;
                        text-align: left;
                    }

                    .nft-item-rarity-img{
                        position absolute;
                        bottom: 6PX;
                        right: 6PX;
                        height: 14PX;
                        width: auto;
                    }

                    .nft-item-attrs {
                        position: absolute;
                        left: 6PX;
                        bottom: 6PX;
                        z-index: 2;
                    }

                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        border-radius: inherit;
                        padding: 1PX;
                        background: linear-gradient(to right bottom, #E2C186, #BF8E6A);
                        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                    }
                }

                .nft-item-price-block{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10PX;

                    .nft-item-price-mobile{
                        font-family: Avenir;
                        font-size: 14PX;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 1;
                    }

                    .nft-item-price-increase{
                        font-family: Avenir-Bold;
                        font-size: 14PX;
                        color: #26BA0E;
                        letter-spacing: 0;
                        line-height: 1;
                        margin-left: 10PX;
                        &.down{
                            color: red
                        }
                        line-height: 1;
                    }
                }

            }
        }

        .hot-list-title-block{
            display: flex;
            column-gap: 30PX;
            margin: 18PX 0 18PX 0PX;
            width: 100%;
            .item-hot-list-title-inner-block{
                display: flex;
                align-items center;
                justify-content center;
                position relative
                cursor pointer
                .title-text{
                    font-family: Avenir-Bold;
                    font-size: 16PX;
                    color: #989898;
                    letter-spacing: 0;
                    line-height: 1;
                    padding 14PX 0
                }
                .line-select{
                    position absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2PX;
                    background: #FFDB62;
                    border-radius: 2PX;
                    display: none;
                }
                &.active,&:hover{
                    .title-text{
                        color: #FFFFFF;
                    }
                    .line-select{
                        display: block;
                    }
                }
            }
        }

        .hot-list-label-block{
            display flex;
            align-items: center;
            justify-content: space-between;
            padding 10PX 0;
            border-bottom: 1PX solid #3D3D3D;
            .item-label{
                font-family: Avenir;
                font-size: 12PX;
                color: #989898;
                letter-spacing: 0;
                line-height: 1;
                text-align: center;
            }
        }
        .hot-list-block{
            display flex;
            flex-direction: column;
            row-gap: 0;
            width: 100vw;
            margin-left: -16PX;
            padding: 0
            background: #000000;

            .item-data-block{
                width: 100%;
                display flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                padding 16PX 16PX 8PX;
                // background: #000000;

                .item-label{
                    font-family: Avenir;
                    font-size: 12PX;
                    color: #989898;
                    letter-spacing: 0;
                    line-height: 1;
                    text-align: center;
                    
                }

                .item-label-block{
                    display flex;
                    align-items: center;
                    justify-content: center
                }

                .item-data{
                    font-family: Avenir-Medium;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 1;
                    text-align: center;
                    &.up{
                        font-family: Avenir-Bold;
                        color: #26BA0E;
                    }
                    &.down{
                        font-family: Avenir-Bold;
                        color: #D50000;
                    }
                }

                .item-data-rarity-block{
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .item-data-image-rarity{
                        height: 18PX;
                        margin-left: 32PX
                    }
                }

                .item-data-row-line1{
                    display flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1PX solid #3D3D3D;

                    .item-data-collection{
                        flex: 1
                        display: flex;
                        align-items: center;

                        .item-data-image-collection-block{
                            height: 50PX;
                            aspect-ratio: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1PX solid #3A3F59;
                            border-radius: 8PX;
                            .item-data-image-collection{
                                height: 42PX;
                                
                            }

                        }

                        .item-data-image-collection-block-right{
                            display: flex;
                            flex-direction: column;
                            margin-left: 16PX;

                            .item-data-collection-character{
                                font-family: Avenir-Medium;
                                font-size: 12PX;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                line-height: 1;
                                
                            }

                            .item-data-collection-name{
                                font-family: Avenir-Medium;
                                font-size: 12PX;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                line-height: 1;
                                margin-top: 8PX;
                                margin-left: 0
                            }

                            
                        }
                    }

                    .item-data-character{
                            flex: 1;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            .item-data-image-character-block{
                                height: 50PX;
                                aspect-ratio: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1PX solid #3A3F59;
                                border-radius: 8PX;
                                .item-data-image-character{
                                    height: 50PX;
                                    border-radius: 8PX;
                                }

                            }

                            .item-data-character-name{
                                font-family: Avenir-Medium;
                                font-size: 12PX;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                line-height: 1;
                                margin-left: 16PX;
                            }
                        }

                    .item-data-price-and-buy{
                        flex: 1
                        display: flex;
                        align-items: center;
                        justify-content: space-between

                        .item-button-buy{
                            border: 1PX solid #3D3D3D;
                            border-radius: 6PX;
                            font-family: Avenir-Medium;
                            font-size: 12PX;
                            color: #989898;
                            letter-spacing: 0;
                            text-align: center;
                            padding 4PX 20PX;
                            outline: none;
                            background: none;
                            transition: color .3s;
                            &:hover,&:active{
                                border: 1PX solid #FFC800;
                                color: #FFC800;
                            }
                        }
                    }

                }

                .item-data-row-list{
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .item-data-row{
                        display flex;
                        width: 100%;
                        border-bottom: 1PX solid #3D3D3D;

                        .item-data-element-block{
                            display flex;
                            align-items: center;
                            justify-content: flex-start
                            flex: 1;

                            .item-data-image-element{
                                height: 20PX;
                                margin-left: 16PX;
                            }

                            .item-data-text-element{
                                font-family: Avenir-Medium;
                                font-size: 12PX;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                line-height: 1;
                                margin-left: 10PX;
                                &.earth{
                                    color: #FFDB62;
                                }

                                &.fire{
                                    color: #FF6D6D;
                                }

                                &.water{
                                    color: #6D9CFF;
                                }

                                &.wind{
                                    color: #6DFFD4;
                                }
                            }
                        }

                        .item-data-24h-block{
                            display: flex;
                            align-items: center;
                            flex: 1;

                            .item-data-24h-data-percent-block{
                                display: flex;
                                align-items: center;
                                margin-left: 16PX;

                                svg{
                                    width: 9PX;
                                    height: 11PX;
                                    display: none;
                                }

                                svg.up{
                                    color: #26BA0E;
                                    display: block;
                                }
                                svg.down{
                                    color: #D50000;
                                    transform: rotate(180deg);
                                    display: block;
                                }
                            }
                        }

                        .item-data-stat-block{
                            display: flex;
                            align-items: center;
                            flex: 1;

                            .item-data{
                                margin-left: 16PX
                            }
                        }

                        .item-data-sparkline-block{
                            display: flex;
                            align-items: center;
                            flex: 1;

                            svg{
                                margin-left: 26PX
                            }
                        }

                        
                        
                    }
                }

                &.expand{
                    .item-data-row-list{
                        display: flex;
                        transition: height 0.3s ease-in-out;
                    }
                    background: rgba(255,255,255,0.1);
                    border-bottom: none;
                    
                }

                &.collapse{
                    .item-data-row-list{
                        display: none;
                        transition: height 0.3s ease-in-out;
                    }
                    background: #000000;
                    // border-bottom: 1PX solid #3D3D3D;
                }

            

                
            }
        }
    }
</style>