<template>
    <div class="mp-header">
        <div class="category-item">
            <div class="category-title">MarketPlace</div>
            <div class="category-list">
                <a href="/#/marketplace/recommend" :class="$route.name === 'MarketplaceRecommend' ? 'active' : ''"
                    class="list-item">Recommend</a>
                <a href="/#/marketplace/buynft" :class="$route.name === 'MarketplaceBuyNFT' ? 'active' : ''"
                    class="list-item">Buy NFTs</a>
                <a href="/#/marketplace/rentnft" :class="$route.name === 'MarketplaceRentNFT' ? 'active' : ''"
                    class="list-item">Rend NFTs</a>
            </div>
        </div>
        <div class="category-item">
            <div class="category-title">Loan</div>
            <div class="category-list">
                <a href="/#/marketplace/borrowmc" :class="$route.name === 'MarketplaceBorrowMC' ? 'active' : ''"
                    class="list-item">Borrow MC</a>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MPHeader',
    setup() {

        return {
        }
    }
})    
</script>
<style lang="stylus">
    .mp-header{
        display flex;
        flex-direction column ;
        margin-top 20PX;
        // border-radius 10PX;
        // border: 1PX solid rgba(85,101,189,1);
        row-gap 10PX;

        .category-item{
            width 100%;
            display flex;
            flex-direction column;
            align-items center;
            padding 0 20PX;
            
            .category-title{
                font-size 18PX;
                color #FFFFFF;
                font-family Avenir-Bold;
            }
            .category-list{
                display flex;
                flex-direction column;
                align-items center;
                width 100%;
                margin-top 10PX;
                row-gap 10PX;

                .list-item{
                    font-size 14PX;
                    color #c7cad5;
                    background none;
                    width 100%;
                    line-height 40PX;
                    text-align center;
                    cursor pointer
                    &:hover,&.active{
                        background rgba(85,101,189,0.2);
                        color #FFFFFF;
                        font-family Avenir-Bold;
                        text-decoration none;
                    }
                }
            }
        }
            
    }
</style>