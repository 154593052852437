export const configProject = {
  test: {
    abis: {
      HT: {
        MULTICALL: '0xe04f7524ae7948403358cf613115347f1b04ae57',
        POOLS: '0xb3BF48fa4EAb5fb1789Ce184462aA30382960E7D',
        GETPRICE: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        FACTORY: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        RELEASE0: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE1: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE2: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        LUCKPOOL: '0xAe50B12E130E904a79bC3fc404f34E667bc96EaD'
      },
      BNB: {
        MULTICALL: '0x57D841460d618233fbe6704b3Fbf74b6e5aA1A79',
        POOLS: '0xb3BF48fa4EAb5fb1789Ce184462aA30382960E7D',
        GETPRICE: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        FACTORY: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        RELEASE0: '0x1DdB588F441EF648F3274c29B631e7e488B8a731',
        RELEASE1: '0x61aE0dD4b193f24CEA1A2e84Ad1B1728d8B13D44',
        RELEASE2: '0xE82DD6263593A29F6587a51753804f09882fdf6f',
        LUCKPOOL: '0xAe50B12E130E904a79bC3fc404f34E667bc96EaD',
        DESKTOP: '0x6C5bD54A7a57648d0f384a82e127C758fD657E64'
      },
      GT: {
        MULTICALL: '0x9f3735118530F3dd5699c797dc495De286620E1c',
        POOLS: '0xb3BF48fa4EAb5fb1789Ce184462aA30382960E7D',
        GETPRICE: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        FACTORY: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        RELEASE0: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE1: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE2: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        LUCKPOOL: '0xAe50B12E130E904a79bC3fc404f34E667bc96EaD'
      }
    },
    tokens: {
      HT: {
        USDT: '0xee4d1297ba3bb705962dbc57f09a2f1d083d63f5',
      },
      BNB: {
        USDT: '0x0C022260C9e695113dFbA8C5E547e941f8ab1921',
        BUSD: '0x0C022260C9e695113dFbA8C5E547e941f8ab1921',
        TUSDT: '0x0C022260C9e695113dFbA8C5E547e941f8ab1921',
        MC: '0xA5638DE1D35B3A69230FEB227118Fd7F483204d8'
      },
      GT: {
        USDT: '0x55d398326f99059fF775485246999027B3197955',
      }
    },
    hide_poollist: {
      HT: [],
      BNB: [],
      GT: []
    },
    blockchain_browser_url: {
      HT: 'https://hecoinfo.com/tx/',
      BNB: 'https://testnet.bscscan.com/tx/',
      GT: 'https://gatescan.org/tx/'
    },
    rpcurls: {
      HT: 'https://http-mainnet.hecochain.com/',
      BNB: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
      GT: 'https://evm.gatenode.cc/'
    },
    chainid: {
      HT: 128,
      BNB: 97,
      GT: 86
    }
  },
  main: {
    abis: {
      BNB: {
        MULTICALL: '0x50eF2D43B9b7505e5669a83BBd2E0ed0008F8b92',
        POOLS: '0xb3BF48fa4EAb5fb1789Ce184462aA30382960E7D',
        GETPRICE: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        FACTORY: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        RELEASE0: '0x07e87369Db2D6b660064358f24FE45CB748EA4D3',
        RELEASE1: '0x61aE0dD4b193f24CEA1A2e84Ad1B1728d8B13D44',
        RELEASE2: '0xE82DD6263593A29F6587a51753804f09882fdf6f',
        LUCKPOOL: '0x91884412BfAac65F9F3992dDB96f7d213aB72F0A',
        DESKTOP: '0x69f08d3e1DB67352a4b271F3ecBaE81eA27fFbD3'
      },
      GT: {
        MULTICALL: '0x9f3735118530F3dd5699c797dc495De286620E1c',
        POOLS: '0xb3BF48fa4EAb5fb1789Ce184462aA30382960E7D',
        GETPRICE: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        FACTORY: '0x240123176F0aA07611B0D3a90993b4aaF6FBdaDC',
        RELEASE0: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE1: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        RELEASE2: '0x0ea1b0b997661829E292332dB582D8e2eAAA1114',
        LUCKPOOL: '0xAe50B12E130E904a79bC3fc404f34E667bc96EaD'
      }
    },
    tokens: {
      BNB: {
        USDT: '0x55d398326f99059fF775485246999027B3197955',
        BUSD: '0x55d398326f99059fF775485246999027B3197955',
        MC: '0x59C49B54D4425C7fF1E49f40958a14d48cC87c26'
      },
      GT: {
        USDT: '0x55d398326f99059fF775485246999027B3197955',
      }
    },
    hide_poollist: {
      BNB: [],
      GT: []
    },
    blockchain_browser_url: {
      HT: '',
      BNB: 'https://bscscan.com/tx/',
      GT: 'https://gatescan.org/tx/'
    },
    rpcurls: {
      HT: '',
      BNB: 'https://bsc-dataseed2.ninicoin.io/',
      GT: 'https://evm.gatenode.cc/'
    },
    chainid: {
      HT: 128,
      BNB: 56,
      GT: 86
    }
  }
}

export default configProject
