import { nftHeadInfo, filterMP } from './../constants/api';
import { createRouter, createWebHashHistory, Router, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
// import Home from '@/views/Home.vue'
// import HomeNew from '@/views/HomeNew.vue'
import HomeNew from '@/views/HomeNew/index.vue'
import GuildCoop from '@/views/GuildCoop.vue'
// import MarketPlace from '@/views/MarketPlace.vue'
import MyNFT from '@/views/MyNFT.vue'
import UserCenter from '@/views/UserCenter.vue'
import MysteryBox from '@/views/MysteryBox.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Resetpass from '@/views/Resetpass.vue'
import Forgetpass from '@/views/Forgetpass.vue'
import Play from '@/views/Play.vue'
import Activity from '@/views/Activity.vue'
import Terms from '@/views/Terms.vue'
import NFTInfo from '@/views/NFTInfo.vue'
import NFTRecordInfo from '@/views/NFTRecordInfo.vue'
// import Pools from '@/views/Pools.vue'
// import LuckPool from '@/views/LuckPool.vue'
import Release from '@/views/Release.vue'
import ReleaseRedirect from '@/views/ReleaseRedirect.vue'
// import Tokennomics from '@/views/Tokennomics.vue'
import pdfView from '@/views/Pdf.vue'
import Page404 from '@/views/Page404.vue'
import MyNFTHead from '@/views/MyNFTHead.vue'
import NFTHeadInfo from '@/views/NFTHeadInfo.vue'
import MyNFTRune from '@/views/MyNFTRune.vue'
import NFTRuneInfo from '@/views/NFTRuneInfo.vue'
import NFTRuneRecordInfo from '@/views/NFTRuneRecordInfo.vue'
import MysteryBoxInfo from '@/views/MysteryBoxInfo.vue'

import Recommend from '@/views/MarketPlaceV2/Recommend.vue'
import BuyNFT from '@/views/MarketPlaceV2/BuyNFT.vue'
import RentNFT from '@/views/MarketPlaceV2/RentNFT.vue'
import BorrowMC from '@/views/MarketPlaceV2/BorrowMC.vue'
// import Test from '@/views/test.vue'
// import Demo from '@/views/Demo.vue'

import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'

let routes: Array<RouteRecordRaw> = [
    // {
    //   path: '/demo',
    //   name: 'Demo',
    //   meta: {
    //     title: 'Demo'
    //   },
    //   component: Demo
    // },
    {
        path: '/terms',
        name: 'Terms',
        meta: {
            gtm: 'Terms'
        },
        component: Terms
    },
    //   {
    //     path: '/test',
    //     name: 'test',
    //     meta: {
    //       gtm: 'test'
    //     },
    //     component: Test
    //   },
    {
        path: '/activity',
        name: 'Activity',
        meta: {
            gtm: 'Activity'
        },
        component: Activity
    },
    // {
    //   path: '/tokennomics',
    //   name: 'Tokennomics',
    //   meta: {
    //     title: 'Tokennomics'
    //   },
    //   component: Tokennomics
    // },
    // {
    //   path: '/pools',
    //   name: 'Pools',
    //   meta: {
    //     title: 'Pools'
    //   },
    //   component: LuckPool
    // },
    {
        path: '/release',
        name: 'Release',
        meta: {
            gtm: 'ReleaseRedirect'
        },
        component: ReleaseRedirect
    },
    {
        path: '/nftrecord/:id/:state/:orderid',
        name: 'NFT Record Info',
        meta: {
            gtm: 'NFT Record Info',
        },
        component: NFTRecordInfo
    },
    {
        path: '/nft/:id',
        name: 'NFTInfo',
        meta: {
            gtm: 'NFT Info',
        },
        component: NFTInfo
    },
    {
        path: '/nfthead/:id',
        name: 'NFTHeadInfo',
        meta: {
            gtm: 'NFT Head Info',
        },
        component: NFTHeadInfo
    },
    {
        path: '/nftrune/:id/:isforsell?',
        name: 'NFTRuneInfo',
        meta: {
            gtm: 'NFT Rune Info',
        },
        component: NFTRuneInfo
    },
    {
        path: '/nftrunerecord/:id/:orderid',
        name: 'NFT Record Record Info',
        meta: {
            gtm: 'NFT Rune Record Info',
        },
        component: NFTRuneRecordInfo
    },
    {
        path: '/mysterybox/:gotoopenbox?',
        name: 'Mysterybox',
        meta: {
            gtm: 'Mystery Box',
        },
        component: MysteryBox
    },
    {
        path: '/mynft/:type?',
        name: 'MyNFTSkin',
        meta: {
            gtm: 'UserCenter - NFT'
        },
        component: MyNFT
    },
    {
        path: '/mynfthead/:type?',
        name: 'MyNFTHead',
        meta: {
            gtm: 'UserCenter - NFT Profile'
        },
        component: MyNFTHead
    },
    {
        path: '/mynftrune/:type?',
        name: 'MyNFTRune',
        meta: {
            gtm: 'UserCenter - NFT Rune'
        },
        component: MyNFTRune
    },
    {
        path: '/pdf/:url?',
        name: 'pdf',
        meta: {
            gtm: 'View PDF'
        },
        component: pdfView

    },
    {
        path: '/guildcoop',
        name: 'Guildcoop',
        meta: {
            gtm: 'Guild Cooperation'
        },
        component: GuildCoop
    },
    {
        path: '/mysteryboxinfo/:id',
        name: 'MysteryBoxInfo',
        meta: {
            gtm: 'MysteryBox Detail'
        },
        component: MysteryBoxInfo
    },
    {
        path: '/homenew',
        name: 'HomeNew',
        meta: {
            gtm: 'Index New'
        },
        component: HomeNew
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            gtm: 'Index'
        },
        component: HomeNew
    },

    // {
    //   path: '/homeold',
    //   name: 'Homeold',
    //   meta: {
    //     gtm: 'Index'
    //   },
    //   component: Home
    // }
]

if (BLOCKCHAIN_CONFIG.isPlayGameOpen) {
    routes = routes.concat([
        {
            path: '/play',
            name: 'Play',
            meta: {
                gtm: 'Play Game'
            },
            component: Play
        },
        {
            path: '/login',
            name: 'Login',
            meta: {
                gtm: 'Login'
            },
            component: Login
        },
        {
            path: '/register',
            name: 'Register',
            meta: {
                gtm: 'Register'
            },
            component: Register
        },
        {
            path: '/forgetpass',
            name: 'Forgetpass',
            meta: {
                gtm: 'Forget Password'
            },
            component: Forgetpass
        },
        {
            path: '/resetpass',
            name: 'Resetpass',
            meta: {
                gtm: 'Reset Password'
            },
            component: Resetpass
        }])

}

if (BLOCKCHAIN_CONFIG.isMarketPlaceOpen) {
    routes = routes.concat([
        {
            path: '/usercenter',
            name: 'UserCenter',
            meta: {
                gtm: 'UserCenter'
            },
            component: UserCenter
        },
        {
            path: '/marketplace/trending',
            name: 'MarketplaceRecommend',
            meta: {
                gtm: 'Marketplace - Trending'
            },
            component: Recommend
        },
        {
            path: '/marketplace/buynft/:closefilter?',
            name: 'MarketplaceBuyNFT',
            meta: {
                gtm: 'Marketplace - BuyNFT'
            },
            component: BuyNFT
        },
        {
            path: '/marketplace/rentnft',
            name: 'Marketplace - RentNFT',
            meta: {
                gtm: 'MarketplaceRentNFT'
            },
            component: RentNFT
        },
        {
            path: '/marketplace/borrowmc',
            name: 'MarketplaceBorrowMC',
            meta: {
                gtm: 'Marketplace - BorrowMC'
            },
            component: BorrowMC
        },
        // {
        //     path: '/marketplace',
        //     name: 'Marketplace',
        //     meta: {
        //         gtm: 'Marketplace'
        //     },
        //     component: MarketPlace
        // },
    ])
}

routes = routes.concat([
    {
        path: '/404',
        name: '404',
        meta: {
            gtm: 'Page 404'
        },
        component: Page404
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
])


const router: Router = createRouter({
    history: createWebHashHistory(),
    routes
})

const defaultTitle = 'Tap Fantasy - ';
router.beforeEach((to, from, next) => {
    document.title = defaultTitle + to.meta.gtm
    next();
});

trackRouter(router)

export default router
