<template>
    <div class="mc-issuance-wrap">
        <div class="mc-issuance">

            <div class="title font-LondonTwo">
                <img src="/static/images/page1_mc_icon.svg" />
                $MC Issuance
            </div>

            <div class="desc-warp">
                <div class="text-desc">
                    {{ $t('HomeNew.mc-issuance-title') }}
                </div>

            </div>

            <div class="issuance-info-wrap">
                <div>
                    <div class="title">MC Price Monitoring</div>
                    <!-- chart -->
                    <div class="chart-wrap">
                        <v-chart :option="getOption()" autoresize></v-chart>
                    </div>
                </div>
                <div>
                    <div class="title">Issuance Exclusive Channel</div>

                    <div class="channel-button">
                        <!-- <Lock style="width: 20px; height: 20px; margin-right: 10px; margin-top: -5px;" /> -->
                        No Issuance
                    </div>
                    <p class="channel-desc">Conditions for issuance not satisfied!</p>
                </div>

                <div>
                    <div class="title">Liquidity Pool
                        <Tooltip
                            :message="['MC/USDT on PancakeSwap:', `Price: $${props.data.price}`, `Liquidity: $${props.data.tvl}`]" />

                    </div>
                    <div class="liquidity-text">
                        ${{ props.data.tvl }}
                    </div>
                </div>
            </div>
            <!-- <div class="bottom-wrap">

                <BuyMcButtom />
            </div>
            <div class="mc-pool">
                <div class="title font-LondonTwo">
                    <img src="/static/images/page1_mc_icon.svg" />
                    MC Pool
                </div>
                <div class="pool-info" v-if="props.data.isShow || isMobile">
                    <div class="item">
                        <div class="text">$<count-to :endVal='parseFloat( props.data.price)' :decimals="getDecimal( props.data.price)" :suffix="getSuffix( props.data.price)"/></div>
                        <div class="desc">MC {{ $t('HomeNew.token-price') }}</div>
                    </div>
                    <div class="item">
                        <div class="text"><count-to :endVal='parseFloat( props.data.tvl)' :decimals="getDecimal( props.data.tvl)" :suffix="getSuffix( props.data.tvl)"/></div>
                        <div class="desc">{{ $t('HomeNew.tvl') }}</div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
// import { getDecimal, getSuffix } from '../utils/index'
// import BuyMcButtom from '@/views/components/BuyMcButtom.vue'
import { cloneDeep } from 'lodash'
import { MC_PRICE_MONITORING_OPTION } from '../config/chartOption'
import Tooltip from '../../components/Tooltip.vue'

const isMobile = window.innerWidth < 1140
const standardPrice = 1.1

const props = defineProps<{
    data: {
        price: string,
        tvl: string,
        isShow: boolean
    }
}>()

const getOption = () => {
    let chartData = cloneDeep(MC_PRICE_MONITORING_OPTION)

    chartData.yAxis[0].data = ['Standrad Price', 'Current Price']
    chartData.series[0].data = [standardPrice, Number(props.data.price)]
    return chartData

}

</script>

<style scoped lang="stylus">


@keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}
    .mc-issuance-wrap{
        padding: 70px 30px;
        .mc-issuance {
            .title {
                img {
                    width: 35px;
                    height: 45px;
                    margin-top: -5px;
                }
                font-size: 40px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 40px;
            }
            .desc-warp{
                margin-top: 70px;
                .text-desc {
                    font-family: Avenir-Light;
                    font-size: 20px;
                    color: #FFFFFF;
                    text-align: left;
                    line-height: 25px;
                    &>span {
                        display: block;
                        margin-top: 20px;
                    }
                    &:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }
            }

            .issuance-info-wrap{
                margin-top: 88px;
                display: flex;
                justify-content: space-between;
                &>div{
                    width: 276px;
                    height: 200px;
                    background: #282828 url(/static/images/page1_mc_issance-wrap1.svg) no-repeat;
                    background-size: 278px 200px;
                    border-radius: 5px;
                    .title{
                        font-family: Avenir-Black;
                        font-size: 16px;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 25px;
                        margin-top: 20px;
                    }
                }
                .chart-wrap{

                    width: 90%;
                    height: 180px;
                    min-height: 160PX;
                    margin: 10px auto;
                }

                .channel-button {
                    width: 200px;
                    height: 50px;
                    background-color: #625E7E;
                    color: #BFBDCA;
                    border: 2px solid #7F7C97;
                    border-radius: 6px;
                    font-family: Avenir-Black;
                    font-size: 16px;
                    text-align: center;
                    line-height: 50px;
                    user-select: none;
                    margin: 40px auto;
                    margin-bottom: 0;
                }
                .channel-desc {
                    font-family: Avenir-Light;
                    margin-top: 20px;
                    font-size: 14px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 18px;
                }
                .liquidity-text {
                    margin: 40px auto;
                    text-align: center;
                    font-family: Avenir-Black;
                    font-size: 35px;
                    color: #B7ADFF;
                }
            }

            /**
            .bottom-wrap {
                margin-top: 45px;

            }
            .mc-pool {
                margin-top: 66px;
                .pool-info {
                    margin: 0 auto;
                    margin-top: 45px;
                    width: 515px;
                    height: 80px;
                    background: url(/static/images/page1_border_issuance_wrap.svg) no-repeat;
                    background-size: 515px 80px;
                    display: flex;
                    align-items: center;
                    justify-content space-around;
                    // .item {
                    //     .text {
                    //         font-family: Impact;
                    //         font-size: 26px;
                    //         color: #FFC800;
                    //         letter-spacing: 0;
                    //         text-align: center;
                    //         line-height: 25px;
                    //         padding-bottom: 5px;
                    //     }
                    //     .desc {
                    //         font-family: Avenir-Light;
                    //         font-size: 14px;
                    //         color: #FFFFFF;
                    //         letter-spacing: 0;
                    //         text-align: center;
                    //         line-height: 1;
                    //     }
                    // }
                }
            }
             */
        }
    }
</style>

<style scoped lang="stylus">

@media screen and (max-width: 1140px) {
    .mc-issuance-wrap{
        overflow: hidden;
        padding: 0;
        .mc-issuance{
            .title{
                padding-top: 30rem;
                img {
                    width: 27rem;
                    height: 36rem;
                    margin-top: -5rem;
                }
                font-size: 24rem;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 24rem;
            }

            .desc-warp{
                margin: 0 auto;
                width: 326rem;
                margin-top: 30rem;
                .text-desc {


                    text-align: left;
                    // width: 326rem;
                    font-size: 14rem;
                    color: #FFFFFF;
                    line-height: 16rem;
                    // float left;
                    &>span {
                        display: inline;
                    }

                }
            }
            .issuance-info-wrap {
                flex-direction: column;
                margin-top: 30rem;
                text-align: center;
                // justify-content: space-between;
                align-items: center;

                &>div{
                    margin-top: 20rem;
                    width: 250rem;
                    height: 180rem;
                    background-size: 250rem 180rem;
                    overflow: hidden;
                    border-radius: 5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    .title{
                        font-size: 16rem;
                        letter-spacing: 0;
                        line-height: 16rem;
                        margin-top: 10rem;
                        padding-top: 64rem;
                    }
                    &:not(:first-child){
                        .title{
                            // margin-top: 0;
                            padding-top: 0;
                        }
                    }
                    // &:nth-child(2){
                    //     .title{
                    //         margin-top: 10rem;
                    //     }
                    // }
                }

                .chart-wrap{

                    height: 180rem;
                    margin-top: 10rem;
                }

                .channel-button {
                    width: 200rem;
                    height: 50rem;
                    font-size: 16rem;
                    line-height: 50rem;
                    // margin: 40rem auto;
                    margin-bottom: 0;
                }
                .channel-desc {
                    font-size: 14rem;
                    margin-bottom: 10rem;
                }

                .liquidity-text{
                    font-size: 24rem;
                }
                
            }
            /**
            .bottom-wrap {
                margin-top: 35rem;
            }

            .mc-pool{
                margin-top: 60rem;
                .pool-info {
                    margin: 0 auto;
                    margin-top: 33rem;
                    width: 340rem;
                    height: 56rem;
                    background: url(/static/images/page1_border_issuance_wrap_mobile.svg) no-repeat;
                    background-size: 340rem 56rem;
                }
            }
             */
        }
    }
}

</style>
