<template>
    <div class="page-nftinfo height-header-padding" style="position: relative;height: auto">
        <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
            <modal-approve v-if="modalShowIndex === 3" :tokeninfo="getApproveTokenAndNeedAmount()" @confirm="approve()"
                @cancel="closeModal()" />
            <ModalActionResult v-else-if="modalShowIndex === 4" :action="actionResultInfo.action"
                :cardinfo="actionResultInfo.cardinfo" @confirm="confirm" @openbox="openbox" :wa="walletAddress" />
        </el-dialog>
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content boxinfopage">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                        fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>

                        <div class="d-flex">
                            <div class="left d-flex flex-column" style="width:300PX;flex: none;margin-right: 100PX;">
                                <img class="image-box" :src="boxData.boximage" />
                                <div class="box-info-line">
                                    <div class="tag">Supply</div>
                                    <div class="text">{{ boxData.stock }}</div>
                                </div>
                                <div class="box-info-line">
                                    <div class="tag">Network</div>
                                    <div class="text">BNB Chain</div>
                                </div>
                                <div class="box-info-line">
                                    <div class="tag">Contract</div>
                                    <div class="d-flex align-items-center" style="cursor: pointer;"
                                        @click="copyText(boxData.contract)">
                                        <div class="text" style="margin-right: 6PX;">{{
                                            boxData.contract.substr(0, 6) +
                                            '...' +
                                            boxData.contract.substr(walletAddress.length - 4, 4)
                                        }}</div>
                                        <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="user-center" transform="translate(-319.000000, -118.000000)"
                                                    stroke="#C7CAD5">
                                                    <g transform="translate(190.000000, 119.000000)">
                                                        <g transform="translate(130.000000, 0.000000)">
                                                            <rect x="0" y="0" width="6" height="8" rx="1"></rect>
                                                            <rect fill="#464454" x="4" y="3" width="6" height="8" rx="1">
                                                            </rect>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>

                                </div>
                            </div>
                            <div class="right d-flex flex-column align-items-center" style="flex: 1;">
                                <div class="box-title">{{ boxData.boxname }}</div>
                                <div class="info-block d-flex justify-content-between" style="width:100%">
                                    <span class="title">Today's Available Amount</span>
                                    <span class="text forsale">{{ getTodayAvailable() }}</span>
                                </div>
                                <div class="info-block d-flex justify-content-between" style="width:100%">
                                    <span class="title">Price</span>
                                    <span class="text forsale">{{ boxData.price }} {{ boxData.paysymbol }}</span>
                                </div>
                                <div class="info-block d-flex justify-content-between align-items-center"
                                    style="padding: 0 15PX;width:100%">
                                    <span class="title">Purchase Quantity</span>
                                    <div class="max-block d-flex align-items-center justify-content-start"
                                        style="margin-top: 0;">
                                        <el-input-number style="margin-left: 0PX;" :disabled="getBoxState() !== 1"
                                            v-model="buyAmount" :min="1" :max="getTodayAvailable()"
                                            @change="watchInput()" />
                                        <button class="max maxbutton" :disabled="getBoxState() !== 1" @click="maxInput">{{
                                            $t('Common.max')
                                        }}</button>
                                    </div>
                                </div>
                                <!-- <div class="box-info-block">
                                <div></div>
                                <a target="_blank"
                                    :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' + boxData['pay']">{{
                                            boxData.paysymbol
                                    }} / USDT Exchange</a>
                            </div> -->

                                <template v-if="boxData.periodtime > 0">
                                    <template v-if="getBoxState() === 2 || getBoxState() === 3">
                                        <div class="countdownbar">
                                            <div class="return-time">
                                                <div class="return-time-item">
                                                    <div class="number-block">
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[1].toString().charAt(0) }}
                                                        </div>
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[1].toString().charAt(1) }}
                                                        </div>
                                                    </div>
                                                    <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                                </div>
                                                <div class="return-time-item">
                                                    <div class="number-block">
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[2].toString().charAt(0) }}
                                                        </div>
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[2].toString().charAt(1) }}
                                                        </div>
                                                    </div>
                                                    <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                                </div>
                                                <div class="return-time-item">
                                                    <div class="number-block">
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[3].toString().charAt(0) }}
                                                        </div>
                                                        <div class="number">
                                                            {{ urSellCountDownTimeArr[3].toString().charAt(1) }}
                                                        </div>
                                                    </div>
                                                    <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="countdown-memo">Today's limit has been sold out.</span>
                                    </template>
                                    <template v-else>
                                        <button class="button-buy nftinfo boxinfopage" @click="openTopbox(buyAmount)"
                                            :disabled="getBoxState() === 0">
                                            {{ boxData['price'] * buyAmount }} {{
                                                boxData['paysymbol']
                                            }}
                                        </button>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-if="boxData['stock'] > -1">
                                        <!-- <template v-if="walletwhitelist.includes(boxInfo['white'])"> -->
                                        <button class="button-buy nftinfo boxinfopage"
                                            v-if="getBoxState() === 2 || getBoxState() === 3" disabled>
                                            <template v-if="getBoxState() === 3">{{ $t('MysteryBox.sold-out')
                                            }}</template>
                                            <template v-else>{{ $t('MysteryBox.button_ended') }}</template>
                                        </button>
                                        <template v-else>
                                            <button class="button-buy nftinfo boxinfopage" @click="openTopbox(buyAmount)"
                                                :disabled="getBoxState() === 0">
                                                {{ boxData['price'] * buyAmount }} {{
                                                    boxData['paysymbol']
                                                }}
                                            </button>
                                        </template>
                                        <!-- </template>
                                <template v-else>
                                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                                        <button class="button-buy mbbuyinfo">{{ $t('MysteryBox.how-to-buy') }}</button>
                                    </a>
                                </template> -->
                                    </template>
                                    <template v-else>
                                        <!-- <template v-if="walletwhitelist.includes(boxInfo['white'])"> -->
                                        <button :disabled="getBoxState() === 0" class="button-buy nftinfo boxinfopage"
                                            @click="openTopbox(buyAmount)">
                                            {{ boxData['price'] * buyAmount }} {{
                                                boxData['paysymbol']
                                            }}
                                        </button>
                                        <!-- </template>
                                <template v-else>
                                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                                        <button class="button-buy mbbuyinfo">{{ $t('MysteryBox.how-to-buy') }}</button>
                                    </a>
                                </template> -->
                                    </template>
                                </template>

                                <div class="box-ps-block">Starting from March 1st, 100 limited mystery boxes will open
                                    for
                                    sale
                                    every day from 9 am.</div>
                            </div>
                        </div>
                        <div class="box-content-title">Mystery Box Content</div>
                        <swiper navigation :pagination="true" :modules="modules" class="box-content-list">
                            <swiper-slide v-for="(itemContent, itemContentIndex) of boxContentListData"
                                :key="'box-content-' + itemContentIndex" class="content-item">
                                <div class="desc-block">
                                    <img class="image-level"
                                        :src="'/static/images/element_level_' + getLowerCase(itemContent.rarity) + '.png'" />
                                    <div class="text-level">Probabllity {{ itemContent.probabllity }}%</div>
                                </div>
                                <div class="image-card-list">
                                    <template v-for="(itemImage, itemImageIndex) of itemContent.cardlist">
                                        <template v-if="itemContent.cardlist.length >= 3">
                                            <template v-if="itemImageIndex <= 2">
                                                <div class="card-item">
                                                    <img :src="itemImage" />
                                                    <div class="price-block">
                                                        <img src="/static/images/icon-mystery-box-mc.png" />
                                                        <div class="price">About {{ itemContent.mc }} MC</div>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <div class="card-item">
                                                <img :src="itemImage" />
                                                <div class="price-block">
                                                    <img src="/static/images/icon-mystery-box-mc.png" />
                                                    <div class="price">About {{ itemContent.mc }} MC</div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </swiper-slide>
                        </swiper>

                    </div>
                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{{ $t('Dialog.back') }}</span>
                </div>

                <div class="box-image-block">
                    <img :src="boxData.boximage" class="box-image" />
                </div>
                <template v-if="boxData.periodtime > 0">
                    <template v-if="getBoxState() === 2 || getBoxState() === 3">
                        <div class="countdownbar">
                            <div class="return-time">
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[1].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[1].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.hrs') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[2].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[2].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.mins') }}</div>
                                </div>
                                <div class="return-time-item">
                                    <div class="number-block">
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[3].toString().charAt(0) }}
                                        </div>
                                        <div class="number">
                                            {{ urSellCountDownTimeArr[3].toString().charAt(1) }}
                                        </div>
                                    </div>
                                    <div class="time-unit-text">{{ $t('Common.secs') }}</div>
                                </div>
                            </div>
                        </div>
                        <span class="countdown-memo">Today's limit has been sold out.</span>
                    </template>
                    <template v-else>
                        <button class="button-buy nftinfo full" @click="openTopbox(buyAmount)"
                            :disabled="getBoxState() === 0">
                            {{ boxData['price'] * buyAmount }} {{
                                boxData['paysymbol']
                            }}
                        </button>
                    </template>
                </template>
                <template v-else>
                    <template v-if="boxData['stock'] > -1">
                        <!-- <template v-if="walletwhitelist.includes(boxInfo['white'])"> -->
                        <button class="button-buy nftinfo full" v-if="getBoxState() === 2 || getBoxState() === 3" disabled>
                            <template v-if="getBoxState() === 3">{{ $t('MysteryBox.sold-out')
                            }}</template>
                            <template v-else>{{ $t('MysteryBox.button_ended') }}</template>
                        </button>
                        <template v-else>
                            <button class="button-buy nftinfo full" @click="openTopbox(buyAmount)"
                                :disabled="getBoxState() === 0">
                                {{ boxData['price'] * buyAmount }} {{
                                    boxData['paysymbol']
                                }}
                            </button>
                        </template>
                        <!-- </template>
                                <template v-else>
                                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                                        <button class="button-buy mbbuyinfo">{{ $t('MysteryBox.how-to-buy') }}</button>
                                    </a>
                                </template> -->
                    </template>
                    <template v-else>
                        <!-- <template v-if="walletwhitelist.includes(boxInfo['white'])"> -->
                        <button :disabled="getBoxState() === 0" class="button-buy nftinfo full"
                            @click="openTopbox(buyAmount)">
                            {{ boxData['price'] * buyAmount }} {{
                                boxData['paysymbol']
                            }}
                        </button>
                        <!-- </template>
                                <template v-else>
                                    <a href="https://discord.gg/tapfantasy" style="width: 100%;" target="_blank">
                                        <button class="button-buy mbbuyinfo">{{ $t('MysteryBox.how-to-buy') }}</button>
                                    </a>
                                </template> -->
                    </template>
                </template>
                <div class="box-ps-block">Starting from March 1st, 100 limited mystery boxes will open for
                    sale
                    every day from 9 am.</div>
                <div class="info-block d-flex justify-content-between" style="margin-top: 0;">
                    <span class="title">Today Available</span>
                    <span class="text">{{ boxData.periodstock - boxData.periodsold }}</span>
                </div>
                <div class="info-block d-flex justify-content-between" style="width:100%">
                    <span class="title">Price</span>
                    <span class="text forsale">{{ boxData.price }} {{ boxData.paysymbol }}</span>
                </div>
                <div class="info-block d-flex justify-content-between align-items-center"
                    style="padding: 0 15PX;width:100%;margin-bottom: 22PX;">
                    <span class="title">Purchase Quantity</span>
                    <div class="max-block d-flex align-items-center justify-content-start">
                        <el-input-number style="margin-left: 0PX;" :disabled="getBoxState() !== 1" v-model="buyAmount"
                            :min="1" :max="getTodayAvailable()" @change="watchInput()" />
                        <button class="max maxbutton" :disabled="getBoxState() !== 1" @click="maxInput">{{
                            $t('Common.max')
                        }}</button>
                    </div>
                </div>
                <div class="box-info-line">
                    <div class="tag">Supply</div>
                    <div class="text">{{ boxData.stock }}</div>
                </div>
                <div class="box-info-line">
                    <div class="tag">Network</div>
                    <div class="text">BNB Chain</div>
                </div>
                <div class="box-info-line">
                    <div class="tag">Contract</div>
                    <div class="d-flex align-items-center" style="cursor: pointer;" @click="copyText(boxData.contract)">
                        <div class="text" style="margin-right: 6PX;">{{
                            boxData.contract.substr(0, 6) +
                            '...' +
                            boxData.contract.substr(walletAddress.length - 4, 4)
                        }}</div>
                        <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="user-center" transform="translate(-319.000000, -118.000000)" stroke="#C7CAD5">
                                    <g transform="translate(190.000000, 119.000000)">
                                        <g transform="translate(130.000000, 0.000000)">
                                            <rect x="0" y="0" width="6" height="8" rx="1"></rect>
                                            <rect fill="#464454" x="4" y="3" width="6" height="8" rx="1">
                                            </rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                </div>
                <div class="box-content-title">Mystery Box Content</div>
                <swiper :pagination="true" :modules="modules" class="box-content-list">
                    <swiper-slide v-for="(itemContent, itemContentIndex) of boxContentListData"
                        :key="'box-content-' + itemContentIndex" class="content-item">
                        <div class="desc-block">
                            <img class="image-level"
                                :src="'/static/images/element_level_' + getLowerCase(itemContent.rarity) + '.png'" />
                            <div class="text-level">Probabllity {{ itemContent.probabllity }}%</div>
                        </div>
                        <div class="image-card-list" style="justify-content: center;">
                            <template v-for="(itemImage, itemImageIndex) of itemContent.cardlist">
                                <template v-if="itemContent.cardlist.length > 2">
                                    <template v-if="itemImageIndex <= 1">
                                        <div class="card-item">
                                            <img :src="itemImage" />
                                            <div class="price-block">
                                                <!-- <img src="/static/images/icon-mystery-box-mc.png" /> -->
                                                <div class="price">About {{ itemContent.mc }} MC</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="card-item">
                                        <img :src="itemImage" />
                                        <div class="price-block">
                                            <!-- <img src="/static/images/icon-mystery-box-mc.png" /> -->
                                            <div class="price"> About {{ itemContent.mc }} MC</div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </template>
    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateEvents } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElMessage } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
// import Pagination1 from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
// import TapDesktop from '../abis/TapDesktop.json'
import ERC20 from '../abis/ERC20.json'
import NFTMysteryKey from '../abis/NFTMysteryKey.json'
import NFTMysteryKeyBuyer from '../abis/NFTMysteryKeyBuyer.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import ERC721 from '../abis/ERC721.json'
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import i18n from '@/i18n'

interface BoxBuyItemInterface {
    buyer: string
    buytime: number
    amount: number
    price: number
    paysymbol: string
}

interface BoxContentItemInterface {
    rarity: string
    rarity_num: number
    probabllity: number
    mc: number
    cardlist: Array<string>
}

const boxDataFake = {
    "id": 5,
    "boxid": 38,
    "boxname": "Super Rare NFTs",
    "boximage": "https://images.tapfantasy.io/nft/preview/box-diamondbox.png",
    "white": 0,
    "activity": "",
    "stock": 2387,
    "sold": 613,
    "starttime": 0,
    "endtime": 1652004000,
    "pay": "0xA5638DE1D35B3A69230FEB227118Fd7F483204d8",
    "price": 35,
    "priceusd": 100,
    "paysymbol": "MC",
    "paydecimals": 18,
    "payapprove": 0,
    "buyAmount": 1,
    "paybalance": 0,
}

const boxContentDataFake = [{
    "edition": "xxxx",
    "rank": "UR",
    "amount": 5,
    "probabllity": 5
}, {
    "edition": "xxxx",
    "rank": "SP",
    "amount": 10,
    "probabllity": 10
}, {
    "edition": "xxxx",
    "rank": "SSR",
    "amount": 15,
    "probabllity": 15
}, {
    "edition": "xxxx",
    "rank": "SR",
    "amount": 35,
    "probabllity": 35
}, {
    "edition": "xxxx",
    "rank": "R",
    "amount": 35,
    "probabllity": 35
}]

export default defineComponent({
    name: 'MysteryBoxInfo',
    components: {
        ElButton, ElLoading, Swiper,
        SwiperSlide,
    },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        const serverHost = ref(BLOCKCHAIN_CONFIG.serverHost)
        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const boxOpenId = ref(-1)
        const boxOpenCatIndex = ref(-1)
        const inviteCode = ref('')
        const inviteBuyCode = ref('')
        const invitedBuyBoxCount = ref(0)
        const inviterBoardList = ref([])
        const contractBoxAssetsAddress = ref('')
        const radioIndex = ref('0')
        const pagePerCount = ref(20)
        const pageNow = ref(1)
        const recordCount = ref(0)
        const isLoadingMore = ref(false)
        const refMyBoxListMobile: Ref<any> = ref(null)
        const selecedBoxIndex = ref(-1)
        const inputWalletAddressTransfer = ref('')
        let boxId = 0
        let updateCurrentTimeTask: NodeJS.Timer | null = null

        // LogUtils.debug(`invitebuycode: ${inviteBuyCode.value}`)
        const isMobile = ref(false)
        // let chainId = -1
        const chainSymbol = ''

        const tokenObject = ref({
            MC: {
                balance: 0,
                symbol: 'MC',
                address: ''
            },
            TAP: {
                balance: 0,
                symbol: 'TAP',
                address: ''
            }
        })

        const walletwhitelist: Ref<number[]> = ref([])

        const currentTime = ref(0)
        let currentBlockNo = 0

        const buyAmount = ref(1)

        let mysteryBoxAPIInfo = {
            chainid: 0,
            contract: '',
            assets: '',
            white: []
        }

        const actionResultInfo = ref({
            action: -1,
            cardinfo: {
                tokenid: 0,
                typeid: 0,
                name: '',
                rarity: '',
                preview: '',
                price: 0,
                pricesymbol: '',
                amount: 0,
                address: ''
            }
        })

        const boxData: Ref<{
            contract: string
            id: number
            boxid: number
            itemid: number
            boxname: string
            boximage: string
            white: number
            activity: string
            revertrate: number
            periodstock: number
            periodsold: number
            periodtime: number
            stock: number
            sold: number
            starttime: number
            endtime: number
            pay: string
            price: number
            paysymbol: string
            paydecimals: number
            payapprove: number
            buyAmount: number
            paybalance: number
        }> = ref({
            contract: '',
            id: 0,
            boxid: 0,
            itemid: 0,
            boxname: "",
            boximage: "",
            white: 0,
            activity: "",
            revertrate: 0,
            periodstock: 0,
            periodsold: 0,
            periodtime: 0,
            stock: 0,
            sold: 0,
            starttime: 0,
            endtime: 0,
            pay: "",
            price: 0,
            paysymbol: "",
            paydecimals: 0,
            payapprove: 0,
            buyAmount: 0,
            paybalance: 0,
        })

        const boxContentListData: Ref<BoxContentItemInterface[]> = ref([])
        const boxBuyListData: Ref<BoxBuyItemInterface[]> = ref([])

        const boxContentListIdx = ref(0)
        const urSellCountDownTimeArr: Ref<(string | number)[]> = ref([])


        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })
            const boxid = router.currentRoute.value.params.id
            if (typeof (boxid) !== "undefined" && boxid.length > 0) {
                boxId = Number(boxid)
            }
            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            if (updateCurrentTimeTask !== null) {
                clearInterval(Number(updateCurrentTimeTask))
                updateCurrentTimeTask = null
            }
        })

        const goBack = () => {
            router.back()
        }

        const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
            TOOL.gtmTrack(event, category, action, label, value)
        }

        const getTodayAvailable = () => {
            const box = boxData.value
            if (box.periodtime > 0) {
                const available = box.stock - box.sold
                const todayAvailable = box.periodstock - box.periodsold
                return Math.min(available, todayAvailable)
            } else {
                return box.stock - box.sold
            }
        }

        const copyText = (content: string) => {
            toClipboard(content);
            ElMessage({
                message: t("Infotip.contract-address-copied"),
                type: "success",
            });
        };

        const go2MyBoxListMobile = () => {
            if (refMyBoxListMobile.value) {
                refMyBoxListMobile.value.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center'
                })
            }
        }

        const getTXLink = (hash: string) => {
            return pageChainConfig.chainBlockchainBrowserURLConfig + hash
        }

        //0-incomming 1-onsale 2-ended 3-soldout
        const getBoxState = () => {
            const box = boxData.value

            if (box.periodtime > 0) {
                if (getTodayAvailable() === 0) {
                    return 3
                } else {
                    return 1
                }
            } else {
                if (box['stock'] !== -1) {
                    if (box['stock'] === 0) {
                        return 3
                    } else {
                        if (box['endtime'] > 0 && currentTime.value >= box.endtime) {
                            return 2
                        } else if (box['starttime'] > 0 && currentTime.value < box['starttime']) {
                            return 0
                        }
                    }
                    return 1
                } else {
                    if (box['starttime'] > 0 && currentTime.value < box['starttime']) {
                        return 0
                    }
                    return 1
                }
            }


        }

        const formatTime = (secs: number) => {
            return moment(secs * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const countDownTime = (secs: number) => {
            return TOOL.dateTransform1(secs)
        }

        const getPeriodCountDown = (secs: number) => {
            const countdownTime = boxData.value.periodtime - secs % boxData.value.periodtime
            if (countdownTime === 0) {
                getBoxDataAPI()
            } else {
                urSellCountDownTimeArr.value = TOOL.dateTransformLong(countdownTime)
            }
        }

        const getBoxDataAPI = async () => {
            const urlBoxInfo = `${API.mysteryBoxInfo}${pageChainConfig.chainId}/${boxId}`
            const boxInfoAPIData = (await axios.get(urlBoxInfo)).data.data

            boxData.value = boxInfoAPIData
        }

        const clearNoNum = (numStr: string) => {
            numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
            numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
            // numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            // numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            // numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const watchInput = () => {
            const box = boxData.value

            let inputNum = buyAmount.value
            // const available = box.stock - box.sold
            let maxBuy = 0
            if (box.periodtime > 0) {
                maxBuy = getTodayAvailable()
            } else {
                maxBuy = box.stock === -1 ? 99 : (box.stock > 99 ? 99 : box.stock)
            }
            if (inputNum > maxBuy) {
                inputNum = maxBuy
            }
            buyAmount.value = inputNum

        }

        const maxInput = () => {
            const box = boxData.value
            // const available = box.stock - box.sold
            let maxBuy = 0
            if (box.periodtime > 0) {
                maxBuy = getTodayAvailable()
            } else {
                maxBuy = box.stock === -1 ? 99 : (box.stock > 99 ? 99 : box.stock)
            }

            buyAmount.value = maxBuy
        }

        const copyTextInvitation = (content: string) => {
            toClipboard(content)
            ElMessage({ message: 'Invitation link copied!', type: 'success' })
        }

        const getBoxPreviewName = (url: string) => {
            const res = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'))
            // LogUtils.debug(res)
            return res
        }

        const showModal = (index: number) => {
            isShowModal.value = true
            modalShowIndex.value = index

        }

        const closeModal = () => {
            isShowModal.value = false
            inputWalletAddressTransfer.value = ''
            modalShowIndex.value = -1
            boxOpenId.value = -1
            selecedBoxIndex.value = -1
            actionResultInfo.value = {
                action: -1,
                cardinfo: {
                    tokenid: 0,
                    typeid: 0,
                    name: '',
                    rarity: '',
                    preview: '',
                    price: 0,
                    pricesymbol: '',
                    amount: 0,
                    address: ''
                }
            }
        }

        const confirm = () => {
            closeModal()
        }

        const openbox = () => {
            closeModal()
            router.push({
                name: 'Mysterybox',
                params: {
                    gotoopenbox: 1,
                }
            })
        }

        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }

        const getApproveTokenAndNeedAmount = () => {
            const ele = boxData.value
            const tokenInfo = {
                address: ele.pay,
                symbol: ele.paysymbol,
                decimals: ele.paydecimals
            }
            const payToken = tokenInfo

            return {
                symbol: payToken.symbol,
                amount: new BigNumber(buyAmount.value).multipliedBy(ele.price).toNumber()
            }
        }

        const checkApprove = async () => {
            const ele = boxData.value
            const tokenInfo = {
                address: ele.pay,
                symbol: ele.paysymbol,
                decimals: ele.paydecimals
            }
            const payToken = tokenInfo
            const contractAddr = mysteryBoxAPIInfo.contract

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount().amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approve = async () => {
            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request')
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const ele = boxData.value
                    const tokenInfo = {
                        address: ele.pay,
                        symbol: ele.paysymbol,
                        decimals: ele.paydecimals
                    }

                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        tokenInfo.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': tokenInfo.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = 0
                    contractToken
                        .approve(mysteryBoxAPIInfo.contract, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.allowance(
                                        walletAddress.value,
                                        mysteryBoxAPIInfo.contract
                                    )

                                    if (boxData.value.pay.toLowerCase() === tokenInfo.address.toLowerCase()) {
                                        boxData.value.payapprove = approveBN / (10 ** tokenInfo.decimals)
                                    }


                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': tokenInfo.symbol })
                                    })
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success')
                                    closeModal()
                                    openTopbox(buyAmount.value)
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade')
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': tokenInfo.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request')
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': tokenInfo.symbol })
                            })
                        })
                }
            }
        }

        const openTopbox = async (count: number = 1, openBox: boolean = false) => {
            const flagApprove = await checkApprove()
            if (!flagApprove) {
                // createNotification({
                //     type: 'error',
                //     message: `Your token approve count is not enough`,
                // })
                showModal(3)
                return
            }
            TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-request')
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const box = boxData.value
                // LogUtils.debug(box)
                const boxCount = Number(count).toString()
                const boxPrice = Number(box['price'])
                const boxPriceAll = boxPrice * Number(boxCount)

                const ele = box
                const tokenInfo = {
                    address: ele.pay,
                    symbol: ele.paysymbol,
                    decimals: ele.paydecimals,
                    balance: ele.paybalance
                }

                const action = openBox ? t('Infotip.action-open') : t('Infotip.action-buy')
                const actioning = openBox ? t('Infotip.action-opening') : t('Infotip.action-buying')

                if (buyAmount.value === 0) {
                    createNotification({
                        type: 'error',
                        message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Infotip.error') })}${t('Common.colon')} ${t('Infotip.buy-amount-can-not-be-zero')}${t('Common.exclamation mark')}`
                    })
                    return
                }

                if (tokenInfo.balance < boxPriceAll) {
                    createNotification({
                        type: 'error',
                        message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Infotip.error') })}${t('Common.colon')} ${t('Infotip.your-token-balance-not-enough', { 'symbol': tokenInfo.symbol })}${t('Common.exclamation mark')}`
                    })
                    return
                }

                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractBoxBuyer = new ethers.Contract(
                            mysteryBoxAPIInfo.contract,
                            NFTMysteryKeyBuyer,
                            signer
                        )
                        // LogUtils.debug(`id: ${box['id']}`)
                        const sendPromise = contractBoxBuyer.onBuyBatch(box.itemid, count, openBox)


                        const uuid = createNotification({
                            message: `${t('Infotip.action-open-buy-mysterybox', { 'action': actioning, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                //上报
                                if (inviteBuyCode.value.length > 0) {
                                    const hash = transaction.hash
                                    const url = `${API.userBlindboxInviteCodeUploadByHash}${pageChainConfig.chainId}/${hash}/${inviteBuyCode.value}/`
                                    axios.get(url)
                                }

                                transaction.wait().then(function (transactionResult: any) {
                                    const blockRate = TOOL.getDynamicObject(pageChainConfig.chainSymbol, BLOCKCHAIN_CONFIG.BLOCK_RATE)
                                    setTimeout(() => {
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-success')
                                        if (openBox) {
                                            // showTopbox(transactionResult.transactionHash)
                                        } else {
                                            createNotification({
                                                type: 'success',
                                                message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.successful') })}`
                                            })
                                            const ar = actionResultInfo.value
                                            ar.action = 0
                                            ar.cardinfo.name = boxData.value.boxname
                                            ar.cardinfo.preview = boxData.value.boximage
                                            ar.cardinfo.amount = buyAmount.value
                                            ar.cardinfo.price = boxData.value.price * buyAmount.value
                                            ar.cardinfo.address = boxData.value.contract
                                            ar.cardinfo.pricesymbol = boxData.value.paysymbol
                                            showModal(4)
                                            loadData()
                                        }
                                    }, 2 * 1000 * blockRate)
                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.error') })}`,
                                    duration: 5
                                })
                            })
                    } catch (e) {
                        TOOL.gtmTrack('buybox-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buybox-error-request')
                        console.error(e)
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-open-buy-mysterybox', { 'action': action, 'boxCount': boxCount, 'boxname': box['boxname'], 'state': t('Common.error') })}`,
                        })
                    }
                }
            }
        }

        // const showboxContent = (index: number) => {
        //     showModal(1, boxTypeInfoList.value[index])
        // }

        // const showTopBoxContent = (index: number) => {
        //     let boxResult
        //     const boxTop = boxList.value[index]
        //     for (const box of boxTypeInfoList.value) {
        //         if (boxTop['boxid'] === box.boxid) {
        //             boxResult = box
        //         }

        //     }
        //     showModal(1, boxResult)
        // }

        const random = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        };

        const loadData = async () => {
            buyAmount.value = 1
            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)

            // const { walletAddress } = toRefs(props)

            const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)
            const currentBlock = (await jsonRPCProvider.getBlock('latest'))

            currentTime.value = currentBlock.timestamp


            const urlBoxInfo = `${API.mysteryBoxInfo}${chainId}/${boxId}`
            const boxInfoAPIData = (await axios.get(urlBoxInfo)).data.data

            mysteryBoxAPIInfo = boxInfoAPIData
            boxContentListData.value = boxInfoAPIData.boxcontent



            if (boxInfoAPIData.periodtime > 0) {
                if (updateCurrentTimeTask === null) {
                    updateCurrentTimeTask = setInterval(() => {
                        currentTime.value = currentTime.value + 1
                        const secs = currentTime.value - boxData.value.starttime
                        getPeriodCountDown(secs)
                    }, 1000)
                }
            }

            const { walletAddress } = toRefs(props)

            boxInfoAPIData.payapprove = 0
            boxInfoAPIData.paybalance = 0

            if (walletAddress.value !== '') {
                let calls: any[] = []
                let returnValue = null

                calls = calls.concat([
                    {
                        target: boxInfoAPIData.pay,
                        call: ['balanceOf(address)(uint256)', walletAddress.value],
                        returns: [['PAYTOKENBOX_BALANCE', (val: any) => val / (10 ** boxInfoAPIData.paydecimals)]]
                    },
                    {
                        target: boxInfoAPIData.pay,
                        call: [
                            'allowance(address,address)(uint256)',
                            walletAddress.value,
                            mysteryBoxAPIInfo.contract
                        ],
                        returns: [['PAYTOKENBOX_APPROVE', (val: any) => val / (10 ** boxInfoAPIData.paydecimals)]]
                    }])

                returnValue = await aggregateEvents(calls, pageChainConfig.multicallConfig)
                const returnResult = returnValue.results.transformed

                boxInfoAPIData.payapprove = returnResult.PAYTOKENBOX_APPROVE
                boxInfoAPIData.paybalance = returnResult.PAYTOKENBOX_BALANCE
            }

            boxData.value = boxInfoAPIData

            isLoading.value = false
            if (isLoadingInit.value) {
                isLoadingInit.value = false
                ctx.emit('update', false)
            }

            nextTick(() => {
                // Loading should be closed asynchronously
                loadingInstance.close()
            })
            // }
            currentBlockNo = currentBlock.number
        }

        return {
            getLowerCase,
            isLoading,
            isMobile,
            isShowModal,
            modalShowIndex,
            showModal,
            closeModal,
            buttonDisabled,
            boxOpenId,
            walletwhitelist,
            approve,
            boxOpenCatIndex,
            getBoxPreviewName,
            serverHost,
            inviteCode,
            invitedBuyBoxCount,
            inviterBoardList,
            copyTextInvitation,
            watchInput,
            maxInput,
            tokenObject,
            contractBoxAssetsAddress,
            radioIndex,
            formatTime,
            currentTime,
            countDownTime,
            getBoxState,
            getTXLink,
            recordCount,
            pageNow,
            pagePerCount,
            isLoadingMore,
            go2MyBoxListMobile,
            refMyBoxListMobile,
            gtmTrack,
            selecedBoxIndex,
            inputWalletAddressTransfer,
            goBack,
            buyAmount,
            openTopbox,
            boxData,
            boxContentListIdx,
            boxContentListData,
            boxBuyListData,
            getPeriodCountDown,
            copyText,
            urSellCountDownTimeArr,
            getApproveTokenAndNeedAmount,
            actionResultInfo,
            confirm,
            openbox,
            getTodayAvailable,
            modules: [Pagination, Navigation, Scrollbar, A11y]
        }
    }

})
</script>
<style lang="stylus">
  #root,#app{
    height: auto
  }

</style>
