<template>
    <div class="page-mp" style="position: relative;height: auto;">
        <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
            <div v-if="modalShowIndex === 1"
                class="mask-nft mobile-filter d-flex flex-column justify-content-end align-items-center">
                <div class="dialog-content d-flex">
                    <div class="gap" @click.stop="closeModal()"></div>
                    <div class="mobile-menu">
                        <template v-if="listIndexNumber === 0">
                            <div class="filter-title-block d-flex justify-content-between align-items-center">
                                <span class="title">{{ $t('Filter.title') }}</span>
                                <span @click="resetFilter" class="reset">{{ $t('Filter.reset') }}</span>
                            </div>
                            <div class="filter-type-search d-flex align-items-center justify-content-center">
                                <svg viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="buy-nft" transform="translate(-36.000000, -242.000000)" stroke="#FFFFFF">
                                            <g transform="translate(26.000000, 235.000000)">
                                                <g transform="translate(11.000000, 8.000000)">
                                                    <circle cx="3.5" cy="3.5" r="3.5"></circle>
                                                    <line x1="6.5" y1="6.5" x2="8.5" y2="8.5" stroke-linecap="round">
                                                    </line>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <input @keyup.enter="searchInput()" v-model="searchKewords[listIndexNumber]"
                                    :placeholder="$t('Search.search-by-nft-id-or-name')"
                                    @focus="handleInputSearchFocusAndBlur($event, true)"
                                    @blur="handleInputSearchFocusAndBlur($event, false)" />
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterOrderBy = !isShowFilterOrderBy" class="title-block">
                                    <span class="filter-title">Order by</span>
                                    <SVGArrowUpDown :class="isShowFilterOrderBy ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-radio-group v-if="isShowFilterOrderBy" v-model="sortCheckedIndex[listIndexNumber]"
                                    @change="changeCheckboxGroup" class="option-list d-flex flex-column align-items-start">
                                    <el-radio :label="1">Price low to high</el-radio>
                                    <el-radio :label="2">Price high to low</el-radio>
                                    <el-radio :label="0">Recently Listed</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterCharacter = !isShowFilterCharacter" class="title-block">
                                    <span class="filter-title">{{ $t('Filter.title-character') }}</span>
                                    <SVGArrowUpDown :class="isShowFilterCharacter ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterCharacter" v-model="checkListCha[radioIndexNumber]"
                                    @change="changeCheckboxGroup" class="option-list d-flex align-items-center">
                                    <template v-for="(cha, chaIndex) of getFilterCharacterList()" :key="'cha-' + chaIndex">
                                        <el-checkbox :label="chaIndex" size="small">{{
                                            $t(`Character.${cha['name']}`).replaceAll("\"", "")
                                        }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterRarity = !isShowFilterRarity" class="title-block">
                                    <span class="filter-title">{{
                                        $t('Filter.title-rarity')
                                    }}</span>
                                    <SVGArrowUpDown :class="isShowFilterRarity ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterRarity"
                                    class="option-list rarity d-flex align-items-center"
                                    v-model="checkListRarity[radioIndexNumber]" @change="changeCheckboxGroup">
                                    <template v-for="(rarity, rarityIndex) of getFilterRarityList()"
                                        :key="'rarity-' + rarityIndex">
                                        <el-checkbox size="small" :label="rarityIndex">
                                            {{
                                                rarity['name']
                                            }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterAttr = !isShowFilterAttr" class="title-block">
                                    <span class="filter-title">Element</span>
                                    <SVGArrowUpDown :class="isShowFilterAttr ? '' : 'up'"></SVGArrowUpDown>
                                </div>

                                <div v-if="isShowFilterAttr" class="attr-list">
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                        <span class="title">{{ $t('Common.earth') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderEarth[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_blue_water_ball.png" />
                                        <span class="title">{{ $t('Common.water') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderWater[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_red_fire_ball.png" />
                                        <span class="title">{{ $t('Common.fire') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderFire[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_green_wind_ball.png" />
                                        <span class="title">{{ $t('Common.wind') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderWind[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="getGoodsPayTokenList().length > 0"
                                class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterPrice = !isShowFilterPrice" class="title-block">
                                    <span class="filter-title">{{
                                        $t('Filter.title-price')
                                    }} Range({{ getGoodsPayTokenList()[0].symbol }})</span>
                                    <SVGArrowUpDown :class="isShowFilterPrice ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterPrice" class="option-list d-flex align-items-center"
                                    v-model="checkListPrice[listIndexNumber]" @change="changeCheckboxGroup">
                                    <template v-for="(price, priceIndex) of getGoodsPayTokenList()[0].priceRange"
                                        :key="'price-' + priceIndex">
                                        <el-checkbox size="small" :label="priceIndex">{{ price['key'] }}</el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <modal-approve v-if="modalShowIndex === 2" :tokeninfo="getApproveTokenAndNeedAmount(selectedBuyIndex)"
                @confirm="approve(selectedBuyIndex)" @cancel="closeModal()" />
        </el-dialog>
        <div v-if="!isLoading" class="marketplace-index d-flex flex-column"
            :class="isMobile ? '' : 'height-header-padding'">
            <template v-if="!isMobile">
                <div class="top-block">
                    <img src="/static/images/icon-mpv2-top-statdata.png" />
                    <div class="stat-block long">
                        <span class="line1" style="font-size: 16PX;">Buy & Sell NFT</span>
                        <span class="line2">Buy and sell rapidly and safely</span>
                    </div>
                    <div class="stat-block" style="align-items: center;font-size: 16PX;">
                        <span class="line1">
                            <template v-if="isLoading">---</template>
                            <template v-else>{{ statData.totalsupply }}</template>
                        </span>
                        <span class="line2">Total Items</span>
                    </div>
                    <div class="stat-block" style="align-items: center;font-size: 16PX;">
                        <span class="line1">
                            <template v-if="isLoading">---</template>
                            <template v-else>{{ statData.listed }}</template>
                        </span>
                        <span class="line2">Total Listed</span>
                    </div>
                    <div class="stat-block" style="align-items: center;font-size: 16PX;">
                        <span class="line1">
                            <template v-if="isLoading">---</template>
                            <template v-else>{{ statData.volume }}</template>
                        </span>
                        <span class="line2">Total Volume</span>
                    </div>
                    <div class="stat-block" style="align-items: center;font-size: 16PX;">
                        <span class="line1">
                            <template v-if="isLoading">---</template>
                            <template v-else>{{ statData.owner }}</template>
                        </span>
                        <span class="line2">Owners</span>
                    </div>
                </div>
                <div class="content">
                    <div class="left" :class="isShowPCLeftMenu ? '' : 'close'">
                        <div class="toggle-block" :class="!isShowPCLeftMenu ? 'left' : ''"
                            @click="isShowPCLeftMenu = !isShowPCLeftMenu">
                            <SVGArrowLeftRight style="color:#FFFFFF" :class="!isShowPCLeftMenu ? 'arrowright' : ''">
                            </SVGArrowLeftRight>
                        </div>
                        <template v-if="listIndexNumber === 0 && isShowPCLeftMenu">
                            <div class="filter-title-block d-flex justify-content-between align-items-center">
                                <span class="title">{{ $t('Filter.title') }}</span>
                                <span @click="resetFilter" class="reset">{{ $t('Filter.reset') }}</span>
                            </div>
                            <div class="filter-type-search d-flex align-items-center justify-content-center">
                                <svg viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="buy-nft" transform="translate(-36.000000, -242.000000)" stroke="#FFFFFF">
                                            <g transform="translate(26.000000, 235.000000)">
                                                <g transform="translate(11.000000, 8.000000)">
                                                    <circle cx="3.5" cy="3.5" r="3.5"></circle>
                                                    <line x1="6.5" y1="6.5" x2="8.5" y2="8.5" stroke-linecap="round">
                                                    </line>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <input @keyup.enter="searchInput()" v-model="searchKewords[listIndexNumber]"
                                    :placeholder="$t('Search.search-by-nft-id-or-name')"
                                    @focus="handleInputSearchFocusAndBlur($event, true)"
                                    @blur="handleInputSearchFocusAndBlur($event, false)" />
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterOrderBy = !isShowFilterOrderBy" class="title-block">
                                    <span class="filter-title">Order by</span>
                                    <SVGArrowUpDown :class="isShowFilterOrderBy ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-radio-group v-if="isShowFilterOrderBy" v-model="sortCheckedIndex[listIndexNumber]"
                                    @change="changeCheckboxGroup" class="option-list d-flex align-items-center">
                                    <el-radio :label="1">Price low to high</el-radio>
                                    <el-radio :label="2">Price high to low</el-radio>
                                    <el-radio :label="0">Recently Listed</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterCharacter = !isShowFilterCharacter" class="title-block">
                                    <span class="filter-title">{{ $t('Filter.title-character') }}</span>
                                    <SVGArrowUpDown :class="isShowFilterCharacter ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterCharacter" v-model="checkListCha[radioIndexNumber]"
                                    @change="changeCheckboxGroup" class="option-list d-flex align-items-center">
                                    <template v-for="(cha, chaIndex) of getFilterCharacterList()" :key="'cha-' + chaIndex">
                                        <el-checkbox :label="chaIndex" size="small">{{ $t(`Character.${cha['name']}`) }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterRarity = !isShowFilterRarity" class="title-block">
                                    <span class="filter-title">{{
                                        $t('Filter.title-rarity')
                                    }}</span>
                                    <SVGArrowUpDown :class="isShowFilterRarity ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterRarity"
                                    class="option-list rarity d-flex align-items-center"
                                    v-model="checkListRarity[radioIndexNumber]" @change="changeCheckboxGroup">
                                    <template v-for="(rarity, rarityIndex) of getFilterRarityList()"
                                        :key="'rarity-' + rarityIndex">
                                        <el-checkbox size="small" :label="rarityIndex">
                                            {{
                                                rarity['name']
                                            }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                            <div class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterAttr = !isShowFilterAttr" class="title-block">
                                    <span class="filter-title">Element</span>
                                    <SVGArrowUpDown :class="isShowFilterAttr ? '' : 'up'"></SVGArrowUpDown>
                                </div>

                                <div v-if="isShowFilterAttr" class="attr-list">
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                        <span class="title">{{ $t('Common.earth') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderEarth[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_blue_water_ball.png" />
                                        <span class="title">{{ $t('Common.water') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderWater[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_red_fire_ball.png" />
                                        <span class="title">{{ $t('Common.fire') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderFire[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                    <div class="item d-flex align-items-center">
                                        <img src="/static/images/element_attr_green_wind_ball.png" />
                                        <span class="title">{{ $t('Common.wind') }}</span>
                                    </div>
                                    <div class=" d-flex align-items-center">
                                        <div class="rg-block">
                                            <el-radio-group v-model="valueSliderWind[radioIndexNumber]"
                                                @change="changeCheckboxGroup" size="small">
                                                <el-radio-button :label="-2">All</el-radio-button>
                                                <el-radio-button :label="0" />
                                                <el-radio-button :label="2" />
                                                <el-radio-button :label="4" />
                                                <el-radio-button :label="6" />
                                                <el-radio-button :label="8" />
                                                <el-radio-button :label="10" />
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="getGoodsPayTokenList().length > 0"
                                class="filter-type-block d-flex flex-column justify-content-center">
                                <div @click="isShowFilterPrice = !isShowFilterPrice" class="title-block">
                                    <span class="filter-title">{{
                                        $t('Filter.title-price')
                                    }} Range({{ getGoodsPayTokenList()[0].symbol }})</span>
                                    <SVGArrowUpDown :class="isShowFilterPrice ? '' : 'up'"></SVGArrowUpDown>
                                </div>
                                <el-checkbox-group v-if="isShowFilterPrice" class="option-list d-flex align-items-center"
                                    v-model="checkListPrice[listIndexNumber]" @change="changeCheckboxGroup">
                                    <template v-for="(price, priceIndex) of getGoodsPayTokenList()[0].priceRange"
                                        :key="'price-' + priceIndex">
                                        <el-checkbox size="small" :label="priceIndex">{{ price['key'] }}</el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </template>
                    </div>
                    <div class="right d-flex flex-column">
                        <div ref="refContent" class="content"
                            :class="marketItemList[listIndexNumber].length === 0 ? 'empty' : ''">
                            <div v-if="marketItemList[listIndexNumber].length > 0" class="nft-item-list">
                                <div @click="go2Info(itemIndex)" v-for="(nft, itemIndex) of marketItemList[listIndexNumber]"
                                    :key="'index_' + itemIndex" style="cursor: pointer;text-decoration: none;">
                                    <div>
                                        <div class="d-flex flex-column justify-content-center align-items-center">
                                            <div class="nft-item d-flex align-items-center justify-content-center">
                                                <img :class="listIndexNumber === 2 ? 'rune' : ''" :src="nft['preview']"
                                                    class="nft-item-image" />
                                                <div class="nft-item-intro d-flex flex-column align-items-start">
                                                    <div v-if="listIndexNumber !== 2" class="nft-item-intro-title">{{
                                                        $t(`CardList.${nft['typeid']}.name`)
                                                    }}</div>
                                                    <div v-if="listIndexNumber === 2" class="nft-item-intro-title">{{
                                                        nft['name']
                                                    }}</div>
                                                    <div class="nft-item-intro-title">#{{ nft['tokenid'] }}</div>
                                                    <!-- <div v-else class="nft-item-intro-upgrade">AMOUNT: {{ nft['amount'] }}</div> -->
                                                </div>
                                                <div v-if="listIndexNumber !== 2"
                                                    class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                                                    <!-- <img src="/static/images/element_lighting.png" /> -->
                                                    <span class="text" :class="nft['level'] > 0 ? 'nozero' : ''">LV. {{
                                                        nft['level']
                                                    }}/5</span>
                                                </div>
                                                <!-- <div v-else class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                        <span>{{ nft['amount'] }}</span>
                      </div> -->

                                                <div v-if="listIndexNumber !== 2" class="nft-item-attrs">
                                                    <div v-if="nft['earth'] > 0"
                                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                        <img class="nft-item-attrs-image"
                                                            src="/static/images/element_attr_yellow_earth.png" />
                                                        <span class="nft-item-attrs-text earth"
                                                            :class="nft['earth'] >= 10 ? 'digit2' : ''">{{
                                                                nft['earth']
                                                            }}</span>
                                                    </div>
                                                    <div v-if="nft['water'] > 0"
                                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                        <img class="nft-item-attrs-image"
                                                            src="/static/images/element_attr_blue_water.png" />
                                                        <span class="nft-item-attrs-text water"
                                                            :class="nft['water'] >= 10 ? 'digit2' : ''">{{
                                                                nft['water']
                                                            }}</span>
                                                    </div>
                                                    <div v-if="nft['fire'] > 0"
                                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                        <img class="nft-item-attrs-image"
                                                            src="/static/images/element_attr_red_fire.png" />
                                                        <span class="nft-item-attrs-text fire"
                                                            :class="nft['fire'] >= 10 ? 'digit2' : ''">{{
                                                                nft['fire']
                                                            }}</span>
                                                    </div>
                                                    <div v-if="nft['wind'] > 0"
                                                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                        <img class="nft-item-attrs-image"
                                                            src="/static/images/element_attr_green_wind.png" />
                                                        <span class="nft-item-attrs-text wind"
                                                            :class="nft['wind'] >= 10 ? 'digit2' : ''">{{
                                                                nft['wind']
                                                            }}</span>
                                                    </div>
                                                    <div class="nft-item-attr d-flex align-items-center"></div>
                                                </div>
                                                <img v-if="listIndexNumber !== 2" class="nft-item-attrs-image-level"
                                                    :src="'/static/images/element_level_' + getLowerCase(nft['rarity']) + '.png'" />
                                                <template
                                                    v-if="listIndexNumber !== 1 && getGoodsPayTokenList().length > 0 && !buttonDisabled">
                                                    <div v-if="getGoodsPayTokenList()[0].approve === 0"
                                                        @click.stop="approvePre(itemIndex)" class="button-buy-bottom">
                                                        Approve {{ getGoodsPayTokenList()[0].symbol }}</div>
                                                    <div v-else @click.stop="buy(itemIndex)" class="button-buy-bottom">
                                                        Buy Now</div>
                                                </template>
                                            </div>
                                            <div v-if="listIndexNumber !== 1"
                                                class="nft-item-price d-flex justify-content-center align-items-center">
                                                <span>{{ nft['price'] % 1 === 0 ? nft['price'] : nft['price'].toFixed(2)
                                                }}
                                                    {{
                                                        getGoodsPayTokenList().length > 0 ? getGoodsPayTokenList()[0].symbol
                                                        :
                                                        ''
                                                    }}</span>
                                            </div>
                                            <template v-else>
                                                <div class="nft-item-rent-block">
                                                    <div class="item">
                                                        <div class="left">{{ $t('MarketPlace.duration') }}</div>
                                                        <div class="right">{{ nft['period'] / 3600 / 24 }} Days</div>
                                                    </div>
                                                    <div class="item">
                                                        <div class="left">{{ $t('MarketPlace.daily-price') }}</div>
                                                        <div class="right">
                                                            <template
                                                                v-if="(nft['price'] / (nft['period'] / 3600 / 24)) % 1 === 0">
                                                                {{ (nft['price'] / (nft['period'] / 3600 / 24)) }}
                                                            </template>
                                                            <template v-else>
                                                                {{ (nft['price'] / (nft['period'] / 3600 /
                                                                    24)).toFixed(2)
                                                                }}
                                                            </template>
                                                            {{
                                                                nft['paytokensymbol']
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <button :disabled="nft['expirytime'] > 0" class="button-buy nft-item-rent-button">
                        <template v-if="nft['expirytime'] > 0">Rented</template>
                        <template v-else>Rent Now</template>
                      </button> -->
                                            </template>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <el-empty v-else :description="$t('Common.no-items')" />
                        </div>

                        <div class="pageinfo d-flex align-items-center justify-content-end">
                            <div class="nft-filter-text">{{ recordCount[listIndexNumber] }} {{ $t('MarketPlace.items')
                            }}
                            </div>
                            <template v-if="listIndexNumber === 0">
                                <pagination :ref="'refPaginationMP' + listIndexNumber"
                                    v-if="marketItemList[listIndexNumber].length > 0" @change-page="getDepartPageData"
                                    :pagesize="pagePerCount" :total="recordCount[listIndexNumber]"
                                    :page="pageNow[listIndexNumber]" />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="mobile-content height-header-padding">
                    <div class="mobile-content-inner">
                        <div class="top-block">
                            <img src="/static/images/icon-mpv2-top-statdata.png" />
                            <div class="right-block">
                                <span class="top-title">Buy & Sell NFT</span>
                                <div class="stat-block-list">
                                    <div class="stat-block">
                                        <span class="line1">
                                            <template v-if="isLoading">---</template>
                                            <template v-else>{{ statData.totalsupply }}</template>
                                        </span>
                                        <span class="line2">Total Items</span>
                                    </div>
                                    <div class="stat-block">
                                        <span class="line1">
                                            <template v-if="isLoading">---</template>
                                            <template v-else>{{ statData.listed }}</template>
                                        </span>
                                        <span class="line2">Total Listed</span>
                                    </div>
                                    <div class="stat-block">
                                        <span class="line1">
                                            <template v-if="isLoading">---</template>
                                            <template v-else>{{ statData.volume }}</template>
                                        </span>
                                        <span class="line2">Total Volume</span>
                                    </div>
                                    <div class="stat-block">
                                        <span class="line1">
                                            <template v-if="isLoading">---</template>
                                            <template v-else>{{ statData.owner }}</template>
                                        </span>
                                        <span class="line2">Owners</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="filter-block d-flex align-items-center justify-content-between">
                            <button @click="showModal(1)" class="button-filter">{{ $t('Filter.title') }}</button>
                            <div class="mobile-right d-flex align-items-center justify-content-end">
                                <div :class="isShowPCSearchRight ? 'expand' : 'collapsed'"
                                    class="search d-flex align-items-center justify-content-center">
                                    <input v-if="isShowPCSearchRight" @keyup.enter="searchInput()"
                                        v-model="searchKewords[listIndexNumber]"
                                        :placeholder="$t('Search.search-nft-id-or-name')" onfocus="this.placeholder=''"
                                        onblur="this.placeholder=$t('Search.search-nft-id-or-name')" />
                                    <img v-if="!isShowPCSearchRight" @click.stop="isShowPCSearchRight = true"
                                        src="/static/images/icon_search.png" />
                                    <svg class="svg" @click.stop="closeSearch" v-if="isShowPCSearchRight"
                                        viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#FFFFFF"
                                            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                                    </svg>
                                </div>
                                <div class="select" style="width: 124PX">
                                    <div v-if="!isShowPCSelectRight"
                                        @click.stop="isShowPCSelectRight = !isShowPCSelectRight"
                                        class="select-input d-flex align-items-center">
                                        <span class="title">
                                            <template v-if="sortCheckedIndex[listIndexNumber] >= 0">{{
                                                $t(`Sort.condition.${getConditionFilter()[sortCheckedIndex[listIndexNumber]].key}`)
                                            }}</template>
                                            <template v-else>{{ $t('Sort.sort-by') }}</template>
                                        </span>
                                    </div>
                                    <div v-else class="list d-flex flex-column"
                                        :class="isShowPCSelectRight ? 'expand' : 'collapsed'">
                                        <div :class="sortCheckedIndex[listIndexNumber] === cIndex ? 'active' : ''"
                                            class="select-item" v-for="(c, cIndex) of getConditionFilter()"
                                            :key="'select-' + cIndex" @click="switchSort(cIndex)">
                                            {{
                                                $t(`Sort.condition.${c.key}`)
                                            }}
                                        </div>
                                    </div>
                                    <img @click.stop="isShowPCSelectRight = !isShowPCSelectRight" class="arrow"
                                        :class="isShowPCSelectRight ? 'rotate' : ''"
                                        src="/static/images/arrow_select.png" />
                                </div>
                            </div>
                        </div>
                        <div v-if="marketItemList[listIndexNumber].length > 0" class="d-flex flex-column">
                            <div class="nft-item-list">
                                <div @click="go2Info(itemIndex)" v-for="(nft, itemIndex) of marketItemList[listIndexNumber]"
                                    :key="'index_' + itemIndex" style="cursor: pointer;text-decoration: none;">
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <div class="nft-item d-flex align-items-center justify-content-center">
                                            <img :class="listIndexNumber === 2 ? 'rune' : ''" :src="nft['preview']"
                                                class="nft-item-image" />
                                            <div class="nft-item-intro d-flex flex-column align-items-start">
                                                <div v-if="listIndexNumber !== 2" class="nft-item-intro-title">{{
                                                    $t(`CardList.${nft['typeid']}.name`)
                                                }}</div>
                                                <div v-if="listIndexNumber === 2" class="nft-item-intro-title">{{
                                                    nft['name']
                                                }}</div>
                                                <div class="nft-item-intro-title">#{{ nft['tokenid'] }}</div>
                                                <!-- <div v-else class="nft-item-intro-upgrade">AMOUNT: {{ nft['amount'] }}</div> -->
                                            </div>
                                            <div v-if="listIndexNumber !== 2"
                                                class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                                                <!-- <img src="/static/images/element_lighting.png" /> -->
                                                <span class="text" :class="nft['level'] > 0 ? 'nozero' : ''">LV. {{
                                                    nft['level']
                                                }}/5</span>
                                            </div>

                                            <div v-if="listIndexNumber !== 2" class="nft-item-attrs">
                                                <div v-if="nft['earth'] > 0"
                                                    class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                    <img class="nft-item-attrs-image"
                                                        src="/static/images/element_attr_yellow_earth.png" />
                                                    <span class="nft-item-attrs-text earth"
                                                        :class="nft['earth'] >= 10 ? 'digit2' : ''">{{
                                                            nft['earth']
                                                        }}</span>
                                                </div>
                                                <div v-if="nft['water'] > 0"
                                                    class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                    <img class="nft-item-attrs-image"
                                                        src="/static/images/element_attr_blue_water.png" />
                                                    <span class="nft-item-attrs-text water"
                                                        :class="nft['water'] >= 10 ? 'digit2' : ''">{{
                                                            nft['water']
                                                        }}</span>
                                                </div>
                                                <div v-if="nft['fire'] > 0"
                                                    class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                    <img class="nft-item-attrs-image"
                                                        src="/static/images/element_attr_red_fire.png" />
                                                    <span class="nft-item-attrs-text fire"
                                                        :class="nft['fire'] >= 10 ? 'digit2' : ''">{{
                                                            nft['fire']
                                                        }}</span>
                                                </div>
                                                <div v-if="nft['wind'] > 0"
                                                    class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                                                    <img class="nft-item-attrs-image"
                                                        src="/static/images/element_attr_green_wind.png" />
                                                    <span class="nft-item-attrs-text wind"
                                                        :class="nft['wind'] >= 10 ? 'digit2' : ''">{{
                                                            nft['wind']
                                                        }}</span>
                                                </div>
                                                <div class="nft-item-attr d-flex align-items-center"></div>
                                            </div>
                                            <img v-if="listIndexNumber !== 2" class="nft-item-attrs-image-level"
                                                :src="'/static/images/element_level_' + getLowerCase(nft['rarity']) + '.png'" />
                                            <template v-if="listIndexNumber !== 1 && getGoodsPayTokenList().length > 0">
                                                <div @click.stop="buy(itemIndex)" class="button-buy-bottom">
                                                    Buy Now</div>
                                            </template>
                                        </div>
                                        <div v-if="listIndexNumber !== 1"
                                            class="nft-item-price d-flex justify-content-center align-items-center">
                                            <span>{{ nft['price'] % 1 === 0 ? nft['price'] : nft['price'].toFixed(2) }}
                                                {{
                                                    getGoodsPayTokenList().length > 0 ? getGoodsPayTokenList()[0].symbol :
                                                    ''
                                                }}</span>
                                        </div>
                                        <template v-else>
                                            <div class="nft-item-rent-block">
                                                <div class="item">
                                                    <div class="left">{{ $t('MarketPlace.duration') }}</div>
                                                    <div class="right">{{ nft['period'] / 3600 / 24 }} Days</div>
                                                </div>
                                                <div class="item">
                                                    <div class="left">{{ $t('MarketPlace.daily-price') }}</div>
                                                    <div class="right">
                                                        <template
                                                            v-if="(nft['price'] / (nft['period'] / 3600 / 24)) % 1 === 0">
                                                            {{ (nft['price'] / (nft['period'] / 3600 / 24)) }}
                                                        </template>
                                                        <template v-else>
                                                            {{ (nft['price'] / (nft['period'] / 3600 / 24)).toFixed(2)
                                                            }}
                                                        </template>
                                                        {{
                                                            nft['paytokensymbol']
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <button :disabled="nft['expirytime'] > 0" class="button-buy nft-item-rent-button">
                        <template v-if="nft['expirytime'] > 0">Rented</template>
                        <template v-else>Rent Now</template>
                      </button> -->
                                        </template>
                                    </div>
                                </div>

                            </div>
                            <div v-if="marketItemList[listIndexNumber].length < recordCount[listIndexNumber] && !isLoading"
                                class="d-flex justify-content-center loadmore">
                                <div v-if="!isLoadingMore[listIndexNumber]"
                                    @click="getDepartPageData(pageNow[listIndexNumber] + 1)">
                                    {{ $t('Common.load-more-data') }}
                                </div>
                                <div v-else class="d-flex justify-content-center align-items-center">
                                    <span>{{ $t('Common.loading-more') }}</span>
                                    <svg class="rotate-circle" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"
                                        style="width: 16px; height: 16px;margin-left: 6PX;">
                                        <path
                                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                                            stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <el-empty v-else :description="$t('Common.no-items')" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick,
    reactive,
    CSSProperties,
} from 'vue'
import { ElDialog, ElMessageBox, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup } from 'element-plus'
import Pagination from '@/components/Pagination.vue'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import TOOL from '@/utils/tool'
import * as API from '@/constants/api'
import axios from '@/utils/axios'
import { getWalletProvider } from '@/utils/provider'
import TapDesktop from '@/abis/TapDesktop.json'
import ERC20 from '@/abis/ERC20.json'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import { aggregate as aggregateMarketPlace } from '@makerdao/multicall'
import { isDebug } from '@/constants/Config'
import MPHeader from './components/MPHeader.vue'
import SVGArrowUpDown from '@/components/svgicon/SVGArrowUpDown.vue'
import SVGArrowLeftRight from '@/components/svgicon/SVGArrowLeftRight.vue'

interface StatData {
    listed: number
    owner: number
    volume: string
    totalsupply: string
}

export default defineComponent({
    name: 'BuyNFT',
    components: { Pagination, ElCarousel, ElCarouselItem, ElButton, ElLoading, ElEmpty, ElRadioButton, ElRadioGroup, MPHeader, SVGArrowUpDown, SVGArrowLeftRight },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        console.log('onSetup...')

        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const router = useRouter()
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const createNotification = <CreateNotification>inject('create-notification')
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber
        const localStorageMPConfig = "mp_data_select_setting_buynft"
        const radioIndex = ref('0')
        const radioIndexNumber = ref(0)
        const listIndexNumber = ref(0)
        const refPaginationMP0: Ref<any> = ref(null)
        const refPaginationMP1: Ref<any> = ref(null)
        const refPaginationMP2: Ref<any> = ref(null)
        const pageChainConfig: any = {}
        const buttonDisabled = ref(true)
        const isLoading = ref(true)
        const isLoadingInit = ref(true)
        const isExpand = ref(false)
        const isMobile = ref(false)
        const isLoadingMore = ref([false, false, false])
        const isShowPCSelectRight = ref(false)
        const isShowPCSearchRight = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const marketItemList: Ref<any[]> = ref([[], [], []])
        const pagePerCount = ref(21)
        const pageNow = ref([1, 1, 1])
        const pageCount = ref([1, 1, 1])
        const recordCount = ref([0, 0, 0])
        const sortCheckedIndex = ref([0, 0, 0])
        const isShowFilterCharacter = ref(true)
        const isShowFilterRarity = ref(true)
        const isShowFilterAttr = ref(true)
        const isShowFilterOrderBy = ref(true)
        const isShowFilterPrice = ref(true)
        const isShowPCLeftMenu = ref(true)
        const checkListCha: Ref<any[]> = ref([[], [], []])
        const checkListRarity: Ref<any[]> = ref([[], [], []])
        const checkListPrice: Ref<any[]> = ref([[], [], []])
        const valueSliderEarth = ref([-2, -2])
        const valueSliderWater = ref([-2, -2])
        const valueSliderFire = ref([-2, -2])
        const valueSliderWind = ref([-2, -2])
        const searchKewords = ref(['', '', ''])
        const orderAPIPara = ref(['-o1', '-o1', '-o1'])
        const filterAPIPara = ref(['', '', ''])
        const filterSearchAPIPara = ref(['', '', ''])
        const refContent: Ref<any> = ref(null)
        const marketTypeIdx = ref(0)
        interface goodsPayToken {
            goodsid: number
            decimals: number,
            address: string,
            symbol: string,
            priceRange: { key: string; value: any; }[],
            balance: string,
            approve: number
        }

        const goodsPayTokenList0: Ref<goodsPayToken[]> = ref([])
        const goodsPayTokenList1: Ref<goodsPayToken[]> = ref([])

        const conditionFilter0: Ref<{
            name: string;
            value: string;
            order: number;
            key: string;
        }[]> = ref([])
        const conditionFilter1: Ref<{
            name: string;
            value: string;
            order: number;
            key: string;
        }[]> = ref([])
        const filterCharacterList0: Ref<{
            name: string;
            value: any;
        }[]> = ref([])
        const filterCharacterList1: Ref<{
            name: string;
            value: any;
        }[]> = ref([])
        const filterRarityList0: Ref<{
            name: string;
            value: any;
        }[]> = ref([])
        const filterRarityList1: Ref<{
            name: string;
            value: any;
        }[]> = ref([])
        const filterAttrList0: Ref<{
            name: string;
            value: any;
        }[]> = ref([])
        const filterAttrList1: Ref<{
            name: string;
            value: any;
        }[]> = ref([])

        let chainId = -1
        const chainSymbol = ''
        let marketItemListData: {
            list: any[],
            filter: string
            order: string
            count: number
            desktop: string
            paytoken: any[]
        }

        let marketAPIFilter: {
            filterchar: any[]
            filterrarity: any[]
            filterattr: any[]
            filtersearch: any[]
            ordertype: any[]
            paytype: any[]
            pt0price: any[]
            pt1price: any[]
            chainid: 0,
        } | null = null

        const mobileScrollTop = ref(0)

        const selectedBuyIndex = ref(-1)

        const statData: Ref<StatData> = ref({
            listed: 0,
            owner: 0,
            volume: '0',
            totalsupply: '0'
        })


        onMounted(() => {
            if (router.currentRoute.value.params.closefilter) {
                try {
                    const closefilter = parseInt(router.currentRoute.value.params.closefilter as string)
                    if (closefilter == 1) {
                        if (!isMobile.value) {
                            isShowPCLeftMenu.value = false

                        }
                    }
                } catch (e: any) {

                }
            }

            isMobile.value = !TOOL.getDeviceScreenIsPC()
            setPagePerCount()
            // LogUtils.debug('load from mount...')
            // setTimeout(() => {
            //   loadData()
            // }, 1000 * 1)

            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
                setPagePerCount()
            })

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            // saveMPStorageSetting()
            ctx.emit('update', true)
        })

        const handleInputSearchFocusAndBlur = (e: any, isFocus: boolean) => {
            if (isFocus) {
                e.target.placeholder = ''
            } else {
                e.target.placeholder = t('Search.search-by-nft-id-or-name')
            }
        }

        const changeRadioGroup = () => {
            // radioIndexNumber.value = parseInt(radioIndex.value)
        }

        const getConditionFilter = () => {
            const listCur = getListCur()
            switch (listCur) {
                case 0:
                    return conditionFilter0.value
            }
            return []
        }

        const getFilterCharacterList = () => {
            const listCur = getListCur()
            switch (listCur) {
                case 0:
                    return filterCharacterList0.value
            }
            return []
        }

        const getFilterRarityList = () => {
            const listCur = getListCur()
            switch (listCur) {
                case 0:
                    return filterRarityList0.value
            }
            return []
        }

        const getFilterAttrList = () => {
            const listCur = getListCur()
            switch (listCur) {
                case 0:
                    return filterAttrList0.value
            }
            return []
        }

        const getGoodsPayTokenList = () => {
            const listCur = getListCur()
            switch (listCur) {
                case 0:
                    return goodsPayTokenList0.value
            }
            return []
        }

        const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
            TOOL.gtmTrack(event, category, action, label, value)
        }

        const getListCur = () => {
            return listIndexNumber.value
        }

        const searchInput = async () => {
            const radioCur = getListCur()
            const keyword = searchKewords.value[radioCur].trim()
            if (keyword.length > 0) {
                const encode = encodeURI(keyword)
                pageNow.value[radioCur] = 1

                switch (radioCur) {
                    case 0:
                        if (refPaginationMP0.value) {
                            refPaginationMP0.value.setCurPage(1)
                        }
                        const urlMarketList = `${API.itemListMP + chainId}/${pageNow.value[radioCur] - 1}/${pagePerCount.value}/?filter=sn${encode}`
                        marketItemListData = (await axios.get(urlMarketList)).data.data
                        marketItemList.value[radioCur] = marketItemListData.list

                        recordCount.value[radioCur] = marketItemListData.count
                        filterSearchAPIPara.value[radioCur] = `sn${encode}`

                        if (isMobile.value) {
                            isLoadingMore.value[radioCur] = false
                        }
                        break
                }
            }
        }

        const closeSearch = () => {
            isShowPCSearchRight.value = false
            filterSearchAPIPara.value[listIndexNumber.value] = ''
            pageNow.value[listIndexNumber.value] = 1

            switch (listIndexNumber.value) {
                case 0:
                    if (refPaginationMP0.value) {
                        refPaginationMP0.value.setCurPage(1)
                    }
                    break;
                default:
                    break;
            }

            const radioCur = listIndexNumber.value
            pageNow.value[radioCur] = 1
            const index = sortCheckedIndex.value[radioCur]
            if (listIndexNumber.value < 3) {
                const sort = getConditionFilter()[index]
                getPageData(index, sort.order)
            }

        }

        const resetFilter = async () => {
            const radioCur = getListCur()

            switch (radioCur) {
                case 0:
                    checkListCha.value[radioCur] = []
                    checkListRarity.value[radioCur] = []
                    valueSliderEarth.value[radioCur] = -2
                    valueSliderWater.value[radioCur] = -2
                    valueSliderFire.value[radioCur] = -2
                    valueSliderWind.value[radioCur] = -2
                    checkListPrice.value[radioCur] = []
                    break
            }

            isShowPCSearchRight.value = false
            searchKewords.value[radioCur] = ''
            pageNow.value[radioCur] = 1

            if (refPaginationMP0.value) {
                refPaginationMP0.value.setCurPage(1)
            }

            filterSearchAPIPara.value[radioCur] = ''
            const sortCur = sortCheckedIndex.value[radioCur]

            const sortData = radioCur === 0 ? conditionFilter0.value[sortCur] : conditionFilter1.value[sortCur]

            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            await getPageData(sortCur, sortData.order)
            nextTick(() => {
                loadingInstance.close()
            })

        }

        const setPagePerCount = () => {
            if (isMobile.value) {
                pagePerCount.value = 30
            } else {
                const leftContentWidth = 250
                const contentPadding = 40 * 2
                const width = 210
                const col_gap = 16
                const windowWidth = window.innerWidth
                const contentWidth = windowWidth - leftContentWidth - contentPadding

                LogUtils.debug(contentWidth)
                const col_number = contentWidth / (width + col_gap)
                const col_number_integer = parseInt(col_number.toString(), 10)

                LogUtils.debug(col_number_integer)

                pagePerCount.value = 3 * col_number_integer
            }
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const showModal = (modalIdx: number, cardIdx: number = -1, cardSrc: number = -1,) => {
            isShowModal.value = true
            modalShowIndex.value = modalIdx

            if (modalIdx === 0) {

            } else if (modalIdx === 2) {

            }
        }

        const closeModal = () => {
            // LogUtils.debug('close...')

            isShowModal.value = false
            modalShowIndex.value = -1
            selectedBuyIndex.value = -1

            // const rootEle = (root as any).value
            // rootEle.style.overflowY = 'auto'

            // const headerEle = (header as any).value.$refs.headerRef
            // headerEle.style.display = 'block'

        }

        const mousewheel = (event: Event) => {
            event.preventDefault();
        }

        const switchMarketType = (index: number) => {
            radioIndexNumber.value = 0
            listIndexNumber.value = index
            isShowPCSearchRight.value = false
        }

        const switchRadioGroupBussinessType = (e: any) => {
            listIndexNumber.value = e
            isShowPCSearchRight.value = false
        }

        const switchSort = (index: number) => {
            isShowPCSelectRight.value = false
            const radioCur = listIndexNumber.value
            pageNow.value[radioCur] = 1
            const sort = radioCur === 0 ? conditionFilter0.value[index] : conditionFilter1.value[index]
            sortCheckedIndex.value[radioCur] = index
            getPageData(index, sort.order)
        }

        const changeCheckboxGroup = () => {
            const radioCur = listIndexNumber.value
            const sortCur = sortCheckedIndex.value[radioCur]
            const sortData = radioCur === 0 ? conditionFilter0.value[sortCur] : conditionFilter1.value[sortCur]
            getPageData(sortCur, sortData.order)
        }

        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }

        const listMyNFT = () => {
            BUS.$emit('SHOW_MASK', { maskIndex: 2, isShowMask: true, nftList: [] })
        }

        const getDepartPageData = async (page: number) => {
            const radioCur = listIndexNumber.value
            if (isMobile.value) {
                isLoadingMore.value[radioCur] = true
            }

            let filterMoreList = []

            if (filterAPIPara.value[radioCur].length > 0) {
                filterMoreList.push(filterAPIPara.value[radioCur])
            }

            if (filterSearchAPIPara.value[radioCur].length > 0) {
                filterMoreList.push(filterSearchAPIPara.value[radioCur])
            }

            const filterAPIP = filterMoreList.join(',')

            switch (radioCur) {
                case 0:
                    const urlMarketList = `${API.itemListMP + chainId}/${page - 1}/${pagePerCount.value}/?order=${orderAPIPara.value[radioCur]}&filter=${filterAPIP}`
                    marketItemListData = (await axios.get(urlMarketList)).data.data

                    if (!isMobile.value) {
                        // if (page === 1) {
                        if (refPaginationMP0.value) {
                            refPaginationMP0.value.setCurPage(page)
                        }
                        // }
                        marketItemList.value[radioCur] = marketItemListData.list
                    } else {
                        if (page === 1) {
                            marketItemList.value[radioCur] = marketItemListData.list
                        } else {
                            marketItemList.value[radioCur] = marketItemList.value[radioCur].concat(marketItemListData.list)
                        }
                    }

                    recordCount.value[radioCur] = marketItemListData.count
                    pageNow.value[radioCur] = page

                    if (isMobile.value) {
                        isLoadingMore.value[radioCur] = false
                    }
                    break
            }
        }

        const getPageData = async (sort: number, order: number, radioIndex: number = -1, page: number = -1, pagePer: number = -1) => {
            const listCur = radioIndex === -1 ? listIndexNumber.value : radioIndex

            if (isMobile.value) {
                isLoadingMore.value[listCur] = true
            }

            let flag = false
            let sortPara = ''

            if (sort === 0) {
                sortPara = '-o1'
            } else {

                const sortFilter = getConditionFilter()
                // radioCur === 0 ? conditionFilter0.value[sort] : conditionFilter1.value[sort]
                sortFilter[sort].order = order
                // sortCheckedIndex.value = sort
                sortPara = sortFilter[sort].value
                // radioCur === 0 ? conditionFilter0.value[sort].value : conditionFilter1.value[sort].value
                if (order === 1) {
                    sortPara = '+' + sortPara
                } else if (order === 2) {
                    sortPara = '-' + sortPara
                }
            }

            const pagePara = page === -1 ? pageNow.value[listCur] - 1 : page - 1
            const pagePerPara = pagePer === -1 ? pagePerCount.value : pagePer

            flag = true

            let filterPara = ''

            const checkListAttr = listCur === 2 ? [] : [valueSliderEarth.value[listCur], valueSliderWater.value[listCur], valueSliderFire.value[listCur], valueSliderWind.value[listCur]]

            if (checkListCha.value[listCur].length > 0 || checkListRarity.value[listCur].length > 0 || checkListAttr.length > 0 || checkListPrice.value[listCur].length > 0) {

                const filterArr = []
                if (checkListCha.value[listCur].length > 0) {
                    const filterList = listCur === 0 ? filterCharacterList0 : filterCharacterList1
                    const filter = filterList.value.filter((item, index) => {
                        return checkListCha.value[listCur].includes(index)
                    })
                    const filterResult = filter.map(item => {
                        return item.value
                    })
                    filterArr.push(filterResult.join(','))
                }
                if (checkListRarity.value[listCur].length > 0) {
                    const filterList = listCur === 0 ? filterRarityList0 : filterRarityList1
                    const filter = filterList.value.filter((item, index) => {
                        return checkListRarity.value[listCur].includes(index)
                    })
                    const filterResult = filter.map(item => {
                        return item.value
                    })
                    filterArr.push(filterResult.join(','))
                }

                const filterAttr = []

                if (valueSliderEarth.value[listCur] === -2) {
                    // filterAttr.push('ah')
                } else {
                    filterAttr.push('ah' + valueSliderEarth.value[listCur])
                }
                if (valueSliderWater.value[listCur] === -2) {
                    // filterAttr.push('ar')
                } else {
                    filterAttr.push('ar' + valueSliderWater.value[listCur])
                }
                if (valueSliderFire.value[listCur] === -2) {
                    // filterAttr.push('ae')
                } else {
                    filterAttr.push('ae' + valueSliderFire.value[listCur])
                }
                if (valueSliderWind.value[listCur] === -2) {
                    // filterAttr.push('ad')
                } else {
                    filterAttr.push('ad' + valueSliderWind.value[listCur])
                }
                if (filterAttr.length > 0) {
                    filterArr.push(filterAttr.join(','))
                }

                if (checkListPrice.value[listCur].length > 0) {
                    const filterList = listCur === 0 ? goodsPayTokenList0 : goodsPayTokenList1
                    const filter = filterList.value[0].priceRange.filter((item, index: number) => {
                        return checkListPrice.value[listCur].includes(index)
                    })
                    const filterResult = filter.map(item => {
                        return item['value']
                    })
                    filterArr.push(filterResult.join(','))
                }

                if (filterArr.length > 0) {
                    filterPara = filterArr.join(',')
                }

                flag = true
            } else {
                filterPara = filterAPIPara.value[listCur]
            }


            if (flag) {
                let filterParaURL = ''
                if (pagePara === 1 && !isMobile.value) {
                    switch (listCur) {
                        case 0:
                            if (refPaginationMP0.value) {
                                refPaginationMP0.value.setCurPage(1)
                            }
                            break
                    }

                }
                filterAPIPara.value[listCur] = ''
                if (filterSearchAPIPara.value[listCur].length > 0) {
                    if (filterPara.length > 0) {
                        filterParaURL = filterPara + ',' + filterSearchAPIPara.value[listCur]
                    } else {
                        filterParaURL = filterSearchAPIPara.value[listCur]
                    }

                } else {
                    filterParaURL = filterPara
                }

                switch (listCur) {
                    case 0:
                        const urlMarketList = `${API.itemListMP + chainId}/${pagePara}/${pagePerPara}/?order=${sortPara}&filter=${filterParaURL}`
                        marketItemListData = (await axios.get(urlMarketList)).data.data
                        marketItemList.value[listCur] = marketItemListData.list
                        recordCount.value[listCur] = marketItemListData.count
                        orderAPIPara.value[listCur] = sortPara
                        filterAPIPara.value[listCur] = filterPara
                        break
                }
            }

            if (isMobile.value) {
                isLoadingMore.value[listCur] = false
            }
        }

        const go2Info = (index: number) => {
            saveMPStorageSetting()
            const nft = marketItemList.value[listIndexNumber.value][index]
            if (listIndexNumber.value === 2) {
                router.push(`/nftrune/${nft['tokenid']}`)
            } else {
                router.push(`/nft/${nft['tokenid']}`)
            }

        }

        interface localStorageMPConfigObject {
            searchKeywords: string,
            pageNow: number,
            checkListCha: number[],
            countListCha: number,
            checkListRarity: number[],
            countListRarity: number,
            checkListAttr: number[],
            checkListPrice: number[],
            sortCheckedIndex: number,
            countSortCondition: number,
            // countListPrice: number,
        }

        interface localStorageMPConfig {
            data: localStorageMPConfigObject,
            scrollTop: number
        }

        const getApproveTokenAndNeedAmount = (index: number) => {
            const payToken = getGoodsPayTokenList()[0]
            const cardInfo = marketItemList.value[listIndexNumber.value][index]

            return {
                symbol: payToken.symbol,
                amount: new BigNumber(cardInfo['price']).toNumber()
            }
        }

        const checkApprove = async (index: number) => {
            const payToken = getGoodsPayTokenList()[0]
            const contractAddr = marketItemListData.desktop

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount(index).amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approve = async (index: number) => {
            const action = 'sale'
            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request-' + action)
            const payToken = getGoodsPayTokenList()[0]
            const contractAddr = marketItemListData.desktop

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': payToken.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = '0'
                    contractToken
                        .approve(contractAddr, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.allowance(
                                        walletAddress.value,
                                        contractAddr
                                    )
                                    payToken.approve = approveBN / (10 ** payToken.decimals)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': payToken.symbol })
                                    })
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success-' + action)
                                    buy(index)
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade-' + action)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request-' + action)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                            })
                        })
                }
            }
        }

        const buy = async (index: number) => {
            TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-request')
            const cardInfo = marketItemList.value[listIndexNumber.value][index]
            const flagApprove = await checkApprove(index)
            if (!flagApprove) {
                // createNotification({
                //   type: 'error',
                //   message: `Your token approve count is not enough`,
                // })
                approvePre(index)
                return
            }
            const id = cardInfo['tokenid']
            const payToken = getGoodsPayTokenList()[0]
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractDesktop = new ethers.Contract(
                            marketItemListData.desktop,
                            TapDesktop,
                            signer
                        )
                        const price = (1 * cardInfo['price'])
                        if ((price) > Number(payToken.balance)) {
                            createNotification({
                                type: 'error',
                                message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': payToken.symbol })}`,
                            })
                            return
                        }
                        const amount = 1
                        // const amountBN = ethers.utils.parseUnits(amount.toString(), 18).toString()
                        // LogUtils.debug(`${cardInfo['orderid']}|${amountBN}`)
                        const sendPromise = contractDesktop.marketBuy(cardInfo['orderid'], amount)

                        const uuid = createNotification({
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buying'), 'id': id, 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.successful') })}`
                                    })
                                    TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    setTimeout(() => {
                                        closeModal()
                                        loadData()
                                    }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)

                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                                    duration: 5
                                })
                            })
                    } catch (e) {
                        console.error(e)
                        TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-request')
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                        })
                    }
                }
            }
        }

        const approvePre = (index: number) => {
            selectedBuyIndex.value = index
            showModal(2)
        }

        const loadMPStorageSetting = () => {
            const json = localStorage.getItem(localStorageMPConfig)
            if (json && json.length > 0) {
                const jo = <localStorageMPConfig>JSON.parse(json)
                const data = jo.data
                const sTop = jo.scrollTop

                if (sTop > 0) {
                    mobileScrollTop.value = sTop
                }

                listIndexNumber.value = 0

                radioIndexNumber.value = 0

                const ele = data
                searchKewords.value[0] = ele.searchKeywords
                if (filterCharacterList0.value.length === ele.countListCha) {
                    checkListCha.value[0] = ele.checkListCha
                }
                if (filterRarityList0.value.length === ele.countListRarity) {
                    checkListRarity.value[0] = ele.checkListRarity
                }
                valueSliderEarth.value[0] = ele.checkListAttr[0]
                valueSliderWater.value[0] = ele.checkListAttr[1]
                valueSliderFire.value[0] = ele.checkListAttr[2]
                valueSliderWind.value[0] = ele.checkListAttr[3]
                checkListPrice.value[0] = ele.checkListPrice

                if (conditionFilter0.value.length === ele.countSortCondition) {
                    sortCheckedIndex.value[0] = ele.sortCheckedIndex
                }

                pageNow.value[0] = ele.pageNow

                //gen search para
                if (searchKewords.value[0].length > 0) {
                    const encode = encodeURI(searchKewords.value[0])
                    filterSearchAPIPara.value[0] = 'sn' + encode
                    isShowPCSearchRight.value = true
                }

                //gen sort para

                let sortPara
                let sortCheckedIndexPara
                let sort

                //sort0
                sortCheckedIndexPara = sortCheckedIndex.value[0]
                sort = conditionFilter0.value[sortCheckedIndexPara]

                if (sortCheckedIndexPara === 0) {
                    sortPara = '-o1'
                } else {
                    sortPara = conditionFilter0.value[sortCheckedIndexPara].value
                    if (sortCheckedIndexPara === 1) {
                        sortPara = '+' + sortPara
                    } else if (sortCheckedIndexPara === 2) {
                        sortPara = '-' + sortPara
                    }
                }
                orderAPIPara.value[0] = sortPara

                // console.log(pageNow.value)
                localStorage.removeItem(localStorageMPConfig)
            }
        }

        const saveMPStorageSetting = () => {
            const cur = getListCur()
            var sTop = document.documentElement.scrollTop || document.body.scrollTop

            // console.log(sTop);
            const data =
            {
                searchKeywords: searchKewords.value[0],
                pageNow: pageNow.value[0],
                checkListCha: checkListCha.value[0],
                countListCha: filterCharacterList0.value.length,
                checkListRarity: checkListRarity.value[0],
                countListRarity: filterRarityList0.value.length,
                checkListAttr: [valueSliderEarth.value[0], valueSliderWater.value[0], valueSliderFire.value[0], valueSliderWind.value[0]],
                checkListPrice: checkListPrice.value[0],
                sortCheckedIndex: sortCheckedIndex.value[0],
                countSortCondition: conditionFilter0.value.length,
            }

            const config = {
                data: data,
                scrollTop: sTop
            }

            localStorage.setItem(localStorageMPConfig, JSON.stringify(config))
        }

        const loadPage0 = async (walletAddress: Ref<string>) => {
            const payTL = []
            const bnbRange = []
            const busdRange = []

            const busdPrice = marketAPIFilter!.pt1price
            for (let key in busdPrice) {
                const valueBusd = (busdPrice as any)[key]
                for (let keyBusd in valueBusd) {
                    busdRange.push({
                        key: keyBusd,
                        value: (valueBusd as any)[keyBusd]
                    })
                }
            }
            let eleUSDT: goodsPayToken = {
                goodsid: 0,
                decimals: 18,
                address: isDebug ? pageChainConfig.chainTokensConfig.TUSDT : pageChainConfig.chainTokensConfig.USDT,
                symbol: isDebug ? "T-USDT" : "USDT",
                priceRange: busdRange,
                balance: '',
                approve: 0
            }
            payTL.push(eleUSDT)

            // const bnbPrice = marketAPIFilter!.pt0price
            // for (let key in bnbPrice) {
            //   const valueBnb = (bnbPrice as any)[key]
            //   for (let keyBnb in valueBnb) {
            //     bnbRange.push({
            //       key: keyBnb,
            //       value: (valueBnb as any)[keyBnb]
            //     })
            //   }
            // }
            // const eleBNB: goodsPayToken = {
            //   goodsid: 0,
            //   decimals: 18,
            //   address: "",
            //   symbol: "BNB",
            //   priceRange: bnbRange,
            //   balance: '',
            //   approve: 0
            // }
            // payTL.push(eleBNB)

            if (walletAddress.value.length > 0) {
                let calls: any[] = []
                let returnValue = null

                LogUtils.debug(`Get Approve Start(${walletAddress.value})...`)

                for (let i = 0; i < payTL.length; i++) {
                    const ele = payTL[i]
                    calls = calls.concat([
                        {
                            target: ele.address,
                            call: ['balanceOf(address)(uint256)', walletAddress.value],
                            returns: [['PAYSALE_BALANCE_' + i, (val: any) => val]]
                        },
                        {
                            target: ele.address,
                            call: [
                                'allowance(address,address)(uint256)',
                                walletAddress.value,
                                pageChainConfig.chainABIConfig.DESKTOP
                            ],
                            returns: [['PAYSALE_APPROVE_' + i, (val: any) => val / 10 ** ele.decimals]]
                        }
                    ])
                }

                returnValue = await aggregateMarketPlace(calls, pageChainConfig.multicallConfig)
                const returnResult = returnValue.results.transformed

                for (let i = 0; i < payTL.length; i++) {
                    const ele = payTL[i]
                    ele.balance = TOOL.scientificToNumber(
                        new BigNumber(returnResult['PAYSALE_BALANCE_' + i].toString())
                            .div(10 ** ele.decimals)
                            .toString()
                    )
                    ele.approve = returnResult['PAYSALE_APPROVE_' + i]
                }
            }

            goodsPayTokenList0.value = payTL

            const radioCur = 0
            const sortCur = sortCheckedIndex.value[radioCur]
            const sortData = conditionFilter0.value[sortCur]

            if (!isMobile.value) {
                await getPageData(sortCur, sortData.order, radioCur, pageNow.value[0])
            } else {
                const initPageCount = pagePerCount.value * pageNow.value[0]
                await getPageData(sortCur, sortData.order, radioCur, 1, initPageCount)
            }
        }

        const loadData = async (index: number = 0) => {
            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            const { walletAddress } = toRefs(props)



            chainId = await TOOL.getChainId()

            const urlStatData = `${API.urlMPBuyNFTStatData}${chainId}`
            statData.value = (await axios.get(urlStatData)).data.data

            const urlMarketFilter = `${API.filterMP}${chainId}/`
            marketAPIFilter = (await axios.get(urlMarketFilter)).data.data

            //0 - mp skins
            const sortListMP = marketAPIFilter!.ordertype
            if (sortListMP.length > 0) {
                const sortObj = sortListMP[0]
                const filterSL = []
                for (const key in sortObj) {
                    if (sortObj.hasOwnProperty(key)) {
                        filterSL.push({
                            name: key,
                            value: sortObj[key],
                            order: 0
                        })
                    }
                }

                const conditionF = []

                const defaultC = filterSL[0]

                conditionF.push({
                    key: 'recently-listed',
                    name: 'Recently Listed',
                    value: defaultC.value,
                    order: 0
                })

                const priceC = filterSL[1]
                conditionF.push({
                    key: 'lowest-price',
                    name: 'Lowest Price',
                    value: priceC.value,
                    order: 1
                })
                conditionF.push({
                    key: 'highest-price',
                    name: 'Highest Price',
                    value: priceC.value,
                    order: 2
                })

                conditionFilter0.value = conditionF
            }

            const chaListMP = marketAPIFilter!.filterchar
            if (chaListMP.length > 0) {
                const filterCha = []
                for (const cha of chaListMP) {
                    for (const key in cha) {
                        if (cha.hasOwnProperty(key)) {
                            filterCha.push({
                                name: key,
                                value: cha[key]
                            })
                        }
                    }
                }
                filterCharacterList0.value = filterCha
            }

            const rarityListMP = marketAPIFilter!.filterrarity
            if (rarityListMP.length > 0) {
                const filterRarity = []
                for (const rarity of rarityListMP) {
                    for (const key in rarity) {
                        if (rarity.hasOwnProperty(key)) {
                            filterRarity.push({
                                name: key,
                                value: rarity[key]
                            })
                        }
                    }
                }
                filterRarityList0.value = filterRarity
            }

            const attrListMP = marketAPIFilter!.filterattr
            if (attrListMP.length > 0) {
                const attrObj = attrListMP[0]
                const filterATTR = []
                for (const key in attrObj) {
                    if (attrObj.hasOwnProperty(key)) {
                        filterATTR.push({
                            name: key,
                            value: attrObj[key]
                        })
                    }
                }
                filterAttrList0.value = filterATTR
            }

            loadMPStorageSetting()

            switch (listIndexNumber.value) {
                case 0:
                    await loadPage0(walletAddress)
                    break
            }

            isLoading.value = false

            if (isLoadingInit.value) {
                isLoadingInit.value = false
                ctx.emit('update', false)
            }

            nextTick(() => {
                // Loading should be closed asynchronously
                loadingInstance.close()
                if (walletAddress.value.length === 0) {
                    buttonDisabled.value = true
                } else {
                    buttonDisabled.value = false
                }
                // console.log(pageNow.value)
                if (!isMobile.value) {
                    switch (listIndexNumber.value) {
                        case 0:
                            if (refPaginationMP0.value) {
                                refPaginationMP0.value.setCurPage(pageNow.value[0])
                            }
                            break
                        case 1:
                            if (refPaginationMP1.value) {
                                refPaginationMP1.value.setCurPage(pageNow.value[1])
                            }
                            break
                        case 2:
                            if (refPaginationMP2.value) {
                                refPaginationMP2.value.setCurPage(pageNow.value[2])
                            }
                            break
                    }
                }

                if (isMobile.value && mobileScrollTop.value > 0) {
                    window.scrollTo({
                        top: mobileScrollTop.value,
                        behavior: "smooth"
                    })
                }
            })
        }

        return {
            sortCheckedIndex,
            isLoading,
            isExpand,
            marketItemList,
            pagePerCount,
            pageNow,
            pageCount,
            recordCount,
            getPageData,
            listMyNFT,
            getLowerCase,
            mousewheel,
            isShowModal,
            modalShowIndex,
            showModal,
            closeModal,
            orderAPIPara,
            filterAPIPara,
            getDepartPageData,
            // pageToJump,
            isMobile,
            getTime,
            isLoadingMore,
            buttonDisabled,
            isShowPCSelectRight,
            checkListCha,
            checkListRarity,
            changeCheckboxGroup,
            checkListPrice,
            valueSliderEarth,
            valueSliderWater,
            valueSliderFire,
            valueSliderWind,
            conditionFilter0,
            conditionFilter1,
            filterCharacterList0,
            filterRarityList0,
            filterAttrList0,
            filterCharacterList1,
            filterRarityList1,
            filterAttrList1,
            goodsPayTokenList0,
            goodsPayTokenList1,
            refPaginationMP0,
            refPaginationMP1,
            refPaginationMP2,
            switchSort,
            resetFilter,
            isShowPCSearchRight,
            searchKewords,
            searchInput,
            gtmTrack,
            refContent,
            radioIndex,
            radioIndexNumber,
            getConditionFilter,
            getFilterCharacterList,
            getFilterRarityList,
            getFilterAttrList,
            getGoodsPayTokenList,
            changeRadioGroup,
            go2Info,
            approve,
            buy,
            selectedBuyIndex,
            approvePre,
            marketTypeIdx,
            switchMarketType,
            listIndexNumber,
            switchRadioGroupBussinessType,
            closeSearch,
            getApproveTokenAndNeedAmount,
            handleInputSearchFocusAndBlur,
            isShowFilterCharacter,
            isShowFilterRarity,
            isShowFilterAttr,
            isShowFilterOrderBy,
            isShowFilterPrice,
            isShowPCLeftMenu,
            statData
        }
    }
})
</script>
<style lang="stylus" scoped>
  pc-mobile-breakpoint = 'screen and (max-width: 1140px)'
  #root,#app{
    height: auto
    overflow-y: auto
  }

  .top-block{
    background: rgba(255,255,255,0.1)
    padding 16PX 0 16PX 50PX
    display: flex
    align-items: center
    border-bottom: 1PX solid #3D3D3D

    img{
        aspect-ratio: 58.8/66.5
        width: 58.8PX
        margin-right: 18PX;
    }

    .stat-block{
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-right: 100PX;

        .line1{
            font-family: Avenir-Bold;
            font-size: 14PX;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 20PX;
        }
        .line2{
            font-family: Avenir;
            font-size: 14PX;
            color: #989898;
            letter-spacing: 0;
        }

        &.long{
            margin-right: 200PX;
        }
    }


  }  

  .content{
    display: flex;
    width: 100%
  }

  .left{
    .filter-type-search{
        padding: 4PX 10PX;
        color: #ffffff;
        background: rgba(85,101,189,0.50);
        border: 1PX solid #5565BD;
        border-radius: 2PX;
        margin: 0 32PX 30PX 26PX;
        display: flex;

        svg{
            height: 10PX;
            width: 10PX;
            margin-right: 10PX;
        }

        input{
            flex: 1;
            font-size: 12PX;
            font-family: Avenir;
            line-height: 16PX;
            caret-color: #ffffff;
            color: #ffffff;
            border: none;
            outline: none;
            background: transparent;
        }

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #C7CAD5;
            opacity: 1; /* Firefox */
        }
    }
    .filter-type-block{
        .title-block{
            display flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding 3PX 32PX 3PX 26PX;
            background #1C1C1C

            .filter-title{
                font-size: 12PX;
                line-height: 16PX;
                color: #ffffff;
                flex: 1
                text-align: left;
                opacity 1
            }

            svg{
                width: 12PX;
                height: 12PX;
            }
        }
    }
    
   }

   @media pc-mobile-breakpoint {
    .mobile-menu{
        .filter-type-search{
                padding: 4PX 10PX;
                color: #ffffff;
                background: rgba(85,101,189,0.50);
                border: 1PX solid #5565BD;
                border-radius: 2PX;
                margin: 0 32PX 20PX 26PX;
                display: flex;

                svg{
                    height: 10PX;
                    width: 10PX;
                    margin-right: 10PX;
                }

                input{
                    flex: 1;
                    font-size: 12PX;
                    font-family: Avenir;
                    line-height: 16PX;
                    caret-color: #ffffff;
                    color: #ffffff;
                    border: none;
                    outline: none;
                    background: transparent;
                }

                ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #C7CAD5;
                    opacity: 1; /* Firefox */
                }
            }
            .filter-type-block{
                .title-block{
                    display flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    padding 3PX 32PX 3PX 26PX;
                    background #1C1C1C

                    .filter-title{
                        font-size: 12PX;
                        line-height: 16PX;
                        color: #ffffff;
                        flex: 1
                        text-align: left;
                        opacity 1
                    }

                    svg{
                        width: 12PX;
                        height: 12PX;
                    }
                }
            }
        }

        .top-block{
            background: rgba(255,255,255,0.1)
            padding 12PX 16PX
            display: flex
            align-items: center
            border-bottom: 1PX solid #3D3D3D;
            width: 100vw;
            margin-bottom: 20PX;
            margin-left: -16PX;

            img{
                aspect-ratio: 60/68
                width: 60PX
                margin-right: 10PX;
            }

            .right-block{
                flex: 1
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .top-title{
                    font-family: Avenir-Bold;
                    font-size: 12PX;
                    color: #FFFFFF;
                    letter-spacing: 0;
                    line-height: 14PX;
                    margin-bottom: 8PX;
                    text-align: left;
                }

                .stat-block-list{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .stat-block{
                        display: flex
                        flex-direction: column
                        align-items: flex-start
                        margin-right: 16PX;

                        .line1{
                            font-family: Avenir-Bold;
                            font-size: 14PX;
                            color: #FFC800;
                            letter-spacing: 0;
                            line-height: 1;
                            margin-bottom: 8PX;
                        }
                        .line2{
                            font-family: Avenir;
                            font-size: 12PX;
                            color: #989898;
                            letter-spacing: 0;
                            line-height: 1;
                        }

                        &.long{
                            margin-right: 200PX;
                        }
                    }
                }
            }


        }  
   }

   

</style>