<template>
    <div class="page-nftheadinfo height-header-padding" style="position: relative;height: auto">
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>

                        <div class="content-up">
                            <div class="left d-flex flex-column" style="padding: 0">
                                <div class="nft-state">
                                    <div class="anim"></div>
                                    <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                                </div>
                                <div class="nft-image-block rune">
                                    <img :src="nftInfo['image']" class="nft-image" />
                                </div>
                                <div class="level">
                                    <div v-for="(attr, attrIndex) of nftInfo.attrs" class="item-rune">
                                        <img :src="attr.image" />
                                        <div class="rune-text-block">
                                            <div class="rune-name">{{ attr.name }}</div>
                                            <div class="rune-value">+{{ attr.value }}</div>

                                        </div>
                                        <!-- <span class="attr-value">
                                        {{ attr.value }}
                                    </span> -->

                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <template v-if="belongto === 'soldout'">
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.sold-price') }}</span>
                                        <span class="text">{{ orderInfo['price'] }} {{ orderInfo['paytokensymbol']
                                        }}</span>
                                    </div>
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                                        <span class="text">{{ getTime(orderInfo['listtime']) }}</span>
                                    </div>
                                    <div class="info-block d-flex justify-content-between">
                                        <span class="title">{{ $t('NFTInfo.sold-time') }}</span>
                                        <span class="text">{{ getTime(orderInfo['closetime']) }}</span>
                                    </div>
                                </template>
                                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                                    <span class="text">#{{ tokenId }}</span>
                                </div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                                    <span class="text">{{ nftInfo.name }}</span>
                                </div>
                                <!-- <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div> -->

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">Class</span>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <span class="text" :class="nftInfo['star'] > 0 ? 'forsale' : ''">{{
                                                nftInfo['star']
                                        }}/3</span>
                                    </div>
                                </div>
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                                    <span class="text">{{ owner }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{{ $t('Dialog.back') }}</span>
                </div>
                <div class="top-block">
                    <div class="nft-state">
                        <div class="anim"></div>
                        <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                    </div>
                </div>
                <div class="nft-image-block rune">
                    <img :src="nftInfo['image']" class="nft-image" />
                </div>

                <template v-if="belongto === 'soldout'">
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.sold-price') }}</span>
                        <span class="text">{{ orderInfo['price'] }} {{ orderInfo['paytokensymbol']
                        }}</span>
                    </div>
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                        <span class="text">{{ getTime(orderInfo['listtime']) }}</span>
                    </div>
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.sold-time') }}</span>
                        <span class="text">{{ getTime(orderInfo['closetime']) }}</span>
                    </div>
                </template>
                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                    <span class="text">#{{ tokenId }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                    <span class="text">{{ nftInfo.name }}</span>
                </div>
                <!-- <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div> -->

                <div class="info-block d-flex justify-content-between">
                    <span class="title">Class</span>
                    <div class="d-flex align-items-center justify-content-end">
                        <span class="text" :class="nftInfo['star'] > 0 ? 'forsale' : ''">{{
                                nftInfo['star']
                        }}/3</span>
                    </div>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                    <span class="text">{{ owner }}</span>
                </div>
            </div>
        </template>


    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateNFTInfo } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElTooltip } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
import ERC20 from '../abis/ERC20.json'
import ERC721 from '../abis/ERC721.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import NFTInGameAcc from '../abis/NFTInGameAcc.json'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import TapDesktop from '../abis/TapDesktop.json'

export default defineComponent({
    name: 'NFTRuneRecordInfo',
    components: { ElButton, ElLoading },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isMobile = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const inputAmountBuy = ref(1)
        const inputWalletAddressTransfer = ref('')
        const belongto = ref('')
        const owner = ref('')
        const isOwner = ref(false)
        const isRenter = ref(false)
        const historyIndex = ref('0')
        const historyIndexNumber = ref(0)
        const indexApproveNFT = ref(-1)
        const tokenId = ref(0)
        let nftRuneInfoAPIData: {
            nftrechargecontract: string
            nftassets: string
            rechargefee: number
            tokenid: number
            chainid: number
            belongto: string
            tag: string
            owner: string
            info: {
                image: string
                preview: string
                attrs: any[]
                medium: string
                name: string
                orderid: number
                price: number,
                star: 1
            },
            soldoutinfo: {
                buyer: "",
                closetime: 0,
                listtime: 0,
                orderid: 0,
                orderorgid: 0,
                owner: "",
                paytoken: "",
                paytokendecimals: 0,
                paytokensymbol: "",
                price: 0
            },
            runedefineid: number
            saletoken: string
            saletokensymbol: string
            saledecimals: number
            salefeeRate: number
            salegoodsid: number
            marketcontract: string

        } | null = null
        const gameAccount = ref('')
        const nftApprove = ref({
            market: false,
            ingame: false,
            lending: false
        })
        let assetsAPIInfo = {
            goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
            mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            wallet: { accountid: 0, address: '', nickname: '' },
        }
        const nftInfo = ref({
            image: '',
            preview: '',
            attrs: [] as any,
            medium: '',
            name: '',
            orderid: 0,
            price: 0,
            star: 0
        })

        interface goodsPayToken {
            approve: number,
            balance: string
            decimals: number,
            address: string,
            symbol: string,
            feerate: number
            price: number
        }
        const goodsPaySale: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })

        const rechargeInfo: Ref<any> = ref({
            fee: 0
        })

        const nftStateMap = {
            forsell: 'For Sale',
            rentout: 'Rent Out',
            rentin: 'Rent In',
            wallet: 'Collection',
            ingame: 'In Game',
            outgame: 'Transacting',
            forrent: 'For Rent',
            soldout: 'Sold Out'
        }

        const inputAmount = ref('1')

        const modalInfotip = ref('')
        const isShowLoginLink = ref(false)
        let loadingInstance: any = null
        const pageSrcPara = ref({
            src: '',
            type: -1
        })
        let orderInfo = ref({
            buyer: "",
            closetime: 0,
            listtime: 0,
            orderid: 0,
            orderorgid: 0,
            owner: "",
            paytoken: "",
            paytokendecimals: 0,
            paytokensymbol: "",
            price: 0
        })

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })

            const tokenid = router.currentRoute.value.params.id
            tokenId.value = Number(tokenid)


            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
        })

        const changeRadioGroup = () => {
            historyIndexNumber.value = parseInt(historyIndex.value, 10)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const clearNoNum = (numStr: string) => {
            numStr = numStr.replace(/[^\d\.]/g, "");  //清除“数字”和“.”以外的字符
            numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const clearNoNumInteger = (numStr: string) => {
            numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
            numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const watchInput = () => {
            const price = inputAmount.value
            let input_num_str = price.toString()
            input_num_str = clearNoNum(input_num_str)
            inputAmount.value = input_num_str
        }

        const goBack = () => {
            router.back()
        }

        const loadData = async () => {
            buttonDisabled.value = true
            // if (loadingInstance === null) {
            loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            // }

            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)

            const { walletAddress } = toRefs(props)
            LogUtils.debug(walletAddress.value)

            const wa = walletAddress.value.length > 0 ? walletAddress.value : BLOCKCHAIN_CONFIG.ZERO_ADDRESS
            const orderid = router.currentRoute.value.params.orderid

            const urlNFTInfo = `${API.nftRuneAssetsInfo}${chainId}/${tokenId.value}/${wa}/?soldoutid=${orderid}`
            try {
                const reuqestData = (await axios.get(urlNFTInfo)).data
                LogUtils.debug(reuqestData)
                nftRuneInfoAPIData = reuqestData.data
                // nftRuneInfoAPIData = {
                //     nftrechargecontract: "0x6C5bD54A7a57648d0f384a82e127C758fD657E64",
                //     nftassets: "0xc3acA4DcAf59665dD559F636df02b6Ffb3CEBb16",
                //     rechargefee: 0,
                //     tokenid: 10000008,
                //     chainid: 97,
                //     belongto: "forsell",
                //     tag: "sasell",
                //     owner: "0x1266a58803E17126E50F7Ce27A83de0FCAcC19f8",
                //     info: {
                //         image: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         preview: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         attrs: [{ id: 1, name: "HP", value: "2006" }, { id: 2, name: "ATK", value: "167" }],
                //         medium: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         name: "Storm",
                //         orderid: 164,
                //         price: 100,
                //         star: 1,
                //     },
                //     runedefineid: 21001,
                //     salegoodsid: 0,
                //     saletoken: "0xf0931E2d258fe49BF4C352EE436FD6D98AdD434f",
                //     salefeeRate: 0.02,
                //     saletokensymbol: "T-USDT",
                //     saledecimals: 18,
                //     marketcontract: "0x6C5bD54A7a57648d0f384a82e127C758fD657E64"

                // }
                if (nftRuneInfoAPIData) {
                    nftInfo.value = nftRuneInfoAPIData.info
                    belongto.value = nftRuneInfoAPIData.belongto
                    owner.value = nftRuneInfoAPIData.owner
                    tokenId.value = nftRuneInfoAPIData.tokenid
                    orderInfo.value = nftRuneInfoAPIData.soldoutinfo

                    const paySale: goodsPayToken = {
                        approve: 0,
                        balance: '',
                        decimals: nftRuneInfoAPIData.saledecimals,
                        address: nftRuneInfoAPIData.saletoken,
                        symbol: nftRuneInfoAPIData.saletokensymbol,
                        feerate: nftRuneInfoAPIData.salefeeRate,
                        price: 0
                    }

                    LogUtils.debug(paySale)

                    const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)

                    if (walletAddress.value.length > 0) {
                        const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`

                        axios.get(urlAssetsAPIInfo)
                            .then(async (response) => {
                                assetsAPIInfo = response.data.data
                                gameAccount.value = assetsAPIInfo!.wallet.nickname

                                buttonDisabled.value = false

                                let calls: any[] = []
                                let returnValue = null

                                LogUtils.debug(`Get Approve Start(${walletAddress.value})...`)

                                calls = calls.concat([
                                    {
                                        target: paySale.address,
                                        call: ['balanceOf(address)(uint256)', walletAddress.value],
                                        returns: [['PAYSALE_BALANCE', (val: any) => val]]
                                    },
                                    {
                                        target: paySale.address,
                                        call: [
                                            'allowance(address,address)(uint256)',
                                            walletAddress.value,
                                            nftRuneInfoAPIData!.marketcontract
                                        ],
                                        returns: [['PAYSALE_APPROVE', (val: any) => val / 10 ** paySale.decimals]]
                                    },
                                ])

                                returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                                const returnResult = returnValue.results.transformed

                                calls = []
                                paySale.balance = TOOL.scientificToNumber(
                                    new BigNumber(returnResult['PAYSALE_BALANCE'].toString())
                                        .div(10 ** paySale.decimals)
                                        .toString()
                                )
                                paySale.approve = returnResult['PAYSALE_APPROVE']


                                goodsPaySale.value = paySale

                                LogUtils.debug('Get Approve End...')
                            })
                            .catch((e) => {

                            })

                        // assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
                    } else {
                        goodsPaySale.value = paySale
                    }



                    if (isOwner.value) {
                        let calls: any[] = []
                        calls = calls.concat([
                            {
                                target: nftRuneInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftRuneInfoAPIData.nftrechargecontract
                                ],
                                returns: [['NFT_APPROVE_INGAME', (val: any) => val]]
                            },
                            {
                                target: nftRuneInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftRuneInfoAPIData.marketcontract
                                ],
                                returns: [['NFT_APPROVE_MARKET', (val: any) => val]]
                            },
                        ])

                        const returnValue = await aggregateNFTInfo(
                            calls,
                            pageChainConfig.multicallConfig
                        )

                        nftApprove.value.ingame = returnValue.results.transformed.NFT_APPROVE_INGAME
                        nftApprove.value.market = returnValue.results.transformed.NFT_APPROVE_MARKET
                    }

                    LogUtils.debug('Loading end')


                    nextTick(() => {
                        // Loading should be closed asynchronously
                        loadingInstance.close()
                        isLoading.value = false

                        if (isLoadingInit.value) {
                            isLoadingInit.value = false
                            ctx.emit('update', false)
                        }
                    })
                } else {
                    nextTick(() => {
                        // Loading should be closed asynchronously
                        loadingInstance.close()
                        LogUtils.error(reuqestData)
                        isLoading.value = false
                        if (isLoadingInit.value) {
                            isLoadingInit.value = false
                            ctx.emit('update', false)
                        }
                    })
                }
            } catch (e: any) {
                LogUtils.error(e)
            }


        }

        return {
            buttonDisabled,
            isLoading,
            isShowModal,
            modalShowIndex,
            isMobile,
            nftInfo,
            getTime,
            inputAmountBuy,
            goodsPaySale,
            belongto,
            nftStateMap,
            isOwner,
            isRenter,
            owner,
            historyIndex,
            historyIndexNumber,
            changeRadioGroup,
            gameAccount,
            nftApprove,
            inputAmount,
            modalInfotip,
            watchInput,
            indexApproveNFT,
            router,
            isShowLoginLink,
            goBack,
            pageSrcPara,
            inputWalletAddressTransfer,
            tokenId,
            orderInfo
        }
    }
})
</script>
<style lang="stylus">
.box-item {
//   width: 110PX;
//   margin-top: 10PX;
    margin-left: 4PX;
    width: 16PX;
        height: 16PX;
}

.el-icon.box-item{
    cursor pointer
    svg{
        width: 16PX;
        height: 16PX;
    }
}
</style>