<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content activity d-flex flex-column align-items-center">
      <span class="title">Activity</span>
      <div class="input-item first d-flex flex-column">
        <span class="input-title">Wallet Address</span>
        <div v-if="walletAddress.length > 0" class="input-block d-flex align-items-center">
          <input class="wallet" readonly v-model.trim="walletAddress" style="flex: 1;" />
        </div>
        <button v-else @click="walletConnect">WALLET CONNECT</button>
      </div>
      <template v-if="!isLoading && walletAddress.length > 0">
        <div v-if="activityDataList.length > 0" class="grid-server" style="margin-bottom: 0;">
          <a :class="activityIndex === 0 ? 'active' : ''" @click="activityIndex = 0; errorMsg = ''">Invitation</a>

          <a v-for="(act, actIndex) of activityDataList" :key="'act-' + actIndex"
            :class="activityIndex === (actIndex + 1) ? 'active' : ''"
            @click="activityIndex = (actIndex + 1); errorMsg = ''">{{ act['actname'] }}</a>
        </div>
        <template v-if="activityIndex === 0">
          <div class="input-item d-flex flex-column">
            <span class="input-title">Invitation Code</span>
            <div class="input-block d-flex align-items-center">
              <input class="wallet" readonly v-model.trim="activityCodeList[activityIndex]" style="flex: 1;" />
            </div>
          </div>
          <span class="error-block">{{ errorMsg }}</span>
          <button style="margin-top: 30px;" class="play-logout play-logout-paddingbig"
            :disabled="activityCodeList[activityIndex].length > 0" @click="getInvitationCode">GET INVITATION
            CODE</button>
        </template>
        <template v-else-if="activityIndex > 0">
          <div class="input-item d-flex flex-column">
            <span class="input-title">Exchange Code</span>
            <div class="input-block d-flex align-items-center">
              <input class="wallet" readonly v-model.trim="activityCodeList[activityIndex]" style="flex: 1;" />
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>

</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { ref, defineComponent, onMounted, getCurrentInstance, toRefs, watch } from 'vue'
import { getRegInvitationCode, getActCode, getActList } from '@/api/account'
import { tokenKey } from '@/settings'
import { _getCookie, _removeCookie } from '@/utils/storage'
import { ElMessage } from 'element-plus'
import { getWalletProvider } from '../utils/provider'
import TOOL from '@/utils/tool'
import LogUtils from '@/utils/LogUtils'

export default defineComponent({
  name: 'Activity',
  props: {
    walletAddress: {
      type: String,
      default: ''
    },
    isReady: {
      type: Boolean,
      default: false
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    watch(
      () => props.walletAddress,
      (newWalletAddr, oldWalletAddr) => {
        // LogUtils.debug(newWalletAddr)
        // LogUtils.debug(oldWalletAddr)
        if (!isLoadingInit.value) {
          if (newWalletAddr !== oldWalletAddr) {
            LogUtils.debug('load from change wallet...')
            loadData()
          }
        }
      },
    )

    watch(
      () => props.isRefresh,
      (newReady, oldReady) => {
        LogUtils.debug(newReady)
        LogUtils.debug(oldReady)
        if (newReady && newReady !== oldReady) {
          if (props.walletAddress.length === 0) {
            buttonDisabled.value = true
          } else {
            buttonDisabled.value = false
          }
          LogUtils.debug('load from ready...')
          loadData()
        }
      }
    )

    const activityIndex = ref(0)
    const buttonDisabled = ref(false)
    const isLoading = ref(true)
    const isLoadingInit = ref(true)
    const { walletAddress } = toRefs(props)
    const activityCodeList = ref([''])
    const activityDataList = ref([])
    const errorMsg = ref('')
    const router = useRouter()
    const isMobile = ref(false)
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })
      if (props.isRefresh && isLoadingInit) {
        LogUtils.debug('load from onMounted...')
        loadData()
      }
    })

    const walletConnect = async () => {
      BUS.$emit('WALLET_CONNECT', {})
      // const provider = await connectWeb3Provider()
      // if (provider) {
      //   await fetchWalletProvider()

      // }
    }

    const getInvitationCode = async () => {
      errorMsg.value = ''
      const provider = await getWalletProvider()
      if (provider) {
        const signer = provider.getSigner()
        const signature = await signer.signMessage("hello,tapfantasy")
        // LogUtils.debug(signature)

        const dataReq = {
          wallet: walletAddress.value,
          signature: signature,
          serverid: await TOOL.getChainId()
        }

        getRegInvitationCode(dataReq)
          .then((response) => {
            const { data } = response
            activityCodeList.value[activityIndex.value] = data.code
          })
          .catch((error) => {
            errorMsg.value = error
            isLoading.value = false
            return false
          })
      }
    }

    const getExchangeCode = async () => {
      errorMsg.value = ''
      const provider = await getWalletProvider()
      if (provider) {
        const signer = provider.getSigner()
        const signature = await signer.signMessage("hello,tapfantasy")
        LogUtils.debug(signature)

        const dataReq = {
          wallet: walletAddress.value,
          signature: signature,
          serverid: await TOOL.getChainId()
        }

        getRegInvitationCode(dataReq)
          .then((response) => {
            const { data } = response
            activityCodeList.value[activityIndex.value] = data.regcode
          })
          .catch((error) => {
            errorMsg.value = error
            isLoading.value = false
            return false
          })
      }
    }

    const loadData = async () => {
      activityCodeList.value = ['']
      activityDataList.value = []

      if (walletAddress.value.length > 0) {
        errorMsg.value = ''
        const provider = await getWalletProvider()
        if (provider) {
          const signer = provider.getSigner()
          const signature = await signer.signMessage("getactlist")
          LogUtils.debug(signature)

          let dataReq = {
            wallet: walletAddress.value,
            serverid: await TOOL.getChainId(),
            signature: signature
          }
          getActList(dataReq)
            .then((response) => {
              const { data } = response
              LogUtils.debug(data)
              activityDataList.value = data
              if (activityDataList.value.length > 0) {
                const list: string[] = []
                let listIds: number[] = []
                let actids = ''
                for (const ele of activityDataList.value) {
                  list.push('')
                  listIds.push(ele['actid'])
                  actids = listIds.join(',')
                }

                const serverid = dataReq.serverid

                let dataReqActCode = {
                  wallet: walletAddress.value,
                  serverid: serverid,
                  signature: signature,
                  actids: actids
                }

                getActCode(dataReqActCode)
                  .then((response) => {
                    const { data } = response
                    LogUtils.debug(data)

                    for (let i = 0; i < listIds.length; i++) {
                      list[i] = data[listIds[i]]
                    }
                    activityCodeList.value = activityCodeList.value.concat(list)
                  })
                  .catch((error) => {

                  })
              }
            })
            .catch((error) => {
              errorMsg.value = error
              isLoading.value = false
              return false
            })

        }
      }


      isLoading.value = false
      if (isLoadingInit.value) {
        isLoadingInit.value = false
        ctx.emit('update', false)
      }
    }

    return {
      activityIndex,
      isLoading,
      walletConnect,
      getInvitationCode,
      getExchangeCode,
      errorMsg,
      activityCodeList,
      activityDataList,
      isMobile,
      buttonDisabled
    }
  }
})
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
