<template>
    <div class="page-nftinfo height-header-padding" style="position: relative;height: auto">
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>
                        <div class="left d-flex flex-column">
                            <div class="nft-state">
                                <div class="anim"></div>
                                <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                            </div>
                            <div class="level d-flex align-items-center justify-content-start">
                                <img
                                    :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                            </div>
                            <div class="nft-image-block">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                            <div class="attr-list d-flex">
                                <div class="item">
                                    <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                    <span class="text earth">
                                        {{ $t('Common.earth') }}
                                    </span>
                                    <span class="value earth">
                                        {{
                                                nftInfo['earth']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_blue_water_ball.png" />
                                    <span class="text water">
                                        {{ $t('Common.water') }}
                                    </span>
                                    <span class="value water">
                                        {{
                                                nftInfo['water']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_red_fire_ball.png" />
                                    <span class="text fire">
                                        {{ $t('Common.fire') }}
                                    </span>
                                    <span class="value fire">
                                        {{
                                                nftInfo['fire']
                                        }}
                                    </span>
                                </div>
                                <div class="item">
                                    <img src="/static/images/element_attr_green_wind_ball.png" />
                                    <span class="text wind">
                                        {{ $t('Common.wind') }}
                                    </span>
                                    <span class="value wind">
                                        {{
                                                nftInfo['wind']
                                        }}
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="right">
                            <template v-if="belongto === 'soldout'">
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.sold-price') }}</span>
                                    <span class="text">{{ orderInfo['price'] }} {{ orderInfo['paytokensymbol']
                                    }}</span>
                                </div>
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                                    <span class="text">{{ getTime(orderInfo['listtime']) }}</span>
                                </div>
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.sold-time') }}</span>
                                    <span class="text">{{ getTime(orderInfo['closetime']) }}</span>
                                </div>
                            </template>
                            <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                                <span class="text">#{{ nftInfo['tokenid'] }}</span>
                            </div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.name') }}</span>
                                <span class="text">{{ $t(`CardList.${nftInfo['typeid']}.name`) }}</span>
                            </div>
                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div>

                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                                <div class="d-flex align-items-center justify-content-end">
                                    <!-- <img src="/static/images/element_lighting.png" /> -->
                                    <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                            nftInfo['level']
                                    }}/5</span>
                                </div>
                            </div>
                            <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.owner') }}</span>
                                <span class="text">{{ owner }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>BACK</span>
                </div>
                <div class="top-block">
                    <div class="level d-flex align-items-center justify-content-start">
                        <img :src="'/static/images/element_level_' + nftInfo['rarity'].toLowerCase() + '.png'" />
                    </div>
                    <div class="nft-state">
                        <div class="anim"></div>
                        <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                    </div>
                </div>
                <div class="nft-image-block">
                    <img :src="nftInfo['medium']" class="nft-image" />
                </div>
                <div class="attr-list d-flex">
                    <div class="item">
                        <img src="/static/images/element_attr_yellow_earth_ball.png" />
                        <span class="text earth">
                            {{ $t('Common.earth') }}
                        </span>
                        <span class="value earth">
                            {{
                                    nftInfo['earth']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_blue_water_ball.png" />
                        <span class="text water">
                            {{ $t('Common.water') }}
                        </span>
                        <span class="value water">
                            {{
                                    nftInfo['water']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_red_fire_ball.png" />
                        <span class="text fire">
                            {{ $t('Common.fire') }}
                        </span>
                        <span class="value fire">
                            {{
                                    nftInfo['fire']
                            }}
                        </span>
                    </div>
                    <div class="item">
                        <img src="/static/images/element_attr_green_wind_ball.png" />
                        <span class="text wind">
                            {{ $t('Common.wind') }}
                        </span>
                        <span class="value wind">
                            {{
                                    nftInfo['wind']
                            }}
                        </span>
                    </div>
                </div>
                <template v-if="belongto === 'soldout'">
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.sold-price') }}</span>
                        <span class="text">{{ orderInfo['price'] }} {{ orderInfo['paytokensymbol']
                        }}</span>
                    </div>
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.list-time') }}</span>
                        <span class="text">{{ getTime(orderInfo['listtime']) }}</span>
                    </div>
                    <div class="info-block d-flex justify-content-between">
                        <span class="title">{{ $t('NFTInfo.sold-time') }}</span>
                        <span class="text">{{ getTime(orderInfo['closetime']) }}</span>
                    </div>
                </template>
                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                    <span class="text">#{{ nftInfo['tokenid'] }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                    <span class="text">{{ $t(`CardList.${nftInfo['typeid']}.name`) }}</span>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.character') }}</span>
                    <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.upgrades') }}</span>
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- <img src="/static/images/element_lighting.png" /> -->
                        <span class="text" :class="nftInfo['level'] > 0 ? 'forsale' : ''">LV. {{
                                nftInfo['level']
                        }}/5</span>
                    </div>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                    <div class="address">
                        <div class="inner">
                            {{ owner }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateNFTInfo } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElMessage } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
// import TapDesktop from '../abis/TapDesktop.json'
import ERC20 from '../abis/ERC20.json'
import ERC721 from '../abis/ERC721.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import TapDesktop from '../abis/TapDesktop.json'
import NFTLending from '../abis/NFTLending.json'
import LogUtils from '@/utils/LogUtils'

export default defineComponent({
    name: 'NFTRecordInfo',
    components: { ElButton, ElLoading },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        const serverHost = ref(BLOCKCHAIN_CONFIG.serverHost)
        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isMobile = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const inputAmountBuy = ref(1)
        const belongto = ref('')
        const owner = ref('')
        const renter = ref('')
        const isOwner = ref(false)
        const isRenter = ref(false)
        const historyIndex = ref('0')
        const historyIndexNumber = ref(0)
        const indexApproveNFT = ref(-1)
        let nftInfoAPIData: {
            marketcontract: string
            ingamecontract: string
            lendingcontract: string
            nftassets: string
            rechargefee: number
            lendingfee: number
            lendingprice: number
            lendingperiod: number
            lendingperiodmin: number
            lendingperiodmax: number
            lendingguessprice: number
            lendingpaytokenid: number
            lendingtoken: string
            lendingtokensymbol: string
            lendingdecimals: number
            marketguessprice: number
            lendinglisttime: number
            lendingorderid: number
            lendingexpirytime: number
            lendingrenewable: number
            tokenid: number
            chainid: number
            belongto: string
            tag: string
            owner: string
            soldoutinfo: {
                buyer: "",
                closetime: 0,
                listtime: 0,
                orderid: 0,
                orderorgid: 0,
                owner: "",
                paytoken: "",
                paytokendecimals: 0,
                paytokensymbol: "",
                price: 0
            },
            rentininfo: {

            },
            info: {
                goodsid: number,
                typenft: boolean,
                pay: string,
                nft: string,
                orderid: number,
                stock: number,
                amount: number,
                price: number,
                timestamp: number,
                name: string,
                description: string,
                image: string,
                rarity: string,
                tokenid: number,
                level: number,
                earth: number,
                fire: number,
                water: number,
                wind: number,
                character: string,
                owner: string,
                preview: string,
                medium: string,
                feeRate: number,
                typeid: number
            }
            saletoken: string
            saletokensymbol: string
            saledecimals: number
            salefeeRate: number
            salegoodsid: number

        } | null = null
        const nftInfo = ref({
            goodsid: 0,
            typenft: false,
            pay: '',
            nft: '',
            orderid: 0,
            stock: 0,
            amount: 0,
            price: 0,
            timestamp: 0,
            name: '',
            description: '',
            image: '',
            rarity: '',
            tokenid: 0,
            level: 0,
            earth: 0,
            fire: 0,
            water: 0,
            wind: 0,
            character: '',
            preview: '',
            medium: '',
            feeRate: 0,
            typeid: 0
        })
        interface goodsPayToken {
            approve: number,
            balance: string
            decimals: number,
            address: string,
            symbol: string,
            feerate: number
            price: number
        }
        const goodsPaySale: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })
        const goodsPayLend: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })

        const nftStateMap = {
            forsell: 'For Sale',
            rentout: 'Rent Out',
            rentin: 'Rent In',
            wallet: 'Collection',
            ingame: 'In Game',
            outgame: 'Transacting',
            forrent: 'For Rent',
            soldout: 'Sold Out'
        }

        const saleHistoryList = ref([])
        const rentHistoryList = ref([])

        const lendingInfo = ref({
            period: 0,
            listtime: 0,
            orderid: 0
        })

        const modalInfotip = ref('')
        const currentTime = ref(0)
        const returnTimeArr: Ref<(string | number)[]> = ref([])
        let updateReturnTimeTask: NodeJS.Timer | null = null
        let orderInfo = ref({
            buyer: "",
            closetime: 0,
            listtime: 0,
            orderid: 0,
            orderorgid: 0,
            owner: "",
            paytoken: "",
            paytokendecimals: 0,
            paytokensymbol: "",
            price: 0
        })

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })

            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
            if (updateReturnTimeTask !== null) {
                clearInterval(Number(updateReturnTimeTask))
                updateReturnTimeTask = null
            }
        })

        const changeRadioGroup = () => {
            historyIndexNumber.value = parseInt(historyIndex.value, 10)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const goBack = () => {
            // const src = router.currentRoute.value.params.src
            // const type = router.currentRoute.value.params.type
            // const url = src === 'mp' ? `/marketplace/${type}/` : `/mynft/${type}/`
            // router.push(
            //     url
            // )

            const src = router.currentRoute.value.params.src
            if (src && src.length > 0) {
                const type = router.currentRoute.value.params.type
                const page = router.currentRoute.value.params.page
                const url = src === 'mp' ? `/marketplace/` : `/mynft/${type}/${page}/`
                router.push(
                    url
                )
            } else {
                router.back()
            }
        }

        const loadData = async () => {
            const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)

            const tokenid = router.currentRoute.value.params.id
            const state = router.currentRoute.value.params.state
            const orderid = router.currentRoute.value.params.orderid

            const { walletAddress } = toRefs(props)

            const wa = walletAddress.value.length > 0 ? walletAddress.value : BLOCKCHAIN_CONFIG.ZERO_ADDRESS

            const urlNFTInfo = `${API.nftInfo}${chainId}/${tokenid}/${wa}/?soldoutid=${orderid}`
            nftInfoAPIData = (await axios.get(urlNFTInfo)).data.data
            if (nftInfoAPIData) {
                nftInfo.value = nftInfoAPIData.info
                belongto.value = state
                orderInfo.value = nftInfoAPIData.soldoutinfo

                isOwner.value = walletAddress.value.toLowerCase() === nftInfoAPIData.owner.toLowerCase()
                owner.value = nftInfoAPIData.owner
                lendingInfo.value.period = nftInfoAPIData.lendingperiod
                lendingInfo.value.listtime = nftInfoAPIData.lendinglisttime
                lendingInfo.value.orderid = nftInfoAPIData.lendingorderid

                const paySale: goodsPayToken = {
                    approve: 0,
                    balance: '',
                    decimals: nftInfoAPIData.saledecimals,
                    address: nftInfoAPIData.saletoken,
                    symbol: nftInfoAPIData.saletokensymbol,
                    feerate: nftInfoAPIData.salefeeRate,
                    price: nftInfoAPIData.info.price
                }

                const payLending: goodsPayToken = {
                    approve: 0,
                    balance: '',
                    decimals: nftInfoAPIData.lendingdecimals,
                    address: nftInfoAPIData.lendingtoken,
                    symbol: nftInfoAPIData.lendingtokensymbol,
                    feerate: nftInfoAPIData.lendingfee,
                    price: nftInfoAPIData.lendingprice
                }

                // if (walletAddress.value.length > 0) {
                //     let calls: any[] = []
                //     let returnValue = null

                //     calls = calls.concat([
                //         {
                //             target: paySale.address,
                //             call: ['balanceOf(address)(uint256)', walletAddress.value],
                //             returns: [['PAYSALE_BALANCE', (val: any) => val]]
                //         },
                //         {
                //             target: paySale.address,
                //             call: [
                //                 'allowance(address,address)(uint256)',
                //                 walletAddress.value,
                //                 nftInfoAPIData.marketcontract
                //             ],
                //             returns: [['PAYSALE_APPROVE', (val: any) => val / 10 ** paySale.decimals]]
                //         },
                //         {
                //             target: payLending.address,
                //             call: ['balanceOf(address)(uint256)', walletAddress.value],
                //             returns: [['PAYLEND_BALANCE', (val: any) => val]]
                //         },
                //         {
                //             target: payLending.address,
                //             call: [
                //                 'allowance(address,address)(uint256)',
                //                 walletAddress.value,
                //                 nftInfoAPIData.lendingcontract
                //             ],
                //             returns: [['PAYLEND_APPROVE', (val: any) => val / 10 ** payLending.decimals]]
                //         },
                //     ])

                //     returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                //     const returnResult = returnValue.results.transformed

                //     calls = []
                //     paySale.balance = returnResult['PAYSALE_BALANCE']
                //     paySale.approve = returnResult['PAYSALE_APPROVE'] / (10 ** paySale.decimals)

                //     payLending.balance = returnResult['PAYLEND_BALANCE']
                //     payLending.approve = returnResult['PAYLEND_APPROVE'] / (10 ** payLending.decimals)

                // }

                goodsPaySale.value = paySale
                goodsPayLend.value = payLending

                // const urlSaleHistroy = `${API.nftHistoryMarket}${chainId}/${tokenid}/0/50`
                // saleHistoryList.value = (await axios.get(urlSaleHistroy)).data.data.list

                // const urlRentHistroy = `${API.nftHistoryLend}${chainId}/${tokenid}/0/50`
                // rentHistoryList.value = (await axios.get(urlRentHistroy)).data.data.list

                // const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)
                // const currentBlock = (await jsonRPCProvider.getBlock('latest'))

                // currentTime.value = currentBlock.timestamp

                // if (belongto.value === ('rentin' || 'rentout')) {
                //     if (updateReturnTimeTask === null) {
                //         updateReturnTimeTask = setInterval(() => {
                //             currentTime.value = currentTime.value + 1
                //             const delta = nftInfoAPIData!.lendingexpirytime - currentTime.value
                //             returnTimeArr.value = TOOL.dateTransformLong(delta)
                //             if (delta === 0) {
                //                 clearInterval(Number(updateReturnTimeTask))
                //                 updateReturnTimeTask = null
                //             }
                //         }, 1000)
                //     }
                // }

                isLoading.value = false
                if (isLoadingInit.value) {
                    isLoadingInit.value = false
                    ctx.emit('update', false)
                }

                nextTick(() => {
                    // Loading should be closed asynchronously
                    loadingInstance.close()
                })
            }

        }

        return {
            buttonDisabled,
            isLoading,
            isShowModal,
            modalShowIndex,
            isMobile,
            nftInfo,
            getTime,
            inputAmountBuy,
            goodsPaySale,
            goodsPayLend,
            belongto,
            nftStateMap,
            isOwner,
            isRenter,
            owner,
            renter,
            historyIndex,
            historyIndexNumber,
            changeRadioGroup,
            saleHistoryList,
            rentHistoryList,
            modalInfotip,
            lendingInfo,
            indexApproveNFT,
            currentTime,
            returnTimeArr,
            router,
            orderInfo,
            goBack
        }
    }
})
</script>
<style lang="stylus">

</style>