<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content d-flex flex-column align-items-center">
      <span class="title">{{ $t('Login.account') }}</span>
      <div class="item d-flex align-items-center">
        <span class="text">{{ $t('Login.username') }}{{ $t('Common.colon') }}</span>
        <span class="text yellow">{{ userName }}</span>
      </div>
      <div class="item d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <span class="text">{{ $t('Login.email') }}{{ $t('Common.colon') }}</span>
          <span class="text yellow">{{ email.length > 0 ? email : 'none' }}</span>
        </div>
        <span @click="isShowBindEmail = !isShowBindEmail" class="text bind" v-if="email.length === 0">{{
            $t('Login.bind-email-now')
        }}
          &gt;</span>
      </div>
      <template v-if="isShowBindEmail">
        <div class="input-item d-flex flex-column">
          <span class="input-title">{{ $t('Login.email') }}</span>
          <div class="input-block d-flex align-items-center">
            <input v-model.trim="emailInput" :placeholder="$t('Login.email-address')" style="flex: 1;" />
            <button style="margin-right: 10PX;width:130PX;height: 24PX;font-size: 14PX;"
              :disabled="emailInput.length === 0 || buttonGetVerifyCodeDisabled" @click="getSecCode"><template
                v-if="countdownGetSecCode === durationGetSecCode">{{ $t('Login.get-verify-code') }}</template>
              <template v-else>{{ countdownGetSecCode }} {{ $t('Login.seconds') }}</template></button>
          </div>
        </div>
        <div class="input-item d-flex flex-column">
          <span class="input-title">{{ $t('Login.verify-code') }}</span>
          <div class="input-block d-flex align-items-center">
            <input v-model.trim="emailSecode" :placeholder="$t('Login.email-verify-code')" style="flex: 1;" />
          </div>
        </div>
        <span class="error-block">{{ errorMsg }}</span>
        <div v-if="!isMobile" class="d-flex align-items-center justify-content-between" style="width: 100%;">
          <button class="verifyemail" style="width: 190PX" @click="bindemail">{{ $t('Login.bind-email') }}</button>
          <button class="verifyemail" style="width: 190PX" @click="isShowBindEmail = false">Cancel</button>
        </div>
        <div v-else="!isMobile" class="d-flex flex-column" style="width: 100%;">
          <button class="verifyemail" style="" @click="bindemail">{{ $t('Login.bind-email') }}</button>
          <button class="verifyemail" style="" @click="isShowBindEmail = false">{{ $t('Login.cancel') }}</button>
        </div>
      </template>
      <span class="title">{{ $t('Login.play-game') }}</span>
      <div class="play-list">
        <button @click="startgame('bsc')" class="play-button active">
          BSC
        </button>
        <button @click="startgame('sol')" class="play-button active">
          SOLANA
        </button>
        <button class="play-button disabled">
          Polygon
        </button>
        <button class="play-button disabled">
          AVALANCHE
        </button>
      </div>
      <span class="logout" @click="logout()">{{ $t('Login.log-out') }} &gt;</span>
    </div>
  </div>
  <!-- <div class="bg">
    <div style="margin-top: 0px">
      <div class="videobg" style="z-index: -1; margin-top: -10%">
        <video autoplay loop muted playsinline>
          <source src="/static/videos/topBg.webm" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>-->
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { ref, defineComponent, onMounted, getCurrentInstance } from 'vue'
import { getInfo, getSign, getEmailSecCode, bindEmail } from '@/api/account'
import { tokenKey } from '@/settings'
import { _getCookie, _removeCookie } from '@/utils/storage'
import { ElMessage } from 'element-plus'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import TOOL from '../utils/tool'
import { useI18n } from 'vue-i18n'
import LogUtils from '@/utils/LogUtils'
import i18n from "@/i18n"

export default defineComponent({
  name: 'Play',
  setup() {
    const { t, locale } = useI18n()
    const hasLogin = ref(false)
    const isShowBindEmail = ref(false)
    const userName = ref('')
    const email = ref('')
    const emailInput = ref('')
    const emailSecode = ref('')
    const errorMsg = ref('')
    const buttonGetVerifyCodeDisabled = ref(false)
    const durationGetSecCode = 90
    const countdownGetSecCode = ref(durationGetSecCode)
    const router = useRouter()
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const isMobile = ref(false)


    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })
      checkLogin()
    })

    const checkLogin = async () => {
      if (_getCookie(tokenKey, '')) {
        getInfo(null)
          .then((response: { data: any }) => {
            const { data, code } = response
            if (code === 0) {
              userName.value = data.name || ''
              email.value = data.email || ''
              hasLogin.value = true
              BUS.$emit('REFRESH_LOGIN_STATE', { isLogin: true })
            } else {
              router.push({ name: 'Login' })
            }

          })
          .catch((error: any) => {
            console.error(error)
            hasLogin.value = false
            return false
          })
      } else {
        router.push({ name: 'Login' })
      }
    }

    const getToken = (bcId: number) => {
      const token = _getCookie(tokenKey, '')
      if (token.length > 0) {
        return token
      } else {
        router.push({ name: 'Login' })
      }
      // if (_getCookie(tokenKey, '')) {
      //   return getSign(bcId)
      //     .then((response) => {
      //       const { data } = response
      //       const { token, url } = data
      //       // this.url = `./game/index.html?code=${token}`
      //       // const bcid = 56
      //       // const gameUrl = `${url}?code=${token}&bcid=${bcId}`
      //       // return gameUrl
      //       return token
      //     })
      //     .catch((error) => {
      //       console.error(error)
      //       return ''
      //     })
      // }
      return ''
    }

    const countdownSecCodeTask = () => {
      countdownGetSecCode.value = durationGetSecCode
      buttonGetVerifyCodeDisabled.value = true

      const timeStop = setInterval(() => {
        countdownGetSecCode.value--
        if (countdownGetSecCode.value === 0) {
          buttonGetVerifyCodeDisabled.value = false
          clearInterval(timeStop)
          countdownGetSecCode.value = durationGetSecCode
        }
      }, 1000)
    }

    const validateEmail = () => {
      const szReg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
      const bChk = szReg.test(emailInput.value);
      if (!bChk) {
        errorMsg.value = t('Login.please-check-your-email-address-is-correct')
        return false
      }
      return true
    }

    const getSecCode = async () => {
      errorMsg.value = ''

      if (!validateEmail()) {
        return
      }

      if (_getCookie(tokenKey, '')) {
        getEmailSecCode({ mail: emailInput.value }).then((response: any) => {
          LogUtils.debug(response['data'])
          ElMessage({ message: t('Login.email-sedcode-sent'), type: 'success' })
          isShowBindEmail.value = true

          countdownSecCodeTask()

        }).catch((error) => {
          console.error(error)
          ElMessage({ message: t('Login.email-sedcode-sent-error'), type: 'error' })
          return ''
        })
      }
    }

    const bindemail = async () => {
      errorMsg.value = ''
      if (!validateEmail()) {
        return
      }
      if (emailSecode.value.length === 0) {
        errorMsg.value = t('Login.please-input-your-email-verify-code')
        return
      }
      if (_getCookie(tokenKey, '')) {
        bindEmail({ mail: emailInput.value, verifycode: emailSecode.value }).then((response: any) => {
          LogUtils.debug(response['data'])
          ElMessage({ message: t('Login.email-bind-successfully'), type: 'success' })
          email.value = emailInput.value
          isShowBindEmail.value = false
        }).catch((error) => {
          console.error(error)
          ElMessage({ message: 'Email bind error!', type: 'error' })
          return ''
        })
      }
    }

    const startgame = async (chain: string) => {
      let url = ''
      let bcid = 0
      bcid = await TOOL.getChainId()
      if (BLOCKCHAIN_CONFIG.isDebug) {
        if (chain === 'bsc') {
          url = 'https://alpha-play.tapfantasy.io/game/bsctest'
        } else {
          bcid = 103
          url = 'https://soltest-web.tapfantasy.io/game/soltest'
        }
      } else {
        if (chain === 'bsc') {
          url = 'https://www.tapfantasy.io/game/bsc'
        } else {
          bcid = 101
          url = 'https://solana-web.tapfantasy.io/game/solana'
        }
      }

      const token = await getToken(bcid)
      // LogUtils.debug(`startgame => ${token}`)

      // let map = new Map([
      //   ['bcid', Number(bcid).toString()],
      //   ['token', ]
      // ])


      // openWindowWithPost(url, map)
      const domainLang = TOOL.getDomainLang()
      const gameUrl = `${url}?token=${_getCookie(tokenKey, '')}&bcid=${bcid}&lang=${i18n.global.locale.value}&area=${domainLang}`
      LogUtils.debug(`startgame => ${gameUrl}`)
      if (isMobile.value) {
        window.location.href = gameUrl
      } else {
        window.open(gameUrl, '_blank')
      }

    }

    const logout = () => {
      _removeCookie(tokenKey)
      BUS.$emit('REFRESH_LOGIN_STATE', { isLogin: false })
      const lang = i18n.global.locale.value
      if (lang === 'zh-TW') {
        router.push({ name: 'Login' })
      } else {
        router.push({ name: 'Home' })
      }

    }

    return {
      userName,
      email,
      logout,
      startgame,
      getSecCode,
      bindemail,
      emailInput,
      emailSecode,
      isShowBindEmail,
      countdownGetSecCode,
      buttonGetVerifyCodeDisabled,
      durationGetSecCode,
      errorMsg,
      isMobile
    }
  }
})
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
