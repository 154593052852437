/**
 * Created by Thierry on 2017/4/14.
 */

// eslint-disable-next-line import/prefer-default-export
export const isDebug: boolean = import.meta.env.VITE_APP_IS_DEBUG === "1"
export const isLocal: boolean = false
export const isIDO: boolean = false
export const isMarketPlaceOpen: boolean = true
export const isMysteryBoxOpen: boolean = true
export const isPlayGameOpen: boolean = true
export const isUrBoxOpen: boolean = false

// console.log(`Config-${isDebug}`)
