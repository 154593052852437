
// 基准大小
// 设置 rem 函数
function setRem() {
    const clientWidth = document.documentElement.clientWidth
    const baseSize = clientWidth < 1140 ? 75 : 192
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
    let scale = document.documentElement.clientWidth / 1920

    if (scale > 1 && scale < 2) {
        scale = 1.1
    } else if (scale >= 2) {
        scale = 1.8
    }
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    // 移动端适配
    if (clientWidth < 1140) {
        document.documentElement.style.fontSize = (document.documentElement.clientWidth / 375) + 'px'
    }
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    // console.log('resize---')
    setRem()
}