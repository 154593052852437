<template>
    <div class="page-nftheadinfo height-header-padding" style="position: relative;height: auto;">
        <el-dialog v-model="isShowModal" :show-close="false" :close-on-click-modal="false"
            :close-on-press-escape="false">
            <modal-approve v-if="modalShowIndex === 1" :tokeninfo="getApproveTokenAndNeedAmount()" @confirm="approve()"
                @cancel="closeModal()" />
            <modal-payment v-else-if="modalShowIndex === 3" action="buy" :symbol="goodsPaySale.symbol"
                :amount="goodsPaySale.price" :detail="nftInfo.name + ' #' + tokenId" @confirm="buy()"
                @cancel="closeModal" />
            <div v-else-if="modalShowIndex === 5"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.sell') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="nft-image-block rune">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('Dialog.your-nft-will-be-listed-on-the-marketplace-for-other-to-buy') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.payment-type') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input :value="goodsPaySale.symbol" readonly type="text" />
                                <span class="symbol">{{ $t('Dialog.token') }}</span>
                            </div>
                            <div class="item-title">{{ $t('Dialog.sale-price') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input ref="saleInput" v-model="inputAmount" type="text" placeholder=""
                                    @input="watchInput()" />
                                <span class="symbol">{{
                                        goodsPaySale.symbol
                                }}</span>
                            </div>
                            <div class="fee-block d-flex justify-content-end">
                                <span class="text">{{ $t('Dialog.fee') }}{{ $t('Common.colon') }}</span>
                                <span class="fee">{{
                                        goodsPaySale.feerate * 100
                                }}%</span>
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="sell()">
                                    {{ $t('Dialog.sell') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 11"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.transter') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="nft-image-block head">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('NFTInfo.your-nft-will-be-transterd-to-another-wallet-address') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.recievewalletaddress') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputWalletAddressTransfer" type="text" placeholder=""
                                    style="text-align: left" maxlength="42" />
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="transfer()">
                                    {{ $t('Dialog.transter') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
            <div v-else-if="modalShowIndex === 8"
                class="mask-nft modalwindow d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.notice') }}</div>
                    <div style="word-break: break-word" class="infotip infotip-attention d-flex justify-content-center">
                        {{ modalInfotip }}
                    </div>
                    <div v-if="isShowLoginLink" style="margin-top: 40PX;" class="d-flex justify-content-center">
                        <a class="go2login" href="/#/login">{{ $t('Dialog.go-to-your-game-account-to-bind-your-wallet')
                        }}</a>
                    </div>
                    <button class="button-buy nftinfo" style="margin-top: 40PX;width:130PX" @click="closeModal()">{{
                            $t('Dialog.ok')
                    }}</button>

                </div>
            </div>
            <modal-approve v-else-if="modalShowIndex === 9" @confirm="approveNFT(indexApproveNFT)"
                @cancel="closeModal" />
            <div v-else-if="modalShowIndex === 11"
                class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
                <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
                    <path
                        d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                        p-id="2960" fill="#ffffff" />
                </svg>
                <div class="dialog-content d-flex flex-column align-items-center">
                    <div class="title">{{ $t('Dialog.transter') }}</div>
                    <div :class="isMobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                        class="content d-flex">
                        <div class="left">
                            <div class="nft-image-block head">
                                <img :src="nftInfo['medium']" class="nft-image" />
                            </div>
                        </div>

                        <div class="right flex flex-column">
                            <div class="infotip">
                                {{ $t('NFTInfo.your-nft-will-be-transterd-to-another-wallet-address') }}
                            </div>
                            <div class="item-title">{{ $t('Dialog.recievewalletaddress') }}</div>
                            <div class="input-block d-flex align-items-center">
                                <input v-model="inputWalletAddressTransfer" type="text" placeholder=""
                                    style="text-align: left" maxlength="42" />
                            </div>
                            <div class="button-block">
                                <button class="button-cancel" @click="closeModal()">
                                    {{ $t('Dialog.cancel') }}
                                </button>
                                <button :disabled="buttonDisabled" class="button-buy mynftsell" @click="transfer()">
                                    {{ $t('Dialog.transter') }}
                                </button>
                            </div>

                        </div>
                    </div>


                    <!-- <a href="/#/marketplace" class="go2mp">Access To Marketplace &gt;</a> -->
                </div>
            </div>
        </el-dialog>
        <template v-if="!isLoading">
            <div v-if="!isMobile" class="outer d-flex justify-content-center">
                <div class="inner d-flex justify-content-between">
                    <div class="content">
                        <div @click="goBack()" class="back-block">
                            <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)"
                                        fill="#FFFFFF" fill-rule="nonzero">
                                        <g transform="translate(410.000000, 126.000000)">
                                            <g transform="translate(16.000000, 18.000000)">
                                                <path
                                                    d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                                </path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>{{ $t('Dialog.back') }}</span>
                        </div>

                        <div class="content-up">
                            <div class="left d-flex flex-column" style="padding: 0">
                                <div class="nft-state" v-if="belongto === 'forsell' || isOwner">
                                    <div class="anim"></div>
                                    <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                                </div>
                                <div class="nft-image-block rune">
                                    <img :src="nftInfo['medium']" class="nft-image" />
                                </div>
                                <div class="level">
                                    <div v-for="(attr, attrIndex) of nftInfo.attrs" class="item-rune">
                                        <img :src="attr.image" />
                                        <div class="rune-text-block">
                                            <div class="rune-name">{{ attr.name }}</div>
                                            <div class="rune-value">+{{ attr.value }}</div>

                                        </div>
                                        <!-- <span class="attr-value">
                                        {{ attr.value }}
                                    </span> -->

                                    </div>
                                </div>
                            </div>
                            <div class="right">
                                <template v-if="belongto !== 'wallet'">
                                    <div class="price-block" v-if="(belongto === 'forsell')"
                                        :class="(belongto === 'forsell') ? 'buyandrent' : ''">
                                        <div class="price-left">
                                            <div class="price-title">
                                                <template v-if="belongto === 'forsell'">{{ $t('NFTInfo.sale-price')
                                                }}</template>
                                            </div>
                                            <div class="price-content">
                                                <img v-if="goodsPaySale.symbol.length > 0"
                                                    :src="('/static/images/uc_token_' + goodsPaySale.symbol.toLowerCase() + '.png')">
                                                <div class="price">
                                                    <template v-if="belongto === 'forsell'">
                                                        {{ goodsPaySale.price.toFixed(2) }}
                                                    </template>
                                                </div>
                                                <div class="price yellow">
                                                    <template v-if="belongto === 'forsell'">
                                                        {{ goodsPaySale.symbol }}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="!isOwner">
                                            <template v-if="belongto === 'forsell'">
                                                <button :disabled="buttonDisabled" @click="buy()"
                                                    class="button-buy nftinfo">{{ $t('NFTInfo.buy') }}</button>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="belongto === 'forsell'">
                                                <button :disabled="buttonDisabled" @click="cancelOrderSell()"
                                                    class="button-buy nftinfo">{{ $t('NFTInfo.cancel-sale') }}</button>
                                            </template>
                                        </template>
                                    </div>
                                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                                        <template v-if="belongto === 'ingame'">
                                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                                        </template>
                                        <template v-else>
                                            {{
                                                    $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time')
                                            }}
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="belongto === 'wallet' && isOwner">
                                    <!-- <div class="title-cat">Action</div> -->
                                    <div class="action-block">
                                        <button v-if="!nftApprove.market" @click="showModal(9, 0)" class="button-buy"
                                            :disabled="buttonDisabled">{{ $t('NFTInfo.sell') }}</button>
                                        <button v-else @click="showModal(5)" class="button-buy"
                                            :disabled="buttonDisabled">{{ $t('NFTInfo.sell') }}</button>
                                        <button v-if="!nftApprove.ingame" @click="showModal(9, 2)" class="button-buy"
                                            :disabled="buttonDisabled">{{ $t('NFTInfo.charge-game') }}</button>
                                        <button v-else @click="charge()" class="button-buy"
                                            :disabled="buttonDisabled">{{
                                                    $t('NFTInfo.charge-game')
                                            }}</button>
                                        <button class="button-buy" :disabled="buttonDisabled" @click="showModal(11)">
                                            {{ $t('Dialog.transter') }}
                                        </button>

                                    </div>
                                </template>
                                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                                    <span class="text">#{{ tokenId }}</span>
                                </div>

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                                    <span class="text">{{ nftInfo.name }}</span>
                                </div>
                                <!-- <div class="info-block d-flex justify-content-between">
                                <span class="title">{{ $t('NFTInfo.character') }}</span>
                                <span class="text">{{ $t(`Character.${nftInfo['character']}`) }}</span>
                            </div> -->

                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">Class</span>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <span class="text" :class="nftInfo['star'] > 0 ? 'forsale' : ''">{{
                                                nftInfo['star']
                                        }}/3</span>
                                    </div>
                                </div>
                                <div class="info-block d-flex justify-content-between">
                                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                                    <span class="text">{{ owner }}</span>
                                </div>
                                <div class="purchase-rent-title-block">
                                    <div class="title-item active" @click="historyIndexNumber = 0">
                                        <div>{{ $t('NFTInfo.purchase-history') }}</div>
                                        <!-- <div class="line" style="height: 1PX"></div> -->
                                    </div>
                                </div>

                                <div class="trade-record">
                                    <div class="title-block d-flex justify-content-between align-items-center">
                                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.buyer') }}</div>
                                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.seller') }}</div>
                                        <div class="col-amount" style="flex: 1;">{{ $t('NFTInfo.price') }}</div>
                                        <div class="col-time" style="flex: 1;">{{ $t('NFTInfo.time') }}</div>
                                    </div>
                                    <template v-if="saleHistoryList.length > 0">
                                        <div class="data-item d-flex align-items-center justify-content-between"
                                            v-for="(sale, saleIndex) of saleHistoryList" :key="'sale-' + saleIndex">
                                            <div class="col-addr" style="flex: 1;">
                                                <div class="inner-text">
                                                    {{ sale['buyer'] }}
                                                </div>
                                            </div>
                                            <div class="col-addr" style="flex: 1;">
                                                <div class="inner-text">{{ sale['seller'] }}</div>
                                            </div>
                                            <div class="col-amount" style="flex: 1;">
                                                <div class="inner-text">{{ sale['price'] }}
                                                    {{ sale['paysymbol'] }}</div>
                                            </div>
                                            <div class="col-time" style="flex: 1;">
                                                <div class="inner-text">
                                                    {{ getTime(sale['closetime'])
                                                    }}
                                                </div>

                                            </div>
                                        </div>
                                    </template>
                                    <div v-else class="data-item d-flex align-items-center justify-content-center">
                                        {{ $t('NFTInfo.no-data') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div v-else class="mobile-content">
                <div @click="goBack()" class="back-block">
                    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Rent-out-detail" transform="translate(-426.000000, -144.000000)" fill="#FFFFFF"
                                fill-rule="nonzero">
                                <g transform="translate(410.000000, 126.000000)">
                                    <g transform="translate(16.000000, 18.000000)">
                                        <path
                                            d="M9.14807745,3.71860816 L9.14807745,0.720606078 C8.87810902,-0.533103896 7.85222901,0.230023916 7.85222901,0.230023916 L0.725062537,6.38955551 C-0.840754335,7.47973809 0.617075169,8.29737502 0.617075169,8.29737502 L7.63625428,14.4023975 C9.04009009,15.4380709 9.14807748,13.8573062 9.14807745,13.8573062 L9.14807745,11.0773406 C16.2752439,8.84246632 19.1909029,17.7819635 19.1909029,17.7819635 C19.4608713,18.2725456 19.6228524,17.7819635 19.6228524,17.7819635 C22.3765304,4.37271772 9.14807745,3.71860819 9.14807745,3.71860816 Z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>{{ $t('Dialog.back') }}</span>
                </div>
                <div class="top-block">
                    <div class="nft-state"
                        v-if="(belongto === 'forsell' || belongto === 'forrent' || belongto === 'rentin' || belongto === 'rentout') || isOwner">
                        <div class="anim"></div>
                        <div class="title">{{ $t(`NFTState.${belongto}`) }}</div>
                    </div>
                </div>
                <div class="nft-image-block rune">
                    <img :src="nftInfo['medium']" class="nft-image" />
                </div>
                <div class="level">
                    <div v-for="(attr, attrIndex) of nftInfo.attrs" class="item-rune">
                        <img :src="attr.image" />
                        <div class="rune-text-block">
                            <div class="rune-name">{{ attr.name }}</div>
                            <div class="rune-value">+{{ attr.value }}</div>

                        </div>
                        <!-- <span class="attr-value">
                                        {{ attr.value }}
                                    </span> -->

                    </div>
                </div>
                <template v-if="belongto !== 'wallet'">
                    <div class="price-block" v-if="(belongto === 'forsell')"
                        :class="(belongto === 'forsell') ? 'buyandrent' : ''">
                        <div class="price-left">
                            <div class="price-title">
                                <template v-if="belongto === 'forsell'">{{ $t('NFTInfo.sale-price')
                                }}</template>
                            </div>
                            <div class="price-content">
                                <img v-if="goodsPaySale.symbol.length > 0"
                                    :src="('/static/images/uc_token_' + goodsPaySale.symbol.toLowerCase() + '.png')">
                                <div class="price">
                                    <template v-if="belongto === 'forsell'">
                                        {{ goodsPaySale.price.toFixed(2) }}
                                    </template>
                                </div>
                                <div class="price yellow">
                                    <template v-if="belongto === 'forsell'">
                                        {{ goodsPaySale.symbol }}
                                    </template>
                                </div>
                            </div>
                        </div>
                        <template v-if="!isOwner">
                            <template v-if="belongto === 'forsell'">
                                <button :disabled="buttonDisabled" @click="buy()" class="button-buy nftinfo">{{
                                        $t('NFTInfo.buy')
                                }}</button>
                            </template>
                        </template>
                        <template v-else>
                            <template v-if="belongto === 'forsell'">
                                <button :disabled="buttonDisabled" @click="cancelOrderSell()"
                                    class="button-buy nftinfo">{{ $t('NFTInfo.cancel-sale') }}</button>
                            </template>
                        </template>
                    </div>
                    <div v-if="belongto === 'outgame' || belongto === 'ingame'" class="info-tip">
                        <template v-if="belongto === 'ingame'">
                            {{ $t('NFTInfo.please-claim-the-nft-card-in-your-game-account') }}
                        </template>
                        <template v-else>
                            {{ $t('NFTInfo.nft-is-transacting-and-will-be-available-in-your-wallet-after-a-time') }}
                        </template>

                    </div>
                </template>
                <template v-if="belongto === 'wallet' && isOwner">
                    <!-- <div class="title-cat">Action</div> -->
                    <div class="action-block">
                        <button v-if="!nftApprove.market" @click="showModal(9, 0)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('NFTInfo.sell') }}</button>
                        <button v-else @click="showModal(5)" class="button-buy" :disabled="buttonDisabled">{{
                                $t('NFTInfo.sell')
                        }}</button>
                        <button v-if="!nftApprove.ingame" @click="showModal(9, 2)" class="button-buy"
                            :disabled="buttonDisabled">{{ $t('Infotip.action-charge') }}</button>
                        <button v-else @click="charge()" class="button-buy" :disabled="buttonDisabled">{{
                                $t('Infotip.action-charge')
                        }}</button>
                        <button class="button-buy" :disabled="buttonDisabled" @click="showModal(11)">
                            {{ $t('Dialog.transter') }}
                        </button>

                    </div>
                </template>
                <div class="title-cat">{{ $t('NFTInfo.about') }}</div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.tokenid') }}</span>
                    <span class="text">#{{ tokenId }}</span>
                </div>

                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.name') }}</span>
                    <span class="text">{{ nftInfo.name }}</span>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">Class</span>
                    <div class="d-flex align-items-center justify-content-end">
                        <span class="text" :class="nftInfo['star'] > 0 ? 'forsale' : ''">{{
                                nftInfo['star']
                        }}/3</span>
                    </div>
                </div>
                <div class="info-block d-flex justify-content-between">
                    <span class="title">{{ $t('NFTInfo.owner') }}</span>
                    <div class="address">
                        <div class="inner">
                            {{ owner }}
                        </div>
                    </div>
                </div>
                <div class="purchase-rent-title-block">
                    <div class="title-item active" @click="historyIndexNumber = 0">
                        <div>{{ $t('NFTInfo.purchase-history') }}</div>
                        <!-- <div class="line" style="height: 1PX"></div> -->
                    </div>
                </div>

                <div class="trade-record">
                    <div class="title-block d-flex justify-content-between align-items-center">
                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.buyer') }}</div>
                        <div class="col-addr" style="flex: 1;">{{ $t('NFTInfo.seller') }}</div>
                        <div class="col-amount" style="flex: 1;">{{ $t('NFTInfo.price') }}</div>
                        <div class="col-time" style="flex: 1;">{{ $t('NFTInfo.time') }}</div>
                    </div>
                    <template v-if="saleHistoryList.length > 0">
                        <div class="data-item d-flex align-items-center justify-content-between"
                            v-for="(sale, saleIndex) of saleHistoryList" :key="'sale-' + saleIndex">
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">
                                    {{ sale['buyer'] }}
                                </div>
                            </div>
                            <div class="col-addr" style="flex: 1;">
                                <div class="inner-text">{{ sale['seller'] }}</div>
                            </div>
                            <div class="col-amount" style="flex: 1;">
                                <div class="inner-text">{{ sale['price'] }}
                                    {{ sale['paysymbol'] }}</div>
                            </div>
                            <div class="col-time" style="flex: 1;">
                                <div class="inner-text">
                                    {{ getTime(sale['closetime'])
                                    }}
                                </div>

                            </div>
                        </div>
                    </template>
                    <div v-else class="data-item d-flex align-items-center justify-content-center">
                        {{ $t('NFTInfo.no-data') }}
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateNFTInfo } from '@makerdao/multicall'
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    Ref,
    getCurrentInstance,
    watch,
    toRefs,
    inject,
    nextTick
} from 'vue'
import { useRouter } from 'vue-router'
import { ElButton, ElLoading, ElTooltip } from 'element-plus'
import { CreateNotification } from '../components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '../utils/provider'
import ERC20 from '../abis/ERC20.json'
import ERC721 from '../abis/ERC721.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import moment from 'moment'
import NFTInGameAcc from '../abis/NFTInGameAcc.json'
import { useI18n } from "vue-i18n"
import LogUtils from '@/utils/LogUtils'
import TapDesktop from '../abis/TapDesktop.json'

export default defineComponent({
    name: 'NFTRuneInfo',
    components: { ElButton, ElLoading },
    props: {
        walletAddress: {
            type: String,
            default: ''
        },
        isReady: {
            type: Boolean,
            default: false
        },
        isRefresh: {
            type: Boolean,
            default: false
        }
    },
    setup(props, ctx) {
        watch(
            () => props.walletAddress,
            (newWalletAddr, oldWalletAddr) => {
                // LogUtils.debug(newWalletAddr)
                // LogUtils.debug(oldWalletAddr)
                if (!isLoadingInit.value) {
                    if (newWalletAddr !== oldWalletAddr) {
                        LogUtils.debug('load from change wallet...')
                        if (isShowModal.value) {
                            closeModal()
                        }
                        loadData()
                    }
                }
            },
        )

        watch(
            () => props.isRefresh,
            (newReady, oldReady) => {
                LogUtils.debug(newReady)
                LogUtils.debug(oldReady)
                if (newReady && newReady !== oldReady) {
                    if (props.walletAddress.length === 0) {
                        buttonDisabled.value = true
                    } else {
                        buttonDisabled.value = false
                    }
                    LogUtils.debug('load from ready...')
                    loadData()
                }
            }
        )

        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
        const router = useRouter()
        const BigNumber =
            getCurrentInstance()?.appContext.config.globalProperties.$BigNumber

        const pageChainConfig: any = {}
        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isLoadingInit = ref(true)
        const isMobile = ref(false)
        const isShowModal = ref(false)
        const modalShowIndex = ref(-1)
        const inputAmountBuy = ref(1)
        const inputWalletAddressTransfer = ref('')
        const belongto = ref('')
        const owner = ref('')
        const isOwner = ref(false)
        const isRenter = ref(false)
        const historyIndex = ref('0')
        const historyIndexNumber = ref(0)
        const indexApproveNFT = ref(-1)
        const tokenId = ref(0)
        const saleInput: Ref<any> = ref(null)
        let isforsell = 0
        let nftRuneInfoAPIData: {
            nftrechargecontract: string
            nftassets: string
            rechargefee: number
            tokenid: number
            chainid: number
            belongto: string
            tag: string
            owner: string
            info: {
                image: string
                preview: string
                attrs: any[]
                medium: string
                name: string
                orderid: number
                price: number,
                star: 1
            },
            runedefineid: number
            saletoken: string
            saletokensymbol: string
            saledecimals: number
            salefeeRate: number
            salegoodsid: number
            marketcontract: string

        } | null = null
        const gameAccount = ref('')
        const nftApprove = ref({
            market: false,
            ingame: false,
            lending: false
        })
        let assetsAPIInfo = {
            goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
            mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
            wallet: { accountid: 0, address: '', nickname: '' },
        }
        const nftInfo = ref({
            image: '',
            preview: '',
            attrs: [] as any,
            medium: '',
            name: '',
            orderid: 0,
            price: 0,
            star: 0
        })

        interface goodsPayToken {
            approve: number,
            balance: string
            decimals: number,
            address: string,
            symbol: string,
            feerate: number
            price: number
        }
        const goodsPaySale: Ref<goodsPayToken> = ref({
            approve: 0,
            balance: '',
            decimals: 0,
            address: '',
            symbol: '',
            feerate: 0,
            price: 0
        })

        const rechargeInfo: Ref<any> = ref({
            fee: 0
        })

        const nftStateMap = {
            forsell: 'For Sale',
            rentout: 'Rent Out',
            rentin: 'Rent In',
            wallet: 'Collection',
            ingame: 'In Game',
            transaction: 'Transacting',
            forrent: 'For Rent',
        }

        const saleHistoryList = ref([])
        const inputAmount = ref('1')

        const modalInfotip = ref('')
        const isShowLoginLink = ref(false)
        let loadingInstance: any = null
        const pageSrcPara = ref({
            src: '',
            type: -1
        })

        onMounted(() => {
            isMobile.value = !TOOL.getDeviceScreenIsPC()
            BUS.$on('RESIZE_WINDOW', (para: any) => {
                isMobile.value = para.isMobile
            })

            const tokenid = router.currentRoute.value.params.id
            tokenId.value = Number(tokenid)

            try {
                isforsell = Number(router.currentRoute.value.params.isforsell)
            } catch (e: any) {

            }


            if (props.isRefresh && isLoadingInit) {
                LogUtils.debug('load from onMounted...')
                loadData()
            }
        })

        onUnmounted(() => {
        })

        const showModal = (modalIdx: number, indexANFT: number = -1) => {
            inputAmount.value = ''
            isShowModal.value = true
            modalShowIndex.value = modalIdx
            indexApproveNFT.value = indexANFT
            if (modalIdx === 5) {
                nextTick(() => {
                    saleInput.value.focus()
                })

            }
        }

        const closeModal = () => {
            isShowModal.value = false
            modalShowIndex.value = -1
            modalInfotip.value = ''
            isShowLoginLink.value = false
            inputAmount.value = '1'
        }

        const changeRadioGroup = () => {
            historyIndexNumber.value = parseInt(historyIndex.value, 10)
        }

        const getTime = (time: number) => {
            return moment(time * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }

        const getApproveTokenAndNeedAmount = () => {
            const cardInfo = nftInfo.value
            const payToken = goodsPaySale.value

            return {
                symbol: payToken.symbol,
                amount: new BigNumber(cardInfo['price']).toNumber()
            }
        }

        const checkApprove = async () => {
            const cardInfo = nftInfo.value
            const payToken = goodsPaySale.value
            const contractAddr = nftRuneInfoAPIData?.marketcontract

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
                    const allowanceNumber = Number(ethers.utils.formatEther(allowance))
                    const needAmount = getApproveTokenAndNeedAmount().amount
                    if (Number(allowanceNumber) < needAmount) {
                        return false
                    } else {
                        return true
                    }
                }
            }
            return false
        }

        const approve = async () => {
            const action = 'sale'
            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-request-' + action)
            const cardInfo = nftInfo.value
            const payToken = goodsPaySale.value
            const contractAddr = nftRuneInfoAPIData?.marketcontract

            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        payToken.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': payToken.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = '0'
                    contractToken
                        .approve(contractAddr, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.allowance(
                                        walletAddress.value,
                                        contractAddr
                                    )
                                    payToken.approve = approveBN / (10 ** payToken.decimals)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': payToken.symbol })
                                    })
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-success-' + action)

                                    if (isOwner.value) {
                                        sell()
                                    } else {
                                        showModal(3)
                                    }


                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-trade-' + action)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'approve-error-request-' + action)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': payToken.symbol })
                            })
                        })
                }
            }
        }

        const approveNFT = async (optType: number) => {
            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-request-nft`)
            const { walletAddress } = toRefs(props)
            let approveAddr = ''
            switch (optType) {
                case 0:
                    approveAddr = nftRuneInfoAPIData!.marketcontract
                    break;
                // case 1:
                //     approveAddr = nftRuneInfoAPIData!.lendingcontract
                //     break;
                case 2:
                    approveAddr = nftRuneInfoAPIData!.nftrechargecontract
                    break;
                default:
                    break;
            }
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractToken = new ethers.Contract(
                        nftRuneInfoAPIData!.nftassets,
                        ERC721,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-nft'),
                        autoClose: false,
                        canClose: false
                    })
                    contractToken
                        .setApprovalForAll(approveAddr, true)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const approveBN = await contractToken.isApprovedForAll(
                                        walletAddress.value,
                                        approveAddr
                                    )
                                    closeModal()
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-nft-successful')
                                    })
                                    switch (optType) {
                                        case 0:
                                            nftApprove.value.market = true
                                            showModal(5)
                                            break;
                                        case 1:
                                            nftApprove.value.lending = true
                                            if (belongto.value === 'wallet') {
                                                showModal(6)
                                            } else if (belongto.value === 'forrent') {
                                                showModal(7)
                                            }

                                            break;
                                        case 2:
                                            nftApprove.value.ingame = true
                                            charge()
                                            break;
                                        default:
                                            break;
                                    }
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-trade`)
                                    console.error(error)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-nft-error')
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('approve-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `approve-error-request`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-nft-error')
                            })
                        })
                }
            }
        }

        const buy = async () => {
            const flagApprove = await checkApprove()
            if (!flagApprove) {
                // createNotification({
                //     type: 'error',
                //     message: `Your token approve count is not enough`,
                // })
                showModal(1)
                return
            }
            TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-request')
            const cardInfo = nftInfo.value
            const id = tokenId.value
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractDesktop = new ethers.Contract(
                            nftRuneInfoAPIData!.marketcontract,
                            TapDesktop,
                            signer
                        )
                        const price = (1 * cardInfo['price'])
                        if ((price) > Number(goodsPaySale.value.balance)) {
                            createNotification({
                                type: 'error',
                                message: `${t('Infotip.action-nft-nftinfo', { 'action': 'Buy', 'id': id, 'state': t('Common.error') })}${t('Common.colon')} ${t('Infotip.your-goodspay-balance-not-enough', { 'symbol': goodsPaySale.value.symbol })}`,
                            })
                            return
                        }
                        const amount = 1
                        // const amountBN = ethers.utils.parseUnits(amount.toString(), 18).toString()
                        // LogUtils.debug(`${cardInfo['orderid']}|${amountBN}`)
                        const sendPromise = contractDesktop.marketBuy(cardInfo['orderid'], amount)

                        const uuid = createNotification({
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buying'), 'id': id, 'state': '' })}...`,
                            autoClose: false,
                            canClose: false
                        })
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transaction: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.successful') })}`
                                    })
                                    TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-success')
                                    // buyItem.value = cardInfo
                                    // modalShowIndex.value = 2
                                    // isShowModal.value = true
                                    setTimeout(() => {
                                        isforsell = 0
                                        closeModal()
                                        loadData()
                                    }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000)


                                })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-trade')
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                                    duration: 5
                                })
                            })
                    } catch (e) {
                        console.error(e)
                        TOOL.gtmTrack('buy-button', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, 'buy-error-request')
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-nft-nftinfo', { 'action': t('Infotip.action-buy'), 'id': id, 'state': t('Common.error') })}`,
                        })
                    }
                }
            }
        }

        const sell = async () => {
            TOOL.gtmTrack('sell-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `sell-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const 你 = nftInfo.value
                const price = parseFloat(inputAmount.value)
                if (inputAmount.value.trim().length === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-sell-price-can-not-be-empty')
                    })
                    return
                } else if (price === 0) {
                    createNotification({
                        type: 'error',
                        message: t('Infotip.the-sell-price-can-not-be-zero')
                    })
                    return
                }
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractDesktop = new ethers.Contract(
                        nftRuneInfoAPIData!.marketcontract,
                        TapDesktop,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': tokenId.value, 'state': '' }) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString())
                    const priceBN = ethers.utils.parseUnits(price.toString(), 18).toString()
                    const tokenid = tokenId.value.toString()
                    const tokenidBN = tokenid
                    const type = nftRuneInfoAPIData!.salegoodsid
                    const sendPromise = contractDesktop.marketSell(type, tokenidBN, priceBN)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('sell-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `sell-success-nft`)

                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })

                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-list'), 'id': tokenid, 'state': t('Common.successful') })
                                    })
                                    closeModal()
                                    isforsell = 1
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('sell-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `sell-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': tokenid, 'state': t('Common.error') })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('sell-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `sell-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-list-nft-nftinfo', { 'action': t('Infotip.action-listing'), 'id': tokenid, 'state': t('Common.error') })
                            })
                        })
                }
            }
        }

        const cancelOrderSell = async () => {
            TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-request-nft`)
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const card = nftInfo.value
                const provider = await getWalletProvider()
                if (provider) {
                    const orderid = card['orderid']
                    const signer = provider.getSigner()
                    const contractDesktop = new ethers.Contract(
                        nftRuneInfoAPIData!.marketcontract,
                        TapDesktop,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), tokenId.value, orderid], ''),
                        autoClose: false,
                        canClose: false
                    })

                    // const options = { value: ethers.utils.parseEther(Number(assetsAPIMyNFTListInfo?.rechargefee).toString()) }

                    const sendPromise = contractDesktop.marketCancel(orderid)

                    sendPromise
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), tokenId.value, orderid], t('Common.successful'))
                                    })
                                    isforsell = 0
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), tokenId.value, orderid], t('Common.error'))
                                    })
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('unlist-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `unlist-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-cancel-list-nft-nftinfo', [t('Infotip.action-cancel-listing'), tokenId.value, orderid], t('Common.error'))
                            })
                        })
                }
            }
        }

        const clearNoNum = (numStr: string) => {
            numStr = numStr.replace(/[^\d\.]/g, "");  //清除“数字”和“.”以外的字符
            numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
            numStr = numStr.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');//只能输入两个小数
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const clearNoNumInteger = (numStr: string) => {
            numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
            numStr = numStr.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
            if (numStr.indexOf(".") < 0 && numStr != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                numStr = parseInt(numStr, 10).toString()
            }
            return numStr
        }

        const watchInput = () => {
            const price = inputAmount.value
            let input_num_str = price.toString()
            input_num_str = clearNoNum(input_num_str)
            inputAmount.value = input_num_str
        }

        const charge = async () => {
            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-request-nft`)
            const tokenid = nftRuneInfoAPIData!.tokenid
            const { walletAddress } = toRefs(props)
            if (walletAddress.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    if (typeof gameAccount.value === 'undefined' || gameAccount.value.length === 0) {
                        modalInfotip.value = `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.before-charging-nft-please-bind-your-wallet-address-to-your-game-account')}`
                        isShowLoginLink.value = true
                        showModal(8)
                        return
                    }

                    const feeNumber = Number(nftRuneInfoAPIData?.rechargefee)
                    // const options = { value: ethers.utils.parseEther(feeNumber.toString()) }
                    const gas = await provider.getBalance(walletAddress.value)
                    const gasNumber = new BigNumber(gas.toString()).div(10 ** 18).toNumber()

                    // LogUtils.debug(gasNumber)
                    // LogUtils.debug(feeNumber)

                    if (gasNumber < feeNumber) {
                        createNotification({
                            type: 'error',
                            message: `${t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])}${t('Common.colon')} ${t('Infotip.not-enough-bnb-available-as-gas-to-transfer')}`
                        })
                        return
                    }

                    const signer = provider.getSigner()
                    const contractIngame = new ethers.Contract(
                        nftRuneInfoAPIData!.nftrechargecontract,
                        NFTInGameAcc,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charging'), tokenid, '']) + '...',
                        autoClose: false,
                        canClose: false
                    })
                    buttonDisabled.value = true

                    contractIngame
                        .lockInGame(1, tokenid)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-success-nft`)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.successful')])
                                    })
                                    buttonDisabled.value = false
                                    isforsell = 0
                                    loadData()
                                })
                                .catch((error: any) => {
                                    TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-trade-nft`)
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                                    })
                                    buttonDisabled.value = false
                                })
                        })
                        .catch((error: any) => {
                            TOOL.gtmTrack('charge-button-nft', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `charge-error-request-nft`)
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.action-charge-nftinfo', [t('Infotip.action-charge'), tokenid, t('Common.error')])
                            })
                            buttonDisabled.value = false
                        })
                }
            }
        }

        const transfer = async () => {
            const address = inputWalletAddressTransfer.value.trim()
            const flag = ethers.utils.isAddress(address)

            if (!flag) {
                createNotification({
                    type: 'error',
                    message: "Your reciece wallet address is invaild",
                    duration: 6
                })
                return
            } else {
                TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-request-nfthead`)
                const { walletAddress } = toRefs(props)
                if (walletAddress.value.length > 0) {
                    const provider = await getWalletProvider()
                    if (provider) {
                        const signer = provider.getSigner()
                        const contractToken = new ethers.Contract(
                            nftRuneInfoAPIData!.nftassets,
                            ERC721,
                            signer
                        )
                        const sendPromise = contractToken["safeTransferFrom(address,address,uint256)"](walletAddress.value, address, nftRuneInfoAPIData!.tokenid)
                        const uuid = createNotification({
                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfering'), nftRuneInfoAPIData!.tokenid, address, '']) + '...',
                            autoClose: false,
                            canClose: false
                        })
                        buttonDisabled.value = true
                        sendPromise
                            .then(async function (transaction: any) {
                                transaction
                                    .wait()
                                    .then(async function (transaction: any) {
                                        TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-success-nfthead`)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'success',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftRuneInfoAPIData!.tokenid, address, t('Common.successful')]),
                                        })
                                        setTimeout(() => {
                                            buttonDisabled.value = false
                                            closeModal()
                                            isforsell = 0
                                            loadData()
                                        }, BLOCKCHAIN_CONFIG.BLOCK_RATE.BNB * 1000 * 1)
                                    })
                                    .catch((error: any) => {
                                        TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-trade-nfthead`)
                                        console.error(error)
                                        BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                        createNotification({
                                            type: 'error',
                                            message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftRuneInfoAPIData!.tokenid, address, t('Common.error')]),
                                        })
                                        buttonDisabled.value = false
                                        closeModal()
                                    })
                            })
                            .catch((error: any) => {
                                TOOL.gtmTrack('transfer-button-nfthead', isMobile.value ? 'mobile' : 'pc', 'click', `${router.currentRoute.value.meta.gtm}`, `transfer-error-request-nfthead`)
                                console.error(error)
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    type: 'error',
                                    message: t('Infotip.action-transfer-token-nftinfo', [t('Infotip.action-transfer'), nftRuneInfoAPIData!.tokenid, address, t('Common.error')]),
                                })
                                buttonDisabled.value = false
                                closeModal()
                            })


                    }

                }


            }



        }

        const goBack = () => {
            router.back()
        }

        const loadData = async () => {
            buttonDisabled.value = true
            // if (loadingInstance === null) {
            loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
            // }

            const projectConfig = TOOL.getConfigProject()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainId = await TOOL.getChainId()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const chainTokensConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.tokens)
            const chainBlockchainBrowserURLConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.blockchain_browser_url)

            pageChainConfig.projectConfig = projectConfig
            pageChainConfig.chainId = chainId
            pageChainConfig.chainSymbol = chainSymbol
            pageChainConfig.chainRPCConfig = chainRPCConfig
            pageChainConfig.chainABIConfig = chainABIConfig
            pageChainConfig.chainTokensConfig = chainTokensConfig
            pageChainConfig.chainBlockchainBrowserURLConfig = chainBlockchainBrowserURLConfig
            pageChainConfig.multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }

            // LogUtils.debug(pageChainConfig)

            const { walletAddress } = toRefs(props)
            LogUtils.debug(walletAddress.value)

            const wa = walletAddress.value.length > 0 ? walletAddress.value : BLOCKCHAIN_CONFIG.ZERO_ADDRESS

            const urlType = API.nftRuneAssetsInfo

            const urlNFTInfo = `${urlType}${chainId}/${tokenId.value}/${wa}/`
            try {
                const reuqestData = (await axios.get(urlNFTInfo)).data
                LogUtils.debug(reuqestData)
                nftRuneInfoAPIData = reuqestData.data
                // nftRuneInfoAPIData = {
                //     nftrechargecontract: "0x6C5bD54A7a57648d0f384a82e127C758fD657E64",
                //     nftassets: "0xc3acA4DcAf59665dD559F636df02b6Ffb3CEBb16",
                //     rechargefee: 0,
                //     tokenid: 10000008,
                //     chainid: 97,
                //     belongto: "forsell",
                //     tag: "sasell",
                //     owner: "0x1266a58803E17126E50F7Ce27A83de0FCAcC19f8",
                //     info: {
                //         image: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         preview: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         attrs: [{ id: 1, name: "HP", value: "2006" }, { id: 2, name: "ATK", value: "167" }],
                //         medium: "https://game.tapfantasy.io/iconImg/rune/21001.png",
                //         name: "Storm",
                //         orderid: 164,
                //         price: 100,
                //         star: 1,
                //     },
                //     runedefineid: 21001,
                //     salegoodsid: 0,
                //     saletoken: "0xf0931E2d258fe49BF4C352EE436FD6D98AdD434f",
                //     salefeeRate: 0.02,
                //     saletokensymbol: "T-USDT",
                //     saledecimals: 18,
                //     marketcontract: "0x6C5bD54A7a57648d0f384a82e127C758fD657E64"

                // }
                if (nftRuneInfoAPIData) {
                    nftInfo.value = nftRuneInfoAPIData.info
                    belongto.value = nftRuneInfoAPIData.belongto
                    isOwner.value = walletAddress.value.toLowerCase() === nftRuneInfoAPIData.owner.toLowerCase()
                    owner.value = nftRuneInfoAPIData.owner
                    tokenId.value = nftRuneInfoAPIData.tokenid

                    const paySale: goodsPayToken = {
                        approve: 0,
                        balance: '',
                        decimals: nftRuneInfoAPIData.saledecimals,
                        address: nftRuneInfoAPIData.saletoken,
                        symbol: nftRuneInfoAPIData.saletokensymbol,
                        feerate: nftRuneInfoAPIData.salefeeRate,
                        price: nftInfo.value.price
                    }

                    LogUtils.debug(paySale)

                    if (walletAddress.value.length > 0) {
                        const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`

                        axios.get(urlAssetsAPIInfo)
                            .then(async (response) => {
                                assetsAPIInfo = response.data.data
                                gameAccount.value = assetsAPIInfo!.wallet.nickname

                                buttonDisabled.value = false

                                let calls: any[] = []
                                let returnValue = null

                                LogUtils.debug(`Get Approve Start(${walletAddress.value})...`)

                                calls = calls.concat([
                                    {
                                        target: paySale.address,
                                        call: ['balanceOf(address)(uint256)', walletAddress.value],
                                        returns: [['PAYSALE_BALANCE', (val: any) => val]]
                                    },
                                    {
                                        target: paySale.address,
                                        call: [
                                            'allowance(address,address)(uint256)',
                                            walletAddress.value,
                                            nftRuneInfoAPIData!.marketcontract
                                        ],
                                        returns: [['PAYSALE_APPROVE', (val: any) => val / 10 ** paySale.decimals]]
                                    },
                                ])

                                returnValue = await aggregateNFTInfo(calls, pageChainConfig.multicallConfig)
                                const returnResult = returnValue.results.transformed

                                calls = []
                                paySale.balance = TOOL.scientificToNumber(
                                    new BigNumber(returnResult['PAYSALE_BALANCE'].toString())
                                        .div(10 ** paySale.decimals)
                                        .toString()
                                )
                                paySale.approve = returnResult['PAYSALE_APPROVE']


                                goodsPaySale.value = paySale

                                LogUtils.debug('Get Approve End...')
                            })
                            .catch((e) => {

                            })

                        // assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
                    } else {
                        goodsPaySale.value = paySale
                    }



                    if (isOwner.value) {
                        let calls: any[] = []
                        calls = calls.concat([
                            {
                                target: nftRuneInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftRuneInfoAPIData.nftrechargecontract
                                ],
                                returns: [['NFT_APPROVE_INGAME', (val: any) => val]]
                            },
                            {
                                target: nftRuneInfoAPIData.nftassets,
                                call: [
                                    'isApprovedForAll(address,address)(bool)',
                                    walletAddress.value,
                                    nftRuneInfoAPIData.marketcontract
                                ],
                                returns: [['NFT_APPROVE_MARKET', (val: any) => val]]
                            },
                        ])

                        const returnValue = await aggregateNFTInfo(
                            calls,
                            pageChainConfig.multicallConfig
                        )

                        nftApprove.value.ingame = returnValue.results.transformed.NFT_APPROVE_INGAME
                        nftApprove.value.market = returnValue.results.transformed.NFT_APPROVE_MARKET
                    }

                    LogUtils.debug('Loading end')


                    nextTick(() => {
                        // Loading should be closed asynchronously
                        loadingInstance.close()
                        isLoading.value = false

                        if (isLoadingInit.value) {
                            isLoadingInit.value = false
                            ctx.emit('update', false)
                        }
                    })
                } else {
                    nextTick(() => {
                        // Loading should be closed asynchronously
                        loadingInstance.close()
                        LogUtils.error(reuqestData)
                        isLoading.value = false
                        if (isLoadingInit.value) {
                            isLoadingInit.value = false
                            ctx.emit('update', false)
                        }
                    })
                }

                const urlSaleHistroy = `${API.nftRuneHistoryMarket}${chainId}/${tokenId.value}/0/50`
                axios.get(urlSaleHistroy)
                    .then((response) => {
                        saleHistoryList.value = response.data.data.list
                    })
                    .catch((e) => {

                    })
            } catch (e: any) {
                LogUtils.error(e)
            }


        }

        return {
            buttonDisabled,
            isLoading,
            isShowModal,
            modalShowIndex,
            isMobile,
            nftInfo,
            getTime,
            inputAmountBuy,
            goodsPaySale,
            belongto,
            nftStateMap,
            isOwner,
            isRenter,
            owner,
            historyIndex,
            historyIndexNumber,
            changeRadioGroup,
            saleHistoryList,
            closeModal,
            showModal,
            gameAccount,
            approveNFT,
            nftApprove,
            inputAmount,
            modalInfotip,
            charge,
            watchInput,
            indexApproveNFT,
            router,
            isShowLoginLink,
            goBack,
            pageSrcPara,
            inputWalletAddressTransfer,
            transfer,
            tokenId,
            approve,
            buy,
            sell,
            cancelOrderSell,
            saleInput,
            getApproveTokenAndNeedAmount
        }
    }
})
</script>
<style lang="stylus">
.box-item {
//   width: 110PX;
//   margin-top: 10PX;
    margin-left: 4PX;
    width: 16PX;
        height: 16PX;
}

.el-icon.box-item{
    cursor pointer
    svg{
        width: 16PX;
        height: 16PX;
    }
}
</style>