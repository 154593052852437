/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// @ts-ignore
import { aggregate as aggregateTool } from '@makerdao/multicall'
// import { JsonRpcProvider as JsonRpcProviderTool } from '@ethersproject/providers'
// import BigNumber from 'bignumber.js'
// import uniswapPairV2Tool from '../abis/IUniswapV2Pair.json'
import BigNumber from 'bignumber.js'
// import ContractTool from 'web3-eth-contract'
// import BigNumberTool from 'bignumber.js'
import NETWORKS from '../constants/networks'
import Store from '../store'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import ERC20TOOL from '../abis/ERC20.json'
import IMdexFactory from '../abis/IMdexFactory.json'
import { getWalletProvider, getWeb3Provider, getWeb3ProviderWallet } from './provider'
import { configProject } from '../../configProject'
import * as Config from '../constants/Config'
import { useGtm } from '@gtm-support/vue-gtm';
import { useGtag } from "vue-gtag-next";
import LogUtils from './LogUtils'

import { getJsonWalletAddress } from 'ethers/lib/utils'

const ContractTool = require('web3-eth-contract')

// const Web3HttpProvider = require('web3-providers-http')

// const optionsTool = {
//   keepAlive: true,
//   timeout: 10000, // milliseconds,
//   headers: [{ name: 'Access-Control-Allow-Origin', value: '*' }],
//   withCredentials: false
// }
// let provider: JsonRpcProviderTool = new JsonRpcProviderTool(
//   BLOCKCHAIN_CONFIG.defaultRpcUrl
// )

// if (typeof ethereumTool === 'undefined' || ethereumTool.chainId === null) {
//   provider = new ethersTool.providers.JsonRpcProvider(BLOCKCHAIN_CONFIG.defaultRpcUrl)
// } else {
//   provider = new ethersTool.providers.Web3Provider(window.ethereum)
// }

// const tpTool = require('tp-js-sdk')

const tool = {

  getDomainLang() {
    const domain = window.location.host
    const parts = domain.split('.')

    return parts[0].toLowerCase() !== null && parts[0].toLowerCase().length > 0 ? parts[0].toLowerCase() : 'en'
  },
  getDomainLocale() {
    const domain = window.location.host
    const parts = domain.split('.')

    if (parts[0].toLowerCase() === 'tw') {
      return 'zh-TW'
    } else if (parts[0].toLowerCase() === 'ja') {
      return 'ja'
    } else {
      return null
    }
  },
  getUserBrowserDefaultLang() {
    let lang = navigator.language || navigator.userLanguage // 常规浏览器语言和IE浏览器
    lang = lang.substr(0, 2)
    if (lang !== 'zh') {
      lang = 'en'
    }
    return lang
  },

  getPageLang(langPara: string) {
    let lang = langPara

    // let lang = Store.getters.lang

    if (typeof lang === 'undefined' || lang === 'undefined' || lang.length === 0) {
      lang = Store.getters.lang
      if (typeof lang === 'undefined' || lang === 'undefined' || lang.length === 0) {
        lang = this.getUserBrowserDefaultLang()
      }
    }

    // localStorage.setItem('lang', this.lang)
    // Store.dispatch('set_lang')

    return lang
  },

  canPageScroll(flag: boolean) {
    const m = function (e: any) {
      e.preventDefault()
    }
    if (!flag) {
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, { passive: false }) // 禁止页面滑动
    } else {
      document.body.style.overflow = ''
      document.removeEventListener('touchmove', m, {}) // 允许页面滑动
    }
  },

  checkNullObj(obj: any) {
    return Object.keys(obj).length === 0
  },

  getLangLink(langSet: string) {
    const localLang = this.getUserBrowserDefaultLang()

    let displayLang = window.location.pathname.replace('zh/', '').replace('en/', '')

    if (localLang !== langSet) {
      if (
        window.location.pathname.substr(window.location.pathname.length - 1, 1) === '/'
      ) {
        displayLang = `${window.location.pathname + langSet}/`
      } else {
        displayLang = `${window.location.pathname}/${langSet}/`
      }
    }

    return displayLang
  },

  getLangLinkByLink(langSet: string, link: string) {
    // const localLangLink = this.getUserBrowserDefaultLang()

    const displayLang = link.replace('zh/', '').replace('en/', '')

    // if (localLangLink !== lang_set) {
    //     if (link.substr(link.length - 1, 1) === "/") {
    //         displayLang = link + lang_set + "/"
    //     } else {
    //         displayLang = link + "/" + lang_set + "/"
    //     }
    //
    // }

    return displayLang
  },

  isEnglish(str: string) {
    return /[a-zA-Z]+/.test(str)
  },

  sortByKey(array: Array<any>, key: any) {
    return array.sort(function (a, b) {
      const x = a[key]
      const y = b[key]

      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0

      // return x < y ? -1 : x > y ? 1 : 0
    })
  },

  // awaitWrap(promise:any) {
  //   return promise
  //     .then((data) => [null, data])
  //     .catch(err => [err, null])
  // },

  formatBigNumber(bn: BigNumber, decimals: number) {
    if (bn.toNumber() === 0) {
      return '0'
    }
    if (bn.toNumber() < 1) {
      return bn.toFixed(decimals).toString()
    }
    return bn.toString()
  },

  formatNumber(number: number) {
    if (number.toString().includes('.')) {
      const arrNumber = number.toString().split('.')
      if (arrNumber[1].length <= 9) {
        return number.toString()
      }
      const arr = []
      let lastChar = ''
      arrNumber.forEach((char) => {
        if (char === '0') {
          arr.push(char)
        } else if (lastChar === '0' || lastChar === '') {
          throw new Error()
        }
        lastChar = char
      })

      // for (const char of arrNumber[1]) {
      // }

      return number.toFixed(arr.length + 2)
    }
    return number.toString()
  },
  toNonExponential(num: number) {
    if (num.toString().includes('e')) {
      const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
      if (m) {
        return num.toFixed(Math.max(0, (m[1] || '').length - (m[2] as any)))
      }
    }
    return num
  },
  // getLPFormatNumber(number, maxNumber) {
  //   if (parseFloat(number) > maxNumber) {
  //     number = maxNumber.toString()
  //   }
  //   if (number.includes('e')) {
  //     number = parseFloat(number).toFixed(9)
  //   }
  //   if (number.includes('.')) {
  //     const arr = number.split('.')
  //     if (arr[1].length > 18) {
  //       return arr[0] + '.' + arr[1].substr(0, 18)
  //     } else {
  //       return number
  //     }
  //   } else {
  //     return number
  //   }
  // },

  async getChainSymbol(hasDefault = true) {
    const web3Provider = await getWeb3ProviderWallet()
    let symbol = hasDefault ? BLOCKCHAIN_CONFIG.defaultSymbol : ''
    if (web3Provider !== null) {
      const providerNetwork = await web3Provider.getNetwork()
      if (providerNetwork.chainId && providerNetwork.chainId > 0) {
        const { chainId } = providerNetwork
        const chainIdObj = this.getDynamicObject(chainId.toString(), NETWORKS)
        if (chainIdObj !== null) {
          symbol = chainIdObj.symbol
        }
      }
    }
    return symbol
  },
  async getChainId(hasDefault = true) {
    const web3Provider = await getWeb3ProviderWallet()
    const chainId = hasDefault ? BLOCKCHAIN_CONFIG.defaultChainId : -1
    if (web3Provider !== null) {
      const providerNetwork = await web3Provider.getNetwork()
      if (providerNetwork.chainId && providerNetwork.chainId > 0) {
        const { chainId } = providerNetwork
        return chainId
      }
    } else {
      return BLOCKCHAIN_CONFIG.defaultChainId
    }
    return chainId
  },

  getTokenByAddressAndChainSymbol(address: string, chainSymbol: string) {
    const chainSymbolObj = this.getDynamicObject(
      chainSymbol,
      BLOCKCHAIN_CONFIG.tokensLogoUrl
    )
    if (chainSymbol !== null) {
      return chainSymbolObj.replace('{}', address)
    }
    return ''
  },

  async getTokenByAddress(address: string) {
    const chainSymbol = await this.getChainSymbol()
    const chainSymbolObj = this.getDynamicObject(
      chainSymbol,
      BLOCKCHAIN_CONFIG.tokensLogoUrl
    )
    if (chainSymbol !== null) {
      return chainSymbolObj.replace('{}', address)
    }
    return ''
  },

  scientificToNumber(num: any) {
    let str = num
    if (typeof num === 'number') {
      str = num.toString()
    }

    const regFloatExp = /^(\d+\.\d+)(e)([-]?\d+)$/
    const regIntegerExp = /^(\d+)(e)([-]?\d+)$/
    let arr
    let len
    let zero = ''

    if (!regFloatExp.test(str)) {
      if (!regIntegerExp.test(str)) {
        return str
      }
      arr = regIntegerExp.exec(str)
      if (arr) {
        len = Math.abs(Number(arr[3])) - 1
        for (let i = 0; i < len; i += 1) {
          zero += '0'
        }
        return `0.${zero}${arr[1]}`
      }
    }
    /* 6e-7 需要手动转换 */
    arr = regFloatExp.exec(str)
    if (arr) {
      len = Math.abs(Number(arr[3])) - 1
      for (let i = 0; i < len; i += 1) {
        zero += '0'
      }
      return `0.${zero}${arr[1].replace('.', '')}`
    }
    return str
  },

  formatFloatNumber(numStr: string, decimals: any) {
    const typeofStr = typeof numStr
    if (typeofStr === 'number') {
      numStr = numStr.toString()
    } else if (typeofStr === 'object') {
      numStr = numStr.toString()
      LogUtils.debug(numStr)
    } else if (typeofStr === 'undefined') {
      return '---'
    }

    if (parseInt(numStr, 10) === 0 && numStr.length === 1) {
      return '0'
    }

    const regFloatExp = /^\d+\.\d+$/
    const regFormatFloat = new RegExp(`^\\d+(?:\\.\\d{0,${decimals}})?`, 'g')

    if (numStr.indexOf('e') >= 0) {
      return this.scientificToNumber(numStr).match(regFormatFloat).toString()
    }

    if (regFloatExp.test(numStr)) {
      const result = numStr.match(regFormatFloat)
      if (result !== null) {
        return result.toString()
      }
    }
    return numStr
  },

  formatNumberByKMB(num: number) {

    if (num >= 1000000000) {
      return (num / 1000000000.0).toFixed(2) + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000.0).toFixed(2) + "M";
    }
    if (num >= 1000) {
      return (num / 1000.0).toFixed(2) + "K";
    }
    return num.toString();
  },

  dateTransform(seconds: number) {
    let [day, hour, minute, second] = [0, 0, 0, 0] // 初始化
    if (seconds > 0) {
      day = Math.floor(seconds / (60 * 60 * 24))
      hour = Math.floor(seconds / (60 * 60)) - day * 24
      minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60
      second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
    }
    // 小于10的，在前面加0
    const dayResult = day < 10 ? `0${day}` : day
    const hourResult = hour < 10 ? `0${hour}` : hour
    const minuteResult = minute < 10 ? `0${minute}` : minute
    const secondResult = second < 10 ? `0${second}` : second
    if (day >= 1) {
      return `${dayResult} days`
    }
    return `${hourResult}:${minuteResult}:${secondResult}`
  },

  dateTransform1(seconds: number) {
    let [day, hour, minute, second] = [0, 0, 0, 0] // 初始化
    if (seconds > 0) {
      day = Math.floor(seconds / (60 * 60 * 24))
      hour = Math.floor(seconds / (60 * 60)) - day * 24
      minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60
      second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
    }
    // 小于10的，在前面加0
    const dayResult = day
    const hourResult = hour
    const minuteResult = minute
    const secondResult = second
    if (day >= 1) {
      return `${dayResult} days ${hourResult} hours`
    }
    return `${hourResult} hours ${minuteResult} minutes`
  },

  dateTransformHMS(seconds: number) {
    let [day, hour, minute, second] = [0, 0, 0, 0] // 初始化
    if (seconds > 0) {
      day = Math.floor(seconds / (60 * 60 * 24))
      hour = Math.floor(seconds / (60 * 60)) - day * 24
      minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60
      second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
    }
    // 小于10的，在前面加0
    const dayResult = day < 10 ? `0${day}` : `${day}`
    const hourResult = hour < 10 ? `0${hour}` : `${hour}`
    const minuteResult = minute < 10 ? `0${minute}` : `${minute}`
    const secondResult = second < 10 ? `0${second}` : `${second}`

    return `${hourResult}:${minuteResult}:${secondResult}`
  },

  dateTransformLong(seconds: number) {
    let [day, hour, minute, second] = [0, 0, 0, 0] // 初始化
    if (seconds > 0) {
      day = Math.floor(seconds / (60 * 60 * 24))
      hour = Math.floor(seconds / (60 * 60)) - day * 24
      minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60
      second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
    }
    // 小于10的，在前面加0
    const dayResult = day < 10 ? `0${day}` : `${day}`
    const hourResult = hour < 10 ? `0${hour}` : `${hour}`
    const minuteResult = minute < 10 ? `0${minute}` : `${minute}`
    const secondResult = second < 10 ? `0${second}` : `${second}`

    return [dayResult, hourResult, minuteResult, secondResult]

    // if(day >= 1){
    //   return day + " days " + hour + " hours " + minute + " mins " + second + " secs "
    // }else{
    //   return hour + " hours " + minute + " mins " + second + " secs "
    // }
  },

  getPendingTextHtml(text: any) {
    const pendingHtml =
      "<svg class='rotate-circle' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='#6F4397' style='width: 16px;height: 16px;margin-left:5px;margin-top:0px'><path d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'></path></svg>"
    return `<div class='item-flex-center' style='align-items: center;'><span>${text}</span>${pendingHtml}</div>`
  },

  retainDecimals(numStr: string, decimal: number) {
    if (typeof numStr === 'string') {
      numStr = numStr.toString()
    }
    const index = numStr.indexOf('.')
    if (index !== -1) {
      numStr = numStr.substring(0, decimal + index + 1)
    } else {
      numStr = numStr.substring(0)
    }
    return Math.floor(parseFloat(numStr) * 10 ** decimal) / 10 ** decimal
  },

  zeroize(num: number) {
    return (num.toString().length === 1 ? '0' : '') + num
  },

  timestampFormat(timestamp: number) {
    const curTimestamp = parseInt((new Date().getTime() / 1000).toString(), 10) // 当前时间戳
    const timestampDiff = curTimestamp - timestamp // 参数时间戳与当前时间戳相差秒数

    const curDate = new Date(curTimestamp * 1000) // 当前时间日期对象
    const tmDate = new Date(timestamp * 1000) // 参数时间戳转换成的日期对象

    const Y = tmDate.getFullYear()
    const m = tmDate.getMonth() + 1
    const d = tmDate.getDate()
    const H = tmDate.getHours()
    const i = tmDate.getMinutes()
    // const s = tmDate.getSeconds()

    if (timestampDiff < 60) {
      // 一分钟以内
      return '刚刚'
    }
    if (timestampDiff < 3600) {
      // 一小时前之内
      return `${Math.floor(timestampDiff / 60)}分钟前`
    }
    if (
      curDate.getFullYear() === Y &&
      curDate.getMonth() + 1 === m &&
      curDate.getDate() === d
    ) {
      return `今天${this.zeroize(H)}:${this.zeroize(i)}`
    }
    const newDate = new Date((curTimestamp - 86400) * 1000) // 参数中的时间戳加一天转换成的日期对象
    if (
      newDate.getFullYear() === Y &&
      newDate.getMonth() + 1 === m &&
      newDate.getDate() === d
    ) {
      return `昨天${this.zeroize(H)}:${this.zeroize(i)}`
    }
    if (curDate.getFullYear() === Y) {
      return `${this.zeroize(m)}月${this.zeroize(d)}日 ${this.zeroize(
        H
      )}:${this.zeroize(i)}`
    }
    return `${Y}年${this.zeroize(m)}月${this.zeroize(d)}日 ${this.zeroize(
      H
    )}:${this.zeroize(i)}`
  },

  getTotalApy(
    apyFarm: number,
    feeRefund: number,
    borrowApy: number,
    plantformApy: number,
    plantformBorrowSubsidyApy: any,
    boxBorrowSubsidyApy: any,
    lavarage: number
  ) {
    const capital = 1
    const dailyAPR = apyFarm / 365
    const refundCount = (60 / 15) * 24
    // const refundCount=24503
    const amountYear =
      capital * (1 + ((dailyAPR / refundCount) * 1) / 100) ** (refundCount * 365)
    const apyRefund = ((amountYear - capital) / capital) * 100
    const result =
      (apyRefund + plantformApy) * lavarage +
      (plantformBorrowSubsidyApy + boxBorrowSubsidyApy - borrowApy) * (lavarage - 1)

    if (Number.isNaN(result)) {
      return '---'
    }
    return result.toFixed(2)
  },

  getDeviceScreenIsPC() {
    if (document.documentElement.clientWidth >= 1140) {
      return true
    }
    if (document.documentElement.clientHeight < 360) {
      window.resizeTo(360, document.documentElement.clientHeight)
    }
    return false
  },

  dealSwapMap(vueInstance: any) {
    const map = new Map()
    if (vueInstance.chain_symbol === 'BNB') {
      const { chainSymbol } = vueInstance
      const swapList = ['PANCAKE', 'MDEX']

      for (let i = 0; i < swapList.length; i += 1) {
        const ele = swapList[i]
        map.set(ele, {
          Factory: vueInstance.$networkenv.abis[chainSymbol].SWAPEX[ele].Factory,
          Router: vueInstance.$networkenv.abis[chainSymbol].SWAPEX[ele].Router,
          FarmPool: vueInstance.$networkenv.abis[chainSymbol].SWAPEX[ele].FarmPool,
          RewardToken:
            vueInstance.$networkenv.abis[chainSymbol].SWAPEX[ele].RewardToken,
          swapFactory: new ContractTool(
            vueInstance.$networkenv.abis[chainSymbol].SWAPEX[ele].Factory,
            IMdexFactory
          )
        })
      }
    }
    return map
  },

  getSwapExName(vueInstance: any) {
    if (vueInstance.chain_symbol === 'BNB') {
      // const chainId = this.getChainId()
      return 'PANCAKE'
    }

    return ''
  },

  getDynamicObject(key: string, typeKeyObj: any) {
    type keyTypes = keyof typeof typeKeyObj
    const keyObj = typeKeyObj[key as keyTypes]

    if (keyObj !== undefined) {
      return keyObj
    }
    return null
  },

  getConfigProject() {
    return Config.isDebug ? configProject.test : configProject.main
  },

  getPageCount(pagesize: number, recordcount: number) {
    const pagecount = (recordcount + pagesize - 1) / pagesize
    return pagecount
  },

  gtmTrack(events: string, category: string, action: string, label: string, value: string) {
    const { event } = useGtag()

    event(events + '-' + action, {
      'event_category': category,
      'event_label': label,
      'value': value
    })

    // const gtm = useGtm();
    // gtm!.trackEvent({
    //   event: event,
    //   category: category,
    //   action: action,
    //   label: label,
    //   value: value,
    //   noninteraction: false,
    // });
  },

  gtmTrackNew(events: string, category: string, action: string, label: string, value: string) {
    const { event } = useGtag()

    event(events, {
      'event_category': category,
      'event_label': label,
      'value': value
    })
  },

  sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time))
  },

  /**
   * format number by K,M,B,T,P,E,Z,Y
   * @param num 
   * @param decimals 
   * @returns 
   */
  formatNumberKMB(num: number, decimals: number) {
    if (num === 0) {
      return '0'
    }
    const k = 1000
    const sizes = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y']
    const i = Math.floor(Math.log(num) / Math.log(k))
    return parseFloat((num / Math.pow(k, i)).toFixed(decimals)) + sizes[i]
  },

  formatdecimalPlaces(value: BigNumber, len: number) {
    let reg = value.toString().match(/[^0.]/)
    let minLen: number = value.toString().indexOf(reg !== null ? reg[0] : '1')
    if (minLen > 2) {
      len = minLen
    }
    return value.dp(len, 1)
  },

  async checkApproveERC20Token(approveTokenInfo: any, multicallConfig: any) {

    let calls: any[] = []
    let returnValue = null

    calls = calls.concat([
      {
        target: approveTokenInfo.address,
        call: [
          'allowance(address,address)(uint256)',
          approveTokenInfo.owner,
          approveTokenInfo.contract
        ],
        returns: [['PAYTOKEN_APPROVE', (val: any) => val / (10 ** approveTokenInfo.decimals)]]
      }])

    returnValue = await aggregateTool(calls, multicallConfig)
    const returnResult = returnValue.results.transformed

    const allowanceNumber = returnResult.PAYTOKEN_APPROVE
    const needAmount = approveTokenInfo.amount
    if (Number(allowanceNumber) < needAmount) {
      return false
    } else {
      return true
    }
  },


  // async getWalletAddress(): string {
  //   let web3Provider
  //   let isWalletConnect = false
  //   let walletAddress = ""
  //   if (web3ProviderType && typeof web3ProviderType !== "undefined" && web3ProviderType!.length > 0) {
  //     const key = TOOL.getDynamicObject(web3ProviderType!, WEB3_PROVIDER_TYPES)
  //     if (key) {
  //       web3Provider = await connectWeb3Provider(key)
  //       if (web3Provider) {
  //         isWalletConnect = true
  //       }
  //     }
  //   }
  //   if (isWalletConnect) {
  //     if (web3Provider) {
  //       const provider = web3Provider.provider
  //       const signer = web3Provider.getSigner()
  //       walletAddress = await signer.getAddress()
  //     }
  //   }
  //   return walletAddress
  // }

}

export default tool
