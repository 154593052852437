<template>
  <div class="page-mynft" style="position: relative;height: auto">
    <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
      <div v-if="modalShowIndex === 2"
        class="mask-nft mobile-filter d-flex flex-column justify-content-end align-items-center">
        <div class="dialog-content d-flex">
          <div class="gap" @click.stop="closeModal()"></div>
          <div class="mobile-menu">
            <div class="filter-title-block d-flex justify-content-between align-items-center">
              <span class="title">{{ $t('Filter.title') }}</span>
              <!-- <span @click="resetFilter" class="reset">Reset</span> -->
            </div>
            <div class="filter-type-block d-flex flex-column justify-content-center">
              <span class="title">{{ $t('Filter.title-character') }}</span>
              <el-checkbox-group v-model="checkListCha" @change="changeCheckboxGroup"
                class="option-list d-flex align-items-center">
                <template v-for="(cha, chaIndex) of filterCharacterList" :key="'cha-' + chaIndex">
                  <el-checkbox :label="chaIndex" size="small">{{ $t(`Character.${cha.name}`) }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
            <div class="filter-type-block d-flex flex-column justify-content-center">
              <span class="title">{{ $t('Filter.title-rarity') }}</span>
              <el-checkbox-group class="option-list rarity d-flex align-items-center" v-model="checkListRarity"
                @change="changeCheckboxGroup">
                <template v-for="(rarity, rarityIndex) of filterRarityList" :key="'rarity-' + rarityIndex">
                  <el-checkbox size="small" :label="rarityIndex">
                    {{
                        rarity.name
                    }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>

    </el-dialog>
    <div class="mynft d-flex justify-content-center">
      <div v-if="!isMobile" class="left">
        <u-c-left></u-c-left>
      </div>
      <div class="right d-flex flex-column">
        <template v-if="!isLoading">
          <template v-if="walletAddress.length > 0">
            <div class="content" :class="myNFTItemList[categoryIndexNumber].length === 0 ? 'empty' : ''">

              <div class="d-flex top align-items-center"
                :class="isMobile ? 'radio-button-group justify-content-center' : 'justify-content-between'">
                <el-radio-group v-model="categoryIndexNumber" size="large" @change="categorySwitch">
                  <el-radio-button :label="0">
                    <div class="mobile-title">
                      {{ $t('MyNFT.title-mynft') }}({{ recordCount[0] }})
                    </div>
                  </el-radio-button>
                  <el-radio-button :label="1">
                    <div class="mobile-title">{{ $t('MyNFT.title-rentin') }}({{ recordCountRentIn }})</div>
                  </el-radio-button>
                  <el-radio-button :label="2">
                    <div class="mobile-title">{{ $t('MyNFT.title-soldout') }}({{ recordCountSoldOut }})</div>
                  </el-radio-button>
                  <!-- <el-radio-button :label="3">Collateral({{ recordCount[3] }})</el-radio-button> -->
                </el-radio-group>
                <div v-if="!isMobile" :class="isShowPCSearchRight ? 'expand' : 'collapsed'"
                  class="search d-flex align-items-center justify-content-center">
                  <input v-if="isShowPCSearchRight" @keyup.enter="searchInput()" v-model="searchKewords"
                    :placeholder="$t('Search.search-by-nft-id-or-name')" onfocus="this.placeholder=''"
                    onblur="this.placeholder=$t('Search.search-by-nft-id-or-name')" />
                  <img v-if="!isShowPCSearchRight" @click.stop="isShowPCSearchRight = true"
                    src="/static/images/icon_search.png" />
                  <svg class="svg" @click.stop="closeSearch()" v-if="isShowPCSearchRight" viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="#FFFFFF"
                      d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                  </svg>
                </div>
              </div>
              <div v-if="!isMobile && categoryIndexNumber === 0"
                class="filter-block d-flex align-items-start justify-content-between">
                <div class="filter-block d-flex align-items-center"
                  @click.self="isShowPCSelectAttr = false; isShowPCSelectRarity = false; isShowPCSelectState = false; isShowPCSearchRight = false">
                  <!-- <span class="filter-title">My NFT</span> -->

                  <div class="select" style="width: 120PX;">
                    <div v-if="!isShowPCSelectState" @click.stop="isShowPCSelectState = !isShowPCSelectState"
                      class="select-input d-flex align-items-center">
                      <span class="title">
                        <template v-if="tagMyNFTIndex >= 0">
                          {{ $t(`MyNFT.filter-taglist.${nftTagList[tagMyNFTIndex].key}`)
                          }}</template>
                        <template v-else>{{ $t('MyNFT.filter-state') }}</template>
                      </span>
                    </div>
                    <div v-else class="list d-flex flex-column" :class="isShowPCSelectState ? 'expand' : 'collapsed'">
                      <div :class="tagMyNFTIndex === cIndex ? 'active' : ''" class="select-item"
                        v-for="(c, cIndex) of nftTagList" :key="'select-state-' + cIndex" @click="switchTag(cIndex)">
                        {{
                            $t(`MyNFT.filter-taglist.${c.key}`)
                        }}
                      </div>
                    </div>
                    <img @click.stop="isShowPCSelectState = !isShowPCSelectState" class="arrow"
                      :class="isShowPCSelectState ? 'rotate' : ''" src="/static/images/arrow_select.png" />
                  </div>
                  <div class="select" style="width: 236PX;">
                    <div v-if="!isShowPCSelectCha" class="select-input d-flex align-items-center"
                      :class="checkListCha.length > 0 ? 'items' : ''">
                      <span v-if="checkListCha.length === 0" class="title">{{ $t('Filter.title-character') }}
                      </span>
                      <div v-else class="select-item-list">
                        <template v-for="(chaSelectIndex, index) of checkListCha" :key="'cha-select-' + index">
                          <el-tag @close="removeCheckList(0, index)" v-if="index <= 1" closable>
                            {{ $t(`Character.${filterCharacterList[chaSelectIndex].name}`) }}
                          </el-tag>
                        </template>
                        <div v-if="checkListCha.length > 2" class="item-more">...</div>
                      </div>
                    </div>
                    <el-checkbox-group v-else v-model="checkListCha" @change="changeCheckboxGroup"
                      class="option-list d-flex align-items-center">
                      <template v-for="(cha, chaIndex) of filterCharacterList" :key="'cha-' + chaIndex">
                        <el-checkbox :label="chaIndex" size="small">{{ $t(`Character.${cha.name}`) }}
                        </el-checkbox>
                      </template>
                    </el-checkbox-group>
                    <div class="arrow-remove-block">
                      <div @click.stop="checkListCha = []; changeCheckboxGroup()"
                        v-if="!isShowPCSelectCha && checkListCha.length > 0" class="item-action br">
                        <svg class="arrow-remove-remove" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M764.288 214.592L512 466.88 259.712 214.592a31.936 31.936 0 00-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1045.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0045.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 10-45.12-45.184z">
                          </path>
                        </svg>
                      </div>
                      <div @click.stop="isShowPCSelectCha = !isShowPCSelectCha" class="item-action">
                        <img class="arrow-remove-arrow" :class="isShowPCSelectCha ? 'rotate' : ''"
                          src="/static/images/arrow_select.png" />
                      </div>
                    </div>
                  </div>
                  <div class="select" style="width: 176PX;">
                    <div v-if="!isShowPCSelectRarity" class="select-input d-flex align-items-center"
                      :class="checkListRarity.length > 0 ? 'items' : ''">
                      <span v-if="checkListRarity.length === 0" class="title">{{ $t('Filter.title-rarity') }}
                      </span>
                      <div v-else class="select-item-list">
                        <template v-for="(raritySelectIndex, index) of checkListRarity" :key="'rarity-select-' + index">
                          <el-tag @close="removeCheckList(1, index)" v-if="index <= 1" closable>
                            {{ filterRarityList[raritySelectIndex].name }}
                          </el-tag>
                        </template>
                        <div v-if="checkListRarity.length > 2" class="item-more">...</div>
                      </div>
                    </div>
                    <el-checkbox-group v-else v-model="checkListRarity" @change="changeCheckboxGroup"
                      class="option-list d-flex align-items-center">
                      <template v-for="(rarity, rarityIndex) of filterRarityList" :key="'rarity-' + rarityIndex">
                        <el-checkbox :label="rarityIndex" size="small">{{ rarity.name }}
                        </el-checkbox>
                      </template>
                    </el-checkbox-group>
                    <div class="arrow-remove-block">
                      <div @click="checkListRarity = []; changeCheckboxGroup()"
                        v-if="!isShowPCSelectRarity && checkListRarity.length > 0" class="item-action br">
                        <svg class="arrow-remove-remove" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M764.288 214.592L512 466.88 259.712 214.592a31.936 31.936 0 00-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1045.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0045.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 10-45.12-45.184z">
                          </path>
                        </svg>
                      </div>
                      <div @click.stop="isShowPCSelectRarity = !isShowPCSelectRarity" class="item-action">
                        <img class="arrow-remove-arrow" :class="isShowPCSelectRarity ? 'rotate' : ''"
                          src="/static/images/arrow_select.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="isMobile" class="top d-flex align-items-center justify-content-between">
                <span class="filter-title"><button v-if="categoryIndexNumber === 0" class="filter-button"
                    @click="showModal(2)">{{ $t('Filter.title') }}</button></span>
                <div class="d-flex align-items-center justify-content-end">
                  <div :class="isShowPCSearchRight ? 'expand' : 'collapsed'"
                    class="search d-flex align-items-center justify-content-center">
                    <input v-if="isShowPCSearchRight" @keyup.enter="searchInput()" v-model="searchKewords"
                      :placeholder="$t('Search.search-nft-id-or-name')" onfocus="this.placeholder=''"
                      onblur="this.placeholder=$t('Search.search-nft-id-or-name')" />
                    <img v-if="!isShowPCSearchRight" @click.stop="isShowPCSearchRight = true"
                      src="/static/images/icon_search.png" />
                    <svg class="svg" @click.stop="closeSearch()" v-if="isShowPCSearchRight" viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill="#FFFFFF"
                        d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                    </svg>
                  </div>
                  <div class="select" style="width: 110PX;">
                    <div v-if="!isShowPCSelectState" @click.stop="isShowPCSelectState = !isShowPCSelectState"
                      class="select-input d-flex align-items-center">
                      <span class="title">
                        <template v-if="tagMyNFTIndex >= 0">
                          {{ $t(`MyNFT.filter-taglist.${nftTagList[tagMyNFTIndex].key}`)
                          }}</template>
                        <template v-else>{{ $t('MyNFT.filter-state') }}</template>
                      </span>
                    </div>
                    <div v-else class="list d-flex flex-column" :class="isShowPCSelectState ? 'expand' : 'collapsed'">
                      <div :class="tagMyNFTIndex === cIndex ? 'active' : ''" class="select-item"
                        v-for="(c, cIndex) of nftTagList" :key="'select-state-' + cIndex" @click="switchTag(cIndex)">
                        {{
                            $t(`MyNFT.filter-taglist.${c.key}`)
                        }}
                      </div>
                    </div>
                    <img @click.stop="isShowPCSelectState = !isShowPCSelectState" class="arrow"
                      :class="isShowPCSelectState ? 'rotate' : ''" src="/static/images/arrow_select.png" />
                  </div>
                </div>
              </div>

              <div v-if="myNFTItemList[categoryIndexNumber].length > 0" class="nft-item-list">
                <div @click="go2Detail(itemIndex)" :class="nft['belongto'] === 'renttimeout' ? 'disabled' : ''"
                  class="d-flex mynft-item flex-column align-items-center"
                  v-for="(nft, itemIndex) of myNFTItemList[categoryIndexNumber]" :key="'index_' + itemIndex">
                  <!-- <div > -->
                  <span class="status" v-if="categoryIndexNumber <= 1"
                    :class="[nft['belongto'] !== 'wallet' ? nft['belongto'] : '', categoryIndexNumber === 1 ? 'alignend' : '']">
                    {{ $t(`NFTState.${nft['belongto']}`) }}
                  </span>
                  <div class="nft-item d-flex align-items-center justify-content-center">
                    <img :src="nft['preview']" class="nft-item-image" />
                    <div class="nft-item-intro d-flex flex-column align-items-start">
                      <div class="nft-item-intro-title">{{ $t(`CardList.${nft['typeid']}.name`) }}</div>
                      <div class="nft-item-intro-title">#{{ nft['tokenid'] }}</div>
                      <!-- <div v-else class="nft-item-intro-upgrade">AMOUNT: {{ nft['amount'] }}</div> -->
                    </div>
                    <div class="nft-item-intro-upgrade d-flex justify-content-end align-items-center">
                      <!-- <img src="/static/images/element_lighting.png" /> -->
                      <span class="text" :class="nft['level'] > 0 ? 'nozero' : ''">LV. {{ nft['level'] }}/5</span>
                    </div>
                    <div class="nft-item-attrs">
                      <div v-if="nft['earth'] > 0"
                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                        <img class="nft-item-attrs-image" src="/static/images/element_attr_yellow_earth.png" />
                        <span class="nft-item-attrs-text earth" :class="nft['earth'] >= 10 ? 'digit2' : ''">{{
                            nft['earth']
                        }}</span>
                      </div>
                      <div v-if="nft['water'] > 0"
                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                        <img class="nft-item-attrs-image" src="/static/images/element_attr_blue_water.png" />
                        <span class="nft-item-attrs-text water" :class="nft['water'] >= 10 ? 'digit2' : ''">{{
                            nft['water']
                        }}</span>
                      </div>
                      <div v-if="nft['fire'] > 0"
                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                        <img class="nft-item-attrs-image" src="/static/images/element_attr_red_fire.png" />
                        <span class="nft-item-attrs-text fire" :class="nft['fire'] >= 10 ? 'digit2' : ''">{{
                            nft['fire']
                        }}</span>
                      </div>
                      <div v-if="nft['wind'] > 0"
                        class="nft-item-attrs-image-block d-flex align-items-center justify-content-center">
                        <img class="nft-item-attrs-image" src="/static/images/element_attr_green_wind.png" />
                        <span class="nft-item-attrs-text wind" :class="nft['wind'] >= 10 ? 'digit2' : ''">{{
                            nft['wind']
                        }}</span>
                      </div>
                      <div class="nft-item-attr d-flex align-items-center"></div>
                    </div>
                    <img class="nft-item-attrs-image-level"
                      :src="'/static/images/element_level_' + getLowerCase(nft['rarity']) + '.png'" />

                  </div>
                  <!-- <div v-if="nft['belongto'] === 'forsell'" class="d-flex align-items-center justify-content-end"
                  style="width:100%;">
                  <div class="nft-item-price-mynftsell font-9px">
                    Price: {{ nft['price'] }} {{ nft['paytokensymbol'] }}
                  </div>
                </div> -->
                  <div v-if="nft['belongto'] === 'rentin'" class="nft-item-rent-countdown-block">
                    <div class="return-time" v-if="currentTime > 0">
                      <div class="return-time-item" v-for="(rentin, rentinIndex) of getRentInCountDown(itemIndex)"
                        :key="'rentin-' + rentinIndex">
                        <div class="number-block">
                          <div class="number">
                            {{ rentin.toString().charAt(0) }}
                          </div>
                          <div class="number">
                            {{ rentin.toString().charAt(1) }}
                          </div>
                        </div>
                        <div class="time-unit-text">
                          <template v-if="rentinIndex === 0">{{ $t('Common.days') }}</template>
                          <template v-else-if="rentinIndex === 1">{{ $t('Common.hrs') }}</template>
                          <template v-else-if="rentinIndex === 2">{{ $t('Common.mins') }}</template>
                          <template v-else>{{ $t('Common.secs') }}</template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="nft['belongto'] === 'collateral'" class="nft-item-rent-countdown-block">
                    <div class="return-time" v-if="currentTime > 0">
                      <div class="return-time-item" v-for="(redeem, redeemIndex) of getRedeemCountDown(itemIndex)"
                        :key="'redeem-' + redeemIndex">
                        <div class="number-block">
                          <div class="number">
                            {{ redeem.toString().charAt(0) }}
                          </div>
                          <div class="number">
                            {{ redeem.toString().charAt(1) }}
                          </div>
                        </div>
                        <div class="time-unit-text">
                          <template v-if="redeemIndex === 0">{{ $t('Common.days') }}</template>
                          <template v-else-if="redeemIndex === 1">{{ $t('Common.hrs') }}</template>
                          <template v-else-if="redeemIndex === 2">{{ $t('Common.mins') }}</template>
                          <template v-else>{{ $t('Common.secs') }}</template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-empty v-else :description="$t('Common.no-items')" />
            </div>
            <template v-if="!isMobile">
              <div v-if="recordCount[categoryIndexNumber] / pagePerCount > 1"
                class="pageinfo d-flex align-items-center justify-content-end">
                <div class="nft-filter-text">{{ recordCount[categoryIndexNumber] }} {{ $t('MyNFT.items') }}</div>
                <template v-if="categoryIndexNumber === 0">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
                <template v-else-if="categoryIndexNumber === 1">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
                <template v-else-if="categoryIndexNumber === 2">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
                <template v-else-if="categoryIndexNumber === 3">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
              </div>

            </template>
            <template v-else>
              <div v-if="myNFTItemList[categoryIndexNumber].length < recordCount[categoryIndexNumber] && !isLoading"
                class="d-flex justify-content-center loadmore">
                <div v-if="!isLoadingMore" @click="getDepartPageData(pageNow[categoryIndexNumber] + 1)">{{
                    $t('Common.load-more-data')
                }}
                </div>
                <div v-else class="d-flex justify-content-center align-items-center">
                  <span>{{ $t('Common.loading-more') }}</span>
                  <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffffff" style="width: 16px; height: 16px;margin-left: 6PX;">
                    <path
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </template>
          </template>
          <div v-else class="content">
            <el-empty :description="$t('Common.please-connect-your-wallet')" />
          </div>

        </template>
      </div>
    </div>
  </div>

</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateMyNFT } from '@makerdao/multicall'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  getCurrentInstance,
  toRefs,
  watch,
  inject,
  nextTick
} from 'vue'
import UCLeft from '@/components/UCLeft.vue'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import { ElMessageBox, ElDialog, ElInputNumber, ElButton, ElLoading } from 'element-plus'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '@/utils/provider'
import TapDesktop from '../abis/TapDesktop.json'
import ERC721 from '../abis/ERC721.json'
import ERC20 from '../abis/ERC20.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { useRouter } from 'vue-router'
import LogUtils from '@/utils/LogUtils'

export default defineComponent({
  name: 'MyNFT',
  components: {
    Pagination,
    ElMessageBox,
    ElDialog,
    ElInputNumber,
    ElButton,
    ElLoading,
    UCLeft
  },
  props: {
    walletAddress: {
      type: String,
      default: ''
    },
    isReady: {
      type: Boolean,
      default: false
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    watch(
      () => props.walletAddress,
      (newWalletAddr, oldWalletAddr) => {
        // LogUtils.debug(newWalletAddr)
        // LogUtils.debug(oldWalletAddr)
        if (!isLoadingInit.value) {
          if (newWalletAddr !== oldWalletAddr) {
            LogUtils.debug('load from change wallet...')
            loadData()
          }
        }
      },
    )

    watch(
      () => props.isRefresh,
      (newReady, oldReady) => {
        LogUtils.debug(newReady)
        LogUtils.debug(oldReady)
        if (newReady && newReady !== oldReady) {
          if (props.walletAddress.length === 0) {
            buttonDisabled.value = true
          } else {
            buttonDisabled.value = false
          }
          LogUtils.debug('load from ready...')
          loadData()
        }
      }
    )

    const createNotification = <CreateNotification>inject('create-notification')
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const router = useRouter()
    const BigNumber =
      getCurrentInstance()?.appContext.config.globalProperties.$BigNumber
    const refPaginationMyNFT0: Ref<any> = ref(null)
    const refPaginationMyNFT1: Ref<any> = ref(null)
    const refPaginationMyNFT2: Ref<any> = ref(null)
    const refPaginationMyNFT3: Ref<any> = ref(null)
    const pageChainConfig: any = {}
    const isLoading = ref(true)
    const buttonDisabled = ref(false)
    const isLoadingInit = ref(true)
    const isMobile = ref(false)
    const isShowModal = ref(false)
    const isMarketPlaceOpen: Ref<boolean> = ref(BLOCKCHAIN_CONFIG.isMarketPlaceOpen)
    const modalInfotip = ref('')
    const modalShowIndex = ref(-1)
    const nftSelectIndex = ref(-1)
    const myNFTItemList: Ref<any[]> = ref([[], [], [], []])
    const nftOptType = ref(0)
    const tagMyNFTIndex = ref(0)
    const categoryIndex = ref('0')
    const categoryIndexNumber = ref(0)
    const isShowPCSelectRight = ref(false)
    const isShowPCSelectCha = ref(false)
    const isShowPCSelectRarity = ref(false)
    const isShowPCSelectAttr = ref(false)
    const isShowPCSelectState = ref(false)
    const isShowPCSearchRight = ref(true)
    const isLoadingMore = ref(false)
    const searchKewords = ref('')
    const { walletAddress } = toRefs(props)
    const sortCheckedIndex = ref(0)
    const chaCheckedIndex = ref(-1)
    const rarityCheckedIndex = ref(-1)
    const attrCheckedIndex = ref(-1)
    const pagePerCount = ref(21)
    const pageNow = ref([1, 1, 1, 1])
    const pageCount = ref([1, 1, 1, 1])
    const recordCount = ref([0, 0, 0, 0])
    const recordCountAll = ref(0)
    const recordCountInGame = ref(0)
    const recordCountCollection = ref(0)
    const recordCountForSale = ref(0)
    const recordCountRentIn = ref(0)
    const recordCountSoldOut = ref(0)
    const recordCountCollateral = ref(0)
    const checkListCha: Ref<number[]> = ref([])
    const checkListRarity: Ref<number[]> = ref([])
    const orderAPIPara = ref('o1')
    const filterAPIPara = ref('')
    const filterSearchAPIPara: Ref<any[]> = ref(['', '', '', ''])
    // const filterTagListPara = ['taall', 'sasell', 'sagame', 'sawallet']
    const nftTagList = ref([
      {
        tag: 'All NFT',
        value: 'taall',
        key: 'all-nft'
      },
      {
        tag: 'For Sale',
        value: 'sasell',
        key: 'for-sale'
      },
      {
        tag: 'For Rent',
        value: 'sarent',
        key: 'for-rent'
      },
      {
        tag: 'Rent Out',
        value: 'sarout',
        key: 'rent-out'
      },
      {
        tag: 'In Game',
        value: 'sagame',
        key: 'in-game'
      },
      {
        tag: 'Collection',
        value: 'sawallet',
        key: 'collection'
      },
    ])
    const conditionFilter: Ref<{
      name: string;
      value: string;
      order: number;
    }[]> = ref([])
    const filterSortList: Ref<{
      name: string;
      value: string;
      order: number;
    }[]> = ref([])
    const filterTypeList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterCharacterList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterRarityList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterAttrList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    let chainId = -1
    const nftStateMap = {
      forsell: 'For Sale',
      rentout: 'Rent Out',
      rentin: 'Rent In',
      renttimeout: 'Closed',
      wallet: 'Collection',
      ingame: 'In Game',
      transaction: 'Transacting',
      forrent: 'For Rent',
      soldout: 'Sold Out'
    }
    const chainSymbol = ref('')
    const gameAccount = ref('')
    let assetsAPIInfo = {
      goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
      mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
      taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
      wallet: { accountid: 0, address: '', nickname: '' },
    }
    let assetsAPIMyNFTListData: {
      count: 0
      list: []
      desktop: ''
      nftassets: '',
      rechargefee: 0,
      nftingame: '',
      countall: 0,
      countingame: 0,
      countcollection: 0,
      countforsell: 0,
      goodsNFT: {
        feeRate: 0
        goodsid: 0
        nft: ""
        pay: ""
        token: ""
        typenft: false
      },
      goodsToken: {
        feeRate: 0
        goodsid: 0
        nft: ""
        pay: ""
        token: ""
        typenft: false
      },

    }
    let mynftAPIInfo: {
      filtertype: any[]
      filterchar: any[]
      filterrarity: any[]
      filterattr: any[]
      ordertype: any[]
      paytype: any[]
      pt0price: any[]
      pt1price: any[]
      chainid: 0,
      desktop: ''
    }
    let mynftRentInData: {
      list: [],
      pageindex: 0,
      atype: -1,
      countsoldout: 0,
      countrentin: 0
    }
    let mynftSoldOutData: {
      list: [],
      pageindex: 0,
      atype: -1,
      countsoldout: 0,
      countrentin: 0
    }
    let mynftCollateralData: {
      list: [],
      pageindex: 0,
      count: 0,
    }

    const currentTime = ref(0)
    let updateReturnTimeTask: NodeJS.Timer | null = null
    let updateCurrentTimeTask: NodeJS.Timer | null = null

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      setPagePerCount()

      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
        setPagePerCount()
      })
      // const { walletAddress } = toRefs(props)
      // if (walletAddress.value.length > 0) {
      if (props.isRefresh && isLoadingInit) {
        LogUtils.debug('load from onMounted...')
        loadData()
      }
      // }
    })

    onUnmounted(() => {
      if (updateReturnTimeTask !== null) {
        clearInterval(Number(updateReturnTimeTask))
        updateReturnTimeTask = null
      }

      if (updateCurrentTimeTask !== null) {
        clearInterval(Number(updateCurrentTimeTask))
        updateCurrentTimeTask = null
      }
    })

    const switchTag = (index: number) => {
      if (categoryIndexNumber.value === 0) {
        tagMyNFTIndex.value = index
        isShowPCSelectState.value = false
        pageNow.value[categoryIndexNumber.value] = 1
        // if (refPaginationMyNFT0.value) {
        //   refPaginationMyNFT0.value.setCurPage(1)
        // }
        getPageData()
      }

    }

    const go2Detail = (index: number) => {
      saveMYNFTStorageSetting()
      const nft = myNFTItemList.value[categoryIndexNumber.value][index]
      if (nft['belongto'] !== 'renttimeout') {
        if (categoryIndexNumber.value === 2) {
          router.push(
            `/nftrecord/${nft['tokenid']}/soldout/${nft['orderid']}`
          )
        } else {
          router.push(
            `/nft/${nft['tokenid']}`
          )
        }
      }
    }

    const getRentInCountDown = (index: number) => {
      const rentin = mynftRentInData.list[index]
      const delta = rentin['endtime'] - currentTime.value
      if (delta > 0) {
        return TOOL.dateTransformLong(delta)
      } else {
        return ['00', '00', '00', '00']
      }
    }

    const getRedeemCountDown = (index: number) => {
      const redeem = mynftCollateralData.list[index]
      const delta = redeem['loanendtime'] - currentTime.value
      if (delta > 0) {
        return TOOL.dateTransformLong(delta)
      } else {
        return ['00', '00', '00', '00']
      }
    }

    const categorySwitch = (key: string, keyPath: string[]) => {
      categoryIndexNumber.value = parseInt(key)
    }

    const removeCheckList = (source: number, indexRemove: number) => {
      if (source === 0) {
        checkListCha.value = checkListCha.value.filter(function (value, index, arr) {
          return index !== indexRemove
        })

        // checkListCha.value
      } else {
        checkListRarity.value = checkListRarity.value.filter(function (value, index, arr) {
          return index !== indexRemove
        })
      }
      changeCheckboxGroup()
    }

    const closeSearch = () => {
      isShowPCSearchRight.value = false
      filterSearchAPIPara.value[categoryIndexNumber.value] = ''
      pageNow.value[categoryIndexNumber.value] = 1
      searchKewords.value = ''

      switch (categoryIndexNumber.value) {
        case 0:
          if (refPaginationMyNFT0.value) {
            refPaginationMyNFT0.value.setCurPage(1)
          }
          break;
        case 1:
          if (refPaginationMyNFT1.value) {
            refPaginationMyNFT1.value.setCurPage(1)
          }
          break;
        case 2:
          if (refPaginationMyNFT2.value) {
            refPaginationMyNFT2.value.setCurPage(1)
          }
          break;
        default:
          break;
      }


      getPageData()
    }

    const switchSort = (index: number) => {
      getPageData()
    }

    const changeRadioGroup = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
      await getDepartPageData(1)
      nextTick(() => {
        loadingInstance.close()
      })

    }

    const changeCheckboxGroup = () => {
      // const sortCur = sortCheckedIndex.value
      // const sortData = filterSortList.value[sortCur]

      getPageData()
    }

    // const resetFilter = () => {
    //   checkListCha.value = []
    //   checkListRarity.value = []
    //   // valueSliderEarth.value = -2
    //   // valueSliderWater.value = -2
    //   // valueSliderFire.value = -2
    //   // valueSliderWind.value = -2
    //   // checkListPrice.value = []
    // }

    const setPagePerCount = () => {
      if (isMobile.value) {
        pagePerCount.value = 30
      } else {
        const leftContentWidth = 210
        const contentPadding = 40 * 2
        const width = 210
        const col_gap = 16
        const windowWidth = window.innerWidth
        const contentWidth = windowWidth - leftContentWidth - contentPadding

        // LogUtils.debug(contentWidth)
        const col_number = contentWidth / (width + col_gap)
        const col_number_integer = parseInt(col_number.toString(), 10)

        // LogUtils.debug(col_number_integer)

        pagePerCount.value = 3 * col_number_integer
      }
      // pagePerCount.value = 5
    }

    const searchInput = async () => {
      const keyword = searchKewords.value.trim()
      if (keyword.length > 0) {
        const encode = encodeURI(keyword)
        pageNow.value[categoryIndexNumber.value] = 1
        const filterAPIP = filterAPIPara.value.length > 0 ? filterAPIPara.value + ',' + nftTagList.value[tagMyNFTIndex.value].value : nftTagList.value[tagMyNFTIndex.value].value

        if (categoryIndexNumber.value === 0) {
          const urlMyNFTList = `${API.assetsListNFT + chainId}/${walletAddress.value}/${pageNow.value[categoryIndexNumber.value] - 1}/${pagePerCount.value}/?filter=sn${encode},${filterAPIP}`
          assetsAPIMyNFTListData = (await axios.get(urlMyNFTList)).data.data

          filterSearchAPIPara.value[categoryIndexNumber.value] = `sn${encode}`
          myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTListData.list

          recordCount.value[categoryIndexNumber.value] = assetsAPIMyNFTListData.count

          recordCountAll.value = assetsAPIMyNFTListData.countall
          recordCountCollection.value = assetsAPIMyNFTListData.countcollection
          recordCountForSale.value = assetsAPIMyNFTListData.countforsell
          recordCountInGame.value = assetsAPIMyNFTListData.countingame
        } else {
          if (categoryIndexNumber.value === 1) {
            const urlMyNFTListRentIn = `${API.assetsListTemptory + chainId}/${walletAddress.value}/2/${pageNow.value[categoryIndexNumber.value] - 1}/${pagePerCount.value}/?filter=sn${encode}`
            mynftRentInData = (await axios.get(urlMyNFTListRentIn)).data.data

            filterSearchAPIPara.value[categoryIndexNumber.value] = `sn${encode}`
            myNFTItemList.value[categoryIndexNumber.value] = mynftRentInData.list
            recordCount.value[categoryIndexNumber.value] = mynftRentInData.countrentin
            recordCountRentIn.value = mynftRentInData.countrentin
          } else if (categoryIndexNumber.value === 2) {
            const urlMyNFTListSoldOut = `${API.assetsListTemptory + chainId}/${walletAddress.value}/1/${pageNow.value[categoryIndexNumber.value] - 1}/${pagePerCount.value}/?filter=sn${encode}`
            mynftSoldOutData = (await axios.get(urlMyNFTListSoldOut)).data.data

            filterSearchAPIPara.value[categoryIndexNumber.value] = `sn${encode}`
            myNFTItemList.value[categoryIndexNumber.value] = mynftSoldOutData.list
            recordCount.value[categoryIndexNumber.value] = mynftSoldOutData.countsoldout
            recordCountSoldOut.value = mynftSoldOutData.countsoldout
          } else if (categoryIndexNumber.value === 3) {
            const urlMyNFTListCollateral = `${API.assetsListNFTCollateral + chainId}/${walletAddress.value}/${pageNow.value[categoryIndexNumber.value] - 1}/${pagePerCount.value}/?filter=sn${encode}`
            mynftCollateralData = (await axios.get(urlMyNFTListCollateral)).data.data

            filterSearchAPIPara.value[categoryIndexNumber.value] = `sn${encode}`
            myNFTItemList.value[categoryIndexNumber.value] = mynftCollateralData.list
            recordCount.value[categoryIndexNumber.value] = mynftCollateralData.count
            recordCountCollateral.value = mynftCollateralData.count
          }
        }
      }
    }

    const getLowerCase = (str: any) => {
      return str.toString().toLowerCase()
    }

    const getPageData = async (categoryIndexN: number = -1, tagMyNFTIndexN: number = -1, pageN: number = -1, pagePerN: number = -1) => {
      if (isMobile.value) {
        isLoadingMore.value = true
      }

      let flag = false

      let filterPara = ''
      const filterArr = []
      const filterInner = []

      const categoryIndexPara = categoryIndexN === -1 ? categoryIndexNumber.value : categoryIndexN
      const tagMyNFTIndexPara = tagMyNFTIndexN === -1 ? tagMyNFTIndex.value : tagMyNFTIndexN
      const pageNowPara = pageN === -1 ? pageNow.value[categoryIndexPara] - 1 : pageN - 1
      const pagePerPara = pagePerN === -1 ? pagePerCount.value : pagePerN

      filterArr.push(nftTagList.value[tagMyNFTIndexPara].value)
      // const checkListAttr = [valueSliderEarth.value, valueSliderWater.value, valueSliderFire.value, valueSliderWind.value]
      // const checkListAttr = []

      if (categoryIndexPara === 0) {
        if (checkListCha.value.length > 0 || checkListRarity.value.length > 0) {
          // if (chaCheckedIndex.value >= 0 || rarityCheckedIndex.value >= 0 || attrCheckedIndex.value >= 0) {
          pageNow.value[categoryIndexPara] = 1

          if (checkListCha.value.length > 0) {
            const filter = filterCharacterList.value.filter((item, index) => {
              return checkListCha.value.includes(index)
            })
            const filterResult = filter.map(item => {
              return item.value
            })
            filterArr.push(filterResult.join(','))
            filterInner.push(filterResult.join(','))
            // filterArr.push(filterCharacterList.value[chaCheckedIndex.value].value)
          }
          if (checkListRarity.value.length > 0) {
            const filter = filterRarityList.value.filter((item, index) => {
              return checkListRarity.value.includes(index)
            })
            const filterResult = filter.map(item => {
              return item.value
            })
            filterArr.push(filterResult.join(','))
            filterInner.push(filterResult.join(','))
            // filterArr.push(filterRarityList.value[rarityCheckedIndex.value].value)
          }

          flag = true
        } else {
          filterPara = filterAPIPara.value
        }

        if (filterArr.length > 0) {
          flag = true
        }

      } else {
        flag = true
      }


      if (flag) {
        if (filterSearchAPIPara.value[categoryIndexPara].length > 0) {
          filterArr.push(filterSearchAPIPara.value[categoryIndexPara])
        }
        if (filterArr.length > 0) {
          filterPara = filterArr.join(',')
        }

        if (pageNowPara === 1 && !isMobile.value) {
          if (categoryIndexPara === 0) {
            if (refPaginationMyNFT0.value) {
              refPaginationMyNFT0.value.setCurPage(1)
            }
          } else if (categoryIndexPara === 1) {
            if (refPaginationMyNFT1.value) {
              refPaginationMyNFT1.value.setCurPage(1)
            }
          } else if (categoryIndexPara === 2) {
            if (refPaginationMyNFT2.value) {
              refPaginationMyNFT2.value.setCurPage(1)
            }
          } else if (categoryIndexPara === 3) {
            if (refPaginationMyNFT3.value) {
              refPaginationMyNFT3.value.setCurPage(1)
            }
          }

        }

        if (categoryIndexPara === 0) {
          const urlMyNFTList = `${API.assetsListNFT + chainId}/${walletAddress.value}/${pageNowPara}/${pagePerPara}/?filter=${filterPara}`
          assetsAPIMyNFTListData = (await axios.get(urlMyNFTList)).data.data

          myNFTItemList.value[categoryIndexPara] = assetsAPIMyNFTListData.list

          recordCount.value[categoryIndexPara] = assetsAPIMyNFTListData.count

          recordCountAll.value = assetsAPIMyNFTListData.countall
          recordCountCollection.value = assetsAPIMyNFTListData.countcollection
          recordCountForSale.value = assetsAPIMyNFTListData.countforsell
          recordCountInGame.value = assetsAPIMyNFTListData.countingame
          filterAPIPara.value = filterInner.join(',')

          const index = tagMyNFTIndexPara

          switch (index) {
            case 1:
              recordCountForSale.value = assetsAPIMyNFTListData.count
              break
            case 2:
              recordCountInGame.value = assetsAPIMyNFTListData.count
              break
            case 3:
              recordCountCollection.value = assetsAPIMyNFTListData.count
              break
            default:
              recordCountAll.value = assetsAPIMyNFTListData.count
          }
        } else {
          if (categoryIndexPara === 1) {
            const urlMyNFTListRentIn = `${API.assetsListTemptory + chainId}/${walletAddress.value}/2/${pageNowPara}/${pagePerPara}/`
            mynftRentInData = (await axios.get(urlMyNFTListRentIn)).data.data

            myNFTItemList.value[categoryIndexPara] = mynftRentInData.list
            recordCount.value[categoryIndexPara] = mynftRentInData.countrentin
            recordCountRentIn.value = mynftRentInData.countrentin
          } else if (categoryIndexPara === 2) {
            const urlMyNFTListSoldOut = `${API.assetsListTemptory + chainId}/${walletAddress.value}/1/${pageNowPara}/${pagePerPara}/`
            mynftSoldOutData = (await axios.get(urlMyNFTListSoldOut)).data.data

            myNFTItemList.value[categoryIndexPara] = mynftSoldOutData.list
            recordCount.value[categoryIndexPara] = mynftSoldOutData.countsoldout
            recordCountSoldOut.value = mynftSoldOutData.countsoldout
          } else {
            const urlMyNFTListCollateral = `${API.assetsListNFTCollateral + chainId}/${walletAddress.value}/${pageNowPara}/${pagePerPara}/`
            mynftCollateralData = (await axios.get(urlMyNFTListCollateral)).data.data

            myNFTItemList.value[categoryIndexPara] = mynftCollateralData.list
            recordCount.value[categoryIndexPara] = mynftCollateralData.count
            recordCountCollateral.value = mynftCollateralData.count
          }
        }
      }
      if (isMobile.value) {
        isLoadingMore.value = false
      }
    }

    const getDepartPageData = async (page: number) => {
      if (isMobile.value) {
        isLoadingMore.value = true
      }

      let filterMoreList = []

      if (filterAPIPara.value.length > 0) {
        filterMoreList.push(filterAPIPara.value)
      }

      if (filterSearchAPIPara.value[categoryIndexNumber.value].length > 0) {
        filterMoreList.push(filterSearchAPIPara.value[categoryIndexNumber.value])
      }

      filterMoreList.push(nftTagList.value[tagMyNFTIndex.value].value)

      const filterAPIP = filterMoreList.join(',')

      if (page === 1) {
        switch (categoryIndexNumber.value) {
          case 0:
            if (refPaginationMyNFT0.value) {
              refPaginationMyNFT0.value.setCurPage(1)
            }
            break;
          case 1:
            if (refPaginationMyNFT1.value) {
              refPaginationMyNFT1.value.setCurPage(1)
            }
            break;
          case 2:
            if (refPaginationMyNFT2.value) {
              refPaginationMyNFT2.value.setCurPage(1)
            }
            break;
          case 3:
            if (refPaginationMyNFT3.value) {
              refPaginationMyNFT3.value.setCurPage(1)
            }
            break;
          default:
            break;
        }
      }

      if (categoryIndexNumber.value === 0) {
        const urlMarketList = `${API.assetsListNFT + chainId}/${walletAddress.value}/${page - 1}/${pagePerCount.value}/?filter=${filterAPIP}`
        assetsAPIMyNFTListData = (await axios.get(urlMarketList)).data.data
        // if (pageCur !== page) {
        if (!isMobile.value) {
          myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTListData.list
        } else {
          if (page === 1) {
            myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTListData.list
          } else {
            myNFTItemList.value[categoryIndexNumber.value] = myNFTItemList.value[categoryIndexNumber.value].concat(assetsAPIMyNFTListData.list)
          }
        }

        recordCount.value[categoryIndexNumber.value] = assetsAPIMyNFTListData.count
        pageNow.value[categoryIndexNumber.value] = page

        recordCountAll.value = assetsAPIMyNFTListData.countall
        recordCountCollection.value = assetsAPIMyNFTListData.countcollection
        recordCountForSale.value = assetsAPIMyNFTListData.countforsell
        recordCountInGame.value = assetsAPIMyNFTListData.countingame

      } else {
        if (categoryIndexNumber.value === 1) {
          const urlMyNFTListRentIn = `${API.assetsListTemptory + chainId}/${walletAddress.value}/2/${page - 1}/${pagePerCount.value}/`
          mynftRentInData = (await axios.get(urlMyNFTListRentIn)).data.data

          if (!isMobile.value) {
            myNFTItemList.value[categoryIndexNumber.value] = mynftRentInData.list
          } else {
            if (page === 1) {
              myNFTItemList.value[categoryIndexNumber.value] = mynftRentInData.list
            } else {
              myNFTItemList.value[categoryIndexNumber.value] = myNFTItemList.value[categoryIndexNumber.value].concat(mynftRentInData.list)
            }
          }

          recordCount.value[categoryIndexNumber.value] = mynftRentInData.countrentin
          recordCountRentIn.value = mynftRentInData.countrentin
          pageNow.value[categoryIndexNumber.value] = page
        } else if (categoryIndexNumber.value === 2) {
          const urlMyNFTListSoldOut = `${API.assetsListTemptory + chainId}/${walletAddress.value}/1/${page - 1}/${pagePerCount.value}/`
          mynftSoldOutData = (await axios.get(urlMyNFTListSoldOut)).data.data

          if (!isMobile.value) {
            myNFTItemList.value[categoryIndexNumber.value] = mynftSoldOutData.list
          } else {
            if (page === 1) {
              myNFTItemList.value[categoryIndexNumber.value] = mynftSoldOutData.list
            } else {
              myNFTItemList.value[categoryIndexNumber.value] = myNFTItemList.value[categoryIndexNumber.value].concat(mynftSoldOutData.list)
            }
          }

          recordCount.value[categoryIndexNumber.value] = mynftSoldOutData.countsoldout
          recordCountSoldOut.value = mynftSoldOutData.countsoldout
          pageNow.value[categoryIndexNumber.value] = page
        } else if (categoryIndexNumber.value === 3) {
          const urlMyNFTListCollateral = `${API.assetsListNFTCollateral + chainId}/${walletAddress.value}/${page - 1}/${pagePerCount.value}/`
          mynftCollateralData = (await axios.get(urlMyNFTListCollateral)).data.data

          if (!isMobile.value) {
            myNFTItemList.value[categoryIndexNumber.value] = mynftCollateralData.list
          } else {
            if (page === 1) {
              myNFTItemList.value[categoryIndexNumber.value] = mynftCollateralData.list
            } else {
              myNFTItemList.value[categoryIndexNumber.value] = myNFTItemList.value[categoryIndexNumber.value].concat(mynftCollateralData.list)
            }
          }

          recordCount.value[categoryIndexNumber.value] = mynftCollateralData.count
          recordCountCollateral.value = mynftCollateralData.count
          pageNow.value[categoryIndexNumber.value] = page
        }
      }

      if (isMobile.value) {
        isLoadingMore.value = false
      }
      // }
    }

    const changePage = (page: number) => {
      // const sortCur = sortCheckedIndex.value
      // const sortData = filterSortList.value[sortCur]
      getPageData()
    }

    const showModal = (index: number, nftindex: number = -1) => {

      nftSelectIndex.value = nftindex
      modalShowIndex.value = index
      isShowModal.value = true

    }


    const showModalTip = (index: number, tip: string) => {
      modalShowIndex.value = index
      modalInfotip.value = tip
      isShowModal.value = true
    }

    const closeModal = () => {
      LogUtils.debug('close...')
      isShowModal.value = false
      nftSelectIndex.value = -1
      modalInfotip.value = ''
    }

    // const updateApprove = (approveInfo: any) => {
    //   for (let i = 0; i < goodsPayTokenList.value.length; i++) {
    //     if (approveInfo['index'] !== i && approveInfo['address']) {
    //       const ele = goodsPayTokenList.value[i]
    //       ele.approve = approveInfo['approve']
    //     }

    //   }
    // }

    // const reloadList = async (index: number) => {
    //   const { walletAddress } = toRefs(props)

    //   if (walletAddress.value.length > 0) {
    //     const provider = await getWalletProvider()
    //     chainId = await TOOL.getChainId()
    //     if (provider) {
    //       getPageData()
    //     }
    //   }
    // }

    const mobileScrollTop = ref(0)
    const localStorageMYNFTConfig = "mynft_data_select_setting"

    interface localStorageMYNFTConfigObject {
      searchKeywords: string,
      pageNow: number,
      checkListCha: number[],
      countListCha: number,
      checkListRarity: number[],
      countListRarity: number,
      tagMyNFTIndex: number
      // countListPrice: number,
    }

    interface localStorageMYNFTConfig {
      cur: number,
      data: localStorageMYNFTConfigObject[],
      scrollTop: number
    }

    const loadMYNFTStorageSetting = () => {
      const json = localStorage.getItem(localStorageMYNFTConfig)
      if (json && json.length > 0) {
        const jo = <localStorageMYNFTConfig>JSON.parse(json)
        const cur = jo.cur
        const data = jo.data
        const sTop = jo.scrollTop

        if (data && data.length > 0) {
          if (sTop > 0) {
            mobileScrollTop.value = sTop
          }

          categoryIndexNumber.value = cur

          for (let i = 0; i < 4; i++) {
            const ele = data[i]
            if (ele) {
              if (i === 0) {
                tagMyNFTIndex.value = ele.tagMyNFTIndex
                if (filterCharacterList.value.length === ele.countListCha) {
                  checkListCha.value = ele.checkListCha
                }
                if (filterRarityList.value.length === ele.countListRarity) {
                  checkListRarity.value = ele.checkListRarity
                }
                if (ele.pageNow) {
                  pageNow.value[i] = ele.pageNow
                }
              } else {
                if (ele.pageNow) {
                  pageNow.value[i] = ele.pageNow
                }
              }
              if (cur === i) {
                searchKewords.value = ele.searchKeywords
              }
            }
          }

          //gen search para
          if (searchKewords.value.length > 0) {
            const encode = encodeURI(searchKewords.value[0])
            filterSearchAPIPara.value[cur] = 'sn' + encode
            isShowPCSearchRight.value = true
          }
        }

        // console.log(pageNow.value)
        localStorage.removeItem(localStorageMYNFTConfig)
      }
    }

    const saveMYNFTStorageSetting = () => {
      const cur = categoryIndexNumber.value
      var sTop = document.documentElement.scrollTop || document.body.scrollTop

      // console.log(sTop);
      const ele0 = {
        tagMyNFTIndex: tagMyNFTIndex.value,
        checkListCha: checkListCha.value,
        countListCha: filterCharacterList.value.length,
        checkListRarity: checkListRarity.value,
        countListRarity: filterRarityList.value.length,
        pageNow: pageNow.value[0],
        searchKeywords: ''
      }
      if (cur === 0) {
        ele0.searchKeywords = searchKewords.value
      }

      const ele1 = {
        pageNow: pageNow.value[1],
        searchKeywords: ''
      }
      if (cur === 1) {
        ele1.searchKeywords = searchKewords.value
      }

      const ele2 = {
        pageNow: pageNow.value[1],
        searchKeywords: ''
      }
      if (cur === 2) {
        ele2.searchKeywords = searchKewords.value
      }

      const ele3 = {
        pageNow: pageNow.value[1],
        searchKeywords: ''
      }
      if (cur === 3) {
        ele3.searchKeywords = searchKewords.value
      }

      const data = [
        ele0,
        ele1,
        ele2,
        ele3
      ]

      const config = {
        cur: cur,
        data: data,
        scrollTop: sTop
      }

      localStorage.setItem(localStorageMYNFTConfig, JSON.stringify(config))
    }

    const loadPage0 = async () => {
      const catCur = 0
      const tagMyNFTIndexPara = tagMyNFTIndex.value
      if (!isMobile.value) {
        await getPageData(catCur, tagMyNFTIndexPara, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, tagMyNFTIndexPara, 1, initPageCount)
      }
    }

    const loadPage1 = async () => {
      const catCur = 1
      if (!isMobile.value) {
        await getPageData(catCur, -1, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, -1, 1, initPageCount)
      }
    }

    const loadPage2 = async () => {
      const catCur = 2
      if (!isMobile.value) {
        await getPageData(catCur, -1, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, -1, 1, initPageCount)
      }
    }

    const loadPage3 = async () => {
      const catCur = 3
      if (!isMobile.value) {
        await getPageData(catCur, -1, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, -1, 1, initPageCount)
      }
    }

    const loadData = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
      const projectConfig = TOOL.getConfigProject()
      const chainSymbolLoad = await TOOL.getChainSymbol()
      chainSymbol.value = chainSymbolLoad
      const chainRPCConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.rpcurls)
      const chainABIConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.abis)
      const chainTokensConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.tokens)

      pageChainConfig.projectConfig = projectConfig
      pageChainConfig.chainSymbol = chainSymbol
      pageChainConfig.chainRPCConfig = chainRPCConfig
      pageChainConfig.chainABIConfig = chainABIConfig
      pageChainConfig.chainTokensConfig = chainTokensConfig
      pageChainConfig.multicallConfig = {
        rpcUrl: chainRPCConfig,
        multicallAddress: chainABIConfig.MULTICALL
      }

      if (walletAddress.value.length > 0) {

        chainId = await TOOL.getChainId()
        const urlMyNFTInfo = `${API.assetsInfoMyNFT}${chainId}/`
        mynftAPIInfo = (await axios.get(urlMyNFTInfo)).data.data

        const chaList = mynftAPIInfo!.filterchar
        if (chaList.length > 0) {
          const filterCha = []
          for (const cha of chaList) {
            for (const key in cha) {
              if (cha.hasOwnProperty(key)) {
                filterCha.push({
                  name: key,
                  value: cha[key]
                })
              }
            }
          }
          filterCharacterList.value = filterCha
        }

        const rarityList = mynftAPIInfo!.filterrarity
        if (rarityList.length > 0) {
          const filterRarity = []
          for (const rarity of rarityList) {
            for (const key in rarity) {
              if (rarity.hasOwnProperty(key)) {
                filterRarity.push({
                  name: key,
                  value: rarity[key]
                })
              }
            }
          }
          filterRarityList.value = filterRarity
        }

        // const provider = await getWalletProvider()
        chainId = await TOOL.getChainId()
        // if (provider) {
        // const debug = '0x97f364fd7d48b3cad3cc69411961614b8b11b230'
        const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`
        assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
        gameAccount.value = assetsAPIInfo!.wallet.nickname


        loadMYNFTStorageSetting()

        if (categoryIndexNumber.value === 0) {
          await loadPage0()
        } else if (categoryIndexNumber.value === 1) {
          await loadPage1()
        } else if (categoryIndexNumber.value === 2) {
          await loadPage2()
        } else if (categoryIndexNumber.value === 3) {
          await loadPage3()
        }



        if (updateCurrentTimeTask === null) {
          const jsonRPCProvider = new ethers.providers.JsonRpcProvider(chainRPCConfig)
          const currentBlock = (await jsonRPCProvider.getBlock('latest'))

          currentTime.value = currentBlock.timestamp
          updateCurrentTimeTask = setInterval(() => {
            currentTime.value = currentTime.value + 1
          }, 1000)
        }

        isLoading.value = false
        if (isLoadingInit.value) {
          isLoadingInit.value = false
          ctx.emit('update', false)
        }

        nextTick(() => {
          // Loading should be closed asynchronously
          loadingInstance.close()

          if (!isMobile.value) {
            if (categoryIndexNumber.value === 0) {
              if (refPaginationMyNFT0.value) {
                refPaginationMyNFT0.value.setCurPage(pageNow.value[0])
              }
            } else if (categoryIndexNumber.value === 1) {
              if (refPaginationMyNFT1.value) {
                refPaginationMyNFT1.value.setCurPage(pageNow.value[1])
              }
            } else if (categoryIndexNumber.value === 2) {
              if (refPaginationMyNFT2.value) {
                refPaginationMyNFT2.value.setCurPage(pageNow.value[2])
              }
            } else if (categoryIndexNumber.value === 3) {
              if (refPaginationMyNFT3.value) {
                refPaginationMyNFT3.value.setCurPage(pageNow.value[3])
              }
            }
          }

          if (isMobile.value && mobileScrollTop.value > 0) {
            window.scrollTo({
              top: mobileScrollTop.value,
              behavior: "smooth"
            })
          }
          isLoading.value = false
        })

        if (categoryIndexNumber.value === 0) {
          loadPage1()
          loadPage2()
          loadPage3()
        } else if (categoryIndexNumber.value === 1) {
          loadPage0()
          loadPage2()
          loadPage3()
        } else if (categoryIndexNumber.value === 2) {
          loadPage0()
          loadPage1()
          loadPage3()
        } else if (categoryIndexNumber.value === 3) {
          loadPage0()
          loadPage1()
          loadPage2()
        }
      } else {
        nextTick(() => {
          // Loading should be closed asynchronously
          loadingInstance.close()
          isLoading.value = false
        })
      }
    }

    return {
      isLoading,
      isMobile,
      nftSelectIndex,
      pageNow,
      pageCount,
      recordCount,
      pagePerCount,
      nftOptType,
      changePage,
      getLowerCase,
      chainSymbol,
      isShowModal,
      showModal,
      closeModal,
      modalShowIndex,
      modalInfotip,
      showModalTip,
      isMarketPlaceOpen,
      walletAddress,
      isShowPCSelectRight,
      isShowPCSearchRight,
      searchKewords,
      searchInput,
      conditionFilter,
      sortCheckedIndex,
      orderAPIPara,
      filterAPIPara,
      filterSortList,
      filterTypeList,
      filterCharacterList,
      filterRarityList,
      filterAttrList,
      myNFTItemList,
      switchSort,
      isLoadingMore,
      getDepartPageData,
      tagMyNFTIndex,
      isShowPCSelectCha,
      isShowPCSelectRarity,
      isShowPCSelectAttr,
      isShowPCSelectState,
      chaCheckedIndex,
      rarityCheckedIndex,
      attrCheckedIndex,
      checkListCha,
      checkListRarity,
      changeCheckboxGroup,
      recordCountAll,
      recordCountInGame,
      recordCountCollection,
      recordCountForSale,
      recordCountRentIn,
      recordCountSoldOut,
      changeRadioGroup,
      refPaginationMyNFT0,
      refPaginationMyNFT1,
      refPaginationMyNFT2,
      closeSearch,
      filterSearchAPIPara,
      removeCheckList,
      nftStateMap,
      categoryIndex,
      categoryIndexNumber,
      categorySwitch,
      getRentInCountDown,
      go2Detail,
      nftTagList,
      switchTag,
      currentTime,
      recordCountCollateral,
      getRedeemCountDown
    }
  }
})
</script>
<style lang="stylus" scoped></style>
