<template>
    <div class="supply-chart-wrap">
        <div class="supply-chart" ref="suplyCharts">
            <v-chart :option="getLineOption()" autoresize></v-chart>
        </div>
        <div class="bar-wrap">
            <div class="bar">
                <div class="bar-item" :class="activeType == 'total' ? 'active' : ''" @click="changeActive('total')">
                    <div class="icon"></div>
                    <div class="bar-text">{{ $t('HomeNew.total') }}</div>
                </div>
                <div class="bar-item" :class="activeType == 'month' ? 'active' : ''" @click="changeActive('month')">
                    <div class="icon"></div>
                    <div class="bar-text">{{ $t('HomeNew.monthly') }}</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import * as echarts from 'echarts'
import { ref, watch, onMounted } from 'vue'
import { useElementVisibility } from '@vueuse/core'
import tool from '@/utils/tool'
import { getProductionAndCost, MCProductionAndCostModel } from '@/api/mcInfo'
import { MC_CONSUMPTION_AND_OUT_PUT_OPTION, MC_CONSUMPTION_AND_OUT_PUT_SERIES_DETAIL, MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL, MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL_MOBILE, MC_CONSUMPTION_AND_OUT_PUT_OPTION_MOBILE  } from '../config/chartOption'
import { cloneDeep } from 'lodash'

type activeType = 'total' | 'month'

const chartData = ref<MCProductionAndCostModel[]>([])
const inGame = ref<number[]>([])

const withdrawalFee = ref<number[]>([])
const transactionFee = ref<number[]>([])
const suplyCharts = ref(null)
const isShow = useElementVisibility(suplyCharts)

let cacheData: MCProductionAndCostModel[] | null = null
// const isMobile = !tool.getDeviceScreenIsPC()

const activeType = ref<activeType>('total')

const changeActive = (type: activeType) => {
    cacheData = null
    activeType.value = type
}

const getData = async () => {
    const res = await getProductionAndCost(activeType.value)
    inGame.value = [Number(res[0].supply), Number(res[0].cost)]
    transactionFee.value = [Number(res[1].supply), Number(res[1].cost)]
    withdrawalFee.value = [Number(res[2].supply), Number(res[2].cost)]
    cacheData = res


}

watch([isShow, activeType], async (value) => {
    if (value[0]) {
        if (cacheData) {
            chartData.value = cacheData
            return
        }
        await getData()

    }
})
onMounted(async () => {
    await getData()
})

const getLineOption = () => {
    const isMobile = !tool.getDeviceScreenIsPC()
    let option: any;
    // 数据格式
    // let data = [
    //     { name: 'In-game Consumption', supply: 0, cost: 11 },
    //     { name: 'Early Withdrawal Fee', supply: 0, cost: 22 },
    //     { name: 'Market Transaction Fee', supply: 88, cost: 44 },
    // ]
    // bar color
    // const colors = ['#46885F', '#69BAA8', '']
    let seriesLastLabel: any

    if(isMobile) {
        option = cloneDeep(MC_CONSUMPTION_AND_OUT_PUT_OPTION_MOBILE)
        seriesLastLabel = cloneDeep(MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL_MOBILE)
    }else {
        option = cloneDeep(MC_CONSUMPTION_AND_OUT_PUT_OPTION)
        seriesLastLabel = cloneDeep(MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL)
    }
    
    let series: any = [
        {
            "name":"In-game Consumption",
            "type":"bar",
            "data": inGame.value,
            "barWidth":"20px",
            "stack":"bar",
            "itemStyle":{
                "normal":{
                    "color":"#46885F",
                    "barBorderRadius":[0,0,0,0]
                }
            }
        },
        {
            "name":"Market Transaction Fee",
            "type":"bar",
            "data": transactionFee.value,
            "barWidth":"20px",
            "stack":"bar",
            "itemStyle":{
                "normal":{
                    "color":"#69BAA8",
                    "barBorderRadius":[0,0,0,0]
                }
            }
        },
        {
            "name":"Early Withdrawal Fee",

            "type":"bar",
            "data": withdrawalFee.value,
            "barWidth":"20px",
            "stack":"bar",
            "itemStyle":{
                "normal":{
                    "color":"",
                    "barBorderRadius":[0,0,0,0]
                }
            }
        }
    ]
    
    // for(let i=0; i<data.length; i++) {
    //     const item = data[i]
    //     const seriesItem = cloneDeep(MC_CONSUMPTION_AND_OUT_PUT_SERIES_DETAIL)
    //     seriesItem.itemStyle.normal.color = colors[i]
    //     seriesItem.name = item.name
    //     seriesItem.data = [inGame.value.supply, inGame.value.cost]
    //     series.push(seriesItem)
    // }
    const seriesLast = series[series.length-1]
    seriesLast.label = seriesLastLabel
    const fun = function(params: any) {
        var series = option["series"]
        var datavalue = 0;
        for (var i = 0; i < series.length; i++) {
            // @ts-ignore
            datavalue += series[i].data[params.dataIndex]
        }
        
        if(isMobile) {
            return [
                '    {d|●}',
                ' {a|'+datavalue.toFixed(2)+'}     \n',
                '    {b|}'
            ].join('')
        } 
        return [
            '{b|}',
            '{d|●  '+datavalue.toFixed(2)+'}',

        ].join('')

        
    }
    seriesLast["label"]['normal']["formatter"] = fun;
    // 最后一根柱子颜色
    seriesLast['itemStyle']['normal']['color'] = function(params: any) {
        if(params.dataIndex === 1) {
            return '#A8DAFF'
        }
        return new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            offset: 0,
            color: '#59AC79' // 0% 处的颜色
        }, {
            offset: 1,
            color: '#87D0FF' // 100% 处的颜色
        }], false)
    }
    seriesLast['itemStyle']['normal']['barBorderRadius'] = isMobile ? [30, 30, 0, 0] : [0, 30, 30, 0]
                    
    option.series = series 
    return option;
}

</script>

<style scoped lang="stylus">
    .supply-chart-wrap{
        margin-top: 60px;
        position: relative;
        .supply-chart{
            width: 100%;
            height: 236px;
        }
        .bar-wrap {
            position: absolute;
            right: 90px;
            
            .bar{
                display: flex;
                .bar-item {
                    margin: 0 10px;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    
                    .icon{
                        background-color: #4F7A64;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 10px;
                        transition: all 0.3s;
                    }
                    .bar-text{
                        font-family: Avenir-Black;
                        font-size: 12px;
                        color: #4F7A64;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 25px;
                        transition: all 0.3s;
                    }
                    &.active>.bar-text{
                            color: #6FBFBA;
                    }
                    &.active>.icon{
                        // background-image: linear-gradient(270deg, #86D2FF 0%, #59AD79 100%);
                        background-color: #59AD79;
                    }
                    &:hover {
                        .icon{
                            background-color: #59AD79;
                        }
                        .bar-text{
                            color: #6FBFBA;
                        }
                    }
                    

                }

            }
            
        }
    }
</style>;

<style scoped lang="stylus">

@media screen and (max-width: 1140px) {
    .supply-chart-wrap{
        margin-top: 0;
        .supply-chart{
            width: 100%;
            height: 300rem;
        }
        .bar-wrap{
            right: 20rem;
            margin-top: -12rem;
            .bar{
                .bar-item{
                    margin: 0 10rem;
                    padding: 5rem 10rem;
                    .icon{
                        width: 10rem;
                        height: 10rem;
                        margin-right: 10rem;
                    }
                    .bar-text{
                        font-size: 12rem;
                        line-height: 25rem;
                    }
                }
            }
        }
    }
}

</style>