<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content d-flex flex-column align-items-center">
      <span class="title">{{ $t('Login.sign-in-to-tap-fantasy') }}</span>
      <div class="input-item first d-flex flex-column">
        <span class="input-title">{{ $t('Login.username') }} / {{ $t('Login.email') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-enter-your-username-or-email')" ref="username"
            v-model.trim="loginForm.username" style="flex: 1;" />
        </div>
      </div>
      <div class="input-item d-flex flex-column">
        <span class="input-title">{{ $t('Login.password') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-enter-your-password')" ref="password" :type="passwordType"
            v-model.trim="loginForm.password" style="flex: 1;" />
        </div>
      </div>
      <!-- <div v-if="langCur.global.locale === 'en' || langCur.global.locale === 'zh-TW'"
        class="link-block d-flex align-items-center justify-content-start">
        <el-checkbox size="small" v-model="isAgreeTerms" @change="changeAgree">
          <span>{{ $t('Login.i-have-read-and-agree-to-the') }}</span> <a class="terms"
            :target="isMobile ? '_self' : '_blank'" href="/#/terms">{{ $t('Login.user-agreement') }}</a>
        </el-checkbox>
      </div> -->
      <div class="link-block d-flex align-items-center justify-content-between">
        <a href="/#/register">{{ $t('Login.create-new-account') }}</a>
        <a href="/#/forgetpass">{{ $t('Login.forget-password') }}</a>
      </div>
      <span class="error-block login">
        {{ errorMsg }}
      </span>
      <button class="button signin" @click="handleLogin">{{ $t('Login.button-sign-in') }}</button>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import md5 from 'js-md5'
import { login } from '@/api/account'
import { tokenKey } from '@/settings'
import { _setCookie, _getCookie } from '@/utils/storage'
import { getInfo } from '@/api/account'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
// import { useI18n } from 'vue-i18n'
import LogUtils from '@/utils/LogUtils'
import { ElCheckbox } from "element-plus";
import TOOL from "../utils/tool";
import i18n from "@/i18n";

// const { t } = useI18n()

export default {
  name: 'Login',
  components: {
    ElCheckbox
  },
  data() {
    return {
      styleObj: {
        width: '100%',
        'font-size': '2vh',
        'min-height': '5vh',
        // border: '1px solid white',
        color: 'white',
        // 'text-align': 'center',
        'border-radius': '0rem',
        border: 'none',
        'border-bottom': '1px solid white',
      },
      actionName: 'first',
      loginForm: {
        username: '',
        password: '',
        code: '',
        loginType: 2
      },
      actionList: {
        first: 1,
        second: 2
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      redirect: undefined,
      router: useRouter(),
      otherQuery: {},
      errorMsg: '',
      isShowUI: false,
      isAgreeTerms: false,
      isMobile: false,
      langCur: i18n
    }
  },
  mounted() {
    const that = this
    this.isAgreeTerms = localStorage.getItem('IS_AGREE_TERMS') !== null && localStorage.getItem('IS_AGREE_TERMS').length > 0 && localStorage.getItem('IS_AGREE_TERMS') === 'true' ? true : false
    this.isMobile = !TOOL.getDeviceScreenIsPC();
    this.$bus.$on("RESIZE_WINDOW", (para) => {
      that.isMobile = para.isMobile;
    });
    this.checkLogin()
  },
  // created() {
  //   this.checkLogin()
  // },
  methods: {
    changeAgree(e) {
      // LogUtils.debug(e)
      this.isAgreeTerms = e
      localStorage.setItem('IS_AGREE_TERMS', e.toString())
    },
    checkLogin() {
      const tokenValue = _getCookie(tokenKey, '')
      LogUtils.debug(tokenValue)
      // _setCookie(tokenKey, '1234')
      if (tokenValue) {
        getInfo(null)
          .then((response) => {
            if (response.code === 0) {
              const { data } = response
              this.router.push({ name: 'Play' })
            } else {
              this.isShowUI = true
              if (this.isShowUI) {
                this.$nextTick(function () {
                  if (this.loginForm.username === '') {
                    this.$refs.username.focus()
                  } else if (this.loginForm.password === '') {
                    this.$refs.password.focus()
                  }
                })

              }
            }

          })
          .catch((error) => {
            console.error(error)
            if (!this.isShowUI) {
              this.isShowUI = true
            }
            return false
          })
      } else {
        this.isShowUI = true
        if (this.isShowUI) {
          this.$nextTick(function () {
            if (this.loginForm.username === '') {
              this.$refs.username.focus()
            } else if (this.loginForm.password === '') {
              this.$refs.password.focus()
            }
          })

        }
      }
    },
    handleClick(name) {
      this.loginForm.loginType = name
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.loading = true
      this.errorMsg = ''
      if (this.validate()) {
        this.loginForm.password = this.getmd5(this.loginForm.password)
        login(this.loginForm)
          .then((response) => {
            const { token } = response.data
            if (token) {
              // if (BLOCKCHAIN_CONFIG.isLocal) {
              _setCookie(tokenKey, token, {
                expires: new Date(new Date().getTime() + 86400000),
              })
              // } else {
              //   _setCookie(tokenKey, token, {
              //     expires: new Date(new Date().getTime() + 86400000),
              //     domain: 'tapfantasy.io'
              //   })
              // }

              this.router.push({ name: 'Play' })
              this.loading = false
            }
          })
          .catch((error) => {
            this.errorMsg = error
            this.loginForm.password = ''
            this.loading = false
            return false
          })
      }
    },
    validateUsername(value) {
      if (!value) {
        return this.$t('Login.error-please-enter-your-account')
      }
      return ''
    },
    validateCode(value) {
      if (!value) {
        return this.$t('Login.error-validatecode-is-empty')
      }
      return ''
    },
    validatePassword(value) {
      if (!value) {
        return this.$t('Login.error-password-is-empty')
      }
      if (value.length < 6) {
        return this.$t('Login.error-password-should-use-8-or-more-characters-with-a-mix-of-letters-numbers-and-symbols')
      }
      return ''
    },
    validate() {
      if (this.validateUsername(this.loginForm.username)) {
        this.errorMsg = this.validateUsername(this.loginForm.username)
        return false
      }
      if (this.loginForm.loginType === 1) {
        if (this.validateCode(this.loginForm.code)) {
          this.errorMsg = this.validateCode(this.loginForm.code)
          return false
        }
      }
      if (this.loginForm.loginType === 2) {
        if (this.validatePassword(this.loginForm.password)) {
          this.errorMsg = this.validatePassword(this.loginForm.password)
          return false
        }
      }
      // if (!this.isAgreeTerms && this.langCur.global.locale.value !== 'ja') {
      //   this.errorMsg = this.$t('Login.please-tick-i-have-read-and-agree-to-the-user-agreement')
      //   return false
      // }
      return true
    },
    getmd5(str) {
      return md5(str)
    }
  }
}
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
