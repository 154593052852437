<template>
  <div class="page-mynft" style="position: relative;height: auto;">
    <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
      <div v-if="modalShowIndex === 2"
        class="mask-nft mobile-filter d-flex flex-column justify-content-end align-items-center">
        <div class="dialog-content d-flex">
          <div class="gap" @click.stop="closeModal()"></div>
          <div class="mobile-menu">
            <div class="filter-title-block d-flex justify-content-between align-items-center">
              <span class="title">{{ $t('Filter.title') }}</span>
              <!-- <span @click="resetFilter" class="reset">Reset</span> -->
            </div>
            <div class="filter-type-block d-flex flex-column justify-content-center">
              <span class="title">{{ $t('Filter.title-character') }}</span>
              <el-checkbox-group v-model="checkListCha" @change="changeCheckboxGroup"
                class="option-list d-flex align-items-center">
                <template v-for="(cha, chaIndex) of filterCharacterList" :key="'cha-' + chaIndex">
                  <el-checkbox :label="chaIndex" size="small">{{ $t(`Character.${cha.name}`) }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
            <div class="filter-type-block d-flex flex-column justify-content-center">
              <span class="title">{{ $t('Filter.title-rarity') }}</span>
              <el-checkbox-group class="option-list rarity d-flex align-items-center" v-model="checkListRarity"
                @change="changeCheckboxGroup">
                <template v-for="(rarity, rarityIndex) of filterRarityList" :key="'rarity-' + rarityIndex">
                  <el-checkbox size="small" :label="rarityIndex">
                    {{
                        rarity.name
                    }}
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="mynft d-flex justify-content-center">
      <div v-if="!isMobile" class="left">
        <u-c-left></u-c-left>
      </div>
      <div class="right d-flex flex-column">
        <template v-if="!isLoading">
          <template v-if="walletAddress.length > 0">
            <div class="content" :class="myNFTItemList[categoryIndexNumber].length === 0 ? 'empty' : ''">

              <!-- <el-menu :default-active="categoryIndex" mode="horizontal" @select="categorySwitch">
              <el-menu-item index="0">My NFT({{ recordCount[0] }})</el-menu-item>
              <el-menu-item index="1">Rent In({{ recordCountRentIn }})</el-menu-item>
              <el-menu-item index="2">Sold Out({{ recordCountSoldOut }})</el-menu-item>
            </el-menu> -->

              <div v-if="!isMobile && categoryIndexNumber === 0"
                class="filter-block d-flex align-items-start justify-content-between">
                <div class="d-flex align-items-center"
                  @click.self="isShowPCSelectAttr = false; isShowPCSelectRarity = false; isShowPCSelectState = false; isShowPCSearchRight = false">
                  <!-- <span class="filter-title">My NFT</span> -->
                  <div class="select" style="width: 120PX;">
                    <div v-if="!isShowPCSelectState" @click.stop="isShowPCSelectState = !isShowPCSelectState"
                      class="select-input d-flex align-items-center">
                      <span class="title">
                        <template v-if="tagMyNFTIndex >= 0">
                          {{ $t(`MyNFT.filter-taglist.${nftTagList[tagMyNFTIndex].key}`)
                          }}</template>
                        <template v-else>{{ $t('MyNFT.filter-state') }}</template>
                      </span>
                    </div>
                    <div v-else class="list d-flex flex-column" :class="isShowPCSelectState ? 'expand' : 'collapsed'">
                      <div :class="tagMyNFTIndex === cIndex ? 'active' : ''" class="select-item"
                        v-for="(c, cIndex) of nftTagList" :key="'select-state-' + cIndex" @click="switchTag(cIndex)">
                        {{
                            $t(`MyNFT.filter-taglist.${c.key}`)
                        }}
                      </div>
                    </div>
                    <img @click.stop="isShowPCSelectState = !isShowPCSelectState" class="arrow"
                      :class="isShowPCSelectState ? 'rotate' : ''" src="/static/images/arrow_select.png" />
                  </div>
                </div>
              </div>
              <div v-else-if="isMobile" class="top d-flex align-items-center justify-content-end">
                <!-- <span class="filter-title"><button v-if="categoryIndexNumber === 0" class="filter-button"
                    @click="showModal(2)">{{ $t('Filter.title') }}</button></span> -->
                <div class="d-flex align-items-center justify-content-end">
                  <!-- <div :class="isShowPCSearchRight ? 'expand' : 'collapsed'"
                    class="search d-flex align-items-center justify-content-center">
                    <input v-if="isShowPCSearchRight" @keyup.enter="searchInput()" v-model="searchKewords"
                      :placeholder="$t('Search.search-nft-id-or-name')" onfocus="this.placeholder=''"
                      onblur="this.placeholder=$t('Search.search-nft-id-or-name')" />
                    <img v-if="!isShowPCSearchRight" @click.stop="isShowPCSearchRight = true"
                      src="/static/images/icon_search.png" />
                    <svg class="svg" @click.stop="closeSearch()" v-if="isShowPCSearchRight" viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill="#FFFFFF"
                        d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z" />
                    </svg>
                  </div> -->
                  <div class="select" style="width: 110PX;">
                    <div v-if="!isShowPCSelectState" @click.stop="isShowPCSelectState = !isShowPCSelectState"
                      class="select-input d-flex align-items-center">
                      <span class="title">
                        <template v-if="tagMyNFTIndex >= 0">
                          {{ $t(`MyNFT.filter-taglist.${nftTagList[tagMyNFTIndex].key}`)
                          }}</template>
                        <template v-else>{{ $t('MyNFT.filter-state') }}</template>
                      </span>
                    </div>
                    <div v-else class="list d-flex flex-column" :class="isShowPCSelectState ? 'expand' : 'collapsed'">
                      <div :class="tagMyNFTIndex === cIndex ? 'active' : ''" class="select-item"
                        v-for="(c, cIndex) of nftTagList" :key="'select-state-' + cIndex" @click="switchTag(cIndex)">
                        {{
                            $t(`MyNFT.filter-taglist.${c.key}`)
                        }}
                      </div>
                    </div>
                    <img @click.stop="isShowPCSelectState = !isShowPCSelectState" class="arrow"
                      :class="isShowPCSelectState ? 'rotate' : ''" src="/static/images/arrow_select.png" />
                  </div>
                </div>
              </div>

              <div v-if="myNFTItemList[categoryIndexNumber].length > 0" class="nft-item-list head">
                <div @click="go2Detail(itemIndex)" :class="nft['belongto'] === 'renttimeout' ? 'disabled' : ''"
                  class="d-flex mynft-item head flex-column align-items-center"
                  v-for="(nft, itemIndex) of myNFTItemList[categoryIndexNumber]" :key="'index_' + itemIndex">
                  <!-- <div > -->
                  <span class="status" v-if="categoryIndexNumber <= 1"
                    :class="[nft['belongto'] !== 'wallet' ? nft['belongto'] : '', categoryIndexNumber === 1 ? 'alignend' : '']">
                    {{ $t(`NFTState.${nft['belongto']}`) }}
                  </span>
                  <div class="nft-item head d-flex flex-column align-items-center justify-content-center">
                    <img :src="nft['preview']" class="nft-item-image" />
                    <div class="nft-title">{{ nft.name }}: #{{ nft.tokenid }}</div>
                    <!-- <div class="nft-item-intro d-flex flex-column align-items-start">
                      <div class="nft-item-intro-title">{{ $t(`CardList.${nft['typeid']}.name`) }}</div>
                      <div class="nft-item-intro-title">#{{ nft['tokenid'] }}</div>
                    </div> -->
                  </div>
                  <div class="button-block">

                  </div>
                </div>
              </div>
              <el-empty v-else :description="$t('Common.no-items')" />
            </div>
            <template v-if="!isMobile">
              <div v-if="recordCount[categoryIndexNumber] / pagePerCount > 1"
                class="pageinfo d-flex align-items-center justify-content-end">
                <div class="nft-filter-text">{{ recordCount[categoryIndexNumber] }} {{ $t('MyNFT.items') }}</div>
                <template v-if="categoryIndexNumber === 0">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
                <!-- <template v-else-if="categoryIndexNumber === 1">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template>
                <template v-else-if="categoryIndexNumber === 2">
                  <pagination :ref="'refPaginationMyNFT' + categoryIndexNumber" @change-page="getDepartPageData"
                    :pagesize="pagePerCount" :total="recordCount[categoryIndexNumber]"
                    :page="pageNow[categoryIndexNumber]" />
                </template> -->
              </div>

            </template>
            <template v-else>
              <div v-if="myNFTItemList[categoryIndexNumber].length < recordCount[categoryIndexNumber] && !isLoading"
                class="d-flex justify-content-center loadmore">
                <div v-if="!isLoadingMore" @click="getDepartPageData(pageNow[categoryIndexNumber] + 1)">{{
                    $t('Common.load-more-data')
                }}
                </div>
                <div v-else class="d-flex justify-content-center align-items-center">
                  <span>{{ $t('Common.loading-more') }}</span>
                  <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffffff" style="width: 16px; height: 16px;margin-left: 6PX;">
                    <path
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </template>
          </template>
          <div v-else class="content">
            <el-empty :description="$t('Common.please-connect-your-wallet')" />
          </div>

        </template>
      </div>
    </div>
  </div>

</template>
<script lang="ts">
import { ethers } from 'ethers'
import { aggregate as aggregateMyNFT } from '@makerdao/multicall'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  getCurrentInstance,
  toRefs,
  watch,
  inject,
  nextTick
} from 'vue'
import UCLeft from '@/components/UCLeft.vue'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import Pagination from '../components/Pagination.vue'
import { ElMessageBox, ElDialog, ElInputNumber, ElButton, ElLoading } from 'element-plus'
import TOOL from '../utils/tool'
import * as API from '../constants/api'
import axios from '../utils/axios'
import { getWalletProvider } from '@/utils/provider'
import TapDesktop from '../abis/TapDesktop.json'
import ERC721 from '../abis/ERC721.json'
import ERC20 from '../abis/ERC20.json'
import BLOCKCHAIN_CONFIG from '../constants/blockchain_config'
import { useRouter } from 'vue-router'
import LogUtils from '@/utils/LogUtils'

export default defineComponent({
  name: 'MyNFTHead',
  components: {
    Pagination,
    ElMessageBox,
    ElDialog,
    ElInputNumber,
    ElButton,
    ElLoading,
    UCLeft
  },
  props: {
    walletAddress: {
      type: String,
      default: ''
    },
    isReady: {
      type: Boolean,
      default: false
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    watch(
      () => props.walletAddress,
      (newWalletAddr, oldWalletAddr) => {
        // LogUtils.debug(newWalletAddr)
        // LogUtils.debug(oldWalletAddr)
        if (!isLoadingInit.value) {
          if (newWalletAddr !== oldWalletAddr) {
            LogUtils.debug('load from change wallet...')
            loadData()
          }
        }
      },
    )

    watch(
      () => props.isRefresh,
      (newReady, oldReady) => {
        LogUtils.debug(newReady)
        LogUtils.debug(oldReady)
        if (newReady && newReady !== oldReady) {
          if (props.walletAddress.length === 0) {
            buttonDisabled.value = true
          } else {
            buttonDisabled.value = false
          }
          LogUtils.debug('load from ready...')
          loadData()
        }
      }
    )

    const createNotification = <CreateNotification>inject('create-notification')
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const router = useRouter()
    const BigNumber =
      getCurrentInstance()?.appContext.config.globalProperties.$BigNumber
    const refPaginationMyNFT0: Ref<any> = ref(null)
    const refPaginationMyNFT1: Ref<any> = ref(null)
    const refPaginationMyNFT2: Ref<any> = ref(null)
    const pageChainConfig: any = {}
    const isLoading = ref(true)
    const buttonDisabled = ref(false)
    const isLoadingInit = ref(true)
    const isMobile = ref(false)
    const isShowModal = ref(false)
    const isMarketPlaceOpen: Ref<boolean> = ref(BLOCKCHAIN_CONFIG.isMarketPlaceOpen)
    const modalInfotip = ref('')
    const modalShowIndex = ref(-1)
    const nftSelectIndex = ref(-1)
    const myNFTItemList: Ref<any[]> = ref([[], [], []])
    const nftOptType = ref(0)
    const tagMyNFTIndex = ref(0)
    const categoryIndex = ref('0')
    const categoryIndexNumber = ref(0)
    const isShowPCSelectRight = ref(false)
    const isShowPCSelectCha = ref(false)
    const isShowPCSelectRarity = ref(false)
    const isShowPCSelectAttr = ref(false)
    const isShowPCSelectState = ref(false)
    const isShowPCSearchRight = ref(true)
    const isLoadingMore = ref(false)
    const searchKewords = ref('')
    const { walletAddress } = toRefs(props)
    const sortCheckedIndex = ref(0)
    const chaCheckedIndex = ref(-1)
    const rarityCheckedIndex = ref(-1)
    const attrCheckedIndex = ref(-1)
    const pagePerCount = ref(21)
    const pageNow = ref([1, 1, 1])
    const pageCount = ref([1, 1, 1])
    const recordCount = ref([0, 0, 0])
    // const recordCountAll = ref(0)
    // const recordCountInGame = ref(0)
    // const recordCountCollection = ref(0)
    // const recordCountForSale = ref(0)
    // const recordCountRentIn = ref(0)
    // const recordCountSoldOut = ref(0)
    const checkListCha: Ref<number[]> = ref([])
    const checkListRarity: Ref<number[]> = ref([])
    const orderAPIPara = ref('o1')
    const filterAPIPara = ref('')
    const filterSearchAPIPara: Ref<any[]> = ref(['', '', ''])
    // const filterTagListPara = ['taall', 'sasell', 'sagame', 'sawallet']
    const nftTagList = ref([
      {
        tag: 'All NFT',
        value: 'taall',
        key: 'all-nft'
      },
      // {
      //   tag: 'For Sale',
      //   value: 'sasell',
      //   key: 'for-sale'
      // },
      // {
      //   tag: 'For Rent',
      //   value: 'sarent',
      //   key: 'for-rent'
      // },
      // {
      //   tag: 'Rent Out',
      //   value: 'sarout',
      //   key: 'rent-out'
      // },
      {
        tag: 'In Game',
        value: 'sagame',
        key: 'in-game'
      },
      {
        tag: 'Collection',
        value: 'sawallet',
        key: 'collection'
      },
    ])
    const conditionFilter: Ref<{
      name: string;
      value: string;
      order: number;
    }[]> = ref([])
    const filterSortList: Ref<{
      name: string;
      value: string;
      order: number;
    }[]> = ref([])
    const filterTypeList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterCharacterList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterRarityList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    const filterAttrList: Ref<{
      name: string;
      value: any;
    }[]> = ref([])
    let chainId = -1
    const nftStateMap = {
      forsell: 'For Sale',
      rentout: 'Rent Out',
      rentin: 'Rent In',
      renttimeout: 'Closed',
      wallet: 'Collection',
      ingame: 'In Game',
      transaction: 'Transacting',
      forrent: 'For Rent',
      soldout: 'Sold Out'
    }
    const chainSymbol = ref('')
    const gameAccount = ref('')
    let assetsAPIInfo = {
      goldtoken: { gold: '', usdt: '', desktop: '', goldmintrate: 0, goldburnfeerate: 0, symbol: '', decimals: 0, address: '', tokenid: 0 },
      mctoken: { mc: '', desktop: '', nftingame: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
      taptoken: { tap: '', desktop: '', symbol: '', decimals: 0, address: '', tokenid: 0 },
      wallet: { accountid: 0, address: '', nickname: '' },
    }
    let assetsAPIMyNFTHeadListData: {
      count: 0
      list: []
      goodsNFT: {
        feeRate: 0
        goodsid: 0
        nft: ""
        pay: ""
        token: ""
        typenft: false
      },
      goodsToken: {
        feeRate: 0
        goodsid: 0
        nft: ""
        pay: ""
        token: ""
        typenft: false
      },

    }
    // let mynftAPIInfo: {
    //   filtertype: any[]
    //   filterchar: any[]
    //   filterrarity: any[]
    //   filterattr: any[]
    //   ordertype: any[]
    //   paytype: any[]
    //   pt0price: any[]
    //   pt1price: any[]
    //   chainid: 0,
    //   desktop: ''
    // }
    let mynftRentInData: {
      list: [],
      pageindex: 0,
      atype: -1,
      countsoldout: 0,
      countrentin: 0
    }
    // let mynftSoldOutData: {
    //   list: [],
    //   pageindex: 0,
    //   atype: -1,
    //   countsoldout: 0,
    //   countrentin: 0
    // }

    const currentTime = ref(0)
    let updateReturnTimeTask: NodeJS.Timer | null = null

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      setPagePerCount()

      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
        setPagePerCount()
      })
      // const { walletAddress } = toRefs(props)
      // if (walletAddress.value.length > 0) {
      if (props.isRefresh && isLoadingInit) {
        LogUtils.debug('load from onMounted...')
        loadData()
      }
      // }
    })

    onUnmounted(() => {
      if (updateReturnTimeTask !== null) {
        clearInterval(Number(updateReturnTimeTask))
        updateReturnTimeTask = null
      }
    })

    const switchTag = (index: number) => {
      if (categoryIndexNumber.value === 0) {
        tagMyNFTIndex.value = index
        isShowPCSelectState.value = false
        pageNow.value[categoryIndexNumber.value] = 1
        // if (refPaginationMyNFT0.value) {
        //   refPaginationMyNFT0.value.setCurPage(1)
        // }
        getPageData()
      }

    }

    const go2Detail = (index: number) => {
      saveMYNFTHeadStorageSetting()
      const nft = myNFTItemList.value[categoryIndexNumber.value][index]
      router.push(
        `/nfthead/${nft['tokenid']}`
      )
    }

    const categorySwitch = (key: string, keyPath: string[]) => {
      categoryIndexNumber.value = parseInt(key)
    }

    const removeCheckList = (source: number, indexRemove: number) => {
      if (source === 0) {
        checkListCha.value = checkListCha.value.filter(function (value, index, arr) {
          return index !== indexRemove
        })

        // checkListCha.value
      } else {
        checkListRarity.value = checkListRarity.value.filter(function (value, index, arr) {
          return index !== indexRemove
        })
      }
      changeCheckboxGroup()
    }

    const closeSearch = () => {
      isShowPCSearchRight.value = false
      filterSearchAPIPara.value[categoryIndexNumber.value] = ''
      pageNow.value[categoryIndexNumber.value] = 1

      switch (categoryIndexNumber.value) {
        case 0:
          if (refPaginationMyNFT0.value) {
            refPaginationMyNFT0.value.setCurPage(1)
          }
          break;
        case 1:
          if (refPaginationMyNFT1.value) {
            refPaginationMyNFT1.value.setCurPage(1)
          }
          break;
        case 2:
          if (refPaginationMyNFT2.value) {
            refPaginationMyNFT2.value.setCurPage(1)
          }
          break;
        default:
          break;
      }


      getPageData()
    }

    const switchSort = (index: number) => {
      getPageData()
    }

    const changeRadioGroup = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
      await getDepartPageData(1)
      nextTick(() => {
        loadingInstance.close()
      })

    }

    const changeCheckboxGroup = () => {
      // const sortCur = sortCheckedIndex.value
      // const sortData = filterSortList.value[sortCur]

      getPageData()
    }

    const setPagePerCount = () => {
      if (isMobile.value) {
        pagePerCount.value = 30
      } else {
        const leftContentWidth = 210
        const contentPadding = 40 * 2
        const width = 210
        const col_gap = 16
        const windowWidth = window.innerWidth
        const contentWidth = windowWidth - leftContentWidth - contentPadding

        // LogUtils.debug(contentWidth)
        const col_number = contentWidth / (width + col_gap)
        const col_number_integer = parseInt(col_number.toString(), 10)

        // LogUtils.debug(col_number_integer)

        pagePerCount.value = 3 * col_number_integer
      }
    }

    const searchInput = async () => {
      const keyword = searchKewords.value.trim()
      if (keyword.length > 0) {
        const encode = encodeURI(keyword)
        pageNow.value[categoryIndexNumber.value] = 1
        const filterAPIP = filterAPIPara.value.length > 0 ? filterAPIPara.value + ',' + nftTagList.value[tagMyNFTIndex.value].value : nftTagList.value[tagMyNFTIndex.value].value

        if (categoryIndexNumber.value === 0) {
          const urlMyNFTList = `${API.assetsListNFT + chainId}/${walletAddress.value}/${pageNow.value[categoryIndexNumber.value] - 1}/${pagePerCount.value}/?filter=sn${encode},${filterAPIP}`
          assetsAPIMyNFTHeadListData = (await axios.get(urlMyNFTList)).data.data

          filterSearchAPIPara.value[categoryIndexNumber.value] = `sn${encode}`
          myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTHeadListData.list

          recordCount.value[categoryIndexNumber.value] = assetsAPIMyNFTHeadListData.count

          // recordCountAll.value = assetsAPIMyNFTListData.countall
          // recordCountCollection.value = assetsAPIMyNFTListData.countcollection
          // recordCountForSale.value = assetsAPIMyNFTListData.countforsell
          // recordCountInGame.value = assetsAPIMyNFTListData.countingame
        }
      }
    }

    const getLowerCase = (str: any) => {
      return str.toString().toLowerCase()
    }

    const getPageData = async (categoryIndexN: number = -1, tagMyNFTIndexN: number = -1, pageN: number = -1, pagePerN: number = -1) => {
      if (isMobile.value) {
        isLoadingMore.value = true
      }

      let flag = false

      let filterPara = ''
      const filterArr = []

      const categoryIndexPara = categoryIndexN === -1 ? categoryIndexNumber.value : categoryIndexN
      const tagMyNFTIndexPara = tagMyNFTIndexN === -1 ? tagMyNFTIndex.value : tagMyNFTIndexN
      const pageNowPara = pageN === -1 ? pageNow.value[categoryIndexPara] - 1 : pageN - 1
      const pagePerPara = pagePerN === -1 ? pagePerCount.value : pagePerN

      filterArr.push(nftTagList.value[tagMyNFTIndexPara].value)
      // const checkListAttr = [valueSliderEarth.value, valueSliderWater.value, valueSliderFire.value, valueSliderWind.value]
      // const checkListAttr = []

      if (categoryIndexPara === 0) {
        if (filterArr.length > 0) {
          flag = true
        }
      }

      if (flag) {
        if (filterSearchAPIPara.value[categoryIndexPara].length > 0) {
          filterArr.push(filterSearchAPIPara.value[categoryIndexPara])
        }
        if (filterArr.length > 0) {
          filterPara = filterArr.join(',')
        }

        if (pageNowPara === 1 && !isMobile.value) {
          if (categoryIndexPara === 0) {
            if (refPaginationMyNFT0.value) {
              refPaginationMyNFT0.value.setCurPage(1)
            }
          }

        }

        if (categoryIndexPara === 0) {
          const urlMyNFTHeadList = `${API.assetsListNFTHead + chainId}/${walletAddress.value}/${pageNowPara}/${pagePerPara}/?filter=${filterPara}`
          assetsAPIMyNFTHeadListData = (await axios.get(urlMyNFTHeadList)).data.data

          myNFTItemList.value[categoryIndexPara] = assetsAPIMyNFTHeadListData.list

          recordCount.value[categoryIndexPara] = assetsAPIMyNFTHeadListData.count

          // recordCountAll.value = assetsAPIMyNFTListData.countall
          // recordCountCollection.value = assetsAPIMyNFTListData.countcollection
          // recordCountInGame.value = assetsAPIMyNFTListData.countingame

          const index = tagMyNFTIndexPara

          // switch (index) {
          //   case 1:
          //     recordCountForSale.value = assetsAPIMyNFTListData.count
          //     break
          //   case 2:
          //     recordCountInGame.value = assetsAPIMyNFTListData.count
          //     break
          //   case 3:
          //     recordCountCollection.value = assetsAPIMyNFTListData.count
          //     break
          //   default:
          //     recordCountAll.value = assetsAPIMyNFTListData.count
          // }
        }
      }
      if (isMobile.value) {
        isLoadingMore.value = false
      }
    }

    const getDepartPageData = async (page: number) => {
      if (isMobile.value) {
        isLoadingMore.value = true
      }

      let filterMoreList = []

      if (filterAPIPara.value.length > 0) {
        filterMoreList.push(filterAPIPara.value)
      }

      if (filterSearchAPIPara.value[categoryIndexNumber.value].length > 0) {
        filterMoreList.push(filterSearchAPIPara.value[categoryIndexNumber.value])
      }

      filterMoreList.push(nftTagList.value[tagMyNFTIndex.value].value)

      const filterAPIP = filterMoreList.join(',')

      if (page === 1) {
        switch (categoryIndexNumber.value) {
          case 0:
            if (refPaginationMyNFT0.value) {
              refPaginationMyNFT0.value.setCurPage(1)
            }
            break;
          // case 1:
          //   if (refPaginationMyNFT1.value) {
          //     refPaginationMyNFT1.value.setCurPage(1)
          //   }
          //   break;
          // case 2:
          //   if (refPaginationMyNFT2.value) {
          //     refPaginationMyNFT2.value.setCurPage(1)
          //   }
          //   break;
          // default:
          //   break;
        }
      }

      if (categoryIndexNumber.value === 0) {
        const urlMarketList = `${API.assetsListNFTHead + chainId}/${walletAddress.value}/${page - 1}/${pagePerCount.value}/?filter=${filterAPIP}`
        assetsAPIMyNFTHeadListData = (await axios.get(urlMarketList)).data.data
        // if (pageCur !== page) {
        if (!isMobile.value) {
          myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTHeadListData.list
        } else {
          if (page === 1) {
            myNFTItemList.value[categoryIndexNumber.value] = assetsAPIMyNFTHeadListData.list
          } else {
            myNFTItemList.value[categoryIndexNumber.value] = myNFTItemList.value[categoryIndexNumber.value].concat(assetsAPIMyNFTHeadListData.list)
          }
        }

        recordCount.value[categoryIndexNumber.value] = assetsAPIMyNFTHeadListData.count
        pageNow.value[categoryIndexNumber.value] = page

        // recordCountAll.value = assetsAPIMyNFTListData.countall
        // recordCountCollection.value = assetsAPIMyNFTListData.countcollection
        // recordCountForSale.value = assetsAPIMyNFTListData.countforsell
        // recordCountInGame.value = assetsAPIMyNFTListData.countingame

      }

      if (isMobile.value) {
        isLoadingMore.value = false
      }
      // }
    }

    const changePage = (page: number) => {
      // const sortCur = sortCheckedIndex.value
      // const sortData = filterSortList.value[sortCur]
      getPageData()
    }

    const showModal = (index: number, nftindex: number = -1) => {

      nftSelectIndex.value = nftindex
      modalShowIndex.value = index
      isShowModal.value = true

    }


    const showModalTip = (index: number, tip: string) => {
      modalShowIndex.value = index
      modalInfotip.value = tip
      isShowModal.value = true
    }

    const closeModal = () => {
      LogUtils.debug('close...')
      isShowModal.value = false
      nftSelectIndex.value = -1
      modalInfotip.value = ''
    }

    const mobileScrollTop = ref(0)
    const localStorageMYNFTHeadConfig = "mynfthead_data_select_setting"

    interface localStorageMYNFTHeadConfigObject {
      searchKeywords: string,
      pageNow: number,
      tagMyNFTIndex: number,
    }

    interface localStorageMYNFTConfig {
      cur: number,
      data: localStorageMYNFTHeadConfigObject[],
      scrollTop: number
    }

    const loadMYNFTHeadStorageSetting = () => {
      const json = localStorage.getItem(localStorageMYNFTHeadConfig)
      if (json && json.length > 0) {
        const jo = <localStorageMYNFTConfig>JSON.parse(json)
        const cur = jo.cur
        const data = jo.data
        const sTop = jo.scrollTop

        if (data && data.length > 0) {
          if (sTop > 0) {
            mobileScrollTop.value = sTop
          }

          categoryIndexNumber.value = cur

          for (let i = 0; i < 2; i++) {
            const ele = data[i]
            if (ele) {
              if (i === 0) {
                searchKewords.value = ele.searchKeywords
                tagMyNFTIndex.value = ele.tagMyNFTIndex
                if (ele.pageNow) {
                  pageNow.value[i] = ele.pageNow
                }
              }
            }
          }

          //gen search para
          if (searchKewords.value.length > 0) {
            const encode = encodeURI(searchKewords.value[0])
            filterSearchAPIPara.value[cur] = 'sn' + encode
            isShowPCSearchRight.value = true
          }
        }

        // console.log(pageNow.value)
        localStorage.removeItem(localStorageMYNFTHeadConfig)
      }
    }

    const saveMYNFTHeadStorageSetting = () => {
      const cur = categoryIndexNumber.value
      var sTop = document.documentElement.scrollTop || document.body.scrollTop

      // console.log(sTop);
      const ele0 = {
        tagMyNFTIndex: tagMyNFTIndex.value,
        pageNow: pageNow.value[0],
        searchKeywords: ''
      }
      if (cur === 0) {
        ele0.searchKeywords = searchKewords.value
      }

      const data = [
        ele0
      ]

      const config = {
        cur: cur,
        data: data,
        scrollTop: sTop
      }

      localStorage.setItem(localStorageMYNFTHeadConfig, JSON.stringify(config))
    }

    const loadPage0 = async () => {
      const catCur = 0
      const tagMyNFTIndexPara = tagMyNFTIndex.value
      if (!isMobile.value) {
        await getPageData(catCur, tagMyNFTIndexPara, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, tagMyNFTIndexPara, 1, initPageCount)
      }
    }

    const loadPage1 = async () => {
      const catCur = 1
      if (!isMobile.value) {
        await getPageData(catCur, -1, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, -1, 1, initPageCount)
      }
    }

    const loadPage2 = async () => {
      const catCur = 2
      if (!isMobile.value) {
        await getPageData(catCur, -1, pageNow.value[0])
      } else {
        const initPageCount = pagePerCount.value * pageNow.value[0]
        await getPageData(catCur, -1, 1, initPageCount)
      }
    }

    const loadData = async () => {
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })
      const projectConfig = TOOL.getConfigProject()
      const chainSymbolLoad = await TOOL.getChainSymbol()
      chainSymbol.value = chainSymbolLoad
      const chainRPCConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.rpcurls)
      const chainABIConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.abis)
      const chainTokensConfig = TOOL.getDynamicObject(chainSymbolLoad, projectConfig.tokens)

      pageChainConfig.projectConfig = projectConfig
      pageChainConfig.chainSymbol = chainSymbol
      pageChainConfig.chainRPCConfig = chainRPCConfig
      pageChainConfig.chainABIConfig = chainABIConfig
      pageChainConfig.chainTokensConfig = chainTokensConfig
      pageChainConfig.multicallConfig = {
        rpcUrl: chainRPCConfig,
        multicallAddress: chainABIConfig.MULTICALL
      }

      if (walletAddress.value.length > 0) {

        chainId = await TOOL.getChainId()
        // const provider = await getWalletProvider()
        // if (provider) {
        // const debug = '0x97f364fd7d48b3cad3cc69411961614b8b11b230'
        const urlAssetsAPIInfo = `${API.assetsInfo}${chainId}/${walletAddress.value}/`
        assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data
        gameAccount.value = assetsAPIInfo!.wallet.nickname

        loadMYNFTHeadStorageSetting()

        if (categoryIndexNumber.value === 0) {
          await loadPage0()
        }

        isLoading.value = false
        if (isLoadingInit.value) {
          isLoadingInit.value = false
          ctx.emit('update', false)
        }

        nextTick(() => {
          // Loading should be closed asynchronously
          loadingInstance.close()

          if (!isMobile.value) {
            if (categoryIndexNumber.value === 0) {
              if (refPaginationMyNFT0.value) {
                refPaginationMyNFT0.value.setCurPage(pageNow.value[0])
              }
            }
          }

          if (isMobile.value && mobileScrollTop.value > 0) {
            window.scrollTo({
              top: mobileScrollTop.value,
              behavior: "smooth"
            })
          }
        })

        // if (categoryIndexNumber.value === 0) {
        //   loadPage1()
        //   loadPage2()
        // } else if (categoryIndexNumber.value === 1) {
        //   loadPage0()
        //   loadPage2()
        // } else {
        //   loadPage0()
        //   loadPage1()
        // }
      } else {
        nextTick(() => {
          // Loading should be closed asynchronously
          isLoading.value = false
          if (isLoadingInit.value) {
            isLoadingInit.value = false
            ctx.emit('update', false)
          }
          loadingInstance.close()
        })
      }
    }

    return {
      isLoading,
      isMobile,
      nftSelectIndex,
      pageNow,
      pageCount,
      recordCount,
      pagePerCount,
      nftOptType,
      changePage,
      getLowerCase,
      chainSymbol,
      isShowModal,
      showModal,
      closeModal,
      modalShowIndex,
      modalInfotip,
      showModalTip,
      isMarketPlaceOpen,
      walletAddress,
      isShowPCSelectRight,
      isShowPCSearchRight,
      searchKewords,
      searchInput,
      conditionFilter,
      sortCheckedIndex,
      orderAPIPara,
      filterAPIPara,
      filterSortList,
      filterTypeList,
      filterCharacterList,
      filterRarityList,
      filterAttrList,
      myNFTItemList,
      switchSort,
      isLoadingMore,
      getDepartPageData,
      tagMyNFTIndex,
      isShowPCSelectCha,
      isShowPCSelectRarity,
      isShowPCSelectAttr,
      isShowPCSelectState,
      chaCheckedIndex,
      rarityCheckedIndex,
      attrCheckedIndex,
      checkListCha,
      checkListRarity,
      changeCheckboxGroup,
      // recordCountAll,
      // recordCountInGame,
      // recordCountCollection,
      // recordCountForSale,
      // recordCountRentIn,
      // recordCountSoldOut,
      changeRadioGroup,
      refPaginationMyNFT0,
      refPaginationMyNFT1,
      refPaginationMyNFT2,
      closeSearch,
      filterSearchAPIPara,
      removeCheckList,
      nftStateMap,
      categoryIndex,
      categoryIndexNumber,
      categorySwitch,
      go2Detail,
      nftTagList,
      switchTag,
      currentTime
    }
  }
})
</script>
<style lang="stylus" scoped></style>
