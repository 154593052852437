<template>
    <div class="mask-nft modalwindow d-flex flex-column justify-content-center align-items-center">
        <!-- <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
            <path
                d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                p-id="2960" fill="#ffffff" />
        </svg> -->
        <div class="dialog-content d-flex flex-column align-items-center">
            <div class="title">{{ $t('Dialog.notice') }}</div>
            <div class="infotip">
                <span>{{ $t('Dialog.payment-price', [action]) }}</span>
                <span class="infotip-attention">{{ amount }} {{ symbol }}</span>
            </div>
            <div class="infotip">
                <span>{{ $t('Dialog.payment-detail', [action]) }}</span>
                <span class="infotip-attention">{{ detail }}</span>
            </div>
            <button class="button-buy" @click="$emit('confirm')">{{ $t(`Infotip.action-${action}`) }}</button>
            <button class="button-cancel" @click="$emit('cancel')">{{ $t('Dialog.cancel') }}</button>
        </div>
    </div>
</template>
<script lang="ts">
import { computed, ref, defineComponent } from 'vue'

export default defineComponent({
    name: 'ModalPayment',
    props: {
        action: {
            type: String,
            default: ''
        },
        symbol: {
            type: String,
            default: ''
        },
        amount: {
            type: Number,
            default: 0
        },
        detail: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit, attrs }: any) {
        const confirm = () => {
            emit('confirm')
        }
        const cancel = () => {
            emit('cancel')
        }
        return { confirm, cancel }
    }
})
</script>
<style lang="stylus">
</style>