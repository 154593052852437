<template>
  <div class="marketplace-content d-flex flex-column align-items-center justify-content-center"
    style="min-height:100vh;color: white">
    <span>Redirect to v1 site...</span>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
} from 'vue'
import TOOL from '../utils/tool'
import { ElMessage } from 'element-plus'
import { toClipboard } from '@soerenmartius/vue3-clipboard'

export default defineComponent({
  name: 'Tokennomics',
  setup() {
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const isMobile = ref(false)

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })

      window.location.href = "https://v1.tapfantasy.io/#/release"
    })

    return {
      isMobile,
    }
  }

})
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
