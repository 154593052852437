import TOOL from '@/utils/tool';
import { isDebug } from './constants/Config';
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config';
import 'remixicon/fonts/remixicon.css'
import VueGtag from 'vue-gtag-next'
import BigNumber from 'bignumber.js'
import 'amfe-flexible/index.js'
import { createApp } from 'vue'
import { bus } from './utils/bus'
import router from './router/index'
import { key, store } from './store'
import App from './App.vue'
import './utils/rem'
import { createGtm } from '@gtm-support/vue-gtm';
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import './style/basic.styl'
import './style/mobile/basic.styl'
import './style/main.styl'
import './style/mobile/main.styl'
import './style/header.styl'
import './style/mobile/header.styl'
import './style/home.styl'
import './style/mobile/home.styl'
import './style/guild.styl'
import './style/mobile/guild.styl'
import './style/marketplace.styl'
import './style/mobile/marketplace.styl'
import './style/footer.styl'
// import './style/mobile/marketplace.styl'
import vhCheck from "vh-check"
import i18n from "./i18n";
import LogUtils from './utils/LogUtils'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { vueEcharts } from './plugins/chart'
import countTo from 'vue3-count-to';


vhCheck();

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
const getHTMLTag = document.documentElement;
getHTMLTag.setAttribute("lang", i18n.global.locale.value);
LogUtils.debug(i18n.global.locale.value)
app.config.globalProperties.$bus = bus
// app.config.globalProperties.$i18n = i18n

BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
app.config.globalProperties.$BigNumber = BigNumber

const domainLang = TOOL.getDomainLang()
app.use(router)
    .use(store, key)
    .use(i18n)
    .use(vueEcharts)
    .use(countTo);

if (domainLang === 'tw') {
    app
        .use(VueGtag, {
            property: [
                {
                    id: "G-R0HWXN0R0Y"
                },
                {
                    id: "G-1LX900SV0Y",
                },
            ],
            useDebugger: isDebug
        })

} else {
    app
        .use(VueGtag, {
            property: [
                {
                    id: "G-1LX900SV0Y"
                },
            ],
            useDebugger: isDebug
        })

}

app.mount('#app')
