<template>
  <div class="marketplace-content d-flex flex-column align-items-center justify-content-center" style="height: 80vh">
    <div style="font-size: 30px;color: #FFFFFF;">{{ $t('Common.page-not-found') }}</div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
} from 'vue'
import TOOL from '../utils/tool'

export default defineComponent({
  name: 'Page404',
  setup() {
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const isMobile = ref(false)

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
      })
    })

    return {
      isMobile,
    }
  }

})
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
