import { createI18n } from "vue-i18n"
import messages from "./locales";
import TOOL from "@/utils/tool"

// 默认语言 - 
const langBrowser = navigator.language
const langBrowserDefault = (langBrowser === 'ja' || langBrowser === 'zh-TW') ? langBrowser : "en"
let default_lang = localStorage.getItem('locale') || TOOL.getDomainLocale() || langBrowserDefault

const i18n = createI18n({
    legacy: false,
    locale: default_lang,
    fallbackLocale: "en",
    globalInjection: true,
    messages: {
        "en":
            messages.en
        ,
        "ja":
            messages.ja
        ,
        "zh-TW":
            messages["zh-TW"]

    }

})

export default i18n 