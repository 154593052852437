<template>
    <div class="banner-wrap">
        <video autoplay loop muted>
            <source src="/static/videos/page1-video-new.mp4" type="video/mp4" />
        </video>
        <div class="banner">
            <div class="text-info font-LondonTwo">
                <div class="line1">
                    {{ $t('HomeNew.building') }}
                    <span class="line2">{{ $t('HomeNew.game-asset') }}</span>
                </div>
            </div>
            <a href="/#/login">
                <div class="play-now"></div>
            </a>
            <div class="btn-list gradient-text">
                <div class="item">
                    <div class="text">
                        <count-to :endVal='parseFloat(props.data?.totalusers)'
                            :decimals="getDecimal(props.data?.totalusers)"
                            :suffix="getSuffix(props.data?.totalusers)" />
                    </div>
                    <div class="desc">{{ $t('HomeNew.total-users') }}</div>
                </div>
                <div class="item">
                    <div class="text">
                        <!-- {{ props.data?.dau }} -->
                        <count-to :endVal='parseFloat(props.data?.dau)' :decimals="getDecimal(props.data?.dau)"
                            :suffix="getSuffix(props.data?.dau)" />
                    </div>
                    <div class="desc">{{ $t('HomeNew.dau-on-chain') }}</div>
                </div>
                <div class="item">
                    <div class="text">NO.
                        <count-to :endVal='parseFloat(props.data?.ranking)' :decimals="getDecimal(props.data?.ranking)"
                            :suffix="getSuffix(props.data?.ranking)" />
                    </div>
                    <!-- <div class="desc">BNBCHAIN Ranking</div> -->
                    <div class="desc">{{ $t('HomeNew.dappradar-ranking') }}</div>
                </div>
            </div>

        </div>
        <div class="palyVideo" @click="playVideo">

        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, getCurrentInstance } from 'vue'
import { getSuffix, getDecimal } from '../utils/index'

const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus


function playVideo() {
    BUS.$emit('SHOW_MASK', { maskIndex: 0, isShowMask: true })
}
const props = defineProps({
    data: {
        type: Object,
        totalusers: String,
        dau: String,
        ranking: String,

    }

})

</script>

<style  scoped lang="stylus">
.banner-wrap {
    width: 100%;
    height: 1080px;
    position relative;
    
    video{  
        position: absolute;  
        right: 0px;
        top: 0;  
        // bottom: 0px;  
        min-width: 100%;  
        min-height: 100%;  
        height: 100%;  
        width: 100%;  
        /*加滤镜*/
        /*filter: blur(15px); //背景模糊设置 */
        /*-webkit-filter: grayscale(100%);*/  
        /*filter:grayscale(100%); //背景灰度设置*/  
        z-index: 1;
        object-fit: cover;

        source{  
            min-width: 100%;  
            min-height: 100%;  
            height: auto;  
            width: auto;  
        } 
    }  
    .palyVideo {
        width: 100px;
        height: 100px;
        background: url(/static/images/page1_play.png) no-repeat;
        background-size: cover;
        position: absolute;
        right: 70px;
        bottom: 40px;
        z-index 5;
        cursor: pointer;

        &:hover, &:active{
            opacity: 0.8;    
        }
        
    }
    padding: 250px 0 0 120px;
    .banner {
        position: relative;
        z-index: 2;
        
        // background-size: cover;
        box-sizing: border-box;
        .text-info{
            line-height: 90px;
            font-size: 60px;
            .line1 {
                color: #FFFFFF;
            }
            .line2 {
                color: #EE9B0B;
                display: block;
            }
        }
        &>a{
            width: 200px;
            display: block;    
        }
        .play-now {
            width: 190px;
            height: 60px;
            background: url(/static/images/page1_play_now.png) no-repeat;
            background-size: cover;
            cursor: pointer;
            background-color: #FFCC19;
            border-radius: 50px;
            margin-top: 40px;
            &:hover{
                opacity: 0.8;    
            }
        }
        .btn-list {
            width: 674px;
            display: flex;
            justify-content: space-between;
            margin-top: 150px;
            .item {
                width: 200px;
                height: 80px;
                border: 2px solid #CBB055;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: rgba(0,0,0,0.5);
            }
        }

    }
}
</style>

<style scoped lang="stylus">
@media screen and (max-width: 1140px) {
    .banner-wrap {
        height: 720PX;
        padding: 100PX 20PX;
        padding-bottom: 65PX;
        .palyVideo {
            display: none;    
        }
        .banner {
            height: 100%;
            // height: 720PX;
            .text-info {
              

                font-size: 24rem;
                line-height: 40rem;
                .line2 {
                    display: inline-block;
                }
                
            }

            .play-now {
                width: 124rem;
                height: 36rem;
                margin-top: 30rem;
                border-radius: 50PX;
            }
            .btn-list {
                width: 340rem;
                height: 46rem;
                margin: 0 auto;
                position: absolute;
                bottom: 30rem;
                left: 50%;
                transform: translateX(-50%);
                .item {
                    width: 98rem;
                    height: 46rem;
                    .text {
                        font-size: 16rem;
                        line-height: 21rem;
                        width: 100%;
                       
                        text-align: center;
                    }
                    .desc {
                        font-size: 12rem;    
                    }
                    &:last-child {
                        width: 120rem;
                    }
                    
                }
            }
            
        }
        
    }
}

</style>