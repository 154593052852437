<template>
  <div class="guild-container font-bmn">
    <template v-if="!isMobile">
      <el-carousel :loop="false" style="height: 100%;position: relative;" trigger="click" direction="vertical"
        :autoplay="false" ref="divCarousel" @mousewheel="rollScroll" @touchstart="handleTouchstart"
        @touchend="handleTouchend" :indicator-position="isMobile ? 'none' : ''" @change="carouselChange">
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-fix' : 'vh-full') : ''">
          <div class="section-inner page1 d-flex align-items-center justify-content-center">
            <div class="content d-flex flex-column justify-content-center align-items-center">
              <span class="title">{{ $t('GuildCoop.page1.title1') }}</span>
              <span class="desc">{{ $t('GuildCoop.page1.title2') }}</span>
              <a href="https://forms.gle/pU1Ekt3Qtaj8FKhF8" target="_blank"><button
                  @click="gtmTrack('guild-apply-button', isMobile ? 'mobile' : 'pc', 'click', $route.meta.gtm, '')"
                  class="button-apply font-bmnbold">{{ $t('GuildCoop.page1.button_apply') }}</button></a>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-fix' : 'vh-full') : ''">
          <div class="section-inner page2 d-flex flex-column justify-content-start">
            <div class="d-flex flex-column align-items-center">
              <div class="page2-title d-flex justify-content-center">
                <span class="page-common-title"></span>
                <span class="text">{{ $t('GuildCoop.page2.page_title') }}</span>
              </div>
            </div>
            <div class="content">
              <div class="data-item">
                <div class="left font-bmnbold"></div>
                <div class="center">
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon1.png" />
                  </div>
                  <svg viewBox="0 0 20 35" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5"
                      stroke-linecap="round">
                      <g id="GUILD" transform="translate(-474.000000, -1478.000000)" stroke="#8A8A8A" stroke-width="5">
                        <path
                          d="M467.183594,1485.57812 L486.183594,1485.57812 C486.735878,1485.57812 487.183594,1486.02584 487.183594,1486.57812 L487.183594,1505.57812 L487.183594,1505.57812"
                          transform="translate(477.183594, 1495.578125) rotate(45.000000) translate(-477.183594, -1495.578125) ">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon2.png" />
                  </div>
                  <svg viewBox="0 0 20 35" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5"
                      stroke-linecap="round">
                      <g id="GUILD" transform="translate(-474.000000, -1478.000000)" stroke="#8A8A8A" stroke-width="5">
                        <path
                          d="M467.183594,1485.57812 L486.183594,1485.57812 C486.735878,1485.57812 487.183594,1486.02584 487.183594,1486.57812 L487.183594,1505.57812 L487.183594,1505.57812"
                          transform="translate(477.183594, 1495.578125) rotate(45.000000) translate(-477.183594, -1495.578125) ">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon3.png" />
                  </div>
                  <svg viewBox="0 0 20 35" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5"
                      stroke-linecap="round">
                      <g id="GUILD" transform="translate(-474.000000, -1478.000000)" stroke="#8A8A8A" stroke-width="5">
                        <path
                          d="M467.183594,1485.57812 L486.183594,1485.57812 C486.735878,1485.57812 487.183594,1486.02584 487.183594,1486.57812 L487.183594,1505.57812 L487.183594,1505.57812"
                          transform="translate(477.183594, 1495.578125) rotate(45.000000) translate(-477.183594, -1495.578125) ">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon4.png" />
                  </div>
                  <svg viewBox="0 0 20 35" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5"
                      stroke-linecap="round">
                      <g id="GUILD" transform="translate(-474.000000, -1478.000000)" stroke="#8A8A8A" stroke-width="5">
                        <path
                          d="M467.183594,1485.57812 L486.183594,1485.57812 C486.735878,1485.57812 487.183594,1486.02584 487.183594,1486.57812 L487.183594,1505.57812 L487.183594,1505.57812"
                          transform="translate(477.183594, 1495.578125) rotate(45.000000) translate(-477.183594, -1495.578125) ">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon5.png" />
                  </div>
                </div>
                <div class="right"></div>
              </div>
              <div class="data-item">
                <div class="left font-bmnbold">{{ $t('GuildCoop.page2.conditions') }}</div>
                <div class="center">
                  <div class="item" style="justify-content: center">
                    <div class="desc">
                      <p class="nobottom">{{ $t('GuildCoop.page2.free-to-join') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.condition.col1.title') }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.condition.col1.desc1') }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.col1.desc2') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.condition.colother.title', { amount: '10000' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc1', { percent: '16' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc2') }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc3', { amount: '10000' }) }}</p>
                    </div>
                  </div>
                  <div class="item font-bmnbold">
                    <div class="title">
                      {{ $t('GuildCoop.page2.condition.colother.title', { amount: '20000' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc1', { percent: '4' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc2') }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc3', { amount: '20000' }) }}</p>
                    </div>
                  </div>
                  <div class="item font-bmnbold">
                    <div class="title">
                      {{ $t('GuildCoop.page2.condition.colother.title', { amount: '30000' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc1', { percent: '1' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc2') }}</p>
                      <p>{{ $t('GuildCoop.page2.condition.colother.desc3', { amount: '30000' }) }}</p>
                    </div>
                  </div>
                </div>
                <div class="right"></div>
              </div>
              <div class="data-item">
                <div class="left font-bmnbold">{{ $t('GuildCoop.page2.benefits') }}</div>
                <div class="center">
                  <div class="item" style="justify-content: center">
                    <div class="desc">
                      <p class="nobottom">{{ $t('GuildCoop.page2.no-revenue') }}</p>
                    </div>
                  </div>
                  <div class="item" style="justify-content: center">
                    <div class="desc">
                      <p class="nobottom">{{ $t('GuildCoop.page2.na') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits1.colother.title', { 'percent': '7' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc1', { 'percent': '7' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc2') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc3', { 'percent': '0.5' }) }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits1.colother.title', { 'percent': '5' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc1', { 'percent': '5' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc2') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc3', { 'percent': '1.2' }) }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits1.colother.title', { 'percent': '3' }) }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc1', { 'percent': '3' }) }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits1.colother.desc2') }}</p>
                    </div>
                  </div>
                </div>
                <div class="right"></div>
              </div>
              <div class="data-item">
                <div class="left font-bmnbold">{{ $t('GuildCoop.page2.benefits') }}</div>
                <div class="center">
                  <div class="item" style="justify-content: center">
                    <div class="desc">
                      <p class="nobottom">{{ $t('GuildCoop.page2.no-revenue') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits2.col.title') }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc1') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc2') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits2.col.title') }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc1') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc2') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits2.col.title') }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc1') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc2') }}</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="title font-bmnbold">
                      {{ $t('GuildCoop.page2.benefits2.col.title') }}
                    </div>
                    <div class="desc">
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc1') }}</p>
                      <p>{{ $t('GuildCoop.page2.benefits2.col.desc2') }}</p>
                    </div>
                  </div>
                </div>
                <div class="right"></div>
              </div>
              <div class="data-item">
                <div class="left font-bmnbold"></div>
                <div class="center">
                  <div class="ps">
                    {{ $t('GuildCoop.page2.ps') }}
                  </div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-fix' : 'vh-full') : ''">
          <div class="section-inner page3 d-flex justify-content-start">
            <div class="d-flex flex-column align-items-center" style="width: 100%;">
              <div class="d-flex page3-title justify-content-center">
                <span class="page-common-title"></span>
                <span class="text">{{ $t('GuildCoop.page3.page_title') }}</span>
              </div>
              <div class="content">
                <div class="item">
                  <div class="title font-bmnbold">{{ $t('GuildCoop.page3.chart1.title') }}</div>
                  <div class="chart">
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart1-icon1.png" />
                      <div>{{ $t('GuildCoop.page3.chart1.content.desc1') }}</div>
                    </div>
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart1-icon2.png" />
                      <div>{{ $t('GuildCoop.page3.chart1.content.desc2') }}</div>
                    </div>
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart1-icon3.png" />
                      <div>{{ $t('GuildCoop.page3.chart1.content.desc3') }}</div>
                    </div>
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart1-icon4.png" />
                      <div>{{ $t('GuildCoop.page3.chart1.content.desc4') }}</div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="title font-bmnbold">{{ $t('GuildCoop.page3.chart2.title') }}</div>
                  <div class="chart">
                    <img :src="'/static/images/gulid-p3-chart2-' + $i18n.locale + '.png'" />
                  </div>
                </div>
                <div class="item">
                  <div class="title font-bmnbold">{{ $t('GuildCoop.page3.chart3.title') }}</div>
                  <div class="desc font-bmnbold">{{ $t('GuildCoop.page3.chart3.summation-by') }}</div>
                  <div class="chart">
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart3-icon1.png" />
                      <div class="chart-item-right">
                        <div class="line1">{{ $t('GuildCoop.page3.chart3.content1.desc1') }}</div>
                        <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '50' }) }}
                        </div>
                      </div>
                    </div>
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart3-icon2.png" />
                      <div class="chart-item-right">
                        <div class="line1">{{ $t('GuildCoop.page3.chart3.content2.desc1') }}</div>
                        <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '30' }) }}
                        </div>
                      </div>
                    </div>
                    <div class="chart-item">
                      <img src="/static/images/gulid-p3-chart3-icon3.png" />
                      <div class="chart-item-right">
                        <div class="line1">{{ $t('GuildCoop.page3.chart3.content3.desc1') }}</div>
                        <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '20' }) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-fix' : 'vh-full') : ''">
          <div class="section-inner page4  d-flex justify-content-start">
            <div class="d-flex flex-column align-items-center" style="width: 100%;">
              <div class="d-flex page4-title justify-content-center">
                <span class="page-common-title"></span>
                <span class="text">{{ $t('GuildCoop.page4.page_title') }}</span>
              </div>
              <div class="content">
                <div class="left">
                  <div class="line"></div>
                  <div class="line1"></div>
                  <div class="data-item">
                    <div class="half top">
                      <div class="desc">
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step1_time') }}</p>
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step1') }}</p>
                      </div>
                      <div class="dot-inner"></div>
                      <div class="line-inner"></div>
                    </div>
                    <div class="dot"></div>
                    <div class="half bottom">
                      <img src="/static/images/gulid-p4-left-icon1.png" />
                    </div>
                  </div>
                  <div class="line2"></div>
                  <div class="data-item">
                    <div class="half top">
                      <img src="/static/images/gulid-p4-left-icon2.png" />
                    </div>
                    <div class="dot"></div>
                    <div class="half bottom">
                      <div class="line-inner"></div>
                      <div class="dot-inner"></div>
                      <div class="desc">
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step2_time') }}</p>
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step2') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="line2"></div>
                  <div class="data-item">
                    <div class="half top">
                      <div class="desc">
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step3_time') }}</p>
                        <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step3') }}</p>
                      </div>
                      <div class="dot-inner"></div>
                      <div class="line-inner"></div>
                    </div>
                    <div class="dot"></div>
                    <div class="half bottom">
                      <img src="/static/images/gulid-p4-left-icon3.png" />
                    </div>
                  </div>
                  <div class="line2"></div>
                  <div class="data-item">
                    <div class="half top">
                      <img src="/static/images/gulid-p4-left-icon4.png" />
                    </div>
                    <div class="dot"></div>
                    <div class="half bottom">
                      <div class="line-inner"></div>
                      <div class="dot-inner"></div>
                      <div class="desc nobold">
                        <p>{{ $t('GuildCoop.page4.timeline.step4') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="line2"></div>
                  <div class="data-item">
                    <div class="half top">
                      <div class="desc nobold">
                        <p>{{ $t('GuildCoop.page4.timeline.step5') }}</p>
                      </div>
                      <div class="dot-inner"></div>
                      <div class="line-inner"></div>
                    </div>
                    <div class="dot"></div>
                    <div class="half bottom">
                      <img src="/static/images/gulid-p4-left-icon5.png" />
                    </div>
                  </div>
                  <div class="line1"></div>
                </div>
                <div class="right">
                  <div class="title">
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.chart.title1') }}</p>
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.chart.title2') }}</p>
                  </div>
                  <img :src="'/static/images/gulid-p4-chart-' + $i18n.locale + '.png'" />
                  <div class="ps">{{ $t('GuildCoop.page4.chart.ps') }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-full' : 'vh-full') : ''">

          <div class="section-inner page5 d-flex flex-column align-items-center">
            <div class="d-flex flex-column align-items-center">
              <div class="page-common-title-line"></div>
              <div class="page-common-title">{{ $t('GuildCoop.page5.page_title') }}</div>
            </div>
            <div class="partners-grid" style="z-index: 11">
              <div class="partner-item">
                <img src="/static/images/partner-gulid-01.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-02.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-03.png" style="width: 70%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-04.png" style="width: 60%;margin-top: 4px;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-05.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-06.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-07.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-08.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-09.png" style="width: 60%;" />
              </div>
            </div>
            <a href="https://forms.gle/pU1Ekt3Qtaj8FKhF8" target="_blank"><button
                @click="gtmTrack('guild-apply-button', isMobile ? 'mobile' : 'pc', 'click', $route.meta.gtm, '')"
                class="button-apply font-bmnbold">{{ $t('GuildCoop.page5.button_apply') }}</button></a>
          </div>
        </el-carousel-item>
        <el-carousel-item class="section" :class="isMobile ? (!isScreenMobileFull ? 'vh-full' : 'vh-full') : ''">
          <div class="page6-footer-warp">
            <div class="page6-footer">
              <FooterNew />
            </div>
          </div>
          <!--  
          <div class="page6">
            <div class="bottom d-flex flex-column">
              <div class="d-flex justify-content-between align-items-center">
                <div class="left d-flex flex-column align-items-center">
                  <div class="logo d-flex flex-column align-items-center">
                    <span class="logo-title1">{{
                        $t("Home.page8.bottom_left.title1")
                    }}</span>
                    <span class="logo-title2">{{
                        $t("Home.page8.bottom_left.title2")
                    }}</span>
                  </div>
                  <a class="link first d-flex" href="https://docsend.com/view/25th9f4eubw8dz9s" target="_blank">
                    <button class="button-block">{{
                        $t("Home.page8.bottom_left.button_pitchdeck")
                    }}</button>
                  </a>
                  <a class="link  d-flex" href="https://forms.gle/ueqGajGW6pdi1U9M6" target="_blank">
                    <button class="button-block">{{
                        $t("Home.page8.bottom_left.button_feedback")
                    }}</button>
                  </a>
                  <div class="auditby">{{
                      $t("Home.page8.bottom_left.auditby")
                  }}</div>
                  <a href="/#/pdf/static%2Fpdf%2Faudit_certik.pdf" class="audit">
                    <img src="/static/images/audit01.png" />
                  </a>
                </div>
                <div class="right d-flex align-items-start" style="flex: 1;">
                  <div class="item flex flex-column justify-content-start">
                    <div class="category">{{
                        $t("Home.page8.bottom_right.col_community.category")
                    }}</div>
                    <a class="sociallink" href="https://twitter.com/tapfantasy2021" target="_blank">
                      <img src="/static/images/page8_bottom_twitter.png" />
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_community.list.twitter")
                      }}</span>
                    </a>
                    <a class="sociallink" href="https://t.me/tap_fantasy" target="_blank">
                      <img src="/static/images/page8_bottom_telegram.png" />
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_community.list.telegram")
                      }}</span>
                    </a>
                    <a class="sociallink" href="https://discord.gg/tapfantasy" target="_blank">
                      <img src="/static/images/page8_bottom_discord.png" />
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_community.list.discord")
                      }}</span>
                    </a>
                    <a class="sociallink" href="https://medium.com/tapfantasy" target="_blank">
                      <img src="/static/images/page8_bottom_medium.png" />
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_community.list.medium")
                      }}</span>
                    </a>
                    <a class="sociallink" href="https://github.com/tapfantasy/" target="_blank">
                      <img src="/static/images/page8_bottom_github.png" />
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_community.list.github")
                      }}</span>
                    </a>
                  </div>
                  <div class="item flex flex-column justify-content-center">
                    <div class="category">{{
                        $t("Home.page8.bottom_right.col_about.category")
                    }}</div>
                    <a class="link" href="https://tapfantasy.gitbook.io/tap-fantasy-en/" target="_blank">
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_about.howtoplay")
                      }}</span>
                    </a>
                    <a class="link" href="https://tapfantasy.gitbook.io/tap-fantasy-en/contract-address"
                      target="_blank">
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_about.contract")
                      }}</span>
                    </a>
                    <a class="link" href="mailto:official@tapfantasy.io" target="_blank">
                      <span class="text">
                        {{
                            $t("Home.page8.bottom_right.col_about.contactus")
                        }}
                      </span>
                    </a>
                  </div>
                  <div class="item flex flex-column justify-content-center align-items-center">
                    <div class="category">{{
                        $t("Home.page8.bottom_right.col_v1.category")
                    }}</div>
                    <a class="link" href="https://v1.tapfantasy.io/#/release" target="_blank">
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_v1.release")
                      }}</span>
                    </a>
                    <a class="link" href="https://v1.tapfantasy.io/#/pools" target="_blank">
                      <span class="text">{{
                          $t("Home.page8.bottom_right.col_v1.pools")
                      }}</span>
                    </a>
                  </div>
                  <div class="item flex flex-column justify-content-center">
                    <div class="category">LANGUAGE</div>
                    <div class="language-select" @click="isOpenLanguage = !isOpenLanguage">
                      <span class="lang-cur">
                        <template v-if="$i18n.locale === 'en'">ENGLISH</template>
                        <template v-else-if="$i18n.locale === 'ja'">日本語</template>
                        <template v-else-if="$i18n.locale === 'zh-TW'">繁體中文</template>
                      </span>
                      <svg :class="isOpenLanguage ? 'rotate' : ''" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(-1662.000000, -8237.000000)" fill="#FFFFFF">
                            <path
                              d="M1667.87416,8238.57348 L1671.17464,8244.51436 C1671.44286,8244.99714 1671.26891,8245.60594 1670.78613,8245.87416 C1670.63757,8245.95669 1670.47043,8246 1670.30049,8246 L1663.69951,8246 C1663.14723,8246 1662.69951,8245.55228 1662.69951,8245 C1662.69951,8244.83006 1662.74282,8244.66292 1662.82536,8244.51436 L1666.12584,8238.57348 C1666.39406,8238.0907 1667.00286,8237.91676 1667.48564,8238.18497 C1667.64888,8238.27566 1667.78347,8238.41024 1667.87416,8238.57348 Z"
                              transform="translate(1667.000000, 8241.500000) scale(1, -1) translate(-1667.000000, -8241.500000) ">
                            </path>
                          </g>
                        </g>
                      </svg>
                      <div v-if="isOpenLanguage" class="lang-list">
                        <template v-for="(langitem) of lang_list" :key="'lang-' + langitem.lang">
                          <div @click="setLang(langitem)" class="lang-item" v-if="langitem.lang !== $i18n.locale">
                            {{ langitem.display }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="copyright">
                <template v-if="domainLang === 'tw'">
                  Copyright Mmobay limited. 2021
                </template>
                <template v-else>
                  {{
                      $t("Home.bottom.copyright")
                  }}
                </template>
              </div>
            </div>
          </div>
        -->
        </el-carousel-item>
      </el-carousel>
    </template>
    <template v-else>
      <div class="section page1">
        <div class="section-inner d-flex align-items-center justify-content-center">
          <div class="content d-flex flex-column justify-content-center align-items-center" style="margin-top: 60%">
            <span class="title">{{ $t('GuildCoop.page1.title1') }}</span>
            <span class="desc">{{ $t('GuildCoop.page1.title2') }}</span>
            <a href="https://forms.gle/pU1Ekt3Qtaj8FKhF8" target="_blank"
              @click="gtmTrack('guild-apply-button', isMobile ? 'mobile' : 'pc', 'click', $route.meta.gtm, '')"><button
                class="button-apply font-bmnbold">{{ $t('GuildCoop.page1.button_apply') }}</button></a>
          </div>
        </div>
      </div>
      <div class="section page2">
        <div class="section-inner d-flex justify-content-center">
          <div class="d-flex flex-column align-items-center" style="width: 100%;height: 100%;">
            <div class="title-block d-flex justify-content-center">
              <span class="page-common-title"></span>
              <span class="title">{{ $t('GuildCoop.page2.page_title') }}</span>
            </div>
            <div class="mobile-content">
              <div class="title-block">
                <div class="data-item"></div>
                <div class="data-item">
                  <div class="title-item font-bmnbold">{{ $t('GuildCoop.page2.conditions') }}</div>
                </div>
                <div style="width:16PX;height: 9PX;"></div>
                <div class="data-item">
                  <div class="title-item font-bmnbold">{{ $t('GuildCoop.page2.benefits') }}</div>
                </div>
              </div>
              <div class="chart-block">
                <div class="data-item">
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon1.png" />
                  </div>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon2.png" />
                  </div>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon3.png" />
                  </div>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon4.png" />
                  </div>
                  <div class="item img">
                    <img src="/static/images/gulid-p2-icon5.png" />
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.free-to-join') }}</div>
                      <div>{{ $t('GuildCoop.page2.not-certified') }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.condition.row1.desc1_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.row1.desc2_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.row1.desc3_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.row1.desc4_mobile') }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc1_mobile', { 'percent': '16' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc2_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc3_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc4_mobile', { 'amount': '10,000' }) }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc1_mobile', { 'percent': '4' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc2_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc3_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc4_mobile', { 'amount': '20,000' }) }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc1_mobile', { 'percent': '1' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc2_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc3_mobile') }}</div>
                      <div>{{ $t('GuildCoop.page2.condition.rowother.desc4_mobile', { 'amount': '30,000' }) }}</div>
                    </div>
                  </div>
                </div>
                <div class="data-item">
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.no-revenue') }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.no-revenue') }}</div>
                    </div>
                  </div>

                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc1', { 'percent': '7' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc2') }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc3') }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc4', { 'percent': '0.5' }) }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc1', { 'percent': '5' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc2') }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc3') }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc4', { 'percent': '1.2' }) }}</div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="desc">
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc1', { 'percent': '3' }) }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc2') }}</div>
                      <div>{{ $t('GuildCoop.page2.benefits-mobile.col.desc3-nopercent') }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ps">{{ $t('GuildCoop.page2.ps') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section page3">
        <div class="section-inner page3 d-flex justify-content-center">
          <div class="d-flex flex-column align-items-center" style="width: 100%;">
            <div class="title-block d-flex justify-content-center">
              <span class="page-common-title"></span>
              <span class="title">{{ $t('GuildCoop.page3.page_title') }}</span>
            </div>
            <div class="mobile-content">
              <div class="item">
                <div class="title font-bmnbold">{{ $t('GuildCoop.page3.chart1.title') }}</div>
                <div class="chart">
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart1-icon1.png" />
                    <div>{{ $t('GuildCoop.page3.chart1.content.desc1') }}</div>
                  </div>
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart1-icon2.png" />
                    <div>{{ $t('GuildCoop.page3.chart1.content.desc2') }}</div>
                  </div>
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart1-icon3.png" />
                    <div>{{ $t('GuildCoop.page3.chart1.content.desc3') }}</div>
                  </div>
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart1-icon4.png" />
                    <div>{{ $t('GuildCoop.page3.chart1.content.desc4') }}</div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="title font-bmnbold">
                  {{ $t('GuildCoop.page3.chart2.title') }}
                </div>
                <div class="chart">
                  <img :src="'/static/images/gulid-p3-chart2_mobile-' + $i18n.locale + '.png'" />
                </div>
              </div>
              <div class="item">
                <div class="title font-bmnbold">
                  {{ $t('GuildCoop.page3.chart3.title') }}
                </div>
                <div class="desc font-bmnbold">{{ $t('GuildCoop.page3.chart3.summation-by') }}</div>
                <div class="chart">
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart3-icon1.png" />
                    <div class="chart-item-right">
                      <div class="line1">{{ $t('GuildCoop.page3.chart3.content1.desc1') }}</div>
                      <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '50' }) }}
                      </div>
                    </div>
                  </div>
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart3-icon2.png" />
                    <div class="chart-item-right">
                      <div class="line1">{{ $t('GuildCoop.page3.chart3.content2.desc1') }}</div>
                      <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '30' }) }}
                      </div>
                    </div>
                  </div>
                  <div class="chart-item">
                    <img src="/static/images/gulid-p3-chart3-icon3.png" />
                    <div class="chart-item-right">
                      <div class="line1">{{ $t('GuildCoop.page3.chart3.content3.desc1') }}</div>
                      <div class="line2 font-bmnbold">{{ $t('GuildCoop.page3.chart3.weight', { 'percent': '20' }) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section page4">
        <div class="section-inner page4 d-flex flex-column align-items-center">
          <div class="title-block d-flex justify-content-center">
            <span class="page-common-title"></span>
            <span class="title">{{ $t('GuildCoop.page4.page_title') }}</span>
          </div>
          <div class="mobile-content">
            <div class="top">
              <div class="line"></div>
              <div class="line1"></div>
              <div class="data-item">
                <div class="half left"></div>
                <div class="dot"></div>
                <div class="half right">
                  <div class="line-inner"></div>
                  <div class="dot-inner"></div>
                  <img src="/static/images/gulid-p4-left-icon1.png" />
                  <div class="desc">
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step1_time') }}</p>
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step1') }}</p>
                  </div>
                </div>
              </div>
              <div class="line2"></div>
              <div class="data-item">
                <div class="half left">
                  <div class="dot-inner"></div>
                  <div class="line-inner"></div>
                  <img src="/static/images/gulid-p4-left-icon2.png" />
                  <div class="desc">
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step2_time') }}</p>
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step2') }}</p>
                  </div>
                </div>
                <div class="dot"></div>
                <div class="half right"></div>
              </div>
              <div class="line2"></div>
              <div class="data-item">
                <div class="half left"></div>
                <div class="dot"></div>
                <div class="half right">
                  <div class="line-inner"></div>
                  <div class="dot-inner"></div>
                  <img src="/static/images/gulid-p4-left-icon3.png" />
                  <div class="desc">
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step3_time') }}</p>
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step3') }}</p>
                  </div>
                </div>
              </div>
              <div class="line2"></div>
              <div class="data-item">
                <div class="half left">
                  <div class="dot-inner"></div>
                  <div class="line-inner"></div>
                  <img src="/static/images/gulid-p4-left-icon4.png" />
                  <div class="desc nobold">
                    <p class="font-bmnbold">{{ $t('GuildCoop.page4.timeline.step4') }}</p>
                  </div>
                </div>
                <div class="dot"></div>
                <div class="half right"></div>
              </div>
              <div class="line2"></div>
              <div class="data-item">
                <div class="half left"></div>
                <div class="dot"></div>
                <div class="half right">
                  <div class="line-inner"></div>
                  <div class="dot-inner"></div>
                  <img src="/static/images/gulid-p4-left-icon5.png" />
                  <div class="desc nobold">
                    <p style="width: 120PX;line-height:1.5">{{ $t('GuildCoop.page4.timeline.step5') }}</p>
                  </div>
                </div>
              </div>
              <div class="line1"></div>
            </div>
            <div class="bottom">
              <div class="title">
                <p class="font-bmnbold">{{ $t('GuildCoop.page4.chart.title1') }}</p>
                <p class="font-bmnbold">{{ $t('GuildCoop.page4.chart.title2') }}</p>
              </div>
              <img :src="'/static/images/gulid-p4-chart_mobile-' + $i18n.locale + '.png'" />
              <div class="ps">{{ $t('GuildCoop.page4.chart.ps') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section page5">
        <div class="section-inner page5 d-flex flex-column align-items-center">
          <div class="title-block d-flex justify-content-center">
            <span class="page-common-title"></span>
            <span class="title">{{ $t('GuildCoop.page5.page_title') }}</span>
          </div>
          <div class="bottom">
            <div class="partners-grid" style="z-index: 11">
              <div class="partner-item">
                <img src="/static/images/partner-gulid-01.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-02.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-03.png" style="width: 70%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-04.png" style="width: 60%;margin-top: 4px;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-05.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-06.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-07.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-08.png" style="width: 80%;" />
              </div>
              <div class="partner-item">
                <img src="/static/images/partner-gulid-09.png" style="width: 60%;" />
              </div>
            </div>
          </div>
        </div>
        <a href="https://forms.gle/pU1Ekt3Qtaj8FKhF8" target="_blank"><button class="button-apply font-bmnbold page5">{{
            $t('GuildCoop.page5.button_apply')
        }}</button></a>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  getCurrentInstance
} from 'vue'
import TOOL from '../utils/tool'
import { ElMessage, ElCarousel, ElCarouselItem } from 'element-plus'
import { toClipboard } from '@soerenmartius/vue3-clipboard'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import { useRouter } from 'vue-router'
import LogUtils from '@/utils/LogUtils'
import { useI18n } from "vue-i18n"
import i18n from "@/i18n";
import FooterNew from './FooterNew/index.vue'

declare let WeixinJSBridge: any

export default defineComponent({
  name: 'GuildCoop',
  components: { ElMessage, ElCarousel, ElCarouselItem, FooterNew },
  setup() {
    const { locale } = useI18n()
    const isShowTeleList = ref(false)
    const isShowContractList = ref(false)
    const isShowTelegram = ref(false)
    const isMarketPlaceOpen = ref(BLOCKCHAIN_CONFIG.isMarketPlaceOpen)
    const isPlayGameOpen = ref(BLOCKCHAIN_CONFIG.isPlayGameOpen)
    const divCarousel: Ref<any> = ref(null)
    const page1Video: Ref<any> = ref(null)
    const page2Video: Ref<any> = ref(null)
    const sliderDiv: Ref<any> = ref(null)
    const widthIndicator = ref('0')
    const pageIndicator = ref(1)
    const clientHeightMobile = ref(0)
    const isScreenMobileFull = ref(false)
    const isShowPage2Video = ref(true)
    let scrollCarouselTimeout: any = null
    const isShowShareMobile = ref(false)
    const refFirstRoadmapItem: Ref<any> = ref(null)
    const refLastRoadmapItem: Ref<any> = ref(null)
    const refRoadmapLineblock: Ref<any> = ref(null)
    const refRoadmapLineblockInner: Ref<any> = ref(null)

    const targetRoadmap = ref(null)
    let timeNow = (new Date()).getTime()
    let isNormalScroll = true

    const startTime = ref(0)
    const startX = ref(0)
    const startY = ref(0)

    const isMobile = ref(false)
    const carouselActiveIndex = ref(0)
    const enterPage4 = ref(false)

    const isOpenLanguage = ref(false)
    const lang_list = ref([
      {
        lang: 'en',
        display: 'ENGLISH'
      },
      {
        lang: 'ja',
        display: '日本語'
      },
      {
        lang: 'zh-TW',
        display: '繁體中文'
      }

    ])

    const router = useRouter()
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus
    const domainLang = TOOL.getDomainLang()

    onMounted(() => {
      // setTimeout(function () {
      //   page1.value!.scrollTop = 0
      // }, 500)

      // const page1Obj = page1.value as any

      // page1Obj.style.top = 0

      isMobile.value = !TOOL.getDeviceScreenIsPC()
      // adjustScreen()

      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile

        if (isMobile.value) {
          const clientHeight = document.documentElement.clientHeight
          clientHeightMobile.value = clientHeight

          if (judgeBrowser().ios) {
            if (clientHeight < window.outerHeight) {
              isScreenMobileFull.value = false
            } else {
              isScreenMobileFull.value = true
            }
          } else {
            if (clientHeight < window.outerHeight) {
              isScreenMobileFull.value = true
            } else {
              isScreenMobileFull.value = false
            }
          }
        }

        BUS.$on('CLOSE_VIDEO_MASK', (para: any) => {
          if (para.close) {
            isShowPage2Video.value = true
          }
        })


        // LogUtils.debug(clientHeightMobile.value)
        // LogUtils.debug(window.outerHeight)
        // adjustScreen()
      })

      clientHeightMobile.value = document.documentElement.clientHeight

      if (judgeBrowser().ios && judgeBrowser().wx) {
        autoPlayVideo()
      }


      // 组件挂载时，添加scroll监听
      // window.addEventListener('scroll', scrollHandle, true)

      // await nextTick()
      // document.body.scrollTop = 0
    })
    onUnmounted(() => {
      // 组件卸载时，停止监听
      // window.removeEventListener('scroll', scrollHandle, true)
    })

    const setLang = (langitem: any) => {
      const lang = i18n.global.locale.value

      const getHTMLTag = document.documentElement
      getHTMLTag.setAttribute("lang", langitem.lang)
      localStorage.setItem('locale', langitem.lang)

      locale.value = langitem.lang

      // if (lang !== langitem.lang) {
      //   window.location.reload()
      // }
    }

    const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
      TOOL.gtmTrack(event, category, action, label, value)
    }

    const carouselChange = (activeIndex: number, oriIndex: number) => {
      carouselActiveIndex.value = activeIndex
      if (carouselActiveIndex.value === 3) {
        setTimeout(() => {
          enterPage4.value = true
        }, 200)
      } else {
        enterPage4.value = false
      }

    }

    const copyText = (content: string) => {
      toClipboard(content)
      ElMessage({ message: 'contract address copied!', type: 'success' })
    }

    // 滚动事件处理函数
    // function scrollHandle() {
    //   const flag = useElementVisibility(targetRoadmap)
    //   if (flag.value !== targetIsVisibleRoadmap.value) {
    //     targetIsVisibleRoadmap.value = flag.value
    //     // LogUtils.debug()
    //     // LogUtils.debug(document.body.clientHeight)
    //     // LogUtils.debug(document.body.offsetHeight)
    //   }
    // }

    function playVideo() {
      BUS.$emit('SHOW_MASK', { maskIndex: 0, isShowMask: true })
      isShowPage2Video.value = false
    }

    const autoPlayVideo = () => {
      if ((window as any).WeixinJSBridge) {
        WeixinJSBridge.invoke('getNetworkType', {}, function (e: any) {
          pagePlayVideo()
        }, false)
      } else {
        document.addEventListener("WeixinJSBridgeReady", function () {
          WeixinJSBridge.invoke('getNetworkType', {}, function (e: any) {
            pagePlayVideo()
          }, false)
        })
      }
      // pagePlayVideo()
    }

    function pagePlayVideo() {
      page1Video.value.play()
      // page1Video.value.addEventListener('ended', function () {
      //   page1Video.value.play()
      // })
      page2Video.value.play()
      // page2Video.value.addEventListener('ended', function () {
      //   page2Video.value.play()
      // })
    }

    const detectTrackPad = (e: any) => {
      var isTrackpad = false;
      if (e.wheelDeltaY) {
        if (e.wheelDeltaY === (e.deltaY * -3)) {
          isTrackpad = true;
        }
      }
      else if (e.deltaMode === 0) {
        isTrackpad = true;
      }
      // LogUtils.debug(isTrackpad ? "Trackpad detected" : "Mousewheel detected");
      return isTrackpad
    }

    const rollScroll = (event: any) => {
      const isTrackpad = detectTrackPad(event)
      let scrollTime = (new Date()).getTime()
      let scrollVal = event.wheelDelta || event.detail
      let scrollInterval = isTrackpad ? 300 : 500
      let scrollIntervalNext = isTrackpad ? 300 : 300
      let scrollDistance = isTrackpad ? 80 : 200
      if ((scrollTime - timeNow) >= scrollInterval && Math.abs(scrollVal) > scrollDistance && isNormalScroll) {
        // LogUtils.debug(scrollVal)
        isNormalScroll = false
        LogUtils.debug('deal scroll...')
        if (scrollCarouselTimeout) {
          clearTimeout(scrollCarouselTimeout)
          scrollCarouselTimeout = null
        }

        scrollCarouselTimeout = setTimeout(() => {
          scrollVal > 0 ? divCarousel.value.prev() : divCarousel.value.next();
          setTimeout(() => {
            timeNow = (new Date()).getTime()
            isNormalScroll = true
          }, scrollIntervalNext)
        }, 0);
      }


    }

    const scrollLeftSlider = () => {
      const scrollWidth = sliderDiv.value.scrollWidth
      const scrollLeft = sliderDiv.value.scrollLeft
      const scrollLeftNew = scrollLeft - window.innerWidth
      const maxScrollWidth = scrollWidth - window.innerWidth

      sliderDiv.value.scrollLeft = scrollLeftNew
      widthIndicator.value = '0%'
      pageIndicator.value = 1
    }

    const scrollRightSlider = () => {
      const scrollWidth = sliderDiv.value.scrollWidth
      const scrollLeft = sliderDiv.value.scrollLeft
      const scrollLeftNew = scrollLeft + window.innerWidth
      const maxScrollWidth = scrollWidth - window.innerWidth
      // LogUtils.debug(window.innerWidth)
      // LogUtils.debug(scrollWidth)
      // LogUtils.debug(scrollLeft)
      // LogUtils.debug(maxScrollWidth)
      sliderDiv.value.scrollLeft = maxScrollWidth
      widthIndicator.value = '100%'
      pageIndicator.value = 2
    }

    //屏幕滑动
    //手指按下屏幕
    const handleTouchstart = (event: any) => {
      startTime.value = Date.now()
      startX.value = event.changedTouches[0].clientX
      startY.value = event.changedTouches[0].clientY
    }
    //手指离开屏幕
    const handleTouchend = (event: any) => {
      const endTime = Date.now()
      const endX = event.changedTouches[0].clientX
      const endY = event.changedTouches[0].clientY
      //判断按下的时长
      if (endTime - startTime.value > 2000) {
        return
      }
      //滑动的方向
      let direction = "";
      // //先判断用户滑动的距离，是否合法，合法:判断滑动的方向 注意 距离要加上绝对值
      // if (Math.abs(endX - startX.value) > 10) {
      //   //滑动方向
      //   if (Math.abs(endY - startY.value) > 30) {
      //     LogUtils.debug("y方向偏移太多，不让你滑了")
      //     return
      //   } else {
      //     direction = endY - startY.value > 0 ? "down" : "up"
      //   }
      // } else {
      //   return
      // }

      if (Math.abs(endY - startY.value) > 100) {
        direction = endY - startY.value > 0 ? "down" : "up"
        //用户做了合法的滑动操作
        // LogUtils.debug('方向'+direction)
        if (direction === 'down') {
          divCarousel.value.prev()
        } else if (direction === 'up') {
          divCarousel.value.next()
        }
      } else {
        return
      }


    }

    const judgeBrowser = () => {
      const ua = navigator.userAgent;
      return {//移动终端浏览器版本信息
        trident: ua.indexOf('Trident') > -1, //IE内核
        presto: ua.indexOf('Presto') > -1, //opera内核
        webKit: ua.indexOf('AppleWebKit') > -1, //苹果内核
        chrome: ua.indexOf("chrome") > -1, //谷歌内核
        gecko: ua.indexOf('Gecko') > -1 && ua.indexOf('KHTML') == -1, //火狐内核
        mobile: !!ua.match(/AppleWebKit.*Mobile.*/) || !!ua.match(/AppleWebKit/), //是否为移动终端
        ios: !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1, //android终端或者uc浏览器
        iPhone: ua.indexOf('iPhone') > -1 || ua.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
        iPad: ua.indexOf('iPad') > -1, //是否iPad
        webApp: ua.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        wx: ua.indexOf("MicroMessenger") > 0 //是否是微信
      };
    }

    return {
      playVideo,
      isMobile,
      isShowTeleList,
      isShowContractList,
      copyText,
      isMarketPlaceOpen,
      isPlayGameOpen,
      rollScroll,
      divCarousel,
      page1Video,
      page2Video,
      sliderDiv,
      scrollLeftSlider,
      scrollRightSlider,
      widthIndicator,
      handleTouchstart,
      handleTouchend,
      startTime,
      startX,
      startY,
      pageIndicator,
      isScreenMobileFull,
      isShowPage2Video,
      isShowShareMobile,
      carouselChange,
      carouselActiveIndex,
      enterPage4,
      refLastRoadmapItem,
      refFirstRoadmapItem,
      refRoadmapLineblock,
      refRoadmapLineblockInner,
      gtmTrack,
      isShowTelegram,
      isOpenLanguage,
      lang_list,
      setLang,
      domainLang
    }
  }
})
</script>

<style lang="stylus">
.color-transparent {
  color: transparent;
}
</style>

<style scoped lang="stylus">
    .page6-footer-warp {
        width: 100%;
        height: 100%;
        background-color: #000;
        position: relative;

        .page6-footer {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

    }
</style>
