<template>
  <div class="page-uc" style="position: relative;">
    <el-dialog v-model="isShowModal" :show-close="false" :before-close="closeModal">
      <div v-if="modalShowIndex === 0" class="
              mask-nft
              usercentercontent
              d-flex
              flex-column
              justify-content-center
              align-items-center
            " @click.stop="isMobile ? 'closeModal' : ''">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <span class="title">{{
            $t("Dialog.deposit-title-token", [
              tokenSelectInfo[tokenSelectIndex].symbol,
            ])
          }}</span>
          <span class="token-title">
            <template v-if="tokenSelectIndex === 0">{{
              $t("Dialog.select-token")
            }}</template>
            <template v-else>{{ $t("Dialog.deposit-token") }}</template>
          </span>
          <div class="token-name d-flex align-items-center">
            <span v-if="tokenSelectIndex !== 0" class="text">{{
              $t("Dialog.action-token-by-symbol", [
                tokenSelectInfo[tokenSelectIndex].symbol,
              ])
            }}</span>
            <template v-else>
              <div @click="changeDepositGoldType(0)" :class="depositGoldType === 0 ? 'active' : ''" class="
                      radio-block
                      d-flex
                      align-items-center
                      justify-content-center
                    ">
                <div class="inner d-flex align-items-center">
                  <div class="radio-icon"></div>
                  <div class="radio-text">GOLD</div>
                </div>
              </div>
              <div @click="changeDepositGoldType(1)" :class="depositGoldType === 1 ? 'active' : ''" class="
                      radio-block
                      d-flex
                      align-items-center
                      justify-content-center
                    ">
                <div class="inner d-flex align-items-center">
                  <div class="radio-icon"></div>
                  <div class="radio-text">{{ tokenUsdtInfo.symbol }}</div>
                </div>
              </div>
            </template>
          </div>
          <div class="balance-block d-flex align-items-center justify-content-end">
            <span class="title">{{ $t("Dialog.balance") }}{{ $t("Common.colon") }}</span>
            <span class="title yellow">
              <template v-if="tokenSelectIndex === 0 && depositGoldType === 1">{{ usdtBalance }} {{ tokenUsdtInfo.symbol
              }}</template>
              <template v-else>{{ tokenBalance[tokenSelectIndex] }}
                {{ tokenSelectInfo[tokenSelectIndex].symbol }}</template>
            </span>
          </div>
          <div class="
                  input-block
                  d-flex
                  align-items-center
                  justify-content-between
                ">
            <div class="inner d-flex align-items-center justify-content-center">
              <input @input="watchInput(tokenSelectIndex)" type="text" v-model="inputAmount" />
              <span @click="maxInput(tokenSelectIndex)" class="max">{{
                $t("Common.max")
              }}</span>
              <span class="token-symbol">
                <template v-if="tokenSelectIndex === 0 && depositGoldType === 1">{{ tokenUsdtInfo.symbol }}</template>
                <template v-else>{{
                  tokenSelectInfo[tokenSelectIndex].symbol
                }}</template>
              </span>
            </div>
          </div>
          <template v-if="depositGoldType === 1 && tokenSelectIndex === 0">
            <svg viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="deposit-busd" transform="translate(-954.000000, -583.000000)" fill="#FFFFFF" fill-rule="nonzero">
                  <g transform="translate(760.000000, 250.000000)">
                    <g transform="translate(194.000000, 333.000000)">
                      <g transform="translate(2.500000, 7.000000) scale(-1, 1) translate(-2.500000, -7.000000) ">
                        <path
                          d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                        </path>
                      </g>
                      <g
                        transform="translate(10.500000, 7.000000) scale(1, -1) translate(-10.500000, -7.000000) translate(8.000000, 0.000000)">
                        <path
                          d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div class="
                    input-block
                    swap
                    d-flex
                    align-items-center
                    justify-content-between
                  ">
              <div class="inner d-flex align-items-center justify-content-center">
                <input readonly v-model="tokenSwapCount" />
                <span class="token-symbol">
                  {{ tokenSelectInfo[tokenSelectIndex].symbol }}
                </span>
              </div>
            </div>
            <div class="
                    balance-block
                    d-flex
                    align-items-center
                    justify-content-end
                  ">
              <el-checkbox v-model="depositToGame" size="small">{{
                $t("Dialog.deposit-to-game")
              }}</el-checkbox>
            </div>
          </template>
          <div class="infotip-block flex flex-column align-items-center">
            <span>{{ $t("Dialog.your-token-will-be-deposited-to") }}</span>
            <div class="d-flex align-items-center">
              <span>{{ $t("Dialog.your-game-account")
              }}{{ $t("Common.colon") }}</span>
              <span class="yellow">{{ gameAccount }}</span>
            </div>
          </div>
          <template v-if="tokenSelectIndex === 0 && depositGoldType === 1">
            <button class="button-buy ucmodal" :disabled="inputAmount.trim().length === 0" @click="swap()">
              {{ inputAmount }} {{ tokenUsdtInfo.symbol }}
            </button>
          </template>
          <template v-else>
            <button class="button-buy ucmodal" :disabled="inputAmount.trim().length === 0"
              @click="deposit(tokenSelectIndex)">
              {{ inputAmount }} {{ tokenSelectInfo[tokenSelectIndex].symbol }}
            </button>
          </template>
        </div>
      </div>
      <div v-else-if="modalShowIndex === 1" class="
              mask-nft
              usercentercontent
              d-flex
              flex-column
              justify-content-center
              align-items-center
            ">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <span class="title">Withdraw {{ tokenSelectInfo[tokenSelectIndex].symbol }}</span>
          <span class="token-title">
            {{ $t("Dialog.withdraw-token") }}
          </span>
          <div class="token-name d-flex align-items-center">
            <span class="text">{{
              $t("Dialog.action-token-by-symbol", [
                tokenSelectInfo[tokenSelectIndex].symbol,
              ])
            }}</span>
          </div>
          <div class="balance-block d-flex align-items-center justify-content-end">
            <span class="title">{{ $t("Dialog.available") }}{{ $t("Common.colon") }}</span>
            <span class="title yellow">
              {{ availableMCWithdraw }}
              {{ tokenSelectInfo[tokenSelectIndex].symbol }}
            </span>
          </div>
          <div class="
                  input-block
                  d-flex
                  align-items-center
                  justify-content-between
                ">
            <div class="inner d-flex align-items-center justify-content-center">
              <input @input="watchInput(tokenSelectIndex)" v-model="inputAmount" />
              <span @click="maxInput(tokenSelectIndex)" class="max">MAX</span>
              <span class="token-symbol">
                {{ tokenSelectInfo[tokenSelectIndex].symbol }}
              </span>
            </div>
          </div>
          <button class="button-buy ucmodal" @click="withdraw(-1, 1)">
            {{ availableMCWithdraw }}
            {{ tokenSelectInfo[tokenSelectIndex].symbol }}
          </button>
        </div>
      </div>
      <div v-else-if="modalShowIndex === 2" class="
              mask-nft
              usercentercontent
              d-flex
              flex-column
              justify-content-center
              align-items-center
            ">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <div class="title">
            <span>{{ tokenSwapSrcInfo.symbol }}</span>

            <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="swap-gold" transform="translate(-953.000000, -265.000000)" fill="#FFFFFF" fill-rule="nonzero">
                  <g transform="translate(760.000000, 240.000000)">
                    <g
                      transform="translate(200.000000, 31.500000) rotate(90.000000) translate(-200.000000, -31.500000) translate(193.500000, 24.500000)">
                      <g transform="translate(2.500000, 7.000000) scale(-1, 1) translate(-2.500000, -7.000000) ">
                        <path
                          d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                        </path>
                      </g>
                      <g
                        transform="translate(10.500000, 7.000000) scale(1, -1) translate(-10.500000, -7.000000) translate(8.000000, 0.000000)">
                        <path
                          d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                        </path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <span>{{ tokenSwapDstInfo.symbol }}</span>
          </div>
          <div class="
                  input-block
                  d-flex
                  align-items-center
                  justify-content-between
                ">
            <div class="inner d-flex align-items-center justify-content-center">
              <input @input="watchInput(tokenSelectIndex)" v-model="inputAmount" />
              <span @click="maxInput(tokenSelectIndex)" class="max">{{
                $t("Common.max")
              }}</span>
              <span class="token-symbol">
                {{ tokenSwapSrcInfo.symbol }}
              </span>
            </div>
          </div>
          <div class="balance-block d-flex align-items-center justify-content-end">
            <span class="title">{{ $t("Dialog.balance") }}{{ $t("Common.colon") }}</span>
            <span class="title yellow">
              {{ tokenSwapSrcInfo.balance }} {{ tokenSwapSrcInfo.symbol }}
            </span>
          </div>
          <svg viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="deposit-busd" transform="translate(-954.000000, -583.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <g transform="translate(760.000000, 250.000000)">
                  <g transform="translate(194.000000, 333.000000)">
                    <g transform="translate(2.500000, 7.000000) scale(-1, 1) translate(-2.500000, -7.000000) ">
                      <path
                        d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                      </path>
                    </g>
                    <g
                      transform="translate(10.500000, 7.000000) scale(1, -1) translate(-10.500000, -7.000000) translate(8.000000, 0.000000)">
                      <path
                        d="M1.5263936,13.5015018 C1.5263936,13.7781411 1.74656003,14 2.01835841,14 L2.98280427,14 C3.11283149,14.0012817 3.23798075,13.9493546 3.33038478,13.8557327 C3.42278882,13.7621107 3.47477559,13.6345669 3.47476909,13.5015018 L3.47476909,3.52166883 L4.44457298,3.52166883 C4.9837859,3.52166883 5.16157515,3.16477924 4.83911902,2.72415019 L3.08509398,0.331594246 C2.75971528,-0.112025498 2.23901193,-0.109034803 1.91606869,0.331594246 L0.162530759,2.72415019 C-0.162847943,3.16776994 0.0164025977,3.52166884 0.556589708,3.52166883 L1.5263936,3.52166883 L1.5263936,13.5015018 Z">
                      </path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <div class="
                  input-block
                  swap
                  d-flex
                  align-items-center
                  justify-content-between
                ">
            <div class="inner d-flex align-items-center justify-content-center">
              <input readonly v-model="tokenSwapCount" />
              <span class="token-symbol">
                {{ tokenSwapDstInfo.symbol }}
              </span>
            </div>
          </div>
          <div class="balance-block d-flex align-items-center justify-content-end">
            <span class="title">Price:</span>
            <span class="title yellow">
              1{{ tokenSwapDstInfo.symbol }} = 10{{ tokenSwapSrcInfo.symbol }}
            </span>
          </div>
          <div class="balance-block d-flex align-items-center justify-content-end">
            <span class="title">{{ $t("Dialog.fee") }}{{ $t("Common.colon") }}</span>
            <span class="title yellow">
              {{ tokenSelectInfo[0].goldburnfeerate! * 100 }}%
            </span>
          </div>
          <button class="button-buy ucmodal" :disabled="inputAmount.trim().length === 0" @click="swap()">
            {{ inputAmount }} {{ tokenSwapSrcInfo.symbol }}
          </button>
        </div>
      </div>
      <div v-else-if="modalShowIndex === 3" class="
              mask-nft
              modalwindow
              d-flex
              flex-column
              justify-content-center
              align-items-center
            ">
        <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
          xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer">
          <path
            d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
            p-id="2960" fill="#ffffff" />
        </svg>
        <div class="dialog-content d-flex flex-column align-items-center">
          <div class="title">{{ $t("Dialog.notice") }}</div>
          <div style="word-break: break-word" class="infotip infotip-attention d-flex justify-content-center">
            {{ modalInfotip }}
          </div>
          <div style="margin-top: 40px" class="d-flex justify-content-center">
            <a class="go2login" href="/#/login">{{
              $t("Dialog.go-to-your-game-account-to-bind-your-wallet")
            }}</a>
          </div>
          <button class="button-buy nftinfo" style="margin-top: 40px; width: 130px" @click="closeModal()">
            OK
          </button>
        </div>
      </div>
      <modal-approve v-else-if="modalShowIndex === 4" :tokeninfo="getApproveTokenAndNeedAmount(tokenSelectIndex)"
        @confirm="approve(tokenSelectIndex)" @cancel="showModal(tokenOptType, tokenSelectIndex, '', false)" />
    </el-dialog>
    <div class="usercenter d-flex">
      <div v-if="!isMobile" class="left">
        <u-c-left></u-c-left>
      </div>
      <div class="right d-flex" :class="isMobile ? 'justify-content-center' : 'justify-content-start'">
        <div :class="isMobile ? 'mobile-content' : 'content usercenterright'">
          <div class="
                  title-block
                  d-flex
                  justify-content-between
                  align-items-center
                ">
            <span class="title">{{ $t("UserCenter.my-account") }}</span>
          </div>
          <div class="assets-block">
            <div class="item d-flex flex-column align-items-center">
              <div @click="copyText(tokenSelectInfo[0].address)"
                class="contract d-flex align-items-center justify-content-end">
                <div>
                  {{ $t("UserCenter.contract") }}{{ $t("Common.colon") }}
                  {{
                    tokenSelectInfo[0].address.substr(0, 6) +
                    "..." +
                    tokenSelectInfo[0].address.substr(
                      tokenSelectInfo[0].address.length - 4,
                      4
                    )
                  }}
                </div>

                <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="user-center" transform="translate(-319.000000, -118.000000)" stroke="#C7CAD5">
                      <g transform="translate(190.000000, 119.000000)">
                        <g transform="translate(130.000000, 0.000000)">
                          <rect x="0" y="0" width="6" height="8" rx="1"></rect>
                          <rect fill="#464454" x="4" y="3" width="6" height="8" rx="1"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="
                      balance-block
                      d-flex
                      align-items-center
                      justify-content-between
                    ">
                <img src="/static/images/uc_token_gold.png" />
                <div class="
                        right
                        d-flex
                        align-items-baseline
                        justify-content-start
                      ">
                  <div class="balance">
                    {{ parseFloat(tokenBalance[0]).toFixed(2) }}
                  </div>
                  <div class="symbol">{{ tokenSelectInfo[0].symbol }}</div>
                </div>
              </div>
              <div class="
                      action-block
                      d-flex
                      align-items-center
                      justify-content-end
                    ">
                <button @click="showModal(0, 0)" :disabled="buttonDisabled">
                  {{ $t("UserCenter.button-deposit") }}
                </button>
                <button @click="showModal(2, 0)" :disabled="buttonDisabled">
                  {{ $t("UserCenter.button-swap") }}
                </button>
              </div>
            </div>
            <div class="item d-flex flex-column align-items-center">
              <div @click="copyText(tokenSelectInfo[1].address)"
                class="contract d-flex align-items-center justify-content-end">
                <div>
                  {{ $t("UserCenter.contract") }}{{ $t("Common.colon") }}
                  {{
                    tokenSelectInfo[1].address.substr(0, 6) +
                    "..." +
                    tokenSelectInfo[1].address.substr(
                      tokenSelectInfo[1].address.length - 4,
                      4
                    )
                  }}
                </div>

                <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="user-center" transform="translate(-319.000000, -118.000000)" stroke="#C7CAD5">
                      <g transform="translate(190.000000, 119.000000)">
                        <g transform="translate(130.000000, 0.000000)">
                          <rect x="0" y="0" width="6" height="8" rx="1"></rect>
                          <rect fill="#464454" x="4" y="3" width="6" height="8" rx="1"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="
                      balance-block
                      d-flex
                      align-items-center
                      justify-content-between
                    ">
                <img src="/static/images/uc_token_mc.png" />
                <div class="
                        right
                        d-flex
                        align-items-baseline
                        justify-content-start
                      ">
                  <div class="balance">
                    {{ parseFloat(tokenBalance[1]).toFixed(2) }}
                  </div>
                  <div class="symbol">{{ tokenSelectInfo[1].symbol }}</div>
                </div>
              </div>
              <div class="
                      action-block
                      d-flex
                      align-items-center
                      justify-content-end
                    ">
                <button @click="showModal(0, 1)" :disabled="buttonDisabled">
                  {{ $t("UserCenter.button-deposit") }}
                </button>
                <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' +
                  tokenSelectInfo[1].address
                  " target="_blank" class="d-flex"><button style="margin-right: 0" :disabled="buttonDisabled">
                    {{ $t("UserCenter.button-buy") }}
                  </button></a>
              </div>
            </div>
            <div class="item d-flex flex-column align-items-center">
              <div @click="copyText(tokenSelectInfo[2].address)"
                class="contract d-flex align-items-center justify-content-end">
                <div>
                  {{ $t("UserCenter.contract") }}{{ $t("Common.colon") }}
                  {{
                    tokenSelectInfo[2].address.substr(0, 6) +
                    "..." +
                    tokenSelectInfo[2].address.substr(
                      tokenSelectInfo[2].address.length - 4,
                      4
                    )
                  }}
                </div>
                <svg width="12px" height="13px" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="user-center" transform="translate(-319.000000, -118.000000)" stroke="#C7CAD5">
                      <g transform="translate(190.000000, 119.000000)">
                        <g transform="translate(130.000000, 0.000000)">
                          <rect x="0" y="0" width="6" height="8" rx="1"></rect>
                          <rect fill="#464454" x="4" y="3" width="6" height="8" rx="1"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="
                      balance-block
                      d-flex
                      align-items-center
                      justify-content-between
                    ">
                <img src="/static/images/uc_token_tap.png" />
                <div class="
                        right
                        d-flex
                        align-items-baseline
                        justify-content-start
                      ">
                  <div class="balance">
                    {{ parseFloat(tokenBalance[2]).toFixed(2) }}
                  </div>
                  <div class="symbol">{{ tokenSelectInfo[2].symbol }}</div>
                </div>
              </div>
              <div class="action-block d-flex align-items-center">
                <button @click="showModal(0, 2)" :disabled="buttonDisabled">
                  {{ $t("UserCenter.button-deposit") }}
                </button>
                <a :href="'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=' +
                  tokenSelectInfo[2].address
                  " target="_blank" class="d-flex"><button style="margin-right: 0" :disabled="buttonDisabled">
                    {{ $t("UserCenter.button-buy") }}
                  </button></a>
              </div>
            </div>
          </div>
          <div v-if="tradeListMC.list.length > 0 && !isLoading" class="
                  title-block
                  d-flex
                  justify-content-between
                  align-items-center
                ">
            <span class="title">MC Withdraw</span>
            <div class="d-flex justify-content-between align-items-center">
              <div class="mc-available">
                <span class="number">
                  <template v-if="!isMobile">
                    {{ $t("UserCenter.available") }}{{ $t("Common.colon") }}
                  </template>
                  {{ availableMCWithdraw }}</span>
                <span class="symbol">MC</span>
              </div>
              <button @click="withdraw(-1, 1)" :disabled="availableMCWithdraw === 0 || buttonDisabled">
                {{ $t("Dialog.button-withdraw") }}
              </button>
            </div>
          </div>
          <template v-if="!isMobile">
            <div v-if="tradeListMC.list.length > 0" class="trade-record">
              <div class="
                      title-block
                      d-flex
                      justify-content-between
                      align-items-center
                    ">
                <div class="col-time wrap">
                  {{ $t("UserCenter.time") }}
                </div>
                <div class="col-amount">
                  {{ $t("UserCenter.amount") }}
                </div>
                <div class="col-amount">
                  {{ $t("UserCenter.available") }}
                </div>
                <div class="col-state">
                  {{ $t("UserCenter.status") }}
                </div>
                <div class="col-progress">{{ $t("UserCenter.progress") }}</div>
                <div class="col-time wrap">
                  {{ $t("UserCenter.unlock-time") }}
                </div>
                <div class="col-action">{{ $t("UserCenter.action") }}</div>
              </div>
              <template v-if="tradeListMC.list.length > 0">
                <div class="
                        data-item
                        d-flex
                        justify-content-between
                        align-items-center
                      " v-for="(ele, index) in tradeListMC.list" :key="'trade-mclist-' + index">
                  <div class="col-time wrap">
                    {{ getTime(ele["timestamp"]) }}
                  </div>
                  <div class="col-amount">
                    {{
                      ele["completed"]
                      ? ele["getamount"].toFixed(4)
                      : ele["amount"]
                    }}
                    {{ ele["symbol"] }}
                  </div>
                  <div class="col-amount">
                    {{ ele["completed"] ? "0" : getWithdrawAvailable(index) }}
                    {{ ele["symbol"] }}
                  </div>
                  <div class="col-state">
                    {{
                      ele["completed"]
                      ? "Completed"
                      : ele["processing"]
                        ? "Processing"
                        : "Locked"
                    }}
                  </div>
                  <div class="
                          col-progress
                          d-flex
                          align-items-center
                          justify-content-end
                        ">
                    <span>{{ mcWithdrawPercent(index) }}</span>
                    <div class="progress">
                      <div class="inner" :style="{
                        width: mcWithdrawPercent(index),
                      }"></div>
                    </div>
                  </div>
                  <div class="col-time wrap">
                    {{
                      ele["completed"]
                      ? "---"
                      : getTime(ele["timestamp"] + ele["locktime"])
                    }}
                  </div>
                  <div class="col-action">
                    <button v-if="!ele['completed']" round @click="withdraw(index, 1)" class="button-buy" style="
                            font-size: 12px;
                            height: 24px;
                            margin-top: 0;
                            padding: 0 10px;
                            width: auto;
                          " :disabled="buttonDisabled">
                      <template v-if="ele['timestamp'] + ele['locktime'] >
                        currentTime / 1000
                        ">
                        {{ $t("UserCenter.button-withdraw-advance") }}
                      </template>
                      <template v-else>
                        {{ $t("Dialog.button-withdraw") }}
                      </template>
                    </button>
                    <template v-else>{{
                      $t("UserCenter.withdraw-completed")
                    }}</template>
                  </div>
                </div>
                <pagination v-if="tradeListMC.recordCount > pagePerCountMC" @change-page="changePage"
                  :page-para="{ optType: 1, isMC: true }" :pagesize="pagePerCountMC" :total="tradeListMC.recordCount"
                  :page="tradeListMC.pageNow" />
              </template>
              <el-empty v-else :description="$t('UserCenter.no-mc-withdraw-trade-record')"></el-empty>
            </div>
          </template>
          <template v-else>
            <div v-if="tradeListMC.list.length > 0" class="trade-record d-flex flex-column"
              style="background: none; padding: 0">
              <template v-if="tradeListMC.list.length > 0">
                <div class="data-item-mobile" v-for="(ele, index) in tradeListMC.list" :key="'trade-mclist-' + index">
                  <div class="row-data">
                    <div class="text">Time</div>
                    <div class="text">{{ getTime(ele["timestamp"]) }}</div>
                  </div>
                  <div class="row-data">
                    <div class="text">Amount</div>
                    <div class="text">
                      {{ ele["amount"] }} {{ ele["symbol"] }}
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">Available</div>
                    <div class="text">
                      {{ ele["completed"] ? "0" : getWithdrawAvailable(index) }}
                      {{ ele["symbol"] }}
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">Status</div>
                    <div class="text">
                      {{
                        ele["completed"]
                        ? "Completed"
                        : ele["processing"]
                          ? "Processing"
                          : "Locked"
                      }}
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">Progress</div>
                    <div class="d-flex align-items-center">
                      <span class="text">{{ mcWithdrawPercent(index) }}</span>
                      <div class="progress">
                        <div class="inner" :style="{
                          width: mcWithdrawPercent(index),
                        }"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">Unlock Time</div>
                    <div class="text">
                      {{
                        ele["completed"]
                        ? "---"
                        : getTime(ele["timestamp"] + ele["locktime"])
                      }}
                    </div>
                  </div>
                  <div class="row-data">
                    <div class="text">Action</div>
                    <div class="text">
                      <button v-if="!ele['completed']" round @click="withdraw(index, 1)" class="button-buy" style="
                              font-size: 12px;
                              height: 24px;
                              line-height: 24px;
                              margin-top: 0;
                              padding: 0 10px;
                              width: auto;
                            " :disabled="buttonDisabled">
                        <template v-if="ele['timestamp'] + ele['locktime'] >
                          currentTime / 1000
                          ">
                          {{ $t("UserCenter.button-withdraw-advance") }}
                        </template>
                        <template v-else>
                          {{ $t("Dialog.button-withdraw") }}
                        </template>
                      </button>
                      <template v-else>Withdraw Completed</template>
                    </div>
                  </div>
                </div>
                <div v-if="tradeListMC.list.length < tradeListMC.recordCount &&
                  !isLoading
                  " class="d-flex justify-content-center loadmore">
                  <div v-if="!tradeListMC.isLoadingMore" @click="getPageData(tradeListMC.pageNow + 1, 1, true)">
                    Load more data
                  </div>
                  <div v-else class="d-flex justify-content-center align-items-center">
                    <span>Loading more</span>
                    <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff" style="width: 16px; height: 16px; margin-left: 6px">
                      <path
                        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </template>
              <el-empty v-else description="No MC withdraw trade record"></el-empty>
            </div>
          </template>
          <div v-if="tradeListMC.list.length > 0" class="tip-block d-flex flex-column">
            <span class="tip">{{
              $t(
                "UserCenter.the-mc-withdrawal-lock-up-period-is-15-days-if-you-withdraw-in-advance-during-the-lock-up-period-a-30-withdrawal-fee-will-be-charged-if-the-lock-up-period-ends-there-will-be-no-withdrawal-fee"
              )
            }}</span>
            <span class="tip">
              {{ $t("UserCenter.please-claim-the-token-mc-in-game-firstly") }}
            </span>
          </div>
          <div v-if="tradeListOther.list.length > 0" class="
                  title-block
                  d-flex
                  justify-content-between
                  align-items-center
                ">
            <span class="title">{{
              $t("UserCenter.gold-and-tap-withdraw")
            }}</span>
          </div>
          <div v-if="tradeListOther.list.length > 0" class="trade-record">
            <template v-if="!isMobile">
              <div class="
                      title-block
                      d-flex
                      justify-content-between
                      align-items-center
                    ">
                <div class="col-time">Time</div>
                <div class="col-amount">Amount</div>
                <div class="col-time">Est. Deal Time</div>
                <div class="col-action">Trade Hash</div>
              </div>
              <template v-if="tradeListOther.list.length > 0">
                <div class="
                        data-item
                        d-flex
                        justify-content-between
                        align-items-center
                      " v-for="(ele, index) in tradeListOther.list" :key="'trade-list-other-' + index">
                  <div class="col-time">{{ getTime(ele["timestamp"]) }}</div>
                  <div class="col-amount">
                    {{ ele["amount"] }} {{ ele["symbol"] }}
                  </div>
                  <div class="col-time">
                    {{
                      ele["dealtime"] === 0
                      ? "Unknown"
                      : getTime(ele["dealtime"])
                    }}
                  </div>
                  <div class="col-action">
                    <span style="margin: 0" v-if="ele['processing']">dealing</span>
                    <a v-else :href="getTxLink(ele['txhash'])" target="_blank">View</a>
                  </div>
                </div>
                <pagination v-if="tradeListOther.recordCount > pagePerCount" @change-page="changePage"
                  :page-para="{ optType: 1, isMC: false }" :pagesize="pagePerCount" :total="tradeListOther.recordCount"
                  :page="tradeListOther.pageNow" />
              </template>
              <el-empty v-else description="No GOLD & TAP withdraw trade record"></el-empty>
            </template>
            <template v-else>
              <div class="
                      title-block
                      d-flex
                      justify-content-between
                      align-items-center
                    ">
                <div class="col-time">
                  <div class="text text9">{{ $t("UserCenter.time") }}</div>
                </div>
                <div class="col-amount">
                  <div class="text text9">{{ $t("UserCenter.amount") }}</div>
                </div>
                <div class="col-time">
                  <div class="text text9">
                    {{ $t("UserCenter.est-deal-time") }}
                  </div>
                </div>
                <div class="col-action">
                  <div class="text text9">
                    {{ $t("UserCenter.trade-hash") }}
                  </div>
                </div>
              </div>
              <template v-if="tradeListOther.list.length > 0">
                <div class="
                        data-item
                        d-flex
                        justify-content-between
                        align-items-center
                      " v-for="(ele, index) in tradeListOther.list" :key="'trade-list-other-' + index">
                  <div class="col-time">
                    <div class="text text10">
                      {{ getTime(ele["timestamp"]) }}
                    </div>
                  </div>
                  <div class="col-amount">
                    <div class="text text10">
                      {{ ele["amount"] }} {{ ele["symbol"] }}
                    </div>
                  </div>
                  <div class="col-time">
                    <div class="text text10">
                      {{
                        ele["dealtime"] === 0
                        ? "Unknown"
                        : getTime(ele["dealtime"])
                      }}
                    </div>
                  </div>
                  <div class="col-action">
                    <div style="margin: 0" class="text text10" v-if="ele['processing']">
                      {{ $t("UserCenter.dealing") }}
                    </div>
                    <a v-else :href="getTxLink(ele['txhash'])" target="_blank">
                      <div class="text text10">View</div>
                    </a>
                  </div>
                </div>
                <div v-if="tradeListOther.list.length < tradeListOther.recordCount &&
                  !isLoading
                  " class="d-flex justify-content-center loadmore">
                  <div v-if="!tradeListOther.isLoadingMore" @click="getPageData(tradeListOther.pageNow + 1, 1, false)">
                    {{ $t("Common.load-more-data") }}
                  </div>
                  <div v-else class="d-flex justify-content-center align-items-center">
                    <span>{{ $t("Common.loading-more") }}</span>
                    <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                      stroke="#ffffff" style="width: 16px; height: 16px; margin-left: 6px">
                      <path
                        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                        stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </template>
              <el-empty v-else description="No GOLD&TAP withdraw trade record"></el-empty>
            </template>
          </div>
          <div v-if="tradeListRecharge.list.length > 0" class="
                  title-block
                  d-flex
                  justify-content-between
                  align-items-center
                ">
            <span class="title">{{ $t("UserCenter.recharge") }}</span>
          </div>
          <div v-if="tradeListRecharge.list.length > 0" class="trade-record">
            <template v-if="!isMobile">
              <div class="
                      title-block
                      d-flex
                      justify-content-between
                      align-items-center
                    ">
                <div class="col-time">{{ $t("UserCenter.time") }}</div>
                <div class="col-amount">{{ $t("UserCenter.amount") }}</div>
                <div class="col-time">{{ $t("UserCenter.est-deal-time") }}</div>
                <div class="col-action">{{ $t("UserCenter.trade-hash") }}</div>
              </div>
              <template v-if="tradeListRecharge.list.length > 0">
                <div class="
                        data-item
                        d-flex
                        justify-content-between
                        align-items-center
                      " v-for="(ele, index) in tradeListRecharge.list" :key="'trade-list-other-' + index">
                  <div class="col-time">{{ getTime(ele["timestamp"]) }}</div>
                  <div class="col-amount">
                    {{ ele["amount"] }} {{ ele["symbol"] }}
                  </div>
                  <div class="col-time">
                    {{
                      ele["dealtime"] === 0
                      ? "Unknown"
                      : getTime(ele["dealtime"])
                    }}
                  </div>
                  <div class="col-action">
                    <span style="margin: 0" v-if="ele['processing']">{{
                      $t("UserCenter.dealing")
                    }}</span>
                    <a v-else :href="getTxLink(ele['txhash'])" target="_blank">{{ $t("UserCenter.view") }}</a>
                  </div>
                </div>
                <pagination v-if="tradeListRecharge.recordCount > pagePerCount" @change-page="changePage"
                  :page-para="{ optType: 0, isMC: false }" :pagesize="pagePerCount" :total="tradeListRecharge.recordCount"
                  :page="tradeListRecharge.pageNow" />
              </template>
              <el-empty v-else description="No recharge trade record"></el-empty>
            </template>
            <template v-else>
              <div class="
                      title-block
                      d-flex
                      justify-content-between
                      align-items-center
                    ">
                <div class="col-time">
                  <div class="text text9">{{ $t("UserCenter.time") }}</div>
                </div>
                <div class="col-amount">
                  <div class="text text9">{{ $t("UserCenter.amount") }}</div>
                </div>
                <div class="col-time">
                  <div class="text text9">
                    {{ $t("UserCenter.est-deal-time") }}
                  </div>
                </div>
                <div class="col-action">
                  <div class="text text9">
                    {{ $t("UserCenter.trade-hash") }}
                  </div>
                </div>
              </div>
              <div class="
                      data-item
                      d-flex
                      justify-content-between
                      align-items-center
                    " v-for="(ele, index) in tradeListRecharge.list" :key="'trade-list-other-' + index">
                <div class="col-time">
                  <div class="text text10">{{ getTime(ele["timestamp"]) }}</div>
                </div>
                <div class="col-amount">
                  <div class="text text10">
                    {{ ele["amount"] }} {{ ele["symbol"] }}
                  </div>
                </div>
                <div class="col-time">
                  <div class="text text10">
                    {{
                      ele["dealtime"] === 0
                      ? "Unknown"
                      : getTime(ele["dealtime"])
                    }}
                  </div>
                </div>
                <div class="col-action">
                  <div class="text text10" style="margin: 0" v-if="ele['processing']">
                    {{ $t("UserCenter.dealing") }}
                  </div>
                  <a v-else :href="getTxLink(ele['txhash'])" target="_blank">
                    <div class="text text10">{{ $t("UserCenter.view") }}</div>
                  </a>
                </div>
              </div>
              <div v-if="tradeListRecharge.list.length <
                tradeListRecharge.recordCount && !isLoading
                " class="d-flex justify-content-center loadmore">
                <div v-if="!tradeListRecharge.isLoadingMore"
                  @click="getPageData(tradeListRecharge.pageNow + 1, 0, false)">
                  {{ $t("Common.load-more-data") }}
                </div>
                <div v-else class="d-flex justify-content-center align-items-center">
                  <span>{{ $t("Common.loading-more") }}</span>
                  <svg class="rotate-circle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffffff" style="width: 16px; height: 16px; margin-left: 6px">
                    <path
                      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ethers } from "ethers";
import { aggregate as aggregateInventory } from "@makerdao/multicall";
import { ElSelect, ElOption, ElMessage } from "element-plus";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  getCurrentInstance,
  toRefs,
  watch,
  inject,
  nextTick,
} from "vue";
import UCLeft from "@/components/UCLeft.vue";
import Pagination from "../components/Pagination.vue";
import { CreateNotification } from "../components/thirdparty/vue3-toast/notifications";
import TOOL from "../utils/tool";
import * as API from "../constants/api";
import axios from "../utils/axios";
import { getWalletProvider } from "../utils/provider";
import TapDesktop from "../abis/TapDesktop.json";
import ERC20 from "../abis/ERC20.json";
import BLOCKCHAIN_CONFIG from "../constants/blockchain_config";
import moment from "moment";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import LogUtils from "@/utils/LogUtils";
import { ElLoading } from "element-plus"

export default defineComponent({
  name: "UserCenter",
  props: {
    walletAddress: {
      type: String,
      default: "",
    },
    isReady: {
      type: Boolean,
      default: false
    },
    isRefresh: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UCLeft,
    Pagination,
    ElSelect,
    ElOption,
  },
  setup(props, ctx) {
    watch(
      () => props.walletAddress,
      (newWalletAddr, oldWalletAddr) => {
        // LogUtils.debug(newWalletAddr)
        // LogUtils.debug(oldWalletAddr)
        if (!isLoadingInit.value) {
          if (newWalletAddr !== oldWalletAddr) {
            LogUtils.debug('load from change wallet...')
            loadData()
          }
        }
      },
    )

    watch(
      () => props.isRefresh,
      (newReady, oldReady) => {
        LogUtils.debug(newReady)
        LogUtils.debug(oldReady)
        if (newReady && newReady !== oldReady) {
          if (props.walletAddress.length === 0) {
            buttonDisabled.value = true
          } else {
            buttonDisabled.value = false
          }
          LogUtils.debug('load from ready...')
          loadData()
        }
      }
    )

    const { t } = useI18n();
    const createNotification = <CreateNotification>(
      inject("create-notification")
    );
    const router = useRouter();
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus;
    const BigNumber =
      getCurrentInstance()?.appContext.config.globalProperties.$BigNumber;
    const pageChainConfig: any = {};
    const isLoading = ref(true);
    const isLoadingInit = ref(true)
    const buttonDisabled = ref(false);
    const isMobile = ref(false);
    const isApproveAfterModal = ref(false);
    const isShowModal = ref(false);
    const modalShowIndex = ref(-1);
    const modalInfotip = ref("");
    const gameAccount = ref("");
    const tokenSelectIndex = ref(0);
    const tokenOptType = ref(0);
    const depositGoldType = ref(0);
    const availableMCWithdraw = ref(0);
    const tokenBalance = ref(["0", "0", "0"]);
    const usdtBalance = ref("0");
    const tokenApprove = ref([0, 0, 0]);
    const usdtApprove = ref(0);
    const tokenSwapCount = ref(0);
    const tokenAvailable = ref(["0", "0", "0"]);
    const goldburnfeerate = ref(0);
    const tokenUsdtInfo = ref({
      address: "",
      decimals: -1,
      symbol: "",
      tokenid: -1,
    });
    const tokenSelectInfo = ref([
      {
        gold: "",
        usdt: "",
        desktop: "",
        goldmintrate: 0,
        goldburnfeerate: 0,
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
      {
        mc: "",
        desktop: "",
        nftingame: "",
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
      {
        tap: "",
        desktop: "",
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
    ]);
    const tokenSwapSrcInfo = ref({
      address: "",
      decimals: -1,
      symbol: "",
      tokenid: -1,
      balance: "",
      approve: 0,
      desktop: "",
    });
    const tokenSwapDstInfo = ref({
      address: "",
      decimals: -1,
      symbol: "",
      tokenid: -1,
      balance: "",
      approve: 0,
      desktop: "",
    });
    const tradeListMC = ref({
      list: [] as any[],
      pageCount: 0,
      recordCount: 0,
      pageNow: 1,
      isLoadingMore: false,
    });
    const tradeListOther = ref({
      list: [],
      pageCount: 0,
      recordCount: 0,
      pageNow: 1,
      isLoadingMore: false,
    });
    const tradeListRecharge = ref({
      list: [],
      pageCount: 0,
      recordCount: 0,
      pageNow: 1,
      isLoadingMore: false,
    });

    const pagePerCount = ref(10);
    const pagePerCountMC = ref(100);

    const inputAmount = ref("");
    const depositToGame = ref(true);
    const currentTime = ref(0);
    let updateTimeTask: NodeJS.Timer | null = null;
    let chainId = -1;
    const chainSymbol = "";
    let assetsAPIInfo = {
      goldtoken: {
        gold: "",
        usdt: "",
        desktop: "",
        goldmintrate: 0,
        goldburnfeerate: 0,
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
      mctoken: {
        mc: "",
        desktop: "",
        nftingame: "",
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
      taptoken: {
        tap: "",
        desktop: "",
        symbol: "",
        decimals: 0,
        address: "",
        tokenid: 0,
      },
      wallet: { accountid: 0, address: "", nickname: "" },
    };

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC();
      BUS.$on("RESIZE_WINDOW", (para: any) => {
        isMobile.value = para.isMobile;
      });
      if (props.isRefresh && isLoadingInit) {
        LogUtils.debug('load from onMounted...')
        loadData()
      }
    });

    onUnmounted(() => {
      if (updateTimeTask !== null) {
        clearInterval(Number(updateTimeTask));
        updateTimeTask = null;
      }
    });

    const mcWithdrawPercent = (index: number) => {
      const ele: any = tradeListMC.value.list[index];
      const percent =
        (1 -
          (ele["timestamp"] + ele["locktime"] - currentTime.value / 1000) /
          ele["locktime"]) *
        100;
      if (percent > 0 && percent < 100) {
        return percent.toFixed(2) + "%";
      } else {
        if (percent <= 0) {
          return "0%";
        } else {
          return "100%";
        }
      }
    };

    const copyText = (content: string) => {
      toClipboard(content);
      ElMessage({
        message: t("Infotip.contract-address-copied"),
        type: "success",
      });
    };

    const changeDepositGoldType = (index: number) => {
      depositGoldType.value = index;
      // if (index === 1) {
      //   depositToGame.value = true
      // }
      inputAmount.value = "";
      refreshTokenSelectData(0, 0);
    };

    const getTxLink = (tx: string) => {
      let result = "";
      try {
        result = pageChainConfig.chainBrowserConfig + tx;
      } catch (e) {
        result = "";
      }
      return result;
    };

    const getTime = (time: number) => {
      return moment(time * 1000)
        .utc()
        .format("MM/DD/yyyy HH:mm:ss z");
    };

    const showModal = (
      index: number,
      tokenIndex: number,
      tip: string = "",
      resetInput: boolean = true
    ) => {
      if (tokenIndex > -1) {
        if (
          (typeof gameAccount.value === "undefined" ||
            gameAccount.value.length === 0) &&
          depositToGame.value &&
          index === 0
        ) {
          closeModal();
          showModal(
            3,
            -1,
            `${t("Infotip.deposit-token-usercenter", [
              t("Infotip.action-deposit"),
              tokenSelectInfo.value[tokenIndex].symbol,
              t("Common.error"),
            ])}${t("Common.colon")} ${t(
              "Infotip.bind-your-game-account-to-wallet-address-first"
            )}`
          );
          return;
        }
      }

      if (tip.length > 0) {
        modalInfotip.value = tip;
      }
      modalShowIndex.value = index;
      tokenSelectIndex.value = tokenIndex;

      if (index === 0) {
        refreshTokenSelectData(tokenIndex, 0);
        if (resetInput) {
          depositGoldType.value = 0;
          tokenOptType.value = 0;
          inputAmount.value = "";
        }
      } else if (index === 2) {
        refreshTokenSelectData(tokenIndex, 2);
        if (resetInput) {
          tokenOptType.value = 2;
          tokenSwapCount.value = 0;
          inputAmount.value = "";
        }
      }

      isShowModal.value = true;
    };

    const closeModal = () => {
      isShowModal.value = false;
      tokenSelectIndex.value = -1;
      modalShowIndex.value = -1;
      modalInfotip.value = "";
      isApproveAfterModal.value = false;
      buttonDisabled.value = false;
    };

    const closeModal1 = () => {
      isShowModal.value = false;
      isApproveAfterModal.value = true;
    };

    /**
     * custom page depart change
     */
    const changePage = (page: number, pagePara: any) => {
      // LogUtils.debug(page)
      // LogUtils.debug(pagePara)
      getPageData(page, pagePara["optType"], pagePara["isMC"]);
      // getPageData(page)
    };

    const swapTokenDirection = () => {
      const src = tokenSwapSrcInfo.value;
      const dst = tokenSwapDstInfo.value;

      tokenSwapSrcInfo.value = dst;
      tokenSwapDstInfo.value = src;

      inputAmount.value = "";
      tokenSwapCount.value = 0;
    };

    const getPageData = async (
      page: number,
      optType: number = 0,
      isMC: boolean = false
    ) => {
      const { walletAddress } = toRefs(props);

      if (walletAddress.value.length > 0) {
        if (isMobile.value) {
          if (optType === 1) {
            if (isMC) {
              tradeListMC.value.isLoadingMore = true;
            } else {
              tradeListOther.value.isLoadingMore = true;
            }
          } else {
            tradeListRecharge.value.isLoadingMore = true;
          }
        }
        const url =
          optType === 0
            ? API.assetsListRecharge
            : isMC
              ? API.assetsListDrawMC
              : API.assetsListDraw;

        const urlAssetsTradeList = `${url}${chainId}/${walletAddress.value}/${page - 1
          }/${isMC ? pagePerCountMC.value : pagePerCount.value}/`;
        const tradeListData = (await axios.get(urlAssetsTradeList)).data.data;

        LogUtils.debug(tradeListData)

        if (tradeListData) {
          if (optType === 1) {
            if (isMC) {
              if (!isMobile) {
                tradeListMC.value.list = tradeListData.list;
              } else {
                if (page === 1) {
                  tradeListMC.value.list = tradeListData.list;
                } else {
                  tradeListMC.value.list = tradeListMC.value.list.concat(
                    tradeListData.list
                  );
                }
              }

              tradeListMC.value.recordCount = tradeListData.count;
              tradeListMC.value.pageCount = TOOL.getPageCount(
                pagePerCountMC.value,
                tradeListData.count
              );
              tradeListMC.value.pageNow = page;
            } else {
              if (!isMobile.value) {
                tradeListOther.value.list = tradeListData.list;
              } else {
                if (page === 1) {
                  tradeListOther.value.list = tradeListData.list;
                } else {
                  tradeListOther.value.list = tradeListOther.value.list.concat(
                    tradeListData.list
                  );
                }
              }

              tradeListOther.value.recordCount = tradeListData.count;
              tradeListOther.value.pageCount = TOOL.getPageCount(
                pagePerCount.value,
                tradeListData.count
              );
              tradeListOther.value.pageNow = page;
            }
          } else {
            if (!isMobile.value) {
              tradeListRecharge.value.list = tradeListData.list;
            } else {
              if (page === 1) {
                tradeListRecharge.value.list = tradeListData.list;
              } else {
                tradeListRecharge.value.list = tradeListRecharge.value.list.concat(
                  tradeListData.list
                );
              }
            }

            tradeListRecharge.value.recordCount = tradeListData.count;
            tradeListRecharge.value.pageCount = TOOL.getPageCount(
              pagePerCount.value,
              tradeListData.count
            );
            tradeListRecharge.value.pageNow = page;
          }
          // tradeList.value = tradeListData.list

          if (isMobile.value) {
            if (optType === 1) {
              if (isMC) {
                tradeListMC.value.isLoadingMore = false;
              } else {
                tradeListOther.value.isLoadingMore = false;
              }
            } else {
              tradeListRecharge.value.isLoadingMore = false;
            }
          }
        }
      }
    };

    const refreshTokenSelectData = async (
      index: number,
      optTypeIndex: number
    ) => {
      // if (tokenSelectIndex.value !== index || tokenOptType.value !== optTypeIndex) {
      //   inputAmount.value = '0'
      //   tokenBalance.value[index] = '0'
      //   tokenAvailable.value[index] = '0'
      //   depositGoldType.value = 0
      //   tokenSwapCount.value = 0
      // }
      const tokenInfo = tokenSelectInfo.value[index];
      const { walletAddress } = toRefs(props);

      if (walletAddress.value.length > 0) {
        let calls: any[] = [];
        let returnValue = null;

        calls = calls.concat([
          {
            target: tokenInfo.address,
            call: ["balanceOf(address)(uint256)", walletAddress.value],
            returns: [["TOKEN_BALANCE", (val: any) => val]],
          },
          {
            target: tokenInfo.address,
            call: [
              "allowance(address,address)(uint256)",
              walletAddress.value,
              tokenSelectInfo.value[index].desktop,
            ],
            returns: [
              ["TOKEN_APPROVE", (val: any) => val / 10 ** tokenInfo.decimals],
            ],
          },
        ]);

        if (
          optTypeIndex === 2 ||
          (index === 0 && depositGoldType.value === 1)
        ) {
          calls = calls.concat([
            {
              target: tokenUsdtInfo.value.address,
              call: ["balanceOf(address)(uint256)", walletAddress.value],
              returns: [["USDT_BALANCE", (val: any) => val]],
            },
            {
              target: tokenUsdtInfo.value.address,
              call: [
                "allowance(address,address)(uint256)",
                walletAddress.value,
                tokenSelectInfo.value[index].desktop,
              ],
              returns: [["USDT_APPROVE", (val: any) => val / 10 ** 18]],
            },
          ]);
        }

        returnValue = await aggregateInventory(
          calls,
          pageChainConfig.multicallConfig
        );
        tokenBalance.value[index] = TOOL.scientificToNumber(
          new BigNumber(
            returnValue.results.transformed.TOKEN_BALANCE.toString()
          )
            .div(10 ** tokenInfo.decimals)
            .toString()
        );
        tokenApprove.value[index] =
          returnValue.results.transformed.TOKEN_APPROVE;

        if (
          optTypeIndex === 2 ||
          (index === 0 && depositGoldType.value === 1)
        ) {
          usdtBalance.value = TOOL.scientificToNumber(
            new BigNumber(
              returnValue.results.transformed.USDT_BALANCE.toString()
            )
              .div(10 ** 18)
              .toString()
          );
          usdtApprove.value = returnValue.results.transformed.USDT_APPROVE;

          const srcInfo = {
            address: tokenUsdtInfo.value.address,
            decimals: tokenUsdtInfo.value.decimals,
            symbol: tokenUsdtInfo.value.symbol,
            tokenid: tokenUsdtInfo.value.tokenid,
            balance: usdtBalance.value,
            approve: usdtApprove.value,
            desktop: tokenInfo.desktop,
          };

          const dstInfo = {
            address: tokenInfo.address,
            decimals: tokenInfo.decimals,
            symbol: tokenInfo.symbol,
            tokenid: tokenInfo.tokenid,
            balance: tokenBalance.value[index],
            approve: tokenApprove.value[index],
            desktop: tokenInfo.desktop,
          };

          if (optTypeIndex === 2) {
            tokenSwapSrcInfo.value = dstInfo;
            tokenSwapDstInfo.value = srcInfo;
          } else {
            tokenSwapSrcInfo.value = srcInfo;
            tokenSwapDstInfo.value = dstInfo;
          }
        }
      }
    };

    const getWithdrawAvailable = (index: number) => {
      if (currentTime.value > 0) {
        const item = tradeListMC.value.list[index];
        const timeCur = currentTime.value;
        if (item["timestamp"] * 1000 + item["locktime"] * 1000 <= timeCur) {
          return item["amount"];
        } else {
          const fee =
            (item["amount"] *
              item["feerate"] *
              (item["timestamp"] * 1000 + item["locktime"] * 1000 - timeCur)) /
            (item["locktime"] * 1000);
          return Number(item["amount"] - fee).toFixed(4);
        }
      } else {
        return "---";
      }
    };

    const getApproveTokenAndNeedAmount = (tokenIndex: number) => {
      let tokenInfo: {
        address: string;
        decimals: number;
        symbol: string;
        tokenid: number;
        desktop: string;
      };
      if (tokenOptType.value === 2) {
        tokenInfo = tokenSwapSrcInfo.value;
      } else if (tokenOptType.value === 0) {
        if (tokenIndex === 0) {
          if (depositGoldType.value === 1) {
            tokenInfo = tokenSwapSrcInfo.value;
          } else {
            tokenInfo = tokenSelectInfo.value[tokenIndex];
          }
        } else {
          tokenInfo = tokenSelectInfo.value[tokenIndex];
        }
      } else {
        tokenInfo = tokenSelectInfo.value[tokenIndex];
      }
      const amount = Number(inputAmount.value);

      return {
        symbol: tokenInfo.symbol,
        amount: new BigNumber(amount).toNumber()
      }
    }

    const checkApprove = async (tokenIndex: number) => {
      let tokenInfo: {
        address: string;
        decimals: number;
        symbol: string;
        tokenid: number;
        desktop: string;
      };
      if (tokenOptType.value === 2) {
        tokenInfo = tokenSwapSrcInfo.value;
      } else if (tokenOptType.value === 0) {
        if (tokenIndex === 0) {
          if (depositGoldType.value === 1) {
            tokenInfo = tokenSwapSrcInfo.value;
          } else {
            tokenInfo = tokenSelectInfo.value[tokenIndex];
          }
        } else {
          tokenInfo = tokenSelectInfo.value[tokenIndex];
        }
      } else {
        tokenInfo = tokenSelectInfo.value[tokenIndex];
      }
      const contractAddr = tokenInfo.desktop

      const { walletAddress } = toRefs(props)
      if (walletAddress.value.length > 0) {
        const provider = await getWalletProvider()
        if (provider) {
          const signer = provider.getSigner()
          const contractToken = new ethers.Contract(
            tokenInfo.address,
            ERC20,
            signer
          )
          const allowance = await contractToken.allowance(walletAddress.value, contractAddr)
          const allowanceNumber = Number(ethers.utils.formatEther(allowance))
          const needAmount = getApproveTokenAndNeedAmount(tokenIndex).amount
          if (Number(allowanceNumber) < needAmount) {
            return false
          } else {
            return true
          }
        }
      }
      return false
    }

    const approve = async (tokenIndex: number) => {
      let tokenInfo: {
        address: string;
        decimals: number;
        symbol: string;
        tokenid: number;
        desktop: string;
      };
      let isSwap = false;
      if (tokenOptType.value === 2) {
        tokenInfo = tokenSwapSrcInfo.value;
        isSwap = true;
      } else if (tokenOptType.value === 0) {
        if (tokenIndex === 0) {
          if (depositGoldType.value === 1) {
            tokenInfo = tokenSwapSrcInfo.value;
            isSwap = true;
          } else {
            tokenInfo = tokenSelectInfo.value[tokenIndex];
          }
        } else {
          tokenInfo = tokenSelectInfo.value[tokenIndex];
        }
      } else {
        tokenInfo = tokenSelectInfo.value[tokenIndex];
      }

      TOOL.gtmTrack(
        "approve-button",
        isMobile.value ? "mobile" : "pc",
        "click",
        `${router.currentRoute.value.meta.gtm}`,
        `approve-request-${tokenInfo.symbol}`
      );

      const { walletAddress } = toRefs(props);
      if (walletAddress.value.length > 0) {
        const provider = await getWalletProvider();
        if (provider) {
          const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString();
          const signer = provider.getSigner();
          const contractToken = new ethers.Contract(
            tokenInfo.address,
            ERC20,
            signer
          );
          const uuid = createNotification({
            message: t("Infotip.approving-token", { symbol: tokenInfo.symbol }),
            autoClose: false,
            canClose: false,
          });

          contractToken
            .approve(tokenInfo.desktop, amount)
            .then(async function (transaction: any) {
              transaction
                .wait()
                .then(async function (transaction: any) {
                  const approveBN = await contractToken.allowance(
                    walletAddress.value,
                    tokenInfo.desktop
                  );
                  if (tokenOptType.value === 2) {
                    tokenSwapDstInfo.value.approve =
                      approveBN / 10 ** tokenInfo.decimals;
                  } else if (
                    tokenOptType.value === 0 &&
                    depositGoldType.value === 1
                  ) {
                    tokenSwapSrcInfo.value.approve =
                      approveBN / 10 ** tokenInfo.decimals;
                    usdtApprove.value = approveBN / 10 ** tokenInfo.decimals;
                  } else {
                    tokenApprove.value[tokenIndex] =
                      approveBN / 10 ** tokenInfo.decimals;
                  }
                  BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                  TOOL.gtmTrack(
                    "approve-button",
                    isMobile.value ? "mobile" : "pc",
                    "click",
                    `${router.currentRoute.value.meta.gtm}`,
                    `approve-success-${tokenInfo.symbol}`
                  );
                  createNotification({
                    type: "success",
                    message: t("Infotip.approve-token-successful", {
                      symbol: tokenInfo.symbol,
                    }),
                  });
                  closeModal1();
                  if (isSwap) {
                    swap();
                  } else {
                    if (tokenOptType.value === 0) {
                      deposit(tokenIndex);
                    }
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                  TOOL.gtmTrack(
                    "approve-button",
                    isMobile.value ? "mobile" : "pc",
                    "click",
                    `${router.currentRoute.value.meta.gtm}`,
                    `approve-error-trade-${tokenInfo.symbol}`
                  );
                  BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                  createNotification({
                    type: "error",
                    message: t("Infotip.approve-token-error", {
                      symbol: tokenInfo.symbol,
                    }),
                  });
                });
            })
            .catch((error: any) => {
              console.error(error);
              TOOL.gtmTrack(
                "approve-button",
                isMobile.value ? "mobile" : "pc",
                "click",
                `${router.currentRoute.value.meta.gtm}`,
                `approve-error-request-${tokenInfo.symbol}`
              );
              BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
              createNotification({
                type: "error",
                message: t("Infotip.approve-token-error", {
                  symbol: tokenInfo.symbol,
                }),
              });
            });
        }
      }
    };

    const deposit = async (tokenIndex: number) => {
      if (tokenIndex === 0 && depositGoldType.value === 0) {
        if (parseFloat(inputAmount.value) < 1) {
          createNotification({
            type: "error",
            message: 'Gold deposit quantity must be greater than one',
          });
          return;
        }
      }

      const flagApprove = await checkApprove(tokenIndex)
      if (!flagApprove) {
        // createNotification({
        //   type: 'error',
        //   message: `Your token approve count is not enough`,
        // })
        showModal(4, tokenIndex)
        return
      }
      const tokenInfo = tokenSelectInfo.value[tokenIndex];
      TOOL.gtmTrack(
        "deposit-button",
        isMobile.value ? "mobile" : "pc",
        "click",
        `${router.currentRoute.value.meta.gtm}`,
        `deposit-request-${tokenInfo.symbol}`
      );
      const { walletAddress } = toRefs(props);
      if (walletAddress.value.length > 0) {
        if (inputAmount.value.length === 0) {
          inputAmount.value = "";
          return;
        } else {
          if (parseFloat(inputAmount.value) === 0) {
            createNotification({
              type: "error",
              message: `${t("Infotip.deposit-token-usercenter", [
                t("Infotip.action-deposit"),
                tokenInfo.symbol,
                t("Common.error"),
              ])}${t("Common.colon")} ${t(
                "Infotip.deposit-amount-can-not-be-zero"
              )}`,
            });
            return;
          }
        }
        const provider = await getWalletProvider();
        if (provider) {
          try {
            const signer = provider.getSigner();
            const contractDesktop = new ethers.Contract(
              tokenInfo.desktop,
              TapDesktop,
              signer
            );
            const amountBN = ethers.utils.parseUnits(inputAmount.value, 18);
            LogUtils.debug(amountBN);
            const sendPromise = contractDesktop.gameTokenIn(
              tokenInfo.address,
              amountBN
            );
            buttonDisabled.value = true;
            const uuid = createNotification({
              message: `${t("Infotip.deposit-token-usercenter", [
                t("Infotip.action-depositing"),
                tokenInfo.symbol,
                "",
              ])}...`,
              autoClose: false,
              canClose: false,
            });
            sendPromise
              .then(function (transaction: any) {
                transaction.wait().then(function (transaction: any) {
                  TOOL.gtmTrack(
                    "deposit-button",
                    isMobile.value ? "mobile" : "pc",
                    "click",
                    `${router.currentRoute.value.meta.gtm}`,
                    `deposit-success-${tokenInfo.symbol}`
                  );
                  BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                  createNotification({
                    type: "success",
                    message: `${t("Infotip.deposit-token-usercenter", [
                      t("Infotip.action-deposit"),
                      tokenInfo.symbol,
                      t("Common.successful"),
                    ])}`,
                  });
                  closeModal();
                  refreshTokenSelectData(tokenIndex, tokenOptType.value);
                  getPageData(1, 0);
                  buttonDisabled.value = false;
                });
              })
              .catch((error: any) => {
                console.error(error);
                TOOL.gtmTrack(
                  "deposit-button",
                  isMobile.value ? "mobile" : "pc",
                  "click",
                  `${router.currentRoute.value.meta.gtm}`,
                  `deposit-error-trade-${tokenInfo.symbol}`
                );
                BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                createNotification({
                  type: "error",
                  message: `${t("Infotip.deposit-token-usercenter", [
                    t("Infotip.action-deposit"),
                    tokenInfo.symbol,
                    t("Common.error"),
                  ])}`,
                  duration: 5,
                });
                if (isApproveAfterModal.value) {
                  showModal(0, tokenSelectIndex.value, "", false);
                }
                buttonDisabled.value = false;
              });
          } catch (e) {
            TOOL.gtmTrack(
              "deposit-button",
              isMobile.value ? "mobile" : "pc",
              "click",
              `${router.currentRoute.value.meta.gtm}`,
              `deposit-error-request-${tokenInfo.symbol}`
            );
            console.error(e);
            createNotification({
              type: "error",
              message: `${t("Infotip.deposit-token-usercenter", [
                t("Infotip.action-deposit"),
                tokenInfo.symbol,
                t("Common.error"),
              ])}`,
            });
            if (isApproveAfterModal.value) {
              showModal(0, tokenSelectIndex.value, "", false);
            }
            buttonDisabled.value = false;
          }
        }
      }
    };

    const withdraw = async (index: number = -1, tokenIndex: number) => {
      const tokenInfo = tokenSelectInfo.value[tokenIndex];
      TOOL.gtmTrack(
        "withdraw-button",
        isMobile.value ? "mobile" : "pc",
        "click",
        `${router.currentRoute.value.meta.gtm}`,
        `withdraw-request-${tokenInfo.symbol}`
      );
      const { walletAddress } = toRefs(props);
      if (walletAddress.value.length > 0) {
        let lid: number[] = [];
        const now = currentTime.value / 1000;
        if (index === -1) {
          for (const item of tradeListMC.value.list) {
            const isLock = item["timestamp"] + item["locktime"] > now;
            if (!item["completed"] && !item["processing"]) {
              if (!isLock) {
                lid.push(item["dbid"]);
              }
            }
          }
        } else {
          const item = tradeListMC.value.list[index];
          if (!item["completed"] && !item["processing"]) {
            lid.push(item["dbid"]);
          }
        }
        if (index === -1) {
          if (lid.length === 0) {
            createNotification({
              type: "error",
              message: `${t("Infotip.withdraw-token-usercenter", [
                t("Infotip.action-withdraw"),
                tokenInfo.symbol,
                t("Common.error"),
              ])}${t("Common.colon")} ${t(
                "Infotip.no-avaiable-withdraw-order"
              )}`,
            });
            return;
          }
        } else {
          if (lid.length === 0) {
            createNotification({
              type: "error",
              message: `${t("Infotip.withdraw-token-usercenter", [
                t("Infotip.action-withdraw"),
                tokenInfo.symbol,
                t("Common.error"),
              ])}${t("Common.colon")} ${t(
                "Infotip.this-withdraw-order-is-processing-please-wait"
              )}`,
            });
            return;
          }
        }
        const provider = await getWalletProvider();
        if (provider) {
          try {
            const signer = provider.getSigner();
            const contractDesktop = new ethers.Contract(
              tokenInfo.desktop,
              TapDesktop,
              signer
            );
            // const amountBN = ethers.utils.parseUnits(inputAmount.value, 18)
            // LogUtils.debug(amountBN)
            const sendPromise = contractDesktop.gameTokenOut(lid);
            const uuid = createNotification({
              message: `${t("Infotip.withdraw-token-usercenter", [
                t("Infotip.action-withdrawing"),
                tokenInfo.symbol,
                "",
              ])}...`,
              autoClose: false,
              canClose: false,
            });
            sendPromise
              .then(function (transaction: any) {
                transaction.wait().then(function (transaction: any) {
                  TOOL.gtmTrack(
                    "withdraw-button",
                    isMobile.value ? "mobile" : "pc",
                    "click",
                    `${router.currentRoute.value.meta.gtm}`,
                    `withdraw-success-${tokenInfo.symbol}`
                  );
                  BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                  createNotification({
                    type: "success",
                    message: `${t("Infotip.withdraw-token-usercenter", [
                      t("Infotip.action-withdraw"),
                      tokenInfo.symbol,
                      t("Common.successful"),
                    ])}`,
                  });
                  closeModal();
                  loadData();
                });
              })
              .catch((error: any) => {
                TOOL.gtmTrack(
                  "withdraw-button",
                  isMobile.value ? "mobile" : "pc",
                  "click",
                  `${router.currentRoute.value.meta.gtm}`,
                  `withdraw-error-trade-${tokenInfo.symbol}`
                );
                LogUtils.error(error);
                BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                createNotification({
                  type: "error",
                  message: `${t("Infotip.withdraw-token-usercenter", [
                    t("Infotip.action-withdraw"),
                    tokenInfo.symbol,
                    t("Common.error"),
                  ])}`,
                  duration: 5,
                });
              });
          } catch (e) {
            TOOL.gtmTrack(
              "withdraw-button",
              isMobile.value ? "mobile" : "pc",
              "click",
              `${router.currentRoute.value.meta.gtm}`,
              `withdraw-error-request-${tokenInfo.symbol}`
            );
            LogUtils.error(e);
            createNotification({
              type: "error",
              message: `${t("Infotip.withdraw-token-usercenter", [
                t("Infotip.action-withdraw"),
                tokenInfo.symbol,
                t("Common.error"),
              ])}`,
            });
          }
        }
      }
    };

    const swap = async () => {
      const tokenInfo = tokenSwapSrcInfo.value;
      const tokenInfoDst = tokenSwapDstInfo.value;

      if (tokenSelectIndex.value === 0 && depositGoldType.value === 1) {
        if (tokenSwapCount.value < 1) {
          createNotification({
            type: "error",
            message: 'Gold deposit quantity must be greater than one',
          });
          return;
        }
      }

      const flagApprove = await checkApprove(tokenSelectIndex.value)
      if (!flagApprove) {
        // createNotification({
        //   type: 'error',
        //   message: `Your token approve count is not enough`,
        // })
        showModal(4, tokenSelectIndex.value)
        return
      }

      TOOL.gtmTrack(
        "swap-button",
        isMobile.value ? "mobile" : "pc",
        "click",
        `${router.currentRoute.value.meta.gtm}`,
        `swap-request-${tokenInfo.symbol}=>${tokenInfoDst.symbol}`
      );
      const { walletAddress } = toRefs(props);
      if (walletAddress.value.length > 0) {
        if (inputAmount.value.length === 0) {
          inputAmount.value = "";
          return;
        } else {
          if (parseFloat(inputAmount.value) === 0) {
            createNotification({
              type: "error",
              message: `${t("Infotip.swap-token-usercenter", [
                t("Infotip.action-swap"),
                tokenInfo.symbol,
                tokenInfoDst.symbol,
                t("Common.error"),
              ])}${t("Common.colon")} ${t(
                "Infotip.swap-amount-can-not-be-zero"
              )}`,
            });
            return;
          }
        }
        const provider = await getWalletProvider();
        if (provider) {
          try {
            const signer = provider.getSigner();
            const contractDesktop = new ethers.Contract(
              tokenInfo.desktop,
              TapDesktop,
              signer
            );
            const amountBN = ethers.utils.parseUnits(inputAmount.value, 18);
            // LogUtils.debug(amountBN)
            const sendPromise =
              tokenSwapSrcInfo.value.symbol === tokenUsdtInfo.value.symbol
                ? contractDesktop.goldMint(amountBN, depositToGame.value)
                : contractDesktop.goldBurn(amountBN);
            const uuid = createNotification({
              message: `${t("Infotip.swap-token-usercenter", [
                t("Infotip.action-swapping"),
                tokenInfo.symbol,
                tokenInfoDst.symbol,
                "",
              ])}...`,
              autoClose: false,
              canClose: false,
            });
            sendPromise
              .then(function (transaction: any) {
                transaction.wait().then(function (transaction: any) {
                  TOOL.gtmTrack(
                    "swap-button",
                    isMobile.value ? "mobile" : "pc",
                    "click",
                    `${router.currentRoute.value.meta.gtm}`,
                    `swap-success-${tokenInfo.symbol}=>${tokenInfoDst.symbol}`
                  );
                  BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                  createNotification({
                    type: "success",
                    message: `${t("Infotip.swap-token-usercenter", [
                      t("Infotip.action-swap"),
                      tokenInfo.symbol,
                      tokenInfoDst.symbol,
                      t("Common.successful"),
                    ])}`,
                  });
                  if (tokenInfo.symbol === tokenUsdtInfo.value.symbol) {
                    tokenOptType.value = 0;
                  }
                  closeModal();
                  refreshTokenSelectData(0, 1);
                });
              })
              .catch((error: any) => {
                TOOL.gtmTrack(
                  "swap-button",
                  isMobile.value ? "mobile" : "pc",
                  "click",
                  `${router.currentRoute.value.meta.gtm}`,
                  `swap-error-trade-${tokenInfo.symbol}=>${tokenInfoDst.symbol}`
                );
                LogUtils.error(error);
                BUS.$emit("REMOVE_NOTIFICATION", { id: uuid });
                createNotification({
                  type: "error",
                  message: `${t("Infotip.swap-token-usercenter", [
                    t("Infotip.action-swap"),
                    tokenInfo.symbol,
                    tokenInfoDst.symbol,
                    t("Common.error"),
                  ])}`,
                });
                if (isApproveAfterModal.value && tokenSelectIndex.value === 0) {
                  showModal(
                    tokenOptType.value,
                    tokenSelectIndex.value,
                    "",
                    false
                  );
                }
              });
          } catch (e) {
            TOOL.gtmTrack(
              "swap-button",
              isMobile.value ? "mobile" : "pc",
              "click",
              `${router.currentRoute.value.meta.gtm}`,
              `swap-error-request-${tokenInfo.symbol}=>${tokenInfoDst.symbol}`
            );
            LogUtils.error(e);
            createNotification({
              type: "error",
              message: `${t("Infotip.swap-token-usercenter", [
                t("Infotip.action-swap"),
                tokenInfo.symbol,
                tokenInfoDst.symbol,
                t("Common.error"),
              ])}`,
            });
            if (isApproveAfterModal.value && tokenSelectIndex.value === 0) {
              showModal(tokenOptType.value, tokenSelectIndex.value, "", false);
            }
          }
        }
      }
    };

    const clearNoNum = (numStr: string) => {
      // numStr = numStr.replace(".", ""); //只保留第一个. 清除多余的
      numStr = numStr.replace(/[^\d\.]/g, ""); //清除“数字”和“.”以外的字符
      numStr = numStr.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      numStr = numStr
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      numStr = numStr.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (numStr.indexOf(".") < 0 && numStr != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        numStr = parseInt(numStr, 10).toString();
      }
      return numStr;
    };

    const watchInput = (tokenIndex: number) => {
      const optType = tokenOptType.value;
      let input_num_str = inputAmount.value.trim().toString();
      input_num_str = clearNoNum(input_num_str);
      inputAmount.value = input_num_str;
      if (optType === 0) {
        if (tokenIndex === 0 && depositGoldType.value === 1) {
          if (parseFloat(usdtBalance.value) < parseFloat(inputAmount.value)) {
            inputAmount.value = usdtBalance.value;
          }
          if (input_num_str.length === 0) {
            tokenSwapCount.value = 0;
          } else {
            tokenSwapCount.value = new BigNumber(inputAmount.value)
              .times(10)
              .toNumber();
          }
        } else {
          if (
            parseFloat(tokenBalance.value[tokenIndex]) <
            parseFloat(inputAmount.value)
          ) {
            inputAmount.value = tokenBalance.value[tokenIndex];
          }
        }
      } else if (optType === 1) {
        if (tokenIndex === 1) {
          if (parseFloat(inputAmount.value) > availableMCWithdraw.value) {
            inputAmount.value = tokenBalance.value[tokenIndex];
          }
        }
      } else {
        if (
          parseFloat(tokenSwapSrcInfo.value.balance) <
          parseFloat(inputAmount.value)
        ) {
          inputAmount.value = tokenSwapSrcInfo.value.balance;
        }
        if (tokenSwapSrcInfo.value.symbol === tokenUsdtInfo.value.symbol) {
          tokenSwapCount.value = parseFloat(inputAmount.value) * 10;
        } else {
          tokenSwapCount.value = parseFloat(inputAmount.value) / 10;
        }
      }
    };

    const maxInput = (tokenIndex: number) => {
      const optType = tokenOptType.value;
      // const tokenInfo = getTokenInfo(selectIndex, optType)
      if (optType === 0) {
        if (tokenIndex === 0 && depositGoldType.value === 1) {
          inputAmount.value = usdtBalance.value;
          tokenSwapCount.value = parseFloat(inputAmount.value) * 10;
        } else {
          inputAmount.value = tokenBalance.value[tokenIndex];
        }
      } else if (optType === 1) {
        if (tokenIndex === 1) {
          inputAmount.value = tokenBalance.value[tokenIndex];
        }
      } else {
        inputAmount.value = tokenBalance.value[tokenIndex];
        tokenSwapCount.value = parseFloat(inputAmount.value) / 10;
      }
    };

    const loadData = async () => {
      LogUtils.debug("load...");
      const loadingInstance = ElLoading.service({ fullscreen: true, background: 'transparent', svg: BLOCKCHAIN_CONFIG.svgLoading, svgViewBox: BLOCKCHAIN_CONFIG.svgLoadingViewbox })

      const projectConfig = TOOL.getConfigProject();
      const chainSymbol = await TOOL.getChainSymbol();
      const chainRPCConfig = TOOL.getDynamicObject(
        chainSymbol,
        projectConfig.rpcurls
      );
      const chainBrowserConfig = TOOL.getDynamicObject(
        chainSymbol,
        projectConfig.blockchain_browser_url
      );
      const chainABIConfig = TOOL.getDynamicObject(
        chainSymbol,
        projectConfig.abis
      );
      const chainTokensConfig = TOOL.getDynamicObject(
        chainSymbol,
        projectConfig.tokens
      );

      pageChainConfig.projectConfig = projectConfig;
      pageChainConfig.chainSymbol = chainSymbol;
      pageChainConfig.chainRPCConfig = chainRPCConfig;
      pageChainConfig.chainBrowserConfig = chainBrowserConfig;
      pageChainConfig.chainABIConfig = chainABIConfig;
      pageChainConfig.chainTokensConfig = chainTokensConfig;
      pageChainConfig.multicallConfig = {
        rpcUrl: chainRPCConfig,
        multicallAddress: chainABIConfig.MULTICALL,
      };

      const { walletAddress } = toRefs(props);

      // const provider = await getWalletProvider()
      chainId = await TOOL.getChainId();
      // if (provider) {

      const wa =
        walletAddress.value.length === 0
          ? BLOCKCHAIN_CONFIG.ZERO_ADDRESS
          : walletAddress.value;
      const urlAssetsAPIInfo = `${API.assetsInfo + chainId}/${wa}/`;
      assetsAPIInfo = (await axios.get(urlAssetsAPIInfo)).data.data;
      if (assetsAPIInfo) {
        tokenSelectInfo.value = [
          assetsAPIInfo.goldtoken,
          assetsAPIInfo.mctoken,
          assetsAPIInfo.taptoken,
        ];
        gameAccount.value = assetsAPIInfo.wallet.nickname;
        const tokenUsdt = {
          address: assetsAPIInfo.goldtoken.usdt.trim(),
          decimals: 18,
          symbol: "",
          tokenid: -1,
        };

        let calls: any[] = [];
        let returnValue = null;

        calls = calls.concat([
          {
            target: tokenUsdt.address,
            call: ["decimals()(uint256)"],
            returns: [["USDT_DECIMALS", (val: any) => val.toNumber()]],
          },
          {
            target: tokenUsdt.address,
            call: ["symbol()(string)"],
            returns: [["USDT_SYMBOL", (val: any) => val]],
          },
        ]);

        returnValue = await aggregateInventory(
          calls,
          pageChainConfig.multicallConfig
        );

        tokenUsdt.decimals = returnValue.results.transformed.USDT_DECIMALS;
        tokenUsdt.symbol = returnValue.results.transformed.USDT_SYMBOL;

        tokenUsdtInfo.value = tokenUsdt;
        if (typeof tokenSelectInfo.value[0].goldburnfeerate !== "undefined") {
          goldburnfeerate.value = tokenSelectInfo.value[0].goldburnfeerate;
        }

        if (walletAddress.value.length > 0) {
          buttonDisabled.value = false
          refreshTokenSelectData(0, 2);
          refreshTokenSelectData(1, 0);
          refreshTokenSelectData(2, 0);

          getPageData(1, 1, true);
          getPageData(1, 1, false);
          getPageData(1, 0);

          const blockRate = TOOL.getDynamicObject(
            pageChainConfig.chainSymbol,
            BLOCKCHAIN_CONFIG.BLOCK_RATE
          );
          updateTimeTask = setInterval(() => {
            currentTime.value = new Date().getTime();
            const now = currentTime.value / 1000;
            if (tradeListMC.value.list.length > 0) {
              let avaiableMC = 0;
              const len = tradeListMC.value.list.length;
              for (let i = 0; i < len; i++) {
                const item = tradeListMC.value.list[i];
                const isLock = item["timestamp"] + item["locktime"] > now;
                if (!item["processing"] && !item["completed"]) {
                  if (!isLock) {
                    avaiableMC = avaiableMC + item["amount"];
                  }
                }
              }
              availableMCWithdraw.value = Number(avaiableMC.toFixed(4));
            }
          }, 1000 * blockRate);
        } else {
          buttonDisabled.value = true
          tokenBalance.value = ["0", "0", "0"]

          tradeListMC.value = {
            list: [],
            pageCount: 0,
            recordCount: 0,
            pageNow: 1,
            isLoadingMore: false,
          }

          tradeListOther.value = {
            list: [],
            pageCount: 0,
            recordCount: 0,
            pageNow: 1,
            isLoadingMore: false,
          }

          tradeListRecharge.value = {
            list: [],
            pageCount: 0,
            recordCount: 0,
            pageNow: 1,
            isLoadingMore: false,
          }

        }
        // }
      }

      isLoading.value = false;
      nextTick(() => {
        // Loading should be closed asynchronously
        loadingInstance.close()
      })

      if (isLoadingInit.value) {
        isLoadingInit.value = false
        ctx.emit('update', false)
      }
    };

    return {
      tokenSelectIndex,
      tokenOptType,
      refreshTokenSelectData,
      tokenBalance,
      tokenApprove,
      usdtBalance,
      usdtApprove,
      tokenAvailable,
      tokenSwapCount,
      tokenSelectInfo,
      tokenUsdtInfo,
      tokenSwapSrcInfo,
      tokenSwapDstInfo,
      tradeListMC,
      tradeListOther,
      approve,
      deposit,
      withdraw,
      swap,
      watchInput,
      maxInput,
      inputAmount,
      swapTokenDirection,
      changePage,
      getTime,
      getTxLink,
      goldburnfeerate,
      depositGoldType,
      changeDepositGoldType,
      depositToGame,
      gameAccount,
      isShowModal,
      modalInfotip,
      showModal,
      closeModal,
      isMobile,
      currentTime,
      getWithdrawAvailable,
      availableMCWithdraw,
      modalShowIndex,
      copyText,
      tradeListRecharge,
      pagePerCount,
      pagePerCountMC,
      isLoading,
      mcWithdrawPercent,
      getPageData,
      isApproveAfterModal,
      buttonDisabled,
      getApproveTokenAndNeedAmount
      // chainId,
      // chainSymbol
      // loadData
    };
  },
});
</script>
<style lang="stylus" scoped></style>