<template>
  <div ref="headerRef" class="menu-index d-flex align-items-center font-bmn">
    <div class="d-flex align-items-center justify-content-between" style="height: 100%;width: 100%;">
      <a v-if="!isMobile" href="/" class="logo-title-block d-flex flex-column align-items-center">
        <span class="logo-title1">{{ $t('Header.logo-title1') }}</span>
        <span class="logo-title2">
          {{ $t('Header.logo-title2') }}
          <template v-if="isDebugSite">
            TEST
          </template>
        </span>
      </a>
      <div v-if="!isMobile" class="d-flex align-items-center pc-nav-list" style="flex: 1">
        <a :class="$route.name === 'Home' ? 'active' : ''" href="/#/"
          class="pc-nav-link-item d-flex align-items-center">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.home') }}</div>
          <div class="pc-nav-link-underline"></div>
        </a>
        <div
          :class="($route.name !== null && typeof $route.name !== 'undefined' && String($route.name).indexOf('Marketplace') >= 0) ? 'active' : ''"
          class="pc-nav-link-item d-flex align-items-center" @mouseenter="isShowMP = true"
          @mouseleave="isShowMP = false">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.marketplace') }}</div>
          <div class="pc-nav-link-new-future"></div>
          <div class="pc-nav-link-underline"></div>
          <svg :class="isShowMP ? 'rotate' : ''" class="pc-nav-link-item-arrow" width="11px" height="6px"
            viewBox="0 0 11 6" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
              stroke-linejoin="round">
              <g transform="translate(-294.000000, -154.000000)" stroke="currentColor" stroke-width="1.5">
                <path
                  d="M302.242641,152.242641 L302.242641,157.242641 C302.242641,157.794925 301.794925,158.242641 301.242641,158.242641 L296.242641,158.242641 L296.242641,158.242641"
                  transform="translate(299.242641, 155.242641) rotate(45.000000) translate(-299.242641, -155.242641) ">
                </path>
              </g>
            </g>
          </svg>
          <div v-if="isShowMP" class="pc-nav-sublink-block">
            <a :class="$route.name === 'MarketplaceRecommend' ? 'active' : ''" class="pc-nav-sublink"
              href="/#/marketplace/trending">
              <div class="pc-nav-sublink-title">Trending</div>
              <div class="pc-nav-sublink-line"></div>
            </a>
            <a :class="$route.name === 'MarketplaceBuyNFT' ? 'active' : ''" class="pc-nav-sublink"
              href="/#/marketplace/buynft">
              <div class="pc-nav-sublink-title">Buy NFTs</div>
              <div class="pc-nav-sublink-line"></div>
            </a>
            <a :class="$route.name === 'MarketplaceRentNFT' ? 'active' : ''" class="pc-nav-sublink"
              href="/#/marketplace/rentnft">
              <div class="pc-nav-sublink-title">Rent NFTs</div>
              <div class="pc-nav-sublink-line"></div>
            </a>
            <!-- <a :class="$route.name === 'MarketplaceBorrowMC' ? 'active' : ''" class="pc-nav-sublink"
              href="/#/marketplace/borrowmc">
              <div class="pc-nav-sublink-title">Borrow MC</div>
              <div class="pc-nav-sublink-line"></div>
            </a> -->
          </div>
        </div>
        <a :class="$route.name === 'Mysterybox' ? 'active' : ''" href="/#/mysterybox"
          class="pc-nav-link-item d-flex align-items-center">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.mystery-box') }}</div>
          <div class="pc-nav-link-underline"></div>
        </a>
        <a :class="$route.name === 'Guildcoop' ? 'active' : ''" href="/#/guildcoop"
          class="pc-nav-link-item d-flex align-items-center">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.guild-cooperation') }}</div>
          <div class="pc-nav-link-underline"></div>
        </a>
        <a href="https://snapshot.org/#/tapfantasymetaverse.eth" target="_blank"
          class="pc-nav-link-item d-flex align-items-center">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.governance') }}</div>
          <div class="pc-nav-link-underline"></div>
        </a>
        <a :class="($route.name === 'UserCenter' || $route.name === 'MyNFTSkin' || $route.name === 'MyNFTHead' || $route.name === 'MyNFTRune') ? 'active' : ''"
          href="/#/usercenter" class="pc-nav-link-item d-flex align-items-center">
          <div class="pc-nav-link-item-text font-bmnbold">{{ $t('Header.user-center') }}</div>
          <div class="pc-nav-link-underline"></div>
        </a>
      </div>
      <div v-if="isMobile" class="d-flex justify-content-center align-items-center">
        <svg @click="showMobileMenu()" style="margin-left: 16px;" width="23px" height="19px" viewBox="0 0 23 19"
          version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
            <g transform="translate(-14.000000, -16.000000)" stroke="#FFFFFF" stroke-width="2">
              <g transform="translate(15.500000, 17.000000)">
                <line x1="0" y1="0.5" x2="20" y2="0.5"></line>
                <line x1="0" y1="8.5" x2="20" y2="8.5"></line>
                <line x1="0" y1="16.5" x2="20" y2="16.5"></line>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="lang-change-wrap" v-if="!isMobile">
        <!-- <div class="lang-change">
          <ChangeLang />
        </div> -->
      </div>
      <div v-if="!isMobile" class="d-flex justify-content-end align-items-center nav-pc-right">
        <div class="change-chain-wrap">
          <ChangeChain />
        </div>
        <div class="change-chain-wrap">
          <BuyMcTop />
        </div>
        <a href="/#/login" @click="gtmTrack('playnow-button', 'pc', 'click', `header-${String($route.name)}`, '')">
          <button class="button-white play-now" id="LondonTwo">{{ $t('Header.button_playnow') }}</button>
        </a>
        <button v-if="walletAddress.length === 0" class="button-purple button-connect-wallet" @click="walletConnect">{{
            $t('Header.connect-wallet')
        }}</button>
        <button v-else @click="walletShow" class="button-purple button-connect-wallet">
          {{
              walletAddress.substr(0, 6) +
              '...' +
              walletAddress.substr(walletAddress.length - 4, 4)
          }}
        </button>
      </div>
      <div v-else class="nav-mobile-right d-flex align-items-center">
        <div class="change-chain-wrap">
          <BuyMcTop />
        </div>
        <div v-if="!isLoading" class="chain-block">
          <div class="inner" @click="isOpenChainSelect = !isOpenChainSelect">
            <img src="/static/images/chain-bsc.png" />
            <span :class="chainId > 97 ? 'sol' : 'bsc'">BSC</span>
            <svg :class="isOpenChainSelect ? 'rotate' : ''" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1662.000000, -8237.000000)" fill="#FFFFFF">
                  <path
                    d="M1667.87416,8238.57348 L1671.17464,8244.51436 C1671.44286,8244.99714 1671.26891,8245.60594 1670.78613,8245.87416 C1670.63757,8245.95669 1670.47043,8246 1670.30049,8246 L1663.69951,8246 C1663.14723,8246 1662.69951,8245.55228 1662.69951,8245 C1662.69951,8244.83006 1662.74282,8244.66292 1662.82536,8244.51436 L1666.12584,8238.57348 C1666.39406,8238.0907 1667.00286,8237.91676 1667.48564,8238.18497 C1667.64888,8238.27566 1667.78347,8238.41024 1667.87416,8238.57348 Z"
                    transform="translate(1667.000000, 8241.500000) scale(1, -1) translate(-1667.000000, -8241.500000) ">
                  </path>
                </g>
              </g>
            </svg>
          </div>
          <div v-if="isOpenChainSelect" class="chain-list">
            <a :href="siteSOL" class="chain-item" style="padding: 6PX 12PX;width:100%">
              <img src="/static/images/chain-solana.png" />
              <span class="name">SOL</span>
            </a>
            <a href="https://ton.tapfantasy.io/" class="chain-item" style="padding: 6PX 12PX;width:100%">
              <img src="/static/images/chain-ton.png" style="height: 24PX;" />
              <span class="name">TON</span>
              <!-- <span style="color: #aaa;" class="name">TON</span> -->
              <!-- <span class="comingsoon" style="">Coming Soon</span> -->
            </a>
          </div>

        </div>
        <a v-if="$route.name !== 'Home'" href="/#/login"
          @click="gtmTrack('playnow-button', 'mobile', 'click', `header-${String($route.name)}`, '')">
          <button class="button-white" id="LondonTwo">{{ $t('Header.button_playnow') }}</button>
        </a>
        <button v-if="walletAddress.length === 0" class="button-purple button-connect-wallet" id="LondonTwo"
          @click="walletConnect">{{
              $t('Header.connect-wallet')
          }}</button>
        <button v-else @click="walletShow" class="button-purple button-connect-wallet">
          {{
              walletAddress.substr(0, 6) +
              '...' +
              walletAddress.substr(walletAddress.length - 4, 4)
          }}
        </button>
      </div>
    </div>
  </div>
  <div v-if="isShowNav" class="full-menu font-bmn" :class="isShowNav ? 'show' : ''">
    <div class="menu-list">
      <div @click.self="isShowTelegram = false" class="content">
        <div @touchmove.prevent class="logo-title-block">
          <span class="logo-title1">{{ $t('Header.logo-title1') }}</span>
          <span class="logo-title2">
            {{ $t('Header.logo-title2') }}
            <template v-if="isDebugSite">
              TEST
            </template>
          </span>
        </div>
        <ul>
          <li class="menu-content font-bmnbold">
            <a @click="closeMobileMenu" :class="$route.name === 'Home' ? 'active' : ''" href="/">{{ $t('Header.home')
            }}</a>
          </li>
          <li class="menu-content font-bmnbold">
            <div class="d-flex flex-column align-items-center justify-content-center" style="width: 100%;">
              <div @click="isShowMP = !isShowMP" class="nav-mobile-level1"
                :class="($route.name !== null && typeof $route.name !== 'undefined' && String($route.name).indexOf('Marketplace') >= 0) ? 'active' : ''">
                <div class="left-item"></div>
                <span>{{ $t('Header.marketplace') }}</span>
                <svg :class="isShowMP ? '' : 'open'" width="11px" height="6px" viewBox="0 0 11 6" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g transform="translate(-294.000000, -153.000000)" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M302.242641,155.242641 L302.242641,160.242641 C302.242641,160.794925 301.794925,161.242641 301.242641,161.242641 L296.242641,161.242641 L296.242641,161.242641"
                        transform="translate(299.242641, 158.242641) scale(1, -1) rotate(45.000000) translate(-299.242641, -158.242641) ">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
              <template v-if="isShowMP">
                <div class="nav-mobile-level2 first" style="width:100%"
                  :class="$route.name === 'MarketplaceRecommend' ? 'active' : ''">
                  <a href="/#/marketplace/trending" @click="closeMobileMenu">Trending</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <div class="nav-mobile-level2" style="width:100%"
                  :class="$route.name === 'MarketplaceBuyNFT' ? 'active' : ''">
                  <a href="/#/marketplace/buynft" @click="closeMobileMenu">Buy NFTs</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <div class="nav-mobile-level2" style="width:100%"
                  :class="$route.name === 'MarketplaceRentNFT' ? 'active' : ''">
                  <a href="/#/marketplace/rentnft" @click="closeMobileMenu">Rent NFTs</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <!-- <div class="nav-mobile-level2" style="width:100%"
                  :class="$route.name === 'MarketplaceBorrowMC' ? 'active' : ''">
                  <a href="/#/marketplace/borrowmc" @click="closeMobileMenu">Borrow MC</a>
                  <div class="menu-level2-selected-block"></div>
                </div> -->
              </template>
            </div>
          </li>
          <li class="menu-content font-bmnbold">
            <a @click="closeMobileMenu" :class="$route.name === 'Mysterybox' ? 'active' : ''" href="/#/mysterybox">{{
                $t('Header.mystery-box')
            }}</a>
          </li>
          <li class="menu-content font-bmnbold">
            <a @click="closeMobileMenu" :class="$route.name === 'Guildcoop' ? 'active' : ''" href="/#/guildcoop">{{
                $t('Header.guild-cooperation')
            }}</a>
          </li>

          <li class="menu-content font-bmnbold">
            <a @click="closeMobileMenu" target="_blank" href="https://snapshot.org/#/tapfantasymetaverse.eth">{{
                $t('Header.governance')
            }}</a>
          </li>
          <li class="menu-content font-bmnbold">
            <div class="d-flex flex-column align-items-center justify-content-center" style="width: 100%;">
              <div @click="isShowUC = !isShowUC" class="nav-mobile-level1"
                :class="isShowUC || ($route.name === 'UserCenter' || $route.name === 'MyNFTSkin' || $route.name === 'MyNFTHead') ? 'active' : ''">
                <div class="left-item"></div>
                <span>{{ $t('Header.user-center') }}</span>
                <svg :class="isShowUC ? '' : 'open'" width="11px" height="6px" viewBox="0 0 11 6" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
                    stroke-linejoin="round">
                    <g transform="translate(-294.000000, -153.000000)" stroke="currentColor" stroke-width="1.5">
                      <path
                        d="M302.242641,155.242641 L302.242641,160.242641 C302.242641,160.794925 301.794925,161.242641 301.242641,161.242641 L296.242641,161.242641 L296.242641,161.242641"
                        transform="translate(299.242641, 158.242641) scale(1, -1) rotate(45.000000) translate(-299.242641, -158.242641) ">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
              <template v-if="isShowUC">
                <div class="nav-mobile-level2 first" style="width:100%"
                  :class="$route.name === 'UserCenter' ? 'active' : ''">
                  <a href="/#/usercenter" @click="closeMobileMenu">{{ $t('Header.wallet') }}</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <div class="nav-mobile-level2" style="width:100%" :class="$route.name === 'MyNFTHead' ? 'active' : ''">
                  <a href="/#/mynfthead" @click="closeMobileMenu">{{
                      $t('Header.nft-profile')
                  }}</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <div class="nav-mobile-level2" style="width:100%" :class="$route.name === 'MyNFTSkin' ? 'active' : ''">
                  <a href="/#/mynft" @click="closeMobileMenu">{{
                      $t('Header.nft-skin')
                  }}</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
                <div class="nav-mobile-level2" style="width:100%" :class="$route.name === 'MyNFTRune' ? 'active' : ''">
                  <a href="/#/mynftrune" @click="closeMobileMenu">NFT Rune</a>
                  <div class="menu-level2-selected-block"></div>
                </div>
              </template>
            </div>
          </li>
        </ul>
        <div @touchmove.prevent class="social-media-list">
          <a href="https://twitter.com/tapfantasy2021" target="_blank">
            <div class="item">
              <img src="/static/images/page8_bottom_twitter.png" />
            </div>
          </a>
          <div @click="isShowTelegram = !isShowTelegram" onblur="isShowTelegram=false" class="item">
            <img src="/static/images/page8_bottom_telegram.png" />
            <div v-if="isShowTelegram" class="telegram-list">
              <a class="item" href="https://t.me/tap_fantasy" target="_blank">{{
                  $t('Home.rightbottom_socialmedia.telegram_channel_global')
              }}</a>
              <a class="item" href="https://t.me/tapfantasy_announcement" target="_blank">{{
                  $t('Home.rightbottom_socialmedia.telegram_channel_announcement')
              }}</a>
              <a class="item" href="https://t.me/tap_fantasy_kr" target="_blank">{{
                  $t('Home.rightbottom_socialmedia.telegram_channel_korean')
              }}</a>
              <a class="item" href="https://t.me/tapfantasy_chinese" target="_blank">{{
                  $t('Home.rightbottom_socialmedia.telegram_channel_chinese')
              }}</a>
            </div>
          </div>
          <a href="https://discord.gg/tapfantasy" target="_blank">
            <div class="item">
              <img src="/static/images/page8_bottom_discord.png" />
            </div>
          </a>
          <a href="https://medium.com/tapfantasy" target="_blank">
            <div class="item">
              <img src="/static/images/page8_bottom_medium.png" />
            </div>
          </a>
          <div class="item">
            <div class="language-select" @click="isOpenLanguage = !isOpenLanguage">
              <span class="lang-cur">
                <template v-if="$i18n.locale === 'en'">EN</template>
                <template v-else-if="$i18n.locale === 'ja'">JP</template>
                <template v-else-if="$i18n.locale === 'zh-TW'">繁</template>
              </span>
              <svg :class="isOpenLanguage ? 'rotate' : ''" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g transform="translate(-1662.000000, -8237.000000)" fill="#FFFFFF">
                    <path
                      d="M1667.87416,8238.57348 L1671.17464,8244.51436 C1671.44286,8244.99714 1671.26891,8245.60594 1670.78613,8245.87416 C1670.63757,8245.95669 1670.47043,8246 1670.30049,8246 L1663.69951,8246 C1663.14723,8246 1662.69951,8245.55228 1662.69951,8245 C1662.69951,8244.83006 1662.74282,8244.66292 1662.82536,8244.51436 L1666.12584,8238.57348 C1666.39406,8238.0907 1667.00286,8237.91676 1667.48564,8238.18497 C1667.64888,8238.27566 1667.78347,8238.41024 1667.87416,8238.57348 Z"
                      transform="translate(1667.000000, 8241.500000) scale(1, -1) translate(-1667.000000, -8241.500000) ">
                    </path>
                  </g>
                </g>
              </svg>
              <div v-if="isOpenLanguage" class="lang-list">
                <template v-for="(langitem) of lang_list" :key="'lang-' + langitem.lang">
                  <div @click="setLang(langitem)" class="lang-item" v-if="langitem.lang !== $i18n.locale">
                    {{ langitem.displaymobile }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @touchmove.prevent style="flex:1;height: 100%;" @click="closeMobileMenu"></div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  watch,
  getCurrentInstance,
  ref
} from 'vue'
import { useRouter } from 'vue-router'
import TOOL from '../utils/tool'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
import { _getCookie } from '@/utils/storage'
import { useI18n } from "vue-i18n"
import i18n from "@/i18n";
import { isDebug } from '@/constants/Config'
// import ChangeLang from '@/views/components/ChangeLang.vue'
import ChangeChain from '@/views/components/ChangeChain.vue'
import BuyMcTop from '@/views/components/BuyMcTop.vue'

export default defineComponent({
  name: 'Header',
  emits: ['goAnchor'],
  props: ['walletAddress'],
  components: {
    ChangeChain,
    BuyMcTop,
    // ChangeLang
  },
  setup(props, context) {
    const { locale } = useI18n()
    const router = useRouter()
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

    // const gtm = useGtm();
    const isMobile = ref(false)
    const isShowNav = ref(false)
    const isLoading = ref(true)
    const isMysteryBoxOpen = ref(BLOCKCHAIN_CONFIG.isMysteryBoxOpen)
    const isMarketPlaceOpen = ref(BLOCKCHAIN_CONFIG.isMarketPlaceOpen)
    const isPlayGameOpen = ref(BLOCKCHAIN_CONFIG.isPlayGameOpen)
    const isShowBank = ref(false)
    const isShowMP = ref(false)
    const isShowUC = ref(false)
    const isShowTelegram = ref(false)
    const isOpenLanguage = ref(false)
    const isOpenChainSelect = ref(false)
    const chainId = ref(0)
    const siteSOL = isDebug ? 'https://solana-test.tapfantasy.io' : 'https://solana.tapfantasy.io'
    const lang_list = ref([
      {
        lang: 'en',
        display: 'ENGLISH',
        displaymobile: 'EN'
      },
      {
        lang: 'ja',
        display: '日本語',
        displaymobile: 'JP'
      },
      {
        lang: 'zh-TW',
        display: '繁體中文',
        displaymobile: '繁'
      }
    ])
    const isDebugSite = ref(isDebug)

    onMounted(() => {
      isMobile.value = !TOOL.getDeviceScreenIsPC()
      BUS.$on('RESIZE_WINDOW', (para: any) => {
        isMobile.value = para.isMobile
        isShowNav.value = false
        if (isMobile.value) {
          if (router.currentRoute.value.name === 'UserCenter' || router.currentRoute.value.name === 'MyNFTSkin' || router.currentRoute.value.name === 'MyNFTHead' || router.currentRoute.value.name === 'MyNFTRune') {
            isShowUC.value = true
          }
        }
      })
      if (isMobile.value) {
        if (router.currentRoute.value.name === 'UserCenter' || router.currentRoute.value.name === 'MyNFTSkin' || router.currentRoute.value.name === 'MyNFTHead' || router.currentRoute.value.name === 'MyNFTRune') {
          isShowUC.value = true
        }
      }

      setTimeout(async () => {
        chainId.value = await TOOL.getChainId()
        isLoading.value = false
      }, 2000)
    })

    const gtmTrack = (event: string, category: string, action: string, label: string, value: string) => {
      TOOL.gtmTrack(event, category, action, label, value)
    }

    const goAnchor = (id: string) => {
      context.emit('goAnchor', id)
    }

    const showMobileMenu = () => {
      isShowNav.value = true;
      BUS.$emit('MOBILE_NAV_SHOW', { isShowMobileNav: isShowNav.value })
      if (isMobile.value) {
        if (router.currentRoute.value.name === 'UserCenter' || router.currentRoute.value.name === 'MyNFTSkin' || router.currentRoute.value.name === 'MyNFTHead' || router.currentRoute.value.name === 'MyNFTRune') {
          isShowUC.value = true
        }
      }
    }

    const closeMobileMenu = () => {
      isShowNav.value = false
      isShowUC.value = false
      BUS.$emit('MOBILE_NAV_SHOW', { isShowMobileNav: isShowNav.value })
    }

    //FIXME: WALLET CONNECT 
    const walletConnect = async () => {
      TOOL.gtmTrack('walletconnect', isMobile.value ? 'mobile' : 'pc', 'click', `header-${router.currentRoute.value.meta.gtm}`, 'connect-button')
      BUS.$emit('WALLET_CONNECT', {})
      // const provider = await connectWeb3Provider()
      // if (provider) {
      //   await fetchWalletProvider()

      // }
    }

    const walletShow = () => {
      BUS.$emit('WALLET_SHOW', {})
    }

    const setLang = (langitem: any) => {
      const lang = i18n.global.locale.value

      const getHTMLTag = document.documentElement
      getHTMLTag.setAttribute("lang", langitem.lang)
      localStorage.setItem('locale', langitem.lang)

      locale.value = langitem.lang

      // if (lang !== langitem.lang) {
      //   window.location.reload()
      // }
    }

    return {
      goAnchor,
      isMobile,
      isShowNav,
      walletConnect,
      isLoading,
      isMarketPlaceOpen,
      isMysteryBoxOpen,
      isShowBank,
      isShowMP,
      isShowUC,
      isPlayGameOpen,
      showMobileMenu,
      closeMobileMenu,
      gtmTrack,
      isShowTelegram,
      setLang,
      isOpenLanguage,
      lang_list,
      isOpenChainSelect,
      chainId,
      siteSOL,
      isDebugSite,
      walletShow
    }

    // eslint-disable-next-line no-unused-vars
    const goGitHub = () => {
      window.open('https://github.com/XPoet/vite-vue3-starter')
    }
  }
})
</script>

<style scoped lang="stylus">
  // .logo-wrapper {
  //   padding: 0;
  // }

    .inner {
        background: #44434E;
        border-radius: 55px;
        width: 166px;
        height: 45px;
        img {
            // width: 28px;
            // height: 28px;
            // border: 4px solid #000;
            // border-radius: 100%;
        }


    }

    #LondonTwo {
        font-family: LondonTwo !important;
        font-size: 18px;
        padding-left: 22px;
        padding-right: 22px;
    }

    // chain select style 
    .button-purple {
        background: #EE9B0B !important;
    }
    .chain-list {
        margin-top: 20px;
        background: #EE9B0B;
    }
    // #44434E
  .pc-nav-list {

    .pc-nav-link-item {
        justify-content: center;
        position relative
        cursor pointer
        .pc-nav-link-item-text {
            font-family: LondonTwo;
            font-size: 18px;
            letter-spacing: 0.9px;
            transition: color .3s;
            
        }
        .pc-nav-link-new-future {
            position: absolute;
            left: -8px;
            top: 26px;
            border-radius: 6px;
            width: 6px;
            height: 6px;
            background: #EE9B0B;
        }
        .pc-nav-link-underline{
          position absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background: #EE9B0B;
          display none
          transition: color .3s;
        }
        .pc-nav-link-item-arrow{
          position: absolute;
          width: 11px;
          height: 6px;
          right:-20px; 
          top: 50%;
          color: #989898;
          &.rotate{
            transform: rotate(180deg)
            color: #FFDB62;
          }
        }
        .pc-nav-sublink-block{
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 10px;
          width: 250px;
          height: 150px;
          background: rgba(0,0,0,0.8);
          bottom:-150px;
          left: 0;

          .pc-nav-sublink{
            display flex
            height: 30px;
            width: 100%;
            position relative
            align-items: flex-start;
            background: rgba(255,255,255,0);
            transition: color .3s;

            .pc-nav-sublink-title{
              font-family: LondonTwo;
              font-size: 18px;
              color: #989898;
              letter-spacing: 1px;
              line-height: 30px;
              text-align left
              padding-left: 16px;
              transition: color .3s;
            }
            
            .pc-nav-sublink-line{
              position absolute;
              left: 0;
              top: 0;
              width: 6px;
              height: 100%;
              background: #EE9B0B;
              display none
              transition: color .3s;
            }

            &:hover,&.active{
              text-decoration: none;
              background: rgba(255,255,255,0.1);
              .pc-nav-sublink-title{
                color: #FFFFFF;
              }
              .pc-nav-sublink-line{
                display: block;
              }
              .pc-nav-link-item-arrow{
                color: #FFDB62;
              }
            }

          }
        }
        &.active, &:hover, &.active  {
            .pc-nav-link-item-text {
                color: #FFDB62;
            }   
            .pc-nav-link-underline {
                display: block;
            }
        }
      }   
    }
    .change-chain-wrap {
        margin-right: 10px;    
    }

    @media screen and (max-width: 1140px) {
      .change-chain-wrap {
          margin-right: 5rem;    
      }
      #LondonTwo {
          font-family: LondonTwo !important;
          font-size: 12PX;
          padding-left: 8PX;
          padding-right: 8PX;
          padding-bottom: 4PX;
      }
    }
    // .lang-change-wrap {
    //     // width: 200px;
    //     height: 100%;
    //     margin-right: 30px;
    //     .lang-change{
    //         position: relative;
    //         top: 50%;
    //         transform: translateY(-50%);
    //     }
        
    // }
</style>
