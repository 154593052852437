<template>
  <div id="root" ref="root">
    <Main />
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, getCurrentInstance, onMounted, ref } from 'vue'

import Main from './components/Main.vue'


export default defineComponent({
  name: 'App',
  components: {
    Main
  },
  setup() {
    const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

    // 父组件 ref 引用
    const root = ref(null);
    const PARENT_PROVIDE = 'rootProvide'
    // 提供父组件 ref 引用
    provide(PARENT_PROVIDE, root);

    onMounted(() => {

    })

    return {
      root
    }
  }
})
</script>

<style lang="stylus">


  #root {
    font-family: Avenir, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    position: relative;
    // background-color: #1d1d26;
    /* text-align: center;
    color: #2c3e50;
    margin-top: 60px; */
    min-height: 100vh;
    overflow-y: hidden;
  }


</style>
