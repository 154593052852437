<template>
    <div id="pdf-view">
        <div id="pdf">
            <div @click="goBack"
                style="cursor: pointer;background: rgb(0, 0, 0);border-radius: 8px;position: fixed;top: 50%;left:16px;z-index: 100;line-height: 56px;height:56px;width:56px;display: flex;align-items: center;justify-content: center;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    style="color: white">
                    <line x1="19" y1="12" x2="5" y2="12" />
                    <polyline points="12 19 5 12 12 5" />
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import LogUtils from "@/utils/LogUtils";

export default {
    name: "PdfView",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            pdfh5: null
        });
        onMounted(() => {
            state.pdfh5 = new Pdfh5("#pdf", {
                pdfurl: route.params.url
            });
            state.pdfh5.on("complete", function (status, msg, time) {
                LogUtils.debug("State" + status + "，Info: " + msg + "，Time: " + time + "ms，pages: " + this.totalNum);
            });
        });
        const goBack = () => {
            router.go(-1)
        };
        return {
            ...toRefs(state),
            goBack
        };
    }
};
</script>

<style lang="stylus">
#pdf-view {
    position relative
    height: 100vh;
}

#pdf {
    height: 100%;
}

.pdfViewer {
    padding: 0;
}
#root{
    height: auto
}
// .pdfjs .loadingBar .progress .glimmer {
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: calc(100% + 0.78rem);
//     background: #ffffff;
// }
</style>
