<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content d-flex flex-column align-items-center">
      <span class="title">{{ $t('Login.create-new-account') }}</span>
      <div class="input-item first d-flex flex-column">
        <span class="input-title">{{ $t('Login.username') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-enter-username')" ref="username" v-model.trim="registerForm.name"
            style="flex: 1;" />
        </div>
      </div>
      <div class="input-item d-flex flex-column">
        <span class="input-title">{{ $t('Login.password') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-enter-password')" ref="password" autocomplete="new-password"
            :type="passwordType" v-model="registerForm.password" style="flex: 1;" />
        </div>
      </div>
      <div class="input-item d-flex flex-column">
        <span class="input-title">{{ $t('Login.confirm-password') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-confirm-password')" ref="password" autocomplete="new-password"
            :type="passwordType" v-model="registerForm.password2" style="flex: 1;" />
        </div>
      </div>
      <div class="input-item d-flex flex-column">
        <span class="input-title">{{ $t('Login.email') }}</span>
        <div class="input-block d-flex align-items-center">
          <input v-model.trim="emailInput" :placeholder="$t('Login.email-address')" style="flex: 1;" />
          <button style="margin-right: 10PX;width:130PX;height: 24PX;font-size: 14PX;"
            :disabled="emailInput.length === 0 || buttonGetVerifyCodeDisabled" @click="getSecCode">
            <template v-if="countdownGetSecCode === durationGetSecCode">{{ $t('Login.get-verify-code') }}</template>
            <template v-else>{{ countdownGetSecCode }} {{ $t('Login.seconds') }}</template></button>
        </div>
      </div>
      <div class="input-item d-flex flex-column">
        <span class="input-title">{{ $t('Login.verify-code') }}</span>
        <div class="input-block d-flex align-items-center">
          <input v-model.trim="emailSecode" :placeholder="$t('Login.email-verify-code')" style="flex: 1;" />
        </div>
      </div>
      <!-- <span class="error-block">{{ errorMsgEmail }}</span> -->
      <div v-if="langCur.global.locale === 'en' || langCur.global.locale === 'zh-TW'"
        class="link-block d-flex align-items-center justify-content-start">
        <el-checkbox size="small" v-model="isAgreeTerms" @change="changeAgree">
          <span>{{ $t('Login.i-have-read-and-agree-to-the') }}</span> <a class="terms"
            :target="isMobile ? '_self' : '_blank'" href="/#/terms">{{ $t('Login.user-agreement') }}</a>
        </el-checkbox>
      </div>
      <div class="link-block d-flex align-items-center justify-content-between">
        <a href="/#/login">{{ $t('Login.sign-in-to-tap-fantasy') }}</a>
        <a href="/#/forgetpass">{{ $t('Login.forget-password') }}</a>
      </div>
      <span class="error-block login">
        {{ errorMsg }}
      </span>
      <button class="button signin" @click="handleRegister">{{
        $t('Login.sign-up')
      }}</button>
    </div>
  </div>
  <!-- <div style="margin-top: 0px">
    <div class="videobg" style="z-index: -1">
      <video autoplay loop muted>
        <source src="/static/videos/topBg.webm" type="video/mp4" />
      </video>
    </div>

    
  </div>-->
</template>


<script>
import { useRouter } from 'vue-router'
import md5 from 'js-md5'
import { registerbyMail } from '@/api/account'
import { tokenKey } from '@/settings'
import { _setCookie } from '@/utils/storage'
import TOOL from '@/utils/tool'
import i18n from "@/i18n";
import LogUtils from '@/utils/LogUtils'
import { getEmailSecCode } from '@/api/account'
import { useI18n } from 'vue-i18n'
import { _getCookie, _removeCookie } from '@/utils/storage'
import { ElMessage } from 'element-plus'
export default {
  name: 'Register',
  data() {
    return {
      styleObj: {
        width: '100%',
        color: 'white',
        'border-radius': '0px',
        'font-size': '2vh',
        'min-height': '5vh',
        border: 'none',
        'border-bottom': '1px solid white'
      },
      passwordType: 'password',
      loading: false,
      registerForm: {
        name: '',
        password: '',
        password2: '',
        inviter: '',
        fromtfid: '',
        area: '',
        mail: '',
        verifycode: '',
      },
      // errorMsg: '',
      router: useRouter(),
      isAgreeTerms: false,
      isMobile: false,
      langCur: i18n,
      email: '',
      emailInput: '',
      emailSecode: '',
      errorMsg: '',
      buttonGetVerifyCodeDisabled: false,
      countdownGetSecCode: 90,
      durationGetSecCode: 90,
      t: useI18n().t
    }
  },
  created() {
    const inviter = this.$route.query && this.$route.query.inviter
    this.registerForm.inviter = inviter

    const fromtfid = this.$route.query && this.$route.query.fromtfid
    if (fromtfid) {
      // const fromtfidLS = localStorage.getItem('fromtfid')
      // if (fromtfidLS === null || typeof (fromtfidLS) === "undefined") {
      localStorage.setItem('fromtfid', fromtfid.toString())
      // }
    }

  },
  mounted() {
    this.isAgreeTerms = localStorage.getItem('IS_AGREE_TERMS') !== null && localStorage.getItem('IS_AGREE_TERMS').length > 0 && localStorage.getItem('IS_AGREE_TERMS') === 'true' ? true : false
    this.isMobile = !TOOL.getDeviceScreenIsPC();
    this.$bus.$on("RESIZE_WINDOW", (para) => {
      that.isMobile = para.isMobile;
    });
  },
  methods: {
    changeAgree(e) {
      // LogUtils.debug(e)
      this.isAgreeTerms = e
      localStorage.setItem('IS_AGREE_TERMS', e.toString())
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    async handleRegister() {
      this.loading = true
      this.errorMsg = ''
      if (this.validate()) {
        const domainLang = TOOL.getDomainLang()

        this.registerForm.password = this.getmd5(this.registerForm.password)
        this.registerForm.password2 = this.getmd5(this.registerForm.password2)
        this.registerForm.fromtfid = localStorage.getItem('fromtfid') ? localStorage.getItem('fromtfid') : ''
        this.registerForm.area = domainLang
        this.registerForm.mail = this.emailInput
        this.registerForm.verifycode = this.emailSecode
        registerbyMail(this.registerForm)
          .then(async (response) => {
            const { token } = response.data
            // if (BLOCKCHAIN_CONFIG.isLocal) {
            _setCookie(tokenKey, token, {
              expires: new Date(new Date().getTime() + 86400000),
            })

            this.errorMsg = ''

            // } else {
            //   _setCookie(tokenKey, token, {
            //     expires: new Date(new Date().getTime() + 86400000),
            //     domain: 'tapfantasy.io'
            //   })
            // }
            if (this.registerForm.fromtfid.length > 0) {
              TOOL.gtmTrackNew('RegisterWeb', '', '', '', '')

              if (domainLang === 'tw') {
                window.fbq('track', 'RegisterWeb')
              }
            }

            this.$store.state.token = token
            this.router.push({ name: 'Play' })
            this.loading = false
          })
          .catch((error) => {
            // Message({
            //   showClose: true,
            //   message: error,
            //   type: 'error',
            //   duration: 3 * 1000
            // })
            this.errorMsg = error
            this.registerForm.password = ''
            this.registerForm.password2 = ''
            this.loading = false
            return false
          })

      }
    },
    validateUsername(value) {
      if (!value) {
        return this.$t('Login.error-please-enter-your-account')
      }
      return ''
    },
    validateRegCode(value) {
      if (!value) {
        return this.$t('Login.invitation-code-is-empty-try-again')
      }
      return ''
    },
    validatePassword() {
      if (!this.registerForm.password) {
        return this.$t('Login.password-is-empty-try-again')
      }
      if (this.registerForm.password.length < 8) {
        return this.$t('Login.password-should-use-8-or-more-characters-with-a-mix-of-letters-numbers-and-symbols')
      }
      if (this.registerForm.password !== this.registerForm.password2) {
        return this.$t('Login.those-passwords-didnt-match-try-again')
      }
      return ''
    },
    validate() {
      if (this.validateUsername(this.registerForm.name)) {
        this.errorMsg = this.validateUsername(this.registerForm.name)
        return false
      }
      if (this.validatePassword()) {
        this.errorMsg = this.validatePassword()
        return false
      }
      // if (this.validateRegCode(this.registerForm.regcode)) {
      //   this.errorMsg = this.validateRegCode(this.registerForm.regcode)
      //   return false
      // }
      if (this.langCur.global.locale.value === 'en' || this.langCur.global.locale.value === 'zh-TW') {
        if (!this.isAgreeTerms) {
          this.errorMsg = this.$t('Login.please-tick-i-have-read-and-agree-to-the-user-agreement')
          return false
        }
      }
      if (!this.validateEmail()) {
        return false
      }
      if (this.emailSecode.length === 0) {
        this.errorMsg = this.$t('Login.please-input-your-email-verify-code')
        return
      }
      return true
    },
    getmd5(str) {
      return md5(str)
    },
    validateEmail() {
      console.log(`here val`)
      const szReg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
      const bChk = szReg.test(this.emailInput);
      if (!bChk) {
        this.errorMsg = this.t('Login.please-check-your-email-address-is-correct')
        return false
      }
      return true
    },
    countdownSecCodeTask() {
      this.countdownGetSecCode = this.durationGetSecCode
      this.buttonGetVerifyCodeDisabled = true

      const timeStop = setInterval(() => {
        this.countdownGetSecCode--
        if (this.countdownGetSecCode === 0) {
          this.buttonGetVerifyCodeDisabled = false
          clearInterval(timeStop)
          this.countdownGetSecCode = this.durationGetSecCode
        }
      }, 1000)
    },
    getSecCode() {
      this.errorMsg = ''
      if (!this.validateEmail()) {
        return
      }
      getEmailSecCode({ mail: this.emailInput }).then((response) => {
        LogUtils.debug(response['data'])
        ElMessage({ message: this.t('Login.email-sedcode-sent'), type: 'success' })
        console.log(`here getse`)
        this.countdownSecCodeTask()

      }).catch((error) => {
        console.error(error)
        ElMessage({ message: this.t('Login.email-sedcode-sent-error'), type: 'error' })
        return ''
      })
    }
  }
}
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
