<template>
  <div class="playgame height-header-padding d-flex align-items-center justify-content-center">
    <div class="content d-flex flex-column align-items-center">
      <span class="title">{{ $t('Login.find-your-tapfantasy-account') }}</span>
      <div class="input-item first d-flex flex-column">
        <span class="input-title">{{ $t('Login.email') }}</span>
        <div class="input-block d-flex align-items-center">
          <input :placeholder="$t('Login.please-input-your-binded-email-address')" ref="regcode"
            v-model.trim="registerForm.mail" style="flex: 1;" />
        </div>
      </div>
      <div class="link-block d-flex align-items-center justify-content-end">
        <a href="/#/login">{{ $t('Login.sign-in-to-tap-fantasy') }}</a>
      </div>
      <span class="error-block login">
        {{ errorMsg }}
      </span>
      <button class="button signin" @click="handleResetpassword">{{ $t('Login.reset-password') }}</button>
    </div>
  </div>

  <!-- <div style="margin-top: 0px">
    <div class="videobg" style="z-index: -1">
      <video autoplay loop muted>
        <source src="/static/videos/topBg.webm" type="video/mp4" />
      </video>
    </div>

    
  </div>-->
</template>


<script>
import { useRouter } from 'vue-router'
import md5 from 'js-md5'
import { resetpwd2 } from '@/api/account'
import { tokenKey } from '@/settings'
import { _setCookie } from '@/utils/storage'
import { ElMessage } from 'element-plus'

export default {
  name: 'Forgetpass',
  data() {
    return {
      styleObj: {
        width: '100%',
        color: 'white',
        'border-radius': '0px',
        'font-size': '2vh',
        'min-height': '5vh',
        border: 'none',
        'border-bottom': '1px solid white'
      },
      loading: false,
      registerForm: {
        mail: '',
      },
      errorMsg: '',
      router: useRouter(),
    }
  },
  props: {
    walletAddress: '',
    isReady: false
  },
  created() {
    // const inviter = this.$route.query && this.$route.query.inviter
    // this.registerForm.inviter = inviter
  },
  methods: {
    login() {
      this.router.push({ name: 'Login' })
    },
    handleResetpassword() {
      this.loading = true
      this.errorMsg = ''
      if (this.validate()) {
        resetpwd2(this.registerForm)
          .then((response) => {
            const { name } = response.data
            ElMessage({ message: this.$t('Login.reset-pass-sedcode-sent-please-check-your-email'), type: 'success' })
            this.router.push({ name: 'Resetpass' })
          })
          .catch((error) => {
            this.errorMsg = error
            this.loading = false
            return false
          })
      }
    },
    validate() {
      const szReg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
      const bChk = szReg.test(this.registerForm.mail);
      if (!bChk) {
        this.errorMsg = this.$t('Login.please-check-your-email-address-is-correct')
      }
      return bChk
    },
    getmd5(str) {
      return md5(str)
    }
  }
}
</script>

<style scoped lang="stylus">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
</style>
