// import request from '@/network/request'
import request from '@/utils/axios'
import tool from '@/utils/tool'
import { server_domain_solana } from '../constants/api'

export interface BaseModel {
    code: number,
    msg: string,
}

export interface MCProductionAndCostModel {
    name: string,
    supply: number,
    cost: number
}

export interface MCBaseInfo {
    ranking: string,
    totalsupply: string,
    vesting: string,
    yieldrate: string,
    baseprice: string,
    tvl: string,
    price: string,
    currentsupply: string,
    burned: string,
    totaloutflow: string,
    todaysupply: string,
    totaluser: string,
    dau: string,
    mcsupplypool: number,
    ingametotalamount: string,
    mcyieldtotalamount: number
}

export interface ChartData {
    text: string,
    value: number
}

export interface NFTInfo {
    totalsupply: string,
    owners: string,
    volume: string,
    change: {
        value: string,
        img: string
    },
    maxtrades: {
        value: string,
        img: string
    },
    maxrent: {
        value: string,
        img: string
    }
}


// 游戏内每月和每日消耗
export async function getTotalOutflowMonthAndDaily(): Promise<{ dailylist: ChartData[], monthlylist: ChartData[] }> {
    const chainid = await tool.getChainId()
    // 这里，返回两组数据，查询一周的数据
    const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querytotaloutflowmonthlist/${chainid}`)
    // const result = await request.get<any, any>(`/api/mc/querytotaloutflowmonthlist/${chainid}`)
    return result.data.data

}

export const getProductionAndCost = async (type: 'total' | 'month'): Promise<MCProductionAndCostModel[]> => {
    const chainid = await tool.getChainId()
    const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querymcproductionandcost/${chainid}?type=${type}`)
    // const result = await request.get<any, any>(`/api/mc/querymcproductionandcost/${chainid}?type=${type}`)
    return result.data.data
}

export default {
    // 查询每日产出
    getDailySupply: async function (): Promise<ChartData[]> {
        const chainid = await tool.getChainId()
        const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querydailysupply/${chainid}`)
        // const result = await request.get<any, any>(`/api/mc/querydailysupply/${chainid}`)
        return result.data.data
    },
    getMCBaseInfo: async function (): Promise<MCBaseInfo> {
        const chainid = await tool.getChainId()
        const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querymcbaseinfo/${chainid}`)
        // const result = await request.get<any, any>(`/api/mc/querymcbaseinfo/${chainid}`)
        return result.data.data
    },

    getNFTInfo: async function (): Promise<NFTInfo> {
        const chainid = await tool.getChainId()
        const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querynftinfo/${chainid}`)
        // const result = await request.get<any, any>(`/api/mc/querynftinfo/${chainid}`)
        return result.data.data
    },

    getMCBaseInfoTon: async function (): Promise<MCBaseInfo> {
        const chainid = await tool.getChainId()
        const result = await request.get<any, any>(`${server_domain_solana}/api/mc/querytonmcprice`)
        // const result = await request.get<any, any>(`/api/mc/querymcbaseinfo/${chainid}`)
        return result.data.data
    },

}



