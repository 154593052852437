<template>
    <svg :id="id" :viewBox="ismobile ? '0 0 126 32' : '0 0 126 42'">
        <defs>
            <linearGradient x1="50%" y1="50%" x2="50%" y2="103.143435%" id="linearGradient-green">
                <stop stop-color="#1E4721" offset="0%"></stop>
                <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="50%" y1="50%" x2="50%" y2="103.143435%" id="linearGradient-red">
                <stop stop-color="#e37f7f" offset="0%"></stop>
                <stop stop-color="#000000" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g>
            <path id="pathArea"
                :fill="color === '' ? 'none' : (color === 'green' ? 'url(#linearGradient-green)' : 'url(#linearGradient-red)')" />
            <path id="pathLine" stroke-width="1" fill="none"
                :stroke="color === '' ? '#FFFFFF' : (color === '#DAFFD6' ? '#DAFFD6' : '#DAFFD6')" />
            <!-- <path d="M 0 20 H 200" fill="none"  /> -->
        </g>
    </svg>
</template>

<script>
import * as d3 from "d3";
import { nanoid } from "nanoid";

export default {
    name: "SparkLine",
    props: {
        data: {
            type: Array,
            default() {
                return [0, 0];
            },
        },
        color: {
            type: String,
        },
        ismobile: {
            type: Boolean,
            default: false
        }
    },


    data() {
        return {
            id: `chart-${nanoid()}`
        };
    },
    watch: {
        data() {
            this.plot();
        },
    },
    methods: {
        plot() {
            const xDomain = [0, this.data.length - 1]
            const yDomain = d3.extent(this.data)

            this.x.domain(xDomain);
            this.y.domain(yDomain);

            // const xAccessor = (d) => d.date
            // const yAccessor = (d) => d.downloads


            // const xScale = d3.scaleLinear()
            //     .domain(xDomain)
            //     .range([0, 126])

            // const yScale = d3.scaleLinear()
            //     .domain(yDomain)
            //     .range([42, 8])

            this.chart.select("#pathArea").datum(this.data).attr("d", this.area(this.data));
            this.chart.select("#pathLine").datum(this.data).attr("d", this.line(this.data));
        },
    },
    mounted() {
        const svgHeight = this.ismobile ? 32 : 42
        this.chart = d3.select(`#${this.id}`);

        this.x = d3.scaleLinear().range([0, 126]);

        this.y = d3.scaleLinear().range([svgHeight, 0]);

        const areaGenerator = d3.area()
            .x((d, i) => this.x(i))
            .y1((d) => this.y(d))
            .y0(svgHeight)
            .curve(d3.curveBumpX)

        this.line = d3
            .line()
            .x((d, i) => this.x(i))
            .y((d) => this.y(d))
            .curve(d3.curveBumpX);

        this.area = areaGenerator

        this.plot();
    },
};
</script>