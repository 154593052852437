<template>
    <template v-if="!isLoading">
        <div v-if="!isShowApprove" style="max-height: max-content;"
            class="mask-nft sellnft d-flex flex-column justify-content-center align-items-center">
            <!-- <svg v-if="isMobile" class="nft-mask-svg-close" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" @click="closeModal()" style="cursor: pointer;">
            <path
                d="M641.447722 511.998977l299.029514-299.033607c22.477944-22.476921 26.320459-55.098899 8.580422-72.838935l-65.198931-65.197908c-17.740036-17.740036-50.316989-13.853519-72.838935 8.624425L512.011768 382.570185 212.975091 83.551928c-22.476921-22.499433-55.098899-26.365484-72.838935-8.601912l-65.197908 65.197908c-17.740036 17.718547-13.897521 50.339502 8.624425 72.817446l299.026444 299.031561L83.562673 811.031561c-22.477944 22.477944-26.365484 55.076386-8.624425 72.817446l65.197908 65.209164c17.740036 17.74106 50.362015 13.897521 72.838935-8.580422l299.04384-299.048957 298.999838 299.004955c22.521946 22.521946 55.098899 26.365484 72.838935 8.624425l65.198931-65.209164c17.740036-17.74106 13.897521-50.339502-8.580422-72.839959L641.447722 511.998977z"
                p-id="2960" fill="#ffffff" />
        </svg> -->
            <div class="dialog-content d-flex flex-column align-items-center">
                <div class="title">
                    <template v-if="action === 0">Borrow MC using NFT</template>
                    <template v-else-if="action === 1">NFT Redeem</template>
                </div>
                <div :class="ismobile ? 'flex-column align-items-center' : 'align-items-start justify-content-between'"
                    class="content d-flex">
                    <div class="left">

                        <div class="level d-flex align-items-center justify-content-start">
                            <img
                                :src="'/static/images/element_level_' + cardInfoAPI.nft['rarity'].toLowerCase() + '.png'" />
                        </div>
                        <div class="nft-image-block">
                            <img :src="cardInfoAPI.nft['medium']" class="nft-image" />
                        </div>
                        <div class="attr-list d-flex">
                            <div class="item">
                                <img src="/static/images/element_attr_yellow_earth_ball.png" />
                                <span class="text earth">
                                    {{ $t('Common.earth') }}
                                </span>
                                <span class="value earth">
                                    {{
                                            cardInfoAPI.nft['earth']
                                    }}
                                </span>
                            </div>
                            <div class="item">
                                <img src="/static/images/element_attr_blue_water_ball.png" />
                                <span class="text water">
                                    {{ $t('Common.water') }}
                                </span>
                                <span class="value water">
                                    {{
                                            cardInfoAPI.nft['water']
                                    }}
                                </span>
                            </div>
                            <div class="item">
                                <img src="/static/images/element_attr_red_fire_ball.png" />
                                <span class="text fire">
                                    {{ $t('Common.fire') }}
                                </span>
                                <span class="value fire">
                                    {{
                                            cardInfoAPI.nft['fire']
                                    }}
                                </span>
                            </div>
                            <div class="item">
                                <img src="/static/images/element_attr_green_wind_ball.png" />
                                <span class="text wind">
                                    {{ $t('Common.wind') }}
                                </span>
                                <span class="value wind">
                                    {{
                                            cardInfoAPI.nft['wind']
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="right">

                        <div class="info-block d-flex justify-content-between" style="width:100%">
                            <span class="tag">Token ID</span>
                            <span class="text">{{ cardInfoAPI.nft.tokenid }}</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">Name</span>
                            <span class="text">{{
                                    $t(`CardList.${cardInfoAPI.nft.typeid}.name`)
                            }}</span>
                        </div>
                        <div v-if="action === 1" class="info-block notfirst d-flex justify-content-between"
                            style="width:100%">
                            <span class="tag">Stake time</span>
                            <span class="text">{{
                                    formatTime(cardInfoAPI.pledgetime)
                            }}</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">{{ action === 0 ? 'Loan' : 'Loan' }}</span>
                            <span class="text forsale">{{
                                    cardInfoAPI.loanamount
                            }} {{
        cardInfoAPI.token.symbol
}}</span>
                        </div>
                        <div class="infotip first right" v-if="action === 0">* Obtain in total</div>
                        <div v-if="action === 1" class="info-block notfirst d-flex justify-content-between"
                            style="width:100%">
                            <span class="tag">Total interest</span>
                            <span class="text forsale">{{
                                    formatNumber(cardInfoAPI.redeemamount - cardInfoAPI.loanamount, 4)
                            }} {{
        cardInfoAPI.token.symbol
}}</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between" style="width:100%">
                            <span class="tag">Interest</span>
                            <span class="text forsale">{{
                                    cardInfoAPI.feeperday
                            }} {{
        cardInfoAPI.token.symbol
}} / Day </span>
                        </div>
                        <div v-if="action === 0" class="info-block notfirst d-flex justify-content-between"
                            style="width:100%">
                            <span class="tag">Redemption time</span>
                            <span class="text forsale">Maximum of {{
                                    cardInfoAPI.maxredeemday
                            }} days</span>
                        </div>
                        <div v-if="action === 0" class="infotip first right">* Cannot be redeemed after {{
                                cardInfoAPI.maxredeemday
                        }} days</div>
                        <div v-if="action === 1" class="info-block notfirst d-flex justify-content-between"
                            style="width:100%">
                            <span class="tag">Total Amount Due</span>
                            <span class="text forsale">{{
                                    formatNumber(cardInfoAPI.redeemamount, 4)
                            }} {{
        cardInfoAPI.token.symbol
}} days</span>
                        </div>
                        <div class="info-block notfirst d-flex justify-content-between align-items-center"
                            style="width:100%">
                            <span class="tag">{{ action === 0 ? 'Wallet address' : 'Redeem address' }}</span>
                            <span class="text multiline">{{ wa }} (BSC)</span>
                        </div>
                        <div class="button-block stakeur justify-content-center align-items-center">
                            <button class="button-cancel" @click="cancel()">{{ $t('Dialog.cancel') }}</button>
                            <template v-if="!isLoading">
                                <button v-if="action === 0" :disabled="buttonDisabled" class="button-buy mynftsell"
                                    @click="loan()">Borrow {{
                                            cardInfoAPI.loanamount
                                    }} {{
        cardInfoAPI.token.symbol
}}</button>
                                <button v-else-if="action === 1" :disabled="buttonDisabled" class="button-buy mynftsell"
                                    @click="redeem()">Redeem</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-approve v-else :tokeninfo="getApproveTokenAndNeedAmount()" @confirm="approve()" />
    </template>
</template>
<script lang="ts">
import { getWalletProvider } from '@/utils/provider'
import { computed, ref, defineComponent, toRefs, nextTick, inject, getCurrentInstance } from 'vue'
import * as API from '@/constants/api'
import axios from '@/utils/axios'
import TOOL from '@/utils/tool'
import { useI18n } from "vue-i18n"
// import ERC721 from '@/abis/ERC721.json'
import ERC20 from '@/abis/ERC721.json'
import NFTTFSkinCashout from '@/abis/NFTTFSkinCashout.json'
import { CreateNotification } from '@/components/thirdparty/vue3-toast/notifications'
import { ethers } from 'ethers'
import moment from 'moment'
import { aggregate as aggregateStakeUR } from '@makerdao/multicall'
import BigNumber from 'bignumber.js'
import LogUtils from '@/utils/LogUtils'
import { is } from '@babel/types'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'

export default defineComponent({
    name: 'ModalStakeUR',
    props: {
        tokenid: {
            type: Number,
            default: -1
        },
        wa: {
            type: String,
            default: ''
        },
        action: {
            type: Number,
            default: 0
        },
        ismobile: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit, attrs }: any) {
        const { t } = useI18n()
        const createNotification = <CreateNotification>inject('create-notification')
        const BUS = getCurrentInstance()?.appContext.config.globalProperties.$bus

        const cardInfoAPI = ref({
            pledgetime: 0,
            ispledge: false,
            loanamount: 0,
            loancontract: "",
            loancontractindex: 0,
            feeperday: 0,
            onceamount: 0,
            maxredeemday: 0,
            redeemamount: 0,
            liquidatedamount: 0,
            nft:
            {
                tokenid: 0,
                character: "",
                description: "",
                image: "",
                medium: "",
                preview: "",
                name: "",
                rarity: "",
                level: 0,
                earth: 0,
                wind: 0,
                water: 0,
                fire: 0,
                typeid: 0
            },
            token:
            {
                id: 0,
                contract: "",
                description: "",
                symbol: "",
                decimals: 0,
                image: "",
                preview: ""
            },
            tokenid: 0,
            chainid: 0,
            nftcontract: "",
            nftassets: ""
        })

        let nftParaInfo = {
            tokenid: 0,
            typeid: 0,
            name: '',
            rarity: '',
            preview: '',
            price: 0,
            pricesymbol: '',
            amount: 1,
        }

        const isLoading = ref(true)
        const buttonDisabled = ref(false)
        const isShowApprove = ref(false)

        const payToken = ref({
            decimals: 0,
            address: '',
            symbol: '',
            balance: '',
            approve: 0
        })

        const showModal = () => {
            isShowApprove.value = false
        }

        const loadData = async () => {
            const { tokenid, action, wa } = toRefs(props)

            const projectConfig = TOOL.getConfigProject()
            const chainid = await TOOL.getChainId()
            const chainSymbol = await TOOL.getChainSymbol()
            const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
            const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
            const multicallConfig = {
                rpcUrl: chainRPCConfig,
                multicallAddress: chainABIConfig.MULTICALL
            }
            // LogUtils.debug(multicallConfig)
            const urlNftURDataAPI = `${API.urCardStakePara}${chainid}/${tokenid.value}`
            const nftURDataAPI = (await axios.get(urlNftURDataAPI)).data.data
            if (action.value === 1) {
                let calls: any[] = []
                let returnValue = null

                calls = calls.concat([
                    {
                        target: nftURDataAPI.loancontract,
                        call: ['pending(uint256)(uint256,uint256)', nftURDataAPI.nft.tokenid],
                        returns: [['REDEEM_AMOUNT', (val: any) => val / (10 ** nftURDataAPI.token.decimals)], ['LIQUDATED_AMOUNT', (val: any) => val / (10 ** nftURDataAPI.token.decimals)]]
                    }])

                returnValue = await aggregateStakeUR(calls, multicallConfig)
                const returnResult = returnValue.results.transformed

                payToken.value.address = nftURDataAPI.token.contract
                payToken.value.symbol = nftURDataAPI.token.symbol
                payToken.value.decimals = nftURDataAPI.token.decimals

                nftURDataAPI.redeemamount = returnResult.REDEEM_AMOUNT
                nftURDataAPI.liquidatedamount = returnResult.LIQUDATED_AMOUNT
            }
            cardInfoAPI.value = nftURDataAPI
            isLoading.value = false
        }
        const formatTime = (secs: number) => {
            return moment(secs * 1000).utc().format('MM/DD/yyyy HH:mm:ss z')
        }
        const getLowerCase = (str: any) => {
            return str.toString().toLowerCase()
        }
        const formatNumber = (n: number, l: number) => {
            return TOOL.formatdecimalPlaces(new BigNumber(n), l)
        }
        const getApproveTokenAndNeedAmount = () => {
            return {
                symbol: payToken.value.symbol,
                amount: new BigNumber(cardInfoAPI.value.redeemamount).toNumber()
            }
        }

        const checkApprove = async () => {
            const contractAddr = cardInfoAPI.value.loancontract

            const { wa } = toRefs(props)
            if (wa.value.length > 0) {
                const projectConfig = TOOL.getConfigProject()
                const chainid = await TOOL.getChainId()
                const chainSymbol = await TOOL.getChainSymbol()
                const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
                const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
                const multicallConfig = {
                    rpcUrl: chainRPCConfig,
                    multicallAddress: chainABIConfig.MULTICALL
                }

                let calls: any[] = []
                let returnValue = null

                calls = calls.concat([
                    {
                        target: payToken.value.address,
                        call: [
                            'allowance(address,address)(uint256)',
                            wa.value,
                            contractAddr
                        ],
                        returns: [['PAYTOKENLOAN_APPROVE', (val: any) => val / (10 ** payToken.value.decimals)]]
                    }])

                returnValue = await aggregateStakeUR(calls, multicallConfig)
                const returnResult = returnValue.results.transformed

                const allowanceNumber = returnResult.PAYTOKENLOAN_APPROVE

                const needAmount = getApproveTokenAndNeedAmount().amount
                if (Number(allowanceNumber) < needAmount) {
                    return false
                } else {
                    return true
                }

            }
            return false
        }

        const approve = async () => {
            const contractAddr = cardInfoAPI.value.loancontract

            const { wa } = toRefs(props)
            if (wa.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    const signer = provider.getSigner()
                    const contractTokenLoan = new ethers.Contract(
                        payToken.value.address,
                        ERC20,
                        signer
                    )
                    const uuid = createNotification({
                        message: t('Infotip.approving-token', { 'symbol': payToken.value.symbol }),
                        autoClose: false,
                        canClose: false
                    })
                    const amount = BLOCKCHAIN_CONFIG.MAX_UINT256.toString()
                    // const amount = '0'
                    contractTokenLoan
                        .approve(contractAddr, amount)
                        .then(async function (transaction: any) {
                            transaction
                                .wait()
                                .then(async function (transaction: any) {
                                    const projectConfig = TOOL.getConfigProject()
                                    const chainid = await TOOL.getChainId()
                                    const chainSymbol = await TOOL.getChainSymbol()
                                    const chainRPCConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.rpcurls)
                                    const chainABIConfig = TOOL.getDynamicObject(chainSymbol, projectConfig.abis)
                                    const multicallConfig = {
                                        rpcUrl: chainRPCConfig,
                                        multicallAddress: chainABIConfig.MULTICALL
                                    }

                                    let calls: any[] = []
                                    let returnValue = null

                                    calls = calls.concat([
                                        {
                                            target: payToken.value.address,
                                            call: [
                                                'allowance(address,address)(uint256)',
                                                wa.value,
                                                contractAddr
                                            ],
                                            returns: [['PAYTOKENLOAN_APPROVE', (val: any) => val / (10 ** payToken.value.decimals)]]
                                        }])

                                    returnValue = await aggregateStakeUR(calls, multicallConfig)
                                    const returnResult = returnValue.results.transformed

                                    const allowanceNumber = returnResult.PAYTOKENLOAN_APPROVE
                                    payToken.value.approve = allowanceNumber
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'success',
                                        message: t('Infotip.approve-token-successful', { 'symbol': payToken.value.symbol })
                                    })

                                    redeem()
                                })
                                .catch((error: any) => {
                                    console.error(error)
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        type: 'error',
                                        message: t('Infotip.approve-token-error', { 'symbol': payToken.value.symbol })
                                    })
                                })
                        })
                        .catch((error: any) => {
                            console.error(error)
                            BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                            createNotification({
                                type: 'error',
                                message: t('Infotip.approve-token-error', { 'symbol': payToken.value.symbol })
                            })
                        })
                }
            }
        }
        const loan = async () => {
            const { wa } = toRefs(props)
            if (wa.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractLoan = new ethers.Contract(
                            cardInfoAPI.value.loancontract,
                            NFTTFSkinCashout,
                            signer
                        )
                        const sendPromise = contractLoan.deposit(cardInfoAPI.value.loancontractindex, cardInfoAPI.value.tokenid)
                        const uuid = createNotification({
                            message: `Loan ${cardInfoAPI.value.loanamount} ${cardInfoAPI.value.token.symbol} for Stake NFT Skin(#${cardInfoAPI.value.nft.tokenid})...`,
                            autoClose: false,
                            canClose: false
                        })
                        buttonDisabled.value = true
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transactionResult: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        message: `Loan ${cardInfoAPI.value.loanamount} ${cardInfoAPI.value.token.symbol} for Stake NFT Skin(#${cardInfoAPI.value.nft.tokenid}) successful`,
                                        type: 'success',
                                        canClose: true
                                    })
                                    nftParaInfo = {
                                        tokenid: cardInfoAPI.value.nft.tokenid,
                                        typeid: cardInfoAPI.value.nft.typeid,
                                        name: cardInfoAPI.value.nft.name,
                                        rarity: cardInfoAPI.value.nft.rarity,
                                        preview: cardInfoAPI.value.nft.preview,
                                        price: cardInfoAPI.value.loanamount,
                                        pricesymbol: cardInfoAPI.value.token.symbol,
                                        amount: 1,
                                    }
                                    emit('loan', nftParaInfo)
                                    buttonDisabled.value = false
                                })
                            })
                            .catch(function (error: any) {
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    message: `Loan ${cardInfoAPI.value.loanamount} ${cardInfoAPI.value.token.symbol} for Stake NFT Skin(#${cardInfoAPI.value.nft.tokenid}) error`,
                                    type: 'error',
                                    canClose: true
                                })
                                buttonDisabled.value = false
                            })
                    } catch (e) {
                        console.error(e)
                        createNotification({
                            message: `Loan ${cardInfoAPI.value.loanamount} ${cardInfoAPI.value.token.symbol} for Stake NFT Skin(#${cardInfoAPI.value.nft.tokenid}) error`,
                            type: 'error',
                            canClose: true
                        })
                        buttonDisabled.value = false
                    }

                }
            }
        }
        const redeem = async () => {

            const flagApprove = await checkApprove()
            if (!flagApprove) {
                // createNotification({
                //     type: 'error',
                //     message: `Your token approve count is not enough`,
                // })
                isShowApprove.value = true
                return
            } else {
                isShowApprove.value = false
            }

            const { wa } = toRefs(props)
            if (wa.value.length > 0) {
                const provider = await getWalletProvider()
                if (provider) {
                    try {
                        const signer = provider.getSigner()
                        const contractLoan = new ethers.Contract(
                            cardInfoAPI.value.loancontract,
                            NFTTFSkinCashout,
                            signer
                        )
                        const sendPromise = contractLoan.withdraw(cardInfoAPI.value.tokenid)
                        const uuid = createNotification({
                            message: `Redeem NFT Skin(#${cardInfoAPI.value.nft.tokenid})...`,
                            autoClose: false,
                            canClose: false
                        })
                        buttonDisabled.value = true
                        sendPromise
                            .then(function (transaction: any) {
                                transaction.wait().then(function (transactionResult: any) {
                                    BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                    createNotification({
                                        message: `Redeem NFT Skin(#${cardInfoAPI.value.nft.tokenid}) successful`,
                                        type: 'success',
                                        canClose: true
                                    })
                                    nftParaInfo = {
                                        tokenid: cardInfoAPI.value.nft.tokenid,
                                        typeid: cardInfoAPI.value.nft.typeid,
                                        name: cardInfoAPI.value.nft.name,
                                        rarity: cardInfoAPI.value.nft.rarity,
                                        preview: cardInfoAPI.value.nft.preview,
                                        price: cardInfoAPI.value.redeemamount,
                                        pricesymbol: cardInfoAPI.value.token.symbol,
                                        amount: 1,
                                    }
                                    emit('redeem', nftParaInfo)
                                    buttonDisabled.value = false
                                })
                            })
                            .catch(function (error: any) {
                                BUS.$emit('REMOVE_NOTIFICATION', { id: uuid })
                                createNotification({
                                    message: `Redeem NFT Skin(#${cardInfoAPI.value.nft.tokenid}) error`,
                                    type: 'error',
                                    canClose: true
                                })
                                buttonDisabled.value = false
                            })
                    } catch (e) {
                        console.error(e)
                        createNotification({
                            message: `Redeem NFT Skin(#${cardInfoAPI.value.nft.tokenid}) error`,
                            type: 'error',
                            canClose: true
                        })
                        buttonDisabled.value = false
                    }

                }
            }
        }

        const cancel = () => {

            emit('cancel')
        }

        loadData()

        return { loan, cancel, redeem, cardInfoAPI, getLowerCase, formatTime, isLoading, formatNumber, buttonDisabled, isShowApprove, approve, getApproveTokenAndNeedAmount, showModal }
    }
})
</script>
<style lang="stylus">
</style>