import type { App } from 'vue'
import VueEcharts from 'vue-echarts'
import { use } from "echarts/core"
import 'echarts-liquidfill'

import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart, LineChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  GridComponent,
  TooltipComponent
])

const vueEcharts = (app: App) => {
  app.component('v-chart', VueEcharts)
}

export {
  vueEcharts
}