import * as echarts from 'echarts';

export const MC_REAL_LINE_BASE_OPTION = {
    backgroundColor: 'transparent',
    grid: {
        top: '25%',
        right: '10%',
        left: '5%',
        bottom: '20%',
        // containLabel: true
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.4)'
            },
        },
        axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
                color: '#d1e6eb',
                // margin: 15,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
            },
        },
        axisTick: {
            show: false,
        },
        data: [],
    }],
    yAxis: [{
        type: 'value',
        min: 0,
        // max: 140,
        // splitNumber: 4,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#7D5718'
            }
        },
        axisLine: {
            show: false,
        },
        axisLabel: {
            show: false,
            // margin: 20, 
            textStyle: {
                color: '#d1e6eb',
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'

            },
        },
        axisTick: {
            show: false,
        },
    }],
    series: [{
        type: 'line',
        // smooth: true, //是否平滑曲线显示
        symbol: 'circle',  // 默认是空心圆（中间是白色的），改成实心圆
        showAllSymbol: true,
        symbolSize: 8,
        lineStyle: {
            normal: {
                color: "#fff", // 线条颜色
            },
            borderColor: '#f0f'
        },
        label: {
            normal: {
                show: true,
                lineHeight: 30,
                width: 80,
                height: 30,
                // backgroundColor: 'rgba(0,160,221,0.1)',
                backgroundColor: '#3F3320',
                borderRadius: 200,
                position: ['-13', '-60'],
                distance: 1,
                formatter: [
                    '   {d|●}',
                    ' {a|{c}}     \n',
                    '    {b|}'
                ].join(''),

                rich: {
                    d: {
                        color: '#FFC160',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    a: {
                        color: '#FFC160',
                        align: 'center',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    b: {
                        width: 1,
                        height: 30,
                        borderWidth: 1,
                        borderColor: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                }
            }
        },

        itemStyle: {
            normal: {
                color: "#FFC05F",
            }
        },
        tooltip: {
            show: false
        },
        areaStyle: { //区域填充样式
            normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#E49A7D'
                },
                {
                    offset: 1,
                    color: 'transparent'
                }
                ], false),
                shadowColor: '#E49A7D', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            }
        },
        data: [],

    }]
}

export const MC_REAL_LINE_BASE_OPTION_MOBILE = {
    animationDuration: 1000,
    backgroundColor: 'transparent',
    grid: {
        top: '25%',
        right: '15%',
        left: '5%',
        bottom: '20%',
        // containLabel: true
    },
    xAxis: [{
        type: 'category',
        boundaryGap: false,
        axisLine: { //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
                color: 'rgba(255,255,255,0.4)'
            },
        },
        axisLabel: { //坐标轴刻度标签的相关设置
            textStyle: {
                color: '#d1e6eb',
                // margin: 15,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
            },
        },
        axisTick: {
            show: false,
        },
        data: [],
    }],
    yAxis: [{
        show: false,
        type: 'value',
        min: 0,
        // max: 140,
        // splitNumber: 4,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#7D5718'
            }
        },
        axisLine: {
            show: false,
        },
        axisLabel: {
            show: false,
            // margin: 20, 
            textStyle: {
                color: '#d1e6eb',
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'

            },
        },
        axisTick: {
            show: false,
        },
    }],
    series: [{
        type: 'line',
        // smooth: true, //是否平滑曲线显示
        symbol: 'circle',  // 默认是空心圆（中间是白色的），改成实心圆
        showAllSymbol: true,
        symbolSize: 8,
        lineStyle: {
            normal: {
                color: "#fff", // 线条颜色
            },
            borderColor: '#f0f'
        },
        label: {
            normal: {
                show: true,
                lineHeight: 30,
                height: 30,
                // backgroundColor: 'rgba(0,160,221,0.1)',
                backgroundColor: '#3F3320',
                borderRadius: 200,
                position: ['-13', '-50'],
                distance: 1,
                formatter: [
                    '   {d|●}',
                    ' {a|{c}}     \n',
                    '    {b|}'
                ].join(''),

                rich: {
                    d: {
                        color: '#FFC160',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    a: {
                        color: '#FFC160',
                        align: 'center',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    b: {
                        width: 1,
                        height: 26,
                        padding: [0, 0, 0, 0],
                        borderWidth: 1,
                        borderColor: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                }
            }
        },

        itemStyle: {
            normal: {
                color: "#FFC05F",
            }
        },
        tooltip: {
            show: false
        },
        areaStyle: { //区域填充样式
            normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#E49A7D'
                },
                {
                    offset: 1,
                    color: 'transparent'
                }
                ], false),
                shadowColor: '#E49A7D', //阴影颜色
                shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            }
        },
        data: [],

    }]
}

export const MC_REAL_BAR_BASE_OPTION = {
    backgroundColor: 'transparent',
    grid: {
        top: '25%',
        right: '5%',
        left: '5%',
        bottom: '20%'
    },
    xAxis: [{
        type: 'category',
        data: [],
        axisLine: {
            lineStyle: {
                color: 'rgba(255,255,255,0.12)' // 底部横杠
            }
        },
        axisLabel: {
            margin: 10,
            color: '#e2e9ff', // 底部文字
            textStyle: {
                // fontSize: 14,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'
            },
        },
    }],
    yAxis: [{
        name: '',
        axisLabel: {
            formatter: '{value}',
            color: '#7A6C4F',
            textStyle: {
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
                // fontSize: 14,
            }
        },
        splitLine: {
            lineStyle: {
                color: '#3B342B'
            }
        }
    }],
    series: [{
        type: 'bar',
        data: [],
        barWidth: '20px',
        itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FFA287' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: '#FFC15F' // 100% 处的颜色
                }], false),
                barBorderRadius: [30, 30, 0, 0],
                shadowColor: 'rgba(0,160,221,1)',
                // shadowBlur: 4,
            }
        },
        label: {
            normal: {
                show: true,
                lineHeight: 30,
                height: 30,
                backgroundColor: 'rgba(255,192,96,0.2)',
                borderRadius: 200,
                position: ['-8', '-60'],
                distance: 1,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
                formatter: [
                    '    {d|●}',
                    ' {a|{c}}    \n',
                    '    {b|}'
                ].join(''),
                rich: {
                    d: {
                        color: '#FFC060',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    a: {
                        color: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    b: {
                        width: 1,
                        height: 30,
                        borderWidth: 1,
                        borderColor: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                }
            }
        }
    }]
}

export const MC_REAL_BAR_BASE_OPTION_MOBILE = {
    backgroundColor: 'transparent',
    grid: {
        top: '25%',
        right: '10%',
        left: '5%',
        bottom: '20%'
    },
    xAxis: [{
        type: 'category',
        data: [],
        axisLine: {
            lineStyle: {
                color: 'rgba(255,255,255,0.12)' // 底部横杠
            }
        },
        axisLabel: {
            margin: 10,
            color: '#e2e9ff', // 底部文字
            textStyle: {
                // fontSize: 14,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'
            },
            formatter: function (value: any, index: number) {
                const arrDate = value.split('-');
                return arrDate[0] + '.' + arrDate[1] + '.' + arrDate[2];
            },
        },
    }],
    yAxis: [{
        name: '',
        show: false,
        axisLabel: {
            formatter: '{value}',
            color: '#7A6C4F',
            textStyle: {
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
                // fontSize: 14,
            }
        },
        splitLine: {
            lineStyle: {
                color: '#3B342B'
            }
        }
    }],
    series: [{
        type: 'bar',
        data: [],
        barWidth: '20px',
        itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FFA287' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: '#FFC15F' // 100% 处的颜色
                }], false),
                barBorderRadius: [30, 30, 0, 0],
                shadowColor: 'rgba(0,160,221,1)',
                // shadowBlur: 4,
            }
        },
        label: {
            normal: {
                show: true,
                lineHeight: 30,
                height: 30,
                backgroundColor: 'rgba(255,192,96,0.2)',
                borderRadius: 200,
                position: ['-8', '-60'],
                distance: 1,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
                formatter: [
                    '    {d|●}',
                    ' {a|{c}}    \n',
                    '    {b|}'
                ].join(''),
                rich: {
                    d: {
                        color: '#FFC060',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    a: {
                        color: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                    b: {
                        width: 1,
                        height: 30,
                        borderWidth: 1,
                        borderColor: '#FFC060',
                        align: 'left',
                        fontFamily: 'Avenir-Obliqu',
                        fontStyle: 'italic',
                    },
                }
            }
        }
    }]
}

// mc mining pool chart
export const MC_MINING_POOL_OPTION = {
    animationDurationUpdate: 3000,

    grid:{
        left:"0",
        right:"0",
        top:"0",
        bottom:"0"
    },
    calculable : true,
    xAxis : [
        {
            type : 'category',
            
            boundaryGap : false,
            data : ['',''],
            axisTick: {
                show: false,//不显示x轴的刻度
            },
            axisLine:{
            show:false,//隐藏x轴不显示
            }, 
        }
    ],
    yAxis : [
        {
            type : 'value',

            show:false, //去掉y轴  
            max: 0,
            axisLabel: {
            show: false // 隐藏 x 轴标签
            }
        }
    ],
    series : [
        {
            type:'line',
            symbol: 'none',
            color:"transparent",//折线图颜色
            label:{
                normal: {
                    show: false,
                    position: 'bottom',
                    color:"#3497cb",//设置数字的位置和颜色
                }
            },
            data:[80,80],
            areaStyle: { //区域填充样式
                normal: {
                    // color: '#FFC060'
                    color: '#E79A6C'
                    // new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    //     offset: 1,
                    //     color: '#E49A7D'
                    // },
                    // {
                    //     offset: 0,
                    //     color: 'transparent'
                    // }
                    // ], false),
                    // shadowColor: '#E49A7D', //阴影颜色
                    // shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                }
            }
        }
    ]
}


export const MC_PRICE_MONITORING_OPTION = {
    backgroundColor: 'transparent',
    grid: {
        top: '30px',
        // right: '20%',
        left: '93px',
        // bottom: '12%'
    },
    yAxis: [{
        type: 'category',
        color: '#59588D',
        data: ['a','b'],
        axisPointer: {
            type: 'line'
        },
        axisLine: {
            lineStyle: {
                color: '#4A3559',// 左侧杠颜色
            }
        },
        axisTick:{
            show:false,
        },
        axisLabel: {
            margin: 10,
            color: 'white', // 左侧文字颜色
            textStyle: {
                fontSize: 12,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'
            },
        },

    }],
    xAxis: [{
        min: 0,
        // max: Math.max.apply(null, supplyDataAndCostData.value) * 1.2,
        position: 'top',
        axisLabel: {
            show: false,
            // formatter: '{value}%', // 刻度线相关
            color: '#4F7A64',
            fontFamily: 'Avenir-Oblique'
        },
        axisLine: {
            show: false
        },
        splitLine: {
            show: false,
            lineStyle: {
                color: '#4F7A64' // 纵向刻度线样式
            }
        },
        axisTick: {
            show: false
        },
    }],
    series: [{
        type: 'bar',
        data: [10,20],
        barWidth: '8px',
        itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: '#E6C5FF' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: '#999EFF' // 100% 处的颜色
                }], false),
                barBorderRadius: [0, 30, 30, 0], //圆润角度
                // shadowColor: 'rgba(0,255,225,1)',
                shadowBlur: 4,
            }
        },
        label: {
            normal: {
                show: true,
                // lineHeight: 0,
                borderRadius: 200,
                position: 'right',
                offset: [-30, -20], //  x y
                formatter: [
                    
                    '{d| ${c}}\n',
                    '    {f|}\n',
                    '     {b|}',
                    // ' {|{c}%}     \n',

                ].join(''),
                rich: {
                    d: {
                        color: '#999EFF', // 文本颜色
                        // width: 40,
                        // height: 30,
                        // backgroundColor: '#27363F',
                        align: 'center',
                        borderRadius: 200,
                        // padding: [0, 0, 0, 10],
                        fontFamily: 'Avenir-Oblique',
                        fontWeight: 'bold',
                        fontSize: 12
                    },
                    f:{
                        width: 10,
                        height: 10,
                        lineHeight: 5,
                        align: 'left',
                         borderRadius: 10,
                        // color: '#3CDDCF',
                        backgroundColor: '#999EFF',
                    },
                    b: {
                        // 应该可以定位到上面去
                        width: 1,
                        // align: 'center',
                        height: 10,
                        borderWidth: 1,
                        verticalAlign: 'top',
                        // margin: [0, 10, 0, 0],
                        lineHeight: 10,
                        // borderColor: '#86D2FF',
                        borderColor: '#999EFF',
                        // align: 'left',
                        // position: 'top',
                    },
                }
            }
        }
    }]
}


// mc Consumption and out put chart
export const MC_CONSUMPTION_AND_OUT_PUT_OPTION = {
    tooltip : {
        trigger: 'axis',
        axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(p: any) {
            // console.log('ppp: ', p[0].dataIndex, p);
            // 第二个不展示
            if(p[0].dataIndex == 0) return ''
            return p.map((item: any) => `${item.seriesName}: ${item.data}<br>`).join('');
        },
    },
    backgroundColor: 'transparent',
    // backgroundColor: 'black',
    grid: {
        top: '20%',
        right: '20%',
        left: '100px',
        bottom: '12%'
    },
    yAxis: [{
        type: 'category',
        color: '#59588D',
        data: ['Output', 'Consumption'],
        axisPointer: {
            type: 'line'
        },
        axisLine: {
            lineStyle: {
                color: '#246142'// 左侧杠颜色
            }
        },
        axisLabel: {
            margin: 10,
            color: 'white', // 左侧文字颜色
            textStyle: {
                fontSize: 14,
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic'
            },
        },

    }],
    xAxis: [{
        min: 0,
        // max: Math.max.apply(null, supplyDataAndCostData.value) * 1.2,
        position: 'top',
        axisLabel: {
            // formatter: '{value}%', // 刻度线相关
            color: '#4F7A64',
            fontFamily: 'Avenir-Oblique'
        },
        axisLine: {
            show: false
        },
        splitLine: {
            lineStyle: {
                color: '#4F7A64' // 纵向刻度线样式
            }
        },
        axisTick: {
            show: false
        },
    }],
    series: [
    ]
}

// labelData; last series use
export const MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL = {
    normal: {
        show: true,
        lineHeight: 30,
        borderRadius: 200,
        position: 'right',
        distance: 1,
        top: 10,
        rich: {
            d: {
                color: '#86D2FF',
                width: 80,
                height: 30,
                backgroundColor: '#27363F',
                align: 'left',
                borderRadius: 200,
                padding: [0, 0, 0, 10],
                fontFamily: 'Avenir-Oblique'
            },
            b: {
                width: 30,
                height: 1,
                borderWidth: 1,
                borderColor: '#86D2FF'                
            },
        }
    }
}

// seriesDetail
export const MC_CONSUMPTION_AND_OUT_PUT_SERIES_DETAIL: any = {
    name: '',
    type: 'bar',
    data: [],
    barWidth: '20px',
    stack: 'bar',
    itemStyle: {
        normal: {
            color: '#69BAA8',
            barBorderRadius: [0, 0, 0, 0],
            // shadowBlur: 4,
        }
    },
}


// 

export const MC_CONSUMPTION_AND_OUT_PUT_OPTION_MOBILE = {
    tooltip : {
        trigger: 'axis',
        axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        formatter: function(p: any) {
            if(p[0].dataIndex == 0) return ''
            return p.map((item: any) => `${item.seriesName}: ${item.data}<br>`).join('');
        },
    },
    // backgroundColor: 'transparent',
    backgroundColor: 'black',
    grid: {
        top: '25%',
        right: '50rem',
        left: '50rem',
        bottom: '40rem'
    },
    xAxis: [{
            type: 'category',
            data: ['Output', 'Consumption'],
            axisLine: {
                lineStyle: {
                    color: '#236241' // 底部横杠
                }
            },
            axisLabel: {
                margin: 10,
                color: '#e2e9ff', // 底部文字
                textStyle: {
                    // fontSize: 14,
                    fontFamily: 'Avenir-Obliqu',
                    fontStyle: 'italic'
                },
            },
        }],
        yAxis: [{
            name: '',
            axisLabel: {
                formatter: '{value}',
                color: '#4F7A64',
                textStyle: {
                    fontFamily: 'Avenir-Obliqu',
                    fontStyle: 'italic',
                    // fontSize: 14,
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#4F7A64'
                }
            }
        }],
    series: []
}



// last label 
export const MC_CONSUMPTION_AND_OUT_PUT_LAST_LABEL_MOBILE = {
    normal: {
        show: true,
        lineHeight: 30,
        height: 30,
        backgroundColor: '#27363F',
        borderRadius: 200,
        position: ['-8', '-60'],
        distance: 1,
        fontFamily: 'Avenir-Obliqu',
        fontStyle: 'italic',
        formatter: [
            '    {d|●}',
            ' {a|{c}}     \n',
            '    {b|}'
        ].join(''),
        rich: {
            d: {
                // color: '#27363F',
                color: '#86D2FF',
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
            },
            a: {
                color: '#86D2FF',
                align: 'center',
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
            },
            b: {
                width: 1,
                height: 30,
                borderWidth: 1,
                borderColor: '#86D2FF',
                align: 'left',
                fontFamily: 'Avenir-Obliqu',
                fontStyle: 'italic',
            },
        }
    }
}