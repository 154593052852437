<template>
    <div class="change-chain-wrap">
        <img class="logo" src="/static/images/page1-bscchain-logo.png" alt="" />
        <el-select v-model="chain" class="m-2" placeholder="Select" size="large" popper-class="chain-select">
            <!-- <el-option v-for="item in chainList" :key="item.chain" :label="item.chain" :value="item.chain" /> -->
            <el-option v-for="item in chainList" :key="item.chain" :value="item.chain">
                <div :class="item.chain">{{ item.chain }}</div>
            </el-option>
            <!-- <el-option disabled>
                <div class="ton">TON <span>Coming Soon</span></div>
            </el-option> -->
        </el-select>
    </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue'
import { isDebug } from '@/constants/Config'
import TOOL from '../../utils/tool'

const siteSOL = isDebug
  ? 'https://solana-test.tapfantasy.io'
  : 'https://solana.tapfantasy.io'

const chain = ref<string>('BNB')

onMounted(async () => {
  const chainid = await TOOL.getChainId()
  if (chainid > 97) {
    chain.value = 'SOL'
  }
})

watch(chain, (value: string) => {
//   if (value == 'SOL') {
//     window.location.href = siteSOL
//   }

  switch(value) {
    case 'BNB':
        window.location.href = 'https://bsc.tapfantasy.io'
        break
    case 'SOL':
        window.location.href = siteSOL
        break
    case 'TON':
        window.location.href = 'https://ton.tapfantasy.io'
        break
    }
})

const chainList = [
  {
    chain: 'BNB'
  },
  {
    chain: 'SOL'
  },
  {
    chain: 'TON'
  }
]
</script>

<style lang="stylus">
.chain-select{
    border: none !important;
    background-color: #000;
    .BNB {
        color: #E4BB04;
    }
    .SOL{
        background-image: linear-gradient(to right,#9249E4,  #14f497);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 600;
    }
    .ton {
        color: #aaa;
        font-weight: 600;
        span {
        font-weight: 400;

            color: #e5e5e5;
            // background-color: pink;
            // background-color: #ee9b0b ;
            background-color: #44A5E1 ;
            padding: 2px 8px;
            border-radius: 50px;
            margin-left: 5px;
            font-size: 12PX;
        }
    }
    .TON {
        color: #44A5E1;
        font-weight: 600;
    }
    &+.el-popper__arrow{
        display: none ;
    }

    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
        background-color: #44434E !important;
        // color: #fff;
    }
    .el-select-dropdown__item {
        background-color: #000;
        // color: #fff;
    }

    .el-select-dropdown__item.selected {
        color: #fff;
    }

    .chain-select .el-select-dropdown__item.selected {
        color: #fff;
    }
}
</style>
<style scoped lang="stylus">
.change-chain-wrap{
    display: flex;
    align-items: center;
    .logo {
        width: 32px;
        height: 32px;
    }

    :deep(.el-select .el-input__inner) {
        background-color: transparent;
        border: none;
        color: white;
        width: 75px !important;
        min-width: 90px !important;
        font-family: Avenir-Heavy;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        // text-align: center;

    }

    :deep(.el-select){
        margin: 0 !important;
    }
}
</style>
