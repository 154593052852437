import axios from 'axios'
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config'
// import store from '@/store/store'
// import { useRouter } from 'vue-router'
import { _getCookie } from '@/utils/storage'
import { tokenKey } from '@/settings'
import LogUtils from '@/utils/LogUtils'

const baseURL = import.meta.env.VITE_APP_WEB_GAME_URL
// BLOCKCHAIN_CONFIG.isDebug ? 'https://alpha-web.tapfantasy.io' : 'https://web.tapfantasy.io'
// const baseURL = 'https://web1.tapfantasy.io:8084'
// const baseURL = 'http://tdev.sanguoh5.com:8282'
// const baseURL = 'http://127.0.0.1:8084'

const service = axios.create({
  baseURL,
  timeout: 20000 // 请求超时 20s
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 设置请求头
    // if (store.state.token) {
    // 让每个请求携带toke
    config.headers.Authorization = _getCookie(tokenKey)
    // }
    // 对全局参数做过滤，把不存在的参数删除
    if (config.method === 'post') {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in config.data) {
        if (!config.data[key] && config.data[key] !== 0) {
          delete config.data[key]
        }
      }
    } else if (config.method === 'get') {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in config.params) {
        if (!config.params[key] && config.params[key] !== 0) {
          delete config.params[key]
        }
      }
    }
    // 全局去前后空格
    // dataTrim(config.data)
    return config
  },
  (error) => {
    LogUtils.debug(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // const router = useRouter()
      // router.push({ name: 'Home' })
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  (error) => {
    LogUtils.debug(`err${error}`) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })`
    return Promise.reject(error)
  }
)

export default service
