<template>
    <div class="mc-model-wrap mobile-card"  ref="mcModel">

        <div class="mc-model-mobile">
            <div class="model-mobile-title">
                {{ $t('HomeNew.title1') }}
            </div>
            <div class="model-mobile-desc">
                {{ $t('HomeNew.title2') }}
            </div>
            <div  class="model-mobile-list">

            </div>
        </div>
        <div class="mc-model" v-show="isShow">
            <div class="box">
                <div class="box1">
                    <div class="line1">{{ $t('HomeNew.box1-title') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box1-line1') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box1-line2') }}</div>
                    <div class="line2">-{{ $t('HomeNew.line3') }}</div>
                </div>                
            </div>
            <div class="box">
                <div class="box2">
                    {{ $t('HomeNew.box3.title') }}
                </div>
                <img class="img1" src="/static/images/page1_model_triangle1.svg" alt="">

            </div>
            <div class="box">
                <div class="box3">
                    <div class="line1">{{ $t('HomeNew.box2.title') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box2.line1') }}</div>
                    <div class="line2">-10% {{ $t('HomeNew.box2.line2') }}</div>
                    <div class="line2">-80% {{ $t('HomeNew.box2.line3') }}</div>
                </div>
                <img class="img2" src="/static/images/page1_model_triangle2.svg" alt="">

            </div>
            <div class="box">
                <div class="box4">
                    <div class="line1">{{ $t('HomeNew.box4.title') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box4.line1') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box4.line2') }}</div>
                </div>
                <img class="img3" src="/static/images/page1_model_triangle3.svg" alt="">

            </div>
            <div class="box">
                <div class="box5">
                    <div class="line1">{{ $t('HomeNew.box5.title') }}</div>
                    <div class="line2">{{ $t('HomeNew.box5.line1') }}</div>
                </div>
                <img class="img4" src="/static/images/page1_model_triangle4.svg" alt="">

            </div>
            <div class="box">
                <div class="box6">
                    <div class="line1">{{ $t('HomeNew.box6.title') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box6.line1') }}</div>
                    <div class="line2">-{{ $t('HomeNew.box6.line2') }}</div>
                </div>
                <img class="img5" src="/static/images/page1_model_triangle5.svg" alt="">

            </div>
            <div class="core-info ">
                <div class="title">
                    {{ $t('HomeNew.title1') }}
                </div>
                <div class="text">{{ $t('HomeNew.title2') }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'

const mcModel = ref(null)
const isShow = useElementVisibility(mcModel)

</script>

<style scoped lang="stylus">

@keyframes identifier {
    0% {
        transform: translate(-50%, 0%) rotate(-30deg);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, 0%) rotate(0turn);
        opacity: 1;
    }
}

@keyframes identifier2 {
    0% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(0turn);
    }
}
block_bg_color=#101010
.mc-model-wrap {
    overflow: hidden;
    .mc-model-mobile {
        display: none;
    }
    background-color: block_bg_color;
    
    height: 580px;
    margin-top: 60px;
    // width: 1780px;
    // padding: 113px 380px;
    padding-bottom: 0;
    box-sizing: border-box; 
    .mc-model {
        width: 1216px;
        height: 680px;
        top: -100px;
        margin: 0 auto;
        background: linear-gradient(180deg, rgba(16,16,16,0.00) 16%, #101010 100%), url(/static/images/page1_mc_model.svg) no-repeat;
        background-size: 973px 520px;
        // background-size: 973px 623px;
        background-position: center bottom;
        position: relative;
        overflow hidden
        .box {
            width: 933px;
            height: 933px;
            border-radius: 50%;
            // border: 1px solid #FFFFFF;
            position: absolute;
            left: 50%;
            opacity: 0;
            top: 158px;
            animation: identifier .5s ease-out 0s 1  alternate forwards
            &>div {
                position: absolute;
                z-index: 1;
                background-size: 100% 100%;
                animation: identifier2 .5s ease-out 0s 1  alternate forwards
            }

            &:nth-child(2) {
                animation-delay: .2s;
                &>div{
                    animation-delay: .2s;
                }
            }
            &:nth-child(3) {
                animation-delay: .4s;
                &>div{
                    animation-delay: .4s;
                }
            }
            &:nth-child(4) {
                animation-delay: .6s;
                &>div{
                    animation-delay: .6s;
                }
            }
            &:nth-child(5) {
                animation-delay: .8s;
                &>div{
                    animation-delay: .8s;
                }
            }
            &:nth-child(6) {
                animation-delay: 1s;
                &>div{
                    animation-delay: 1s;
                }
            }
            img {
                position: absolute;
                width: 20px;
                height: 20px;
                z-index: 2;
                transition: all .5s;
                // opacity : 0;
            }
            .img1 {
                bottom: 640px;
                left: 30px;
            }

            .img2 {
                bottom: 805px;
                left: 150px;
            }

            .img3 {
                top: -5px;
                left: 468px;
            }

            .img4 {
                top: 95px;
                right: 168px;
            }

            .img5 {
                top: 265px;
                right: 32px;
            }

            .box1{
                width: 186px;
                height: 112px;
                bottom: 482px;
                left: -29px;
                box-sizing: border-box;
                padding: 7px 0 0 20px;
                background-image: url(/static/images/page1_model_rectangle1.png);
                // padding: 14px 0 0 20px;
                .line1 {
                    color: #4A94FD;
                    line-height: 30px;
                }
                // transition-delay: 2s;
            }
            .box2{
                width: 215px;
                height: 55px;
                bottom: 710px;
                left: 20px;
                background-image: url(/static/images/page1_model_rectangle2.png);
                color: #5871FB;
                letter-spacing: 0;
                line-height: 56px;
                text-align: center;
                font-family: Avenir-Medium;
                font-size: 20px;
                color: #625EFB;

            }
            .box3{
                width: 200px;
                height: 115px;
                top: -16px;
                left: 215px;
                padding: 10px 0 0 15px;
                background-image: url(/static/images/page1_model_rectangle3.png);
            }
            .box4{
                width: 119px;
                height: 100px;
                top: -2px;
                right: 235px;
                padding: 14px 0 0 25px;
                background-image: url(/static/images/page1_model_rectangle4.png);
                .line1 {
                    color: #825CF9;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }

            .box5{
                width: 270px;
                height: 75px;
                top: 150px;
                right: 30px;
                padding: 0px 0 0 20px;
                background-image: url(/static/images/page1_model_rectangle5.png);

                .line1 {
                    color: #874CF8;
                    width: 100%;

                }

            }

            .box6{
                width: 160px;
                height: 100px;
                bottom: 500px;
                right: -25px;
                padding: 14px 0 0 25px;
                background-image: url(/static/images/page1_model_rectangle6.png);

                .line1 {
                    color: #AD46F8;
                }
            }

        }
        &>div {
            position: absolute;
            box-sizing: border-box;
        }
        .line1 {
            font-family: Avenir-Medium;
            font-size: 20px;
            color: #625EFB;
            letter-spacing: 0;
            line-height: 30px;
            // white-space: nowrap;

        }
        .line2 {
            font-family: Avenir-BookOblique;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 22px;
            // white-space: nowrap;

        }
        .text1{
            width: 190px;
            height: 120px;
            bottom: 58px;
            box-sizing: border-box;
            padding: 14px 0 0 20px;
            .line1 {
                color: #4A94FD;
                line-height: 30px;
            }
        }
        .text2{
            width: 220px;
            height: 56px;
            bottom: 268px;
            left: 47px;

            color: #5871FB;
            letter-spacing: 0;
            line-height: 56px;
            text-align: center;
            font-family: Avenir-Medium;
            font-size: 20px;
            color: #625EFB;

        }
        .text3{
            width: 200px;
            height: 128px;
            top: 60px;
            left: 250px;
            box-sizing: border-box;
            padding: 25px 0 0 15px;
        }
        .text4{
            width: 119px;
            height: 100px;
            top: 90px;
            right: 266px;
            padding: 14px 0 0 25px;
            .line1 {
                color: #825CF9;
                letter-spacing: 0;
                line-height: 30px;
            }
        }

        .text5{
            width: 270px;
            height: 75px;
            top: 225px;
            right: 60px;
            padding: 14px 0 0 20px;

            .line1 {
                color: #874CF8;
            }

        }

        .text6{
            width: 160px;
            height: 100px;
            bottom: 75px;
            right: 0px;
            padding: 14px 0 0 25px;

            .line1 {
                color: #AD46F8;
            }
        }
        .core-info {
            left: 50%;
            top: 55%;
            transform: translate(-50%, 0%);
            text-align: center;
            .title {
                font-family: LondonTwo;
                font-size: 40px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 40px; 
            }

            .text {
                font-family: Avenir-Light;
                font-size: 20px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                line-height: 25px;
                margin-top: 30px;
                
            }
        }

    }
}

</style>

<style scoped lang="stylus">
@media screen and (max-width: 1140px) {

    .mc-model-wrap {
        height: 890rem;
        .mc-model {
            display: none;
        }

        .mc-model-mobile {
            display: block;

            .model-mobile-title {
                font-family: LondonTwo;
                font-size: 24rem;
                color: #FFFFFF;
                line-height: 24rem;
                text-align: center;
                padding-top: 30rem;
            }
            .model-mobile-desc {
                font-family: Avenir-Light;
                font-size: 14rem;
                color: #FFFFFF;
                text-align: center;
                line-height: 14rem;
                margin-top: 20rem;
            }

            .model-mobile-list {
                height: 730rem;
                width: 290rem;
                margin: 0 auto;
                margin-top: 30rem;
                background: url(/static/images/page1_model_mobile.svg) no-repeat;
                background-size: 290rem 730rem;

            }
        }

    }
    
}
</style>