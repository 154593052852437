<template>
    <div class="wrap mobile-card">
        <div class="game-info-wrap">
            <div class="game-ifno font-LondonTwo">
                <div class="title">How To <span>P2E</span></div>
                <div class="block">
                    <div class="text-info">
                        <div class="title">{{ $t('HomeNew.arena-ranking') }}</div>
                        <div class="desc">
                            <span class="line">{{ $t('HomeNew.game-arean-line1') }}<br /></span>
                            <span class="line">{{ $t('HomeNew.game-arean-line2') }}<br /></span>


                        </div>
                        <a href="https://tapfantasy.gitbook.io/tap-fantasy-en/the-way-of-p2e/season-system"
                            target="_blank">
                            <div class="nft-info-link">
                                {{ $t('HomeNew.learn-more') }}
                                <svg t="1683164214485" class="right-arrow" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="4997" width="200" height="200">
                                    <path
                                        d="M841.076364 546.909091H162.909091a34.909091 34.909091 0 0 1 0-69.818182h678.167273a34.909091 34.909091 0 0 1 0 69.818182z"
                                        fill="#EE9B0B" p-id="4998"></path>
                                    <path
                                        d="M861.090909 546.909091a34.443636 34.443636 0 0 1-24.901818-10.472727l-182.458182-186.181819A34.909091 34.909091 0 0 1 703.534545 302.545455l182.458182 186.181818a34.909091 34.909091 0 0 1 0 49.338182 34.443636 34.443636 0 0 1-24.901818 8.843636z"
                                        fill="#EE9B0B" p-id="4999"></path>
                                    <path
                                        d="M678.632727 733.090909a34.909091 34.909091 0 0 1-24.901818-58.181818l182.458182-186.181818a34.909091 34.909091 0 1 1 49.803636 48.872727l-182.458182 186.181818a34.443636 34.443636 0 0 1-24.901818 9.309091z"
                                        fill="#EE9B0B" p-id="5000"></path>
                                </svg>
                            </div>
                        </a>
                    </div>
                    <div class="img"></div>
                </div>
                <div class="block">
                    <div class="img"></div>
                    <div class="text-info">
                        <div class="title">{{ $t('HomeNew.lottery-slot') }}</div>
                        <div class="desc">
                            <span class="line">{{ $t('HomeNew.slot-line1') }}<br /></span>
                            <span class="line">{{ $t('HomeNew.slot-line2') }}<br /></span>
                        </div>
                        <a href="https://medium.com/tapfantasy/slot-tournament-to-get-mc-5d098e62d96b" target="_blan">
                            <div class="nft-info-link">
                                {{ $t('HomeNew.learn-more') }}
                                <svg t="1683164214485" class="right-arrow" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="4997" width="200" height="200">
                                    <path
                                        d="M841.076364 546.909091H162.909091a34.909091 34.909091 0 0 1 0-69.818182h678.167273a34.909091 34.909091 0 0 1 0 69.818182z"
                                        fill="#EE9B0B" p-id="4998"></path>
                                    <path
                                        d="M861.090909 546.909091a34.443636 34.443636 0 0 1-24.901818-10.472727l-182.458182-186.181819A34.909091 34.909091 0 0 1 703.534545 302.545455l182.458182 186.181818a34.909091 34.909091 0 0 1 0 49.338182 34.443636 34.443636 0 0 1-24.901818 8.843636z"
                                        fill="#EE9B0B" p-id="4999"></path>
                                    <path
                                        d="M678.632727 733.090909a34.909091 34.909091 0 0 1-24.901818-58.181818l182.458182-186.181818a34.909091 34.909091 0 1 1 49.803636 48.872727l-182.458182 186.181818a34.443636 34.443636 0 0 1-24.901818 9.309091z"
                                        fill="#EE9B0B" p-id="5000"></path>
                                </svg>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>

        <div class="nft-ifno-wrap">
            <div class="nft-info" ref="gameInfoItem">
                <div class="nft-title font-LondonTwo"><span>NFT</span> {{ $t('HomeNew.collection') }} </div>
                <div class="nft-desc">{{ $t('HomeNew.collection-desc') }}</div>
                <div class="nft-data gradient-text" v-if="isShow">
                    <div class="item">
                        <div class="text">
                            <count-to :endVal='parseFloat(nftInfo.totalsupply)'
                                :decimals="getDecimal(nftInfo.totalsupply)" :suffix="getSuffix(nftInfo.totalsupply)" />
                        </div>
                        <div class="desc">{{ $t('HomeNew.total-supply') }}</div>
                    </div>
                    <div class="item">
                        <div class="text">
                            <count-to :endVal='parseFloat(nftInfo.owners)' :decimals="getDecimal(nftInfo.owners)"
                                :suffix="getSuffix(nftInfo.owners)" />
                        </div>
                        <div class="desc">{{ $t('HomeNew.owners') }}</div>
                    </div>
                    <div class="item">
                        <div class="text">
                            <count-to :endVal='parseFloat(nftInfo.volume)' :decimals="getDecimal(nftInfo.volume)"
                                :suffix="getSuffix(nftInfo.volume)" prefix="$" />
                        </div>
                        <div class="desc">{{ $t('HomeNew.volume') }}</div>
                    </div>
                </div>
                <div class="nft-top-wrap">
                    <div class="nft-top">
                        <div class="top-item">
                            <img :src="nftInfo.change.img">
                            <div class="top-info">
                                <div class="top-change">+{{ nftInfo.change.value }}</div>
                                <div class="top-desc">{{ $t('HomeNew.30d-change') }}</div>
                            </div>
                        </div>
                        <div class="top-item">
                            <img :src="nftInfo.maxtrades.img">
                            <div class="top-info">
                                <div class="top-change">{{ nftInfo.maxtrades.value }}</div>
                                <div class="top-desc">{{ $t('HomeNew.max-trades') }}</div>
                            </div>
                        </div>
                        <div class="top-item">
                            <img :src="nftInfo.maxrent.img">
                            <div class="top-info">
                                <div class="top-change">{{ nftInfo.maxrent.value }}</div>
                                <div class="top-desc">{{ $t('HomeNew.max-rent-income') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile-img"></div>
                </div>

                <a href="/#/marketplace/buynft" class="nft-more">
                    {{ $t('HomeNew.marketplace') }}
                    <svg t="1683164214485" class="right-arrow" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="4997" width="200" height="200">
                        <path
                            d="M841.076364 546.909091H162.909091a34.909091 34.909091 0 0 1 0-69.818182h678.167273a34.909091 34.909091 0 0 1 0 69.818182z"
                            fill="#EE9B0B" p-id="4998"></path>
                        <path
                            d="M861.090909 546.909091a34.443636 34.443636 0 0 1-24.901818-10.472727l-182.458182-186.181819A34.909091 34.909091 0 0 1 703.534545 302.545455l182.458182 186.181818a34.909091 34.909091 0 0 1 0 49.338182 34.443636 34.443636 0 0 1-24.901818 8.843636z"
                            fill="#EE9B0B" p-id="4999"></path>
                        <path
                            d="M678.632727 733.090909a34.909091 34.909091 0 0 1-24.901818-58.181818l182.458182-186.181818a34.909091 34.909091 0 1 1 49.803636 48.872727l-182.458182 186.181818a34.443636 34.443636 0 0 1-24.901818 9.309091z"
                            fill="#EE9B0B" p-id="5000"></path>
                    </svg>
                </a>

            </div>
            <div class="img"></div>

        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import mcInfo, { NFTInfo } from '@/api/mcInfo';
import { getDecimal, getSuffix } from '../utils/index'
import { useElementVisibility } from '@vueuse/core'

const gameInfoItem = ref(null)
const isShow = useElementVisibility(gameInfoItem)


const nftInfo = ref<NFTInfo>({
    totalsupply: '0',
    owners: '0',
    volume: '0',
    change: {
        value: '',
        img: ''
    },
    maxtrades: {
        value: '',
        img: ''
    },
    maxrent: {
        value: '',
        img: ''
    }


})

const getNftInfoData = async () => {
    nftInfo.value = await mcInfo.getNFTInfo()
}

onMounted(async () => {
    await getNftInfoData()
})


</script>
<style scoped lang="stylus">

@keyframes moveArrow {
  from {
    transform: translateX(10px);
  }
  to {
    transform: translateX(0px);
  }
}

.wrap{
    
    margin: 0 auto;
    margin-top: 100px;
    // text-align: center;
    .game-info-wrap {
        height: 1126px;
        width: 100%;
        // background-color: red;
        background: linear-gradient(179deg, #000000 0%, rgba(0,0,0,0.00) 25%, rgba(0,0,0,0.00) 70%, #000000 91%),url(/static/images/page1_game_info_bg.png) no-repeat;
        // background-image: ;
        background-size: 100% 100%;
        .game-ifno {
            width: 1200px;
            margin: 0 auto;
            .title{
                text-align: center;
                // font-family: AppleSystemUIFont;
                font-size: 56px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 56px;
                
                span{
                    color: #EE9B0B;
                }
            }
            .block{
                display: flex;
                justify-content: space-around;
                // margin-top: 60px;
                align-items: center;
                .text-info{
                    // user-select: none;
                    .nft-info-link {
                        margin-top: 20px;
                        font-size: 18px;
                        color: #EE9B0B;
                        letter-spacing: 0;
                        line-height: 30px;
                        cursor: pointer;
                        .right-arrow {
                            // font-size: 12PX;
                            width: 30px;
                            height: 30px;
                            color: red;
                            animation: moveArrow 1s alternate-reverse infinite;
                        }    
                    }
                    .title{
                        letter-spacing: 2PX;
                        font-size: 40px;
                        color: #F0B030;
                        text-align: right;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: Impact;
                        // background-image: linear-gradient(#F7C25D , rgb(248, 221, 168, 0) 10%, #F7C25D );
                        background-image: linear-gradient(to bottom, #F7C25D, #F3DBB1, #F7C25D);
                        height: 40px;
                        line-height: 40px;
                        font-weight: 600;
                        /* color: transparent; */
                        -webkit-text-stroke: 1PX #AB8850;
                        margin-bottom: 10px;
                        
                    }
                    .desc{
                        font-family: Avenir-Light;
                        font-size: 18px;
                        color: #FFFFFF;
                        letter-spacing: 0;
                        text-align: right;
                        line-height: 30px;
                        white-space: pre-line;
                        padding-right: 20px;
                        background: url(/static/images/page1_game_info_bar.png) no-repeat;
                        background-size: 10px 87px;
                        background-position: right center;
                        margin-top: 20px;

                    }
                }
                .img {
                    width: 440px;
                    height: 370px;
                    background: url(/static/images/page1_game_info_bank.webp) no-repeat;
                    background-size: 440px 370px;
                }

                &:last-child{
                    .img {
                        width: 380px;
                        height: 520px;
                        background: url(/static/images/page1_game_info_solt.png) no-repeat;
                        background-size: 380px 520px;
                        
                    }
                    .text-info {
                        margin-top: -120px;
                    }
                    .title {
                        text-align: left;
                    }
                    .desc {
                        text-align: left;
                        background-position: left center;
                        padding-left: 20px;
                        width: 758px;
                        background-size: 10px 100%;
                    }

                    .nft-info-link {
                        margin-top: 50px;
                        float: right;
                    }
                }
            }

            
        }
    }

    .nft-ifno-wrap {
        width: 1200px;
        margin: 0 auto;
        margin-top: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
            width: 400px;
            height: 450px;
            background: url(/static/images/page1_game_info_card.png) no-repeat;
            background-size: 400px 450px;
        }
        .nft-info {
            width: 760px;
            .nft-title {
                font-size: 56px;
                color: #fff;
                letter-spacing: 0;
                line-height: 56px;
                span {
                    color: #EE9B0B;
                }
            }
            .nft-desc {
                margin-top: 25px;
                font-family: Avenir-Light;
                font-size: 16px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 26px;
                
            }
            
            .nft-data {
                display: flex;
                margin-top: 35px;
                .item {
                    margin-right: 50px;
                    width: 180px;
                    height: 70px;
                    border: 1px solid #CBB055;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    // .text {
                    //     padding-top: 5px;
                    //     font-family: Avenir-Black;
                    //     font-size: 30px;
                    //     color: #FFC300;
                    //     letter-spacing: 0;
                    //     line-height: 30px;
                    // }
                    // .desc {
                    //     font-family: Avenir-Medium;
                    //     font-size: 16px;
                    //     color: #FFFFFF;
                    //     letter-spacing: 0;
                    //     text-align: center;
                    //     line-height: 25px;
                    // }
                }
            }
            .nft-top {
                display: flex;
                margin-top: 70px;
                user-select: none;
                .top-item {
                    margin-right: 80px;
                    display: flex;
                    align-items: center;
                    img {
                        // border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        margin-right: 10px;
                        background: url(/static/images/page1_game_nft_bg.png) no-repeat;
                        background-size: cover;
                        padding-bottom: 7px;

                    }
                    .top-info{
                        color: #fff;
                        .top-change {
                            font-family: Avenir-Black;
                            font-size: 16px;
                            color: #FFFFFF;
                            letter-spacing: 0;
                        }
                        .top-desc {
                            font-family: Avenir-Light;
                            font-size: 12px;
                            color: #FFFFFF;
                            letter-spacing: 0;
                            text-align: center;
                        }
                    }
                } 
            }

            .nft-more {
                margin-top: 70px;
                font-size: 18px;
                color: #EE9B0B;
                letter-spacing: 0;
                line-height: 30px;
                cursor: pointer;
                position: relative;
                top: 30px;
                .right-arrow {
                    // font-size: 12PX;
                    width: 30px;
                    height: 30px;
                    color: red;
                    animation: moveArrow 1s alternate-reverse infinite;
                }
            }
        }
       
    }
    
    
}

</style>

<style scoped lang="stylus">
@media screen and (max-width: 1140px) {
    .wrap {  
        background: none ;

        .game-info-wrap{
            margin-top: 60rem;

            height: 1020rem;
            background: none;
            .game-ifno {
                width: 100%;
                .title {
                    font-size: 30rem;
                    line-height: 1;
                    
                    
                }

                .block {
                    flex-direction: column-reverse;
                    align-items: center;
                    
                    .text-info{
                        .nft-info-link {
                            display: none;
                        }
                        width: 320rem;
                        padding-left: 20rem;
                        
                        .title {
                            font-size: 26rem;
                            line-height: 1;
                            height: 26rem;
                            text-align: left;
                        }
                        .desc {
                            margin-top: 20rem;
                            font-size: 14rem;
                            line-height: 1.2;
                            text-align: left;
                            background-size: 6rem 100rem;
                            padding-left: 15rem;
                            background-position: left center;
                            .line {

                                &:not(:first-child) {
                                    display: block;
                                    margin-top: 10rem;

                                
                                }
                            }
                            

                        }
                    }
                        

                    .img {
                        padding-left: 20rem;
                        width: 330rem;
                        height: 280rem;
                        background-size: 333rem 280rem;
                    }

                    &:last-child{
                        .img {
                            order: 1;
                            padding: 0;
                            width: 281rem;
                            height: 387rem;
                            background-size: 281rem 387rem;
                            margin-top: 30rem;
                            
                        }
                        .text-info {
                            // margin-top: -120px;
                            font-family: Impact;
                            font-size: 26px;
                            line-height: 26px;
                        }
                        .title {
                            // text-align: left;
                        }
                        .desc {
                            // text-align: left;
                            // background-position: left center;
                            // padding-left: 20px;
                            width: 100%;
                        }
                    }

                    
                }
            }
            

        }

        .nft-ifno-wrap {
            width: 340rem;
            margin: 0 auto;
            margin-top: 40rem;
            .nft-info {
                width: 100%;
                .nft-title {
                    font-size: 30rem;
                    line-height: 1;
                    text-align: center;
                }
                .nft-desc {
                    margin-top: 30rem;
                    padding: 0 10rem
                    font-size: 14rem;
                    line-height: 1;
                }

                .nft-data {
                    width: 340rem;
                    height: 46rem;
                    display: flex;
                    margin-top: 30rem;
                    justify-content: space-between;
                    .item {
                        width: 98rem;
                        height: 46rem;
                        .text {
                            font-size: 16rem;

                            line-height: 21rem;
                        }

                    }
                }
                .nft-more {
                    font-size: 12rem;
                    line-height: 12rem;
                    position: relative;
                    top: -35rem;
                    .right-arrow {
                        width: 12rem;
                        height: 10rem;
                    }
                }

            }
            &>.img {
                display: none;
            }
            .nft-top-wrap {
                margin-top: 40rem;
                display: flex;
                justify-content: space-between;
                .nft-top{
                    margin-top: 10rem;
                    height: 150rem;
                    flex-direction: column;
                    justify-content: space-between;
                    .top-item{
                        img {
                            width: 30rem;
                            height: 30rem;
                            background-size: 30rem 30rem;
                            margin-right: 10rem;
                        }
                        .top-info {
                            height: 30rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .top-change {
                                font-size: 14rem;
                                line-height: 1;
                            }
                            .top-desc {
                                font-size: 12rem;
                                line-height: 1;
                            }
                        }
                    }
                    
                }
                .mobile-img {
                    width: 204rem;
                    height: 230rem;
                    background: url(/static/images/page1_game_info_card.png)  no-repeat ;
                    background-size: 204rem 230rem;
                }
            }
            
        }
        
    }
}
</style>