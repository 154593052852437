import { isLocal, isUrBoxOpen } from './Config';
import BLOCKCHAIN_CONFIG from '@/constants/blockchain_config';
/**
 * Created by Thierry on 2017/4/14.
 */

export const isDebug: boolean = BLOCKCHAIN_CONFIG.isDebug

export const server_domain: string = isLocal ? '' : import.meta.env.VITE_APP_API_URL
export const server_domain_solana: string = isLocal ? '' : import.meta.env.VITE_APP_API_SOLANA_URL
// (isDebug ? 'https://alpha-api.tapfantasy.io' : 'https://api.tapfantasy.io')

export const assetsInfo: string = server_domain + '/web2/user/assets/'
export const assetsListDraw: string = server_domain + '/web2/user/tokenwithdraw/'
export const assetsListDrawMC: string = server_domain + '/web2/user/tokenwithdrawmc/'
export const assetsListRecharge: string = server_domain + '/web2/user/tokenrecharge/'
export const assetsInfoMyNFT: string = server_domain + '/web2/user/mynft/filter/'
export const assetsListNFT: string = server_domain + '/web2/user/mynft/'
export const assetsListTemptory: string = server_domain + '/web2/user/mynft/once/'
export const assetsListNFTCollateral: string = server_domain_solana + '/api/user/mynftcollateral/'
export const assetsListNFTHead: string = server_domain_solana + '/api/assets/nftavatarlist/'
export const assetsListNFTRune: string = server_domain_solana + '/api/assets/nftrunelist/'
export const assetsListNFTRuneSoldout: string = server_domain_solana + '/api/market/nftrunesoldlist/'
// export const assetsListSellingNFT: string = '/web2/market/listowned/'
export const filterMP: string = server_domain + '/web2/market/filter/'
export const itemListMP: string = server_domain + '/web2/market/list/'
export const filterRENT: string = server_domain + '/web2/lending/filter/'
export const itemListRENT: string = server_domain + '/web2/lending/list/'
export const filterRUNE: string = server_domain_solana + '/api/market/runefilter/'
export const itemListRUNE: string = server_domain_solana + '/api/market/runelist/'

export const nftInfo: string = server_domain_solana + '/api/lending/nfttokeninfo/'
export const nftHeadInfo: string = server_domain_solana + '/api/assets/nftavatardetail/'
export const nftRuneMarketInfo: string = server_domain_solana + '/api/market/runedetail/'
export const nftRuneAssetsInfo: string = server_domain_solana + '/api/assets/nftrunedetail/'
export const nftRuneSoldoutInfo: string = server_domain_solana + '/api/market/runedetail/'

export const nftHistoryMarket = server_domain + '/web2/market/history/'
export const nftHistoryLend = server_domain + '/web2/lending/history/'

export const nftRuneHistoryMarket = server_domain_solana + '/api/market/runehistory/'

export const boxkeyInfo: string = server_domain + '/web2/boxkey/info/'
export const boxkeyListowned: string = server_domain + '/web2/boxkey/listowned/'

export const boxkeyTxInfo: string = isUrBoxOpen ? server_domain_solana + '/api/boxkey/keytxinfo/' : server_domain + '/web2/boxkey/keytxinfo/'
export const buyerboxInfo: string = isUrBoxOpen ? server_domain_solana + '/api/boxbuyer/info/' : server_domain + '/web2/boxbuyer/info/'
//FIX UR BOX
// export const boxkeyTxInfo: string = server_domain_solana + '/api/boxkey/keytxinfo/'
// export const buyerboxInfo: string = server_domain_solana + '/api/boxbuyer/info/'

export const buyerboxTxInfo: string = server_domain + '/web2/boxbuyer/keytxinfo/'
export const buyerboxHistory: string = server_domain + '/web2/boxbuyer/history/'
export const userInviteCode: string = server_domain + '/web/user/invcode/'
export const userBlindboxInviteCodeUploadByHash: string = server_domain + '/web/boxbuyer/inviter/'
export const inviteBlindboxBoard: string = server_domain + '/web/boxbuyer/inviterboard/'

export const mysteryBoxInfo: string = server_domain_solana + '/api/box/detail/'
export const mysteryBoxOpenedHistory: string = server_domain_solana + '/api/box/openhistory/'

export const assetsListShop: string = server_domain + '/web2/shop/info/'
export const assetsBoxInfo: string = server_domain + '/web/mbox/info/'
export const assetsBoxItemInfo: string = server_domain + '/web/mbox/txinfo/'
export const assetsListRecommend: string = server_domain + '/web/shop/special/{}/?type=8'

export const urCardStakePara: string = server_domain_solana + '/api/urpledge/peldgeparams/'

//Marketplace V2
export const urlMPRecommendForNewUser: string = server_domain_solana + '/api/marketplace/recommend/fornewuser/'
export const urlMPRecommendPopularCollection: string = server_domain_solana + '/api/marketplace/recommend/popularcollections/'
export const urlMPBuyNFTHotGainer: string = server_domain_solana + '/api/marketplace/buynft/hottopgainer/'
export const urlMPBuyNFTHotRarity: string = server_domain_solana + '/api/marketplace/buynft/hotrarity/'
export const urlMPBuyNFTHotCharacter: string = server_domain_solana + '/api/marketplace/buynft/hotcharacter/'
export const urlMPBuyNFTList: string = itemListMP
export const urlMPBuyNFTStatData: string = server_domain_solana + '/api/marketplace/buynft/stat/'
export const urlMPRentNFTList: string = itemListRENT
export const urlMPRentNFTStatData: string = server_domain_solana + '/api/marketplace/rentnft/stat/'
export const urlNFTChartData: string = server_domain_solana + '/api/nftinfo/trendchart/'